import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import {IFormField} from "./Input.field.component";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "../../Services/Api.service";
import {DebounceInput} from 'react-debounce-input';
import StringHelper from "../../Helpers/String.helper";


type SearchFieldProps = IFormField & {
    oldValue?: string,
    onChange: (value: string) => void,
}

export default function SearchFieldComponent(props: SearchFieldProps){
    const [currentValue, setCurrentValue] = useState<string>("");
    const [errors, setErrors] = useState<IApiErrorMessage>(null);

    useEffect(()=> {
        setCurrentValue(props.oldValue ? props.oldValue: "");
    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);

    /**
     * Ecoute du onChange sur le champ
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        let value: string = e.target.value;
        value = StringHelper.removeAccents(value)
        setCurrentValue(value);
        props.onChange(value);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    };


    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            <div className={`input-wrapper -with-aside ${errors ? '-error' : ''}`}>
                <DebounceInput
                    minLength={2}
                    debounceTimeout={500}
                    onChange={onChangeInput}
                    className="form-field-input input"
                    name={props.fieldName}
                    type="text"
                    placeholder={props.placeholder}
                    required={props.required}
                    value={currentValue}
                 />

                <button type="submit" className="form-field-aside -is-button icon-search" />
            </div>
        </div>
    )
}
