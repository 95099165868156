import ApiService from "./Api.service";


export type GeoApiServiceResponse = {
    records: IVille[]
}

export type IVille = {
    fields: {
        nom_com: string,
        libelle: string,
        code: string,
        insee: string,
    }
}

export class GeoApiService extends ApiService{

    public getVilles(code: string):Promise<GeoApiServiceResponse>{
        return this.callExternalApi<GeoApiServiceResponse>('https://public.opendatasoft.com/api/records/1.0/search/?dataset=correspondance-code-cedex-code-insee&q=code:'+code+'&facet=type_code&facet=nom_com&facet=nom_dep&facet=nom_epci&facet=nom_reg&rows=100');
    }

}
