import React, {useEffect, useState} from "react";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import DateHelper from "../../../Helpers/Date.helper";
import {INotaire} from "../../../Models/Notaire.model";
import NotairesHelper from "../../../Helpers/Notaires.helper";
import {IDocument} from "../../../Models/Document.model";
import {AppConstants} from "../../../Constants/AppConstants";
import DocumentsHelper from "src/Helpers/Documents.helper";
import SelectFieldComponent from "../../Fields/Select.field.component";
import {NotairesConstants} from "../../../Constants/NotairesConstants";


const NotaireDocuments = (props: {
    notaire: INotaire
}) => {
    const [documentsList, setDocumentsList] = useState<IDocument[]>([]);
    const [documentsFiltered, setDocumentsFiltered] = useState<IDocument[]>([]);
    const [orderDirection, setOrderDirection] = useState<string>(AppConstants.orderType.asc);
    const [orderBy, setOrderBy] = useState<string>('date');
    const [filterBy, setFilterBy] = useState<string>("type");
    const [filterByValue, setFilterByValue] = useState<string>('');

    useEffect(() => {
        if(props.notaire && props.notaire.documents){
            const newDocumentsList: IDocument[] = props.notaire.documents;
            setDocumentsList(newDocumentsList);
        }
    }, [props.notaire])


    useEffect(() => {
        filterAndOrderDocuments();
    }, [documentsList, orderBy, orderDirection, filterByValue])

    /**
     * Permet le tri et le filtre des documents
     */
    const filterAndOrderDocuments = (): void => {
        const newDocuments = DocumentsHelper.filterAndOrder(documentsList, orderBy, orderDirection, filterBy, filterByValue)
        setDocumentsFiltered(newDocuments);
    }



    return (
        <>
            {
                documentsList && (documentsList.length > 0) &&
                <div className="list-filters -documents-list">
                    <i className={`icon icon-filtre`}/>

                    <SelectFieldComponent fieldName="notaireDocumentsFilterBy"
                                          label="Trier par"
                                          options={NotairesConstants.documentsTypesOptions}
                                          errors={null}
                                          placeholder="&nbsp;"
                                          hideSearch={true}
                                          modificators={`-inline -on-white`}
                                          oldValue={filterByValue ? filterByValue : ""}
                                          onChange={(value: string) => setFilterByValue(value)}
                    />
                    <SelectFieldComponent fieldName="notaireDocumentsOrder"
                                          label="Trier par"
                                          options={NotairesConstants.documentsOrderOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white`}
                                          oldValue={orderBy ? orderBy : ""}
                                          onChange={(value: string) => setOrderBy(value)}
                    />
                    <SelectFieldComponent fieldName="notaireDocumentsOrderDirectement"
                                          label="Ordre du tri"
                                          options={AppConstants.orderDirectionOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white -small-select`}
                                          oldValue={orderDirection ? orderDirection : ""}
                                          onChange={(value: string) => setOrderDirection(value)}
                    />
                </div>
            }

            <div className="informations-wrapper">
            {
                documentsFiltered && documentsFiltered.map((d,dIndex) =>
                    <div className="columns" key={dIndex}>
                        <p className="title">{ d.nom ? d.nom : DateHelper.getFormatedDate(d.date)}</p>

                        <div className="column -auto">
                            <div className="informations-block">
                                <p className="label">Document</p>
                                <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(d.fichier)} />
                            </div>
                        </div>
                        <div className="column -auto">
                            <div className="informations-block">
                                <p className="label">Type de document</p>
                                <p className="value">{d.type ? NotairesHelper.getFormatedDocumentType(d.type) : '-'}</p>
                            </div>
                        </div>
                        <div className="column -auto">
                            <div className="informations-block">
                                <p className="label">Titre</p>
                                <p className="value">{d.commentaires ? d.commentaires : '-'}</p>
                            </div>
                        </div>
                    </div>

                )
            }
        </div>
        </>
    )
};

export default NotaireDocuments;
