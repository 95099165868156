import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ICtrc, ICtrcNotaireMembrePresence} from "src/Models/Ctrc.model";
import {ICtrcGetApiResponse, ICtrcInformationsFormData} from "src/Services/Ctrcs.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import NotairesHelper from "../../../Helpers/Notaires.helper";
import {INotaire} from "../../../Models/Notaire.model";
import {INotaireGetAllApiResponse, NotairesService} from "../../../Services/Notaires.service";


type ICtrcInformationsFormProps = {
    ctrc?: ICtrc,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ICtrcInformationsFormData) => Promise<ICtrcGetApiResponse>,
    onSuccessSubmit: (response: ICtrcGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ICtrcGetApiResponse) => void,
    onCancel: () => void,
}

export default function CtrcInformationsFormComponent(props: ICtrcInformationsFormProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [notaires, setNotaires] = useState<INotaire[]>(null);
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notairesCTRC, setNotairesCTRC] = useState<INotaire[]>([]);
    const [notairesCTRCLoaded, setNotairesCTRCLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();

    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response: INotaireGetAllApiResponse) => {
            if (response && response.datas && response.datas.notaires) {
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

        notairesService.getCTRC().then((response: INotaireGetAllApiResponse) => {
            if (response && response.datas && response.datas.notaires) {
                setNotairesCTRC(response.datas.notaires);
                setNotairesCTRCLoaded(true);
            }
        });

    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const ctrc: ICtrc = props.ctrc;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'hidden',
                fieldName: 'creation',
                oldValue: props.isOnCreationStep ? "oui" : "non",
            },
            {
                type: 'datetime-local',
                withTime: true,
                fieldName: "date",
                label: "Date",
                placeholder: "Date",
                required: true,
                oldValue: ctrc && ctrc.date ? ctrc.date : "",
                modificators: !ctrc ? "-on-white" : '',
            }
        ];

        if (!props.isOnCreationStep) {
            formElementsColumn1.push(
                {
                    type: 'text',
                    fieldName: "nom",

                    label: "Nom",
                    placeholder: "Nom",
                    required: true,
                    modificators: !ctrc ? "-on-white" : '',
                    oldValue: ctrc && ctrc.nom ? ctrc.nom : "",
                    showFieldErrorDetail: true,
                },
            );
        }

        const formElementsColumn2: FormElement[] = [];

        if (!props.isOnCreationStep) {
            console.log(ctrc.president)

            formElementsColumn2.push(
                {
                    type: 'select',
                    fieldName: "presidentId",

                    label: "Président",
                    placeholder: "Président",
                    required: false,
                    modificators: !ctrc ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                    oldValue: ctrc && ctrc.president ? ctrc.president.id.toString() : "",
                    options: notaires && notaires.length ? NotairesHelper.formatListForOptions(notaires) : [],
                },
                {
                    type: 'selectMultiple',
                    fieldName: "notairesMembresIds",
                    //Uniquement des notaires (dont le président)
                    label: "Membres",
                    options: notairesCTRC ? NotairesHelper.formatListForOptions(notairesCTRC) : [],
                    required: false,
                    modificators: !ctrc ? "-on-white" : "",

                    oldValues: ctrc && ctrc.notairesMembresPresences && ctrc.notairesMembresPresences.length > 0 ? NotairesHelper.getIdsFromList(ctrc.notairesMembresPresences.map((notaireMembrePresence: ICtrcNotaireMembrePresence) => {
                        return notaireMembrePresence.notaire
                    })) : [],
                },
                {
                    type: "mention",
                    label: "Veillez à ne pas supprimer le président de la liste des membres (ligne sans nom avec une croix).",
                }
            );
        }


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true);
        setFormActions(currentFormActions);


    }, [notairesCTRC, notaires]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as ICtrcInformationsFormData).then((response: ICtrcGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }


            const message: string = props.ctrc ? "Le ctrc a bien été modifiée." : "Le ctrc a bien été créée."
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: message,
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création du ctrc.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )
}
