import React from "react";
import {ICorrespondance} from "../../../Models/Correspondance.model";
import DateHelper from "../../../Helpers/Date.helper";
import CalendarComponent from "../../Calendar/Calendar.component";
import {CorrespondanceConstants} from "../../../Constants/CorrespondancesConstants";

const CorrespondanceInformations = (props: {
    correspondance: ICorrespondance
}) => {

    return <div className="informations-wrapper">
        <div className="columns">

            {
                props.correspondance.date &&
                <div className="column -auto">
                    <CalendarComponent date1={props.correspondance.date} date2={null}/>
                </div>
            }


            <div className="column">
                <div className="informations-block">
                    <p className="label">Envoyé</p>
                    <p className="value -flex">
                        <span className={`state-bullet icon-bullet -before-element -${props.correspondance.envoye}`}>&nbsp;</span>
                        {props.correspondance.envoye}
                    </p>
                </div>
                <div className="informations-block">
                    <p className="label">Titre</p>
                    <p className="value">{props.correspondance.titre || '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Date</p>
                    <p className="value">{ props.correspondance.date ?  DateHelper.getFormatedDate(props.correspondance.date, true) : '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Lieu</p>
                    <p className="value">{props.correspondance.lieu || '-'}</p>
                </div>
            </div>

            <div className="column">
                <div className="informations-block -skip-line-1">
                    <p className="label">Type</p>
                    <p className="value">
                        { props.correspondance.type ? CorrespondanceConstants.types[props.correspondance.type] : '-'}
                    </p>
                </div>
                <div className="informations-block">
                    <p className="label">Date limite de réponse</p>
                    <p className="value">{props.correspondance.dateLimiteReponse ? DateHelper.getFormatedDate(props.correspondance.dateLimiteReponse) : '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Adresse</p>
                    <p className="value">{props.correspondance.adresse || '-'}</p>
                </div>
            </div>
        </div>

    </div>

};

export default CorrespondanceInformations;
