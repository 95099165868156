import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";

export type IClassementOfficeData = {
    nom: string,
    CRPCEN: string,
    departement: string,
    [key:number]: number,
    moyenne: number
}
export type IClassementTotauxData = {
    [key:string]: {
        "total": number,
        "nbOffices": number,
        "moyenne": number,
        "min": number,
        "max": number,
    }
}
export type IClassementStatsData = {
    [key:string]: {
        [key:string]: {
            "total": number,
            "moyenne": number,
            "min": number,
            "max": number,
        }
    }
}
export type IClassementHeader = {slug: string, label: string};

export type IClassementsOfficesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IApiPagination & {
            headers : IClassementHeader[],
            years : number[],
            data: IClassementOfficeData[],
            totaux: IClassementTotauxData
        }
    }
}

export type IClassementsStatsGetApiResponse = IApiCustomResponse & {
    datas: {
        stats: IClassementStatsData
    }
}



export type IClassementsFormData = {
    [key:string]: string
};

export class ClassementsService extends ApiService {

    /**
     * Récupère les classements pour les offices
     * @param {IStatistiquesFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public getClassementsOffices(formData: IClassementsFormData): Promise<IClassementsOfficesGetApiResponse> {
        return this.doApiCall(`api/inspections/classements/offices`, 'POST', formData);
    }

    /**
     * Récupère les stats des classements
     * @param {IClassementsFormData} formData
     * @returns {Promise<IFormationsGetApiResponse>}
     */
    public getStatistiques(formData: IClassementsFormData): Promise<IClassementsStatsGetApiResponse> {
        return this.doApiCall(`api/inspections/classements/statistiques`, 'POST', formData);
    }
}
