import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IReclamation} from "src/Models/Reclamation.model";
import {IReclamationGetApiResponse, IReclamationNotairesFormData} from "src/Services/Reclamations.service";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import {INotaire} from "src/Models/Notaire.model";
import {NotairesService} from "src/Services/Notaires.service";
import DateHelper from "src/Helpers/Date.helper";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {NotairesConstants} from "../../../Constants/NotairesConstants";


type IReclamationNotairesFormComponentProps = {
    reclamation?: IReclamation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IReclamationNotairesFormData) => Promise<IReclamationGetApiResponse>,
    onSuccessSubmit: (response: IReclamationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IReclamationGetApiResponse) => void,
    onCancel: () => void,
}

export default function ReclamationNotairesFormComponent(props: IReclamationNotairesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response) => {
            if (response && response.datas && response.datas.notaires) {
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });
    }, []);



    useEffect(() => {
        const reclamation: IReclamation = props.reclamation;

        const formElementsColumn1: FormElement[] = [
            {
                type: "selectMultiple",
                fieldName: "notairesIds",
                label: "Notaire.s concerné.s",
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValues: reclamation && reclamation.notaires ? NotairesHelper.getIdsFromList(reclamation.notaires) : [],
                options: notaires ? NotairesHelper.formatListForOptions(notaires, null, NotairesConstants.typesAnciensLight) : [],
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);


    }, [notaires]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IReclamationNotairesFormData).then((response: IReclamationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "Les informations de la réclamation ont bien été modifiées." : "La réclamation a bien été créée.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive de la réclamation.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                notairesLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }
        </div>
    )

}
