import React, {useEffect, useState} from "react";

import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import DateHelper from "src/Helpers/Date.helper";
import {IDocument} from "src/Models/Document.model";
import {OfficesConstants} from "../../../Constants/OfficesConstants";
import SelectFieldComponent from "../../Fields/Select.field.component";
import StringHelper from "../../../Helpers/String.helper";
import {AppConstants} from "../../../Constants/AppConstants";
import DocumentsHelper from "src/Helpers/Documents.helper";


const OfficeDocuments = (props: {
    documents: IDocument[]
}) => {
    const [documentsList, setDocumentsList] = useState<IDocument[]>([]);
    const [documentsFiltered, setDocumentsFiltered] = useState<IDocument[]>([]);
    const [orderDirection, setOrderDirection] = useState<string>(AppConstants.orderType.asc);
    const [orderBy, setOrderBy] = useState<string>('date');
    const stringHelper = new StringHelper();

    useEffect(() => {
        if(props.documents){
            const newDocumentsList: IDocument[] = props.documents;
            for(let doc of newDocumentsList){
                doc.nomCleaned = stringHelper.cleanString(doc.nom);
            }

            setDocumentsList(newDocumentsList);
        }
    }, [props.documents])


    useEffect(() => {
        filterAndOrderDocuments();
    }, [documentsList, orderBy, orderDirection])


    /**
     * Permet le tri et le filtre des documents
     */
    const filterAndOrderDocuments = (): void => {
        const newDocuments = DocumentsHelper.filterAndOrder(documentsList, orderBy, orderDirection)
        setDocumentsFiltered(newDocuments);
    }




    return (
        <>
            {
                documentsList && (documentsList.length > 0) &&
                <div className="list-filters -documents-list">
                    <i className={`icon icon-filtre`}/>

                    <SelectFieldComponent fieldName="vefaDocumentsOrder"
                                          label="Trier par"
                                          options={OfficesConstants.vefaDocumentsOrderOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white`}
                                          oldValue={orderBy ? orderBy : ""}
                                          onChange={(value: string) => setOrderBy(value)}
                    />
                    <SelectFieldComponent fieldName="vefaDocumentsOrderDirection"
                                          label="Ordre du tri"
                                          options={AppConstants.orderDirectionOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white -small-select`}
                                          oldValue={orderDirection ? orderDirection : ""}
                                          onChange={(value: string) => setOrderDirection(value)}
                    />
                </div>
            }
            <div className="informations-wrapper">
                {
                    documentsFiltered && (documentsFiltered.length > 0) && documentsFiltered.map((d, dIndex) =>
                        <div className="columns" key={dIndex}>
                            <p className="title">{ d.nom ? d.nom : DateHelper.getFormatedDate(d.date)}</p>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Date</p>
                                    <p className="value">{d.date ? DateHelper.getFormatedDate(d.date) : '-'}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Fichier</p>
                                    <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(d.fichier)} />
                                </div>
                            </div>
                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Date de dépot de pièce</p>
                                    <p className="value">{d.dateDepot ? DateHelper.getFormatedDate(d.dateDepot) : '-'}</p>
                                </div>
                            </div>
                            <div className="column">

                                <div className="informations-block">
                                    <p className="label">Ville</p>
                                    <p className="value">{d.commentaires || '-'}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
};

export default OfficeDocuments;
