import React, {useEffect, useState} from "react";
import SelectFieldComponent, {IOption} from "./Select.field.component";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {IInspecteur} from "../../Models/Inspecteur.model";
import InspectionsHelper from "../../Helpers/Inspections.helper";
import {INotaire} from "../../Models/Notaire.model";
import {IPersonneExterieure} from "../../Models/PersonneExterieure.model";
import {FormComponentFormData} from "../Forms/FormCreator.component";
import {ICorrespondanceGetApiResponse} from "../../Services/Correspondances.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {IApiCustomResponse, IApiErrorMessage} from "../../Services/Api.service";
import {
    IInspectionGetApiResponse,
    InspectionsService,
    IUpdateInspecteursFormData
} from "../../Services/Inspections.service";
import {toInteger} from "lodash";
import {ICorrespondance} from "../../Models/Correspondance.model";
import {IInspection} from "../../Models/Inspection.model";

export type IInspecteurOption = IOption & {
    type: "inspecteur" | "inspecteurSpec" | "observateur"
}

const SelectMultiInspecteurField = (props: {
    hideForm: any,
    inspectionId: number,
    inspecteursList: INotaire[],
    inspecteursObservateurList: INotaire[],
    inspecteursStarList: IPersonneExterieure[],
    inspecteursSelected: IInspecteur[],
}) => {

    const [inspecteurs, setInspecteurs] = useState<IInspecteurOption[]>([]);
    const [inspecteursList, setInspecteursList] = useState<IInspecteurOption[]>([]);
    const [observateurList, setObservateurList] = useState<IInspecteurOption[]>([]);
    const [inspecteursStarList, setInspecteursStarList] = useState<IInspecteurOption[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);

    useEffect(()=>{
        setInspecteurs(props.inspecteursSelected.map((i)=>{
            return InspectionsHelper.getInspecteurOption(i);
        }));
    },[props.inspecteursSelected]);


    useEffect(()=>{
        setInspecteursList(props.inspecteursList.map((i)=>{
            return InspectionsHelper.getInspecteurOptionFromNotaire(i);
        }));

        setObservateurList(props.inspecteursObservateurList.map((i)=>{
            return InspectionsHelper.getObservateurOptionFromNotaire(i);
        }));

        setInspecteursStarList(props.inspecteursStarList.map((i)=>{
            return InspectionsHelper.getInspecteurOptionFromPersonneExterieure(i);
        }));
    },[props.inspecteursList,props.inspecteursStarList]);



    const getInspecteursSelectOptions = (inspecteur: IInspecteurOption): IInspecteurOption[] => {
        return inspecteur.type === 'inspecteur' ? inspecteursList : (inspecteur.type === 'observateur' ? observateurList : inspecteursStarList);
    };


    const onInspecteurSelected = (type: 'inspecteur' | 'inspecteurSpec' | 'observateur', id: string, index: number) => {
        let inspecteur = null;

        if (type === 'inspecteur') {
            inspecteur = inspecteursList.find((inspecteur) => inspecteur.value === id);
        }
        else if (type === 'observateur') {
            inspecteur = observateurList.find((inspecteur) => inspecteur.value === id);
        }
        else {
            inspecteur = inspecteursStarList.find((inspecteur) => inspecteur.value === id);
        }

        if (inspecteur) {
            const currentInspecteurs = [...inspecteurs];
            currentInspecteurs[index] = inspecteur;
            setInspecteurs(currentInspecteurs);
        }
    };

    const getSelectElement = (options: IInspecteurOption[], selectedInspecteur: IInspecteurOption, index: number) => {
        const uniqueId = generateUniqueID();

        return <div className="select-inspecteur-field -on-white -full">
                <button className="remove" onClick={() => removeInspecteur(index)}><i className="icon icon-remove" /></button>
                {(options && options[0] && options[0].type && options[0].type === "inspecteurSpec") && <i className="icon icon-star"/>}
                {(options && options[0] && options[0].type && options[0].type === "observateur") && <i className="icon icon-voir"/>}


                <SelectFieldComponent
                    modificators="-full"
                    options={options}
                    onChange={(value) => onInspecteurSelected(options[0].type, value, index) }
                    oldValue={selectedInspecteur ? selectedInspecteur.value: ""}
                    key={ uniqueId }
                />
            </div>;
    };

    /**
     * Ajout d'un inspecteur
     *
     * @param {"inspecteur" | "inspecteurSpec" | "observateur"} type
     */
    const addInspecteurSelect = (type: 'inspecteur' | 'inspecteurSpec' | 'observateur'): void => {
        const currentInspecteurs = [...inspecteurs];
        currentInspecteurs.push({
            type,
            value: null,
            label: null
        });

        setInspecteurs(currentInspecteurs);
    };

    /**
     * Suppression d'un inspecteur
     *
     * @param {number} index
     */
    const removeInspecteur = (index: number): void => {
        const currentInspecteurs = [...inspecteurs];
        currentInspecteurs.splice(index, 1);
        setInspecteurs(currentInspecteurs);
    };


    /**
     * Soumission du formulaire
     */
    const onSubmit = (): void => {
        const inspectionService: InspectionsService = new InspectionsService();
        let formData: IUpdateInspecteursFormData = {
            inspecteurs: [],
            inspecteursCompta: [],
            observateurs: [],
        };

        for (let i=0; i < inspecteurs.length; i++) {
            if (inspecteurs[i].type === 'inspecteur') {
                formData.inspecteurs.push(inspecteurs[i].value);
            }
            if (inspecteurs[i].type === 'inspecteurSpec') {
                formData.inspecteursCompta.push(inspecteurs[i].value);
            }
            if (inspecteurs[i].type === 'observateur') {
                formData.observateurs.push(inspecteurs[i].value);
            }
        }

        inspectionService.updateInspecteurs(props.inspectionId, formData).then((response: IInspectionGetApiResponse) => {

            //On reset les erreurs
            setErrorsMessage(null);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La correspondance a bien été mise à jour.",
                type: "success"
            });

            props.hideForm();

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }

            props.hideForm();
        });
    };

    return <div className="select-inspecteur">
        {
            inspecteurs && inspecteurs.map((inspecteur, iIndex) =>
                <div key={iIndex} className="select-inspecteur-row">
                    { getSelectElement(getInspecteursSelectOptions(inspecteur), inspecteur, iIndex) }
                </div>
            )

        }

        <div className="form-actions">
            <button onClick={() => addInspecteurSelect("inspecteur")} className="g-button -add-button -big-picto">Ajouter un inspecteur</button>
            <button onClick={() => addInspecteurSelect("observateur")} className="g-button -add-button -big-picto">Ajouter un observateur</button>
            <button onClick={() => addInspecteurSelect("inspecteurSpec")} className="g-button -add-button -big-picto -with-icon"><i className="picto icon-star"/><span className="text">Ajouter un inspecteur specialisé</span></button>
            <button onClick={() => onSubmit()} className="g-button -add-button -primary"><span className="text">Enregistrer</span></button>
        </div>
    </div>
};

export default SelectMultiInspecteurField;
