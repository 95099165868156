import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type ClassementsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
}
export default function ClassementsExportComponent(props: ClassementsExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Classements"}
                         icon="icon-classements"
                         subTitle={"Export des classements"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl= {`api${AppConstants.pathClassements}/export`}
                         columns={[
                             {
                                 elements: [
                                 ]
                             },
                         ]}
        />
    )
}
