import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideHabilitationsComponent from "src/Components/Aside/AsideHabilitations.component";
import HabilitationsExportComponent from "src/Components/Inspections/Habilitations/HabilitationsExport.component";
import {HabilitationsService, IHabilitationData, IHabilitationsFormData} from "src/Services/Habilitations.service";
import NotairesHelper from "src/Helpers/Notaires.helper";
import moment from "moment";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import SelectMultipleFieldComponent from "src/Components/Fields/SelectMultiple.field.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import HabilitationsCourrierFormComponent
    from "../../../Components/Forms/Inspections/Habilitations/HabilitationsCourrierForm.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";

type IHabilitationsListScreenProps = {

}


const HabilitationsListScreen = (props: IHabilitationsListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const location = useLocation();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathHabilitations);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadingResults, setLoadingResults] = useState<boolean>(false);
    const habilitationsService: HabilitationsService = new HabilitationsService();
    const [typeHabilitation,setTypeHabilitation] = useState<string>("inspecteur");
    const [habilitations,setHabilitations] = useState<IHabilitationData[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [years, setYears] = useState<number[]>([]);
    const [startYear, setStartYear] = useState<number>(moment().year());


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            { text: "Habilitations" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);



    useEffect(()=>{
        if(!filters) return null;

        if(typeHabilitation !== "courriers"){
            launchFilteredSearch();
        }
    },[filters, typeHabilitation]);

    useEffect(() => {
        if(!habilitations || !habilitations.length) return;

        const habilitation1 = habilitations[0];
        let newStartYear = moment().year();
        if(habilitation1.gotHabilitationNextYear){
            newStartYear++;
        }

        const newYears: number[] = [];
        for(let i = newStartYear-4; i <= newStartYear; i++){
            newYears.push(i);
        }
        setYears(newYears);
        setStartYear(newStartYear);



    }, [habilitations]);


    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setHabilitations([]);
        setPaginationLinks(null);
        setLoadingResults(true);

        habilitationsService.getHabilitations({...filters, typeHabilitation: typeHabilitation} as IHabilitationsFormData).then((result)=>{
            if(result.datas && result.datas.pagination && result.datas.pagination.data){
                setHabilitations(result.datas.pagination.data);
                setPaginationLinks(result.datas.pagination.links);
                setLoadingResults(false);
            }
        });

        if (!loaded) {
            setLoaded(true);
        }
    };

    const onCheckboxChange = (id:string, year: string, isActive: string) => {
        habilitationsService.updateHabilitation({year,isActive} as IHabilitationsFormData,id);
    };



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Habilitations"
                    icon="icon-habilitations"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
						<TabsContainerComponent onClickTabCallback={(slug)=>setTypeHabilitation(slug)}>
							<TabPanelComponent label="Liste inspecteurs" icon="icon-inspecteurs" slug="inspecteur">
								<div className="list-content -right-gutter">
									<div className="list-filters">
										<i className={`icon icon-filtre`} />
										<SelectMultipleFieldComponent fieldName="departement"
										                              label="Départements"
										                              options={OfficesConstants.departementsOptions}
										                              hideCurrentValuesOnTop={true}
										                              errors={null}
										                              placeholder="Tous les départements"
										                              hideSearch={true}
										                              modificators={`-inline -on-white`}
										                              oldValues={filters.departement ? filters.departement as string[] : []}
										                              onChange={(values: string[]) => updateFilters({departement: values})}
										/>
										<SelectMultipleFieldComponent fieldName="type"
										                              label="Type"
										                              optionsGroup={NotairesConstants.typesOptions}
										                              hideCurrentValuesOnTop={true}
										                              errors={null}
										                              placeholder="Tous les types"
										                              hideSearch={true}
										                              modificators={`-inline -on-white`}
										                              oldValues={filters.type ? filters.type as string[] : []}
										                              onChange={(values: string[]) => updateFilters({type: values})}
										/>
									</div>

                                    {
                                        !loadingResults &&
                                        <table className={`list-table`}>
                                            <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {value: "nom", label: "Nom"},
                                                    {value: "type", label: "Type"},
                                                    {value: "departement", label: "Département"},
                                                    {value: "ville", label: "Ville"}
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.value}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                                {years.map((y, yIndex) => <th key={yIndex} className="th">
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={`annee${y}`}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={y.toString()}
                                                        click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                habilitations.map((h, hIndex) =>
                                                    <tr key={hIndex}>
                                                        <td className="td">
                                                            <p>
                                                                {h.nom}<br/>
                                                                <span className="habilitation-date-serment">Date de serment : {h.dateSermentInitial ? DateHelper.formatDate(h.dateSermentInitial, "DD/MM/YYYY") : ''}</span>
                                                            </p>
                                                        </td>
                                                        <td className="td">{NotairesHelper.getFormattedType(h.type)}</td>
                                                        <td className="td">{h.departement}</td>
                                                        <td className="td -uppercase">{h.ville}</td>

                                                        {
                                                            years.map((y, yIndex) =>
                                                                <td key={yIndex} className="td">
                                                                    <HabilitationCheckbox currentYear={startYear}
                                                                                          disabled={y <= h.lastYearBLocked}
                                                                                          onChange={(isActive) => onCheckboxChange(h.id.toString(), y.toString(), isActive ? "1" : "0")}
                                                                                          h={h} y={y}/>
                                                                </td>
                                                            )
                                                        }
                                                    </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        loadingResults &&
                                        <LoadingComponent />
                                    }

									<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

									<AsideHabilitationsComponent type="list" filters={filters} exportCallback={launchFilteredSearch}/>
								</div>
							</TabPanelComponent>



							<TabPanelComponent label="Liste comptabilité" icon="icon-comptabilite" slug="comptabilite">
								<div className="list-content -right-gutter">
									<div className="list-filters">
										<i className={`icon icon-filtre`} />
										<SelectMultipleFieldComponent fieldName="departement"
										                              label="Départements"
										                              options={OfficesConstants.departementsOptions}
										                              hideCurrentValuesOnTop={true}
										                              errors={null}
										                              placeholder="Tous les départements"
										                              hideSearch={true}
										                              modificators={`-inline -on-white`}
										                              oldValues={filters.departement ? filters.departement as string[] : []}
										                              onChange={(values: string[]) => updateFilters({departement: values})}
										/>
										<SelectMultipleFieldComponent fieldName="type"
										                              label="Type"
										                              optionsGroup={NotairesConstants.typesOptions}
										                              hideCurrentValuesOnTop={true}
										                              errors={null}
										                              placeholder="Tous les types"
										                              hideSearch={true}
										                              modificators={`-inline -on-white`}
										                              oldValues={filters.type ? filters.type as string[] : []}
										                              onChange={(values: string[]) => updateFilters({type: values})}
										/>
									</div>

                                    {
                                        !loadingResults &&
                                        <table className={`list-table`}>
                                            <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {value:"nom",label:"Nom"},
                                                    {value:"type",label:"Type"},
                                                    {value:"departement",label:"Département"},
                                                    {value:"ville",label:"Ville"}
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.value} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                                {years.map((y,yIndex)=><th key={yIndex} className="th">
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={`annee${y}`} selectedValue={filters.orderBy as string || ""}
                                                        label={y.toString()} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                habilitations.map((h,hIndex)=>
                                                    <tr key={hIndex}>
                                                        <td className="td">
                                                            <p>
                                                                {h.nom}<br/>
                                                                <span className="habilitation-date-serment">Date de serment : {h.dateSermentInitial ? DateHelper.formatDate(h.dateSermentInitial, "DD/MM/YYYY") : ''}</span>
                                                            </p>
                                                        </td>
                                                        <td className="td">{NotairesHelper.getFormattedType(h.type)}</td>
                                                        <td className="td">{h.departement}</td>
                                                        <td className="td">{h.ville}</td>

                                                        {
                                                            years.map((y,yIndex)=>
                                                                <td key={yIndex} className="td">
                                                                    <HabilitationCheckbox currentYear={startYear} disabled={y <= h.lastYearBLocked} onChange={(isActive)=>onCheckboxChange(h.id.toString(),y.toString(),isActive ? "1" : "0")} h={h} y={y} />
                                                                </td>
                                                            )
                                                        }
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        loadingResults &&
                                        <LoadingComponent />
                                    }



									<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>


									<AsideHabilitationsComponent type="list" filters={filters}  exportCallback={launchFilteredSearch} />
								</div>
							</TabPanelComponent>



                            <TabPanelComponent label="Courriers" icon="icon-courrier">
                                <div className="fiche-content -right-gutter">
                                    <div className="g-content">
                                        <HabilitationsCourrierFormComponent />
                                    </div>
                                </div>
                            </TabPanelComponent>

						</TabsContainerComponent>
					</>
                }
            </div>

        </LayoutComponent>
    )
};

export default HabilitationsListScreen;

const HabilitationCheckbox = (props:{
    h: IHabilitationData,
    y: number,
    currentYear: number,
    disabled?: boolean,
    onChange: (isActive: boolean)=>void
}) => {

    const {h,y,currentYear} = props;

    return <div className={`habilitation-checkbox `}>
        <label htmlFor={`${h.id}_${y}_checkbox`} className={`label ${props.disabled ? '-disabled': ''}`}>
            <input key={h.id} id={`${h.id}_${y}_checkbox`} type="checkbox" defaultChecked={h[`annee${y}`] ? true : false} onChange={(e)=>props.onChange(e.currentTarget.checked)} className="field" disabled={props.disabled}/>
            <span className="icon icon-check"></span>
        </label>
    </div>
}
