import React, {ChangeEvent, useEffect, useState} from "react";
import {IFormField} from "./Input.field.component";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "../../Services/Api.service";
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";


type PasswordFieldProps = IFormField & {
    onChange: (value: string) => void,
}

export default function PasswordFieldComponent(props: PasswordFieldProps){
    const [errors, setErrors] = useState<IApiErrorMessage>(null);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);



    /**
     * Ecoute du onChange sur le champ
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value: string|number = e.target.value;
        props.onChange(value);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    }

    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                <input className="form-field-input input" name={props.fieldName} type="password" placeholder={props.placeholder} required={props.required} onChange={onChangeInput} />
            </div>

            {
                props.showFieldErrorDetail &&
                <ErrorFieldsMessagesComponent fieldsErrorsMessages={errors} />
            }
        </div>
    )
}
