import React from "react";
import {IOffice} from "src/Models/Office.model";
import {Link} from "react-router-dom";
import {AppConstants} from "src/Constants/AppConstants";
import AdressesHelper from "src/Helpers/Adresses.helper";
import {ICoordonnee} from "src/Models/Coordonnee.model";
import CoordonneesHelper from "src/Helpers/Coordonnees.helper";
import OfficesHelper from "src/Helpers/Offices.helper";

type ICotisationsOfficeResumeComponent = {
    office: IOffice,
}

const CotisationsOfficeResumeComponent = (props: ICotisationsOfficeResumeComponent ) => {
    const {office} = props;
    if( !office ) return;

    return (
        <div className="informations-wrapper">
            {
                office &&
                <>
                    <div className="columns">
                        <p className="title"><Link to={`${AppConstants.pathOffices}/${office.id}`} target="_blank" rel="noopener noreferrer">{ office.nom}</Link></p>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">CRPCEN</p>
                                <p className="value">{office.CRPCEN}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Office</p>
                                <p className="value"><Link to={`${AppConstants.pathOffices}/${office.id}`} target="_blank" rel="noopener noreferrer" >{ office.nom}</Link></p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{office.type ? OfficesHelper.getFormatedType(office.type) : "-"}</p>
                            </div>
                        </div>
                    </div>

                    {
                        office.adressePrincipale &&
                        <div className="columns" >
                            <p className="title">Adresse</p>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Rue</p>
                                    <p className="value">{office.adressePrincipale.ligne1 ? office.adressePrincipale.ligne1: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Code Postal</p>
                                    <p className="value">{office.adressePrincipale.codePostal ? office.adressePrincipale.codePostal: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Tribunal Judiciaire</p>
                                    <p className="value">{office.adressePrincipale.tribunalJudiciaire ? AdressesHelper.getFormatedTribunal(office.adressePrincipale.tribunalJudiciaire): "-"}</p>
                                </div>
                            </div>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Complément d'adresse</p>
                                    <p className="value">{office.adressePrincipale.ligne2 ? office.adressePrincipale.ligne2: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Ville</p>
                                    <p className="value -uppercase">{office.adressePrincipale.ville ? office.adressePrincipale.ville: "-"}</p>
                                </div>
                            </div>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Boîte Postale</p>
                                    <p className="value">{office.adressePrincipale.boitePostale ? office.adressePrincipale.boitePostale: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Arrondissement</p>
                                    <p className="value">{office.adressePrincipale.arrondissement ? AdressesHelper.getFormatedArrondissement(office.adressePrincipale.arrondissement): "-"}</p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        office.coordonnees && OfficesHelper.getComptabiliteCoordonnees(office.coordonnees).map((coordonnee: ICoordonnee, index: number) =>
                            <div className="columns" key={index} >
                                <p className="title">Comptabilité</p>

                                <div className="column">
                                    <div className="informations-block">
                                        <p className="label">Type</p>
                                        <p className="value">{coordonnee.type ? CoordonneesHelper.getFormatedType(coordonnee.type) : "-"}</p>
                                    </div>
                                </div>


                                <div className="column">
                                    <div className="informations-block">
                                        <p className="label">Détail</p>
                                        <p className="value">
                                            {
                                                coordonnee.coordonnees && (coordonnee.type === 'url') &&
                                                <a href={coordonnee.coordonnees} target="_blank" rel="noopener noreferrer" className={"link"} >{coordonnee.coordonnees}</a>
                                            }
                                            {
                                                coordonnee.coordonnees && (coordonnee.type === 'telephone') &&
                                                <>{CoordonneesHelper.formatPhoneNumberForDisplay(coordonnee.coordonnees)}</>
                                            }
                                            {
                                                coordonnee.coordonnees && (coordonnee.type !== 'url'&& coordonnee.type !== 'telephone') &&
                                                <>{coordonnee.coordonnees}</>
                                            }
                                            {
                                                !coordonnee.coordonnees && "-"
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>
            }
        </div>
    )
}

export default CotisationsOfficeResumeComponent;
