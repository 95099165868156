import {ICoordonnee} from "src/Models/Coordonnee.model";
import CoordonneesHelper from "src/Helpers/Coordonnees.helper";
import {IReclamationPlaignant} from "src/Models/ReclamationPlaignant.model";

type IReclamationCoordonneesPlaignantsComponentProps = {
    plaignants: IReclamationPlaignant[],
    coordonnees: ICoordonnee[],
}

export default function ReclamationCoordonneesPlaignantsComponent(props: IReclamationCoordonneesPlaignantsComponentProps) {
    const plaignants: IReclamationPlaignant[] = props.plaignants;


    /**
     * Permet la récupération des noms des plaignants d'une coordonnée
     *
     * @param {ICoordonnee} coordonnee
     * @returns {string}
     */
    const getPlaignantNomForCoordonnee = (coordonnee: ICoordonnee) => {
        const plaignantIds: string[] = coordonnee.plaignantId;
        const plaignantNom: string[] = [];

        const plaignantsFound = plaignants.filter( (p) => plaignantIds.includes(p.id));
        for(let p of plaignantsFound){
            plaignantNom.push(`${p.nom} ${p.prenom}`);
        }

        return plaignantNom.join(' et ');
    };



    return (
        <div className="informations-wrapper">
            {
                props.coordonnees && props.coordonnees.map((coordonnee: ICoordonnee, index: number) =>
                    <div className="columns" key={"c_" + index}>
                        <p className="title  -alternative">{getPlaignantNomForCoordonnee(coordonnee)}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{coordonnee.type ? CoordonneesHelper.getFormatedType(coordonnee.type) : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Détail</p>
                                <p className="value">
                                    {
                                        coordonnee.coordonnees && (coordonnee.type === 'url') &&
                                        <a href={coordonnee.coordonnees} target="_blank" rel="noopener noreferrer"
                                           className={"link"}>{coordonnee.coordonnees}</a>
                                    }
                                    {
                                        coordonnee.coordonnees && (coordonnee.type === 'telephone') &&
                                        <>{CoordonneesHelper.formatPhoneNumberForDisplay(coordonnee.coordonnees)}</>
                                    }
                                    {
                                        coordonnee.coordonnees && (coordonnee.type !== 'url' && coordonnee.type !== 'telephone') &&
                                        <>{coordonnee.coordonnees}</>
                                    }
                                    {
                                        !coordonnee.coordonnees && "-"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!plaignants || (plaignants.length === 0)) &&
                    <>-</>
            }
        </div>
    );
}
