import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {CtrcsService, ICtrcGetApiResponse, ICtrcInformationsFormData,} from "src/Services/Ctrcs.service";
import {ICtrc} from "src/Models/Ctrc.model";
import CtrcInformationsFormComponent from "../../Components/Forms/Ctrcs/CtrcInformationsForm.component";

type ICtrcCreationScreenProps = {}
export default function CtrcCreationScreen(props: ICtrcCreationScreenProps) {
    const history = useHistory();
    const ctrcsService: CtrcsService = new CtrcsService();

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: ICtrcGetApiResponse): void => {
        if (response.datas && response.datas.ctrc) {
            const ctrc: ICtrc = response.datas.ctrc;
            history.push(AppConstants.pathCtrcs + '/' + ctrc.id);
        } else {
            history.push(AppConstants.pathCtrcs);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathCtrcs);

    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                         contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un CTRC" icon="icon-ctrcs"
                                 modificators={`-in-fullscreen -border-full-screen`}/>

            <CtrcInformationsFormComponent
                isOnCreationStep={true}
                submitMethod={(formData: ICtrcInformationsFormData) => ctrcsService.create(formData)}
                onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm}/>
        </LayoutComponent>
    )
}
