import React, {useEffect, useState} from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {NotairesService} from "../../Services/Notaires.service";
import {INotaire} from "../../Models/Notaire.model";
import NotairesHelper from "../../Helpers/Notaires.helper";
import {DifferendsConstants} from "../../Constants/DifferendsConstants";

type DifferentsAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,
}
export default function DifferentsAdvancedSearchComponent(props: DifferentsAdvancedSearchComponentProps) {

    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);

    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response) => {
            if (response && response.datas && response.datas.notaires) {
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });
    }, []);


    return (
        <>
            {
                notairesLoaded && <AdvancedSearchComponent
					title="Différends"
					showSummary={props.showSummary}
					modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
                    showAdvancedSearch={props.showAdvancedSearch}
                    filters={props.filters}
					columns={[
                        {
                            elements: [
                                {
                                    type: 'hidden',
                                    fieldName: "advanced",
                                    oldValue: "1",
                                    modificators: "-hidden"
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "diff_notaireId_plaignant",
                                    label: "Notaire.s plaignant.s",
                                    modificators:"-on-white",
                                    oldValues: props.filters.diff_notaireId_plaignant as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "diff_notaireId_misEnCause",
                                    label: "Notaire.s mis en cause.s",
                                    modificators:"-on-white",
                                    oldValues: props.filters.diff_notaireId_misEnCause as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "diff_objet",
                                    label: "Objets",
                                    hideSearchOnMultiple: true,
                                    modificators:"-on-white",
                                    oldValues: props.filters.diff_objet as string[] || [],
                                    options: DifferendsConstants.subjects,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "diff_etat",
                                    label: "Etats",
                                    hideSearchOnMultiple: true,
                                    modificators:"-on-white",
                                    oldValues: props.filters.diff_etat as string[] || [],
                                    options: DifferendsConstants.states,
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: 'text',
                                    label: "Nom du plaignant",
                                    fieldName: "diff_nom_plaignant",
                                    oldValue: props.filters.diff_nom_plaignant as string || "",
                                    modificators:"-on-white",
                                },
                                {
                                    type: 'text',
                                    label: "Nom du mis en cause",
                                    fieldName: "diff_nom_misEnCause",
                                    oldValue: props.filters.diff_nom_misEnCause as string || "",
                                    modificators:"-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date de réception (min)",
                                    fieldName: "dateReceptionMin",
                                    oldValue: props.filters.dateReceptionMin as string || "",
                                    modificators:"-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date de réception (max)",
                                    fieldName: "dateReceptionMax",
                                    oldValue: props.filters.dateReceptionMax as string || "",
                                    modificators:"-on-white",
                                }
                            ]
                        }
                    ]}
					onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
					onClose={() => props.setShowAdvancedSearch()}
					onSearch={props.onSearch}
				/>
            }
        </>
    )

}
