import React, {useEffect, useRef, useState} from "react";

import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {AppConstants} from "src/Constants/AppConstants";
import {ILettreType} from "src/Models/LettreType.model";
import useLettreType from "src/Hooks/UseLettreType";
import FormationCourrierUrssafFormComponent
    from "../../../Components/Forms/Formations/Formations/FormationCourrierUrssafForm.component";

export type ICourrierUrssafScreenProps = {
}

export default function CourrierUrssafScreen(props: ICourrierUrssafScreenProps){
    const [loaded, setLoaded] = useState<boolean>(false);
    const [getLettreTypeById] = useLettreType();
    const [lettreType, setLettreType] = useState<ILettreType>(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);

    useEffect(() => {
        getLettreType();
    }, [])


    useEffect(() => {
        if(!lettreType) return;

        const breadcrumbLinks = [
            {link: AppConstants.pathMosaique + '#formations', text: "Formations"},
            { text: "Courrier Urssaf" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [lettreType, loaded]);


    /**
     * Récupération de la lettreType spécifique au courrier URSSAF
     */
    const getLettreType = (): void => {
        getLettreTypeById(15, (l: ILettreType) => {
            setLettreType(l);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={lettreType.nom} icon="icon-courrier" modificators="" onSearch={null}/>
                    <TabsContainerComponent>
                        <TabPanelComponent label="Courrier" icon="icon-lettre-type">
                                <FormationCourrierUrssafFormComponent lettreType={lettreType} />
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}

