import React, {useEffect, useState} from "react";
import LayoutComponent from "../../../Components/Layout/Layout.component";
import PageHeaderComponent from "../../../Components/Page/PageHeader.component";
import useListFilters, {IFilterData} from "../../../Hooks/FiltersHandler";
import {useHistory, useLocation} from "react-router";
import TabsContainerComponent, {TabPanelComponent} from "../../../Components/Tabs/TabsContainer.component";
import {
    FormationsService,
    IFormationsGetApiResponse,
    IFormationsGetFormData
} from "../../../Services/Formations.service";
import TableHeaderButtonComponent from "../../../Components/Lists/TableHeaderButton.component";
import {AppConstants, TabsSlugs} from "../../../Constants/AppConstants";
import PaginationComponent from "../../../Components/Pagination/Pagination.component";
import {IFormation} from "../../../Models/Formation.model";
import {IApiPaginationLink} from "../../../Services/Api.service";
import DateHelper from "../../../Helpers/Date.helper";
import AsideFormationComponent from "../../../Components/Aside/AsideFormations.component";
import FormationsExportComponent from "../../../Components/Formations/Formations/FormationsExport.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";

const FormationsListScreen = () => {

    const [filters,initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathFormations);
    const history = useHistory();
    const location = useLocation();
    const [showExport, setShowExport] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [formations,setFormations] = useState<IFormation[]>([]);
    const [formationsPassees,setFormationsPassees] = useState<IFormation[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [paginationLinksPassees, setPaginationLinksPassees] = useState<IApiPaginationLink[]>([]);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.A_VENIR);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(initialFilters, false, true);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        const formationsService: FormationsService = new FormationsService();

        // A venir
        if(activeTab === TabsSlugs.A_VENIR){
            formationsService.getFormations(filters as IFormationsGetFormData).then((response: IFormationsGetApiResponse)=>{

                if (response && response.datas.pagination) {

                    setFormations(response.datas.pagination.data);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }

        // Passees
        if(activeTab === TabsSlugs.PASSEES){
            formationsService.getFormations({...filters,old: 1} as IFormationsGetFormData).then((response: IFormationsGetApiResponse)=>{

            if (response && response.datas.pagination) {

                setFormationsPassees(response.datas.pagination.data);

                // Pagination
                setPaginationLinksPassees(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
        }
    };

    return <>
        <LayoutComponent showMenu={true} wrapperModificators={`${showExport ? '-no-scroll' : ''}`}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent breadcrumbLinks={[
                    {text: "Formations", link: AppConstants.pathMosaique + "#formations"},
                    {text: "Liste des formations"},
                                     ]}
                                     text="Formations"
                                     icon="icon-univers-formations"
                                     onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}/>
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
		            <>
                        <TabsContainerComponent defaultTab={TabsSlugs.A_VENIR} onClickTabCallback={(tab)=>{updateFilters({},true); setActiveTab(tab)}}>
                            <TabPanelComponent label="À venir" icon="icon-a-venir" slug={TabsSlugs.A_VENIR}>
                                <div className={`list-content -right-gutter`}>
                                    <FormationsListTableComponent filters={filters} formations={formations} setOrder={setOrder} isOld={false}/>
	                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

	                                <AsideFormationComponent type={"list"} exportCallback={() => setShowExport(true)} filters={filters}  />
                                </div>
                            </TabPanelComponent>
                            <TabPanelComponent label="Passées" icon="icon-termine" slug={TabsSlugs.PASSEES}>
                                <div className={`list-content -right-gutter`}>
	                                <FormationsListTableComponent filters={filters} formations={formationsPassees} setOrder={setOrder} isOld={true}/>
	                                <PaginationComponent links={paginationLinksPassees} onPageClick={page => updateFilters({page})}/>

	                                <AsideFormationComponent type={"list"} exportCallback={() => setShowExport(true)} filters={{...filters,old: 1} as IFormationsGetFormData} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            <FormationsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} activeTab={activeTab} filters={filters} />
        </LayoutComponent>
    </>
};

const FormationsListTableComponent = (props:{formations: IFormation[], filters: IFilterData, setOrder:(value:string)=>void, isOld:boolean}) => {
    const history = useHistory();

    console.log(props.formations);

    return <table className={`list-table`}>
        <thead className={"headers"}>
        <tr>
            {[
                {slug: "etat", label: "Etat"},
                {slug: "date", label: "Date"},
                {slug: "titre", label: "Libellé"},
                {slug: "nbInscrits", label: "Nb Inscrits"},
            ].map((item, iIndex) => <th
                className="th" key={iIndex}>
                <TableHeaderButtonComponent
                    direction={props.filters.orderType as string || AppConstants.orderType.asc}
                    value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                    label={item.label} click={(value: string) => props.setOrder(value)}/>
            </th>)}
        </tr>
        </thead>
        <tbody>
        {
            props.formations && props.formations.map((formation: IFormation, index: number) =>
                <tr key={index}
                    onClick={() => history.push(`${AppConstants.pathFormations}/${formation.id}`)}
                    className="tr">
                    <td className="td -bullet">{props.isOld ? <span className={`state-bullet icon-check`}></span> : <span className={`state-bullet icon-bullet -${formation.etat}`}>&nbsp;</span>}</td>
                    <td className="td">{DateHelper.getFormatedDate(formation.date)}</td>
                    <td className="td">
                        {formation.titre}
                    </td>
                    <td className="td">
                        {formation.nbInscrits}
                    </td>
                </tr>
            )
        }
        </tbody>
    </table>
};

export default FormationsListScreen;
