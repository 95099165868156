import React, {useEffect, useState} from "react";
import {IStatistiques} from "../../../Models/Statistiques.model";
import CommonHelper from "../../../Helpers/Common.helper";
import {IOffice} from "../../../Models/Office.model";
import NumberHelper from "../../../Helpers/Number.helper";
import {StatistiquesConstants} from "../../../Constants/StatistiquesConstants";
import StatistiquesHelper from "../../../Helpers/Statistiques.helper";


const StatistiquesTable = (props:{
    stats: IStatistiques[],
    office: IOffice,
    firstYear: number,
    editMode: boolean,
    onValueChange: (sid: string,path: string, value:string) => void,
    onEditModeChange: (isActive:boolean) => void,
    onSubmit: () => void,
}) => {

    const getRowFromStats = (isHeader: boolean, label:string, path: any, defaultValue: string = "", operator?: "ratio"|"divide"|"add"|"substract"|"empty"|"actions"|"string",finalPath?: string,isReadOnly: boolean = false) => {
        const CellComponent = isHeader ? 'th' : 'td';
        const classNamesCell = isHeader ? 'th' : `td -right`;
        const classNamesInnerWrapper = isHeader ? 'link' : '';

        return <tr>
            <CellComponent className={classNamesCell}>
                {operator === "actions" && <>
	                <div className={`form-actions -stats-table`}>
		                <button className={`g-button -primary`} onClick={(e) => props.onSubmit()} >
                            Enregistrer
		                </button>
		                <button className={`g-button -is-link`} onClick={(e) => props.onEditModeChange(false)} >
                            Annuler
		                </button>
	                </div>
                </>}

                {operator !== "actions" && <span className={classNamesInnerWrapper}>
                    {label}
                </span>}
            </CellComponent>
            {props.stats.map((s: IStatistiques,sIndex:number)=>{

                // Si on a une date plus ancienne que la première année à afficher, on passe
                if(s.dapYear < props.firstYear) return null;

                let officeCode = CommonHelper.getObjectValueFromPath(s,"data.dap.code_office_csn");

                if(!officeCode){
                    officeCode = "csn_empty";
                }

                if(Array.isArray(path)){
                    path = path.map((item)=>{
                        if(Array.isArray(item)){
                            return item.map((subitem)=>subitem.replace("*",officeCode).replace("csn_empty",officeCode));
                        }else{
                            return item.replace("*",officeCode).replace("csn_empty",officeCode);
                        }
                    });
                }
                else{
                    path = path.replace("*",officeCode).replace("csn_empty",officeCode);;
                }


                let value: number = 0;
                let suffix:string = "";
                if(operator === "ratio"){
                    if(!Array.isArray(path) || (Array.isArray(path) && path.length !== 2) ) return null;

                    let v1: number = 0;
                    if(Array.isArray(path[0])){
                        for(let p of path[0]){
                            v1 += CommonHelper.getObjectValueFromPath(s,p);
                        }
                    }else{
                        v1 = CommonHelper.getObjectValueFromPath(s,path[0]);
                    }
                    const v2 = CommonHelper.getObjectValueFromPath(s,path[1]);
                    value = (v1*100)/v2;
                    suffix = "%";
                }
                else if(operator === "substract"){
                    if(!Array.isArray(path) || (Array.isArray(path) && path.length !== 2) ) return null;
                    const v1 = CommonHelper.getObjectValueFromPath(s,path[0]);
                    const v2 = CommonHelper.getObjectValueFromPath(s,path[1]);
                    value = v1-v2;
                }
                else if(operator === "divide"){
                    if(!Array.isArray(path) || (Array.isArray(path) && path.length !== 2) ) return null;
                    const v1 = CommonHelper.getObjectValueFromPath(s,path[0]);
                    const v2 = CommonHelper.getObjectValueFromPath(s,path[1]);
                    value = v1/v2;
                }else{
                    if(Array.isArray(path)){
                        for(let p of path){
                            value += parseFloat(CommonHelper.getObjectValueFromPath(s,p)) ?? 0;
                        }
                    }else{
                        value = CommonHelper.getObjectValueFromPath(s,path) ?? 0 ;
                    }
                }

                // Si il y a un final path on essaie de voir si une valeur existe déjà
                if(finalPath){
                    const finalValue = CommonHelper.getObjectValueFromPath(s,finalPath);
                    value = finalValue || value;
                }
                

                if( operator === "actions" ) return null;

                return <CellComponent className={classNamesCell} key={sIndex}>
                    <span className={classNamesInnerWrapper}>
                        {!isHeader && !isReadOnly && operator !== "empty"&& operator !== "divide" && operator !== "ratio" && operator !== "string"  && props.editMode && <input defaultValue={value ? parseFloat(value.toString()).toFixed(0) : ""}  onChange={(e) => props.onValueChange(s.id as string,(finalPath || path) as string,e.currentTarget.value)}/>}
                        {!isHeader && !isReadOnly && operator !== "empty"&& operator === "string"  && props.editMode && <input defaultValue={value || ""}  onChange={(e) => props.onValueChange(s.id as string,(finalPath || path) as string,e.currentTarget.value)}/>}
                        {(isHeader || isReadOnly || operator === "empty"|| operator === "divide" || operator === "ratio" || !props.editMode ) && <>
                            { value ? ((isHeader || operator === "string") ? value : NumberHelper.formatThousand(value, 0 )) : defaultValue }{suffix}
                        </>}
                    </span>
                </CellComponent>
            })}
        </tr>
    };

    return <div className={`statistiques-table-wrapper ${props.editMode ? '-edit' : ''}`}>
        <table className={`list-table -stats`}>
            <thead className={"headers -stats -with-color"}>
                {getRowFromStats(true,"Déclaration CSN","dapYear")}
            </thead>
            <tbody className={"body -stats -with-color"}>
            {getRowFromStats(false,"Régime fiscal","data.dap.regime_fiscal","", "string")}
            {/* Equivalent temps plein */}
            {getRowFromStats(false,"Equivalent temps plein","nombreSalaries","0")}
            {/* Nombre d'actes */}
            {getRowFromStats(false,"Nombre d'actes","nombreActe","0")}
            {/* Nombre de DS */}
            {getRowFromStats(false,"Nombre de DS","nombreSuccessions","0")}
            {/* Produits Courants */}
            {getRowFromStats(false,"Produits Courants","produitCourant","0")}
            {/* Charges Courantes */}
            {getRowFromStats(false,"Charges Courantes","data.office.*.charges_courantes_iiiv","0")}

            {/* Affichage different selon le type de société */}
            {
                StatistiquesHelper.isOfficeWithImpotsSociete(props.office, props.stats) &&
                <>
                    {getRowFromStats(false,"Résultat Courant","data.office.*.3resultat_courant_i_iiiii_iv","0")}
                    {getRowFromStats(false,"Résultat Courant (Brut de rémunération)",["data.office.*.3resultat_courant_i_iiiii_iv", 'data.office.*.dap_volet_7_bremuneration_des_notaires_associes_en_sel'],"0", "add")}
                    {getRowFromStats(false,"Rémunération notaires associés en SEL",'data.office.*.dap_volet_7_bremuneration_des_notaires_associes_en_sel',"0", )}
                </>
            }
            {
                (!StatistiquesHelper.isOfficeWithImpotsSociete(props.office, props.stats)) &&
                <>
                    {/* Résultat Courant */}
                    {getRowFromStats(false,"Résultat Courant","data.office.*.3resultat_courant_i_iiiii_iv","0")}
                </>
            }

            {/* Résultat de l'Exercice */}
            {getRowFromStats(false,"Résultat de l'Exercice","data.office.*.5resultat_de_lexercice_benefice_ou_perte_a_precisier_ou","0")}
            {/* Prélèvements */}
            {getRowFromStats(false,"Prélèvements","prelevements","0")}
            {/* Honoraires */}
            {getRowFromStats(false,"Honoraires","montantsHonoraires","0")}
            {/* Frais de personnel*/}
            {getRowFromStats(false,"Frais de personnel",["data.office.*.remuneration_de_personnel_sauf_64113_et_64114","data.office.*.remuneration_des_notaires_salaries","data.office.*.charges_sociales_pour_le_personnel","data.office.*.autres_charges_sociales_et_de_personnel"],"0","add","data.frais_de_personnel")}
            {/* Clients débiteurs */}
            {getRowFromStats(false,"Clients débiteurs","data.office.*.creances_client_et_comptes_rattaches_nt","0")}
            {/* Montant émoluments de formalités */}
            {getRowFromStats(false,"Montant émoluments de formalités","data.office.*.emoluments_fixes_de_formalites_sauf_706129","0")}
            {/* Montant écrêtement */}
            {getRowFromStats(false,"Montant écrêtement",["data.office.*.ecretement_sur_emoluments_de_formalites","data.office.*.ecretement_sur_emoluments_proportionnels_de_ventes","data.office.*.dap_volet_6ecretement_sur_emoluments_proportionnels_divers"],"0","add","data.montant_ecretement")}
            {/* Montant remises */}
            {getRowFromStats(false,"Montant remises",["data.office.*.remises_totales_demoluments_et_honoraires_aux_proches_parents_et_collaborateurs","data.office.*.remises_totales_demoluments_et_honoraires_aux_clients","data.office.*.remises_partielles_demoluments_aux_proches_parents_et_collaborateurs"], "0","add","data.montant_remises")}
            {/* Montant remises partielles 10% max. */}
            {getRowFromStats(false,"Montant remises partielles 10% max.",["data.office.*.remises_partielles_demoluments_aux_proches_parents_et_collaborateurs_10_maximum","data.office.*.remises_partielles_demoluments_aux_proches_parents_et_collaborateurs_40_maximum","data.office.*.remises_partielles_demoluments_aux_clients_10_maximum","data.office.*.remises_partielles_demoluments_aux_clients_40_maximum"], "0","add","data.montant_remises_partielles_10%_max")}

            {getRowFromStats(false,"","", "","empty")}
            </tbody>

            <thead className={"headers -stats -with-color"}>
                {getRowFromStats(true,"Honoraires","dapYear")}
            </thead>
            <tbody className={"body -stats -with-color"}>
                {/* Honoraires de négociation */}
                {getRowFromStats(false,"Honoraires de négociation","data.office.*.total_des_honoraires_de_negociation", "0")}
                {/* Honoraires de transaction */}
                {getRowFromStats(false,"Honoraires de transaction","montantsHonorairesTransaction", "0")}
                {/* Négociation Fonds de Commerce */}
                {getRowFromStats(false,"Honoraires de négociation Fonds de Commerce","data.office.*.honoraires_de_negociation_de_baux_commerciaux_et_de_ventes_de_fonds_de_commerce", "0")}
                {/* Ventes Fonds de Commerce */}
                {getRowFromStats(false,"Ventes Fonds de Commerce","data.office.*.ventes_fonds_de_commerce", "0")}
                {/* Baux Commerciaux */}
                {getRowFromStats(false,"Baux Commerciaux",["data.office.*.baux_commerciaux", "data.office.*.activite_de_baux_commerciaux"], "0", 'add')}
                {/* Droits spécifiques des Sociétés */}
                {getRowFromStats(false,"Droits spécifiques des Sociétés","data.office.*.droits_specifiques_des_societes_contrats_de_societe_consultations_statuts", "0")}
                {/* Louage d'ouvrages et industrie */}
                {getRowFromStats(false,"Louage d'ouvrages et industrie","data.office.*.contrats_de_louage_douvrage_et_dindustrie", "0")}
                {/* Adjudications mobilières */}
                {getRowFromStats(false,"Adjudications mobilières","data.office.*.ventes_par_adjudication_volontaire_de_meubles", "0")}
                {/* Droits de recettes - Gérance */}
                {getRowFromStats(false,"Droits de recettes - Gérance","data.office.*.droits_de_recettes_gerance", "0")}
                {/* Expertise */}
                {getRowFromStats(false,"Expertise","data.office.*.expertises", "0")}
                {/* Consultations analyses patrimoniales */}
                {getRowFromStats(false,"Analyses et Consultations patrimoniales","data.office.*.activite_consultations_et_analyses_patrimoniales", "0")}
                {/* Associations et collectivités */}
                {getRowFromStats(false,"Associations et collectivités","data.office.*.consultations_aux_associations_et_collectivites_contrats_dassociation", "0")}
                {/* Autres conseils */}
                {getRowFromStats(false,"Autres conseils","data.office.*.autres_consultations_juridiques_et_fiscales", "0")}

                {getRowFromStats(false,"","", "",props.editMode ? "actions" : "empty")}
            </tbody>

            <thead className={"headers -stats -with-color"}>
                {getRowFromStats(true,"RATIOS Honoraires","dapYear")}
            </thead>
            <tbody className={"body -stats -with-color"}>
                {/* Honoraires/ Produits Courants */}
                {getRowFromStats(false,"Honoraires/ Produits Courants",["montantsHonoraires","produitCourant"], "0","ratio")}

                {getRowFromStats(false,"","", "","empty")}
            </tbody>

            <thead className={"headers -stats -with-color"}>
                {getRowFromStats(true,"RATIOS","dapYear")}
            </thead>
            <tbody className={"body -stats -with-color"}>
                {/* Honoraires/ Produits Courants */}
                {getRowFromStats(false,"Résultat Courant / Produits Courants",["resultatCourant","produitCourant"], "0","ratio")}
                {/* Affichage different selon le type de société */}
                {
                    StatistiquesHelper.isOfficeWithImpotsSociete(props.office, props.stats) &&
                    <>
                        {getRowFromStats(false,"Résultat Courant (brut de rémunération) / Produits Courants",[["data.office.*.3resultat_courant_i_iiiii_iv", 'data.office.*.dap_volet_7_bremuneration_des_notaires_associes_en_sel'],"produitCourant"], "0","ratio")}
                    </>
                }

                {/* Charges Courantes / Produits Courants */}
                {getRowFromStats(false,"Charges Courantes / Produits Courants",["data.office.*.charges_courantes_iiiv","produitCourant"], "0","ratio")}
                {/* Frais de Personnel / Produits Courants */}
                {getRowFromStats(false,"Frais de Personnel / Produits Courants",[["data.office.*.remuneration_de_personnel_sauf_64113_et_64114","data.office.*.remuneration_des_notaires_salaries","data.office.*.charges_sociales_pour_le_personnel","data.office.*.autres_charges_sociales_et_de_personnel"],"produitCourant"], "0","ratio")}
                {/* Produits Courants / Salariés */}
                {getRowFromStats(false,"Produits Courants / Salariés",["produitCourant","nombreSalaries"], "0","divide")}
                {/* Produits Courants / Actes */}
                {getRowFromStats(false,"Produits Courants / Actes",["produitCourant","nombreActe"], "0","divide")}
                {/* Emoluments de formalités / Actes */}
                {getRowFromStats(false,"Emoluments de formalités / Actes",["data.office.*.emoluments_fixes_de_formalites_sauf_706129","nombreActe"], "0","divide")}
                {/* Clients débiteurs / Produits Courants */}
                {getRowFromStats(false,"Clients débiteurs / Produits Courants",["data.office.*.creances_client_et_comptes_rattaches_nt","produitCourant"], "0","ratio")}
                {/* Ecrêtements / Produits Courants */}
                {getRowFromStats(false,"Ecrêtements / Produits Courants",[["data.office.*.ecretement_sur_emoluments_de_formalites","data.office.*.ecretement_sur_emoluments_proportionnels_de_ventes","data.office.*.dap_volet_6ecretement_sur_emoluments_proportionnels_divers"],"produitCourant"], "0","ratio")}
                {/* Remises / Produits Courants */}
                {getRowFromStats(false,"Remises / Produits Courants",[["data.office.*.remises_totales_demoluments_et_honoraires_aux_proches_parents_et_collaborateurs","data.office.*.remises_totales_demoluments_et_honoraires_aux_clients","data.office.*.remises_partielles_demoluments_aux_proches_parents_et_collaborateurs"],"produitCourant"], "0","ratio")}
                {/* Remises partielles 10% / Produits Courants */}
                {getRowFromStats(false,"Remises partielles 10% / Produits Courants",[["data.office.*.remises_partielles_demoluments_aux_proches_parents_et_collaborateurs_10_maximum","data.office.*.remises_partielles_demoluments_aux_proches_parents_et_collaborateurs_40_maximum","data.office.*.remises_partielles_demoluments_aux_clients_10_maximum","data.office.*.remises_partielles_demoluments_aux_clients_40_maximum"],"produitCourant"], "0","ratio")}
            </tbody>
        </table>
    </div>
};

export default StatistiquesTable;
