import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";

export type IHabilitationData =  {[key:string]: string} & {
    nom: string,
    type: string,
    departement: string,
    ville: string,
    inspecteur: boolean,
    dateDernierSerment: string,
    dateSermentInitial: string,
    lastYearBLocked?: number
}


export type IHabilitationsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IApiPagination & {
            data: IHabilitationData[],
        }
    }
}
export type IHabilitationCourrierFormData = {
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}


export type IHabilitationsFormData = {
    [key:string]: string
};

export class HabilitationsService extends ApiService{
    public getHabilitations(formData: IHabilitationsFormData): Promise<IHabilitationsGetApiResponse> {
        return this.doApiCall(`api/inspections/habilitations`, 'POST', formData);
    }

    public updateHabilitation(formData: IHabilitationsFormData, id:string): Promise<IHabilitationsGetApiResponse> {
        return this.doApiCall(`api/inspections/habilitations/${id}/update`, 'POST', formData);
    }
    public lockHabilitationsYears(year: number): Promise<IHabilitationsGetApiResponse> {
        return this.doApiCall(`api/inspections/habilitations/lock/${year}`, 'POST');
    }

    public downloadCourrier(formDatas: IHabilitationCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/inspections/habilitations/courrier/download', 'POST', formDatas);
    }
}
