import React, {useContext, useEffect, useState} from "react";
import {ICotisationsNotaire} from "src/Models/CotisationsNotaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import NumberHelper from "src/Helpers/Number.helper";
import CotisationSaisieItemComponent from "./CotisationSaisieItem.component";
import SeeMoreLinkComponent from "../Links/SeeMoreLink.component";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {Redirect} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {IStatistiquesFormData} from "src/Services/StatistiquesService.service";
import {CotisationsService, ICotisationsSaisieNotairesFormData} from "src/Services/Cotisations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse} from "src/Services/Api.service";
import TableHeaderButtonComponent from "../Lists/TableHeaderButton.component";
import {IFilterData} from "../../Hooks/FiltersHandler";

type ICotisationSaisieAssuranceMaladieNotaireTableComponentProps = {
    cotisationsNotaire: ICotisationsNotaire[],
    currentYear: number,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onCancel: () => void,
    filters?: IFilterData,
    setOrder?: (value: string) => void,
    editingStatus?: boolean,
    setEditingStatus?: (status: boolean) => void,
}

export default function CotisationSaisieAssuranceMaladieNotaireTableComponent(props: ICotisationSaisieAssuranceMaladieNotaireTableComponentProps) {
    const {isAuthenticated} = useContext(UserContext) as IUserContext;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const [formData,setFormData] = useState<IStatistiquesFormData>({});
    const cotisationsService: CotisationsService = new CotisationsService();


    useEffect(() => {
        setIsEditing(props.editingStatus || false);
    }, [props.editingStatus])

    useEffect(() => {
        if(props.setEditingStatus && isEditing != props.editingStatus ){
            props.setEditingStatus(isEditing);
        }
    }, [isEditing])


    /**
     * Permet la récupération du fieldname pour l'item selon l'objet de cotisation du notaire
     *
     * @param {ICotisationsNotaire} cotisationNotaire
     * @param {string} fieldName
     * @returns {string}
     */
    const getFieldNameForItem = (cotisationNotaire: ICotisationsNotaire, fieldName:string): string => {
        return `${cotisationNotaire.id}#${fieldName}`;
    }


    /**
     * Changement des valeurs du formulaire
     *
     * @param {string} fieldName
     * @param {string} value
     */
    const onValueChange = (fieldName: string, value:string) => {
        const newFormData = {...formData};

        newFormData[fieldName] = value;
        setFormData(newFormData);
    };



    /**
     * Soumission du formulaire
     *
     */
    const onSubmit = (): void => {
        cotisationsService.updateSaisieNotaires(formData as ICotisationsSaisieNotairesFormData).then((response: IApiCustomResponse) => {
            setIsEditing(false);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les montants des cotisations des notaires ont été modifiés.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des montants des cotisations des notaires.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     */
    const onCancel = (): void => {
        setShowConfirmation(true);
    };


    /**
     * Validation de la confirmation
     * Débloquage du contenu
     */
    const onValidationConfirmation = () => {
        setIsEditing(false);
        setShowConfirmation(false);
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);

        props.onCancel();
    }

    //Affichage du formulaire
    const showForm = () => {
        //Vérification si la personne est loggée
        if (!isAuthenticated) {
            return <Redirect push to={AppConstants.pathLogin} />
        }

        setIsEditing(true);
    }




    return (
        <div className={`list-content -cotisation-list -right-gutter border-footer ${isEditing ? '-with-form' : ''}`}>
            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onValidationConfirmation} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment annuler vos modifications ?" />
            }

            {
                !isEditing &&
                <div className="list-edit-button">
                    <SeeMoreLinkComponent text="Modifier" icon="icon-cog" modificators="-small" callback={()=>showForm()} />
                </div>
            }

            <table className={`list-table`}>
                <thead className={"headers"}>
                    <tr>
                        <th className="th -pre-header" colSpan={3} />
                        <th className="th -pre-header" colSpan={3}><span className="text">Assurance Maladie {props.currentYear}</span></th>
                        <th className="th -pre-header" colSpan={3}><span className="text">Assurance Maladie {props.currentYear - 1} (à régulariser)</span></th>
                    </tr>
                    <tr>
                        {[
                            {slug: "CRPCEN", label: "CRPCEN"},
                            {slug: "nom", label: "Nom & Prénom"},
                            {slug: "type", label: "Type"},
                            {slug: "montant1AssuranceMaladie", label: "Montant 1"},
                            {slug: "montant2AssuranceMaladie", label: "Montant 2"},
                            {slug: "totalCurrent", label: "Total"},
                            {slug: "montant1AssuranceMaladieNmoins1", label: "Montant 1"},
                            {slug: "montant2AssuranceMaladieNmoins1", label: "Montant 2"},
                            {slug: "totalPrevious", label: "Total"},
                        ].map((item, iIndex) => <th className="th" key={iIndex}>
                            <TableHeaderButtonComponent
                                direction={ props.filters ? props.filters.orderType as string : AppConstants.orderType.asc}
                                value={item.slug} selectedValue={props.filters ? props.filters.orderBy as string : ""}
                                label={item.label} click={ props.setOrder ? (value: string) => props.setOrder(value) : ()=>{}}/>
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.cotisationsNotaire && props.cotisationsNotaire.map( (cn: ICotisationsNotaire, index: number) =>
                            <tr key={index}>
                                <td className="td -w85px">
                                    {cn.notaire.office ? cn.notaire.office.CRPCEN : ''}
                                </td>
                                <td className="td">
                                    {cn.notaire.nom} {cn.notaire.prenom}
                                </td>
                                <td className="td">
                                    {NotairesHelper.getFormattedType(cn.notaire.type)}
                                </td>
                                <td className="td">
                                    <CotisationSaisieItemComponent isEditing={isEditing}
                                                                   value={cn.montant1AssuranceMaladie}
                                                                   fieldName={getFieldNameForItem(cn, 'montant1AssuranceMaladie' )}
                                                                   digits={2}
                                                                   onValueChange={onValueChange}
                                    />
                                </td>
                                <td className="td">
                                    <CotisationSaisieItemComponent isEditing={isEditing}
                                                                   value={cn.montant2AssuranceMaladie}
                                                                   fieldName={getFieldNameForItem(cn, 'montant2AssuranceMaladie' )}
                                                                   digits={2}
                                                                   onValueChange={onValueChange}
                                    />
                                </td>
                                <td className="td">
                                    {NumberHelper.formatThousand(cn.montant1AssuranceMaladie+cn.montant2AssuranceMaladie, 2,2)} €
                                </td>
                                <td className="td">
                                    <CotisationSaisieItemComponent isEditing={isEditing}
                                                                   value={cn.montant1AssuranceMaladieNmoins1}
                                                                   fieldName={getFieldNameForItem(cn, 'montant1AssuranceMaladieNmoins1' )}
                                                                   digits={2}
                                                                   onValueChange={onValueChange}
                                    />
                                </td>
                                <td className="td">
                                    <CotisationSaisieItemComponent isEditing={isEditing}
                                                                   value={cn.montant2AssuranceMaladieNmoins1}
                                                                   fieldName={getFieldNameForItem(cn, 'montant2AssuranceMaladieNmoins1' )}
                                                                   digits={2}
                                                                   onValueChange={onValueChange}
                                    />
                                </td>
                                <td className="td">
                                    {NumberHelper.formatThousand(cn.montant1AssuranceMaladieNmoins1+cn.montant2AssuranceMaladieNmoins1, 2, 2)}€
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>


            {
                isEditing &&
                <div className="list-edit-actions">
                    <div className="form-actions ">
                        <button className="g-button -primary" onClick={() => onSubmit()}>Enregistrer</button>
                        <button className="g-button -is-link" onClick={() => onCancel()}>Annuler</button>
                    </div>
                </div>
            }
        </div>
    );
}
