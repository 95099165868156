import {IFormation} from "src/Models/Formation.model";
import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "../../FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {IFormationFormData, IFormationGetApiResponse} from "src/Services/Formations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IFormationParticipant} from "src/Models/FormationParticipant.model";

type IFormationParticipantMailFormProps = {
    formation?: IFormation,
    participantId?: number,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IFormationFormData) => Promise<IFormationGetApiResponse>,
    onSuccessSubmit: (response: IFormationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IFormationGetApiResponse) => void,
    onCancel: () => void,
}

export default function FormationParticipantMailForm(props: IFormationParticipantMailFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [currentParticipant, setCurrentParticipant] = useState<IFormationParticipant>(null);
    const {getFormActions} = useCreationSteps();

    useEffect( () => {
        if(props.formation && props.participantId){
            //On cherche l'id dans les inscrits
            let newCurrentParticipant: IFormationParticipant = props.formation.inscrits.find( (i) => i.id == props.participantId);
            if(newCurrentParticipant){
                setCurrentParticipant(newCurrentParticipant);
            }

            //On cherche l'id dans les personnes en attente
            newCurrentParticipant = props.formation.enAttente.find( (i) => i.id == props.participantId);
            if(newCurrentParticipant){
                setCurrentParticipant(newCurrentParticipant);
            }

            //On cherche l'id dans les annulations
            newCurrentParticipant = props.formation.annulation.find( (i) => i.id == props.participantId);
            if(newCurrentParticipant){
                setCurrentParticipant(newCurrentParticipant);
            }
        }
    }, [props.formation, props.participantId]);


    useEffect(()=>{
        if(currentParticipant) {
            const formElementsColumn1: FormElement[] = [
                {
                    type: 'email',
                    fieldName: "email",
                    label: "Email du participant",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white" : "",
                    oldValue: currentParticipant && currentParticipant.email ? currentParticipant.email : "",
                },
            ];

            const currentFormColumns: FormColumn[] = [
                {
                    modificators: "-full",
                    elements: formElementsColumn1
                },
            ];
            setFormColumns(currentFormColumns);

            //Préparation des actions du formulaires
            let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true);
            setFormActions(currentFormActions);

        }
    },[currentParticipant]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: IFormationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'adresse email a bien été mise à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de l'adresse email.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
