import React from "react";
import {IFormation} from "../../../Models/Formation.model";
import StringHelper from "../../../Helpers/String.helper";
import CommentairesComponent from "../../Annuaire/Commons/Commentaires/Commentaires.component";


const FormationComplements = (props: {
    formation: IFormation
}) => {

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column -full">
                <div className="informations-block">
                    <p className="label">Message complémentaire</p>
                    <div className="value">
                        <CommentairesComponent commentaires={props.formation.commentaires} />
                    </div>
                </div>
            </div>
        </div>

    </div>

};

export default FormationComplements;