import React, {createContext, FunctionComponent, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Store as notificationSystem} from 'react-notifications-component';
import {defaultNotificationConfig} from "src/Shared/config";
import {IGetUserApiResponse, UsersService} from "src/Services/Users.service";
import ApiService, {IApiCustomResponse} from "src/Services/Api.service";
import {IUser, IUserModule} from "src/Models/User.model";
import {AppConstants} from "src/Constants/AppConstants";
import {UserConstants} from "../Constants/UsersConstants";
import {useLocation} from "react-router-dom";


const userContextDefaultValue: IUserContext = {
    isAuthenticated: false,
    loaded:false,
    authUser: null,
    logout: null,
    login: null,
    getUser: null,
    isAdmin: null,
    isSuperAdmin: null,
    checkModuleAllowed: null
};
export const UserContext = createContext(userContextDefaultValue);

export type IUserContext = {
    isAuthenticated: boolean,
    loaded: boolean,
    authUser: IUser,
    logout: () => void,
    getUser: () => void,
    checkModuleAllowed: (module: string) => boolean,
    isAdmin: () => boolean,
    isSuperAdmin: () => boolean,
    login: (email: string, password: string) => Promise<IApiCustomResponse>
}


/**
 * Provider gérant l'utilisateur connecté à l'appli
 * Utilise le sytème de contexte pour accéder à cet utilisateur dans tous les composants enfants
 *
 * @param {React.PropsWithChildren<{}>} props
 * @returns {any}
 * @constructor
 */
const UserProvider:FunctionComponent<{}> = (props) => {
    const usersService = new UsersService(null);
    const apiService = new ApiService(null);
    const [user,setUser] = useState<IUser>(undefined);
    const [isLogged,setIsLogged] = useState<boolean>(false);
    const [loaded,setIsLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();



    useEffect(()=>{
        checkAuthenticated();
    },[]);// eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Connexion de l'utilisateur
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise<IApiCustomResponse>}
     */
    const login = (email: string, password: string): Promise<IApiCustomResponse> => {
        return new Promise((resolve, reject) => {
            usersService.login({email: email, password: password}).then((response: IApiCustomResponse) => {
                getUser();

                resolve(response);
            }, (error: IApiCustomResponse) => {
                resetLoginStatus();
                reject(error);
            });
        });
    };


    /**
     * Permet la récupérer des infos de l'utilisateur connecté
     */
    const getUser = ():void => {
        usersService.getMe().then((response: IGetUserApiResponse) => {
            if(response.datas && response.datas.user){
                setUser(response.datas.user);
                setIsLogged(true);
            }
            else{
                logout();
            }
        },(error: IApiCustomResponse) => {
            resetLoginStatus();
        });
    }


    /**
     * Vérifier l'authentification de l'utilisateur
     */
    const checkAuthenticated = ():void => {

        const pathName: string = location.pathname;
        let skipCheck: boolean = false;
        if(pathName.includes("successions") && pathName.includes('reponse')){
            skipCheck = true;
        }
        if(pathName.includes("formations") && pathName.includes('inscription-exterieure')){
            skipCheck = true;
        }
        if(pathName.includes("fil-infos/abonnement")){
            skipCheck = true;
        }
        if(pathName.includes("fil-infos/archives")){
            skipCheck = true;
        }
        if(pathName.includes("annuaire/offices") && pathName.includes("/tableau-de-bord") && pathName.includes("/document") ){
            skipCheck = true;
        }
        if(pathName.includes("correspondances/inscription")){
            skipCheck = true;
        }
        if(pathName.includes("annuaire/notaires") && pathName.includes("/urssaf") ){
            skipCheck = true;
        }
        if(pathName.includes("documents/formations") ){
            skipCheck = true;
        }


        if (!isLogged && !skipCheck) {
            apiService.getSanctumCookie().then((response) => {
                usersService.getMe().then((response: IGetUserApiResponse) => {
                    if(response.datas && response.datas.user){
                        setUser(response.datas.user);
                        setIsLogged(true);
                    }
                    else{
                        resetLoginStatus();
                    }

                    setIsLoaded(true);
                },(error: IApiCustomResponse) => {
                    setIsLoaded(true);
                    resetLoginStatus();
                });
            },
            (error: IApiCustomResponse) => {
                setIsLoaded(true);
                resetLoginStatus();
            });
        }
        else {
            setIsLoaded(true);
        }
    }


    /**
     * Permet de savoir si une personne est connectée
     *
     * @returns {boolean}
     */
    const isAuthenticated = (): boolean => {
        return (loaded && isLogged && user != null);
    }


    /**
     * Permet de remettre à 0 les éléments de login
     */
    const resetLoginStatus = (): void => {
        setIsLogged(false);
        setUser(null);
        setIsLoaded(true);
    }


    /**
     * Déconnexion de l'utilisateur
     */
    const logout = (): void => {
        usersService.logout().then(function() {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Vous avez bien été déconnecté.e..",
                type: "success"
            });

            resetLoginStatus();

            // On redirige vers la page de login
            history.push(AppConstants.pathLogin);
        }, function(error: IApiCustomResponse) {
            notificationSystem.addNotification({...defaultNotificationConfig,message: "Une erreur est survenue lors de la déconnexion.",type: "danger"});
        });
    };


    /**
     * Permet de savoir si un module est autorisé pour l'utilisateur
     *
     * @param {string} module
     * @returns {boolean}
     */
    const checkModuleAllowed = (module: string): boolean => {
        //Dans le cas d'un admin/super admin, tous les modules sont autorisés
        if(user.role > UserConstants.roles.contributor){
            return true;
        }
        
        const moduleData: IUserModule = user.modules.find((m: IUserModule) => m.slug === module);

        return ((moduleData && moduleData.active === 1) );
    }

    /**
     * Permet de savoir si l'utilisateur est un admin ou non
     * @returns {boolean}
     */
    const isAdmin = (): boolean => {
        return user && user.role > UserConstants.roles.contributor;
    }
    /**
     * Permet de savoir si l'utilisateur est un super admin ou non
     * @returns {boolean}
     */
    const isSuperAdmin = (): boolean => {
        return user && user.role > UserConstants.roles.admin;
    }



    return (
        <UserContext.Provider value={{loaded, authUser: user, logout, login, isAuthenticated: isAuthenticated(), getUser, checkModuleAllowed, isAdmin, isSuperAdmin}}>
            {props.children}
        </UserContext.Provider>
    );

};

export default UserProvider;
