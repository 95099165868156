import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";
import {ICotisationsOffice} from "../Models/CotisationsOffice.model";
import {ICotisationsNotaire} from "../Models/CotisationsNotaire.model";


export type IGetCotisationsOffice = IApiCustomResponse & {
    datas: {
        cotisations: ICotisationsOffice[],
        tauxChambre: number,
        tauxCridon: number,
    }
}

export type ICotisationsSaisieOfficeFormData = {
    compta?: string,
    montantGarantieCollective?: number,
    montantAbsenceCollaborateur?: number,
    cridonHT?: number,
    tropPercuHT?: number,
    acompteConseilSuperieur?: number,
    soldeConseilSuperieur?: number,
    montantAssuranceRC2?: number,
    montantAssuranceRC1?: number,
    montantPerte1?: number,
    montantPerte2?: number,
    montantCyber1?: number,
    montantCyber2?: number,
    montantCotisationChambre?: number,
    montantFraisTraitement?: number,
}
export type ICotisationsSaisieNotairesFormData = {

}
export type ICotisationsSaisieNotaireGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    keyword?: string,
    year?: string,
}

export type ICotisationsSaisieNotaireGetPaginationApi = IApiPagination & {
    data: ICotisationsNotaire[],
}
export type ICotisationsSaisieNotaireGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICotisationsSaisieNotaireGetPaginationApi
    }
}


export type ICotisationsSaisieOfficesGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    keyword?: string,
}
export type ICotisationsSaisieOfficesGetPaginationApi = IApiPagination & {
    data: ICotisationsOffice[],
}
export type ICotisationsSaisieOfficesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICotisationsSaisieOfficesGetPaginationApi
    }
}
export type ICotisationsSaisieOfficesFormData = {

}
export type ICotisationsSaisieNotaireCourrierFormData = {
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}




export class CotisationsService extends ApiService{

    /**
     * Permet la récupération des cotisations pour un office
     *
     * @param {string} oid
     * @returns {Promise<IGetCotisationsOffice>}
     */
    public getCotisationsForOffice(oid: string): Promise<IGetCotisationsOffice>{
        return this.doApiCall('api/cotisations/offices/' +oid, 'GET');
    }


    /**
     * Mise à jour des données de cotisations d'un office
     *
     * @param {number} cid
     * @param {ICotisationsSaisieOfficeFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateCotisationsOffice(cid: number, formData: ICotisationsSaisieOfficeFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/cotisations/offices/update/' + cid, 'POST', formData);
    }

    /**
     * Mise à jour des données de cotisations des notaires
     *
     * @param {ICotisationsSaisieNotairesFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateSaisieNotaires(formData: ICotisationsSaisieNotairesFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/cotisations/notaires/saisie', 'POST', formData);
    }

    /**
     * Mise à jour des données de cotisations des offices
     *
     * @param {ICotisationsSaisieOfficesFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateSaisieOffices(formData: ICotisationsSaisieOfficesFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/cotisations/offices/saisie', 'POST', formData);
    }

    /**
     * Récupération de la liste des cotisations des notaires
     *
     * @param {ICotisationsSaisieNotaireGetFormData} formData
     * @returns {Promise<ICotisationsSaisieNotaireGetApiResponse>}
     */
    public getCotisationsForNotaires(formData: ICotisationsSaisieNotaireGetFormData ): Promise<ICotisationsSaisieNotaireGetApiResponse>{
        return this.doApiCall('api/cotisations/notaires/saisie', 'GET', formData);
    }

    /**
     * Récupération de la liste des cotisations des notaires
     *
     * @param {ICotisationsSaisieOfficesGetFormData} formData
     * @returns {Promise<ICotisationsSaisieOfficesGetApiResponse>}
     */
    public getCotisationsForOffices(formData: ICotisationsSaisieOfficesGetFormData ): Promise<ICotisationsSaisieOfficesGetApiResponse>{
        return this.doApiCall('api/cotisations/offices/saisie', 'GET', formData);
    }


    /**
     * Envoi d'un courrier pour la saisie des notaires
     *
     * @param {ICotisationsSaisieNotaireCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(formDatas: ICotisationsSaisieNotaireCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/cotisations/saisie-notaires/courrier/send', 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier pour la saisie des notaires
     *
     * @param {ICotisationsSaisieNotaireCourrierFormData} formDatas
     * @returns {Promise<IFileDownloadApiResponse>}
     */
    public downloadCourrier(formDatas: ICotisationsSaisieNotaireCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/cotisations/saisie-notaires/courrier/download', 'POST', formDatas);
    }


    /**
     * Envoi d'un courrier pour les offices
     *
     * @param {ICotisationsSaisieNotaireCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrierOffice(formDatas: ICotisationsSaisieNotaireCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/cotisations/saisie-notaires/courrier/send', 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier pour les offices
     *
     * @param {ICotisationsSaisieNotaireCourrierFormData} formDatas
     * @returns {Promise<IFileDownloadApiResponse>}
     */
    public downloadCourrierOffice(formDatas: ICotisationsSaisieNotaireCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/cotisations/saisie-notaires/courrier/download', 'POST', formDatas);
    }
}
