import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {ILabel} from "src/Models/Label.model";
import {ILabelsGetFormData, ILabelsListGetApiResponse, LabelsService} from "src/Services/Labels.service";
import AsideLabelsComponent from "src/Components/Aside/AsideLabels.component";
import LabelsHelper from "../../../Helpers/Labels.helper";
import LoadingComponent from "../../../Components/Loading/Loading.component";

type LabelsListScreenProps = {

}
export default function LabelsListScreen(props: LabelsListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [labelsList, setLabelsList] = useState<ILabel[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder] = useListFilters("/annuaire/labels");
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    /**
     * Initialisation
     */
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Annuaire", link: AppConstants.pathAnnuaire},
            { text: "Labels" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);

        const params: any = location.search;
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);

    /**
     * Changement des filtres
     */
    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Lancement de la recherche
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const labelsService: LabelsService = new LabelsService();

        labelsService.getLabels(filters as ILabelsGetFormData).then((response: ILabelsListGetApiResponse) => {
            if( response && response.datas.pagination){
                setLabelsList(response.datas.pagination.data);
                setPaginationLinks(response.datas.pagination.links);

                if(!loaded){
                    setLoaded(true);
                }
            }
        });
    }



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text="Labels" icon="icon-labels"/>
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {["nom","module"].map((item, iIndex)=><th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent direction={filters.orderType as string || AppConstants.orderType.asc} value={item} selectedValue={filters.orderBy as string || ""} label={item} click={(value:string)=>setOrder(value)} />
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                labelsList && labelsList.map( (label: ILabel, index: number) =>
                                                    <tr key={index} onClick={()=>history.push(`/annuaire/labels/${label.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {label.nom}
                                                        </td><td className="td">
                                                            {LabelsHelper.getFormatedModule(label.module)}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <PaginationComponent links={paginationLinks}  onPageClick={page=>updateFilters({page})}/>

                                    <AsideLabelsComponent type='list' filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    )
}
