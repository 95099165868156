import React from "react";
import {AppConstants} from "../../Constants/AppConstants";

const TableHeaderButtonComponent = (props:{
    selectedValue: string,
    direction: string,
    value: string,
    label: string,
    click: (value:string) => void,
    noSort?: boolean
}) => {



    return <button className={`link ${props.selectedValue === props.value ? '-active' : ''} ${props.direction === AppConstants.orderType.desc ? '-desc' : ''} ${props.noSort ? '-no-action' : ''}`} onClick={()=> !props.noSort ? props.click(props.value) : null}>
        <span className="text">{props.label}</span>
        <i className="icon icon-arrow-small-down" />
    </button>

};

export default TableHeaderButtonComponent;
