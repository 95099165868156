import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {ICertification} from "../Models/Certification.model";

export type ICertificationGetApiResponse = IApiCustomResponse & {
    datas: {
        certification: ICertification
    }
}
export type ICertificationsGetPaginationApi = IApiPagination & {
    data: ICertification[],
}
export type ICertificationsListGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICertificationsGetPaginationApi
    }
}
export type ICertificationsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}
export type ICertificationInformationsFormData = {
    nom: string,
    fichier: string
}
export type ICertificationGetAllApiResponse = IApiCustomResponse & {
    datas: {
        certifications: ICertification[]
    }
}



export class CertificationsService extends ApiService{
    /**
     * Permet la récupération d'une liste des certifications
     *
     * @returns {Promise<ICertificationsListGetApiResponse>}
     */
    public getCertifications(formData: ICertificationsGetFormData): Promise<ICertificationsListGetApiResponse>{
        return this.doApiCall('api/annuaire/certifications', 'GET', formData);
    }


    /**
     * Permet la récupération d'une liste des certifications
     *
     * @returns {Promise<ICertificationsListGetApiResponse>}
     */
    public getAll(): Promise<ICertificationGetAllApiResponse>{
        return this.doApiCall('api/annuaire/certifications/all', 'GET');
    }


    /**
     * Permet la récupération d'une certification à partir de son id
     *
     * @param {string} cid
     * @returns {Promise<>}
     */
    public getCertification(cid: string): Promise<ICertificationGetApiResponse>{
        return this.doApiCall('api/annuaire/certifications/' + cid, 'GET');
    }

    /**
     * Creation d'une certification
     *
     * @param {ICertificationInformationsFormData} formDatas
     * @returns {Promise<ICertificationGetApiResponse>}
     */
    public create(formDatas: ICertificationInformationsFormData): Promise<ICertificationGetApiResponse>{
        return this.doApiCall('api/annuaire/certifications/create', 'POST', formDatas);
    }


    /**
     * Modification d'une certification
     *
     * @param {string} cid
     * @param {ICertificationInformationsFormData} formDatas
     * @returns {Promise<ICertificationGetApiResponse>}
     */
    public update(cid: string, formDatas: ICertificationInformationsFormData): Promise<ICertificationGetApiResponse>{
        return this.doApiCall('api/annuaire/certifications/' + cid + '/update', 'POST', formDatas);
    }


    /**
     * Permet la suppression d'une certification
     *
     * @param {number} cid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(cid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/certifications/' + cid + '/delete', 'POST');
    }


}

