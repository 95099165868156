import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideStatistiquesComponent from "src/Components/Aside/AsideStatistiques.component";
import StatistiquesExportComponent from "src/Components/Inspections/Statistiques/StatistiquesExport.component";
import {IOffice} from "../../../Models/Office.model";
import {IOfficesGetApiResponse, IOfficesGetFormData, OfficesService} from "../../../Services/Offices.service";
import TableHeaderButtonComponent from "../../../Components/Lists/TableHeaderButton.component";
import OfficesHelper from "../../../Helpers/Offices.helper";
import SelectMultipleFieldComponent from "../../../Components/Fields/SelectMultiple.field.component";
import {OfficesConstants} from "../../../Constants/OfficesConstants";
import {Link} from "react-router-dom";
import LoadingComponent from "../../../Components/Loading/Loading.component";

type IStatistiquesListScreenProps = {

}


const StatistiquesListScreen = (props: IStatistiquesListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const location = useLocation();
    const history = useHistory();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathStatistiques);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);

    const [officesList, setOfficesList] = useState<IOffice[]>([]);


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            { text: "Statistiques" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);


    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Récupération des offices
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const officesService: OfficesService = new OfficesService();

        officesService.getOfficesPrincipalesWithPagination(filters as IOfficesGetFormData).then((response: IOfficesGetApiResponse) => {
            if (response && response.datas.pagination) {
                const currentOfficesList = response.datas.pagination.data;
                setOfficesList(currentOfficesList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Statistiques"
                    icon="icon-statistiques"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
						<TabsContainerComponent>
							<TabPanelComponent label="Liste" icon="icon-menu">
								<div className="list-content -right-gutter">

									<div className="list-filters">
										<i className={`icon icon-filtre`}/>
										<SelectMultipleFieldComponent fieldName="departement"
										                              label="Départements"
										                              options={OfficesConstants.departementsOptions}
										                              hideCurrentValuesOnTop={true}
										                              errors={null}
										                              placeholder="Tous les départements"
										                              hideSearch={true}
										                              modificators={`-inline -on-white`}
										                              oldValues={filters.departement ? filters.departement as string[] : []}
										                              onChange={(values: string[]) => updateFilters({departement: values})}
										/>
									</div>

									<table className={`list-table`}>
										<thead className={"headers"}>
										<tr>
                                            {[
                                                {slug: "CRPCEN", label: "CRPCEN"},
                                                {slug: "nom", label: "nom"},
                                                {slug: "type", label: "type"},
                                                {slug: "ville", label: "ville"},
                                            ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    label={item.label} click={(value: string) => setOrder(value)}/>
                                            </th>)}
										</tr>
										</thead>
										<tbody>
                                        {
                                            officesList && officesList.map((office: IOffice, index: number) =>
                                                <tr key={index}
                                                    onClick={() => history.push(`${AppConstants.pathStatistiques}/${office.id}`)}
                                                    className="tr">
                                                    <td className="td -bold">
                                                        {office.CRPCEN}
                                                    </td>
                                                    <td className="td">
                                                        <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${office.id}`} onClick={(e) => e.stopPropagation()}>{office.nom}</Link>
                                                    </td>
                                                    <td className="td">
                                                        {OfficesHelper.getFormatedType(office.type)}
                                                    </td>
                                                    <td className="td -uppercase">
                                                        {office.adressePrincipale ? office.adressePrincipale.ville : "-"}
                                                    </td>
                                                </tr>
                                            )
                                        }
										</tbody>
									</table>

									<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>


									<AsideStatistiquesComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
								</div>
							</TabPanelComponent>
						</TabsContainerComponent>
					</>
                }
            </div>
            
            <StatistiquesExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} filters={filters}/>

        </LayoutComponent>
    )
};

export default StatistiquesListScreen;
