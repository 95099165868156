import {IApiCustomResponse} from "../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "../Constants/AppConstants";
import {ISuccessionGetApiResponse, SuccessionsService} from "../Services/Successions.service";
import {ISuccession} from "../Models/Succession.model";

const UseSuccession = () => {
    const successionsService: SuccessionsService = new SuccessionsService();
    const history = useHistory();

    /**
     *
     * Récupération d'une succession à partir de son id
     *
     * @param {string} sid
     * @param {(succession: ISuccession) => void} callback
     * @param {() => void} callbackError
     */
    const getSuccessionById = (sid: string, callback:(succession: ISuccession) => void, callbackError?: () => void ) => {
        successionsService.getSuccession(sid).then((response: ISuccessionGetApiResponse) => {
            if( response && response.datas.succession){
                if(callback){
                    callback(response.datas.succession);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathSuccessions);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la succession'",type: "danger"});
        });
    };


    /**
     *
     * Récupération d'une succession à partir de son id
     *
     * @param {string} sid
     * @param {(succession: ISuccession) => void} callback
     * @param {() => void} callbackError
     */
    const getSuccessionExternalById = (sid: string, callback:(succession: ISuccession) => void, callbackError?: () => void ) => {
        successionsService.getSuccessionExternal(sid).then((response: ISuccessionGetApiResponse) => {
            if( response && response.datas.succession){
                if(callback){
                    callback(response.datas.succession);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathSuccessions);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la succession'",type: "danger"});
        });
    };


    return {getSuccessionById, getSuccessionExternalById};
};

export default UseSuccession;
