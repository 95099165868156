import {IDifferend} from "../../Models/Differend.model";
import DateHelper from "../../Helpers/Date.helper";

type IDifferendNotairesComponentProps = {
    differend: IDifferend,
}

export default function DifferendNotairesComponent(props: IDifferendNotairesComponentProps) {
    const differend: IDifferend = props.differend;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Plaignant</p>
                        <p className="value">{differend.plaignant}{differend.originePlaignant === 'externe' && differend.notairePlaignantVille ? `, notaire à ${differend.notairePlaignantVille}`:'' }</p>
                    </div>
                    {
                        differend.notairePlaignantPresident &&
                            <div className="informations-block">
                                <p className="label">Président de la chambre du plaignant</p>
                                <p className="value">{`${differend.notairePlaignantPresident.nom} ${differend.notairePlaignantPresident.prenom}`}{ differend.notairePlaignantPresident.hasOwnProperty('organisme') && differend.notairePlaignantPresident.organisme !== null ? `- ${differend.notairePlaignantPresident.organisme?.nom}` : null}</p>
                            </div>
                    }
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Mis en cause</p>
                        <p className="value">{differend.misEnCause}{differend.origineMisEnCause === 'externe' && differend.notaireMisEnCauseVille ? `, notaire à ${differend.notaireMisEnCauseVille}`:'' }</p>
                    </div>
                    {
                        differend.notaireMisEnCausePresident &&
                        <div className="informations-block">
                            <p className="label">Président de la chambre du mis en cause</p>
                            <p className="value">{`${differend.notaireMisEnCausePresident.nom} ${differend.notaireMisEnCausePresident.prenom} - ${differend.notaireMisEnCausePresident.organisme.nom}`}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
