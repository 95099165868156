import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";
import moment, {Moment} from "moment";

type ICotisationsOfficeRecapAssuranceMaladieComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapAssuranceMaladieComponent(props: ICotisationsOfficeRecapAssuranceMaladieComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;
    const currentDate:Moment = moment();
    const currentYear: string = currentDate.format("YYYY");
    const previousYear: string = currentDate.subtract(1, 'y').format("YYYY");


    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant {currentYear}</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantAssuranceMaladieN, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant {previousYear} à régulariser</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantAssuranceMaladieNmoins1, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
