import React from "react";
import {IFormation} from "../../../Models/Formation.model";

const FormationFormation = (props: { formation: IFormation }) => {

    /**
     *
     * @param {string} cout
     * @returns {string}
     */
    const formatCout = (cout: string) => {
        if (cout == '0') {
            return 'Gratuit';
        }

        if (isNumber(cout)) {
            return cout +' €';
        }

        return cout;
    }

    /**
     *
     * @param value
     * @returns {boolean}
     */
    const isNumber = (value: any) => {
        return !isNaN(value) &&
            !isNaN(parseFloat(value))
    }

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column">
                <div className="informations-block">
                    <p className="label">Intervenants (personnes extérieures)</p>
                    <p className="value">
                        {props.formation.intervenantsPersonnesExterieures.map((intervenant,index)=>`${index>0 ? ', ': ''} ${intervenant.prenom} ${intervenant.nom}`)}
                    </p>
                </div>

                <div className="informations-block">
                    <p className="label">Intervenants (notaires)</p>
                    <p className="value">
                        {props.formation.intervenantsNotaires.map((intervenant,index)=>`${index>0 ? ', ': ''} ${intervenant.prenom} ${intervenant.nom}`)}
                    </p>
                </div>

                <div className="informations-block">
                    <p className="label">Url Questionnaire</p>
                    <p className="value">
                        {props.formation.urlQuestionnaire}
                    </p>
                </div>
                <div className="informations-block">
                    <p className="label">Nb. max Participants</p>
                    <p className="value">{props.formation.nbMaxParticipants}</p>
                </div>
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Nb. heures</p>
                    <p className="value">{props.formation.nbHeures}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Lieu</p>
                    <p className="value">{props.formation.lieu}</p>
                </div>
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Adresse</p>
                    <p className="value">{props.formation.adresse}</p>
                </div>

                <div className="informations-block">
                    <p className="label">Coût</p>
                    <p className="value">{ formatCout(props.formation.cout) }</p>
                </div>
            </div>
        </div>

    </div>

};

export default FormationFormation;
