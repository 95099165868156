import {IOption} from "src/Components/Fields/Select.field.component";

export type IRepertoiresConstants = {
    numerosOptions: IOption[],
}
export const RepertoiresConstants: IRepertoiresConstants = {
    numerosOptions: [
        {
            value: "1-500",
            label: "1-500"
        },
        {
            value: "501-1000",
            label: "501-1000"
        },
        {
            value: "1001-1500",
            label: "1001-1500"
        },
        {
            value: "1501-2000",
            label: "1501-2000"
        },
        {
            value: "classeur",
            label: "classeur"
        },
    ],
};
