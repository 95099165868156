import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ICertification} from "src/Models/Certification.model";
import {ICertificationGetApiResponse, ICertificationInformationsFormData} from "src/Services/Certifications.service";
import useCreationSteps from "src/Hooks/useCreationSteps";


type ICertificationInformationsFormProps = {
    certification?: ICertification,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ICertificationInformationsFormData) => Promise<ICertificationGetApiResponse>,
    onSuccessSubmit: (response: ICertificationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ICertificationGetApiResponse) => void,
    onCancel: () => void,
}

export default function CertificationInformationsFormComponent(props: ICertificationInformationsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const certification: ICertification = props.certification;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: !certification ? "-on-white" : '',
                oldValue: certification && certification.nom ? certification.nom : "",
                showFieldErrorDetail: true,
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'file',
                fieldName: "fichier",

                label: "Logo",
                placeholder: "Logo",
                required: false,
                showImage: true,
                showImageDeleteButton: true,

                oldValue: certification && certification.fichier ? certification.fichier : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ICertificationInformationsFormData).then((response: ICertificationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }


            const message: string = props.certification ? "La certification a bien été modifiée." : "La certification a bien été créée."
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: message,
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création de la certification.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
