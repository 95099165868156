import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ISuccession} from "src/Models/Succession.model";
import {ISuccessionReponseExternalFormData, ISuccessionGetApiResponse} from "src/Services/Successions.service";
import {IOfficesGetAllApiResponse, OfficesService} from "src/Services/Offices.service";
import {IOffice} from "src/Models/Office.model";
import OfficesHelper from "src/Helpers/Offices.helper";
import NotairesHelper from "src/Helpers/Notaires.helper";


type ISuccessionReponseExternalFormProps = {
    succession?: ISuccession,

    submitMethod: (formData: ISuccessionReponseExternalFormData) => Promise<ISuccessionGetApiResponse>,
    onSuccessSubmit: (response: ISuccessionGetApiResponse) => void,
}

export default function SuccessionReponseExternalFormComponent(props: ISuccessionReponseExternalFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [offices,setOffices] = useState<IOffice[]>([]);
    const [officeLoaded,setOfficesLoaded] = useState<boolean>(false);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);


    useEffect(() => {
        const officesService: OfficesService = new OfficesService();
        officesService.getAllOfficesPrincipalesExternal().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                let officesTmp = response.datas.offices;

                //Trier les offices par CRPCEN
                let officesOrdered = officesTmp.sort((a, b) => (a.CRPCEN < b.CRPCEN ? -1 : 1));
                //Récupérer que les offices actifs (pour le choix du nouvel office)
                let officesOrderedActifs = officesOrdered.filter(office => office.actif === "oui");
                setOffices(officesOrderedActifs);

                setOfficesLoaded(true);
            }
        });
    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        if(!offices && !offices.length) return;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "officeId",

                label: "Office",
                placeholder: "Sélectionnez un office",
                required: true,
                modificators: "-on-white",
                oldValue: "",


                options: offices ? OfficesHelper.formatListForOptions(offices) : [],

                hideEmptyOption: true,
            },
        ];

        for(let office of offices){
            formElementsColumn1.push({
                type: 'select',
                fieldName: "notaireId",

                label: "Notaire",
                placeholder: "Notaire",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                hideEmptyOption: true,
                condition:{
                    fieldName: "officeId",
                    value: office.id.toString()
                },

                options: office.notaires ? NotairesHelper.formatListForOptions(office.notaires) : [],
            })
        }

        formElementsColumn1.push({
            type: 'textarea',
            fieldName: "commentaires",

            label: "Commentaires",
            placeholder: "Commentaires",
            required: false,
            modificators: "-on-white -wysiwyg",

            oldValue: "",
        });

        formElementsColumn1.push({
            type: 'checkbox',
            fieldName: "accept",

            label: "Mon office est chargé de cette succession. Je prendrai directement contact avec le demandeur.",
            placeholder: "",
            required: true,
            modificators: "-on-white -full",
            labelModificators: "-size-3 -fw-bold",
            oldValue: "",
        })


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1,
                modificators: "-size-2"
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [];
        currentFormActions = [
            {
                label: "Répondre",
                modificators: "-secondary",
                onAction: (formData) => {onSubmit(formData, false)}
            },
        ];
        setFormActions(currentFormActions);

    },[offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISuccessionReponseExternalFormData).then((response: ISuccessionGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            /*
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Votre réponse a bien été prise en compte.",
                type: "success"
            });
             */

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la réponse à la demande de succession.",
                    type: "danger"
                });
            }
        });
    };


    return (
        <div className="form-wrapper -edit">
            {
                officeLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions="-outside-right"
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }

        </div>
    )
}
