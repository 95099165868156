import React, { useEffect, useState } from "react";
import { IDifferend } from "../../../Models/Differend.model";
import {
    IDifferendDifferendFormData,
    IDifferendGetApiResponse,
} from "../../../Services/Differends.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "../FormCreator.component";
import { IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages } from "../../../Services/Api.service";
import useCreationSteps from "../../../Hooks/useCreationSteps";
import {Store as notificationSystem} from "react-notifications-component";
import { defaultNotificationConfig } from "../../../Shared/config";
import { DifferendsConstants } from "../../../Constants/DifferendsConstants";
import DateHelper from "../../../Helpers/Date.helper";
import CommonHelper from "../../../Helpers/Common.helper";

const DifferendDifferendForm = (props: {
    differend: IDifferend,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IDifferendDifferendFormData) => Promise<IDifferendGetApiResponse>,
    onSuccessSubmit: (response: IDifferendGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IDifferendGetApiResponse) => void,
    onCancel: () => void,
}) => {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const { getFormActions } = useCreationSteps();

    useEffect(() => {
        initForm();
    }, []);

    /**
     * Initialise le formulaire
     * @param {INotaire[]} notaires
     * @param {IGroupe[]} groupes
     * @param {IPersonneExterieure[]} presidents
     */
    const initForm = () => {
        const { differend } = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateReception",
                label: "Date de réception",
                placeholder: "Date",
                required: true,
                oldValue: differend.dateReception ? DateHelper.getFormatedDateForInput(differend.dateReception) : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
            {
                type: "select",
                fieldName: "etat",
                label: "Etat",
                hideSearchOnMultiple: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: differend.etat || 'en_cours',
                options: DifferendsConstants.states,
            },
            {
                type: "select",
                fieldName: "objet",
                label: "Objet",
                hideSearchOnMultiple: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: differend.objet || '',
                options: DifferendsConstants.subjects,
            },

        ];

        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                label: "Référence",
                fieldName: "reference",
                oldValue: differend.reference || '',
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
        ];
        if(!props.isOnCreationStep) {

            formElementsColumn2.push({
                type:"informations",
                label: "Date de modification",
                oldValue:differend.dateModification ? DateHelper.getFormatedDate(differend.dateModification) : ''
            });
            formElementsColumn2.push(
                {
                    type: 'datetime-local',
                    withTime: true,
                    fieldName: "dateConvocationBureau",
                    label: "Date de convocation devant le bureau",
                    placeholder: "Date",
                    required: false,
                    oldValue: differend.dateConvocationBureau ? DateHelper.getFormatedDateForInput(differend.dateConvocationBureau) : "",
                    modificators: props.isOnCreationStep ? "-on-white" : '',
                },
            );
        }
        const formElementsColumn3: FormElement[] = [

            {
                type: 'wysiwyg',
                label: "Notes",
                fieldName: "note",
                oldValue: differend.note || '',
                modificators: props.isOnCreationStep ? "-on-white" : '',
                showMentions: false,
            },

        ];
        const currentFormColumns: FormColumn[] = [
            {
                modificators: "",
                elements: formElementsColumn1
            },
            {
                modificators: "",
                elements: formElementsColumn2
            },
            {
                modificators: "",
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit);
        setFormActions(currentFormActions);
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IDifferendDifferendFormData).then((response: IDifferendGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le différend a bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la mise à jour du differend.",
                    type: "danger"
                });
            }
        });
    };

    if (!formColumns) return;

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
};

export default DifferendDifferendForm;
