import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import UseParametre from "src/Hooks/UseParametre";
import {IParametre} from "src/Models/Parametre.model";
import AsideParametresComponent from "src/Components/Aside/AsideParametres.component";
import AccordionWithFormSwitchComponent from "../../Components/Accordions/AccordionWithFormSwitch.component";
import ParametreInformationsFormComponent from "../../Components/Forms/Parametres/ParametreInformationsForm.component";
import {IParametreInformationsFormData, ParametresService} from "../../Services/Parametres.service";
import ParametreInformationsComponent from "../../Components/Parametres/ParametreInformations.component";


type IParametreFicheScreenProps = {
    pid: string
}
export default function ParametreFicheScreen(props: IParametreFicheScreenProps){
    const informationAccordionRef = useRef(null);

    const {getParametreById} = UseParametre();
    const [parametre, setParametre] = useState<IParametre>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const parametresService: ParametresService = new ParametresService();
    const [formOpened, setFormOpened] = useState<boolean>(false);



    useEffect(() => {
        if(!parametre) return;

        let breadcrumbLinks: IBreadcrumbLink[] = [
            {text: "Paramètres", link: AppConstants.pathParametres },
            {text: parametre.nom},
        ];

        setBreadcrumbLinks(breadcrumbLinks);
    }, [parametre, loaded]);



    useEffect(() => {
        if( !props.pid ){
            if(parametre.module === "formations"){
                history.push(AppConstants.pathFormations);
            }
        }

        getParametre();
    }, [props.pid])


    /**
     * Récupération du diffrend selon l'uid
     */
    const getParametre = (): void => {
        getParametreById(props.pid, (parametre: IParametre) => {
            setParametre(parametre);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getParametre();

        closeForms();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationAccordionRef && informationAccordionRef.current) ){
            informationAccordionRef.current.hideForm(isCancel);
        }
    }



    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={parametre.nom} icon="icon-cog" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={informationAccordionRef}
                                    title="Informations"
                                    icon="icon-informations"
                                    contentShowedOnInit={true}
                                    contentType="parametre"
                                    contentId={parametre.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<ParametreInformationsFormComponent parametre={parametre} submitMethod={(formData: IParametreInformationsFormData)=>parametresService.updateInformations(props.pid,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<ParametreInformationsComponent parametre={parametre} />}
                                />


                                <AsideParametresComponent type="fiche" parametre={parametre} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
