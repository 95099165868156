import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {CertificationsService, ICertificationGetApiResponse, ICertificationInformationsFormData,} from "src/Services/Certifications.service";
import {ICertification} from "src/Models/Certification.model";
import CertificationInformationsFormComponent from "src/Components/Forms/Annuaire/Certifications/CertificationInformationsForm.component";


type ICertificationCreationScreenProps = {
}
export default function CertificationCreationScreen(props: ICertificationCreationScreenProps){
    const history = useHistory();
    const certificationsService: CertificationsService = new CertificationsService();

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: ICertificationGetApiResponse): void => {
        if(response.datas && response.datas.certification){
            const certification: ICertification = response.datas.certification;
            history.push(AppConstants.pathCertifications + '/' + certification.id);
        }
        else{
            history.push(AppConstants.pathCertifications);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathCertifications);

    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une certifications" icon="icon-certifications" modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />

            <CertificationInformationsFormComponent submitMethod={(formData: ICertificationInformationsFormData)=>certificationsService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />
        </LayoutComponent>
    )
}
