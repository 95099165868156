import React, {useEffect, useState} from "react";
import {IAccueilNote} from "../../Models/AccueiNote.model";
import ReactHtmlParser from "react-html-parser";

export type IAccueilNoteDetailProps = {
    note: IAccueilNote
}


const AccueilNoteDetail = (props: IAccueilNoteDetailProps) => {
    const [note, setNote] = useState<IAccueilNote>(null);

    useEffect(() => {
        setNote(props.note)
    }, [props.note]);


    return (
        <div className={"accueil-note-details"}>
            { ReactHtmlParser(props.note.texteFormated) }
        </div>
    )
}
export default AccueilNoteDetail;
