import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants, ICreationSteps} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import UseDifferend from "src/Hooks/UseDifferend";
import {IDifferend} from "src/Models/Differend.model";
import {DifferendsConstants} from "src/Constants/DifferendsConstants";
import AsideDifferendsComponent from "src/Components/Aside/AsideDifferends.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import DifferendNotairesForm from "src/Components/Forms/Differends/DifferendNotairesForm.component";
import {
    DifferendsService,
    IDifferendDifferendFormData, IDifferendDocumentsFormData,
    IDifferendNotairesFormData
} from "src/Services/Differends.service";
import DifferendNotairesComponent from "src/Components/Differends/DifferendNotaires.component";
import DifferendDifferendForm from "src/Components/Forms/Differends/DifferendDifferendForm.component";
import DifferendDifferendComponent from "src/Components/Differends/DifferendDifferend.component";
import DifferendHelper from "src/Helpers/Differend.helper";
import DifferendDocumentsFormForm from "src/Components/Forms/Differends/DifferendDocumentsForm.component";
import DifferendDocumentsComponent from "src/Components/Differends/DifferendDocuments.component";
import DifferendCourrierForm from "src/Components/Forms/Differends/DifferendCourrierForm.component";


type IDifferendFicheScreenScreenProps = {
    did: string
}
export default function DifferendFicheScreen(props: IDifferendFicheScreenScreenProps){
    const notairesAccordionRef = useRef(null);
    const informationsAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);

    const differendService: DifferendsService = new DifferendsService();

    const {getDifferendById} = UseDifferend();
    const [differend, setDifferend] = useState<IDifferend>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [creationSteps, setCreationSteps] = useState<ICreationSteps[]>([]);
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!differend) return;

        const breadcrumbLinks = [
            {text: "Différends", link: AppConstants.pathDifferends},
            {text: `Différend entre ${differend.plaignant} et ${differend.misEnCause} ${ differend.objet ? differend.objet : ""}`},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

        setCreationSteps( DifferendsConstants.creationSteps);
    }, [differend, loaded]);



    useEffect(() => {
        if( !props.did ){
            history.push(AppConstants.pathDifferends);
        }

        getDifferend();
    }, [props.did])


    /**
     * Récupération du diffrend selon l'uid
     */
    const getDifferend = (): void => {
        getDifferendById(props.did, (differend: IDifferend) => {
            setDifferend(differend);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getDifferend();

        closeForms();
    };

    /**
     * Callback au succès du submit de l'envoi de courrier
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitCourrierForm = (response: IApiCustomResponse): void => {
        getDifferend();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if(notairesAccordionRef && notairesAccordionRef.current){
            notairesAccordionRef.current.hideForm(isCancel);
        }
        if(informationsAccordionRef && informationsAccordionRef.current){
            informationsAccordionRef.current.hideForm(isCancel);
        }
        if(documentsAccordionRef && documentsAccordionRef.current){
            documentsAccordionRef.current.hideForm(isCancel);
        }
    };


    
    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={`Différend entre ${differend.plaignant} et ${differend.misEnCause}  ${differend.objet ? '(' + DifferendHelper.getFormattedObjet(differend.objet) + ')' : ''}`} icon="icon-differends" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
	                            <AccordionWithFormSwitchComponent
		                            ref={notairesAccordionRef}
		                            title="Notaires"
		                            icon="icon-notaires"
		                            contentShowedOnInit={true}
		                            contentType="differend"
		                            contentId={differend.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
		                            form={<DifferendNotairesForm differend={differend} submitMethod={(formData: IDifferendNotairesFormData)=>differendService.notaires( formData,differend.id)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
		                            infos={<DifferendNotairesComponent differend={differend} />}
	                            />
	                            <AccordionWithFormSwitchComponent
		                            ref={informationsAccordionRef}
		                            title="Informations"
		                            icon="icon-informations"
		                            contentShowedOnInit={true}
		                            contentType="differend"
		                            contentId={differend.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
		                            form={<DifferendDifferendForm differend={differend} submitMethod={(formData: IDifferendDifferendFormData)=>differendService.differend( formData,differend.id)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
		                            infos={<DifferendDifferendComponent differend={differend} />}
	                            />


                                <AsideDifferendsComponent type="fiche" differend={differend} />
                            </div>
                        </TabPanelComponent>

	                    <TabPanelComponent label="Documents" icon="icon-documents">
		                    <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="differend"
                                    contentId={differend.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<DifferendDocumentsFormForm differend={differend}
                                                                     submitMethod={(formData: IDifferendDocumentsFormData) => differendService.updateDocuments(differend.id, formData)}
                                                                     onSuccessSubmit={onSuccessSubmitForm}
                                                                     onCancel={onCancelForm}/>}
                                    infos={<DifferendDocumentsComponent differend={differend}/>}
                                />

			                    <AsideDifferendsComponent type="fiche" differend={differend} />
		                    </div>
	                    </TabPanelComponent>

                        <TabPanelComponent label="Courriers" icon="icon-courrier">
                            <div className="fiche-content -right-gutter border-footer">

                                <div className="g-content">
	                                <DifferendCourrierForm differend={differend} onSuccessSubmit={onSuccessSubmitCourrierForm} />
                                </div>

                                <AsideDifferendsComponent type="fiche" differend={differend} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
