import ReactHtmlParser from 'react-html-parser';

type ICommentairesComponentProps = {
    commentaires: string,
    modificators?: string
}

export default function CommentairesComponent(props: ICommentairesComponentProps) {
    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className={`column ${props.modificators || '-size-2'}`}>
                    {
                        props.commentaires &&
                        <div className="informations-text">
                            { ReactHtmlParser(props.commentaires) }
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}
