import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {AppConstants} from "../../Constants/AppConstants";


type IAsideClassementsComponentProps = {
    modificators?: string,
    type: "list" | "fiche",
    filters?: any,
    donnee?: string,

    exportCallback?: () => void
}

export default function AsideClassementsComponent(props: IAsideClassementsComponentProps ) {
    const {exportListPDF} = useListExportPDF();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];
        actions.push({icon: "icon-print", click: () => exportPDF()})

        setActions(actions);
    }, [props.filters]);


    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        let url:string = `api${AppConstants.pathClassements}/export-pdf`;

        //Pour les statistiques le tableau est vraiment différent (d'où l'appel à une autre methode de l'API)
        if(props.donnee === "statistiques") {
            url = `api${AppConstants.pathClassements}/statistiques/export-pdf`
        }

        const filters = {...props.filters, donnee: props.donnee};

        exportListPDF(filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions}  isLoading={isLoading}/>
        </>
    )
}
