import React, {useEffect, useState} from "react";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {ICorrespondanceGetApiResponse, CorrespondancesService, ICorrespondanceFormData} from "src/Services/Correspondances.service";
import {ICorrespondance} from "src/Models/Correspondance.model";
import useCorrespondance from "src/Hooks/UseCorrespondance";
import CorrespondancePrecisionsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondancePrecisionsForm.component";
import CorrespondanceComplementsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceComplementsForm.component";
import CorrespondanceDocumentsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceDocumentsForm.component";
import CorrespondanceCreateForm
    from "../../../Components/Forms/Correspondances/Courriers/CorrespondanceCreateForm.component";

type ICorrespondanceCreationStepsScreenProps = {
    cid?: number,
    type: string
}


const CorrespondanceCreationStepsScreen = (props:ICorrespondanceCreationStepsScreenProps) => {
    const correspondancesService: CorrespondancesService = new CorrespondancesService();
    const [getCorrespondanceById] = useCorrespondance();
    const [correspondance, setCorrespondance] = useState<ICorrespondance>(null);
    const {redirectToNextCreationStep} = useCreationSteps();
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if (props.cid){
            getCorrespondance();
        } else {
            setLoaded(true);
        }
    }, [props.cid]);


    const getCorrespondance = (): void => {
        getCorrespondanceById(props.cid, (c: ICorrespondance) => {
            setCorrespondance(c);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Callback au succès du submit du formulaire
     *
     * @param {ICorrespondanceGetApiResponse} response
     */
    const onSuccessSubmitForm = (response: ICorrespondanceGetApiResponse): void => {
        if(response.datas && response.datas.correspondance){
            const correspondance: ICorrespondance = response.datas.correspondance;
            history.push(AppConstants.pathCorrespondances + '/' + correspondance.id);
        }
        else{
            history.push(AppConstants.pathCorrespondances);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {ICorrespondanceGetApiResponse} response
     */
    const onSuccessSubmitNext = (response: ICorrespondanceGetApiResponse): void => {
        if(response.datas && response.datas.correspondance){
            const correspondance: ICorrespondance = response.datas.correspondance;
            redirectToNextCreationStep(AppConstants.pathCorrespondances, correspondance.id, correspondance.creationSteps);
        }
        else{
            history.push(AppConstants.pathCorrespondances);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathCorrespondances);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une correspondance" icon="icon-correspondances-add" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
                    {
                        props.type === "informations" &&
                        <>
                            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />
                            <CorrespondanceCreateForm correspondance={correspondance} submitMethod={(formData: ICorrespondanceFormData)=>correspondancesService.updateCorrespondanceInformations(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}

export default CorrespondanceCreationStepsScreen;
