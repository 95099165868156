import {IOption} from "src/Components/Fields/Select.field.component";

export type IParametresConstants = {
    modulesOptions: IOption[],
}
export const ParametresConstants: IParametresConstants = {
    modulesOptions: [
        {
            value: "formations",
            label: "Formations"
        },
        {
            value: "cotisations",
            label: "Cotisations"
        },
    ],
};
