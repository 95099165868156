import DateHelper from "src/Helpers/Date.helper";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import OfficesHelper from "src/Helpers/Offices.helper";
import FileHelper from "src/Helpers/File.helper";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";


type IOfficeNotairesComponentProps = {
    notaires: INotaire[],
}

export default function OfficeNotairesComponent(props: IOfficeNotairesComponentProps) {
    const notaires: INotaire[] = props.notaires;
    const history = useHistory();

    const getImagePlaceholder = function(sexe: string): string{
        let imagePlaceholder: string = "";

        switch (sexe) {
            case "masculin":
                imagePlaceholder = FileHelper.getPlaceholderUrl("picture-man");
                break;
            case "feminin":
                imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
                break;

            case "nonPrecise":
            default:
                imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
                break;
        }

        return imagePlaceholder;
    }

    return (
        <section className="informations-wrapper -notaires">
            <div className="columns"  >
            {
                notaires && notaires.map((notaire: INotaire, index: number) =>
                    <div className="column -notaire informations-notaire" onClick={() => history.push(AppConstants.pathNotaires + "/" + notaire.id)} key={index}>
                        <div className={"image-wrapper"}>
                            <figure className="informations-image -list-notaire">
                                <img src={notaire.photo ? FileHelper.getImageUrlFromSize(notaire.photo,"officeNotaire") : getImagePlaceholder(notaire.sexe)} alt="" className="image" />
                            </figure>
                        </div>

                        <div className={"content"}>
                            <p className="name">{notaire.nom} {notaire.prenom}</p>
                            {
                                notaire.type &&
                                    <p className="info">{NotairesHelper.getFormattedType(notaire.type)}</p>
                            }
                            {
                                notaire.historiqueEnCours && notaire.historiqueEnCours.officeType &&
                                <>
                                    <p className="info">{DateHelper.getFormatedDate(notaire.historiqueEnCours.dateArrivee)}</p>
                                </>
                            }
                        </div>
                    </div>
                )
            }
            </div>
        </section>
    );
}
