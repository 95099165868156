import React, {ChangeEvent, useEffect, useState} from "react";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "src/Services/Api.service";
import DateHelper from "../../Helpers/Date.helper";
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";
import StringHelper from "../../Helpers/String.helper";

export type IFormField = {
    fieldName?: string,

    label?: string,
    labelModificators?: string,

    placeholder?: string,
    required?: boolean,
    modificators?: string,
    maxLength?: number,
    disabled?: boolean,
    showFieldErrorDetail?: boolean,

    errors?: IApiErrorMessage,
}

export type InputFieldProps = IFormField & {
    oldValue?: string,
    onChange: (value: string) => void,
}

export default function TextareaFieldComponent(props: InputFieldProps){
    const [currentValue, setCurrentValue] = useState<string>("");
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const [init, setInit] = useState<boolean>(false);


    useEffect(()=> {
        if(init) return;
        let value = props.oldValue;
        setCurrentValue(value);

        setInit(true);

    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);

    /**
     * Ecoute du onChange sur le champ
     *
     * @param {React.ChangeEvent<HTMLTextAreaElement>} e
     */
    const onChangeInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let value: string = e.target.value;
        value = StringHelper.removeAccents(value)

        setCurrentValue(value);
        props.onChange(value);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    };



    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}
            <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                <textarea className={"form-field-input -textarea -flex-full-w"}
                      name={props.fieldName}
                      placeholder={props.placeholder}
                      required={props.required}
                      value={currentValue}
                      disabled={props.disabled || false}
                      onChange={onChangeInput}
                />
            </div>

            {
                props.showFieldErrorDetail &&
                    <ErrorFieldsMessagesComponent fieldsErrorsMessages={props.errors} />
            }
        </div>
    )
}
