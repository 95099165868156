import React, {useEffect, useState} from "react";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {AppConstants} from "src/Constants/AppConstants";
import moment, {Moment} from "moment";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {useHistory, useLocation} from "react-router";
import {CotisationsService, ICotisationsSaisieOfficesGetApiResponse, ICotisationsSaisieOfficesGetFormData} from "src/Services/Cotisations.service";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import YearsListComponent from "src/Components/Cotisations/YearsList.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import CotisationSaisieOfficeGarantieCollectiveComponent from "src/Components/Cotisations/CotisationSaisieOfficeGarantieCollective.component";
import CotisationSaisieOfficeAbsenceCollaborateurComponent from "src/Components/Cotisations/CotisationSaisieOfficeAbsenceCollaborateur.component";
import CotisationSaisieOfficeCSNComponent from "src/Components/Cotisations/CotisationSaisieOfficeCSN.component";
import CotisationSaisieOfficeAssuranceRCComponent from "src/Components/Cotisations/CotisationSaisieOfficeAssuranceRC.component";
import CotisationSaisieOfficePerteExploitationComponent from "src/Components/Cotisations/CotisationSaisieOfficePerteExploitation.component";
import CotisationSaisieOfficeCyberComponent from "src/Components/Cotisations/CotisationSaisieOfficeCyber.component";
import CotisationSaisieOfficeCotisationChambreComponent from "src/Components/Cotisations/CotisationSaisieOfficeCotisationChambre.component";
import CotisationSaisieOfficeCridonComponent from "src/Components/Cotisations/CotisationSaisieOfficeCridon.component";
import CotisationSaisieOfficeRecapitulatifComponent from "src/Components/Cotisations/CotisationSaisieOfficeRecapitulatif.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideCotisationsOfficesComponent from "../../Components/Aside/AsideCotisationsOffices.component";
import CotisationsOfficesExportComponent from "../../Components/Cotisations/CotisationsOfficesExport.component";
import LoadingComponent from "../../Components/Loading/Loading.component";


type ICotisationsSaisieOfficesScreenProps = {
};

export default function CotisationsSaisieOfficesScreen(props: ICotisationsSaisieOfficesScreenProps){
    const currentDate:Moment = moment();

    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [cotisationsOfficesList, setCotisationsOfficesList] = useState<ICotisationsOffice[]>([]);
    const [cotisationsYears, setCotisationsYears] = useState<number[]>(null);
    const [currentYear, setCurrentYear] = useState<number>(parseInt(currentDate.format("YYYY")));
    const [currentTab, setCurrentTab] = useState<string>("garantieCollective");

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathCotisationsSaisieOffices);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [editingStatus, setEditingStatus] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    
    

    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Cotisations",  link: AppConstants.pathMosaique + '#cotisations'},
            { text: "Offices", link: AppConstants.pathCotisationsListeOffices },
            { text: "Saisie Offices" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
        
        
        const newCotisationsYears: number[] = [];
        for(let i = 0, j = 5; i < j; i++){
            const newYear: number = currentYear - i;
            newCotisationsYears.push(newYear);
        }
        setCotisationsYears(newCotisationsYears)
    }, []);


    
    useEffect(() => {
        const params: any = location.search;

        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        defaultFilters.year = currentYear;

        updateFilters(defaultFilters);
    }, [currentYear])


    useEffect(()=>{
        if(!filters) return null;
        setEditingStatus(false);

        launchFilteredSearch();
    },[filters]);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[currentTab]);

    /**
     * Récupération des cotisations
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const cotisationsService: CotisationsService = new CotisationsService();
        filters.currentTab = currentTab;

        cotisationsService.getCotisationsForOffices(filters as ICotisationsSaisieOfficesGetFormData).then((response: ICotisationsSaisieOfficesGetApiResponse) => {
            if (response && response.datas && response.datas.pagination) {
                const newCotisationsOfficesList = response.datas.pagination.data;
                setCotisationsOfficesList(newCotisationsOfficesList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        launchFilteredSearch();
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
    };




    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Cotisations"
                    subText="Saisie Offices"
                    icon="icon-cotisations"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />

                <YearsListComponent years={cotisationsYears} setCurrentYear={setCurrentYear} currentYear={currentYear} />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent onClickTabCallback={(slug) => { setCurrentTab(slug);}}>
                            <TabPanelComponent label="Garantie Collective"  slug="garantieCollective">
                                <CotisationSaisieOfficeGarantieCollectiveComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Absence Collaborateur" slug="absenceCollaborateur">
                                <CotisationSaisieOfficeAbsenceCollaborateurComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="CSN" slug="csn">
                                <CotisationSaisieOfficeCSNComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Assurance RC" slug="assuranceRC">
                                <CotisationSaisieOfficeAssuranceRCComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Perte exploitation" slug="perteExploitation">
                                <CotisationSaisieOfficePerteExploitationComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Cyber" slug="cyber">
                                <CotisationSaisieOfficeCyberComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Cotisation Chambre"  slug="cotisationChambre">
                                <CotisationSaisieOfficeCotisationChambreComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Cridon" slug="cridon">
                                <CotisationSaisieOfficeCridonComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Récapitulatif" slug="recapitulatif">
                                <CotisationSaisieOfficeRecapitulatifComponent
                                    cotisationsOffices={cotisationsOfficesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieOffice" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            <CotisationsOfficesExportComponent cotisationsYears={cotisationsYears} setShowExport={() => setShowExport(false)} showExport={showExport} />
        </LayoutComponent>
    )
}
