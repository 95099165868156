import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";
import moment, {Moment} from "moment";

type ICotisationsOfficeRecapCridonComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapCridonComponent(props: ICotisationsOfficeRecapCridonComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;
    const currentDate:Moment = moment();
    const currentYear: string = currentDate.format("YYYY");


    return (
        <div className="informations-wrapper">
            <div className="columns">
                <p className="title">Cotisation Année {currentYear}</p>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">TTC</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cridonTTC, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">HT</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cridonHT, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">TVA</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cridonTVA, 2,2)} €</p>
                    </div>
                </div>
            </div>

            <div className="columns">
                <p className="title">Trop perçu</p>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">TTC</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.tropPercuTTC, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">HT</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.tropPercuHT, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">TVA</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.tropPercuTVA, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
