import React from "react";

import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import DateHelper from "src/Helpers/Date.helper";
import {IDocument} from "src/Models/Document.model";


const TableauBordDocuments = (props: {
    documents: IDocument[]
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.documents && (props.documents.length > 0) && props.documents.map((d, index) =>
                    <div className="columns" key={index}>
                        <p className="title">{ `${DateHelper.getMonthFromDate(d.date)} ${DateHelper.getYearFromDate(d.date)}` }</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Fichier</p>
                                {
                                    !d.fichier &&
                                    <p className="value">-</p>
                                }
                                {
                                    d.fichier &&
                                    <DocumentLink icon="icon-documents" link={d.fichier ? FileHelper.getFileUrl(d.fichier) : '-'} />
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default TableauBordDocuments;
