import React from "react";
import {AppConstants} from "../../Constants/AppConstants";

export type IAccueilStatsComponentProps = {
    compositions: any
}

const AccueilCompositionDetails = (props: IAccueilStatsComponentProps) => {

    return (
        <div className="home-compositions-details">
            {
                props.compositions && props.compositions.map((c: any, index: number) =>
                    c.list && c.list.length > 0 &&
                    <React.Fragment key={index}>
                        <p className="fonctions">{c.nom}</p>
                        <div className="personnes">
                            {
                                c.list && c.list.map((p: any, indexP: number) => <a href={`${AppConstants.pathNotaires}/${p.id}`} key={indexP} className="g-link -text personne">{p.nom}</a> )
                            }
                        </div>
                    </React.Fragment>
                )
            }
        </div>
    )
}
export default AccueilCompositionDetails;
