import React from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {AdresseConstants} from "src/Constants/AdresseConstants";

type AnnexesAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,

}
export default function AnnexesAdvancedSearchComponent(props: AnnexesAdvancedSearchComponentProps){
    return (
        <AdvancedSearchComponent title="Annexes"
             showSummary={props.showSummary}
             modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
             showAdvancedSearch={props.showAdvancedSearch}
             filters={props.filters}
             columns={[
                 {
                     elements: [
                         {
                             type: 'hidden',
                             fieldName: "advanced",
                             oldValue: "1",
                             modificators: "-hidden"
                         },
                         {
                             type: "text",
                             fieldName: "nom",
                             label: "Nom",
                             modificators: "-on-white",
                             oldValue: props.filters.nom as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "CRPCEN",
                             label: "CRPCEN",
                             modificators: "-on-white",
                             oldValue: props.filters.CRPCEN as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "ville",
                             label: "Ville",
                             modificators: "-on-white",
                             oldValue: props.filters.ville as string || ''
                         },

                         {
                             type: "text",
                             fieldName: "codepostal",
                             label: "Code postal",
                             modificators: "-on-white",
                             oldValue: props.filters.codepostal as string || ''
                         }
                     ]
                 },
                 {
                     elements: [
                         {
                             type: "text",
                             fieldName: "mail_comptabilite",
                             label: "Mail comptabilité",
                             modificators: "-on-white",
                             oldValue: props.filters.mail_comptabilite as string || ''
                         },
                         /*
                         {
                             type: "select",
                             fieldName: "arrondissement",
                             label: "Arrondissement",
                             modificators: "-on-white",
                             options: AdresseConstants.arrondissementsOptions,
                             hideSearchOnMultiple: true,
                             oldValue: props.filters.arrondissement as string || ''
                         },
                         {
                             type: "select",
                             fieldName: "tribunal_judiciaire",
                             label: "Tribunal judiciaire",
                             modificators: "-on-white",
                             options: AdresseConstants.tribunalJudiciaireOptions,
                             hideSearchOnMultiple: true,
                             oldValue: props.filters.tribunal_judiciaire as string || ''
                         },
                          */
                         {
                             type: "selectMultiple",
                             fieldName: "departement",
                             label: "Département",
                             modificators: "-on-white",
                             hideSearchOnMultiple: true,
                             options: OfficesConstants.departementsOptions,
                             oldValues: props.filters.departement as string[] || []
                         },
                     ]
                 },
             ]}
             onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
             onClose={() => props.setShowAdvancedSearch()}
             onSearch={props.onSearch}
        />
    )

}
