import React, { useEffect, useState } from "react";
import {
    DifferendsService,
    IDifferendGetApiResponse,
    IDifferendNotairesFormData
} from "../../Services/Differends.service";
import useCreationSteps from "../../Hooks/useCreationSteps";
import { IDifferend } from "../../Models/Differend.model";
import { useHistory } from "react-router";
import UseDifferend from "../../Hooks/UseDifferend";
import { AppConstants } from "../../Constants/AppConstants";
import LayoutComponent from "../../Components/Layout/Layout.component";
import PageHeaderComponent from "../../Components/Page/PageHeader.component";
import InformationHeaderComponent from "../../Components/Informations/InformationHeader.component";
import DifferendCreationForm from "../../Components/Forms/Differends/DifferendCreationForm.component";

type IDifferendCreationStepsScreenProps = {
    did?: number,
    type: string,
}


const DifferendCreationStepsScreen = (props: IDifferendCreationStepsScreenProps) => {
    const differendsService: DifferendsService = new DifferendsService();
    const { getDifferendById } = UseDifferend();
    const { redirectToNextCreationStep } = useCreationSteps();
    const [differend, setDifferend] = useState<IDifferend>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if (props.did) {
            getDifferendById(props.did.toString(), (s: IDifferend) => {
                setDifferend(s);
                setLoaded(true);
            })
        }
        else {
            setLoaded(true);
        }
    }, [props.did]);


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IDifferendGetApiResponse} response
     */
    const onSuccessSubmitForm = (response: IDifferendGetApiResponse): void => {
        if (response.datas && response.datas.differend) {
            const differend: IDifferend = response.datas.differend;
            history.push(AppConstants.pathDifferends + '/' + differend.id);
        }
        else {
            history.push(AppConstants.pathDifferends);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IDifferendGetApiResponse} response
     */
    const onSuccessSubmitNext = (response: IDifferendGetApiResponse): void => {
        if (response.datas && response.datas.differend) {
            const differend: IDifferend = response.datas.differend;
            redirectToNextCreationStep(AppConstants.pathDifferends, differend.id, differend.creationSteps);
        }
        else {
            history.push(AppConstants.pathDifferends);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathDifferends);
    };

    const getDifferendStepTitle = (differend: IDifferend): string => {
        return `Différend entre ${differend.plaignant} et ${differend.misEnCause}`;
    }




    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text={props.type !== "notaires" && differend ? getDifferendStepTitle(differend) : "Ajouter un différend"} icon="icon-differends-add" modificators={`-in-fullscreen -border-full-screen`} />

            {
                loaded &&
                <>
                    {
                        props.type === "notaires" &&
                        <>
                            <InformationHeaderComponent icon={"icon-notaires"} title={"Informations"} modificators="-margin-bottom" />
                            <DifferendCreationForm differend={differend} submitMethod={(formData: IDifferendNotairesFormData) => differendsService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
};

export default DifferendCreationStepsScreen;
