import ApiService, {IApiCustomResponse} from "./Api.service";
import {IAccueilNote} from "../Models/AccueiNote.model";

export type IAccueilGetApiResponse = IApiCustomResponse & {
    datas: {
        stats: any,
        compositions: any,
        note: IAccueilNote
    }
}
export type IAccueilNoteGetApiResponse = IApiCustomResponse & {
    datas: {
        note: IAccueilNote
    }
}
export type IAccueilNoteFormData = {
    text?: string,
}

export class AccueilService extends ApiService {

    /**
     * Permet de récupérer les infos de la page d'accueil
     * @returns {Promise<IAccueilGetApiResponse>}
     */
    public getAccueilInfos(): Promise<IAccueilGetApiResponse>{
        const url: string = 'api/accueil';

        return this.doApiCall(url, 'GET');
    }


    /**
     * Mise à jour d'une note
     *
     * @param {number} noteId
     * @param {IAccueilNoteFormData} formData
     * @returns {Promise<IAccueilNoteGetApiResponse>}
     */
    public updateNote(noteId: number, formData: IAccueilNoteFormData): Promise<IAccueilNoteGetApiResponse>{
        const url: string = 'api/accueil/note/' + noteId + '/update';

        return this.doApiCall(url, 'POST', formData);
    }
}




