import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IGroupe} from "src/Models/Groupe.model";
import useGroupe from "src/Hooks/UseGroupe";
import AsideGroupesComponent from "src/Components/Aside/AsideGroupes.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import GroupeInformationsFormComponent from "src/Components/Forms/Groupes/GroupeInformationsForm.component";
import GroupeInformationsComponent from "src/Components/Annuaire/Groupes/GroupeInformations.component";
import {GroupesService, IGroupeInformationsFormData} from "src/Services/Groupes.service";
import {IOffice} from "src/Models/Office.model";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {IOrganisme} from "src/Models/Organisme.model";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import GroupesListExportComponent from "src/Components/Annuaire/Groupes/GroupesListExport.component";
import useAbonnes from "src/Hooks/UseAbonnes";
import {IGroupeMembre} from "src/Models/GroupeMembre.model";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {Link} from "react-router-dom";


type IGroupeFicheScreenProps = {
    gid: string,
}

type IGroupeAbonnes = string[];

export default function GroupeFicheScreen(props: IGroupeFicheScreenProps){
    const groupesServices: GroupesService = new GroupesService();
    const informationAccordionRef = useRef(null);
    const [getGroupeById] = useGroupe();
    const [getGroupeAbonnes] = useAbonnes();
    const [groupe, setGroupe] = useState<IGroupe>(null);
    const [abonnes, setGroupeAbonne] = useState<IGroupeAbonnes>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [membreSelected, setMembreSelected] = useState<IGroupeMembre>(null);
    const [showConfirmationAnnulation, setShowConfirmationAnnulation] = useState<boolean>(false);
    const history = useHistory();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!groupe) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Groupes", link: AppConstants.pathGroupes},
            {text: groupe.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

    }, [groupe, abonnes, loaded])

    useEffect(() => {
        if( !props.gid ){
            history.push(AppConstants.pathGroupes);
        }

        getGroupe();
    }, [props.gid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getGroupe = (): void => {
        getGroupeById(props.gid, (groupe: IGroupe) => {
            setGroupe(groupe);
            getAbonnes(groupe);
        });
    };

    /**
     * Annule la demande d'annulation
     */
    const onCancelAnnulation = () => {
        setMembreSelected(null);
        setShowConfirmationAnnulation(false);
    };


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getAbonnes = (groupe: IGroupe): void => {
        let wait = false;
        for (let i = 0; i < groupe.types.length; i++) {
            if (groupe.types[i].slug === "abonnes-fil-dinfos") {
                getGroupeAbonnes((abonnes: IGroupeAbonnes) => {
                    setGroupeAbonne(abonnes);
                    wait = true;
                    if (!loaded) {
                        setLoaded(true);
                    }
                });
            }
        }

        if (!loaded && !wait) {
            setGroupeAbonne([])
            setLoaded(true);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getGroupe();

        closeForms();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationAccordionRef && informationAccordionRef.current) ){
            informationAccordionRef.current.hideForm(isCancel);
        }
    }

    const removeMembre = () => {
        const datas:{[key:string]:any} = {
            notaires: [],
            personnes: [],
            offices: [],
            organismes: [],
        };

        if (membreSelected.notaire) {
            datas.notaires.push(membreSelected.notaire.id);
        }

        if (membreSelected.personne) {
            datas.personnes.push(membreSelected.personne.id);
        }

        if (membreSelected.office) {
            datas.offices.push(membreSelected.office.id);
        }

        if (membreSelected.organisme) {
            datas.organismes.push(membreSelected.organisme.id);
        }

        groupesServices.removeGroupeMembre(groupe.id, datas).then((result) => {
            setMembreSelected(null);
            setShowConfirmationAnnulation(false);
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le membre a bien été supprimé",
                type: "success"
            });

            if (result.datas && result.datas.groupe) {
                setGroupe(result.datas.groupe);
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression.",
                type: "danger"
            });
        });
    };


    let headers: {label: string, states: string[]}[] = [];
    if( groupe && groupe.id === 1 ){
        headers = [
            {label: "Nom", states: []},
            {label: "Type", states: []},
        ];
    }
    else{
        headers = [
            {label: "Nom", states: []},
            {label: "Email", states: []},
            {label: "Type", states: []},
            {label: "Étude/Organisme", states: []},
            {label: "Adresse", states: []},
            {label: "Ville", states: []},
            {label: "", states: []},
        ];
    }



    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={groupe.nom} icon="icon-groupes" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={informationAccordionRef}
                                    title="Informations"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="groupe"
                                    contentId={groupe.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<GroupeInformationsFormComponent groupe={groupe} submitMethod={(formData: IGroupeInformationsFormData)=>groupesServices.update(props.gid, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<GroupeInformationsComponent groupe={groupe} />}
                                />

                                <table className={`list-table`}>
                                    <thead className={"headers"}>
                                        <tr>
                                            {
                                                headers.map((item, iIndex) =>
                                                    <React.Fragment key={iIndex}>
                                                        {
                                                            <th className="th">
                                                                <span className="link">
                                                                    <span className="text">{item.label}</span>
                                                                </span>
                                                            </th>
                                                        }
                                                    </React.Fragment>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        /* Groupes avec office */
                                        (groupe.id != 2) && groupe.offices && groupe.offices.map((office: IOffice, index: number) =>
                                            <tr key={index} className="tr" >
                                            <td className="td -bold">
                                                <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${office.id}`} onClick={(e) => e.stopPropagation()}>{office.nom}</Link>
                                            </td>
                                                <td className="td">
                                                    { Array.isArray(office.emailPrincipal) ? office.emailPrincipal.join(', ') : office.emailPrincipal}
                                                </td>
                                            <td className="td">
                                                {office.type}
                                            </td>
                                            <td className="td">
                                                <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${office.id}`} onClick={(e) => e.stopPropagation()}>{office.nom}</Link>
                                            </td>
                                            <td className="td">
                                                {
                                                    office.adressePrincipale &&
                                                    <>
                                                        {
                                                            office.adressePrincipale.ligne1 &&
                                                            <>
                                                                {office.adressePrincipale.ligne1}<br/>
                                                            </>
                                                        }
                                                        {
                                                            office.adressePrincipale.ligne2 &&
                                                            <>
                                                                {office.adressePrincipale.ligne2}<br/>
                                                            </>
                                                        }
                                                        {office.adressePrincipale.codePostal} {office.adressePrincipale.ville}
                                                    </>
                                                }
                                            </td>
                                            <td className="td -uppercase">
                                                {
                                                    office.adressePrincipale &&
                                                    <>
                                                        {office.adressePrincipale.ville}
                                                    </>
                                                }
                                            </td>
                                            <td className="td">
                                                <button className="g-button -delete-button icon-remove" onClick={() => {
                                                    setMembreSelected({
                                                        'office': office
                                                    });
                                                    setShowConfirmationAnnulation(true)
                                                }}/>
                                            </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        /* Groupes des comptables */
                                        groupe.id === 2 && groupe.offices && groupe.offices.map((office: IOffice, index: number) =>
                                            <tr key={index} className="tr" >
                                                <td className="td -bold">
                                                    Comptable : {office.nom}
                                                </td>
                                                <td className="td">
                                                    { Array.isArray(office.emailsComptables) ? office.emailsComptables.join(', ') : office.emailsComptables}
                                                </td>
                                                <td className="td">
                                                    {office.type}
                                                </td>
                                                <td className="td">
                                                    <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${office.id}`} onClick={(e) => e.stopPropagation()}>{office.nom}</Link>
                                                </td>
                                                <td className="td">
                                                    {
                                                        office.adressePrincipale &&
                                                            <>
                                                                {
                                                                    office.adressePrincipale.ligne1 &&
                                                                    <>
                                                                        {office.adressePrincipale.ligne1}<br/>
                                                                    </>
                                                                }
                                                                {
                                                                    office.adressePrincipale.ligne2 &&
                                                                    <>
                                                                        {office.adressePrincipale.ligne2}<br/>
                                                                    </>
                                                                }
                                                                {office.adressePrincipale.codePostal} {office.adressePrincipale.ville}
                                                            </>
                                                    }
                                                </td>
                                                <td className="td -uppercase">
                                                    {
                                                        office.adressePrincipale &&
                                                            <>
                                                            {office.adressePrincipale.ville}
                                                            </>
                                                    }
                                                </td>
                                                <td className="td">
                                                    <button className="g-button -delete-button icon-remove" onClick={() => {
                                                        setMembreSelected({
                                                            'office': office
                                                        });
                                                        setShowConfirmationAnnulation(true)
                                                    }}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        groupe.notaires && groupe.notaires.map((notaire: INotaire, index: number) =>
                                            <tr key={index} className="tr" >
                                                <td className="td -bold" onClick={() => history.push(`${AppConstants.pathNotaires}/${notaire.id}`)}>
                                                    <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathNotaires}/${notaire.id}`} onClick={(e) => e.stopPropagation()}>{notaire.nom} {notaire.prenom}</Link>
                                                </td>
                                                <td className="td">
                                                    { Array.isArray(notaire.emailPrincipal) ? notaire.emailPrincipal.join(', ') : notaire.emailPrincipal}
                                                </td>
                                                <td className="td">
                                                    {NotairesHelper.getFormattedType(notaire.type)}
                                                </td>
                                                <td className="td">
                                                    {
                                                        notaire.office &&
                                                            <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${notaire.office.id}`} onClick={(e) => e.stopPropagation()}>{notaire.office.nom}</Link>
                                                    }
                                                </td>
                                                <td className="td">
                                                    {
                                                        notaire.adressePrincipale &&
                                                            <>
                                                                {
                                                                    notaire.adressePrincipale.ligne1 &&
                                                                    <>
                                                                        {notaire.adressePrincipale.ligne1}<br/>
                                                                    </>
                                                                }
                                                                {
                                                                    notaire.adressePrincipale.ligne2 &&
                                                                    <>
                                                                        {notaire.adressePrincipale.ligne2}<br/>
                                                                    </>
                                                                }
                                                                {notaire.adressePrincipale.codePostal} {notaire.adressePrincipale.ville}
                                                            </>
                                                    }
                                                </td>
                                                <td className="td -uppercase">
                                                    {notaire.adressePrincipale ? notaire.adressePrincipale.ville : ''}
                                                </td>
                                                <td className="td">
                                                    <button className="g-button -delete-button icon-remove" onClick={() => {
                                                        setMembreSelected({
                                                            'notaire': notaire
                                                        });
                                                        setShowConfirmationAnnulation(true)
                                                    }}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        groupe.organismes && groupe.organismes.map((organisme: IOrganisme, index: number) =>
                                            <tr key={index} className="tr">
                                                <td className="td -bold"  onClick={() => history.push(`${AppConstants.pathOrganismes}/${organisme.id}`)}>
                                                    <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOrganismes}/${organisme.id}`} onClick={(e) => e.stopPropagation()}>{organisme.nom}</Link>
                                                </td>
                                                <td className="td">
                                                    { Array.isArray(organisme.emails) ? organisme.emails.join(', ') : organisme.emails}
                                                </td>
                                                <td className="td">
                                                    Organisme
                                                </td>
                                                <td className="td"> </td>
                                                <td className="td">
                                                    { organisme.adressePrincipale &&
                                                        <>
                                                            {
                                                                organisme.adressePrincipale.ligne1 &&
                                                                <>
                                                                    {organisme.adressePrincipale.ligne1}<br/>
                                                                </>
                                                            }
                                                            {
                                                                organisme.adressePrincipale.ligne2 &&
                                                                <>
                                                                    {organisme.adressePrincipale.ligne2}<br/>
                                                                </>
                                                            }
                                                            {organisme.adressePrincipale.codePostal} {organisme.adressePrincipale.ville}
                                                        </>
                                                    }
                                                </td>
                                                <td className="td -uppercase">
                                                    { organisme.adressePrincipale &&
                                                        <>
                                                            {organisme.adressePrincipale.ville}
                                                        </>
                                                    }
                                                </td>
                                                <td className="td">
                                                    <button className="g-button -delete-button icon-remove" onClick={() => {
                                                        setMembreSelected({
                                                            'organisme': organisme
                                                        });
                                                        setShowConfirmationAnnulation(true)
                                                    }}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        groupe.personnesExterieures && groupe.personnesExterieures.map((personneExterieure: IPersonneExterieure, index: number) =>
                                            <tr key={index} className="tr" >
                                                <td className="td -bold" onClick={() => history.push(`${AppConstants.pathPersonnesExterieures}/${personneExterieure.id}`)}>
                                                    {personneExterieure.nom} {personneExterieure.prenom}
                                                </td>
                                                <td className="td">
                                                    { Array.isArray(personneExterieure.emails) ? personneExterieure.emails.join(', ') : personneExterieure.emails}
                                                </td>
                                                <td className="td">
                                                    Personne Extérieure
                                                </td>
                                                <td className="td">
                                                    {personneExterieure.organisme &&
                                                    <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOrganismes}/${personneExterieure.organisme.id}`} onClick={(e) => e.stopPropagation()}>{personneExterieure.organisme.nom}</Link>
                                                    }
                                                </td>
                                                <td className="td">
                                                    { personneExterieure.adressePrincipale &&
                                                        <>
                                                            {
                                                                personneExterieure.adressePrincipale.ligne1 &&
                                                                <>
                                                                    {personneExterieure.adressePrincipale.ligne1}<br/>
                                                                </>
                                                            }
                                                            {
                                                                personneExterieure.adressePrincipale.ligne2 &&
                                                                <>
                                                                    {personneExterieure.adressePrincipale.ligne2}<br/>
                                                                </>
                                                            }
                                                            {personneExterieure.adressePrincipale.codePostal} {personneExterieure.adressePrincipale.ville}
                                                        </>
                                                    }
                                                </td>
                                                <td className="td -uppercase">
                                                    { personneExterieure.adressePrincipale &&
                                                        <>
                                                            {personneExterieure.adressePrincipale.ville}
                                                        </>
                                                    }
                                                </td>
                                                <td className="td">
                                                    <button className="g-button -delete-button icon-remove" onClick={() => {
                                                        setMembreSelected({
                                                            'personne': personneExterieure
                                                        });
                                                        setShowConfirmationAnnulation(true)
                                                    }}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        abonnes && abonnes.map((abonne: string, index: number) =>
                                            <tr key={index} className="tr">
                                                <td className="td -bold">
                                                    {abonne}
                                                </td>
                                                <td className="td">Abonné</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>

                                <AsideGroupesComponent type="fiche" groupe={groupe} exportCallback={() => setShowExport(true)} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                    <GroupesListExportComponent groupe={groupe} setShowExport={() => setShowExport(false)} showExport={showExport} />
                </LayoutComponent>
            }
            {showConfirmationAnnulation && <ConfirmationComponent onConfirm={()=> removeMembre()} onCancel={onCancelAnnulation} text="Souhaitez-vous vraiment supprimer ce membre ?"/>}
        </>
    )
}
