import React from "react";

type ErrorPageScreenProps = {
    code?:number,
    message: string,
};

export default function ErrorPageScreen(props: ErrorPageScreenProps){
    return (
        <div className="">
            <h1 className="">Une erreur irrécupérable est survenue.</h1>
        </div>
    )
}
