import moment from "moment";
import 'moment/locale/fr';
import {IOption} from "../Components/Fields/Select.field.component";
import parseISO from "date-fns/parseISO";

export default class DateHelper{

    /**
     * Retourne un tableau d'option avec Année n-1 et n+1 basé sur l'année en paramètre
     * @param {string} year
     * @returns {IOption[]}
     */
    static getYearRangeOptions(): IOption[] {
        const current = moment().format("YYYY");
        const min = moment().subtract(1,"y").format("YYYY");
        const max = moment().add(1,"y").format("YYYY");
        return [
            {value: min, label: min},
            {value: current, label: current},
            {value: max, label: max},
        ];
    }

    /**

     * Retourne un tableau d'option avec Année n+1 basé sur l'année en cours
     * @param {string} year
     * @returns {IOption[]}
     */
    static getYearRangeOptionsForHabilitations(): IOption[] {
        const current = moment().format("YYYY");
        const max = moment().add(1, "y").format("YYYY");

        return [
            {value: current, label: current},
            {value: max, label: max},
        ];
    }

    
    /**
     * Retourne un tableau d'option avec Année n-X et n+X basé sur l'année en cours
     * 
     * @param {number} amountMin
     * @param {number} amountMax
     * @returns {IOption[]}
     */
    static getCompleteYearRangeOptions(amountMin: number = 1, amountMax: number = 1): IOption[]{
        const current = moment().format("YYYY");
        const min = moment().subtract(amountMin,"y").format("YYYY");
        const max = moment().add(amountMax,"y").format("YYYY");

        const options = [];
        for(let i = parseInt(min); i < parseInt(current); i++){
            options.push(
                {value: i.toString(), label: i.toString()}
            );
        }
        options.push(
            {value: current, label: current}
        );

        for(let i = parseInt(current); i < parseInt(max); i++){
            options.push(
                {value: i.toString(), label: i.toString()}
            );
        }
        return options;
    }


    /**
     * Permet de récupérer une date formatée à partir d'une date en string
     *
     * @param {string} date
     * @param {boolean} showHour
     * @returns {string}
     */
    static getFormatedDate(date: string, showHour: boolean = false): string {
        let mask = "L";
        if(showHour) mask += " HH[h]mm";
        let formatedDate = this.formatDate(date, mask);
        return formatedDate;
    }
    static getFormatedDateForTableauBord(date: string): string{
        let mask = "MMMM YYYY";
        let formatedDate = this.formatDate(date, mask);
        return formatedDate;
    }

    /**
     * Permet de récupérer une heure formatée à partir d'une date en string
     *
     * @param {string} date
     * @returns {string}
     */
    static getFormatedHour(date: string): string {
        let mask = "HH[h]mm";
        let formatedDate = this.formatDate(date, mask);
        return formatedDate;
    }

    /**
     * Permet de récupérer une année formatée à partir d'une date en string
     *
     * @param {string} date
     * @param {boolean} showHour
     * @returns {string}
     */
    static getFormatedYear(date: string): string {
        return this.formatDate(date, 'Y');
    }

    /**
     * Permet de formater une date pour une champ texte
     *
     * @param {string} date
     * @returns {string}
     */
    static getFormatedDateForInput(date: string): string{
        let formatedDate = this.formatDate(date, "YYYY-MM-DD");
        return formatedDate;
    }
    /**
     * Permet de formater une date time pour une champ texte
     *
     * @param {string} date
     * @returns {string}
     */
    static getFormatedDateTimeForInput(date: string): string{
        let formatedDate = this.formatDate(date, "YYYY-MM-DD HH:mm");
        return formatedDate;
    }


    /**
     * Permet de récupérer l'année à artir d'une date
     *
     * @param {string} date
     * @returns {string}
     */
    public static getYearFromDate(date: string): string{
        let formatedDate = this.formatDate(date, "YYYY");
        return formatedDate;
    }

    /**
     * Permet de récupérer le mois à artir d'une date
     *
     * @param {string} date
     * @returns {string}
     */
    public static getMonthFromDate(date: string): string{
        let formatedDate = this.formatDate(date, "MMMM");
        return formatedDate;
    }


    /**
     * Permet de formater une date dans le format passé en paramètre
     *
     * @param {string} date
     * @param {string} format
     * @returns {string}
     * @public
     */
    public static formatDate(date: string, format: string): string{
        let formatedDate: string = date;

        moment.locale('fr');
        const momentDate = moment(date);
        if(momentDate){
            formatedDate = momentDate.format(format);
        }

        return formatedDate;
    }

    /**
     * Indique si deux dates appartiennent au même jour
     * @param {string} date1
     * @param {string} date2
     * @returns {boolean}
     */
    public static isSameDay(date1: string, date2:string): boolean{
        return Math.abs(moment(date1).startOf('day').diff(moment(date2).startOf('day'), 'days')) === 0;
    }

    /**
     * Indique si deux dates appartiennent à la même année
     * @param {string} date1
     * @param {string} date2
     * @returns {boolean}
     */
    public static isSameYear(date1: string, date2:string): boolean{
        return Math.abs(moment(date1).startOf('year').diff(moment(date2).startOf('year'), 'years')) === 0;
    }

    /**
     * Permet de récupérer l'année en cours
     *
     * @returns {string}
     */
    public static getCurrentYear(): string{
        moment.locale('fr');
        const momentDate = moment();

        return momentDate.format('Y');
    }

    /**
     * Permet de récupérer le jour en cours
     *
     * @returns {string}
     */
    public static getCurrentDay(): string{
        moment.locale('fr');
        const momentDate = moment();

        return momentDate.format('DD');
    }


    /**
     * Permet de récupérer le mois en cours
     * @returns {string}
     */
    public static getCurrentMonth(isCourt?: boolean): string{
        moment.locale('fr');
        const momentDate = moment();

        return momentDate.format(isCourt ? 'MMM' : 'MMMM');
    }
    /**
     * Permet de récupérer le mois en cours
     * @returns {string}
     */
    public static getLastMonth(): string{
        moment.locale('fr');
        const momentDate = moment();

        return momentDate.subtract(1, 'month').format('MMMM');
    }

    /**
     * Permte de récupérer l'année précédente
     *
     * @returns {string}
     */
    public static getLastYear(): string{
        moment.locale('fr');
        const momentDate = moment();

        return momentDate.subtract(1, 'y').format('Y');
    }


    /**
     * Validation d'une date input
     *
     * @param date
     * @param {false} withTime
     * @returns {boolean}
     */
    public static validateInputDate(date: any, withTime: boolean): boolean {
        if( date instanceof Date){
            if(date.getDay() == null || isNaN(date.getDay()) ) return false;
            if(date.getMonth() == null || isNaN(date.getMonth()) ) return false;
            if(date.getFullYear() == null || isNaN(date.getFullYear()) ) return false;

            if(withTime){
                if(date.getHours() == null || isNaN(date.getHours()) ) return false;
                if(date.getMinutes() == null || isNaN(date.getMinutes()) ) return false;
            }

            const momentDateObject = moment(date);
            if(!momentDateObject.isValid()){
                return false;
            }

            return true;
        }

        if(!date) return false;
        if(date.length != 10 && !withTime) return false;//dd/mm/yyyy
        if(date.length != 16 && withTime) return false;//dd/mm/yyyy hh:mm
        if(!date.includes("h") && withTime) return false;//dd/mm/yyyy hh:mm
        if(date.split("/").length != 3) return false;

        return true;
    }


    /**
     * Permet de convertir une date string en object date
     *
     * @param {string} dateString
     * @param {boolean} withTime
     * @returns {null | Date}
     */
    public static convertDateString(dateString: string, withTime: boolean){
        if( !this.validateInputDate(dateString,withTime)) {
            return null;
        }

        let dateFormat = 'DD/MM/YYYY';
        if(withTime) {
            dateFormat = 'DD/MM/YYYY H\hi';
        }

        const momentDateObject = moment(dateString, dateFormat);

        if(!momentDateObject.isValid()){
            return null;
        }

        return momentDateObject.toDate();
    }
}
