import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";

type ICotisationsOfficeRecapDecesInvaliditeComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapDecesInvaliditeComponent(props: ICotisationsOfficeRecapDecesInvaliditeComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantDecesInvalidite, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
