import React, {useEffect, useState} from "react";

const SwitchComponent = (props: {
    isActive: boolean,
    onClick: (isActive: boolean) => void,
    modificators?: string,
}) => {

    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    const onClick = () => {
        if(props.onClick) props.onClick(!isActive);
        setIsActive(!isActive);
    };

    return <span className={`switch-item ${isActive ? '-active' : ''} ${props.modificators ? props.modificators : "" }`} onClick={onClick}>
        <span className="circle icon-bullet"></span>
    </span>
};

export default SwitchComponent;
