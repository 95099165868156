import ApiService, {IApiCustomResponse} from "./Api.service";
import {INotaire} from "../Models/Notaire.model";
import {IOffice} from "../Models/Office.model";
import {IOrganisme} from "../Models/Organisme.model";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";
import {ISuccession} from "../Models/Succession.model";
import {IFormation} from "../Models/Formation.model";
import {ISinistre} from "../Models/Sinistre.model";
import {IReclamation} from "../Models/Reclamation.model";
import {IDifferend} from "../Models/Differend.model";

export type IGlobalSearchFormData = {
    keyword: string
}

export type IGlobalSearchApiResponse = IApiCustomResponse & {
    datas: {
        notaires: INotaire[],
        anciensNotaires: INotaire[],
        offices: IOffice[],
        successions: ISuccession[],
        formations?: IFormation[],
        sinistres?: ISinistre[],
        reclamations?: IReclamation[],
        differends?: IDifferend[],
        organismes: IOrganisme[],
        personnesExterieures: IPersonneExterieure[],
    }
};

export class SearchService extends ApiService{
    /**
     * Permet la récupération d'une liste de notaires
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public doGlobalSearch(data: IGlobalSearchFormData): Promise<IGlobalSearchApiResponse>{
        return this.doApiCall('api/recherche', 'GET', data);
    }


}
