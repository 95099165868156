import {UserConstants} from "src/Constants/UsersConstants";
import {IOption} from "src/Components/Fields/Select.field.component";
import {IUserModule} from "../Models/User.model";
import CommonHelper from "./Common.helper";

export default class UserHelper{
    /**
     * Retourne le role de l'utilisateur à partir de la clé passée en paramètre
     * @param {string} role
     * @returns {string}
     */
    static getFormatedRole(role: string): string {
        return CommonHelper.getFormatedElementFromOptions(role, UserConstants.rolesOptions);
    }


    /**
     * Retourne les modules utilisées par l'utilisateur
     *
     * @param {string[]} modules
     * @returns {string}
     */
    static getFormatedAllowedModules(modules: IUserModule[]): string {
        if(!modules || !modules.length) return;

        let activeModules: string[] = [];
        activeModules = modules.filter((m) => m.active === 1).map(function(m) {
            return m.nom;
        });

        return activeModules.join(', ');
    }
}
