import React, {SyntheticEvent, useContext, useState} from "react";
import AccountBlockComponent from "src/Components/Account/AccountBlock.component";
import {Link, useHistory} from "react-router-dom";
import {Module, modules} from "src/Constants/ModulesConstants";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import { useLocation } from 'react-router-dom'
import {AppConstants} from "../../Constants/AppConstants";


type LayoutComponentProps = {
    wrapperModificators?: string,
    contentModificators?: string,
    showMenu: boolean,
    children?: React.ReactNode
}

const LayoutComponent = ({wrapperModificators, contentModificators, showMenu, children}: LayoutComponentProps) => {

    const location = useLocation();
    const history = useHistory();
    const {checkModuleAllowed, isAdmin} = useContext(UserContext) as IUserContext;
    const [isMenuClosed, setIsMenuCLosed] = useState(true);

    /**
     * Redirige vers un module
     * @param {string} path
     */
    const goToModule = (path: string): void => {
        history.push(path);
    };

    /**
     * Ouvre/ferme un module
     * @param {React.SyntheticEvent} e
     */
    const toggleOpenedModule = (e: SyntheticEvent): void => {
        e.currentTarget.parentElement.classList.toggle("-is-opened")
    };

    const hasCurrentPageInIt = (module: Module) => {
        let found = false;

        if(module.submodules){
            const sub = module.submodules.findIndex((s)=> isCurrentLink(s.link));

            if(sub !== -1) found = true;
        }

        return found;
    };

    const isCurrentLink = (path:string) => {
        return path === location.pathname;
    }


    /**
     * Redirection vers la home
     * @returns {boolean}
     */
    const redirectToHome = () => {
        history.push(AppConstants.pathHome);
        return false;
    }
    /**
     * Redirection vers la page de mosaique
     * @returns {boolean}
     */
    const redirectToMosaique = () => {
        history.push(AppConstants.pathMosaique);
        return false;
    }


    return <div className={`page-wrapper ${wrapperModificators || ''} ${showMenu ? '-with-menu' : ''} ${isMenuClosed ? '-closed-menu' : ''}`}>

        {showMenu && <aside className={`page-menu`}>
            {
                isMenuClosed &&
                <button className="button" onClick={() => redirectToHome()} title="Accueil">
                    <i className="icon icon-accueil"/>
                </button>
            }
            {
                isMenuClosed &&
                <button className="button" onClick={() => redirectToMosaique()} title="Mosaïque">
                    <i className="icon icon-mosaique"/>
                </button>
            }
            <button className={`button`} onClick={()=>setIsMenuCLosed(!isMenuClosed)}  title="Menu">
                {!isMenuClosed && <i className={`icon icon-close`} />}
                {isMenuClosed && <i className={`icon icon-burger -small`} />}
            </button>


	        <AccountBlockComponent modificators={`-menu`} />
            <div className="content">
                <Link to="/recherche" className={`search`}><i className={`icon icon-search`}></i> Recherche</Link>
                
                {modules.map((module,moduleIndex)=>checkModuleAllowed(module.slug) &&
		            <div className={`menu-module ${hasCurrentPageInIt(module) ? '-is-opened' : ''}`} key={moduleIndex}>
			            <span className="name" onClick={(e)=>module.link ? goToModule(module.link) : toggleOpenedModule(e)}>{module.name}</span>
                        {module.submodules && <div className={`submodules`}>
                            {module.submodules.map((sub,subIndex)=><Link key={subIndex} to={sub.link} className={`link ${isCurrentLink(sub.link) ? '-active' : ''}`}><i className='icon icon-arrow-right-full'></i>{sub.name}</Link>)}
                        </div>}
		            </div>
                )}
            </div>
        </aside>}

        <div className={`content ${contentModificators || ''}`}>
            {children}

            <div className="page-footer"></div>
        </div>

    </div>

};

export default LayoutComponent;
