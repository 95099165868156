import {IOption} from "src/Components/Fields/Select.field.component";

export type INegociateursConstants = {
    typesOptions: IOption[],
    labelsOptions: IOption[],
}
export const NegociateursConstants: INegociateursConstants = {
    typesOptions: [
        {
            value: "venteLocation",
            label: "Vente et location"
        },
        {
            value: "vente",
            label: "Vente"
        },
        {
            value: "location",
            label: "Location"
        },
    ],
    labelsOptions: [
        {
            value: "rev",
            label: "REV : Recognised European Valuer"
        },
        {
            value: "trv",
            label: "TRV : Tegova Residential Valuer"
        },
    ],
};
