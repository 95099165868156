import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AutresContactsService, IOrganismeGetApiResponse} from "../Services/AutresContacts.service";
import {IOrganisme} from "../Models/Organisme.model";

export default function useOrganismes(){
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const history = useHistory();

    /**
     * Récupération d'un organisme à partir de son id
     *
     * @param {string} oid
     * @param {(organisme: IOrganisme) => void} callback
     * @param {() => void} callbackError
     */
    const getOrganismeById = (oid: string, callback:(organisme: IOrganisme) => void, callbackError?: () => void ) => {
        autresContactsService.getOrganisme(oid).then((response: IOrganismeGetApiResponse) => {
            if( response && response.datas.organisme){
                if(callback){
                    callback(response.datas.organisme);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOrganismes);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de l'organisme'",type: "danger"});
        });
    };

    return [getOrganismeById];
};

