import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type FormationsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    activeTab: string,
    filters?: any,

}
export default function FormationsExportComponent(props: FormationsExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Formations"}
                         icon="icon-formations"
                         subTitle={"Export des formations"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl= {`api${AppConstants.pathFormations}/export/${props.activeTab}`}
                         filters={props.filters || null}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-formations',
                                         fieldName: "informations",
                                         label: "Informations",
                                         options: [
                                             {value: 'titre', label: 'Titre'},
                                             {value: 'etat', label: 'Etat'},
                                             {value: 'categorie', label: 'Catégorie'},
                                             {value: "nbMaxParticipants", label: "Nb Participants"},
                                             {value: "nbHeures", label: "Nb Heures"},
                                             {value: "lieu", label: "Lieu"},
                                             {value: "adresse", label: "Adresse"},
                                             {value: "cout", label: "Coût"},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-precisions',
                                         fieldName: "precisions",
                                         label: "Précisions",
                                         options: [
                                             {value: 'urlQuestionnaire', label: 'Url du questionnaire'},
                                             {value: 'urlReponse', label: 'Url de réponse'},
                                             {value: 'covoiturage', label: 'Covoiturage'},
                                             {value: 'repas', label: 'Repas'},
                                             {value: 'repasNom', label: 'Type de repas'},
                                             {value: 'repasLieu', label: 'Lieu de repas'},
                                             {value: 'repasHeure', label: 'Heure de repas'},
                                             {value: 'cocktail', label: 'Cocktail'},
                                             {value: 'cocktailNom', label: 'Type de cocktail'},
                                             {value: 'cocktailLieu', label: 'Lieu de cocktail'},
                                             {value: 'cocktailHeure', label: 'Heure de cocktail'},
                                             {value: 'commentaires', label: 'Commentaires'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-termine',
                                         fieldName: "dates",
                                         label: "Dates",
                                         options: [
                                             {value: 'date', label: 'Date de début'},
                                             {value: 'dateFin', label: 'Date de fin'},
                                             {value: 'heureDebut', label: 'Horaire de début'},
                                             {value: 'heureFin', label: 'Horaire de fin'},
                                             {value: 'dateLimiteReponse', label: 'Date de limite de réponse'},
                                             {value: 'dateConfirmation', label: 'Date de confirmation'},
                                             {value: 'dateClotureDossier', label: 'Date de clôture du dossier'},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
