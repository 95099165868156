import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {CertificationsService, ICertificationsGetFormData, ICertificationsListGetApiResponse} from "src/Services/Certifications.service";
import {ICertification} from "src/Models/Certification.model";
import AsideCertificationsComponent from "src/Components/Aside/AsideCertifications.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";

type CertificationsListScreenProps = {

}
export default function CertificationsListScreen(props: CertificationsListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [certificationsList, setCertificationsList] = useState<ICertification[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder] = useListFilters("/annuaire/certifications");
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Annuaire", link: AppConstants.pathAnnuaire},
            { text: "Certifications" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Récupération des utilisateurs
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const certificationsService: CertificationsService = new CertificationsService();

        certificationsService.getCertifications(filters as ICertificationsGetFormData).then((response: ICertificationsListGetApiResponse) => {
            if( response && response.datas.pagination){
                setCertificationsList(response.datas.pagination.data);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if(!loaded){
                    setLoaded(true);
                }
            }
        });
    }




    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text="Certifications" icon="icon-certifications"/>

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {["nom"].map((item, iIndex)=><th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent direction={filters.orderType as string || AppConstants.orderType.asc} value={item} selectedValue={filters.orderBy as string || ""} label={item} click={(value:string)=>setOrder(value)} />
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                certificationsList && certificationsList.map( (certification: ICertification, index: number) =>
                                                    <tr key={index} onClick={()=>history.push(`/annuaire/certifications/${certification.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {certification.nom}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <PaginationComponent links={paginationLinks}  onPageClick={page=>updateFilters({page})}/>

                                    <AsideCertificationsComponent type='list' filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    )
}
