import ApiService, {
    IApiCustomResponse,
    IApiPagination
} from "./Api.service";
import {ILettreType} from "src/Models/LettreType.model";

export type ILettreTypesGetPaginationApi = IApiPagination & {
    data: ILettreType[],
}

export type ILettreTypesFormData = {}

export type ILettreTypesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ILettreTypesGetPaginationApi
    }
}

export type ILettreTypeGetApiResponse = IApiCustomResponse & {
    datas?: {
        lettreType: ILettreType
    }
}
export type ILettreTypesAllGetApiResponse = IApiCustomResponse & {
    datas?: {
        lettreTypes: ILettreType[]
    }
}

export type ILettreTypeFormData = {}

export type ILettreTypeCourrierFormData = {
    numeroFacture: number,
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}

export type ILettreTypesGetFormData = {
    page: number,
    orderBy: string,
    old?: number
}
export type ILettreTypeSendMailListFormData = {
    ids: string[],
}

export class LettreTypesService extends ApiService{
    /**
     * Permet la récupération d'une liste de lettre types
     * @param {ILettreTypesGetFormData} formData
     * @returns {Promise<ILettreTypesGetApiResponse>}
     */
    public getLettreTypes(formData: ILettreTypesGetFormData): Promise<ILettreTypesGetApiResponse>{
        return this.doApiCall('api/correspondances/lettres-types', 'GET', formData);
    }

    public getLettreType(lid: number): Promise<ILettreTypeGetApiResponse>{
        return this.doApiCall('api/correspondances/lettres-types/' + lid, 'GET');
    }

    public updateType(lid: number, formDatas: ILettreTypesFormData): Promise<ILettreTypeGetApiResponse>{
        return this.doApiCall(`api/correspondances/lettres-types/${lid}/update`, 'POST', formDatas);
    }
}
