import DateHelper from "src/Helpers/Date.helper";
import FileHelper from "src/Helpers/File.helper";
import React from "react";
import {IDocument} from "src/Models/Document.model";
import {IInspection} from "src/Models/Inspection.model";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import InspectionsHelper from "../../../Helpers/Inspections.helper";

type IInspectionDocumentsComponentProps = {
    inspection: IInspection,
}

export default function InspectionDocumentsComponent(props: IInspectionDocumentsComponentProps) {
    const inspection: IInspection = props.inspection;
    const documents: IDocument[] = inspection.documents;

    return (
        <div className="informations-wrapper">
            {
                documents && (documents.length > 0) && documents.map((d, index) =>
                    <div className="columns" key={index}>
                        <p className="title"> {`${d.type ? InspectionsHelper.getFormatedDocumentTypes(d.type): ''}` }</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date</p>
                                <p className="value">{d.dateDepot ? DateHelper.getFormatedDate(d.dateDepot) : '-'}</p>
                            </div>
                        </div>

                        {
                            (d.type == "inspection-lettre-mission" || d.type == "inspection-rapport-procureur") &&
                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Fichier</p>
                                    {
                                        !d.fichier &&
                                        <p className="value">-</p>
                                    }
                                    {
                                        d.fichier &&
                                        <DocumentLink icon="icon-documents" link={d.fichier ? FileHelper.getFileUrl(d.fichier) : '-'} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                )
            }
        </div>
    );
}
