import React from "react";
import {IFormationParticipant} from "src/Models/FormationParticipant.model";
import DateHelper from "src/Helpers/Date.helper";
import FormationsHelper from "src/Helpers/Formations.helper";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";

type INotaireFormationsListComponentProps = {
    formations: IFormationParticipant[],
}

export default function NotaireFormationsListComponent(props: INotaireFormationsListComponentProps) {
    const formations: IFormationParticipant[] = props.formations;
    const history = useHistory();

    const headers: { label: string, states: string[] }[] = [
        {label: "Titre", states: []},
        {label: "Date", states: []},
        {label: "Etat de l'inscription", states: []},
    ];


    return (
        <>
            {
                formations && (formations.length > 0) &&
                <table className={`list-table`}>
                    <thead className={"headers"}>
                    <tr>
                        {
                            headers.map((item, iIndex) =>
                                <React.Fragment key={iIndex}>
                                    {
                                        <th className="th">
                                            <span className="link">
                                                <span className="text">{item.label}</span>
                                            </span>
                                        </th>
                                    }
                                </React.Fragment>
                            )
                        }
                    </tr>
                    </thead>

                    <tbody>
                    {
                        formations && formations.map((participant: IFormationParticipant, index: number) =>
                            <React.Fragment key={index}>
                                {participant.formation &&
                                    <tr key={index} className="tr"
                                        onClick={() => history.push(`${AppConstants.pathFormations}/${participant.formation.id}`)}>
                                        <td className="td -bold">
                                            {participant.formation.titre}
                                        </td>
                                        <td className="td">
                                            {DateHelper.getFormatedDate(participant.formation.date)}
                                        </td>
                                        <td className="td">
                                            {FormationsHelper.getParticipantEtatFormated(participant.etat)}
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        )
                    }
                    </tbody>

                </table>
            }

            {
                (!formations || formations.length === 0) &&
                <p className="fiche-content-notice">Ce notaire ne participe ou n'a participé à aucune formation.</p>
            }

        </>
    )

}
