import React from "react";
import {IFilInformations} from "src/Models/FilInformations.model";
import FileHelper from "src/Helpers/File.helper";


const FilInformationsBandeauAccueil = (props: {
    filInformations: IFilInformations
}) => {

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column ">
                <div className="informations-block">
                    <p className="label">Image</p>
                    {
                        props.filInformations.imageBandeauAccueil &&
                            <figure className="informations-image -resume">
                                <img src={FileHelper.getImageUrlFromSize(props.filInformations.imageBandeauAccueil, "filBandeauAccueil")} alt="" className="image"/>
                            </figure>
                    }
                    {
                        !props.filInformations.imageBandeauAccueil &&
                        <div className="value">
                            -
                        </div>
                    }
                </div>
            </div>
        </div>

    </div>

};

export default FilInformationsBandeauAccueil;
