import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type IAnnexesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
}
export default function AnnexesExportComponent(props: IAnnexesExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Annexes"}
                         icon="icon-annexe"
                         subTitle={"Export des annexes"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl={`api${AppConstants.pathAnnexes}/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-office',
                                         fieldName: "identite",
                                         label: "Identité",
                                         options: [
                                             {value: 'nom', label: 'Nom de l\'office'},
                                             {value: 'CRPCEN', label: 'CRPCEN'},
                                             {value: 'officePrincipalNom', label: 'Office principal'},
                                             {value: 'officePrincipalType', label: 'Type'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-coordonnees',
                                         fieldName: "coordonnees",
                                         label: "Coordonnées",
                                         options: [
                                             {value: 'coordonnees.emailOffice', label: 'Email Principal'},
                                             {value: 'coordonnees.emailComptabilite', label: 'Email Comptabilité'},
                                             {value: 'coordonnees.emailAutre', label: 'Email Autre'},
                                             {value: 'coordonnees.telephoneOffice', label: 'Téléphone Principal'},
                                             {value: 'coordonnees.telephoneCompta', label: 'Téléphone Comptabilité'},
                                             {value: 'coordonnees.telephoneAutre', label: 'Téléphone Autre'},
                                             {value: 'coordonnees.siteInternet', label: 'Site internet'},
                                             {value: 'adresses.ligne1', label: 'Rue'},
                                             {value: 'adresses.ligne2', label: 'Complément d\'adresse'},
                                             {value: 'adresses.boitePostale', label: 'Boîte Postale'},
                                             {value: 'adresses.codePostal', label: 'Code Postal / CEDEX'},
                                             {value: 'adresses.ville', label: 'Ville'},
                                             /*{value: 'adresses.arrondissement', label: 'Arondissement'},*/
                                             /*{value: 'adresses.tribunalJudiciaire', label: 'Tribunal Judiciaire'},*/
                                             /*{value: 'autres.emailNegociation', label: 'Email de négociation'},*/
                                         ]
                                     },
                                 ],
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-commentaires',
                                         fieldName: "commentaires",
                                         label: "Commentaires",
                                         options: [
                                             {value: 'commentaires', label: 'Commentaires'},
                                         ]
                                     },
                                 ],
                             },
                         ]}
        />
    )

}
