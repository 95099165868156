import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "src/Services/Api.service";
import DateHelper from "../../Helpers/Date.helper";
import {IFormField} from "./Input.field.component";
import CheckboxFieldComponent from "./Checkbox.field.component";

export type CheckboxSingleFieldProps = IFormField & {
    oldValue?: string,
    onChange: (value: string) => void,
}

export default function CheckboxSingleFieldComponent(props: CheckboxSingleFieldProps){
    const [currentValue, setCurrentValue] = useState<string>(null);
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const inputRef = useRef<HTMLInputElement>();


    useEffect(()=> {
        let value = props.oldValue;
        setCurrentValue(value);
    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);

    /**
     * Ecoute du onChange sur le champ
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        setCurrentValue(value);
        props.onChange(value);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    };

    /**
     * Prise en charge d'un changement au niveau d'une checkbox
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newCurrentValue = `${e.target.checked}`;

        setCurrentValue(newCurrentValue);
        if(props.onChange){
            props.onChange(newCurrentValue)
        }

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    };



    return (
        <div className={`form-field ${props.modificators || ""}`}>
            <CheckboxFieldComponent
                modificators={`${errors ? '-error' : ''}`}
                value={currentValue || ""}
                onChange={onCheckboxChange}
                id={props.fieldName}
                name={props.fieldName}
                label={props.label}
                labelModificators={props.labelModificators}
                isChecked={!!currentValue}
            />
        </div>
    )
}
