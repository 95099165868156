import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IFormation} from "src/Models/Formation.model";
import {FormationsService} from "src/Services/Formations.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {FormationConstants} from "../../Constants/FormationsConstants";


type IAsideFormationComponentProps = {
    formation?: IFormation,
    modificators?: string,
    type: "list" | "fiche" | "inscriptions" | "liste-d-attente" | 'annulations' | 'courrier',

    exportCallback?: () => void,
    emargementCallback?: () => void,

    filters?: any
}

export default function AsideFormationComponent(props: IAsideFormationComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];


        if(props.type === "fiche") {
            actions.push({icon: "icon-print", click: () => printScreen()});
        }

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()});
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-formation-add", click: () => history.push(AppConstants.pathFormations + "/creation")});
        }

        if( props.type === "fiche" || props.type === "inscriptions"){
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        }

        if(props.type === "liste-d-attente" || props.type === "inscriptions" || props.type === "annulations"){
            actions.push({icon: "icon-print", click: () => exportPDF()});
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
        }

        if(props.type === "inscriptions"){
            if(props.formation.etat === FormationConstants.stateActif) {
                actions.push({icon: "icon-add", click: () => history.push(`${AppConstants.pathFormations}/${props.formation.id}/inscription`)});
            }

            actions.push({icon: "icon-emargement", click: () => props.emargementCallback()});
        }

        setActions(actions);
    }, [props.type, props.filters]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    };

    /**
     * Suppression
     */
    const doDelete = (): void => {
        const formationsService: FormationsService = new FormationsService();

        formationsService.delete(props.formation.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La Formation a bien été supprimée.",
                type: "success"
            });

            history.push(AppConstants.pathFormations);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression de la formation.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    };

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        let url:string = `api${AppConstants.pathFormations}/export-pdf`;

        if(props.type && props.type !== "list"){
            let etat = "inscrit";
            if(props.type === "liste-d-attente"){
                etat = "enAttente";
            } else if(props.type === "annulations"){
                etat = "annulation";
            }
            url = `api${AppConstants.pathFormations}/${props.formation.id}/participants/export-pdf/${etat}`;
        }

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    };

    return (
        <>
            {
                showConfirmation && props.formation &&
				<ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer cette formation ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
