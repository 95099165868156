import {IApiCustomResponse} from "../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import {useHistory} from "react-router";
import {IDocument} from "../Models/Document.model";
import {DocumentsService, IDocumentGetApiResponse} from "../Services/Document.service";



export default function useDocument(){
    const documentsServices: DocumentsService = new DocumentsService();
    const history = useHistory();

    /**
     * Récupération d'un tableau de bord à partir de son id
     *
     * @param {string} tid
     * @param {(tableauBord: ITableauBord) => void} callback
     * @param {() => void} callbackError
     */
    const getDocumentById = (tid: string, callback:(document: IDocument) => void, callbackError?: () => void ) => {
        documentsServices.getDocument(tid).then((response: IDocumentGetApiResponse) => {
            if( response && response.datas.document){
                if(callback){
                    callback(response.datas.document);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du document'",type: "danger"});
        });
    };
    /**
     * Récupération d'un tableau de bord à partir de son id
     *
     * @param {string} tid
     * @param {(tableauBord: ITableauBord) => void} callback
     * @param {() => void} callbackError
     */
    const getDocumentExternalById = (tid: string, callback:(document: IDocument) => void, callbackError?: () => void ) => {
        documentsServices.getDocumentExternal(tid).then((response: IDocumentGetApiResponse) => {
            if( response && response.datas.document){
                if(callback){
                    callback(response.datas.document);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du document'",type: "danger"});
        });
    };


    return {getDocumentById,getDocumentExternalById};
};

