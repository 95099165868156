import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from "react";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "src/Services/Api.service";
import {InputFieldProps} from "./Input.field.component";
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";

export default function NumberExtendedFieldComponent(props: InputFieldProps){
    const [currentValue, setCurrentValue] = useState<string>("");
    const [initialized, setInitialized] = useState<boolean>(false);
    const [errors, setErrors] = useState<IApiErrorMessage>(null);


    useEffect(()=> {
        if(initialized) return;
        if(!props.oldValue) return;

        let value = props.oldValue;
        setCurrentValue(value);

        //Condition suite au problème de champ qui ne sont pas envoyés lors de la soumission du formulaire (si le champ n'est pas modifié par l'utilisateur)
        if(props.triggerOnChangeOnOldValue) {
            props.onChange(value);
        }

        setInitialized(true);
    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);

    /**
     * Ecoute du onChange sur le champ
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        let value: string = e.target.value.replace(',', '.');

        setCurrentValue(value);
        props.onChange(value);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    };

    /**
     * Vérfication du key press
     * Pour uniquement autoriser les valeurs numériques
     * @param {KeyboardEvent<HTMLInputElement>} e
     */
    const checkKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9.,]/.test(e.key)) {
            e.preventDefault();
        }
    }



    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                <input className="form-field-input -flex-full-w"
                       name={props.fieldName}
                       type="text"
                       placeholder={props.placeholder}
                       required={props.required}
                       value={currentValue}
                       onChange={onChangeInput}
                       onKeyPress={checkKeyPress}
                       maxLength={props.maxLength}
                />
            </div>

            {
                props.showFieldErrorDetail &&
                <ErrorFieldsMessagesComponent fieldsErrorsMessages={props.errors} />
            }
        </div>
    )
}
