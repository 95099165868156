import React, {useEffect, useState} from "react";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {
    IReclamationCreationFormData,
    IReclamationGetApiResponse,
    ReclamationsService
} from "src/Services/Reclamations.service";
import UseReclamation from "src/Hooks/UseReclamation";
import {IReclamation} from "src/Models/Reclamation.model";
import {ICoordonnee} from "../../Models/Coordonnee.model";
import {IAdresse} from "../../Models/Adresse.model";
import ReclamationCreationFormComponent from "../../Components/Forms/Reclamations/ReclamationCreationForm.component";

type IReclamationCreationStepsScreenProps = {
    rid?: number,
    type: string,
}



const ReclamationCreationStepsScreen = (props:IReclamationCreationStepsScreenProps) => {
    const reclamationsService: ReclamationsService = new ReclamationsService();
    const {getReclamationById} = UseReclamation();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [reclamation, setReclamation] = useState<IReclamation>(null);
    const [coordonneesPlaignants, setCoordonnesPlaignants] = useState<ICoordonnee[]>(null);
    const [adressesPlaignants, setAdressesPlaignants] = useState<IAdresse[]>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.rid){
            getReclamationById(props.rid.toString(), (r: IReclamation) => {
                setReclamation(r);

                const newCoordonneesPlaignants: ICoordonnee[] = [];
                const newAdressesPlaignants: IAdresse[] = [];
                if(r.plaignants){
                    for(let p of r.plaignants){
                        for(let c of p.coordonnees){
                            newCoordonneesPlaignants.push(c);
                        }
                        for(let a of p.adresses){
                            newAdressesPlaignants.push(a);
                        }
                    }
                }
                setCoordonnesPlaignants(newCoordonneesPlaignants);
                setAdressesPlaignants(newAdressesPlaignants);


                setLoaded(true);
            })
        } else {
            setLoaded(true);
        }
    }, [props.rid]);


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IReclamationGetApiResponse} response
     */
    const onSuccessSubmitForm = (response: IReclamationGetApiResponse): void => {
        if(response.datas && response.datas.reclamation){
            const reclamation: IReclamation = response.datas.reclamation;
            history.push(AppConstants.pathReclamations + '/' + reclamation.id);
        }
        else{
            history.push(AppConstants.pathReclamations);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IReclamationGetApiResponse} response
     */
    const onSuccessSubmitNext = (response: IReclamationGetApiResponse): void => {
        if(response.datas && response.datas.reclamation){
            const reclamation: IReclamation = response.datas.reclamation;
            redirectToNextCreationStep(AppConstants.pathReclamations, reclamation.id, reclamation.creationSteps);
        }
        else{
            history.push(AppConstants.pathReclamations);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathReclamations);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une réclamation" icon="icon-reclamations-add" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-reclamations"} title={"Reclamation"} modificators="-margin-bottom" />
                            <ReclamationCreationFormComponent isOnCreationStep={true} submitMethod={(formData: IReclamationCreationFormData)=>reclamationsService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}

export default ReclamationCreationStepsScreen;
