import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import UseFileDownload from "../../Hooks/UseFileDownload";
import {StatistiquesService} from "../../Services/StatistiquesService.service";


type IAsideStatistiquesComponentProps = {
    modificators?: string,
    type: "list" | "fiche" | "screen",

    exportCallback?: () => void,

    filters?: any,
    officeId?: string,
}

export default function AsideStatistiquesComponent(props: IAsideStatistiquesComponentProps ) {
    const {handleFileDownload} = UseFileDownload();
    const {exportListPDF} = useListExportPDF();

    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if(props.type === "screen"){
            actions.push({icon: "icon-print", click: () => window.print()})
        } else {
            if( props.type === "list") {
                actions.push({icon: "icon-print", click: (filters) => exportPDF(filters)})
                actions.push({icon: "icon-export", click: () => props.exportCallback()});
                actions.push({icon: "icon-import", click: () => history.push(AppConstants.pathStatistiques + "/import")});
            }
            else{
                actions.push({icon: "icon-print", click: () => exportPDFFiche()})
            }
        }

        setActions(actions);
    }, []);


    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (filters: any): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathStatistiques}/export-pdf`;

        exportListPDF(filters, url, () => { setIsLoading(false)});
    }


    /**
     * Export PDF de la fiche
     */
    const exportPDFFiche = (): void => {
        setIsLoading(true);
        const statsService: StatistiquesService = new StatistiquesService();

        statsService.printStatsForOffice(props.officeId).then((response)=>{
            setIsLoading(false);

            if (response && response.datas.url) {
                handleFileDownload(response.datas.url,true);
            }else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }
        }, (error) => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading} filters={props.filters}/>
        </>
    )
}
