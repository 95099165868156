import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect,useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse} from "src/Services/Api.service";
import DateHelper from "src/Helpers/Date.helper";
import {IDocument} from "src/Models/Document.model";
import {ITableauBord} from "src/Models/TableauBord.model";
import useOffice from "src/Hooks/UseOffice";
import useTableauBord from "src/Hooks/UseTableauBord";
import useDocument from "src/Hooks/UseDocument";
import {IOffice} from "src/Models/Office.model";
import {ITableauBordUploadFormData, TableauxBordService} from "src/Services/TableauxBord.service";
import OfficeTableauBordExternalFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeTableauBordExternalForm.compontent";


type IOfficeTableauBordExternalScreenProps = {
    oid: number,
    did: number,
    tid: number,
}
export default function OfficeTableauBordExternalScreen(props: IOfficeTableauBordExternalScreenProps){
    const [officeLoaded, setOfficeLoaded] = useState<boolean>(false);
    const [tableauBordLoaded, setTableauBordLoaded] = useState<boolean>(false);
    const [documentLoaded, setDocumentLoaded] = useState<boolean>(false);
    const [answered, setAnswered] = useState<boolean>(false);
    const [answeredCurrentForm, setAnsweredCurrentForm] = useState<boolean>(false);
    const [tableauBord, setTableauBord] = useState<ITableauBord>(null);
    const [document, setDocument] = useState<IDocument>(null);
    const [office, setOffice] = useState<IDocument>(null);
    const history = useHistory();
    const [getOfficeById, getOfficeExternalById] = useOffice();
    const {getTableauBordById, getTableauBordExternalById} = useTableauBord();
    const {getDocumentById, getDocumentExternalById} = useDocument();
    const tableauxBordService: TableauxBordService = new TableauxBordService();


    useEffect(() => {
        if( !props.oid ){
            history.push(AppConstants.path403);
        }
        getOffice();

    }, [props.oid])


    useEffect(() => {
        if( !props.tid ){
            history.push(AppConstants.path403);
        }
        getTableauBord();

    }, [props.tid])

    useEffect(() => {
        if( !props.did ){
            history.push(AppConstants.path403);
        }
        getDocument();

    }, [props.did])


    /**
     * Récupération du tableau de bord selon son id
     */
    const getTableauBord = (): void => {
        getTableauBordExternalById(props.tid.toString(), (tableauBord: ITableauBord) => {
            setTableauBord(tableauBord);
            setTableauBordLoaded(true);
        });
    };

    /**
     * Récupération de l'office selon son id
     */
    const getOffice = (): void => {
        getOfficeExternalById(props.oid.toString(), (office: IOffice) => {
            setOffice(office);
            setOfficeLoaded(true);
        });
    };

    /**
     * Récupération du document selon son id
     */
    const getDocument = (): void => {
        getDocumentExternalById(props.did.toString(), (document: IDocument) => {
            setDocument(document);
            setDocumentLoaded(true);

            if(document && document.fichier){
                setAnswered(true)
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getOffice();
        getTableauBord();
        getDocument();
        setAnsweredCurrentForm(true);
    };


    return (
        <>
            {
                officeLoaded && tableauBordLoaded && documentLoaded &&
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
                    <PageHeaderComponent text="Office" subText={`Ajout du tableau de bord ${DateHelper.getMonthFromDate(document.date)} ${DateHelper.getYearFromDate(document.date)} `} icon="icon-formations" modificators={`-in-fullscreen -border-full-screen`}/>


                    {
                        !answered &&
                        <OfficeTableauBordExternalFormComponent submitMethod={(formData: ITableauBordUploadFormData)=>tableauxBordService.uploadTableauBordExternal(props.tid, props.oid, props.did, formData)} onSuccessSubmit={onSuccessSubmitForm} />
                    }

                    {
                        answered && !answeredCurrentForm &&
                        <p className="information-text">
                            Vous avez déjà envoyé votre tableau de bord pour ce mois.
                        </p>
                    }

                    {
                        answered && answeredCurrentForm &&
                        <p className="information-text">
                            Votre fichier a bien été envoyé.
                        </p>
                    }
                </LayoutComponent>
            }
        </>
    )
}
