import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useProtection from "src/Hooks/UseProtection";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {ISinistre} from "src/Models/Sinistre.model";
import {SinistresService} from "src/Services/Sinistres.service";
import useListExportPDF from "../../Hooks/UseListExportPDF";


type IAsideSinistresComponentProps = {
    sinistre?: ISinistre,
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void,

    filters?: any
}

export default function AsideSinistresComponent(props: IAsideSinistresComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const {checkLockProtection, unlockProtection, initProtection} = useProtection();
    const {isAdmin} = useContext(UserContext) as IUserContext;
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-sinistres-add", click: () => history.push(AppConstants.pathSinistres + "/creation")});
        }

        if( props.type === "fiche"){
            actions.push({icon: "icon-print", click: () => printScreen()})
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});

            // const sinistre: ISinistre = props.sinistre;
            // if(isAdmin && sinistre){
            //     initProtection('sinistre', sinistre.id);
            //     checkLockProtection().then((isLocked: boolean) => {
            //         const oldActions = actions;
            //         const newActions = [...oldActions];
            //         if(isLocked){
            //             newActions.push({icon: "icon-unlock", click: () => unlockProtection().then(()=> {
            //                     notificationSystem.addNotification({
            //                         ...defaultNotificationConfig,
            //                         message: "Le sinistre a bien été débloqué.",
            //                         type: "success"
            //                     });
            //
            //                     setActions(oldActions);
            //                 })
            //             });
            //         }
            //
            //         setActions(newActions);
            //     });
            // }
        }

        setActions(actions);
    }, [props.type, props.filters]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const sinistresService: SinistresService = new SinistresService();

        sinistresService.delete(props.sinistre.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le sinistre a bien été supprimé.",
                type: "success"
            });

            history.push(AppConstants.pathSinistres);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du sinistre.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }


    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathSinistres}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }

    return (
        <>
            {
                showConfirmation && props.sinistre &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer cet sinistre ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
