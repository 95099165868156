import React, {useEffect, useRef, useState} from "react";
import {IAccueilNote} from "../../Models/AccueiNote.model";
import AccordionWithFormSwitchComponent from "../Accordions/AccordionWithFormSwitch.component";
import AccueilNoteDetail from "./AccueilNoteDetail.component";
import AccueilNoteFormComponent from "../Forms/Accueil/AccueilNoteForm.component";
import {AccueilService, IAccueilNoteFormData, IAccueilNoteGetApiResponse} from "../../Services/Accueil.service";

export type IAccueilStatsComponentProps = {
    note: IAccueilNote
}

const AccueilNotesComponent = (props: IAccueilStatsComponentProps) => {
    const [note, setNote] = useState<IAccueilNote>(null);
    const noteAccordionRef = useRef(null);
    const accueilService: AccueilService = new AccueilService();


    useEffect(() => {
        setNote(props.note)
    }, [props.note]);


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IAccueilNoteGetApiResponse): void => {
        if(response && response.datas && response.datas.note){
            setNote(response.datas.note)
        }

        closeForms();
    };

    /**
     * Callback à la fermeture du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };


    /**
     * Fermeture du formulaire
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if ((noteAccordionRef && noteAccordionRef.current)) {
            noteAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                note &&
                    <AccordionWithFormSwitchComponent
                        ref={noteAccordionRef}
                        title="Notes collectives"
                        updatedDate={note.dateModification}
                        accordionModificators="-accueil-notes"
                        contentShowedOnInit={true}
                        contentType="accueil"
                        contentId={note.id}
                        form={<AccueilNoteFormComponent
                            note={note}
                            submitMethod={(formData: IAccueilNoteFormData) => accueilService.updateNote(note.id, formData)}
                            onSuccessSubmit={onSuccessSubmitForm}
                            onCancel={onCancelForm}
                        />}
                        infos={<AccueilNoteDetail note={note}/>}
                    />
            }
        </>
    )
}
export default AccueilNotesComponent;
