import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistreGetApiResponse, SinistresService} from "src/Services/Sinistres.service";

const UseSinistre = () => {
    const sinistresService: SinistresService = new SinistresService();
    const history = useHistory();

    /**
     *
     * Récupération d'un sinistre à partir de son id
     *
     * @param {string} sid
     * @param {(sinistre: ISinistre) => void} callback
     * @param {() => void} callbackError
     */
    const getSinistreById = (sid: string, callback:(sinistre: ISinistre) => void, callbackError?: () => void ) => {
        sinistresService.getSinistre(sid).then((response: ISinistreGetApiResponse) => {
            if( response && response.datas.sinistre){
                if(callback){
                    callback(response.datas.sinistre);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathSinistres);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du sinistre'",type: "danger"});
        });
    };


    return {getSinistreById};
};

export default UseSinistre;
