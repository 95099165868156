import React, {useEffect, useState} from "react";
import LayoutComponent from "../Layout/Layout.component";
import PageHeaderComponent from "../Page/PageHeader.component";
import FormCreatorComponent, {FormColumn, FormComponentFormData, FormElement} from "../Forms/FormCreator.component";
import {IFilterData} from "../../Hooks/FiltersHandler";
import {IOption} from "../Fields/Select.field.component";
import DateHelper from "../../Helpers/Date.helper";

type AdvancedSearchSummaryValue = {
    label: string,
    fieldName: string,
    value: string | string[] | number | number[],
    valueFormated: string,

    oldValues?: any
}

const AdvancedSearchComponent = (props: {
    title: string,
    children?: React.ReactNode,
    onClose: () => void,
    onSearch: (formData: FormComponentFormData) => void,
    onRemoveFilter: (filter:IFilterData) => void,
    modificators?: string,
    showAdvancedSearch: boolean,
    columns: FormColumn[],
    showSummary?: boolean
    filters: IFilterData,
}) => {

    const [existingValues, setExistingValues] = useState<AdvancedSearchSummaryValue[]>([]);

    useEffect(()=>{
        const currentExistingValues: AdvancedSearchSummaryValue[] = [];

        for(let col of props.columns){
            for(let el of col.elements){
                if(el.type !== "hidden" && (el.oldValue || el.oldValues)){

                    let value = "";
                    if(el.oldValue){
                        value = el.oldValue;
                    }
                    else if(el.oldValues){
                        value = oldValuesToString(el);
                    }

                    if(value) {
                        let valueFormated: string;
                        if(el.options){
                            const option:IOption = el.options.find((o)=> (o.value == value || o.label == value));
                            valueFormated = option ? option.label : value;
                        }
                        if(el.optionsGroup){
                            el.optionsGroup.forEach((g)=>{
                                const option:IOption = g.options.find((o)=> (o.value == value || o.label == value));
                                valueFormated = option ? option.label : value;
                            });
                        }

                        if(el.type == "date"){
                            valueFormated = DateHelper.getFormatedDate(valueFormated || value );
                        }
                        else if(el.type == "datetime-local" || el.type === "datetime-local-with-step"){
                            valueFormated = DateHelper.getFormatedDate(valueFormated, true);
                        }


                        let oldValuesArray;
                        if(el.oldValues && el.oldValues.length){
                            oldValuesArray = oldValuesToStringArray(el);
                        }

                        currentExistingValues.push({
                            label: el.label,
                            value,
                            valueFormated: valueFormated ?? value,
                            fieldName: el.fieldName,
                            oldValues: oldValuesArray
                        });
                    }
                }
            }
        }

        setExistingValues(currentExistingValues);
    },[props.columns]);

    /**
     * Génère une chaine à partir des valeurs déjà sélectionnées d'un champ multiple
     * @param {FormElement} el
     * @returns {string}
     */
    const oldValuesToString = (el: FormElement): string => {
        let value = "";

        if(el.options){
            if(!Array.isArray(el.oldValues)){
                el.oldValues = [el.oldValues];
            }
            el.oldValues.forEach((v,index)=>{
                const option = el.options.find((o)=>o.value === v);
                value += option ? `${index > 0 ? ',' : ''} ${option.label}` : v;
            });
        }
        else if(el.optionsGroup){
            el.oldValues.forEach((v,index)=>{
                let option: IOption = null;
                el.optionsGroup.forEach((g)=>{
                    const currentOpt = g.options.find((o)=>o.value === v);
                    if(currentOpt) option = currentOpt;
                });

                value += option ? `${index > 0 ? ',' : ''} ${option.label}` : v;
            });
        }
        else{
            value = el.oldValues.toString();
        }
        return value;
    };


    const oldValuesToStringArray = (el: FormElement): string => {
        let values: any = {};

        if (el.options) {
            if (!Array.isArray(el.oldValues)) {
                el.oldValues = [el.oldValues];
            }
            el.oldValues.forEach((v, index) => {
                const option = el.options.find((o) => o.value === v);
                values[v] = option ? `${option.label}` : v;
            });
        } else if (el.optionsGroup) {
            el.oldValues.forEach((v, index) => {
                let option: IOption = null;
                el.optionsGroup.forEach((g) => {
                    const currentOpt = g.options.find((o) => o.value === v);
                    if (currentOpt) option = currentOpt;
                });

                values[v] = option ? `${option.label}` : v;
            });
        }

        return values;
    }


    /**
     * Suppression du filtre
     *
     * @param {string} filter
     */
    const onRemoveFilter = (filter: string) => {
        let filterData: IFilterData = {};
        filterData[filter] = null;
        props.onRemoveFilter(filterData);
    };


    /**
     * Suppression d'une valeur spécifique dans un filtre et non tout l'objet
     *
     * @param {string} filter
     * @param {string} value
     */
    const onRemoveFilterSingle = (filter: string, value: string) => {
        let filterData: IFilterData = {};

        if(props.filters && props.filters[filter] && Array.isArray(props.filters[filter]) ){
            let newFilterValue = [];
            for(let f of props.filters[filter] as string[]){
                if( f !== value){
                    newFilterValue.push(f);
                }
            }
            filterData[filter] = newFilterValue ?? null;
        }
        else{
            filterData[filter] = null
        }

        props.onRemoveFilter(filterData);
    }



    return <div className={`advanced-search ${props.modificators}`}>

        {
            props.showSummary && existingValues.length > 0 &&
            <div className="summary">
                <h3 className="title">Eléments de recherche</h3>

                {
                    existingValues.map((item, iIndex) =>
                        <React.Fragment key={iIndex}>
                            {
                                item.oldValues && (Object.keys(item.oldValues).length > 0) &&
                                <span className="search-filter" key={`filter_${iIndex}`}>
                                    <span className="filter">
                                        {item.label} :&nbsp;
                                        {
                                            Object.keys(item.oldValues).map( (oldValueKey, oldIndex) =>
                                                <>
                                                    <span className="value">
                                                        {item.oldValues[oldValueKey]}
                                                    </span>
                                                    <button className="remove icon-remove" onClick={()=>onRemoveFilterSingle(item.fieldName, oldValueKey)} />
                                                </>
                                            )
                                        }

                                    </span>
                                </span>
                            }
                            {
                                (!item.oldValues || ( item.oldValues && item.oldValues.length < 1)) &&
                                <span className="search-filter" key={`filter_${iIndex}`}>
                                    <span className="filter">{item.label} :&nbsp;<span className="value">{item.valueFormated}</span></span>
                                    <button className="remove icon-remove" onClick={()=>onRemoveFilter(item.fieldName)} />
                                </span>
                            }
                        </React.Fragment>
                    )
                }
            </div>
        }

        {
            props.showAdvancedSearch &&
            <div className="overlay">
                <button className="close icon-close" onClick={props.onClose} />
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -advanced-search -no-border"} contentModificators={`-bg-white -no-flex`}>
                    <PageHeaderComponent text={props.title} subText="Recherche avancée" icon="icon-search" modificators={`-in-fullscreen `}/>
                    <FormCreatorComponent
                        formColumns={props.columns}
                        formActions={[
                            {
                                label: "Annuler",
                                modificators: "-is-link -on-second",
                                onAction: props.onClose
                            },
                            {
                                label: "Rechercher",
                                modificators: "-primary -on-second",
                                onAction: props.onSearch
                            }
                        ]}
                        errorFieldsMessages={null}
                        errorMessages={null}
                    />
                </LayoutComponent>
            </div>
        }
    </div>
};

export default AdvancedSearchComponent;
