import React from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";

type NotairesUrssafAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,

}
export default function NotairesUrssafAdvancedSearchComponent(props: NotairesUrssafAdvancedSearchComponentProps){
    return (
        <AdvancedSearchComponent
            title="Notaires"
            showSummary={props.showSummary}
            modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
            showAdvancedSearch={props.showAdvancedSearch}
            filters={props.filters}
            columns={[
             {
                 elements: [
                     {
                         type: 'hidden',
                         fieldName: "advanced",
                         oldValue: "1",
                         modificators: "-hidden"
                     },
                     {
                         type: "text",
                         fieldName: "nom",
                         label: "Nom",
                         modificators:"-on-white",
                         oldValue: props.filters.nom as string || ""
                     },{
                         type: "text",
                         fieldName: "ville_office",
                         label: "Ville",
                         modificators:"-on-white",
                         oldValue: props.filters.ville_office as string || ""
                     },
                     {
                         type: "text",
                         fieldName: "num_CSN",
                         label: "Num. CSN",
                         modificators:"-on-white",
                         oldValue: props.filters.num_CSN as string || ""
                     },
                 ]
             },
             {
                 elements: [
                     {
                         type: "text",
                         fieldName: "prenom",
                         label: "Prénom",
                         modificators:"-on-white",
                         oldValue: props.filters.prenom as string || ""
                     },
                     {
                         type: "selectMultiple",
                         hideSearchOnMultiple: true,
                         fieldName: "departement",
                         label: "Département",
                         modificators:"-on-white",
                         options: OfficesConstants.departementsOptions,
                         oldValues: props.filters.departement as string[] || []
                     },
                     {
                         type: "text",
                         fieldName: "num_siret",
                         label: "Num. Siret",
                         modificators:"-on-white",
                         oldValue: props.filters.num_siret as string || ""
                     },
                 ]
             },
            ]}
            onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
            onClose={() => props.setShowAdvancedSearch()}
            onSearch={props.onSearch}
        />
    )

}
