import ExportComponent from "src/Components/Exports/Export.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";

type ParametresExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    module?: string,
    filters?: any,
}
export default function ParametresExportComponent(props: ParametresExportComponentProps) {
    const [exportUrl, setExportUrl] = useState<string>();

    useEffect(() => {
        let newExportUrl: string = `api${AppConstants.pathParametres}/export`;
        if(props.module){
            newExportUrl += `/${props.module}`;
        }

        setExportUrl(newExportUrl);
    }, [props.module]);


    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Paramètres"}
                         icon="icon-cog"
                         subTitle={"Export des paramètres"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl={exportUrl}
                         filters={props.filters || null}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-informations',
                                         fieldName: "informations",
                                         label: "Informations",
                                         options: [
                                             {value: 'nom', label: 'Nom'},
                                             {value: 'slug', label: 'Identifiant'},
                                             {value: 'valeur', label: 'Valeur'},
                                             {value: 'updated_at', label: 'Date de modification'},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
