import {IOption, IOptionsGroup} from "../Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type ICorrespondanceConstants = {
    typesOptions: IOptionsGroup[],
    creationSteps: ICreationSteps[],
    types: ICorrespondanceCategories,
    documentsOrderOptions: IOption[],
}

export type ICorrespondanceStates = {
    [key: string]: string
}

export type IParticipantStates = {
    [key: string]: string
}

export type ICorrespondanceCategories = {
    [key: string]: string
}

export const CorrespondanceConstants: ICorrespondanceConstants = {
    creationSteps: [
        {
            url: "/precisions",
            title: "Précisions",
            icon: "icon-precisions",
        },
        {
            url: "/documents",
            title: "Documents",
            icon: "icon-documents",
        },
        {
            url: "/complements",
            title: "Compléments",
            icon: "icon-complements",
        },
    ],
    typesOptions: [
        {
            label: "",
            options: [
                {value: "assemblee_generale", label: 'Assemblée Générale'},
                {value: "association_pour_la_communication", label: 'Association pour la communication'},
                {value: "audience_de_discipline", label: 'Audience de discipline'},
                {value: "caisse_de_garantie", label: 'Caisse de garantie'},
                {value: "convocation_individuelle", label: 'Convocation individuelle'},
                {value: "CTRC", label: 'CTRC'},
                {value: "reunion_de_discipline", label: 'Réunion de discipline'},
                {value: "reunion", label: 'Réunion'},
                {value: "information_de_la_chambre", label: 'Information de la Chambre'},
                {value: "reservation_d_un_bureau", label: 'Réservation d’un bureau'},
                {value: "reunion_des_membres_du_bureau", label: 'Réunion des membres du bureau'},
                {value: "reunion_de_la_chambre_des_notaires", label: 'Réunion de la Chambre des Notaires'},
                {value: "personnalisable", label: 'Courrier personnalisable'},
            ]
        },
    ],
    types:{
        'assemblee_generale': 'Assemblée Générale',
        'association_pour_la_communication': 'Association pour la communication',
        'audience_de_discipline': 'Audience de discipline',
        'caisse_de_garantie': 'Caisse de garantie',
        'convocation_individuelle': 'Convocation individuelle',
        'CTRC': 'CTRC',
        'reunion_de_discipline': 'Réunion de discipline',
        'reunion': 'Réunion',
        'information_de_la_chambre': 'Information de la Chambre',
        'reservation_d_un_bureau': 'Réservation d’un bureau',
        'reunion_des_membres_du_bureau': 'Réunion des membres du bureau',
        'reunion_de_la_chambre_des_notaires': 'Réunion de la Chambre des Notaires',
        'personnalisable': 'Courrier personnalisable',
    },
    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
    ],
};
