import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {ITableauBord} from "../Models/TableauBord.model";

export type ITableauBordGetApiResponse = IApiCustomResponse & {
    datas: {
        tableauBord: ITableauBord
    }
}

export type ITableauxBordGetPaginationApi = IApiPagination & {
    data: ITableauBord[],
    countTotalFichierRecus?: number,
    countTotalFichiers?: number,
}
export type ITableauxBordGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ITableauxBordGetPaginationApi
    }
}
export type ITableauxBordGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    departements?: string[],
}
export type ITableauBordUploadFormData = {
    fichier: File,
    CRPCEN: string
}

type ITableauBordDocumentFormData ={
    fichier?: string;
    date: string,
}
export type ITableauBordDocumentsFormData = {
    documents: ITableauBordDocumentFormData[],
}




export class TableauxBordService extends ApiService{
    /**
     * Permet la récupération d'un tableau de bord à partir de son id
     *
     * @param {string} tid
     * @returns {Promise<ITableauBordGetApiResponse>}
     */
    public getTableauBord(tid: string): Promise<ITableauBordGetApiResponse>{
        return this.doApiCall('api/tableau-de-bord/' + tid, 'GET');
    }

    /**
     * Permet la récupération d'un tableau de bord à partir de son id
     *
     * @param {string} tid
     * @returns {Promise<ITableauBordGetApiResponse>}
     */
    public getTableauBordExternal(tid: string): Promise<ITableauBordGetApiResponse>{
        return this.doApiCall('api/external/tableau-de-bord/' + tid, 'GET');
    }

    /**
     * Permet la récupération d'une liste de tableau de bord
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getTableauxBord(formData: ITableauxBordGetFormData): Promise<ITableauxBordGetApiResponse>{
        return this.doApiCall('api/tableau-de-bord', 'GET', formData);
    }


    /**
     * Upload d'un tableau de bord pour un office
     *
     * @param {number} tid
     * @param {number} oid
     * @param {number} did
     * @param {ITableauBordUploadFormData} formData
     * @returns {Promise<ITableauBordGetApiResponse>}
     */
    public uploadTableauBord(tid: number, oid: number, did: number, formData: ITableauBordUploadFormData ): Promise<ITableauBordGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/'+ oid +'/tableau-de-bord/' + tid + '/document/' + did + '/upload', 'POST', formData);
    }
    /**
     * Upload d'un tableau de bord pour un office
     *
     * @param {number} tid
     * @param {number} oid
     * @param {number} did
     * @param {ITableauBordUploadFormData} formData
     * @returns {Promise<ITableauBordGetApiResponse>}
     */
    public uploadTableauBordExternal(tid: number, oid: number, did: number, formData: ITableauBordUploadFormData ): Promise<ITableauBordGetApiResponse>{
        return this.doApiCall('api/external/annuaire/offices/'+ oid +'/tableau-de-bord/' + tid + '/document/' + did + '/upload', 'POST', formData);
    }


    /**
     * Upload des documents
     *
     * @param {number} tid
     * @param {ITableauBordDocumentsFormData} formDatas
     * @returns {Promise<ITableauBordGetApiResponse>}
     */
    public updateDocumentsList( tid: number, formDatas: ITableauBordDocumentsFormData): Promise<ITableauBordGetApiResponse>{
        return this.doApiCall('api/tableau-de-bord/' + tid + "/documents", 'POST', formDatas);
    }
}




