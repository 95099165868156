import {IOption} from "src/Components/Fields/Select.field.component";

export type ICtrcsConstants = {
    documentsTypesOptions: IOption[],
    documentsOrderOptions: IOption[],
}
export const CtrcsConstants: ICtrcsConstants = {
    documentsTypesOptions: [
        {
            value: "ordre-du-jour",
            //label: "Ordre du jour (un par CTRC)",
            label: "Ordre du jour",
        },
        {
            value: "rapports",
            //label: "Rapports (un par CTRC)",
            label: "Rapports",
        },
        {
            value: "autre",
            label: "Autre",
        },
    ],

    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "type",
            label: "Type"
        },
    ]
};
