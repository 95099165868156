import React from "react";
import {IReclamation} from "src/Models/Reclamation.model";
import DateHelper from "../../Helpers/Date.helper";
import ReclamationsHelper from "../../Helpers/Reclamations.helper";
import {INotaire} from "../../Models/Notaire.model";

type IReclamationInformationsComponentProps = {
    reclamation: IReclamation,
}

export default function ReclamationInformationsComponent(props: IReclamationInformationsComponentProps) {
    const reclamation: IReclamation = props.reclamation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Numéro de dossier</p>
                        <p className="value">{reclamation.numeroDossier || '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Objet</p>
                        <p className="value">{reclamation.objet ? ReclamationsHelper.getFormatedObjet(reclamation.objet) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Etat</p>
                        <p className="value">{reclamation.etat ? ReclamationsHelper.getFormatedEtat(reclamation.etat) : '-'}</p>
                    </div>

                    { (reclamation && reclamation.etat === 'autre' && reclamation.etatAutre) &&
                    <div className="informations-block">
                        <p className="label">Motif</p>
                        <p className="value">{reclamation.etatAutre ? ReclamationsHelper.getFormatedEtatAutre(reclamation.etatAutre) : '-'}</p>
                    </div>
                    }

                    <div className="informations-block">
                        <p className="label">Date envoi syndic</p>
                        <p className="value">{reclamation.dateEnvoiSyndic ? DateHelper.getFormatedDate(reclamation.dateEnvoiSyndic) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">
                            {/*Date limite 1er rappel*/}
                            Date limite convocation conciliation
                        </p>
                        <p className="value">{reclamation.dateLimiteInitial1erRappel ? DateHelper.getFormatedDate(reclamation.dateLimiteInitial1erRappel) : '-'}</p>
                    </div>

                    {/*
                    <div className="informations-block">
                        <p className="label">Date du 1er rappel</p>
                        <p className="value">{reclamation.date1erRappel ? DateHelper.getFormatedDate(reclamation.date1erRappel) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Pénalités encourues</p>
                        <p className="value">{ reclamation.penalitesEncourues ? `${reclamation.penalitesEncourues} €`: '-'}</p>
                    </div>
                    */}

                    <div className="informations-block">
                        <p className="label">Note</p>
                        <p className="value">{reclamation.note || '-'}</p>
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de réception</p>
                        <p className="value">{reclamation.dateReception ? DateHelper.getFormatedDate(reclamation.dateReception) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Motif invoqué par le réclamant</p>
                        <p className="value">{reclamation.fondement ? ReclamationsHelper.getFormatedFondement(reclamation.fondement) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Origine</p>
                        <p className="value">{reclamation.origine ? ReclamationsHelper.getFormatedOrigine(reclamation.origine) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Date retour syndic</p>
                        <p className="value">{reclamation.dateRetourSyndic ? DateHelper.getFormatedDate(reclamation.dateRetourSyndic) : '-'}</p>
                    </div>

                    {/*
                    <div className="informations-block">
                        <p className="label">Date limite convocation</p>
                        <p className="value">{reclamation.dateLimiteAccordee ? DateHelper.getFormatedDate(reclamation.dateLimiteAccordee) : '-'}</p>
                    </div>
                    */}

                    <div className="informations-block">
                        <p className="label">Date de demande d'explication</p>
                        <p className="value">{reclamation.dateDemandeExplication ? DateHelper.getFormatedDate(reclamation.dateDemandeExplication) : '-'}</p>
                    </div>

                    {/*
                    <div className="informations-block">
                        <p className="label">Pénalités retenues</p>
                        <p className="value">{ reclamation.penalitesRetenues ? `${reclamation.penalitesRetenues} €`: '-'}</p>
                    </div>
                    */}
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de modification</p>
                        <p className="value">{reclamation.dateModification ? DateHelper.getFormatedDate(reclamation.dateModification) : '-'}</p>
                    </div>
                    {/*
                    <div className="informations-block">
                        <p className="label">Précision sur le fondement</p>
                        <p className="value">{reclamation.precision || '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date de convocation devant le bureau</p>
                        <p className="value">{reclamation.dateConvocationBureau ? DateHelper.getFormatedDate(reclamation.dateConvocationBureau, true) : '-'}</p>
                    </div>
                    */}
                    {/*Si la réclamation est d'origine "Plaignant", il ne devrait pas y avoir de champ "Nom du client.*/}
                    {reclamation.origine != 'plaignant' &&
                        <div className="informations-block">
                            <p className="label">Nom du client</p>
                            <p className="value">{reclamation.nomClient || '-'}</p>
                        </div>
                    }
                    <div className="informations-block">
                        <p className="label">Référence</p>
                        <p className="value">{reclamation.reference || '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date de cloture du dossier</p>
                        <p className="value">{reclamation.dateClotureDossier ? DateHelper.getFormatedDate(reclamation.dateClotureDossier, false) : '-'}</p>
                    </div>
                </div>
            </div>

            {
                reclamation.syndicInitial &&
                <div className="columns">
                    <p className="title">Syndic - {reclamation.syndicInitial.nom} {reclamation.syndicInitial.prenom}</p>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">CRPCEN</p>
                            <p className="value">{reclamation.syndicInitial.office ? reclamation.syndicInitial.office.CRPCEN : '-'}</p>
                        </div>
                    </div>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Nom</p>
                            <p className="value">{reclamation.syndicInitial.nom || '-'}</p>
                        </div>
                    </div>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Prénom</p>
                            <p className="value">{reclamation.syndicInitial.prenom || '-'}</p>
                        </div>
                    </div>
                </div>
            }

            {
                reclamation.repriseDossier &&
                <div className="columns">
                    <p className="title">Reprise du dossier - {reclamation.repriseDossier.nom} {reclamation.repriseDossier.prenom}</p>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">CRPCEN</p>
                            <p className="value">{reclamation.repriseDossier.office ? reclamation.repriseDossier.office.CRPCEN : '-'}</p>
                        </div>
                    </div>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Nom</p>
                            <p className="value">{reclamation.repriseDossier.nom || '-'}</p>
                        </div>
                    </div>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Prénom</p>
                            <p className="value">{reclamation.repriseDossier.prenom || '-'}</p>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}
