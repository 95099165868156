import React, {useEffect, useState} from "react";
import {IOption} from "./Select.field.component";
import CheckboxFieldComponent from "./Checkbox.field.component";

const CheckboxGroupField = (props:{
    label: string,
    id: string,
    icon?: string,
    options?: IOption[],
    onChange?: (values: string[]) => void,
}) => {

    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    useEffect(()=>{
        props.onChange(selectedValues);
    },[selectedValues]);

    /**
     * Utilisation de la coche globale
     */
    const onSelectAll = (): void => {
        const nextAllSelected = !allSelected;
        let newSelectedValues = [...selectedValues];
        props.options.forEach((opt)=>{
            if(
                (nextAllSelected && !isValueSelected(opt.value)) || // Si tout doit être sélectionné et que la valeur n'est pas déjà sélectionnée
                (!nextAllSelected && isValueSelected(opt.value))  // Si tout doit être désélectionné et que la valeur est déjà sélectionnée
            ){
                newSelectedValues = toggleArrayItem(newSelectedValues,opt.value);
            }
        });
        setSelectedValues(newSelectedValues);
        setAllSelected(nextAllSelected);
    };

    /**
     * Prise en charge d'un changement au niveau d'une checkbox
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newSelectedValues = toggleArrayItem(selectedValues,e.currentTarget.value);
        setSelectedValues(newSelectedValues);

        // Changement manuel, on met à jour la coche globale
        setAllSelected(newSelectedValues.length === props.options.length);
    };

    /**
     * Supprime un élément dans le tableau s'il est déjà présent ou l' ajoute s'il ne l'est pas
     * @param {string[]} arr
     * @param {string} item
     * @returns {string[]}
     */
    const toggleArrayItem = (arr: string[], item: string): string[] => arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];

    /**
     * Retourne un booleen indiquant si la valeur passée en paramètre est dans les valeurs sélectionnées
     * @param {string} value
     * @returns {boolean}
     */
    const isValueSelected = (value: string): boolean => {
        return selectedValues.includes(value);
    };

    return <div className="form-checkbox-group">
        <h3 className="title">
            <CheckboxFieldComponent value={""} id={""} onChange={()=>onSelectAll()} isChecked={allSelected}/>
            {props.icon && <i className={`icon ${props.icon}`}></i>}
            {props.label}
        </h3>
        {props.options.map((opt, i)=><CheckboxFieldComponent key={i} value={opt.value} id={props.id} label={opt.label} onChange={onCheckboxChange} isChecked={isValueSelected(opt.value)}/>)}
    </div>
};

export default CheckboxGroupField;