import React, {useEffect, useState} from "react";
import LayoutComponent from "../../Components/Layout/Layout.component";
import PageHeaderComponent from "../../Components/Page/PageHeader.component";
import {IFilInformations} from "../../Models/FilInformations.model";
import {FilInformationsService} from "../../Services/FilInformations.service";
import {AppConstants} from "../../Constants/AppConstants";
import FileHelper from "../../Helpers/File.helper";
import DocumentLink from "../../Components/Documents/DocumentLink.component";


const FilInformationsListExternalScreen = (props:{}) => {


    const [fils, setFils] = useState<IFilInformations[]>([]);
    const [loaded, setLoaded] = useState<boolean>(true);
    const filInfosService: FilInformationsService = new  FilInformationsService();

    useEffect(()=>{
        filInfosService.getFilInformationsAllExternal().then((result)=>{
            if(result.datas && result.datas.fils){
                setFils(result.datas.fils);
            }
            setLoaded(true);
        },(e)=>{
            console.error(e);
            setLoaded(true);
        });
    },[]);

    return <LayoutComponent showMenu={false}>
        <div className="list">
            <PageHeaderComponent
                breadcrumbLinks={null}
                modificators="-with-border-bottom"
                text="Fil d'informations"
                icon="icon-fil-infos"
            />
            <div className="g-content -no-padding-mobile">
                <table className={`list-table -stats`}>
                    <thead className={"headers"}>
                    <tr>
                        <th className="th"><span className="link">Num</span></th>
                        <th className="th"><span className="link">Titre</span></th>
                        {/*<th className="th"><span className="link">PDF</span></th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {loaded && fils.map((f,index)=><tr key={index} className="tr">
                        <td className="td">{f.numero}</td>
                        <td className="td">
                            {!f.pdf && <a href={`${AppConstants.serverUrl}fil-infos/archives/${f.numero}`} target="_blank" rel="nofollow, noindex, noreferrer">{f.titre} </a>}

                            {f.pdf && <>{f.titre}</>}
                        </td>
                        {/*<td className="td">{f.pdf && <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(f.pdf,"documents-externes")}/>}</td>*/}
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>

    </LayoutComponent>

};

export default FilInformationsListExternalScreen;
