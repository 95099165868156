import React, { useContext, useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { IUserContext, UserContext } from "./Providers/User.provider";
import "./assets/sass/main.scss";
import LoginScreen from "./Screens/Users/Login.screen";
import LoadingComponent from "./Components/Loading/Loading.component";
import ForgotPasswordScreen from "./Screens/Users/ForgotPassword.screen";
import ResetPasswordScreen from "./Screens/Users/ResetPassword.screen";
import NotFoundScreen from "./Screens/NotFound.screen";
import AuthRoute from "./Middlewares/Auth.route";
import ForbiddenScreen from "./Screens/Forbidden.screen";
import { AppConstants } from "./Constants/AppConstants";
import AuthModuleCheckRoute from "./Middlewares/AuthModuleCheck.route";
import HomeScreen from "./Screens/Home.screen";
import OfficesListScreen from "./Screens/Annuaire/Offices/OfficesList.screen";
import NotairesListScreen from "./Screens/Annuaire/Notaires/NotairesList.screen";
import SearchScreen from "./Screens/Search.screen";
import AuthAdminRoute from "./Middlewares/AuthAdmin.route";
import UsersListScreen from "./Screens/Users/UsersList.screen";
import UserFicheScreen from "./Screens/Users/UserFiche.screen";
import UserAuthFicheScreen from "./Screens/Users/UserAuthFiche.screen";
import OfficeFicheScreen from "./Screens/Annuaire/Offices/OfficeFiche.screen";
import NotaireFicheScreen from "./Screens/Annuaire/Notaires/NotaireFiche.screen";
import OrganismeFicheScreen from "./Screens/Annuaire/AutresContacts/Organismes/OrganismeFiche.screen";
import PersonneExterieureFicheScreen from "./Screens/Annuaire/AutresContacts/PersonnesExterieures/PersonneExterieureFiche.screen";
import CertificationsListScreen from "./Screens/Annuaire/Certifications/CertificationsList.screen";
import CertificationFicheScreen from "./Screens/Annuaire/Certifications/CertificationFiche.screen";
import NotaireCreationStepsScreen from "./Screens/Annuaire/Notaires/NotaireCreationSteps.screen";
import OfficeCreationStepsScreen from "./Screens/Annuaire/Offices/OfficeCreationSteps.screen";
import OrganismeCreationStepsScreen from "./Screens/Annuaire/AutresContacts/Organismes/OrganismeCreationSteps.screen";
import PersonneExterieureCreationStepsScreen from "./Screens/Annuaire/AutresContacts/PersonnesExterieures/PersonneExterieureCreationSteps.screen";
import CertificationCreationScreen from "./Screens/Annuaire/Certifications/CertificationCreation.screen";
import UserCreationScreen from "./Screens/Users/UserCreation.screen";
import FormationsListScreen from "./Screens/Formations/Formations/FormationsList.screen";
import GroupesListScreen from "./Screens/Annuaire/Groupes/GroupesList.screen";
import GroupeCreationScreen from "./Screens/Annuaire/Groupes/GroupeCreation.screen";
import GroupeFicheScreen from "./Screens/Annuaire/Groupes/GroupeFiche.screen";
import FormationCreationInformationsScreen from "./Screens/Formations/Formations/FormationCreationInformations.screen";
import FormationFicheScreen from "./Screens/Formations/Formations/FormationFiche.screen";
import SuccessionsListScreen from "./Screens/Successions/SuccessionsList.screen";
import SuccessionFicheScreen from "./Screens/Successions/SuccessionFiche.screen";
import ExternalRoute from "./Middlewares/External.route";
import SuccessionExternalReponseScreen from "./Screens/Successions/SuccessionExternalReponse.screen";
import FormationInscriptionExternalScreen from "./Screens/Formations/Formations/FormationInscriptionExternal.screen";
import UrssafListScreen from "./Screens/Formations/Urssaf/UrssafList.screen";
import TableauBordsListScreen from "./Screens/TableauBord/TableauBordList.screen";
import FormationAjoutInscriptionScreen from "./Screens/Formations/Formations/FormationAjoutInscription.screen";
import NotaireUrssafExternalScreen from "./Screens/Annuaire/Notaires/NotaireUrssafExternal.screen";
import OfficeTableauBordExternalScreen from "./Screens/Annuaire/Offices/OfficeTableauBordExternal.screen";
import TableauBordFicheScreen from "./Screens/TableauBord/TableauBordFiche.screen";
import SinistresListScreen from "./Screens/Sinistres/SinistresList.screen";
import IntervenantsScreen from "./Screens/Formations/Intervenants/Intervenants.screen";
import SinistreFicheScreen from "./Screens/Sinistres/SinistreFiche.screen";
import SinistreCreationStepsScreen from "./Screens/Sinistres/SinistreCreationSteps.screen";
import DifferendsScreen from "./Screens/Differends/DifferendsList.screen";
import DifferendCreationStepsScreen from "./Screens/Differends/DifferendCreationSteps.screen";
import StatistiquesListScreen from "./Screens/Inspections/Statistiques/StatistiquesList.screen";
import InspectionsListScreen from "./Screens/Inspections/Inspections/InspectionsList.screen";
import HabilitationsListScreen from "./Screens/Inspections/Habilitations/HabilitationsList.screen";
import ClassementsListScreen from "./Screens/Inspections/Classements/ClassementsList.screen";
import ReclamationsListScreen from "./Screens/Reclamations/ReclamationsList.screen";
import StatistiquesImport from "./Screens/Inspections/Statistiques/StatistiquesImport.screen";
import DifferendFicheScreen from "./Screens/Differends/DifferendFiche.screen";
import ParametresListScreen from "./Screens/Parametres/ParametresList.screen";
import ParametreFicheScreen from "./Screens/Parametres/ParametreFiche.screen";
import ParametreCreationScreen from "./Screens/Parametres/ParametreCreation.screen";
import FilInformationsListScreen from "./Screens/FilInformations/FilInformationsList.screen";
import ReclamationCreationStepsScreen from "./Screens/Reclamations/ReclamationCreationSteps.screen";
import ReclamationFicheScreen from "./Screens/Reclamations/ReclamationFiche.screen";
import FilInformationsCreationScreen from "./Screens/FilInformations/FilInformationsCreationScreen";
import FilInformationsFicheScreen from "./Screens/FilInformations/FilInformationsFiche.screen";
import FilInformationsInscriptionExternalScreen from "./Screens/FilInformations/FilInformationsInscriptionExternal.screen";
import StatistiquesFicheScreen from "./Screens/Inspections/Statistiques/StatistiquesFiche.screen";
import CotisationsListeOfficesScreen from "./Screens/Cotisations/CotisationsListeOffices.screen";
import CotisationsSaisieOfficesScreen from "./Screens/Cotisations/CotisationsSaisieOffice.screen";
import CotisationsSaisieNotairesScreen from "./Screens/Cotisations/CotisationsSaisieNotaires.screen";
import CotisationsOfficeFicheScreen from "./Screens/Cotisations/CotisationsOfficeFiche.screen";
import InspectionFicheScreen from "./Screens/Inspections/Inspections/InspectionFiche.screen";
import CorrespondancesListScreen from "./Screens/Correspondances/Courriers/CourriersList.screen";
import CorrespondanceFicheScreen from "./Screens/Correspondances/Courriers/CourrierFiche.screen";
import CorrespondanceAjoutInvitesScreen from "./Screens/Correspondances/Courriers/CourrierAjoutInvites.screen";
import CorrespondanceCreationStepsScreen from "./Screens/Correspondances/Courriers/CourrierCreationSteps.screen";
import CorrespondanceAjoutTestsScreen from "./Screens/Correspondances/Courriers/CourrierAjoutTests.screen";
import CorrespondanceInscriptionExternalScreen from "./Screens/Correspondances/Courriers/CourrierInscriptionExternal.screen";
import LettresTypesListScreen from "./Screens/Correspondances/LettresTypes/LettresTypesList.screen";
import LettresTypesFicheScreen from "./Screens/Correspondances/LettresTypes/LettresTypesFiche.screen";
import FilInformationsListExternalScreen from "./Screens/FilInformations/FilInformationsListExternal.screen";
import AnnexesListScreen from "./Screens/Annuaire/Offices/AnnexesList.screen";
import AnnexeFicheScreen from "./Screens/Annuaire/Offices/AnnexeFiche.screen";
import AnnexeCreationStepsScreen from "./Screens/Annuaire/Offices/AnnexeCreationSteps.screen";
import LabelsListScreen from "./Screens/Annuaire/Labels/LabelsList.screen";
import LabelCreationScreen from "./Screens/Annuaire/Labels/LabelCreation.screen";
import LabelFicheScreen from "./Screens/Annuaire/Labels/LabelFiche.screen";
import NotairesAnciensListScreen from "./Screens/Annuaire/Notaires/NotairesAnciensList.screen";
import GroupeAjoutMembresScreen from "./Screens/Annuaire/Groupes/GroupeAjoutMembres.screen";
import OrganismesListScreen from "./Screens/Annuaire/AutresContacts/Organismes/OrganismesList.screen";
import PersonneExterieureListScreen from "./Screens/Annuaire/AutresContacts/PersonnesExterieures/PersonneExterieureList.screen";
import SignaturesListScreen from "./Screens/Correspondances/Signatures/SignaturesList.screen";
import SignatureFicheScreen from "./Screens/Correspondances/Signatures/SignatureFiche.screen";
import SignatureCreationScreen from "./Screens/Correspondances/Signatures/SignatureCreation.screen";
import OrganismeAjoutPersonnesScreen from "./Screens/Annuaire/AutresContacts/Organismes/OrganismeAjoutPersonnes.screen";
import SuccessionCreationStepsScreen from "./Screens/Successions/SuccessionCreationSteps.screen";
import FormationParticipantEditEmailScreen from "./Screens/Formations/Formations/FormationParticipantEditEmail.screen";
import FormationCreationFormationScreen from './Screens/Formations/Formations/FormationCreationFormation.screen';
import FormationCreationPrecisionsScreen from './Screens/Formations/Formations/FormationCreationPrecisions.screen';
import FormationCreationDocumentsScreen from './Screens/Formations/Formations/FormationCreationDocuments.screen';
import FormationCreationComplementsScreen from './Screens/Formations/Formations/FormationCreationComplements.screen';
import MosaiqueScreen from './Screens/Mosaique.screen';
import CorrespondanceInscriptionExternalPreviewScreen from "./Screens/Correspondances/Courriers/CourrierInscriptionExternalPreview.screen";
import CourrierUrssafScreen from "./Screens/Formations/Urssaf/CourrierUrssaf.screen";
import CtrcCreationScreen from "./Screens/Ctrcs/CtrcCreation.screen";
import CtrcFicheScreen from "./Screens/Ctrcs/CtrcFiche.screen";
import CtrcsListScreen from "./Screens/Ctrcs/CtrcsList.screen";
import CtrcRattachementSinistresScreen from "./Screens/Ctrcs/CtrcRattachementSinistres.screen";



type AppProps = RouteComponentProps & {}



const App = (props: AppProps) => {
    const { loaded } = useContext(UserContext) as IUserContext;


    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_NAME;
    }, []);


    return (
        <div className="app-wrapper">
            {
                !loaded &&
                <LoadingComponent />
            }
            {
                loaded &&
                <Switch>
                    {/* Routes de recherche*/}
                    <Route exact path="/recherche"><SearchScreen /></Route>

                    {/* Routes de connexion*/}
                    <Route exact path={AppConstants.pathLogin}><LoginScreen /></Route>
                    <Route exact path="/mot-de-passe-oublie"><ForgotPasswordScreen /></Route>
                    <Route exact path="/reinitialisation-mot-de-passe/:token" component={(props: any) => <ResetPasswordScreen key={Math.random()} {...props} {...props.match.params} />} />



                    {/* Routes Externes */}
                    <ExternalRoute exact path="/successions/:sid/reponse" component={(props: any) => <SuccessionExternalReponseScreen {...props.match.params} />} />
                    <ExternalRoute exact path="/formations/:fid/inscription-exterieure" component={(props: any) => <FormationInscriptionExternalScreen {...props.match.params} />} />
                    <ExternalRoute exact path="/correspondances/inscription/:pid" component={(props: any) => <CorrespondanceInscriptionExternalScreen {...props.match.params} type="normal" />} />
                    <ExternalRoute exact path="/correspondances/inscription/tests/:pid" component={(props: any) => <CorrespondanceInscriptionExternalScreen {...props.match.params} type="test" />} />
                    <ExternalRoute exact path="/fil-infos/abonnement" component={(props: any) => <FilInformationsInscriptionExternalScreen {...props.match.params} />} />
                    <ExternalRoute exact path="/fil-infos/archives" component={(props: any) => <FilInformationsListExternalScreen {...props.match.params} />} />
                    <ExternalRoute exact path="/annuaire/notaires/:nid/urssaf/:aid" component={(props: any) => <NotaireUrssafExternalScreen {...props.match.params} />} />
                    <ExternalRoute exact path="/annuaire/offices/:oid/tableau-de-bord/:tid/document/:did" component={(props: any) => <OfficeTableauBordExternalScreen {...props.match.params} />} />




                    {/* Routes protégées par l'authentification */}
                    <AuthRoute exact path={AppConstants.pathHome}><HomeScreen /></AuthRoute>
                    <AuthRoute exact path={AppConstants.pathMosaique}><MosaiqueScreen /></AuthRoute>
                    <AuthRoute exact path="/mon-compte/modification"><UserAuthFicheScreen /></AuthRoute>

                    {/* Routes du module annuaire */}
                    {/* Routes des offices */}
                    <AuthRoute exact path="/annuaire/offices"><OfficesListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/offices/creation"><OfficeCreationStepsScreen type="creation" /></AuthRoute>
                    <AuthRoute exact path="/annuaire/offices/:oid" component={(props: any) => <OfficeFicheScreen {...props.match.params} />} />

                    {/* Routes des annexes */}
                    <AuthRoute exact path="/annuaire/annexes"><AnnexesListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/annexes/creation"><AnnexeCreationStepsScreen type="creation" /></AuthRoute>
                    <AuthRoute exact path="/annuaire/annexes/:oid" component={(props: any) => <AnnexeFicheScreen {...props.match.params} />} />



                    {/* Routes des notaires */}
                    <AuthRoute exact path="/annuaire/notaires"><NotairesListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/anciens-notaires"><NotairesAnciensListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/notaires/creation"><NotaireCreationStepsScreen type="creation" /></AuthRoute>
                    <AuthRoute exact path="/annuaire/anciens-notaires/creation"><NotaireCreationStepsScreen type="creation" anciensNotaire={true} /></AuthRoute>
                    <AuthRoute exact path="/annuaire/notaires/:nid" component={(props: any) => <NotaireFicheScreen {...props.match.params} />} />

                    {/* Routes des organismes */}
                    <AuthRoute exact path="/annuaire/organismes"><OrganismesListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/organismes/creation"><OrganismeCreationStepsScreen type="creation" /></AuthRoute>
                    <AuthRoute exact path="/annuaire/organismes/:oid" component={(props: any) => <OrganismeFicheScreen {...props.match.params} />} />
                    <AuthRoute exact path="/annuaire/organismes/:oid/personnes/ajout" component={(props: any) => <OrganismeAjoutPersonnesScreen {...props.match.params} />} />

                    {/* Routes des personnes extérieures */}
                    <AuthRoute exact path="/annuaire/personnes-exterieures"><PersonneExterieureListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/personnes-exterieures/creation"><PersonneExterieureCreationStepsScreen type="creation" /></AuthRoute>
                    <AuthRoute exact path="/annuaire/personnes-exterieures/:pid" component={(props: any) => <PersonneExterieureFicheScreen {...props.match.params} />} />

                    {/* Routes des groupes */}
                    <AuthRoute exact path="/annuaire/groupes"><GroupesListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/groupes/creation"><GroupeCreationScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/groupes/:gid" component={(props: any) => <GroupeFicheScreen {...props.match.params} />} />
                    <AuthRoute exact path="/annuaire/groupes/:gid/membres" component={(props: any) => <GroupeAjoutMembresScreen {...props.match.params} />} />


                    {/* Routes des certifications */}
                    <AuthRoute exact path="/annuaire/certifications"><CertificationsListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/certifications/creation"><CertificationCreationScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/certifications/:cid" component={(props: any) => <CertificationFicheScreen {...props.match.params} />} />

                    {/* Routes des labels */}
                    <AuthRoute exact path="/annuaire/labels"><LabelsListScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/labels/creation"><LabelCreationScreen /></AuthRoute>
                    <AuthRoute exact path="/annuaire/labels/:lid" component={(props: any) => <LabelFicheScreen {...props.match.params} />} />


                    {/* Routes Paramètres */}
                    <AuthRoute exact path="/parametres"><ParametresListScreen  /></AuthRoute>
                    <AuthRoute exact path="/parametres/creation"><ParametreCreationScreen /></AuthRoute>
                    <AuthRoute exact path="/parametres/:pid" component={(props: any) => <ParametreFicheScreen {...props.match.params} />} />



                    {/* Routes protégées par l'authentification et par accès aux modules */}
                    {/* Routes du module Formations */}
                    <AuthModuleCheckRoute exact path="/formations" module="formations"><FormationsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/formations/urssaf" module="formations"><UrssafListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/formations/urssaf/courrier" module="formations"><CourrierUrssafScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/formations/parametres" module="formations"><ParametresListScreen module="formations" /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/formations/intervenants" module="formations"><IntervenantsScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/formations/creation" module="formations" component={(props: any) => <FormationCreationInformationsScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/informations" module="formations" component={(props: any) => <FormationCreationInformationsScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/formation" module="formations" component={(props: any) => <FormationCreationFormationScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/documents" module="formations" component={(props: any) => <FormationCreationDocumentsScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/precisions" module="formations" component={(props: any) => <FormationCreationPrecisionsScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/complements" module="formations" component={(props: any) => <FormationCreationComplementsScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/inscription" module="formations" component={(props: any) => <FormationAjoutInscriptionScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/formations/:fid/participants/:pid/edit-email" module="formations" component={(props: any) => <FormationParticipantEditEmailScreen {...props.match.params} />} />


                    <AuthModuleCheckRoute exact path="/formations/:fid" module="formations" component={(props: any) => <FormationFicheScreen {...props.match.params} />} />


                    {/* Routes du module Tableau de bord */}
                    <AuthModuleCheckRoute exact path="/tableau-de-bord" module="tableau-bord"><TableauBordsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/tableau-de-bord/:tid" module="tableau-bord" component={(props: any) => <TableauBordFicheScreen {...props.match.params} />} />


                    {/* Routes du module Successions */}
                    <AuthModuleCheckRoute exact path="/successions" module="successions"><SuccessionsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/successions/creation" module="successions"><SuccessionCreationStepsScreen type="creation" /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/successions/:sid" module="successions" component={(props: any) => <SuccessionFicheScreen {...props.match.params} />} />



                    {/* Routes du module Sinistres */}
                    <AuthModuleCheckRoute exact path="/sinistres" module="sinistres"><SinistresListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/sinistres/creation" module="sinistres"><SinistreCreationStepsScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/sinistres/:sid" module="sinistres" component={(props: any) => <SinistreFicheScreen {...props.match.params} />} />

                    {/* Routes des ctrcs (modules sinistres) */}
                    <AuthModuleCheckRoute exact path="/ctrcs" module="sinistres"><CtrcsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/ctrcs/creation" module="sinistres"><CtrcCreationScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/ctrcs/:cid" module="sinistres" component={(props: any) => <CtrcFicheScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/ctrcs/:cid/sinistres" module="sinistres" component={(props: any) => <CtrcRattachementSinistresScreen {...props.match.params} />} />

                    {/* Routes du module Fil d'infos */}
	                <AuthModuleCheckRoute exact path="/fil-infos/creation" module="fil-dinfos" component={(props: any) => <FilInformationsCreationScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/fil-infos/:fid" module="fil-dinfos" component={(props: any) => <FilInformationsFicheScreen {...props.match.params} />} />



                    {/* Routes du module Différends */}
                    <AuthModuleCheckRoute exact path="/differends" module="differends"><DifferendsScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/differends/notaires" module="differends"><DifferendCreationStepsScreen type="notaires" /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/differends/:did/differend" module="differends" component={(props: any) => <DifferendCreationStepsScreen type="differend" {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/differends/:did" module="differends" component={(props: any) => <DifferendFicheScreen {...props.match.params} />} />



                    {/* Routes du module Inspections */}
                    <AuthModuleCheckRoute exact path="/inspections" module="inspections"><InspectionsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/inspections/statistiques" module="inspections"><StatistiquesListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/inspections/statistiques/import" module="inspections"><StatistiquesImport /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/inspections/statistiques/:oid" module="inspections" component={(props: any) => <StatistiquesFicheScreen {...props.match.params} />}  />
                    <AuthModuleCheckRoute exact path="/inspections/habilitations" module="inspections"><HabilitationsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/inspections/classements" module="inspections"><ClassementsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/inspections/:id" module="inspections" component={(props: any) => <InspectionFicheScreen {...props.match.params} />} />



                    {/* Routes du module Réclamations */}
                    <AuthModuleCheckRoute exact path="/reclamations" module="reclamations"><ReclamationsListScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/reclamations/creation" module="reclamations"><ReclamationCreationStepsScreen type="creation" /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/reclamations/:rid" module="reclamations" component={(props: any) => <ReclamationFicheScreen {...props.match.params} />} />


                    {/* Routes du module Cotisations */}
                    <AuthModuleCheckRoute exact path="/cotisations/parametres" module="cotisations"><ParametresListScreen module="cotisations" /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/cotisations/offices" module="cotisations"><CotisationsListeOfficesScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/cotisations/offices/:oid" module="cotisations" component={(props: any) => <CotisationsOfficeFicheScreen type="documents" {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/cotisations/saisie-offices" module="cotisations"><CotisationsSaisieOfficesScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/cotisations/saisie-notaires" module="cotisations"><CotisationsSaisieNotairesScreen /></AuthModuleCheckRoute>


                    {/* Routes du module Fils d'infos */}
                    <AuthModuleCheckRoute exact path="/fil-infos" module="fil-dinfos"><FilInformationsListScreen/></AuthModuleCheckRoute>


                    {/* Routes du module Correspondances */}
                    <AuthModuleCheckRoute exact path="/correspondances/courriers" module="correspondances"><CorrespondancesListScreen/></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/correspondances/courriers/creation" module="correspondances" component={(props: any) => <CorrespondanceCreationStepsScreen type="informations" {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/correspondances/courriers/:cid" module="correspondances" component={(props: any) => <CorrespondanceFicheScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/correspondances/courriers/:cid/[correspondance_lien]" module="correspondances" component={(props: any) => <CorrespondanceInscriptionExternalPreviewScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/correspondances/courriers/:cid/tests" module="correspondances" component={(props: any) => <CorrespondanceAjoutTestsScreen {...props.match.params} />} />
                    <AuthModuleCheckRoute exact path="/correspondances/courriers/:cid/invites-individuel" module="correspondances" component={(props: any) => <CorrespondanceAjoutInvitesScreen {...props.match.params} />} />

                    <AuthModuleCheckRoute exact path="/correspondances/lettres-types" module="correspondances"><LettresTypesListScreen/></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/correspondances/lettres-types/:lid" module="correspondances" component={(props: any) => <LettresTypesFicheScreen {...props.match.params} />} />

                    <AuthModuleCheckRoute exact path="/correspondances/signatures" module="correspondances"><SignaturesListScreen/></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/correspondances/signatures/creation" module="correspondances"><SignatureCreationScreen /></AuthModuleCheckRoute>
                    <AuthModuleCheckRoute exact path="/correspondances/signatures/:sid" module="correspondances" component={(props: any) => <SignatureFicheScreen {...props.match.params} />} />



                    {/* Routes protégées par l'authentification et par droit admin */}
                    <AuthAdminRoute exact path="/utilisateurs"><UsersListScreen /></AuthAdminRoute>
                    <AuthAdminRoute exact path="/utilisateurs/creation"><UserCreationScreen /></AuthAdminRoute>
                    <AuthAdminRoute exact path="/utilisateurs/:uid" component={(props: any) => <UserFicheScreen {...props.match.params} />} />

                    {/* Routes génériques */}
                    <Route exact path={AppConstants.path403}><ForbiddenScreen /></Route>
                    <Route><NotFoundScreen /></Route>
                </Switch>
            }
        </div>
    );
}

export default App;
