import React from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {AdresseConstants} from "../../../Constants/AdresseConstants";
import {AppConstants} from "../../../Constants/AppConstants";

type OfficesAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,

}
export default function OfficesAdvancedSearchComponent(props: OfficesAdvancedSearchComponentProps){
    return (
        <AdvancedSearchComponent title="Offices"
             showSummary={props.showSummary}
             modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
             showAdvancedSearch={props.showAdvancedSearch}
             filters={props.filters}
             columns={[
                 {
                     elements: [
                         {
                             type: 'hidden',
                             fieldName: "advanced",
                             oldValue: "1",
                             modificators: "-hidden"
                         },
                         {
                             type: "text",
                             fieldName: "nom_office",
                             label: "Nom",
                             modificators: "-on-white",
                             oldValue: props.filters.nom as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "CRPCEN",
                             label: "CRPCEN",
                             modificators: "-on-white",
                             oldValue: props.filters.CRPCEN as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "ville",
                             label: "Ville",
                             modificators: "-on-white",
                             oldValue: props.filters.ville as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "nom_notaires",
                             label: "Nom notaire",
                             modificators: "-on-white",
                             oldValue: props.filters.nom_notaires as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "nom_predecesseurs",
                             label: "Nom prédécesseurs",
                             modificators: "-on-white",
                             oldValue: props.filters.nom_predecesseurs as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "nom_negociateurs",
                             label: "Nom négociateur",
                             modificators: "-on-white",
                             oldValue: props.filters.nom_negociateurs as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "mail_negociateurs",
                             label: "Mail négociateur",
                             modificators: "-on-white",
                             oldValue: props.filters.mail_negociateurs as string || ''
                         },
                     ]
                 }, {
                     elements: [
                         {
                             type: "text",
                             fieldName: "mail_comptabilite",
                             label: "Mail comptabilité",
                             modificators: "-on-white",
                             oldValue: props.filters.mail_comptabilite as string || ''
                         },
                         {
                             type: 'radio',
                             fieldName: "actif",

                             label: "Actif",
                             placeholder: "Actif",
                             required: false,
                             options: AppConstants.ouiNonOptions,
                             hideSearchOnMultiple: true,
                             modificators: "-on-white",
                             oldValue: props.filters.actif as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "sirenSiret",
                             label: "Siren/Siret",
                             modificators: "-on-white",
                             oldValue: props.filters.sirenSiret as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "TVA",
                             label: "TVA",
                             modificators: "-on-white",
                             oldValue: props.filters.TVA as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "numeroAgrement",
                             label: "Numéro agrément",
                             modificators: "-on-white",
                             oldValue: props.filters.numeroAgrement as string || ''
                         },
                         {
                             type: "selectMultiple",
                             fieldName: "labels",
                             label: "Labels",
                             modificators: "-on-white",
                             hideSearchOnMultiple: true,
                             options: OfficesConstants.labelsOptions,
                             oldValues: props.filters.labels as string[] || []
                         },
                     ]
                 }, {
                     elements: [
                         {
                             type: "selectMultiple",
                             fieldName: "systemeCompta",
                             label: "Système comptabilité",
                             modificators: "-on-white",
                             hideSearchOnMultiple: true,
                             options: OfficesConstants.systemeComptaOptions,
                             oldValues: props.filters.systemeCompta as string[] || []
                         },
                         {
                             type: 'radio',
                             fieldName: "borneElectrique",

                             label: "Borne électrique",
                             placeholder: "Borne électrique",
                             required: false,
                             options: AppConstants.ouiNonOptions,
                             hideSearchOnMultiple: true,
                             modificators: "-on-white",
                             oldValue: props.filters.borneElectrique as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "codepostal",
                             label: "Code postal",
                             modificators: "-on-white",
                             oldValue: props.filters.codepostal as string || ''
                         },
                         {
                             type: "selectMultiple",
                             fieldName: "departement",
                             label: "Département",
                             modificators: "-on-white",
                             hideSearchOnMultiple: true,
                             options: OfficesConstants.departementsOptions,
                             oldValues: props.filters.departement as string[] || []
                         },
                         {
                             type: "select",
                             fieldName: "arrondissement",
                             label: "Arrondissement",
                             modificators: "-on-white",
                             options: AdresseConstants.arrondissementsOptions,
                             hideSearchOnMultiple: false,
                             oldValue: props.filters.arrondissement as string || ''
                         },
                         {
                             type: "select",
                             fieldName: "tribunal_judiciaire",
                             label: "Tribunal judiciaire",
                             modificators: "-on-white",
                             options: AdresseConstants.tribunalJudiciaireOptions,
                             hideSearchOnMultiple: false,
                             oldValue: props.filters.tribunal_judiciaire as string || ''
                         },
                     ]
                 }, {
                     elements: [
                         {
                             type: "radio",
                             fieldName: "office_cree",
                             label: "Office créé",
                             required: false,
                             options: AppConstants.ouiNonOptions,
                             hideSearchOnMultiple: true,
                             modificators: "-on-white",
                             oldValue: props.filters.office_cree as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "prenom_notaires",
                             label: "Prénom notaire",
                             modificators: "-on-white",
                             oldValue: props.filters.prenom_notaires as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "csn_notaires",
                             label: "CSN notaire",
                             modificators: "-on-white",
                             oldValue: props.filters.csn_notaires as string || ''
                         },
                         {
                             type: "text",
                             fieldName: "siret_notaires",
                             label: "Siret notaire",
                             modificators: "-on-white",
                             oldValue: props.filters.siret_notaires as string || ''
                         },
                         {
                             type: "selectMultiple",
                             fieldName: "languesParlees_notaires",
                             label: "Langues parlées",
                             modificators: "-on-white",
                             hideSearchOnMultiple: true,
                             options: NotairesConstants.languesParleesOptions,
                             oldValues: props.filters.languesParlees_notaires as string[] || []
                         },
                         {
                             type: "selectMultiple",
                             fieldName: "type",
                             label: "Type",
                             modificators:"-on-white",
                             hideSearchOnMultiple: true,
                             options: OfficesConstants.typesOptions,
                             oldValues: props.filters.type as string[] || []
                         },
                     ]
                 },
             ]}
             onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
             onClose={() => props.setShowAdvancedSearch()}
             onSearch={props.onSearch}
        />
    )

}
