import React from "react";
import FileHelper from "src/Helpers/File.helper";

type IDocumentLinkProps = {
    link: string,
    icon?: string,
    modificators?: string,
}


const DocumentLink = (props: IDocumentLinkProps) => {
    return <div className={`document-link ${props.modificators ? props.modificators: ''}`}>
        {
            props.icon && <span className={`icon ${props.icon}`} />
        }

        <span className="text">
            <a href={props.link} target="_blank" rel="noopener noreferrer" className={"link"}>{FileHelper.basename(props.link)}</a>
        </span>
    </div>
};

export default DocumentLink;
