import {IFormation} from "src/Models/Formation.model";
import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages, IFileDownloadApiResponse} from "src/Services/Api.service";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypeGetApiResponse,} from "src/Services/Courriers.service";
import {FormationsService, IFormationSendMailListFormData} from "src/Services/Formations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";

type IFormationCourrierConfirmationFormProps = {
    formation?: IFormation,
    ids?: string[],
    onCancelForm: () => void,
    onFormSuccess: () => void,
}

export default function FormationCourrierConfirmationForm(props: IFormationCourrierConfirmationFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersType, setCourriersType] = useState<ICourrierType>(null);
    const formationsService: FormationsService = new FormationsService();
    const [participantsIds, setParticipantsIds] = useState<string[]>([]);

    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();

        courriersService.getFormationsCourrierForPreview('confirmation',props.formation.id).then((response: ICourriersTypeGetApiResponse) => {
            if(response && response.datas && response.datas.type){
                setCourriersType(response.datas.type);
            }
        });
    }, [props.formation])


    useEffect( () => {
        if(props.ids) {
            setParticipantsIds(props.ids);
        }
    }, [props.ids])


    useEffect(()=> {
        if(!courriersType) return;

        const {formation} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "subjectMail",

                label: "Sujet du mail",
                placeholder: "Sujet de l'email",
                required: false,
                modificators: "-wysiwyg -on-white",

                oldValue: formation.saveConfirmationsSubject ? formation.saveConfirmationsSubject : (courriersType.sujet ? courriersType.sujet : ""),
            },
            {
                type: 'wysiwyg',
                fieldName: "contenuMail",

                label: "Contenu Email",
                placeholder: "Contenu Email",
                required: false,
                modificators: "-wysiwyg -on-white",

                oldValue: formation.saveConfirmationsMail ? formation.saveConfirmationsMail : (courriersType.contenuMail ? courriersType.contenuMail : ""),
            }
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: () => { props.onCancelForm() }
            },
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData as IFormationSendMailListFormData, participantsIds, onActionEnded)}
            },
            {
                label: "Sauvegarder",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSave(formData as IFormationSendMailListFormData, participantsIds, onActionEnded)}
            },
        ];
        setFormActions(currentFormActions);

    }, [courriersType, participantsIds]);



    /**
     * Sauvegarde du courrier
     *
     * @param {IFormationSendMailListFormData} formData
     * @param {string[]} currentIds
     * @param {() => void} onActionEnded
     */
    const onSave = (formData: IFormationSendMailListFormData, currentIds: string[], onActionEnded?: ()=>void): void => {
        formationsService.saveMailListContent(props.formation.id, 'confirmation', formData).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le courrier et le sujet ont été enregistrés.",
                type: "success"
            });
        }, (error:IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de l'enregistrement'.",
                type: "danger"
            });
        });
    }


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {IFormationSendMailListFormData} formData
     * @param {string[]} currentIds
     * @param {() => void} onActionEnded
     */
    const onSend = (formData: IFormationSendMailListFormData, currentIds: string[], onActionEnded?: ()=>void): void => {
        formData.ids = currentIds;


        formationsService.sendMailList(props.formation.id, 'confirmation', formData).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if(props.onFormSuccess) props.onFormSuccess();

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "L'envoi s'est déroulé avec succès.",
                type: "success"
            });
        }, (error:IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Une erreur est survenue lors de l'envoi.",
                type: "danger"
            });
        });
    }



    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
