import React from "react";
import AccordionSimpleComponent from "../Accordions/AccordionSimple.component";
import AccueilCompositionDetails from "./AccueilCompositionDetails";


export type IAccueilStatsComponentProps = {
    compositions: any
}

const AccueilCompositionsComponent = (props: IAccueilStatsComponentProps) => {

    return (
        <>
            <AccordionSimpleComponent
                modificators="-no-padding-left"
                title="Composition de la chambre"
                icon="icon-nombre-salaries"
                contentShowedOnInit={false}
                children={<AccueilCompositionDetails compositions={props.compositions.chambre} />}
            />

            <AccordionSimpleComponent
                modificators="-no-padding-left"
                title="Composition du CTRC"
                icon="icon-nombre-salaries"
                contentShowedOnInit={false}
                children={<AccueilCompositionDetails compositions={props.compositions.ctrc} />}
            />

            <AccordionSimpleComponent
                modificators="-no-padding-left"
                title="Composition de la Caisse Régionale de Garantie"
                icon="icon-nombre-salaries"
                contentShowedOnInit={false}
                children={<AccueilCompositionDetails compositions={props.compositions.caisse} />}
            />
        </>
    )
}
export default AccueilCompositionsComponent;
