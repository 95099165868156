import React from "react";
import {Link} from "react-router-dom";

type ISeeMoreLinkProps = {
    icon?: string,
    link?: string,
    text: string,
    modificators?: string,
    callback?: () => void
}


const SeeMoreLinkComponent = (props: ISeeMoreLinkProps ) => {

    /**
     * Gestion du click sur le lien
     *
     * @param e
     */
    const onClick = (e:React.MouseEvent) => {
        if( props.callback){
            e.preventDefault();
            props.callback();
        }
    }

    return (
        <Link to={props.link || '#'} className={`see-more-link ${props.modificators}`} onClick={onClick}>
            <span className="text">{props.text}</span>
            <i className={`icon ${props.icon || 'icon-voir'}`} />
        </Link>
    )
};

export default SeeMoreLinkComponent;
