const UseFileDownload = () =>{


    /**
     * Permet la création et le download d'un fichier à partir de son url
     *
     * @param {string} url
     * @param {boolean} blank
     */
    const handleFileDownload = (url:string,blank: boolean = false) => {
        const link = document.createElement('a');
        link.href = url;
        if(blank)
            link.target = "_blank";
        link.setAttribute('download', ``);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };



    return {handleFileDownload};
};

export default UseFileDownload;
