import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {AutresContactsService, IPersonneExterieureIdentiteFormData} from "src/Services/AutresContacts.service";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import PersonneExterieureIdentiteComponent
    from "src/Components/Annuaire/Autres-contacts/Personnes-exterieures/PersonneExterieureIdentite.component";
import PersonneExterieureIdentiteFormComponent
    from "src/Components/Forms/Annuaire/Autres-contacts/Personnes-exterieures/PersonneExterieureIdentiteForm.component";
import usePersonneExterieure from "src/Hooks/UsePersonneExterieure";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import AsidePersonneExterieureComponent from "src/Components/Aside/AsidePersonneExterieure.component";
import {IAdresse} from "src/Models/Adresse.model";
import MapHelper from "src/Helpers/Map.helper";
import AdressesFormComponent from "src/Components/Forms/Commons/AdressesForm.component";
import {CommonsService, IAdressesFormData, ICoordonneesFormData} from "src/Services/Commons.service";
import AdressesInfosComponent from "src/Components/Annuaire/Commons/Adresses/AdressesInfos.component";
import CoordonneesFormComponent from "src/Components/Forms/Commons/CoordonneesForm.component";
import CoordonneesInfosComponent from "src/Components/Annuaire/Commons/Coordonnees/CoordonneesInfos.component";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import {Link} from "react-router-dom";
import GroupesHelper from "../../../../Helpers/Groupes.helper";
import {ICoordonnee} from "../../../../Models/Coordonnee.model";
import CoordonneesHelper from "../../../../Helpers/Coordonnees.helper";


type PersonneExterieureFicheProps = {
    pid: string
}
export default function PersonneExterieureFicheScreen(props: PersonneExterieureFicheProps) {
    const identiteAccordionRef = useRef(null);
    const adressesAccordionRef = useRef(null);
    const coordonneesAccordionRef = useRef(null);

    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [personne, setPersonne] = useState<IPersonneExterieure>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const commonsService: CommonsService = new CommonsService();
    const history = useHistory();
    const [getPersonnesExterieuresById] = usePersonneExterieure();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if (!personne) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Personnes Extérieures", link: AppConstants.pathPersonnesExterieures},
            {text: personne.nom + " " + personne.prenom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [personne, loaded])


    useEffect(() => {
        if (!props.pid) {
            history.push(AppConstants.pathPersonnesExterieures);
        }

        getPersonneExterieure();

    }, [props.pid])


    /**
     * Récupération de la personne extérieure selon le pid
     */
    const getPersonneExterieure = (): void => {
        getPersonnesExterieuresById(props.pid, (personne: IPersonneExterieure) => {
            setPersonne(personne);

            const markers: MapMarker[] = [];
            if (personne.adressePrincipale && personne.adressePrincipale.latitude) {
                const adressePrincipale: IAdresse = personne.adressePrincipale;
                const markerText: string = MapHelper.createMapMarkerTextPersonneExterieure(`${AppConstants.pathPersonnesExterieures}/${personne.id}`, `${personne.nom} ${personne.prenom}`, adressePrincipale, personne.organisme);
                const marker: MapMarker = MapHelper.createMapMarker(markerText, adressePrincipale);

                markers.push(marker);
            }
            setMarkers(markers);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getPersonneExterieure();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if ((identiteAccordionRef && identiteAccordionRef.current)) {
            identiteAccordionRef.current.hideForm(isCancel);
        }
        if ((adressesAccordionRef && adressesAccordionRef.current)) {
            adressesAccordionRef.current.hideForm(isCancel);
        }
        if ((coordonneesAccordionRef && coordonneesAccordionRef.current)) {
            coordonneesAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={personne.nom + ' ' + personne.prenom}
                                         icon="icon-autres-contacts" onSearch={null}/>

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={identiteAccordionRef}
                                    title="Identité"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="personneExterieure"
                                    contentId={personne.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<PersonneExterieureIdentiteFormComponent personne={personne}
                                                                                   submitMethod={(formData: IPersonneExterieureIdentiteFormData) => autresContactsService.updatePersonne(props.pid, formData)}
                                                                                   onSuccessSubmit={onSuccessSubmitForm}
                                                                                   onCancel={onCancelForm}/>}
                                    infos={<PersonneExterieureIdentiteComponent personne={personne}/>}
                                />

                                {
                                    personne.organisme &&
                                    <AccordionSimpleComponent title="Informations de l'organisme" icon="icon-organismes"
                                                              contentShowedOnInit={true}>
                                        <div className="informations-wrapper">
                                            <div className="columns">
                                                <p className="title">
                                                    <Link to={`${AppConstants.pathOrganismes}/${personne.organisme.id}`}
                                                          target="_blank" rel="noopener noreferrer">
                                                        {personne.organisme.nom}
                                                    </Link>
                                                </p>
                                                <div className="column">
                                                    <div className="informations-block">
                                                        <p className="label">Groupes</p>
                                                        <p className="value">{personne.organisme && personne.organisme.groupes && personne.organisme.groupes.length ? GroupesHelper.getFormated(personne.organisme.groupes) : "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columns"></div>
                                        </div>

                                        <AdressesInfosComponent adresses={personne.organisme.adresses || null}
                                                                showTitle={true}/>

                                        <div className="informations-wrapper">
                                            <div className="columns"></div>
                                            {
                                                personne.organisme.coordonnees && personne.organisme.coordonnees.map((coordonnee: ICoordonnee, index: number) =>
                                                    <div className="columns" key={index}>
                                                        {
                                                            index === 0 &&
                                                            <p className="title">Coordonnées</p>
                                                        }
                                                        <div className="column">
                                                            <div className="informations-block">
                                                                <p className="label">Type</p>
                                                                <p className="value">{coordonnee.type ? CoordonneesHelper.getFormatedType(coordonnee.type) : "-"}</p>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <div className="informations-block">
                                                                <p className="label">Destination</p>
                                                                <p className="value">{coordonnee.destination ? CoordonneesHelper.getFormatedDestination(coordonnee.destination) : "-"}</p>
                                                            </div>
                                                        </div>

                                                        <div className="column">
                                                            <div className="informations-block">
                                                                <p className="label">Détail</p>
                                                                <p className="value">
                                                                    {
                                                                        coordonnee.coordonnees && (coordonnee.type === 'url') &&
                                                                        <a href={coordonnee.coordonnees} target="_blank" rel="noopener noreferrer" className={"link"} >{coordonnee.coordonnees}</a>
                                                                    }
                                                                    {
                                                                        coordonnee.coordonnees && (coordonnee.type === 'telephone') &&
                                                                        <>{CoordonneesHelper.formatPhoneNumberForDisplay(coordonnee.coordonnees)}</>
                                                                    }
                                                                    {
                                                                        coordonnee.coordonnees && (coordonnee.type !== 'url'&& coordonnee.type !== 'telephone') &&
                                                                        <>{coordonnee.coordonnees}</>
                                                                    }
                                                                    {
                                                                        !coordonnee.coordonnees && "-"
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </AccordionSimpleComponent>
                                }
                                {
                                    !personne.organisme &&
                                    <AccordionWithFormSwitchComponent
                                        ref={adressesAccordionRef}
                                        title="Adresses"
                                        icon="icon-coordonnees"
                                        contentShowedOnInit={true}
                                        contentType="personneExterieure"
                                        contentId={personne.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<AdressesFormComponent adresses={personne.adresses}
                                                                     relationId={personne.id}
                                                                     relationName="personneExterieure"
                                                                     submitMethod={(formData: IAdressesFormData) => commonsService.syncAdresses(formData)}
                                                                     onSuccessSubmit={onSuccessSubmitForm}
                                                                     onCancel={onCancelForm}/>}
                                        infos={<AdressesInfosComponent adresses={personne.adresses || null}/>}
                                    />
                                }


                                <AccordionWithFormSwitchComponent
                                    ref={coordonneesAccordionRef}
                                    title="Coordonnées"
                                    icon="icon-coordonnees-adresses"
                                    contentShowedOnInit={true}
                                    contentType="personneExterieure"
                                    contentId={personne.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<CoordonneesFormComponent coordonnees={personne.coordonnees}
                                                                    relationId={personne.id}
                                                                    relationName="personneExterieure"
                                                                    submitMethod={(formData: ICoordonneesFormData) => commonsService.syncCoordonnees(formData)}
                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                    onCancel={onCancelForm}/>}
                                    infos={<CoordonneesInfosComponent coordonnees={personne.coordonnees || null}/>}
                                />


                                <AsidePersonneExterieureComponent type="fiche" personne={personne}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Carte" icon="icon-map">
                            <div className="fiche-content border-footer">
                                <div className="map-wrapper">
                                    <MapComponent markers={markers} key={Math.random()}/>
                                </div>

                                <AsidePersonneExterieureComponent type="ficheCarte" modificators="-on-map"
                                                                  personne={personne}/>
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>


                </LayoutComponent>
            }
        </>
    )
}
