import {iNotification} from "react-notifications-component/dist/src/typings";

export const defaultNotificationConfig: iNotification = {
    container: "top-center",
    dismiss: {
        duration: 5000,
        onScreen: true
    },
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
};

export const defaultNotificationClickToCloseConfig: iNotification = {
    container: "top-center",
    dismiss: {
        duration: 20000,
        showIcon: true,
        onScreen: true
    },
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
};
