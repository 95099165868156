import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {CotisationsService, IGetCotisationsOffice} from "../Services/Cotisations.service";
import {ICotisationsOffice} from "../Models/CotisationsOffice.model";

const UseCotisation = () => {
    const cotisationsService: CotisationsService = new CotisationsService();
    const history = useHistory();


    const getCotisationsForOffice = (oid: string, callback:(cotisations: ICotisationsOffice[], tauxChambre: number, tauxCridon: number ) => void, callbackError?: () => void ) => {
        cotisationsService.getCotisationsForOffice(oid).then((response: IGetCotisationsOffice) => {
            if( response && response.datas.cotisations){
                if(callback){
                    callback(response.datas.cotisations, response.datas.tauxChambre || 0, response.datas.tauxCridon || 0);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathCotisationsListeOffices);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération des cotisations de l'office'",type: "danger"});
        });
    };


    return {getCotisationsForOffice};
};

export default UseCotisation;
