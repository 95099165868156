import {IOption} from "../Components/Fields/Select.field.component";

export type ISuccessionsConstants = {
    etatsOptions: IOption[]
}
export const SuccessionsConstants: ISuccessionsConstants = {
    etatsOptions: [
        {
            value: "attente",
            label: "En attente"
        },
        {
            value: "actif",
            label: "Actif"
        },
        {
            value: "suspendu",
            label: "Suspendu"
        },
        {
            value: "resolu",
            label: "Résolu"
        },
    ]
};
