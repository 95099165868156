import React, {Component, ErrorInfo} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import ErrorPageScreen from "src/Screens/ErrorPage.screen";

type ErrorBoundaryComponentProps = {};
type ErrorBoundaryComponentState = {
    hasError: boolean,
    message: string,
};

export default class ErrorBoundary extends Component<ErrorBoundaryComponentProps, ErrorBoundaryComponentState> {
    constructor(props: ErrorBoundaryComponentProps) {
        super(props);

        this.state = {
            hasError: false,
            message: ""
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if( AppConstants.useSentry){
            //Gestion Sentry
        }

        console.error('ErrorBoundary');
        console.log('error',error)
        console.log('errorInfo',errorInfo);
    }


    render() {
        if (this.state.hasError) {
            return <ErrorPageScreen message={this.state.message} />;
        }
        return this.props.children;
    }
}
