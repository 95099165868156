import {ISuccession} from "src/Models/Succession.model";
import SuccessionsHelper from "src/Helpers/Successions.helper";
import CoordonneesHelper from "../../Helpers/Coordonnees.helper";

type ISuccessionDemandeurComponentProps = {
    succession: ISuccession,
}

export default function SuccessionDemandeurComponent(props: ISuccessionDemandeurComponentProps) {
    const succession: ISuccession = props.succession;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{succession.demandeurNom || "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Adresse</p>
                        <p className="value">{succession.demandeurAdresse || "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Téléphone</p>
                        <p className="value">{succession.demandeurTelephone  ? CoordonneesHelper.formatPhoneNumberForDisplay(succession.demandeurTelephone) : "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Prénom</p>
                        <p className="value">{succession.demandeurPrenom || "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Code Postal</p>
                        <p className="value">{succession.demandeurCodePostal || "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Email</p>
                        <p className="value">{succession.demandeurEmail || "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Raison Sociale</p>
                        <p className="value">{succession.demandeurRaisonSociale || "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Ville</p>
                        <p className="value">{succession.demandeurVille || "-"}</p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column -size-2">
                    <div className="informations-block">
                        <p className="label">Motif</p>
                        <p className="value">{succession.demandeurMotif || "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
