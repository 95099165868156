import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideGroupesComponent from "src/Components/Aside/AsideGroupes.component";
import {IGroupe} from "src/Models/Groupe.model";
import {GroupesService, IGroupesListGetApiResponse, IGroupesGetFormData} from "src/Services/Groupes.service";
import CommonHelper from "src/Helpers/Common.helper";
import GroupesExportComponent from "src/Components/Annuaire/Groupes/GroupesExport.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";



type GroupesListScreenProps = {

}
export default function GroupesListScreen(props: GroupesListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [groupesList, setGroupesList] = useState<IGroupe[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathGroupes);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const [showExport, setShowExport] = useState<boolean>(false);


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Annuaire", link: AppConstants.pathAnnuaire},
            { text: "Groupes" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Récupération des utilisateurs
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const groupesService: GroupesService = new GroupesService();
        groupesService.getGroupes(filters as IGroupesGetFormData).then((response: IGroupesListGetApiResponse) => {
            if( response && response.datas.pagination){
                setGroupesList(response.datas.pagination.data);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if(!loaded){
                    setLoaded(true);
                }
            }
        });
    }




    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Groupes"
                    icon="icon-groupes"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {
                                                    [
                                                    {slug: "nom", label: "Nom"},
                                                    {slug: "automatique", label: "Automatique"},
                                                    {slug: "nbContacts", label: "Nb Contacts"}
                                                ].map((item, iIndex)=>
                                                    <th className="th" key={iIndex}>
                                                        <TableHeaderButtonComponent direction={filters.orderType as string || AppConstants.orderType.asc} value={item.slug} selectedValue={filters.orderBy as string || ""} label={item.label} click={(value:string)=>setOrder(value)} />
                                                    </th>)
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                groupesList && groupesList.map( (groupe: IGroupe, index: number) =>
                                                    <tr key={index} onClick={()=>history.push(`${AppConstants.pathGroupes}/${groupe.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {groupe.nom}
                                                        </td>
                                                        <td className="td">
                                                            {CommonHelper.getFormatedOuiNon(groupe.automatique)}
                                                        </td>
                                                        <td className="td">
                                                            {groupe.nbContacts ?? 0}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <PaginationComponent links={paginationLinks}  onPageClick={page=>updateFilters({page})}/>

                                    <AsideGroupesComponent type="list" exportCallback={() => setShowExport(true)} filters={filters}  />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>



            <GroupesExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} filters={filters} />
        </LayoutComponent>
    )
}
