import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { IBreadcrumbLink } from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, { IFilterData } from "src/Hooks/FiltersHandler";
import { AppConstants } from "src/Constants/AppConstants";
import { FormComponentFormData } from "src/Components/Forms/FormCreator.component";
import { IDifferend } from "src/Models/Differend.model";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, { TabPanelComponent } from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import { IApiPaginationLink } from "src/Services/Api.service";
import DifferentsAdvancedSearchComponent from "src/Components/Differends/DifferentsAdvancedSearch.component";
import AsideDifferendsComponent from "src/Components/Aside/AsideDifferends.component";
import DifferendsExportComponent from "src/Components/Differends/DifferendsExport.component";
import { DifferendsService, IDifferendsGetFormData } from "src/Services/Differends.service";
import DifferendsSummary from "src/Components/Differends/DifferendsSummary";
import LoadingComponent from "../../Components/Loading/Loading.component";

const DifferendsScreen = () => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const location = useLocation();
    const history = useHistory();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathDifferends);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);

    const [differends, setDifferends] = useState<IDifferend[]>([]);
    const [differendsArchives, setDifferendsArchives] = useState<IDifferend[]>([]);
    const [currentTab, setCurrentTab] = useState<string>("en_cours");


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Différends" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(defaultFilters, false, true);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({ ...defaultFilters, ...formData }, true);
        setShowAdvancedSearch(false);
    };

    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const differendsService: DifferendsService = new DifferendsService();

        const enCours = differendsService.getDifferends({ ...filters, etat: ["en_cours"] } as IDifferendsGetFormData);
        const archives = differendsService.getDifferends({ ...filters, etat: ["action_terminee"] } as IDifferendsGetFormData);

        Promise.all([enCours, archives]).then(([responseEnCours, responseArchives]) => {
            if (responseEnCours && responseEnCours.datas.pagination) {
                const newDifferendsList = responseEnCours.datas.pagination.data;
                setDifferends(newDifferendsList);

                //Pagination
                if (currentTab === "en_cours")
                    setPaginationLinks(responseEnCours.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
            if (responseArchives && responseArchives.datas.pagination) {
                const newDifferendsList = responseArchives.datas.pagination.data;
                setDifferendsArchives(newDifferendsList);

                //Pagination
                if (currentTab === "action_terminee")
                    setPaginationLinks(responseArchives.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });

        if (!loaded) {
            setLoaded(true);
        }
    };

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Différends"
                    icon="icon-differends"
                    onSearch={(keyword => updateFilters({ ...defaultFilters, keyword }, true))}
                    onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent defaultTab={'en_cours'} onClickTabCallback={(slug) => { setCurrentTab(slug); launchFilteredSearch() }}>
                            <TabPanelComponent label="En cours" icon="icon-sablier" slug="en_cours">
                                <div className="list-content -right-gutter">

                                    <DifferendsSummary differendsArchives={differendsArchives} differendsEnCours={differends} />

                                    <DifferentsAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={onAdvancedSearch}
                                    />

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    { slug: "dateReception", label: "Date de réception" },
                                                    { slug: "plaignant", label: "Notaire plaignant" },
                                                    { slug: "misEnCause", label: "Notaire mis en cause" },
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)} />
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                differends && differends.map((differend: IDifferend, index: number) =>
                                                    <tr key={index} onClick={() => history.push(`${AppConstants.pathDifferends}/${differend.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {differend.dateReception ? DateHelper.getFormatedDate(differend.dateReception) : ""}
                                                        </td>
                                                        <td className="td -bold">
                                                            {differend.plaignant}
                                                        </td>
                                                        <td className="td -bold">
                                                            {differend.misEnCause}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>


                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({ page })} />

                                    <AsideDifferendsComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>
                            <TabPanelComponent label="Actions terminées" icon="icon-archives" slug="action_terminee">
                                <div className="list-content -right-gutter">

	                                <DifferendsSummary differendsArchives={differendsArchives} differendsEnCours={differends} />

                                    <DifferentsAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={onAdvancedSearch}
                                    />

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    { slug: "dateReception", label: "Date de réception" },
                                                    { slug: "plaignant", label: "Notaire plaignant" },
                                                    { slug: "notaireMisEnCause", label: "Notaire mis en cause" },
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)} />
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                differendsArchives && differendsArchives.map((differend: IDifferend, index: number) =>
                                                    <tr key={index} onClick={() => history.push(`${AppConstants.pathDifferends}/${differend.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {DateHelper.getFormatedDate(differend.dateReception)}
                                                        </td>
                                                        <td className="td -bold">
                                                            {differend.plaignant}
                                                        </td>
                                                        <td className="td -bold">
                                                            {differend.misEnCause}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>


                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({ page })} />

                                    <AsideDifferendsComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            <DifferendsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} filters={filters}/>

        </LayoutComponent>
    )
};

export default DifferendsScreen;
