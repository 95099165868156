import {IOffice} from "src/Models/Office.model";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import OfficesHelper from "src/Helpers/Offices.helper";
import React from "react";
import {useHistory} from "react-router";
import {IFilterData} from "src/Hooks/FiltersHandler";

type IAnnexesTableListComponentProps = {
    filters: IFilterData,
    setOrder:(value:string)=>void,
    officesList: IOffice[],
}

export default function AnnexesTableListComponent(props: IAnnexesTableListComponentProps) {
    const {filters, setOrder, officesList} = props;
    const history = useHistory();

    return (
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                {[
                    {slug: "CRPCEN", label: "CRPCEN"},
                    {slug: "nom", label: "nom"},
                    {slug: 'officePrincipale', label: "Office Principal"},
                    {slug: "type", label: "type"},
                    {slug: "ville", label: "ville"},
                ].map((item, iIndex) => <th className="th" key={iIndex}>
                    <TableHeaderButtonComponent
                        direction={filters.orderType as string || AppConstants.orderType.asc}
                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                        label={item.label} click={(value: string) => setOrder(value)}/>
                </th>)}
            </tr>
            </thead>
            <tbody>
            {
                officesList && officesList.map((office: IOffice, index: number) =>
                    <tr key={index}
                        onClick={() => history.push(`/annuaire/offices/${office.id}`)}
                        className="tr">
                        <td className="td -bold">
                            {office.CRPCEN}
                        </td>
                        <td className="td">
                            {office.nom}
                        </td>
                        <td className="td">
                            {office?.officePrincipale?.nom}
                        </td>
                        <td className="td">
                            {OfficesHelper.getFormatedType(office?.officePrincipale?.type)}
                        </td>
                        <td className="td -uppercase">
                            {office.adressePrincipale ? office.adressePrincipale.ville : "-"}
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}
