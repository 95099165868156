import React from "react";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import {IFilInformations} from "../../Models/FilInformations.model";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import placeholder from "../../assets/images/placeholders/picture-woman.png";
import DateHelper from "../../Helpers/Date.helper";


const FilInformationsAgenda = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.evenements.map((evenement, evenementIndex) =>
                    <div className="columns" key={evenementIndex}>
                        <p className="title">{evenement.titre}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date</p>
                                <div className="value">{DateHelper.formatDate(evenement.date, "DD/MM/YYYY")}</div>
                            </div>
                            <div className="informations-block">
                                <p className="label">Texte</p>
                                <div className="value">
                                    <CommentairesComponent modificators="-full" commentaires={evenement.texte}/>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date de fin</p>
                                <div className="value">{evenement.dateFin ? DateHelper.formatDate(evenement.dateFin, "DD/MM/YYYY") : "-"}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {props.filInformations.evenements.length === 0 && <p>-</p>}
        </div>
    )
};

export default FilInformationsAgenda;
