import React, { useEffect } from "react";
import DateHelper from "../../Helpers/Date.helper";

export type IAccueilStatsComponentProps = {
    stats: any,
}

const AccueilStatsComponent = (props: IAccueilStatsComponentProps) => {

    const currentDay = DateHelper.getCurrentDay();
    const currentYear = DateHelper.getCurrentYear();
    const currentMonth = DateHelper.getCurrentMonth(true);


    return (
        <>
            <table className={`list-table -totaux`}>
                <thead className={"headers"}>
                    <tr>
                        <th className="th">
                            <div className="date-list-header">
                                <span className="day">{currentDay}</span>
                                <div className="details">
                                    <span className="month">{currentMonth}</span>
                                    <span className="year">{currentYear}</span>
                                </div>
                            </div>
                        </th>

                        <th className="th">
                            <span className="text">Calvados</span>
                        </th>
                        <th className="th">
                            <span className="text">Manche</span>
                        </th>
                        <th className="th">
                            <span className="text">Orne</span>
                        </th>
                        <th className="th">
                            <span className="text">Total Cour d'Appel</span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr className="tr -no-hover">
                        <td className="td -bold">Nombre d’offices actifs</td>
                        <td className="td -bold -w100px">{props.stats.office.calvados}</td>
                        <td className="td -bold -w100px">{props.stats.office.manche}</td>
                        <td className="td -bold -w100px">{props.stats.office.orne}</td>
                        <td className="td -bold -w200px">{props.stats.office.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td">Dont offices individuels</td>
                        <td className="td -w100px">{props.stats.officeIndividuels.calvados}</td>
                        <td className="td -w100px">{props.stats.officeIndividuels.manche}</td>
                        <td className="td -w100px">{props.stats.officeIndividuels.orne}</td>
                        <td className="td -w200px">{props.stats.officeIndividuels.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td">Dont sociétés</td>
                        <td className="td -w100px">{props.stats.officeSocietes.calvados}</td>
                        <td className="td -w100px">{props.stats.officeSocietes.manche}</td>
                        <td className="td -w100px">{props.stats.officeSocietes.orne}</td>
                        <td className="td -w200px">{props.stats.officeSocietes.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td -bold">Nombre d’annexes</td>
                        <td className="td -bold -w100px">{props.stats.annexes.calvados}</td>
                        <td className="td -bold -w100px">{props.stats.annexes.manche}</td>
                        <td className="td -bold -w100px">{props.stats.annexes.orne}</td>
                        <td className="td -bold -w200px">{props.stats.annexes.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td -bold">Nombre de notaires actifs</td>
                        <td className="td -bold -w100px">{props.stats.notairesActifs.calvados}</td>
                        <td className="td -bold -w100px">{props.stats.notairesActifs.manche}</td>
                        <td className="td -bold -w100px">{props.stats.notairesActifs.orne}</td>
                        <td className="td -bold -w200px">{props.stats.notairesActifs.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td">Dont notaires salariés</td>
                        <td className="td -w100px">{props.stats.notairesSalaries.calvados}</td>
                        <td className="td -w100px">{props.stats.notairesSalaries.manche}</td>
                        <td className="td -w100px">{props.stats.notairesSalaries.orne}</td>
                        <td className="td -w200px">{props.stats.notairesSalaries.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td">Dont notaires individuels</td>
                        <td className="td -w100px">{props.stats.notairesIndividuels.calvados}</td>
                        <td className="td -w100px">{props.stats.notairesIndividuels.manche}</td>
                        <td className="td -w100px">{props.stats.notairesIndividuels.orne}</td>
                        <td className="td -w200px">{props.stats.notairesIndividuels.total}</td>
                    </tr>
                    <tr className="tr -no-hover">
                        <td className="td">Dont notaires associés</td>
                        <td className="td -w100px">{props.stats.notairesAssocies.calvados}</td>
                        <td className="td -w100px">{props.stats.notairesAssocies.manche}</td>
                        <td className="td -w100px">{props.stats.notairesAssocies.orne}</td>
                        <td className="td -w200px">{props.stats.notairesAssocies.total}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
export default AccueilStatsComponent;

