import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {ICertification} from "src/Models/Certification.model";
import {CertificationsService, ICertificationInformationsFormData} from "src/Services/Certifications.service";
import CertificationInformationsFormComponent from "src/Components/Forms/Annuaire/Certifications/CertificationInformationsForm.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import CertificationInformationsComponent from "src/Components/Annuaire/Certifications/CertificationInformations.component";
import useCertifications from "src/Hooks/useCertifications";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import AsideCertificationsComponent from "src/Components/Aside/AsideCertifications.component";


type CertificationFicheProps = {
    cid: string
}
export default function CertificationFicheScreen(props: CertificationFicheProps){
    const informationAccordionRef = useRef(null);
    const [getCertificationById] = useCertifications();
    const [certification, setCertification] = useState<ICertification>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const certificationsService: CertificationsService = new CertificationsService();
    const history = useHistory();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!certification) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Certifications", link: AppConstants.pathCertifications},
            {text: certification.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [certification, loaded])



    useEffect(() => {
        if( !props.cid ){
            history.push(AppConstants.pathCertifications);
        }

        getCertification();

    }, [props.cid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getCertification = (): void => {
        getCertificationById(props.cid, (certification: ICertification) => {
            setCertification(certification);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCertification();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationAccordionRef && informationAccordionRef.current) ){
            informationAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={certification.nom} icon="icon-certifications" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={informationAccordionRef}
                                    title="Identité"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="certification"
                                    contentId={certification.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<CertificationInformationsFormComponent certification={certification} submitMethod={(formData: ICertificationInformationsFormData)=>certificationsService.update(props.cid, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<CertificationInformationsComponent certification={certification} />}
                                />


                                <AsideCertificationsComponent type='fiche' certification={certification} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
