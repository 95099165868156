import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type NotairesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
    isAnciens?: boolean,
}
export default function NotairesExportAnciensComponent(props: NotairesExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={ props.isAnciens ? "Anciens notaires" : "Notaires"}
                         icon="icon-notaires"
                         subTitle={ props.isAnciens ? "Export des anciens notaires" : "Export des notaires"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl= { props.isAnciens ? `api${AppConstants.pathNotaires}/export/anciens` : `api${AppConstants.pathNotaires}/export` }
                         columns={[
                             {
                              elements: [
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-notaires',
                                      fieldName: "identite",
                                      label: "Identité",
                                      options: [
                                          {value: 'nom', label: 'Nom'},
                                          {value: 'prenom', label: 'Prénom'},
                                          {value: 'fonctions', label: 'Fonction(s)'},
                                          {value: 'sexe', label: 'Sexe'},
                                          {value: 'type', label: 'Type de notaire'},
                                          {value: 'dateNaissance', label: 'Date de naissance'},
                                          {value: 'lieuNaissance', label: 'Lieu de naissance'},
                                          {value: 'CSN', label: 'N° CSN'},
                                          {value: 'labels', label: 'Labels'},
                                          {value: 'languesParlees', label: 'Langue(s) parlée(s)'},
                                          {value: 'agrementInspection', label: 'Agrément inspection'},
                                          {value: 'agrementComptabilite', label: 'Agrément comptabilité'},
                                          {value: 'siret', label: 'N° Siret'},
                                          {value: 'dateDeces', label: 'Date du décés'},
                                          {value: 'coordonnees.groupesEnvoi', label: 'Groupes'},
                                      ]
                                  },
                              ]
                          },
                          {
                              elements: [
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-repertoire',
                                      fieldName: "dates",
                                      label: "Dates importantes",
                                      options: [
                                          {value: 'dateSermentInitial', label: 'Date serment initial'},
                                          {value: 'dateDernierSerment', label: 'Date du dernier serment'},
                                          {value: 'dateNominationJournalOfficiel', label: 'Date JO (1e nomination)'},
                                          {value: 'dateRetrait', label: 'Date de retrait'},
                                          {value: 'dateHonorariat', label: 'Date honorariat'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-coordonnees-adresses',
                                      fieldName: "coordonnees",
                                      label: "Coordonnées",
                                      options: [
                                          {value: 'coordonnees.emailPrincipal', label: 'Email principal'},
                                          {value: 'coordonnees.emailConfidentiel', label: 'Email Confidentiel (Perso)'},
                                          {value: 'coordonnees.emailConvocation', label: 'Email Convocation'},
                                          {value: 'coordonnees.telPortable', label: 'Téléphone portable'},
                                          {value: 'coordonnees.telLigneDirecte', label: 'Téléphone ligne directe'},
                                          {value: 'coordonnees.telAutre', label: 'Téléphone autre'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-coordonnees',
                                      fieldName: "adresses",
                                      label: "Adresse Perso",
                                      options: [
                                          {value: 'adressesPerso.ligne1Perso', label: 'Rue'},
                                          {value: 'adressesPerso.ligne2Perso', label: 'Complément d\'adresse'},
                                          {value: 'adressesPerso.boitePostalePerso', label: 'Boîte Postale'},
                                          {value: 'adressesPerso.codePostalPerso', label: 'Code Postal'},
                                          {value: 'adressesPerso.villePerso', label: 'Ville'},
                                      ]
                                  },
                              ]
                          },
                          {
                              elements: [
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-building',
                                      fieldName: "office",
                                      label: "Dernier Office",
                                      options: [
                                          {value: 'offices.CRPCEN', label: 'CRPCEN'},
                                          {value: 'offices.nom', label: 'Office'},
                                          {value: 'officesAdresses.arrondissement', label: 'Arrondissement - Office'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-historique',
                                      fieldName: "historique",
                                      label: "Historique",
                                      options: [
                                          {value: 'autres.historiques', label: 'Historique'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-commentaires',
                                      fieldName: "commentaires",
                                      label: "Commentaires",
                                      options: [
                                          {value: 'commentaires', label: 'Commentaires'},
                                      ]
                                  },
                              ]
                          },
                      ]}
        />
    )
}
