import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideClassementsComponent from "src/Components/Aside/AsideClassements.component";
import ClassementsExportComponent from "src/Components/Inspections/Classements/ClassementsExport.component";
import {
    ClassementsService, IClassementHeader,
    IClassementOfficeData,
    IClassementsFormData, IClassementStatsData, IClassementTotauxData
} from "../../../Services/ClassementsService.service";
import TableHeaderButtonComponent from "../../../Components/Lists/TableHeaderButton.component";
import SelectMultipleFieldComponent from "../../../Components/Fields/SelectMultiple.field.component";
import {OfficesConstants} from "../../../Constants/OfficesConstants";
import Totaux from "../../../Components/Inspections/Classements/Totaux.component";
import ClassementStats from "../../../Components/Inspections/Classements/Stats.component";
import NumberHelper from "../../../Helpers/Number.helper";
import LoadingComponent from "../../../Components/Loading/Loading.component";


type IClassementsListScreenProps = {}


const ClassementsListScreen = (props: IClassementsListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const location = useLocation();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathClassements);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [donnee, setDonnee] = useState<string>("produitCourant");
    const [offices, setOffices] = useState<IClassementOfficeData[]>([]);
    const [headers, setHeaders] = useState<IClassementHeader[]>([]);
    const [years, setYears] = useState<number[]>([]);
    const [totaux, setTotaux] = useState<IClassementTotauxData>(null);
    const [stats, setStats] = useState<IClassementStatsData>(null);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const classementsService: ClassementsService = new ClassementsService();


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            {text: "Classements"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(defaultFilters);
    }, []);


    useEffect(() => {
        if (!filters) return null;
        if(donnee === "statistiques"){
            classementsService.getStatistiques(filters as IClassementsFormData).then((result)=>{
                if(result.datas && result.datas.stats){
                    setStats(result.datas.stats);
                }
            })
        }else{
            launchFilteredSearch();
        }

    }, [filters, donnee]);

    const onTabChange = (slug: string) => {
        setDonnee(slug);
    };


    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        classementsService.getClassementsOffices({...filters, donnee} as IClassementsFormData).then((response) => {
            if (response.datas && response.datas.pagination) {
                setOffices(response.datas.pagination.data);
                setPaginationLinks(response.datas.pagination.links);
                setHeaders(response.datas.pagination.headers);
                setYears(response.datas.pagination.years);
                setTotaux(response.datas.pagination.totaux)
            }

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    if (!offices) return null;

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Classements"
                    icon="icon-classements"
                    onSearch={donnee === "statistiques" ? null : (keyword => updateFilters({...defaultFilters, keyword}, true))}
                />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
						<TabsContainerComponent onClickTabCallback={onTabChange}>
							<TabPanelComponent label="Produit Courant" icon="" slug="produitCourant">
								<Totaux totaux={totaux} headers={headers}/>
								<div className="list-content -right-gutter">

									<ClassementTable setOrder={setOrder} filters={filters} headers={headers}
									                 offices={offices} years={years} paginationLinks={paginationLinks}
									                 updateFilters={updateFilters}/>
									<AsideClassementsComponent type="list" exportCallback={() => setShowExport(true)} filters={filters}  donnee={donnee}/>
								</div>
							</TabPanelComponent>

							<TabPanelComponent label="Produit Courant par notaire" icon="" slug="produitCourantByNotaire">
								<Totaux totaux={totaux} headers={headers}/>
								<div className="list-content -right-gutter">
									<ClassementTable setOrder={setOrder} filters={filters} headers={headers}
									                 offices={offices} years={years} paginationLinks={paginationLinks}
									                 updateFilters={updateFilters}/>
									<AsideClassementsComponent type="list" exportCallback={() => setShowExport(true)} filters={filters}   donnee={donnee}/>
								</div>
							</TabPanelComponent>

							<TabPanelComponent label="Résultat Courant" icon="" slug="resultatCourant">


								<Totaux totaux={totaux} headers={headers}/>
								<div className="list-content -right-gutter">
									<ClassementTable setOrder={setOrder} filters={filters} headers={headers}
									                 offices={offices} years={years} paginationLinks={paginationLinks}
									                 updateFilters={updateFilters}/>
									<AsideClassementsComponent type="list" exportCallback={() => setShowExport(true)} filters={filters}   donnee={donnee}/>
								</div>
							</TabPanelComponent>

							<TabPanelComponent label="Résultat Courant par notaire" icon="" slug="resultatCourantByNotaire">


								<Totaux totaux={totaux} headers={headers}/>
								<div className="list-content -right-gutter">
									<ClassementTable setOrder={setOrder} filters={filters} headers={headers}
									                 offices={offices} years={years} paginationLinks={paginationLinks}
									                 updateFilters={updateFilters}/>
									<AsideClassementsComponent type="list" exportCallback={() => setShowExport(true)} filters={filters}   donnee={donnee}/>
								</div>
							</TabPanelComponent>

							<TabPanelComponent label="Statistiques" icon="" slug="statistiques">
								<div className="list-content -right-gutter">
									<ClassementStats stats={stats} />
									<AsideClassementsComponent type="list" exportCallback={() => setShowExport(true)}  filters={filters}  donnee={donnee}/>
								</div>
							</TabPanelComponent>
						</TabsContainerComponent>
					</>
                }
            </div>

            <ClassementsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport}/>

        </LayoutComponent>
    )
};

export default ClassementsListScreen;

const ClassementTable = (props: {
    offices: IClassementOfficeData[],
    headers: IClassementHeader[],
    years: number[],
    filters: IFilterData,
    setOrder: (value: string) => void,
    paginationLinks: IApiPaginationLink[],
    updateFilters: (data: IFilterData) => void
}) => {
    return <>
        <div className="list-filters">
            <i className={`icon icon-filtre`}/>
            <SelectMultipleFieldComponent fieldName="departement"
                                          label="Départements"
                                          options={OfficesConstants.departementsOptions}
                                          hideCurrentValuesOnTop={true}
                                          errors={null}
                                          placeholder="Tous les départements"
                                          hideSearch={true}
                                          modificators={`-inline -on-white`}
                                          oldValues={props.filters.departement ? props.filters.departement as string[] : []}
                                          onChange={(values: string[]) => props.updateFilters({departement: values})}
            /></div>

        {props.offices && <table className={`list-table`}>
			<thead className={"headers"}>
			<tr>
                {props.headers.map((item, iIndex) => <th className={`th ${iIndex > 1 ? '-right' : ''}`} key={iIndex}>
                    <TableHeaderButtonComponent
                        key={iIndex}
                        direction={props.filters.orderType as string || AppConstants.orderType.asc}
                        value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                        label={item.label} click={(value: string) => props.setOrder(value)}/>
                </th>)}
			</tr>
			</thead>
			<tbody>
            {
                props.offices && props.offices.map((office: IClassementOfficeData, index: number) => {
                    return <tr key={index}>
                        <td className="td">
                            <strong>{office.nom}</strong>
                        </td>
                        <td className="td">
                            {office.CRPCEN}
                        </td>
                        <td className="td -center">{office.departement}</td>
                        {props.years.map((value, vIndex) => <td className="td -right" key={vIndex} width={120}>{NumberHelper.formatThousand(office[value])}</td>)}
                        <td className="td -right">{NumberHelper.formatThousand(office.moyenne)}</td>
                    </tr>
                })
            }
			</tbody>
		</table>}

        <PaginationComponent links={props.paginationLinks} onPageClick={page => props.updateFilters({page})}/>
    </>
}
