import React from "react";
import {IReclamation} from "src/Models/Reclamation.model";
import TableHeaderButtonComponent from "../Lists/TableHeaderButton.component";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import DateHelper from "src/Helpers/Date.helper";
import ReclamationsHelper from "src/Helpers/Reclamations.helper";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {ReclamationsConstants} from "../../Constants/ReclamationsConstants";


type IReclamationsListCommunComponentProps = {
    reclamationsList: IReclamation[],
    listType: string,
    filters: IFilterData,
    setOrder: (value: string) => void,
}

//Utilisé pour les onglets : En cours, Autres et Archivage
export default function ReclamationsListCommunComponent(props: IReclamationsListCommunComponentProps) {
    const {reclamationsList, filters, setOrder} = props;
    const history = useHistory();

    let columns = [];

    //Sur la page de listing à part En cours : Afficher une colonne Prochaine étape (prochaineEtape)
    if (props.listType && props.listType === TabsSlugs.EN_COURS) {
        columns.push({slug: "prochaineEtape", label: "Prochaine étape"});
    }

    const defaultColumns = [
        {slug: "dateReception", label: "Date de réception"},
        {slug: "plaignantOrder", label: "Plaignant"},
        {slug: "notaireOrder", label: "Notaire"},
        {slug: "syndicOrder", label: "Syndic"},
        {slug: "numeroDossier", label: "Numéro de dossier"},
        {slug: "conciliationDate", label: "Date conciliation"},
        {slug: "etat", label: "Etat"},
    ];

    //Ajouter les colonnes par défaut
    columns = columns.concat(defaultColumns);

    //Sur la page de listing Autres : Afficher une colonne Motif (etatAutre)
    if (props.listType && props.listType === TabsSlugs.AUTRES) {
        columns.push({slug: "etatAutre", label: "Motif"});
    }

    return (
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                {columns.map((item, iIndex) => <th className="th" key={iIndex}>
                    <TableHeaderButtonComponent
                        direction={filters.orderType as string || AppConstants.orderType.asc}
                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                        label={item.label} click={(value: string) => setOrder(value)}/>
                </th>)}
            </tr>
            </thead>

            <tbody>
            {
                reclamationsList && reclamationsList.map((reclamation: IReclamation, index: number) => {
                    let prochaineEtape = null;
                    if (reclamation && reclamation.prochaineEtape) {
                        prochaineEtape = ReclamationsConstants.prochaineEtapeOptions.find((prochaineEtapeOption) => {
                            return prochaineEtapeOption.value === reclamation.prochaineEtape;
                        });
                    }
                    return (
                        <tr key={index} className="tr -with-link-inside-td">
                            {/* Sur la page de listing à part En cours : Afficher une colonne Prochaine étape (prochaineEtape) */}
                            {props.listType && props.listType === TabsSlugs.EN_COURS &&
                                <td className="td -bullet">
                                    <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                        <span
                                            className={`state-bullet -next-step icon-bullet ${prochaineEtape != null ? prochaineEtape.circleColorModificators : "-empty"}`}
                                            title={prochaineEtape != null ? prochaineEtape.label : ''}>
                                            {prochaineEtape != null ? prochaineEtape.circleLetter : ''}
                                        </span>
                                    </Link>
                                </td>
                            }
                            <td className="td -bold">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.dateReception ? DateHelper.getFormatedDate(reclamation.dateReception) : ''}
                                </Link>
                            </td>
                            <td className="td">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.plaignants ? ReclamationsHelper.getNomPlaignants(reclamation) : ""}
                                </Link>
                            </td>
                            <td className="td">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.notaires ? ReclamationsHelper.getNomMisEnCause(reclamation) : ""}
                                </Link>
                            </td>
                            <td className="td">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.syndicInitial ? ReclamationsHelper.getNomSyndicInitial(reclamation) : ""}
                                </Link>
                            </td>
                            <td className="td">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.numeroDossier ? reclamation.numeroDossier : ""}
                                </Link>
                            </td>
                            <td className="td">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.conciliationDate ? DateHelper.getFormatedDate(reclamation.conciliationDate) : ""}
                                </Link>
                            </td>
                            <td className="td">
                                <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                    {reclamation.etat ? ReclamationsHelper.getFormatedEtat(reclamation.etat) : ''}
                                </Link>
                            </td>
                            {/* Afficher le motif sur le listing Autres */}
                            {props.listType && props.listType === TabsSlugs.AUTRES &&
                                <td className="td">
                                    <Link to={`${AppConstants.pathReclamations}/${reclamation.id}`}>
                                        {reclamation.etatAutre ? ReclamationsHelper.getFormatedEtatAutre(reclamation.etatAutre) : ''}
                                    </Link>
                                </td>
                            }
                        </tr>
                    )
                })
            }
            </tbody>
        </table>

    )
}
