import ExportComponent from "src/Components/Exports/Export.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {AutresContactsService} from "src/Services/AutresContacts.service";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import PersonnesExterieuresHelper from "src/Helpers/PersonnesExterieures.helper";

type InspectionsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
}
export default function InspectionsExportComponent(props: InspectionsExportComponentProps) {
    const [inspecteurLoaded, setInspecteursLoaded] = useState<boolean>(false);
    const [inspecteurs, setInspecteurs] = useState<IPersonneExterieure[]>([]);


    useEffect( () => {
        if (!props.showExport) return null;

            const autresContactsService: AutresContactsService = new AutresContactsService();
            autresContactsService.getInspecteursComptables().then((response) => {
                if (response && response.datas && response.datas.inspecteurs) {
                    //On limite la liste seulement à Raphaël PRUVOST ou Yohann MANCEL
                    const newInspecteursList = response.datas.inspecteurs.filter( (i) => ["353","279"].includes(i.id.toString()));

                    setInspecteurs(newInspecteursList);
                    setInspecteursLoaded(true);
                }
            });
        
    }, [props.showExport]);



    if (!props.showExport) return null;

    return (
        <>
            {
                inspecteurLoaded &&
                <ExportComponent title={"Inspections"}
                                 icon="icon-inspections"
                                 subTitle={"Export des inspections"}
                                 onClose={() => props.setShowExport()}
                                 sendFullObject={true}
                                 modificators={`${!props.showExport ? '-hide' : ''}`}
                                 exportUrl= {`api${AppConstants.pathInspections}/export/pdf`}
                                 columns={[
                                     {
                                         elements: [
                                             {
                                                 type: "selectMultiple",
                                                 hideSearchOnMultiple: true,
                                                 fieldName: "departements",
                                                 label: "Départements",
                                                 placeholder: "Départements",
                                                 modificators:"-on-white",
                                                 options: OfficesConstants.departementsOptions,
                                                 oldValues: []
                                             },
                                         ]
                                     },
                                     {
                                         elements: [
                                             {
                                                 type: 'selectMultiple',
                                                 fieldName: "inspecteurs",

                                                 label: "Inspecteurs Comptable",
                                                 placeholder: "Inspecteurs Comptable",
                                                 required: false,
                                                 modificators:"-on-white",
                                                 oldValues: [],
                                                 options: inspecteurs ? PersonnesExterieuresHelper.formatPersonnesListForOptions(inspecteurs) : []
                                             },
                                         ]
                                     },
                                 ]}
                />
            }
        </>


    )
}
