import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {ILabel} from "../Models/Label.model";

export type ILabelGetApiResponse = IApiCustomResponse & {
    datas: {
        label: ILabel
    }
}
export type ILabelsGetPaginationApi = IApiPagination & {
    data: ILabel[],
}
export type ILabelsListGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ILabelsGetPaginationApi
    }
}
export type ILabelsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}
export type ILabelInformationsFormData = {
    nom: string,
    module: string,
    fichier?: string
}
export type ILabelGetAllApiResponse = IApiCustomResponse & {
    datas: {
        labels: ILabel[]
    }
}



export class LabelsService extends ApiService{
    /**
     * Permet la récupération d'une liste des Labels
     *
     * @returns {Promise<ILabelsListGetApiResponse>}
     */
    public getLabels(formData: ILabelsGetFormData): Promise<ILabelsListGetApiResponse>{
        return this.doApiCall('api/annuaire/labels', 'GET', formData);
    }


    /**
     * Permet la récupération d'une liste des Labels
     *
     * @returns {Promise<ILabelsListGetApiResponse>}
     */
    public getAll(): Promise<ILabelGetAllApiResponse>{
        return this.doApiCall('api/annuaire/labels/all', 'GET');
    }


    /**
     * Permet la récupération d'une liste des Labels pour un module
     *
     * @param {string} module
     * @returns {Promise<ILabelGetAllApiResponse>}
     */
    public getAllForModule(module: string): Promise<ILabelGetAllApiResponse>{
        return this.doApiCall('api/annuaire/labels/all/' + module, 'GET');
    }


    /**
     * Permet la récupération d'un Label à partir de son id
     *
     * @param {string} lid
     * @returns {Promise<>}
     */
    public getLabel(lid: string): Promise<ILabelGetApiResponse>{
        return this.doApiCall('api/annuaire/labels/' + lid, 'GET');
    }

    /**
     * Creation d'un Label
     *
     * @param {ILabelInformationsFormData} formDatas
     * @returns {Promise<ILabelGetApiResponse>}
     */
    public create(formDatas: ILabelInformationsFormData): Promise<ILabelGetApiResponse>{
        return this.doApiCall('api/annuaire/labels/create', 'POST', formDatas);
    }


    /**
     * Modification d'un Label
     *
     * @param {string} lid
     * @param {ILabelInformationsFormData} formDatas
     * @returns {Promise<ILabelGetApiResponse>}
     */
    public update(lid: string, formDatas: ILabelInformationsFormData): Promise<ILabelGetApiResponse>{
        return this.doApiCall('api/annuaire/labels/' + lid + '/update', 'POST', formDatas);
    }


    /**
     * Permet la suppression d'un Label
     *
     * @param {number} lid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(lid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/labels/' + lid + '/delete', 'POST');
    }
}

