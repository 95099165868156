import React, {useEffect, useRef, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {INotaire} from "src/Models/Notaire.model";
import {INotaireGetApiResponse, INotaireIdentiteFormData} from "src/Services/Notaires.service";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useCreationSteps from "src/Hooks/useCreationSteps";
import OfficesHelper from "../../../../Helpers/Offices.helper";
import {IOffice} from "../../../../Models/Office.model";
import {IOfficesGetAllApiResponse, OfficesService} from "../../../../Services/Offices.service";
import ConfirmationComponent from "../../../Confirmation/Confirmation.component";
import PageHeaderComponent from "../../../Page/PageHeader.component";


type NotaireUpdateTypeNotaireFormComponentProps = {
    notaire?: INotaire,
    isAncienNotaire?: boolean,
    isOnCreationStep?: boolean,

    submitMethod: (formData: INotaireIdentiteFormData) => Promise<INotaireGetApiResponse>,
    onSuccessSubmit: (response: INotaireGetApiResponse) => void,
    onCloseModal: () => void,
}

export default function NotaireUpdateTypeNotaireFormComponent(props: NotaireUpdateTypeNotaireFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [formDatas, setFormDatas] = useState<FormComponentFormData>({});
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [offices, setOffices] = useState<IOffice[]>(null);
    const [officesActifs, setOfficesActifs] = useState<IOffice[]>(null);
    const [officesLoaded, setOfficesLoaded] = useState<boolean>(false);
    const [isLastStep, setIsLastStep] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string>("");
    const [showConfirmation, setShowConfirmation] = useState<boolean>();

    const {getFormActions} = useCreationSteps();
    const titleRef = useRef(null);

    const errorScroll = () => titleRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'nearest'
    })


    useEffect(() => {
        const officeService: OfficesService = new OfficesService();
        officeService.getAllOfficesPrincipales().then((response: IOfficesGetAllApiResponse) => {
            if (response && response.datas && response.datas.offices) {
                let officesTmp = response.datas.offices;

                //Trier les offices par CRPCEN
                let officesOrdered = officesTmp.sort((a, b) => (a.CRPCEN < b.CRPCEN ? -1 : 1));
                setOffices(officesOrdered);

                //Récupérer que les offices actifs (pour le choix du nouvel office)
                let officesOrderedActifs = officesOrdered.filter(office => office.actif === "oui");
                setOfficesActifs(officesOrderedActifs);

                setOfficesLoaded(true);
            }
        });
    }, [])

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const notaire: INotaire = props.notaire;

        let formElementsColumn1: FormElement[] = [];
        let formElementsColumn2: FormElement[] = [];
        let formElementsColumn3: FormElement[] = [];

        //Champs pour la première étape
        if (!isLastStep) {
            //Type de notaire : Non éditable => Type actuel
            formElementsColumn1.push({
                type: 'selectBrut',
                fieldName: "",

                label: "Type de notaire",
                placeholder: "Type de notaire",
                required: false,
                disabled: true,

                optionsGroup: NotairesConstants.typesFullOptions,

                oldValue: notaire && notaire.type ? notaire.type : "",
            });

            //Nom de l'office : Non éditable => Office actuel (si l'office est défini)
            if (notaire && notaire.office) {
                formElementsColumn2.push({
                    type: 'selectBrut',
                    fieldName: "",

                    label: "Nom de l'office",
                    options: offices && offices.length ? OfficesHelper.formatListForOptions(offices) : [],
                    required: false,
                    disabled: true,

                    oldValue: notaire.office.id ? "" + notaire.office.id : "",
                });
            }

            //Date de départ : Editable, Obligatoire
            //(masqué pour ceux qui ont le type : Ancien notaire ou Ancien notaire salarié ou Notaire retraité)
            if (notaire.office && !NotairesConstants.typesAnciens.includes(notaire.type)) {
                formElementsColumn3.push({
                    type: 'date',
                    fieldName: "dateDepart",

                    label: "Date de départ",
                    placeholder: "Date de départ",
                    required: true,
                    modificators: "-on-white",

                    oldValue: "",
                })
            }
        }
        //Champs pour la deuxième étape
        else if (isLastStep) {
            //Cas particulier (pour la sélection du type) : Un Notaire actif ne peut pas passer en tant que notaire honoraire.
            let typesNotaires = NotairesConstants.typesFullOptions;
            if (notaire && notaire.type && NotairesConstants.typesEnFonction.includes(notaire.type)) {
                typesNotaires = NotairesConstants.typesSansHonoraireOptions;
            }

            //Type de notaire : Editable, Obligatoire => Nouveau type
            formElementsColumn1.push({
                type: 'select',
                fieldName: "type",

                label: "Type de notaire",
                placeholder: "Type de notaire",
                required: true,
                modificators: "-on-white",

                optionsGroup: typesNotaires,

                oldValue: "",
            });

            if (NotairesConstants.typesEnFonction.includes(selectedType)) {
                //Office : Editable, Obligatoire => Nouvel Office
                formElementsColumn2.push({
                    type: 'select',
                    fieldName: "officeId",

                    label: "Office",
                    options: officesActifs && officesActifs.length ? OfficesHelper.formatListForOptions(officesActifs) : [],
                    required: true,
                    modificators: "-on-white",

                    oldValue: "",
                });

                //Date d'arrivée : Editable, Obligatoire
                formElementsColumn3.push({
                    type: 'date',
                    fieldName: "dateArrivee",

                    label: "Date d'arrivée",
                    placeholder: "Date d'arrivée",
                    required: true,
                    modificators: "-on-white",
                    showFieldErrorDetail: true,

                    oldValue: "",
                });
            }

            if (NotairesConstants.typesAnciensLight.includes(selectedType)) {
                //Date de retrait : Editable, Optionnel
                formElementsColumn2.push({
                    type: 'date',
                    fieldName: "dateRetrait",

                    label: "Date de retrait",
                    placeholder: "Date de retrait",
                    required: false,
                    modificators: "-on-white",

                    oldValue: "",
                });
            }

            if (selectedType === 'deces') {
                //Date du décès : Editable, Optionnel
                formElementsColumn2.push({
                    type: 'date',
                    fieldName: "dateDeces",

                    label: "Date du décès",
                    placeholder: "Date du décès",
                    required: false,
                    modificators: "-on-white",

                    oldValue: "",
                });
            }

            if (selectedType === 'honoraire') {
                //Date honarariat : Editable, Obligatoire
                formElementsColumn2.push({
                    type: 'date',
                    fieldName: "dateHonorariat",

                    label: "Date honorariat",
                    placeholder: "Date honorariat",
                    required: true,
                    modificators: "-on-white",

                    oldValue: "",
                });
            }
        }

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            }
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(false, onCancel, onSubmit, isLastStep, true);
        setFormActions(currentFormActions);

    }, [props.notaire, offices, isLastStep, selectedType]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        //Ajouter dans les données du formulaire le fait si c'est la dernière étape ou pas
        formData['isLastStep'] = isLastStep ? 'oui' : 'non';

        props.submitMethod(formData).then((response: INotaireGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep) {
                setIsLastStep(true);
            } else if (!goNextStep && props.onSuccessSubmit) {
                props.onSuccessSubmit(response);

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: response.messages[0],
                    type: "success"
                });
            }

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
                errorScroll();
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.fieldsMessages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le bouton d'annulation du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        let backFirstStepButtonPressed = false;

        if(formData['backFirstStepButtonPressed'] !== undefined) {
            backFirstStepButtonPressed = formData['backFirstStepButtonPressed'];
        }

        delete formData['backFirstStepButtonPressed'];

        //Quand on clique sur Annuler : Fermer la modale (demander la validation)
        if (!isLastStep || !backFirstStepButtonPressed) {
            setShowConfirmation(true);
            return;
        }

        //À la dernière étape, si on clique sur "Retour à l'étape 1", on revient à l'étape précédente
        if (isLastStep && backFirstStepButtonPressed) {
            //Vider/Supprimer les données saisies à la deuxième/dernière étape
            delete formData['officeId'];
            delete formData['dateArrivee'];
            delete formData['dateRetrait'];
            delete formData['dateDeces'];
            delete formData['dateHonorariat'];

            setFormDatas(formData);

            setIsLastStep(false);
            return;
        }
    };

    /**
     * On écoute le changement de valeur du select de type de notaires
     * (présent sur la 2ème étape pour tous les types actuels de notaires)
     *
     * @param {FormComponentFormData} formData
     */
    const onFormDataChange = (formData: FormComponentFormData): void => {
        if (formData['type']) {
            const currentType = formData['type'];

            if (currentType !== selectedType) {
                //Quand on change le type, vider/supprimer les informations déjà saisies
                delete formData['officeId'];
                delete formData['dateArrivee'];
                delete formData['dateRetrait'];
                delete formData['dateDeces'];
                delete formData['dateHonorariat'];

                setFormDatas(formData);

                setSelectedType(currentType);
            }
        }
    }

    /**
     * Validation de la confirmation
     * Débloquage du contenu
     */
    const onValidationConfirmation = () => {
        setShowConfirmation(false);

        if (props.onCloseModal) {
            props.onCloseModal();
        }
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    //Ajout le PageHeader ici en conditionnel

    return (
        <>
            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onValidationConfirmation} onCancel={onCancelConfirmation}
                                       text="Souhaitez-vous vraiment fermer la fenêtre d'édition du type de notaire ?"/>
            }
            <PageHeaderComponent text={!isLastStep ? "Étape 1":"Étape 2"}
                                 subText={props.notaire ? props.notaire.prenom + " " + props.notaire.nom : ""}
                                 icon={"icon-notaires"} modificators="-in-fullscreen"/>
            <div ref={titleRef} className="form-wrapper -edit">
                {
                    officesLoaded && formColumns &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        errorFieldsMessages={errorFieldsMessages}
                        modificatorsActions={'-right'}
                        onFormDataChange={(formData => (onFormDataChange(formData)))}
                        formDatas={formDatas}
                        errorMessages={errorMessages}/>
                }
            </div>
        </>
    )
}
