import React, {useEffect, useState} from "react";
import {IClassementHeader, IClassementStatsData} from "../../../Services/ClassementsService.service";
import moment from "moment";
import NumberHelper from "../../../Helpers/Number.helper";

const ClassementStats = (props: {
    stats: IClassementStatsData
}) => {

    const [stats, setStats] = useState();
    const [years,setYears] = useState<string[]>([]);
    const [yearsRaw,setYearsRaw] = useState<string[]>([]);

    useEffect(()=>{
        const currentYear = parseInt(moment().format("YYYY"));
        const firstYear = currentYear - 5;
        const newYears: string[] = [];
        const newYearsRaw: string[] = [];

        //for(let i = currentYear; i > firstYear; i--){
        for(let i = firstYear; i < currentYear; i++){
            newYears.push(`${i} [N${i<currentYear ? `${i-currentYear}` : ''}]`);
            newYearsRaw.push(i.toString());
        }

        setYears(newYears);
        setYearsRaw(newYearsRaw);
    },[]);

    if (!props.stats) return null;

    return <>
        <table className="list-table">
            <thead className="headers">
                <tr>
                    <th className="th"><span className="link"><span className="text">Données</span></span></th>
                    <th className="th">&nbsp;</th>
                    {years.map((y, yIndex)=><th key={yIndex} className="th -right"><span className="link"><span className="text">{y}</span></span></th>)}
                </tr>
            </thead>
            <tbody>
                {Object.keys(props.stats).map((statSlug,sIndex)=>{
                    const stat = props.stats[statSlug];
                    return <tr key={sIndex}>
                        <td className="td" width={200}>{stat.label}</td>
                        <td className="td" width={200}>
                            <p>Total:</p>
                            <p>Minimum:</p>
                            <p>Maximum:</p>
                            <p>Moyenne:</p>
                        </td>
                        {Object.keys(stat).map((statKey,statKeyIndex)=>{
                            if(statKey === "label" || statKey === "suffix"){
                                return null;
                            }

                            if(!yearsRaw.includes(statKey)){
                                return null;
                            }

                            return <td key={statKeyIndex} className="td -right">
                                <p>{ stat[statKey] && stat[statKey].total && isNaN(stat[statKey].total) ? 0 : NumberHelper.formatThousand(stat[statKey].total)}{stat.suffix || ''}</p>
                                <p>{ stat[statKey] && stat[statKey].min && isNaN(stat[statKey].min) ? 0 : NumberHelper.formatThousand(stat[statKey].min)}{stat.suffix || ''}</p>
                                <p>{ stat[statKey] && stat[statKey].max && isNaN(stat[statKey].max) ? 0 : NumberHelper.formatThousand(stat[statKey].max)}{stat.suffix || ''}</p>
                                <p>{ stat[statKey] && stat[statKey].moyenne && isNaN(stat[statKey].moyenne) ? 0 : NumberHelper.formatThousand(stat[statKey].moyenne)}{stat.suffix || ''}</p>
                            </td>;
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    </>
};

export default ClassementStats;
