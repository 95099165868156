import React, {useContext, useEffect, useState} from "react";
import CotisationSaisieItemComponent from "./CotisationSaisieItem.component";
import SeeMoreLinkComponent from "../Links/SeeMoreLink.component";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {Redirect} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {IStatistiquesFormData} from "src/Services/StatistiquesService.service";
import {CotisationsService, ICotisationsSaisieOfficesFormData} from "src/Services/Cotisations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse} from "src/Services/Api.service";
import {IFilterData} from "src/Hooks/FiltersHandler";
import TableHeaderButtonComponent from "../Lists/TableHeaderButton.component";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import OfficesHelper from "src/Helpers/Offices.helper";




type ICotisationSaisieOfficeCSNComponentProps = {
    cotisationsOffices: ICotisationsOffice[],
    currentYear: number,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onCancel: () => void,
    filters?: IFilterData,
    setOrder?: (value: string) => void,
    editingStatus?: boolean,
    setEditingStatus?: (status: boolean) => void,
}

export default function CotisationSaisieOfficeCSNComponent(props: ICotisationSaisieOfficeCSNComponentProps) {
    const {isAuthenticated} = useContext(UserContext) as IUserContext;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const [formData,setFormData] = useState<IStatistiquesFormData>({});
    const cotisationsService: CotisationsService = new CotisationsService();



    useEffect(() => {
        setIsEditing(props.editingStatus || false);
    }, [props.editingStatus])

    useEffect(() => {
        if(props.setEditingStatus && isEditing != props.editingStatus ){
            props.setEditingStatus(isEditing);
        }
    }, [isEditing])


    /**
     * Permet la récupération du fieldname pour l'item selon l'objet de cotisation de l'office
     *
     * @param {ICotisationsOffice} cotisationOffice
     * @param {string} fieldName
     * @returns {string}
     */
    const getFieldNameForItem = (cotisationOffice: ICotisationsOffice, fieldName:string): string => {
        return `${cotisationOffice.id}#${fieldName}`;
    }


    /**
     * Changement des valeurs du formulaire
     *
     * @param {string} fieldName
     * @param {string} value
     */
    const onValueChange = (fieldName: string, value:string) => {
        const newFormData = {...formData};

        newFormData[fieldName] = value;
        setFormData(newFormData);
    };



    /**
     * Soumission du formulaire
     *
     */
    const onSubmit = (): void => {
        cotisationsService.updateSaisieOffices(formData as ICotisationsSaisieOfficesFormData).then((response: IApiCustomResponse) => {
            setIsEditing(false);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les montants des cotisations des offices ont été modifiés.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des montants des cotisations des offices.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     */
    const onCancel = (): void => {
        setShowConfirmation(true);
    };


    /**
     * Validation de la confirmation
     * Débloquage du contenu
     */
    const onValidationConfirmation = () => {
        setIsEditing(false);
        setShowConfirmation(false);
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);

        props.onCancel();
    }

    //Affichage du formulaire
    const showForm = () => {
        //Vérification si la personne est loggée
        if (!isAuthenticated) {
            return <Redirect push to={AppConstants.pathLogin} />
        }

        setIsEditing(true);
    }




    return (
        <div className={`list-content -right-gutter ${isEditing ? '-with-form' : ''}`}>
            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onValidationConfirmation} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment annuler vos modifications ?" />
            }


            {
                !isEditing &&
                <div className="list-edit-button">
                    <SeeMoreLinkComponent text="Modifier" icon="icon-cog" modificators="-small" callback={()=>showForm()} />
                </div>
            }


            <table className={`list-table`}>
                <thead className={"headers"}>
                <tr>
                    {[
                        {slug: "CRPCEN", label: "CRPCEN"},
                        {slug: "nom", label: "Nom"},
                        {slug: "type", label: "Type"},
                        {slug: "acompteConseilSuperieur", label: "CSN Acompte"},
                        {slug: "soldeConseilSuperieur", label: "CSN Solde"},
                    ].map((item, iIndex) => <th className="th" key={iIndex}>
                        <TableHeaderButtonComponent
                            direction={props.filters.orderType as string || AppConstants.orderType.asc}
                            value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                            label={item.label} click={ props.setOrder ?  (value: string) => props.setOrder(value) : null}/>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {
                    props.cotisationsOffices && props.cotisationsOffices.map( (co: ICotisationsOffice, index: number) =>
                        <tr key={index}>
                            <td className="td">
                                {co.office.CRPCEN}
                            </td>
                            <td className="td">
                                {co.office.nom}
                            </td>
                            <td className="td">
                                {OfficesHelper.getFormatedType(co.office.type)}
                            </td>
                            <td className="td">
                                <CotisationSaisieItemComponent isEditing={isEditing}
                                                               value={co.acompteConseilSuperieur}
                                                               fieldName={getFieldNameForItem(co, 'acompteConseilSuperieur' )}
                                                               digits={2}
                                                               onValueChange={onValueChange}
                                />
                            </td>
                            <td className="td">
                                <CotisationSaisieItemComponent isEditing={isEditing}
                                                               value={co.soldeConseilSuperieur}
                                                               fieldName={getFieldNameForItem(co, 'soldeConseilSuperieur' )}
                                                               digits={2}
                                                               onValueChange={onValueChange}
                                />
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>


            {
                isEditing &&
                <div className="list-edit-actions">
                    <div className="form-actions ">
                        <button className="g-button -primary" onClick={() => onSubmit()}>Enregistrer</button>
                        <button className="g-button -is-link" onClick={() => onCancel()}>Annuler</button>
                    </div>
                </div>
            }
        </div>
    );
}
