import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IUser} from "src/Models/User.model";
import {UsersService} from "src/Services/Users.service";
import useListExportPDF from "../../Hooks/UseListExportPDF";

type IAsideUtilisateursComponentProps = {
    modificators?: string,
    type: "list" | "fiche",
    user?: IUser

    exportCallback?: () => void,
    filters?: any
}

export default function AsideUtilisateursComponent(props: IAsideUtilisateursComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-notaires-add", click: () => history.push(AppConstants.pathUtilisateurs + "/creation")});
        }
        else{
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        if( props.type === "fiche" && props.user){
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        }

        setActions(actions);
    }, [props.filters]);


    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const usersService: UsersService = new UsersService();

        usersService.delete(props.user.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'utilisateur a bien été supprimé.",
                type: "success"
            });

            history.push(AppConstants.pathUtilisateurs);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression de l'utilisateur.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api/users/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }

    return (
        <>
            {
                showConfirmation && props.user &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer cet utilisateur ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
