import React, {ChangeEvent, useEffect, useState} from "react";
import LabelComponent from "./Label.component";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

export type IRadioFieldProps = {
    value: string,
    id: string,
    name?: string,
    label?: string,
    labelModificators ?: string,
    modificators ?: string,
    circleColorModificators ?: string,

    isChecked?: boolean
    isDisabled?: boolean

    required?: boolean

    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
}


export default function RadioFieldComponent(props: IRadioFieldProps){
    const [isChecked, setIsChecked] = useState<boolean>(props.isChecked || false);
    const [isDisabled, setIsDisabled] = useState<boolean>(props.isDisabled || false);


    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);
    useEffect(() => {
        setIsDisabled(props.isDisabled);
    }, [props.isDisabled]);


    const suffix = generateUniqueID();

    return (
        <label className={`form-field-radio form-field-radio-color ${props.modificators || ""} ${props.isDisabled ? "-disabled" : ""}`} key={props.id} htmlFor={props.id+suffix}>
            <div className="radio-wrapper">
                <input type="radio" className="radio"
                       id={props.id+suffix}
                       onChange={props.onChange}
                       checked={isChecked}
                       disabled={isDisabled}
                       required={props.required}
                       value={props.value} />

                <i className={`picto -circle ${props.circleColorModificators || ""}`} />
            </div>

            {props.label && <LabelComponent fieldName={props.id+suffix} label={props.label} modificators={`-no-margin-b -fw-medium -got-hover ${props.labelModificators || "-size-2"} ${props.isChecked ? "-checked" : ""}`} />}
        </label>
    )
}
