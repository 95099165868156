import {IOfficeCertificationsLabelsHorairesFormData, IOfficeGetApiResponse} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import {AppConstants} from "src/Constants/AppConstants";
import {CertificationsService, ICertificationGetAllApiResponse} from "src/Services/Certifications.service";
import {ICertification} from "src/Models/Certification.model";
import CertificationsHelper from "src/Helpers/Certifications.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {ILabelGetAllApiResponse, LabelsService} from "src/Services/Labels.service";
import {ILabel} from "src/Models/Label.model";
import LabelsHelper from "src/Helpers/Labels.helper";


type IOfficeCertificationsLabelsHorairesFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IOfficeCertificationsLabelsHorairesFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeCertificationsLabelsHorairesFormComponent(props: IOfficeCertificationsLabelsHorairesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [certifications, setCertifications] = useState<ICertification[]>([]);
    const [labels, setLabels] = useState<ILabel[]>([]);
    const [certificationsLoaded, setCertificationsLoaded] = useState<boolean>(false);
    const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();


    useEffect( () => {
        const certificationsService: CertificationsService = new CertificationsService();
        certificationsService.getAll().then((response: ICertificationGetAllApiResponse) => {
            if( response && response.datas && response.datas.certifications){
                setCertifications(response.datas.certifications);
                setCertificationsLoaded(true);
            }
        });
        const labelsService: LabelsService = new LabelsService();
        labelsService.getAllForModule('office').then((response: ILabelGetAllApiResponse) => {
            if( response && response.datas && response.datas.labels){
                setLabels(response.datas.labels);
                setLabelsLoaded(true);
            }
        });
    }, []);

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const office: IOffice = props.office;
        const certificationsIDs: string[] = [];
        if(office && office.certifications){
            for(let certif of office.certifications){
                certificationsIDs.push(certif.id.toString());
            }
        }

        const labelsIds: string[] = [];
        if(office && office.labels){
            for(let certif of office.labels){
                labelsIds.push(certif.id.toString());
            }
        }


        const formElementsColumn1: FormElement[] = [
            {
                type: 'selectMultiple',
                fieldName: "certifications",

                label: "Certifications ISO",
                placeholder: "Certifications ISO",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: certifications ? CertificationsHelper.formatListForOptions(certifications) : [],

                oldValues: office && office.certifications ? certificationsIDs : []
            },
            {
                type: 'selectMultiple',
                fieldName: "labels",

                label: "Labels",

                placeholder: "Labels",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: labels ? LabelsHelper.formatListForOptions(labels) : [],

                oldValues: office && office.labels ? labelsIds : []
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'radio',
                fieldName: "borneElectrique",

                label: "Borne électrique",
                placeholder: "Borne électrique",
                required: false,
                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.borneElectrique ? office.borneElectrique : "",
            },
        ];


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, [certifications, labels]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeCertificationsLabelsHorairesFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: !props.isOnCreationStep ? "Les informations de l'office ont bien été modifiée." : "L'office a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                certificationsLoaded && labelsLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }
        </div>
    )

}
