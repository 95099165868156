import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type IOfficesConstants = {
    departementsOptions: IOption[],
    typesOptions: IOption[],
    typesOfficesOptions: IOption[],
    typesBureauOptions: IOption[],
    logicielsVisioOptions: IOption[],
    labelsOptions: IOption[],
    joursFermetureOptions: IOption[],
    systemeComptaOptions: IOption[],
    logicielRedactionActesOptions: IOption[],
    logicielNegociationOptions: IOption[],
    creationSteps?: ICreationSteps[],
    creationStepsAnnexe?: ICreationSteps[],
    vefaDocumentsOrderOptions?: IOption[],
}
export const OfficesConstants: IOfficesConstants = {
    typesOptions: [
        {
            value: "annexe",
            label: "Annexe"
        },
        {
            value: "eirl",
            label: "EIRL"
        },
        {
            value: "office",
            label: "Office"
        },
        {
            value: "sarl",
            label: "SARL"
        },
        {
            value: "sas",
            label: "SAS"
        },
        {
            value: "scp",
            label: "SCP"
        },
        {
            value: "selarl",
            label: "SELARL"
        },
        {
            value: "selas",
            label: "SELAS"
        },
        {
            value: "sfpl",
            label: "SFPL"
        },
    ],
    typesOfficesOptions: [
        {
            value: "eirl",
            label: "EIRL"
        },
        {
            value: "office",
            label: "Office"
        },
        {
            value: "sarl",
            label: "SARL"
        },
        {
            value: "sas",
            label: "SAS"
        },
        {
            value: "scp",
            label: "SCP"
        },
        {
            value: "selarl",
            label: "SELARL"
        },
        {
            value: "selas",
            label: "SELAS"
        },
        {
            value: "sfpl",
            label: "SFPL"
        },
    ],
    typesBureauOptions: [
        {
            value: "",
            label: "Aucun"
        },
        {
            value: "principal",
            label: "Bureau principal"
        },
        {
            value: "annexe",
            label: "Bureaux ou Annexes rattaché(e)s à cet office"
        },
    ],

    logicielsVisioOptions: [
        {
            value: "lifesize",
            label: "LifeSize"
        },
    ],
    labelsOptions: [
        {
            value: "delegueProtectionDonnees",
            label: "Délégué à la protection des données"
        },
        {
            value: "notexpert",
            label: "Notexpert"
        },
        {
            value: "notaireConseilPersonnesPubliques",
            label: "Notaire Conseil des Personnes Publiques"
        },
        {
            value: "notaireConseilMondeRural",
            label: "Notaire Conseil du Monde Rural"
        },
        {
            value: "notaireConseilGestionImmobiliere",
            label: "Notaire Conseil en Gestion Immobilière"
        },
        {
            value: "notaireJuristeEntreprise",
            label: "Notaire Juriste d'Entreprise"
        },
    ],
    joursFermetureOptions: [
        {
            value: "lundi",
            label: "Lundi"
        },
        {
            value: "mardi",
            label: "Mardi"
        },
        {
            value: "mercredi",
            label: "Mercredi"
        },
        {
            value: "jeudi",
            label: "Jeudi"
        },
        {
            value: "vendredi",
            label: "Vendredi"
        },
        {
            value: "samedi",
            label: "Samedi"
        },
    ],
    systemeComptaOptions: [
        {
            value: "fiducialNotairesComptabilite",
            label: "Fiducial Notaires Comptabilité"
        },
        {
            value: "iNotComptabilite",
            label: "iNotComptabilité"
        },
        {
            value: "jurisCompta",
            label: "Juris Compta"
        },
        {
            value: "jurisWeb",
            label: "JurisWeb"
        },
        {
            value: "wincompt",
            label: "Wincompt"
        },
    ],
    logicielRedactionActesOptions: [
        {
            value: "genapiInot",
            label: "Genapi iNot"
        },
        {
            value: "fiducial",
            label: "Fiducial"
        },
        {
            value: "fiducialSignature",
            label: "Fiducial Signature"
        },
        {
            value: "fiducialWinnot",
            label: "Fiducial Winnot"
        },
        {
            value: "fiducialNotairesActes",
            label: "Fiducial Notaires Actes"
        },
        {
            value: "fichorgaAuthenTic",
            label: "Fichorga Authen.Tic"
        },
        {
            value: "realInot",
            label: "Real.inot"
        },
    ],
    logicielNegociationOptions: [
        {
            value: "fiducial",
            label: "Fiducial"
        },
        {
            value: "genapi",
            label: "Genapi"
        },
        {
            value: "immonot",
            label: "Immonot"
        },
        {
            value: "noty",
            label: "Noty (Soqrate)"
        },
        {
            value: "theano",
            label: "Theano"
        },
        {
            value: "transim",
            label: "Transim"
        },
    ],


    departementsOptions: [
        {
            value: "14",
            label: "Calvados"
        },
        {
            value: "50",
            label: "Manche"
        },
        {
            value: "61",
            label: "Orne"
        },
    ],

    creationSteps: [
        {
            url: "/annexes",
            title: "Annexes",
            icon: "icon-office",
        },
        {
            url: "/coordonnees",
            title: "Coordonnées",
            icon: "icon-coordonnees-adresses",
        },
        {
            url: "/adresses",
            title: "Adresse",
            icon: "icon-coordonnees",
        },
        {
            url: "/chiffres",
            title: "Données Chiffrées",
            icon: "icon-chiffre",
        },
        {
            url: "/informations-complementaires",
            title: "Informations complémentaires",
            icon: "icon-informations",
        },
        {
            url: "/predecesseurs",
            title: "Prédécesseurs",
            icon: "icon-predecesseur",
        },
        {
            url: "/certifications-labels-horaires",
            title: "Certifications, Labels et horaires",
            icon: "icon-certifications",
        },
        {
            url: "/sites-groupes-logiciels",
            title: "Sites, Groupes et Logiciels",
            icon: "icon-logiciels",
        },
        {
            url: "/negociateurs",
            title: "Négociateurs",
            icon: "icon-negociateurs",
        },
        {
            url: "/repertoires",
            title: "Répertoires",
            icon: "icon-repertoire",
        },
        {
            url: "/recus-livraison",
            title: "Reçus de livraison",
            icon: "icon-recus-livraison",
        },
        {
            url: "/commentaires",
            title: "Commentaires",
            icon: "icon-commentaires",
        },
    ],
    creationStepsAnnexe: [
        {
            url: "/coordonnees",
            title: "Coordonnées",
            icon: "icon-coordonnees-adresses",
        },
        {
            url: "/informations-complementaires",
            title: "Informations complémentaires",
            icon: "icon-informations",
        },
        {
            url: "/adresses",
            title: "Adresse",
            icon: "icon-coordonnees",
        },
        {
            url: "/commentaires",
            title: "Commentaires",
            icon: "icon-commentaires",
        },
    ],

    vefaDocumentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "dateDepot",
            label: "Date de dépôt"
        },
        {
            value: "nomCleaned",
            label: "Nom"
        },
        {
            value: "commentaires",
            label: "Ville"
        },
    ]
};
