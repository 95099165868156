import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import {useHistory} from "react-router";
import UseCotisation from "src/Hooks/UseCotisation";
import YearsListComponent from "src/Components/Cotisations/YearsList.component";
import CotisationsOfficeResumeComponent from "src/Components/Cotisations/CotisationsOfficeResume.component";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import CotisationsOfficeRecapComptaComponent from "src/Components/Cotisations/CotisationsOfficeRecapCompta.component";
import CotisationsOfficeRecapProduitsCourantsComponent from "src/Components/Cotisations/CotisationsOfficeRecapProduitsCourants.component";
import CotisationsOfficeRecapGarantieCollectiveComponent from "src/Components/Cotisations/CotisationsOfficeRecapGarantieCollective.component";
import CotisationsOfficeRecapAbsenceCollaborateurComponent from "src/Components/Cotisations/CotisationsOfficeRecapAbsenceCollaborateur.component";
import CotisationsOfficeRecapCridonComponent from "src/Components/Cotisations/CotisationsOfficeRecapCridon.component";
import CotisationsOfficeRecapConseilSuperieurNotariatComponent from "src/Components/Cotisations/CotisationsOfficeRecapConseilSuperieurNotariat.component";
import CotisationsOfficeRecapAssuranceRCComponent from "src/Components/Cotisations/CotisationsOfficeRecapAssuranceRC.component";
import CotisationsOfficeRecapPertesExploitationComponent from "src/Components/Cotisations/CotisationsOfficeRecapPertesExploitation.component";
import CotisationsOfficeRecapConventionAssuranceCyberComponent from "src/Components/Cotisations/CotisationsOfficeRecapConventionAssuranceCyber.component";
import CotisationsOfficeRecapAssuranceMaladieComponent from "src/Components/Cotisations/CotisationsOfficeRecapAssuranceMaladie.component";
import CotisationsOfficeRecapDecesInvaliditeComponent from "src/Components/Cotisations/CotisationsOfficeRecapDecesInvalidite.component";
import CotisationsOfficeRecapAdhesionCNAFComponent from "src/Components/Cotisations/CotisationsOfficeRecapAdhesionCNAF.component";
import CotisationsOfficeRecapChambreComponent from "src/Components/Cotisations/CotisationsOfficeRecapChambre.component";
import CotisationsOfficeRecapFraisTraitementComponent from "src/Components/Cotisations/CotisationsOfficeRecapFraisTraitement.component";
import CotisationsOfficeRecapRepertoiresComponent from "src/Components/Cotisations/CotisationsOfficeRecapRepertoires.component";
import CotisationsOfficeRecapTotalComponent from "src/Components/Cotisations/CotisationsOfficeRecapTotal.component";
import CotisationsOfficeRecapComptaFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapComptaForm.component";
import {CotisationsService, ICotisationsSaisieOfficeFormData} from "src/Services/Cotisations.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import CotisationsOfficeRecapGarantieCollectiveFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapGarantieCollectiveForm.component";
import CotisationsOfficeRecapAbsenceCollaborateurFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapAbsenceCollaborateurForm.component";
import CotisationsOfficeRecapCridonFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapCridonForm.component";
import CotisationsOfficeRecapConseilSuperieurNotariatFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapConseilSuperieurNotariatForm.component";
import CotisationsOfficeRecapAssuranceRCFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapAssuranceRCForm.component";
import CotisationsOfficeRecapPerteExploitationFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapPerteExploitationForm.component";
import CotisationsOfficeRecapCyberFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapCyberForm.component";
import CotisationsOfficeRecapChambreFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapChambreForm.component";
import CotisationsOfficeRecapFraisTraitementFormComponent from "src/Components/Forms/Cotisations/CotisationsOfficeRecapFraisTraitementForm.component";
import CotisationSaisieAssuranceMaladieNotaireTableComponent from "src/Components/Cotisations/CotisationSaisieAssuranceMaladieNotaireTable.component";
import {ICotisationsNotaire} from "src/Models/CotisationsNotaire.model";
import CotisationSaisieDecesInvaliditeNotairesComponent from "src/Components/Cotisations/CotisationSaisieDecesInvaliditeNotaires.component";
import CotisationSaisieCNAFNotairesComponent from "src/Components/Cotisations/CotisationSaisieCNAFNotaires.component";
import CotisationSaisieFraisTraitementNotairesComponent from "src/Components/Cotisations/CotisationSaisieFraisTraitementNotaires.component";
import moment, {Moment} from "moment";
import CotisationsOfficeCourrierForm from "../../Components/Forms/Cotisations/CotisationsOfficeCourrierForm.component";
import CotisationsOfficeRecapTotauxFormComponent
    from "../../Components/Forms/Cotisations/CotisationsOfficeRecapTotauxForm.component";



type ICotisationsOfficeFicheScreenProps = {
    oid: string
}
export default function CotisationsOfficeFicheScreen(props: ICotisationsOfficeFicheScreenProps){
    const comptaAccordionRef = useRef(null);
    const garantieCollectiveRef = useRef(null);
    const absenceCollaborateurRef = useRef(null);
    const cotisationCridonRef = useRef(null);
    const csnRef = useRef(null);
    const assuranceRCRef = useRef(null);
    const perteExploitationRef = useRef(null);
    const cyberRef = useRef(null);
    const chambreRef = useRef(null);
    const traitementRef = useRef(null);
    const totalRef = useRef(null);

    const currentDate:Moment = moment();
    const momentCurrentYear: string = currentDate.format("YYYY");

    const [cotisationsOffice, setCotisationsOffice] = useState<ICotisationsOffice[]>(null);
    const [currentCotisation, setCurrentCotisation] = useState<ICotisationsOffice>(null);
    const [cotisationsNotaires, setCotisationsNotaires] = useState<ICotisationsNotaire[]>(null);
    const [cotisationsNotairesAssuranceMaladie, setCotisationsNotairesAssuranceMaladie] = useState<ICotisationsNotaire[]>(null);
    const [cotisationsNotairesDecesInvalidite, setCotisationsDecesInvalidite] = useState<ICotisationsNotaire[]>(null);
    const [cotisationsNotairesAdhesionCNAF, setCotisationsAdhesionCNAF] = useState<ICotisationsNotaire[]>(null);
    const [cotisationsNotairesFraisTraitement, setCotisationsFraisTraitement] = useState<ICotisationsNotaire[]>(null);


    const [tauxChambre, setTauxChambre] = useState<number>(0);
    const [tauxCridon, setTauxCridon] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [cotisationsYears, setCotisationsYears] = useState<number[]>(null);
    const [currentYear, setCurrentYear] = useState<number>(null);
    const history = useHistory();
    const {getCotisationsForOffice} = UseCotisation();
    const cotisationsService: CotisationsService = new CotisationsService();
    const [formOpened, setFormOpened] = useState<boolean>(false);



    useEffect(() => {
        if( !props.oid ){
            history.push(AppConstants.pathCotisationsListeOffices);
        }
        const newCotisationsYears: number[] = [];
        for(let i = 0, j = 5; i < j; i++){
            const newYear: number = parseInt(momentCurrentYear) - i;
            newCotisationsYears.push(newYear);
        }
        setCotisationsYears(newCotisationsYears)

        if(!currentYear){
            setCurrentYear(parseInt(momentCurrentYear));
        }

        getCotisations();

    }, [props.oid]);



    useEffect(() => {
        if(!currentCotisation) return;

        const breadcrumbLinks = [
            { text: "Cotisations",  link: AppConstants.pathMosaique + '#cotisations'},
            {text: "Offices", link: AppConstants.pathCotisationsListeOffices},
            {text: currentCotisation.office.nom},
        ];

        setBreadcrumbLinks(breadcrumbLinks);
    }, [loaded, currentCotisation]);


    
    useEffect(()=> {
        if(!cotisationsOffice || !currentYear ) return;

        const newCurrentCotisation: ICotisationsOffice = cotisationsOffice.find((c) => c.annee == currentYear);
        setCurrentCotisation(newCurrentCotisation);

        //On récupère la liste de tous les notaires pour la cotisation
        const cotisationsNotaireList: ICotisationsNotaire[] = newCurrentCotisation && newCurrentCotisation.cotisationsNotaires && newCurrentCotisation.cotisationsNotaires.list ? newCurrentCotisation.cotisationsNotaires.list : [];

        //On filtre la liste des notaires de la cotisation par année
        const newCotisationsNotaires: ICotisationsNotaire[] = cotisationsNotaireList.filter( (n: ICotisationsNotaire) => n.annee == currentYear);
        setCotisationsNotaires(newCotisationsNotaires);

        //On filtre la liste des notaires de la cotisation pour l'assurance Maladie
        const notairesIdsAssuranceMaladie: number[] = newCurrentCotisation && newCurrentCotisation.cotisationsNotaires && newCurrentCotisation.cotisationsNotaires.assuranceMaladie ? newCurrentCotisation.cotisationsNotaires.assuranceMaladie : [];
        const newCotisationsNotairesAssurancesMaladie = newCotisationsNotaires.filter( (n) => notairesIdsAssuranceMaladie.includes(n.id) );
        setCotisationsNotairesAssuranceMaladie(newCotisationsNotairesAssurancesMaladie);

        //On filtre la liste des notaires de la cotisation pour la garantie Déces Invalidite
        const notairesIdsDecesInvalidite: number[] = newCurrentCotisation && newCurrentCotisation.cotisationsNotaires && newCurrentCotisation.cotisationsNotaires.decesInvalidite ? newCurrentCotisation.cotisationsNotaires.decesInvalidite : [];
        const newCotisationsNotairesDecesInvalidite = newCotisationsNotaires.filter( (n) => notairesIdsDecesInvalidite.includes(n.id) );
        setCotisationsDecesInvalidite(newCotisationsNotairesDecesInvalidite);

        //On filtre la liste des notaires de la cotisation pour l'adhesion CNAF
        const notairesIdsCNAF: number[] = newCurrentCotisation && newCurrentCotisation.cotisationsNotaires && newCurrentCotisation.cotisationsNotaires.adhesionCNAF ? newCurrentCotisation.cotisationsNotaires.adhesionCNAF : [];
        const newCotisationsNotairesCNAF = newCotisationsNotaires.filter( (n) => notairesIdsCNAF.includes(n.id) );
        setCotisationsAdhesionCNAF(newCotisationsNotairesCNAF);

        //On filtre la liste des notaires de la cotisation pour les frais de traitement
        const notairesIdsFraisTraitement: number[] = newCurrentCotisation && newCurrentCotisation.cotisationsNotaires && newCurrentCotisation.cotisationsNotaires.fraisTraitement ? newCurrentCotisation.cotisationsNotaires.fraisTraitement : [];
        const newCotisationsNotairesFrais = newCotisationsNotaires.filter( (n) => notairesIdsFraisTraitement.includes(n.id) );
        setCotisationsFraisTraitement(newCotisationsNotairesFrais);
    }, [currentYear, cotisationsOffice]);



    const getCotisations = (): void => {
        getCotisationsForOffice(props.oid, (cotisations: ICotisationsOffice[], tauxChambre: number, tauxCridon: number) => {
            setCotisationsOffice(cotisations);
            setTauxChambre(tauxChambre);
            setTauxCridon(tauxCridon);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };





    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCotisations();

        closeForms();
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };


    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((comptaAccordionRef && comptaAccordionRef.current) ){
            comptaAccordionRef.current.hideForm(isCancel);
        }
        if((garantieCollectiveRef && garantieCollectiveRef.current) ){
            garantieCollectiveRef.current.hideForm(isCancel);
        }
        if((absenceCollaborateurRef && absenceCollaborateurRef.current) ){
            absenceCollaborateurRef.current.hideForm(isCancel);
        }
        if((cotisationCridonRef && cotisationCridonRef.current) ){
            cotisationCridonRef.current.hideForm(isCancel);
        }
        if((csnRef && csnRef.current) ){
            csnRef.current.hideForm(isCancel);
        }
        if((assuranceRCRef && assuranceRCRef.current) ){
            assuranceRCRef.current.hideForm(isCancel);
        }
        if((perteExploitationRef && perteExploitationRef.current) ){
            perteExploitationRef.current.hideForm(isCancel);
        }
        if((cyberRef && cyberRef.current) ){
            cyberRef.current.hideForm(isCancel);
        }
        if((chambreRef && chambreRef.current) ){
            chambreRef.current.hideForm(isCancel);
        }
        if((traitementRef && traitementRef.current) ){
            traitementRef.current.hideForm(isCancel);
        }
        if((totalRef && totalRef.current) ){
            totalRef.current.hideForm(isCancel);
        }
    };



    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks}
                                         text={`Cotisations -  ${currentCotisation ? currentCotisation.office.nom : ''}`}
                                         icon="icon-cotisations"
                                         modificators=""
                                         onSearch={null}
                    />

                    <YearsListComponent years={cotisationsYears} setCurrentYear={setCurrentYear} currentYear={currentYear} />


                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Récapitulatif" icon="icon-recapitulatif">

                            {
                                currentCotisation &&

                                <div className="fiche-content -right-gutter border-footer">
                                    <AccordionWithFormSwitchComponent
                                        ref={comptaAccordionRef}
                                        title="Compta"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapComptaFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapComptaComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionSimpleComponent title="Produits courants" contentShowedOnInit={true}>
                                        <CotisationsOfficeRecapProduitsCourantsComponent cotisation={currentCotisation} />
                                    </AccordionSimpleComponent>

                                    <AccordionWithFormSwitchComponent
                                        ref={garantieCollectiveRef}
                                        title="Garantie Collective"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapGarantieCollectiveFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapGarantieCollectiveComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={absenceCollaborateurRef}
                                        title="Absence collaborateur"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapAbsenceCollaborateurFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapAbsenceCollaborateurComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={cotisationCridonRef}
                                        title="Cotisation Cridon"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapCridonFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapCridonComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={csnRef}
                                        title="Conseil supérieur du Notariat"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapConseilSuperieurNotariatFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapConseilSuperieurNotariatComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={assuranceRCRef}
                                        title="Assurance RC"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapAssuranceRCFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapAssuranceRCComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={perteExploitationRef}
                                        title="Perte d'exploitation"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapPerteExploitationFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapPertesExploitationComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={cyberRef}
                                        title={`Convention d'assurance "Cyber"`}
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapCyberFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapConventionAssuranceCyberComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionSimpleComponent title="Assurance Maladie" contentShowedOnInit={true}>
                                        <CotisationsOfficeRecapAssuranceMaladieComponent cotisation={currentCotisation} />
                                    </AccordionSimpleComponent>

                                    <AccordionSimpleComponent title="Décès Invalidité" contentShowedOnInit={true}>
                                        <CotisationsOfficeRecapDecesInvaliditeComponent cotisation={currentCotisation} />
                                    </AccordionSimpleComponent>

                                    <AccordionSimpleComponent title="Adhésion CNAF" contentShowedOnInit={true}>
                                        <CotisationsOfficeRecapAdhesionCNAFComponent cotisation={currentCotisation} />
                                    </AccordionSimpleComponent>

                                    <AccordionWithFormSwitchComponent
                                        ref={chambreRef}
                                        title="Cotisation Chambre"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapChambreFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapChambreComponent cotisation={currentCotisation} tauxChambre={tauxChambre} />}
                                    />

                                    <AccordionWithFormSwitchComponent
                                        ref={traitementRef}
                                        title="Frais Traitement"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CotisationsOfficeRecapFraisTraitementFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapFraisTraitementComponent cotisation={currentCotisation} />}
                                    />

                                    <AccordionSimpleComponent title="Répertoires" contentShowedOnInit={true}>
                                        <CotisationsOfficeRecapRepertoiresComponent cotisation={currentCotisation} />
                                    </AccordionSimpleComponent>

                                    <AccordionWithFormSwitchComponent
                                        ref={totalRef}
                                        title="Total Cotisations"
                                        contentShowedOnInit={true}
                                        contentType="cotisation"
                                        contentId={currentCotisation.id}
                                        form={<CotisationsOfficeRecapTotauxFormComponent cotisation={currentCotisation} submitMethod={(formData: ICotisationsSaisieOfficeFormData)=>cotisationsService.updateCotisationsOffice(currentCotisation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                        infos={<CotisationsOfficeRecapTotalComponent cotisation={currentCotisation} />}
                                    />
                                </div>
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Assurance Maladie" icon="icon-assurance-maladie">
                            {
                                currentCotisation &&
                                <CotisationSaisieAssuranceMaladieNotaireTableComponent
                                    cotisationsNotaire={cotisationsNotairesAssuranceMaladie}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    currentYear={currentYear}
                                />
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Décès invalidité" icon="icon-assurance-maladie">
                            {
                                currentCotisation &&
                                <CotisationSaisieDecesInvaliditeNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesDecesInvalidite}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                />
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Adhésion CNAF" icon="icon-cnaf">
                            {
                                currentCotisation &&
                                <CotisationSaisieCNAFNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesAdhesionCNAF}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                />
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Pénalités de retard" icon="icon-frais-traitement">
                            {
                                currentCotisation &&
                                <CotisationSaisieFraisTraitementNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesFraisTraitement}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                />
                            }
                        </TabPanelComponent>



                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                {
                                    currentCotisation &&
                                    <div className="g-content">
                                        <CotisationsOfficeResumeComponent office={currentCotisation.office}/>
                                    </div>
                                }
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Courriers" icon="icon-courrier">
                            <div className="fiche-content -right-gutter border-footer">
                                {
                                    currentCotisation &&
                                    <div className="g-content">
                                        <CotisationsOfficeCourrierForm officeId={currentCotisation.office.id}/>
                                    </div>
                                }
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>


                </LayoutComponent>
            }
        </>
    )
}
