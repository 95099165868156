import React from "react";
import {IApiErrorMessage} from "src/Services/Api.service";

type FieldErrorsMessagesProps = {
    fieldsErrorsMessages: IApiErrorMessage,
    showAll?: boolean
}

export default function ErrorFieldsMessagesComponent(props: FieldErrorsMessagesProps){
    return (
        <>
            {
                props.fieldsErrorsMessages &&
                <div className="form-field-errors">

                    {
                        props.showAll && props.fieldsErrorsMessages.map((errorMessage: string, index: number) =>
                            <p className="message" key={index}>{errorMessage}</p>
                        )
                    }
                    {
                        !props.showAll &&
                        <p className="message">{props.fieldsErrorsMessages[0]}</p>
                    }
                </div>
            }
        </>
    )
}
