import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import ReclamationsExportComponent from "src/Components/Reclamations/ReclamationsExport.component";
import {
    IReclamationsGetApiResponse,
    IReclamationsGetFormData,
    ReclamationsService
} from "src/Services/Reclamations.service";
import {IReclamation, IReclamationsStats} from "src/Models/Reclamation.model";
import ReclamationsListCommunComponent from "src/Components/Reclamations/ReclamationsListCommun.component";
import ReclamationsListArchivageComponent from "src/Components/Reclamations/ReclamationsListArchivage.component";
import ReclamationsListPenalitesComponent from "src/Components/Reclamations/ReclamationsListPenalites.component";
import ReclamationsListStatsComponent from "src/Components/Reclamations/ReclamationListStats.component";
import RadioButtonsField from "src/Components/Fields/RadioButtons.field.component";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import ReclamationsAdvancedSearchComponent from "src/Components/Reclamations/ReclamationsAdvancedSearch.component";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import AsideReclamationsComponent from "../../Components/Aside/AsideReclamations.component";
import LoadingComponent from "../../Components/Loading/Loading.component";
import moment, {Moment} from "moment/moment";
import SelectFieldComponent from "../../Components/Fields/Select.field.component";


type IReclamationsListScreenProps = {}


const ReclamationsListScreen = (props: IReclamationsListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const location = useLocation();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathReclamations);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [reclamationsEnCours, setReclamationsEnCours] = useState<IReclamation[]>([]);
    const [reclamationsAutres, setReclamationsAutres] = useState<IReclamation[]>([]);
    const [reclamationsArchivage, setReclamationsArchivage] = useState<IReclamation[]>([]);
    const [reclamationsPenalites, setReclamationsPenalites] = useState<IReclamation[]>([]);
    const [reclamationsStats, setReclamationsStats] = useState<IReclamationsStats>(null);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.EN_COURS);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);

    const currentDate: Moment = moment();
    const currentYear: string = currentDate.format("YYYY");
    const previousYear: string = currentDate.subtract(1, "years").format("YYYY");

    const filtresParAnnees = [
        {value: currentYear, label: currentYear},
        {value: previousYear, label: previousYear},
        {value: 'before', label: 'Avant'},
    ];

    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Réclamations"},
        ];

        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), ['origine', 'etat', 'id_notaires', 'objet', 'id_syndic']);
        updateFilters(defaultFilters, false, true);
    }, []);


    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters, ...formData}, true);
        setShowAdvancedSearch(false);
    };


    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const reclamationsServices: ReclamationsService = new ReclamationsService();

        reclamationsServices.getReclamationsParType(activeTab, filters as IReclamationsGetFormData).then((response: IReclamationsGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newReclamationsList = response.datas.pagination.data;

                if(activeTab === TabsSlugs.EN_COURS) {
                    setReclamationsEnCours(newReclamationsList);
                } else if(activeTab === TabsSlugs.AUTRES) {
                    setReclamationsAutres(newReclamationsList);
                } else if(activeTab === TabsSlugs.ARCHIVAGE) {
                    setReclamationsArchivage(newReclamationsList);
                } else if(activeTab === TabsSlugs.PENALITES) {
                    setReclamationsPenalites(newReclamationsList);
                }

                setPaginationLinks(response.datas.pagination.links);

                if (response.datas.stats) {
                    setReclamationsStats(response.datas.stats);
                }


                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };


    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Réclamations"
                    icon="icon-reclamations"
                    onSearch={(keyword => updateFilters({...defaultFilters, keyword}, true))}
                    onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent/>
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent defaultTab={TabsSlugs.EN_COURS} onClickTabCallback={(tab) => {
                            updateFilters({}, false);
                            setActiveTab(tab)
                        }}>
                            <TabPanelComponent label="En cours" icon="icon-sablier" slug={TabsSlugs.EN_COURS}>
                                <div className={`list-content -right-gutter`}>
                                    <ReclamationsListStatsComponent stats={reclamationsStats}/>


                                    <ReclamationsAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={onAdvancedSearch}
                                    />


                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`}/>
                                        <RadioButtonsField fieldName="prochaineEtape"
                                                           label="Filtrer par prochaine étape"
                                                           options={ReclamationsConstants.prochaineEtapeOptions}
                                                           modificators="-full -no-margin-b"
                                                           oldValue={filters.prochaineEtape ? filters.prochaineEtape.toString() : ""}
                                                           onChange={(value: string) => updateFilters({prochaineEtape: value})}
                                        />

                                        <SelectFieldComponent fieldName="annee"
                                                              options={filtresParAnnees}
                                                              errors={null}
                                                              hideSearch={true}
                                                              modificators="-on-white"
                                                              label="Filtrer par année"
                                                              oldValue={filters.annee ? filters.annee.toString() : ""}
                                                              onChange={(value: string) => updateFilters({
                                                                  annee: value,
                                                                  advanced: 1
                                                              })}
                                        />
                                    </div>

                                    <ReclamationsListCommunComponent reclamationsList={reclamationsEnCours}
                                                                      listType={TabsSlugs.EN_COURS}
                                                                      filters={filters} setOrder={setOrder}/>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>

                                    <AsideReclamationsComponent type={"liste-en-cours"}
                                                                exportCallback={() => setShowExport(true)}
                                                                filters={filters}/>
                                </div>
                            </TabPanelComponent>

                            <TabPanelComponent label="Autres" icon="icon-liste-attente" slug={TabsSlugs.AUTRES}>
                                <div className={`list-content -right-gutter`}>
                                    <ReclamationsListStatsComponent stats={reclamationsStats}/>


                                    <ReclamationsAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={onAdvancedSearch}
                                    />


                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`}/>
                                        <SelectFieldComponent fieldName="annee"
                                                              options={filtresParAnnees}
                                                              errors={null}
                                                              hideSearch={true}
                                                              modificators="-on-white"
                                                              label="Filtrer par année"
                                                              oldValue={filters.annee ? filters.annee.toString() : ""}
                                                              onChange={(value: string) => updateFilters({
                                                                  annee: value,
                                                                  advanced: 1
                                                              })}
                                        />
                                    </div>

                                    {/*Utilisation de la même liste / composant que en cours pour autres */}
                                    <ReclamationsListCommunComponent reclamationsList={reclamationsAutres}
                                                                      listType={TabsSlugs.AUTRES}
                                                                      filters={filters} setOrder={setOrder}/>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>

                                    <AsideReclamationsComponent type={"liste-autres"}
                                                                exportCallback={() => setShowExport(true)}
                                                                filters={filters}/>
                                </div>
                            </TabPanelComponent>


                            <TabPanelComponent label="Archivage" icon="icon-archives" slug={TabsSlugs.ARCHIVAGE}>
                                <div className={`list-content -right-gutter`}>

                                    <ReclamationsAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={onAdvancedSearch}
                                    />

                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`}/>
                                        <SelectFieldComponent fieldName="annee"
                                                              options={filtresParAnnees}
                                                              errors={null}
                                                              hideSearch={true}
                                                              modificators="-on-white"
                                                              label="Filtrer par année"
                                                              oldValue={filters.annee ? filters.annee.toString() : ""}
                                                              onChange={(value: string) => updateFilters({
                                                                  annee: value,
                                                                  advanced: 1
                                                              })}
                                        />
                                    </div>


                                    {/*<ReclamationsListArchivageComponent reclamationsList={reclamationsArchivage}
                                                                        filters={filters} setOrder={setOrder}/>*/}

                                    <ReclamationsListCommunComponent reclamationsList={reclamationsArchivage}
                                                                     listType={TabsSlugs.ARCHIVAGE}
                                                                     filters={filters} setOrder={setOrder}/>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>

                                    <AsideReclamationsComponent type={"liste-archivage"}
                                                                exportCallback={() => setShowExport(true)}
                                                                filters={filters}/>
                                </div>
                            </TabPanelComponent>


                            {/*<TabPanelComponent label="Récapitulatif pénalité" icon="icon-penalites" slug={TabsSlugs.PENALITES}>
                                <div className={`list-content -right-gutter`}>
                                    <ReclamationsListStatsComponent stats={reclamationsStats} />



                                    <ReclamationsAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={onAdvancedSearch}
                                    />

                                    <ReclamationsListPenalitesComponent reclamationsList={reclamationsPenalites} filters={filters} setOrder={setOrder} />

                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
                                    <AsideReclamationsComponent type={"liste-penalites"} exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>*/}


                        </TabsContainerComponent>
                    </>
                }
            </div>


            <ReclamationsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport}
                                         listType={activeTab} filters={filters}/>

        </LayoutComponent>
    )
};

export default ReclamationsListScreen;
