import {IOffice} from "src/Models/Office.model";
import {AppConstants} from "../../../Constants/AppConstants";
import {Link} from "react-router-dom";
import React from "react";

type IOfficeAnnexesComponentProps = {
    annexes: IOffice[],
}

export default function OfficeAnnexesComponent(props: IOfficeAnnexesComponentProps) {
    const annexes: IOffice[] = props.annexes;

    return (
        <div className="informations-wrapper">
            {
                annexes && annexes.map((annexe: IOffice, index: number) =>
                    <div className="columns" key={index} >
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Nom de l'office rattaché</p>
                                <Link to={`${AppConstants.pathAnnexes}/${annexe.id}`}>
                                    {annexe.nom}
                                </Link>
                            </div>

                        </div>
                    </div>
                )
            }
            {
                (!annexes || (annexes.length === 0)) &&
                    <>-</>
            }
        </div>
    );
}
