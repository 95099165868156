import React, {useEffect, useState} from "react";
import {AutresContactsService, IPersonneExterieureGetApiResponse, IPersonneExterieureIdentiteFormData} from "src/Services/AutresContacts.service";
import usePersonneExterieure from "src/Hooks/UsePersonneExterieure";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import PersonneExterieureIdentiteFormComponent from "src/Components/Forms/Annuaire/Autres-contacts/Personnes-exterieures/PersonneExterieureIdentiteForm.component";


type IPersonneExterieureCreationStepsScreenProps = {
    pid?: string,
    type: string;
}
export default function PersonneExterieureCreationStepsScreen(props: IPersonneExterieureCreationStepsScreenProps){
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const [getPersonnesExterieuresById] = usePersonneExterieure();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [personne, setPersonne] = useState<IPersonneExterieure>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.pid){
            getPersonnesExterieuresById(props.pid, (personne: IPersonneExterieure) => {
                setPersonne(personne);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [])


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IPersonneExterieureGetApiResponse): void => {
        if(response.datas && response.datas.personneExterieure){
            const personne: IPersonneExterieure = response.datas.personneExterieure;
            history.push(AppConstants.pathPersonnesExterieures + '/' + personne.id);
        }
        else{
            history.push(AppConstants.pathPersonnesExterieures);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitNext = (response: IPersonneExterieureGetApiResponse): void => {
        if(response.datas && response.datas.personneExterieure){
            const personne: IPersonneExterieure = response.datas.personneExterieure;
            redirectToNextCreationStep(AppConstants.pathPersonnesExterieures, personne.id, personne.creationSteps);
        }
        else{
            history.push(AppConstants.pathPersonnesExterieures);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathPersonnesExterieures);
    };

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une personne extérieure" icon="icon-personne-exterieure" modificators={`-in-fullscreen -border-full-screen`}/>
            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-personne-exterieure"} title={"Identité"} modificators="-margin-bottom" />
                            <PersonneExterieureIdentiteFormComponent isOnCreationStep={true} submitMethod={(formData: IPersonneExterieureIdentiteFormData)=>autresContactsService.createPersonne(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}
