import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {ISignatureGetApiResponse, SignaturesService} from "../Services/Signatures.service";
import {ISignature} from "../Models/Signature.model";



export default function UseSignatures(){
    const signaturesService: SignaturesService = new SignaturesService();
    const history = useHistory();

    /**
     * Récupération d'une signature à partir de son id
     *
     * @param {string} sid
     * @param {(signature: ISignature) => void} callback
     * @param {() => void} callbackError
     */
    const getSignatureById = (sid: string, callback:(signature: ISignature) => void, callbackError?: () => void ) => {
        signaturesService.getSignature(sid).then((response: ISignatureGetApiResponse) => {
            if( response && response.datas.signature){
                if(callback){
                    callback(response.datas.signature);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathSignatures);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la signature",type: "danger"});
        });
    };

    return [getSignatureById];
};

