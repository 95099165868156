import ApiService from "../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import UseFileDownload from "./UseFileDownload";

const useListExportPDF = () =>{
    const {handleFileDownload} = UseFileDownload();


    /**
     * Export des listes en PDF
     *
     * @param filters
     * @param {string} url
     * @param {() => void} callback
     */
    const exportListPDF = (filters: any, url: string, callback: () => void) => {
        const apiService = new ApiService();

        apiService.exportPdf(url, filters).then((response)=> {
            if (response && response.datas.url) {
                handleFileDownload(response.datas.url,true);
            }
            else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }

            callback();
        }, (error) => {
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});

            callback()
        });
    };

    return {exportListPDF};
};

export default useListExportPDF;
