import React from "react";
import FormationCreationStepComponent from "src/Components/Formations/Formations/FormationCreationStep.component";

const FormationCreationFormationScreen = (props:{
    fid: number
}) => {
    return (
        <FormationCreationStepComponent fid={props.fid} type="formation" />
    )
};

export default FormationCreationFormationScreen;
