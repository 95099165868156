import {useHistory} from "react-router";
import {GroupesService, IGroupeGetApiResponse, IGroupeInformationsFormData} from "src/Services/Groupes.service";
import {AppConstants} from "src/Constants/AppConstants";
import {IGroupe} from "src/Models/Groupe.model";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import React from "react";
import GroupeInformationsFormComponent from "src/Components/Forms/Groupes/GroupeInformationsForm.component";


type IGroupeCreationScreenProps = {
}
export default function GroupeCreationScreen(props: IGroupeCreationScreenProps){
    const history = useHistory();
    const groupesService: GroupesService = new GroupesService();


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathGroupes);
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IGroupeGetApiResponse): void => {
        if(response.datas && response.datas.groupe){
            const groupe: IGroupe = response.datas.groupe;
            history.push(AppConstants.pathGroupes + '/' + groupe.id);
        }
        else{
            history.push(AppConstants.pathGroupes);
        }
    };




    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un groupe" icon="icon-groupes" modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />
            <GroupeInformationsFormComponent submitMethod={(formData: IGroupeInformationsFormData)=>groupesService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />
        </LayoutComponent>
    )
}
