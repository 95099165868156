import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AutresContactsService, IPersonneExterieureGetApiResponse} from "../Services/AutresContacts.service";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";



export default function usePersonneExterieure(){
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const history = useHistory();

    /**
     * Récupération d'une personne extérieure à partir de son id
     *
     * @param {string} pid
     * @param {(personne: IPersonneExterieure) => void} callback
     * @param {() => void} callbackError
     */
    const getPersonnesExterieuresById = (pid: string, callback:(personne: IPersonneExterieure) => void, callbackError?: () => void ) => {
        autresContactsService.getPersonneExterieure(pid).then((response: IPersonneExterieureGetApiResponse) => {
            if( response && response.datas.personneExterieure){
                if(callback){
                    callback(response.datas.personneExterieure);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOrganismes);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la personne extérieure",type: "danger"});
        });
    };

    return [getPersonnesExterieuresById];
};

