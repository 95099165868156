import {ISinistre} from "../../Models/Sinistre.model";
import DateHelper from "../../Helpers/Date.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import FileHelper from "../../Helpers/File.helper";
import React, {useEffect, useState} from "react";
import {IDocument} from "../../Models/Document.model";
import SinistresHelper from "../../Helpers/Sinistres.helper";

type ISinistreDocumentsComponentProps = {
    sinistre: ISinistre,
}

export default function SinistreDocumentsComponent(props: ISinistreDocumentsComponentProps) {
    const [documentsFiltered, setDocumentsFiltered] = useState<IDocument[]>([]);

    useEffect(() => {
        if (props.sinistre && props.sinistre.documents) {
            const newDocumentsList: IDocument[] = props.sinistre.documents;
            setDocumentsFiltered(newDocumentsList);
        }
    }, [props.sinistre])


    return (
        <>
            <div className="informations-wrapper">
                {
                    documentsFiltered && (documentsFiltered.length > 0) && documentsFiltered.map((d, index) =>
                        <div className="columns" key={index}>
                            <p className="title">{`${DateHelper.getMonthFromDate(d.date)} ${DateHelper.getYearFromDate(d.date)}`}</p>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Date</p>
                                    <p className="value">{d.date ? DateHelper.getFormatedDate(d.date) : '-'}</p>
                                </div>

                                <div className="informations-block">
                                    <p className="label">Document</p>
                                    {
                                        !d.fichier &&
                                        <p className="value">-</p>
                                    }
                                    {
                                        d.fichier &&
                                        <DocumentLink icon="icon-documents"
                                                      link={d.fichier ? FileHelper.getFileUrl(d.fichier) : '-'}/>
                                    }
                                </div>
                            </div>


                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Type</p>
                                    <p className="value">{d.type ? SinistresHelper.getFormatedDocumentTypes(d.type) : '-'}</p>
                                </div>

                                {d.type === 'autre' &&
                                    <div className="informations-block">
                                        <p className="label">Commentaires</p>
                                        <p className="value">
                                            {d.commentaires ? d.commentaires : '-'}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}
