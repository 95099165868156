import {FilInformationsConstants} from "../Constants/FilInformationsConstants";
import {IRevuePresse} from "../Services/FilInformations.service";
import CommonHelper from "./Common.helper";

export default class FilInformationsHelper{

    static getEtatAbonne = (etat: string) => {
        return FilInformationsConstants.abonnesStates[etat] || etat;
    }

    static getRevuesPresseOptionsForSelect = (revues: IRevuePresse[]) => {
        return revues.map((r)=>{
            return {
                value: r.nid[0].value,
                label: r.title[0].value
            }
        })
    }

    /**
     * Permet de récupérer le picto formaté
     *
     * @param {string} picto
     * @returns {string}
     */
    static getFormatedPicto(picto: string): string {
        return CommonHelper.getFormatedElementFromOptions(picto, FilInformationsConstants.formationsPictosOptions);
    }



}
