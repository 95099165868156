import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IOffice} from "src/Models/Office.model";
import {OfficesService} from "src/Services/Offices.service";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import useListExportPDF from "../../Hooks/UseListExportPDF";

import StringHelper from "src/Helpers/String.helper";

type IAsideOfficeComponentProps = {
    modificators?: string,
    type: "list" | "fiche" | "ficheCarte" | "ficheVefa",
    isPrincipales?: boolean,
    office?: IOffice,

    filters?: any

    exportCallback?: () => void
}

export default function AsideOfficeComponent(props: IAsideOfficeComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [filters, setFilters] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        if(props.filters){
            setFilters(props.filters);
        }

        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});

            if(props.isPrincipales){
                actions.push({icon: "icon-office-add", click: () => history.push(AppConstants.pathOffices + "/creation")});
            }
            else{
                actions.push({icon: "icon-annexe-add", click: () => history.push(AppConstants.pathAnnexes + "/creation")});
            }
        }

        if( props.type === "fiche" || props.type === "ficheVefa"){
            let stringHelper = new StringHelper();
            let filename = "office-";

            if(!props.isPrincipales){
                filename = "annexe-";
            }
            filename += "fiche-" + stringHelper.slugify(props.office.nom);
            actions.push({name: "print-pdf", filename: filename , icon: "icon-print"});
        } else if( props.type !== "list") {
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        if(props.type.startsWith("fiche")) {
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        }


        setActions(actions);
    }, [props.filters]);


    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = props.isPrincipales ? `api${AppConstants.pathOffices}/export-pdf` : `api${AppConstants.pathAnnexes}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }

    /**
     * Suppression
     */
    const doDelete = (): void => {
        const officesService: OfficesService = new OfficesService();

        officesService.delete(props.office.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isPrincipales ? "L'office a bien été supprimé." : "L'annexe a bien été supprimée.",
                type: "success"
            });

            history.push(AppConstants.pathOffices);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: props.isPrincipales ? "Une erreur est survenue lors de la suppression de l'office." : "Une erreur est survenue lors de la suppression de l'annexe.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    return (
        <>
            {
                showConfirmation && props.office &&
                <ConfirmationComponent onConfirm={doDelete}
                                       onCancel={onCancelConfirmation}
                                       text={props.isPrincipales ? "Souhaitez-vous vraiment supprimer cet office ?" : "Souhaitez-vous vraiment supprimer cette annexe ?"} />
            }

            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
