import React, {useEffect, useRef, useState} from "react";

import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {AppConstants} from "src/Constants/AppConstants";
import {ILettreType} from "src/Models/LettreType.model";
import useLettreType from "src/Hooks/UseLettreType";
import {useHistory} from "react-router";
import {LettreTypesService} from "src/Services/LettreTypes.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import LettreTypeCourrierForm from "../../../Components/Forms/Correspondances/LettresTypes/CorrespondanceLettresTypesForm.component";

export type ILettreTypeFicheScreenProps = {
    lid: number,
}

export default function LettresTypesFicheScreen(props: ILettreTypeFicheScreenProps){
    const [loaded, setLoaded] = useState<boolean>(false);
    const [getLettreTypeById] = useLettreType();
    const [lettreType, setLettreType] = useState<ILettreType>(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();

    const lettreTypesService: LettreTypesService = new LettreTypesService();
    const informationsAccordionRef = useRef(null);

    useEffect(() => {
        if( !props.lid ){
            history.push(AppConstants.pathLettreTypes);
        }
        getLettreType();

    }, [props.lid])


    useEffect(() => {
        if(!lettreType) return;

        const breadcrumbLinks = [
            { text: "Correspondances", link: AppConstants.pathMosaique + '#correspondances'},
            {text: "Lettres types", link: AppConstants.pathLettreTypes},
            {text: lettreType.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [lettreType, loaded]);


    /**
     * Récupération de la lettreType selon l'id
     */
    const getLettreType = (): void => {
        getLettreTypeById(props.lid, (l: ILettreType) => {
            setLettreType(l);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationsAccordionRef && informationsAccordionRef.current) ){
            informationsAccordionRef.current.hideForm(isCancel);
        }
    };

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getLettreType();

        closeForms();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={lettreType.nom} icon="icon-lettreTypes" modificators="" onSearch={null}/>
                    <TabsContainerComponent>
                        <TabPanelComponent label="Fiche" icon="icon-lettre-type">
                                <LettreTypeCourrierForm lettreType={lettreType} />
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}

