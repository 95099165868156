import axios, {AxiosError, AxiosResponse, Method} from 'axios';
import {IUser} from "src/Models/User.model";
import {AppConstants} from "src/Constants/AppConstants";
import {FormComponentFormData} from "../Components/Forms/FormCreator.component";

export const apiClient = axios.create({
    baseURL: AppConstants.serverUrl,
    withCredentials: true,
    headers: { Authorization: `Bearer ${AppConstants.bearer}` }
});

export type IApiCustomResponse = {
    code: number,
    messages?: IApiErrorMessage,
    fieldsMessages?: IApiFieldsErrorMessages,
}

export type IApiCustomErrorResponse = {
    code: number,
    messages?: IApiErrorMessage,
    fieldsMessages?: IApiFieldsErrorMessages,
    datas?: IApiFieldsErrorMessages,
}

export type IExportApiResponse = IApiCustomResponse & {
    datas: {
        url: string
    }
}
export type IApiFieldsErrorMessages = {
    [field:string]: IApiErrorMessage
};
export type IApiErrorMessage = string[];

export type IApiPaginationLink = {
    active: boolean,
    label: string,
    url: string,
}
export type IApiPagination = {
    total: number,
    current_page: number,
    per_page: number,
    last_page: number,
    path: string,
    links: IApiPaginationLink[]
}
export type IFileDownloadApiResponse = IApiCustomResponse & {
    datas: {
        file: string
    }
}

export abstract class IExportApiService{
    public abstract export(url: string, fields: string[]): Promise<IApiCustomResponse>;
}

export default class ApiService extends IExportApiService{

    constructor(private authUser: IUser = null){
        super();
    }


    /**
     * Permet la récupération du cookie Sanctum
     *
     * @returns {Promise<IApiCustomResponse>}
     */
    public getSanctumCookie(): Promise<IApiCustomResponse> {
        return this.doApiCall('sanctum/csrf-cookie', "GET", null, true);
    }


    /**
     * Permet de faire l'appel à l'API
     *
     * @param {string} path
     * @param {Method} method
     * @param params
     * @param {boolean} isSanctumCookieRequest
     * @returns {Promise<T>}
     * @protected
     */
    protected doApiCall<T extends IApiCustomResponse>(path: string, method: Method, params?: {[key:string]: any}, isSanctumCookieRequest?:boolean):Promise<T>{

        const datas = new FormData();
        if(params){
            Object.entries(params).forEach(([key,value])=>{
                if(Array.isArray(value) && value.length){
                    value.forEach((row)=>{
                        if(row !== undefined) {
                            datas.append(`${key}[]`, row);
                        }
                    });
                }else{
                    if(key !== undefined && value !== undefined ) {
                        datas.append(key, value);
                    }
                }
            });
        }

        return new Promise((resolve, reject) => {
            const query = method === "POST" ? apiClient.post(path, datas) : apiClient.get(path, {params}) ;

            query.then( (response: AxiosResponse) => {
                //On vérifie si la réponse est formée comme on souhaite, sinon on rejette
                //Dans le cas d'une demande de cookieSanctum, nous n'avons pas la main sur le formatage de la réponse, la promesse doit donc être résolue tout le temps dans ce cas là
                if( (response && response.data) || isSanctumCookieRequest){
                    resolve(response.data as T);
                }
                else{
                    reject("Retour de l'API mal formaté");
                }
            }).catch((error: AxiosError) => {

                // Si le retour est que l'utilisateur n'est plus connecté, on redirige vers le login
                // Vu qu'il ya une vérification de l'état de connexion sur cette page également, on évite la redirection à ce moment
                // Pour ne pas avoir de boucle infinie
                if (error.response && [401,419].includes(error.response.status ) && window.location.pathname !== AppConstants.pathLogin) {
                    return window.location.href = AppConstants.pathLogin;
                }
                //On vérifie si on est dans le cas d'une erreur gérée par nous dans l'API
                if(error.response && error.response.data){
                    reject(error.response.data as T);
                }
                else{
                    reject(JSON.stringify(error));
                }
            });
        });
    }


    /**
     * Appel de l'API D'export
     *
     * @param {string} url
     * @param {string[] | FormComponentFormData} fields
     * @param {boolean} isFullObject
     * @param filters
     * @returns {Promise<IExportApiResponse>}
     */
    public export(url: string, fields: string[]|FormComponentFormData, isFullObject?:boolean, filters?: any): Promise<IExportApiResponse>{
        const params: any = isFullObject ? fields : {fields};

        if(filters){
            params.filters = JSON.stringify(filters);
        }

        return this.doApiCall(url, 'POST',params);
    }


    /**
     * Appel de l'API D'export de PDF
     *
     * @param {string} url
     * @param filters
     * @returns {Promise<IExportApiResponse>}
     */
    public exportPdf(url: string,filters?: any): Promise<IExportApiResponse>{
        return this.doApiCall(url, 'POST', filters);
    }

    /**
     * Appel d'une API Externe
     *
     * @param {string} url
     * @returns {Promise<T>}
     */
    public callExternalApi<T>(url:string):Promise<T>{
        return new Promise((resolve, reject) => {
            const query = axios.create().get(url);

            query.then( (response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {

                // Si le retour est que l'utilisateur n'est plus connecté, on redirige vers le login
                // Vu qu'il ya une vérification de l'état de connexion sur cette page également, on évite la redirection à ce moment
                // Pour ne pas avoir de boucle infinie
                if (error.response && [401,419].includes(error.response.status ) && window.location.pathname !== AppConstants.pathLogin) {
                    return window.location.href = AppConstants.pathLogin;
                }
                //On vérifie si on est dans le cas d'une erreur gérée par nous dans l'API
                if(error.response && error.response.data){
                    reject(error.response.data);
                }
                else{
                    reject(JSON.stringify(error));
                }
            });
        });
    }

}
