import React, {useEffect, useState} from "react";
import RadioColorFieldComponent, {IRadioFieldProps} from "./RadioColor.field.component";
import {IOption} from "./Select.field.component";
import {IApiErrorMessage} from "../../Services/Api.service";

type RadioButtonsFieldProps = {
    options: IOption[],
    fieldName: string,
    label: string,
    modificators?: string,
    oldValue: string,
    disabled?: boolean,
    required?: boolean,
    column?: boolean,

    errors?: IApiErrorMessage,
    onChange: (value: string) => void
};

const RadioButtonsField = (props: RadioButtonsFieldProps) => {
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const [value, setValue] = useState<string>("");

    useEffect(()=>{
        if(props.oldValue) setValue(props.oldValue);
    },[]);
    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);



    const onChange = (newValue: string) => {
        setValue(newValue);
        if(props.onChange) props.onChange(newValue);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    };




    return <div className={`form-field -on-white ${props.modificators ?? ''}`}>
        <label className={`form-field-label ${errors ? '-error' : ''}`}>{props.label} {props.required && '*'}</label>
        <div className={"form-field-radios-group" + (props.column ? ' column': '')}>
            {props.options.map((r, index) => <div className="radio" key={index}>
                <RadioColorFieldComponent modificators={`-radio ${r.modificators ?? ''}`} name={props.fieldName} value={r.value} label={r.label}
                                     id={`${props.fieldName}_${index}`} onChange={() => onChange(r.value)}
                                     circleColorModificators={r.circleColorModificators ?? null}
                                     isDisabled={props.disabled}
                                     required={props.required}
                                     isChecked={value == r.value}/>
            </div>)}
        </div>
    </div>
};

export default RadioButtonsField;
