import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {ICorrespondanceGetApiResponse, ICorrespondanceFormData} from "src/Services/Correspondances.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICorrespondance} from "src/Models/Correspondance.model";
import CommonHelper from "src/Helpers/Common.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type CorrespondancePrecisionsFormProps = {
    correspondance?: ICorrespondance,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ICorrespondanceFormData) => Promise<ICorrespondanceGetApiResponse>,
    onSuccessSubmit: (response: ICorrespondanceGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ICorrespondanceGetApiResponse) => void,
    onCancel: () => void,
}

export default function CorrespondancePrecisionsForm(props: CorrespondancePrecisionsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const {correspondance} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'radio',
                fieldName: "inclureCourrier",
                label: "Inclure le courrier",
                required: false,
                modificators: "",
                oldValue: correspondance && correspondance.inclureCourrier ? correspondance.inclureCourrier : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "reunion",
                label: "Réunion",
                required: false,
                modificators: "",
                oldValue: correspondance && correspondance.reunion ? correspondance.reunion : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "presence",
                label: "Présence",
                required: false,
                modificators: "",
                oldValue: correspondance && correspondance.presence ? correspondance.presence : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "collaborateur",
                label: "Collaborateur",
                required: false,
                modificators: "",
                oldValue: correspondance && correspondance.collaborateur ? correspondance.collaborateur : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "transport",
                label: "Transport",
                required: false,
                modificators: "",
                oldValue: correspondance && correspondance.transport ? correspondance.transport : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'text',
                fieldName: "transportDetail",
                label: "Détail du transport",
                placeholder: "Détail du transport",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.transportDetail ? correspondance.transportDetail : "",
                condition: {
                    fieldName: "transport",
                    value: "oui"
                }
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'radio',
                fieldName: "repas",
                label: "Repas",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.repas ? correspondance.repas : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'text',
                fieldName: "repasNom",
                label: "Nom",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.repasNom ? correspondance.repasNom : "",
                condition: {
                    fieldName: "repas",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "repasLieu",
                label: "Lieu",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.repasLieu ? correspondance.repasLieu : "",
                condition: {
                    fieldName: "repas",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "repasHeure",
                label: "Heure",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.repasHeure ? correspondance.repasHeure : "",
                condition: {
                    fieldName: "repas",
                    value: "oui"
                }
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'radio',
                fieldName: "cocktail",
                label: "Cocktail",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.cocktail ? correspondance.cocktail : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'text',
                fieldName: "cocktailNom",
                label: "Nom",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.cocktailNom ? correspondance.cocktailNom : "",
                condition: {
                    fieldName: "cocktail",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "cocktailLieu",
                label: "Lieu",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.cocktailLieu ? correspondance.cocktailLieu : "",
                condition: {
                    fieldName: "cocktail",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "cocktailHeure",
                label: "Heure",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.cocktailHeure ? correspondance.cocktailHeure : "",
                condition: {
                    fieldName: "cocktail",
                    value: "oui"
                }
            },
        ];
        const formElementsColumn4: FormElement[] = [
            {
                type: 'radio',
                fieldName: "hebergement",
                label: "Hébergement",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.hebergement ? correspondance.hebergement : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'text',
                fieldName: "hebergementNom",
                label: "Nom",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.hebergementNom ? correspondance.hebergementNom : "",
                condition: {
                    fieldName: "hebergement",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "hebergementLieu",
                label: "Lieu",
                placeholder: "",
                required: true,
                modificators: "",
                oldValue: correspondance && correspondance.hebergementLieu ? correspondance.hebergementLieu : "",
                condition: {
                    fieldName: "hebergement",
                    value: "oui"
                }
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
            {
                elements: formElementsColumn4
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: ICorrespondanceGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }



            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La correspondance a bien été mise à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
