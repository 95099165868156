import React from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import {FilInformationsConstants} from "../../Constants/FilInformationsConstants";
import DateHelper from "../../Helpers/Date.helper";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";


const FilInformationsReal = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.realDates && props.filInformations.realDates.map( (d, dIndex) =>
                    <div className="columns" key={dIndex}>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date</p>
                                <div className="value">{DateHelper.formatDate(d.date,"DD/MM/YYYY")}</div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Lien</p>
                                <div className="value">{d.lien || '-'}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )

};

export default FilInformationsReal;
