import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {IOrganisme} from "src/Models/Organisme.model";
import {AutresContactsService, IOrganismeInformationsFormData,} from "src/Services/AutresContacts.service";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import OrganismeIdentiteComponent from "src/Components/Annuaire/Autres-contacts/Organismes/OrganismeIdentite.component";
import OrganismeInformationsFormComponent from "src/Components/Forms/Annuaire/Autres-contacts/Organismes/OrganismeInformationsForm.component";
import useOrganismes from "src/Hooks/UseOrganismes";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import MapHelper from "src/Helpers/Map.helper";
import AdressesFormComponent from "src/Components/Forms/Commons/AdressesForm.component";
import {CommonsService, IAdressesFormData, ICoordonneesFormData} from "src/Services/Commons.service";
import AdressesInfosComponent from "src/Components/Annuaire/Commons/Adresses/AdressesInfos.component";
import CoordonneesFormComponent from "src/Components/Forms/Commons/CoordonneesForm.component";
import CoordonneesInfosComponent from "src/Components/Annuaire/Commons/Coordonnees/CoordonneesInfos.component";
import AsideOrganismeComponent from "src/Components/Aside/AsideOrganisme.component";
import OrganismePersonneListComponent from "src/Components/Annuaire/Autres-contacts/Organismes/OrganismePersonneList.component";


type OrganismeScreenProps = {
    oid: string
}
export default function OrganismeFicheScreen(props: OrganismeScreenProps){
    const identiteAccordionRef = useRef(null);
    const adressesAccordionRef = useRef(null);
    const coordonneesAccordionRef = useRef(null);
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [getOrganismeById] = useOrganismes();

    const [organisme, setOrganisme] = useState<IOrganisme>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const commonsService: CommonsService = new CommonsService();
    const history = useHistory();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!organisme) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Organismes", link: AppConstants.pathOrganismes},
            {text: organisme.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [organisme, loaded])



    useEffect(() => {
        if( !props.oid ){
            history.push(AppConstants.pathOrganismes);
        }

        getOrganisme();

    }, [props.oid])


    /**
     * Récupération de l'organisme selon l'oid
     */
    const getOrganisme = (): void => {
        getOrganismeById(props.oid, (organisme: IOrganisme) => {
            setOrganisme(organisme);

            if (organisme.adressePrincipale && organisme.adressePrincipale.latitude) {
                const adresse = organisme.adressePrincipale;

                let markerText: string = MapHelper.createMapMarkerTextOrganisme(`${AppConstants.pathOrganismes}/${organisme.id}`,organisme.nom, adresse, organisme.personnesExterieures);
                const marker: MapMarker = MapHelper.createMapMarker(markerText, adresse);
                markers.push(marker);
                setMarkers(markers);
            }

            if (!loaded) {
                setLoaded(true);
            }
        })
    };



    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getOrganisme();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm(isCancel);
        }
        if((adressesAccordionRef && adressesAccordionRef.current) ){
            adressesAccordionRef.current.hideForm(isCancel);
        }
        if((coordonneesAccordionRef && coordonneesAccordionRef.current) ){
            coordonneesAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={organisme.nom} icon="icon-organismes" onSearch={null} />


                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={identiteAccordionRef}
                                    title="Identité"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="organisme"
                                    contentId={organisme.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OrganismeInformationsFormComponent organisme={organisme} submitMethod={(formData: IOrganismeInformationsFormData)=>autresContactsService.updateOrganisme(props.oid,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<OrganismeIdentiteComponent organisme={organisme} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={adressesAccordionRef}
                                    title="Adresses"
                                    icon="icon-coordonnees"
                                    contentShowedOnInit={true}
                                    contentType="organisme"
                                    contentId={organisme.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<AdressesFormComponent adresses={organisme.adresses}
                                                                 relationId={organisme.id} relationName="organisme"
                                                                 submitMethod={(formData: IAdressesFormData) => commonsService.syncAdresses(formData)}
                                                                 onSuccessSubmit={onSuccessSubmitForm}
                                                                 onCancel={onCancelForm}/>}
                                    infos={<AdressesInfosComponent adresses={organisme.adresses || null}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={coordonneesAccordionRef}
                                    title="Coordonnées"
                                    icon="icon-coordonnees-adresses"
                                    contentShowedOnInit={true}
                                    contentType="organisme"
                                    contentId={organisme.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<CoordonneesFormComponent coordonnees={organisme.coordonnees}
                                                                    relationId={organisme.id} relationName="organisme"
                                                                    submitMethod={(formData: ICoordonneesFormData) => commonsService.syncCoordonnees(formData)}
                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                    onCancel={onCancelForm}/>}
                                    infos={<CoordonneesInfosComponent coordonnees={organisme.coordonnees || null}/>}
                                />


                                <AsideOrganismeComponent type="fiche" organisme={organisme} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Personnes Extérieures" icon="icon-personne-exterieure">
                            <div className="fiche-content border-footer">

                                <div className="list-content -right-gutter">
                                    <OrganismePersonneListComponent organisme={organisme} />
                                </div>

                                <AsideOrganismeComponent type="fichePersonnesExterieures" modificators="-on-map" organisme={organisme} />
                            </div>
                        </TabPanelComponent>



                        <TabPanelComponent label="Carte" icon="icon-map">
                            <div className="fiche-content border-footer">
                                <div className="map-wrapper">
                                    <MapComponent markers={markers} key={Math.random()}/>
                                </div>

                                <AsideOrganismeComponent type="ficheCarte" modificators="-on-map" organisme={organisme} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
