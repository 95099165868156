import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {IGroupe, IGroupeType} from "src/Models/Groupe.model";
import {GroupesService, IGroupeGetApiResponse, IGroupeInformationsFormData, IGroupeTypesGetApiResponse} from "src/Services/Groupes.service";
import {AppConstants} from "src/Constants/AppConstants";
import GroupesHelper from "src/Helpers/Groupes.helper";
import useCreationSteps from "../../../Hooks/useCreationSteps";
import CommonHelper from "src/Helpers/Common.helper";


type IGroupeInformationsFormProps = {
    groupe?: IGroupe,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IGroupeInformationsFormData) => Promise<IGroupeGetApiResponse>,
    onSuccessSubmit: (response: IGroupeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IGroupeGetApiResponse) => void,
    onCancel: () => void,
}

export default function GroupeInformationsFormComponent(props: IGroupeInformationsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [groupeTypes,setGroupeTypes] = useState<IGroupeType[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();



    useEffect(() => {
        const groupesServices: GroupesService = new GroupesService();

        groupesServices.getGroupesTypes().then((response: IGroupeTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                setGroupeTypes(response.datas.types);
            }
        });
    }, [])


    /**
     * Initialisation du form
     */
    useEffect(()=>{
        const groupe: IGroupe = props.groupe;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: !groupe ? "-on-white" : '',
                oldValue: groupe && groupe.nom ? groupe.nom : "",
                showFieldErrorDetail: true,
            },
            {
                type: 'wysiwyg',
                fieldName: "commentaires",

                label: "Commentaires",
                placeholder: "Commentaires",
                required: false,
                modificators:  props.isOnCreationStep ? "-on-white -wysiwyg" : "-wysiwyg",
                oldValue: groupe && groupe.commentaires ? groupe.commentaires : "",
            },
        ];


        let formElementsColumn2:FormElement[] = [];
        let formElementsColumn3:FormElement[] = [];

        if(groupe) {
            formElementsColumn2 = [
                {
                    type: "informations",
                    label: "Automatique",
                    oldValue: groupe && groupe.automatique ? CommonHelper.getFormatedOuiNon(groupe.automatique) : "-",
                },
            ];
            formElementsColumn3 = [
                {
                    type: "informations",
                    label: "Types automatique",
                    oldValue: groupe && groupe.types && groupe.types.length ? GroupesHelper.getFormated(groupe.types) : "-",
                },
            ];
        }

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1,
                modificators: "-size-2",
            },
            {
                elements: formElementsColumn2,
            },
            {
                elements: formElementsColumn3,
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    },[groupeTypes]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IGroupeInformationsFormData).then((response: IGroupeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le groupe a bien été modifié.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification du groupe.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
