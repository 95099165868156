import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {IParametre} from "../Models/Parametre.model";

export type IParametresGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}

export type IParametreGetPaginationApi = IApiPagination & {
    data: IParametre[],
}
export type IParametresGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IParametreGetPaginationApi
    }
}
export type IParametreGetApiResponse = IApiCustomResponse & {
    datas?: {
        parametre: IParametre
    }
}

export type IParametreInformationsFormData = {
    slug?: string,
    nom: string,
    valeur: number,
    module: string,
}


export class ParametresService extends ApiService {
    /**
     * Permet la suppression d'un parametre
     *
     * @param {number} pid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(pid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/parametres/' + pid + '/delete', 'POST');
    }


    /**
     * Permet de récupérer la liste de parametres pour un module
     *
     * @param {string} module
     * @param {IParametresGetFormData} formDatas
     * @returns {Promise<IParametresGetApiResponse>}
     */
    public getListForModule(module: string, formDatas: IParametresGetFormData): Promise<IParametresGetApiResponse>{
        const url: string = module ? ('api/parametres/modules/' + module) : 'api/parametres';

        return this.doApiCall(url, 'GET', formDatas);
    }


    /**
     * Permet de récupérer un parametre par son id
     *
     * @param {string} pid
     * @returns {Promise<IParametreGetApiResponse>}
     */
    public getParametre(pid: string): Promise<IParametreGetApiResponse> {
        return this.doApiCall('api/parametres/' + pid, 'GET');
    }


    /**
     * Mise à jour des informations d'un paramètre
     *
     * @param {string} pid
     * @param {IParametreInformationsFormData} formDatas
     * @returns {Promise<IParametreGetApiResponse>}
     */
    public updateInformations(pid: string, formDatas: IParametreInformationsFormData): Promise<IParametreGetApiResponse>{
        return this.doApiCall('api/parametres/' + pid + '/informations', 'POST', formDatas);
    }

    /**
     * Création d'un paramètre
     *
     * @param {IParametreInformationsFormData} formDatas
     * @returns {Promise<IParametreGetApiResponse>}
     */
    public create(formDatas: IParametreInformationsFormData): Promise<IParametreGetApiResponse>{
        return this.doApiCall('api/parametres/create', 'POST', formDatas);
    }


}




