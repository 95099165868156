import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {IOffice} from "src/Models/Office.model";

export type IOfficesGetPaginationApi = IApiPagination & {
    data: IOffice[],
}
export type IOfficesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IOfficesGetPaginationApi
    }
}
export type IOfficesGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    departement?: string[]
}
export type IOfficeGetApiResponse = IApiCustomResponse & {
    datas?: {
        office?: IOffice
    }
}
export type IOfficesGetAllApiResponse = IApiCustomResponse & {
    datas: {
        offices: IOffice[]
    }
}
export type IOfficeInformationFormData = {
    CRPCEN: string,
    nom: string,
    type: string,
    logo?: string,
    logo_old?: string,
    officeId?: string,
    actif?: string,
    officeCree?: string,
}
export type IAnnexeCreateFormData = {
    nom: string,
    officeId: string,
}
export type IOfficeSitesGroupesLogicielsFormData = {
    siteInternet: string,
    logicielRedactionActes?: string,
    logicielVisio?: string,
    logicielNegociation?: string,
    systemeCompta?: string,
    logicielAutres: string,
}

export type IOfficeNegociateurFormData = {
    nom: string,
    type: string,
    telephone: string,
    telephonePortable: string,
    email: string,
    labels?: string[],
    officeId: string,
    officeType: string,
}
export type IOfficeNegociateursFormData = {
    officeId?: string,
    negociateurs: IOfficeNegociateurFormData[]
}

export type IOfficeRepertoireFormData = {
    date: string,
    annee: string,
    numero: string,
}
export type IOfficeRepertoiresFormData = {
    officeId?: string,
    repertoires: IOfficeRepertoireFormData[]
}

export type IOfficeRecuLivraisonFormData = {
    date: string,
    annee: string,
    numero: string,
    type: string,
}
export type IOfficeRecusLivraisonFormData = {
    officeId?: string,
    recusLivraison: IOfficeRecuLivraisonFormData[]
}
export type IOfficeCommentairesFormData = {
    commentaire?: string,
}
export type IOfficeChiffresFormData = {
    nbSalaries: string,
    nbActes: string,
    nbSuccessions: string,
}
export type IOfficeInformationsComplementairesFormData = {
    actif: string,
    anneeCreation: string,
    sirenSiret: string,
    dateDelivrance: string,
    officeCree: string,
    anneeDebutActivite: string,
    TVA: string,
    numeroAgrement: string,
}
type IDocumentVefaFormData ={
    fichier?: string;
    date: string,
    dateDepot?: string,
    nom?: string,
    commentaires?: string
}
export type IOfficeDocumentsVefaFormData = {
    documents: IDocumentVefaFormData[],
    idsToDelete?: string[]
}
export type IOfficeCertificationsLabelsHorairesFormData = {
    certifications: string[],
    labels: string[],
    borneElectrique: string,
}
export type IOfficeAnnexesFormData = {
    annexes?: string[],
    idsToDelete?: string[]
}

export class OfficesService extends ApiService{
    /**
     * Permet la récupération d'une liste d'offices
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getOffices(formData: IOfficesGetFormData): Promise<IOfficesGetApiResponse>{
        return this.doApiCall('api/annuaire/offices', 'GET', formData);
    }

    /**
     * Permet la récupération d'une liste d'offices principale
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getOfficesPrincipalesWithPagination(formData: IOfficesGetFormData): Promise<IOfficesGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/principales', 'GET', formData);
    }

    /**
     * Permet la récupération d'une liste d'offices principale
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getAnnexesWithPagination(formData: IOfficesGetFormData): Promise<IOfficesGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/annexes', 'GET', formData);
    }


    /**
     * Permet la récupération d'une office à partir de son id
     *
     * @param {string} oid
     * @returns {Promise<IOfficeGetApiResponse>}
     */
    public getOffice(oid: string): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid, 'GET');
    }

    /**
     * Permet la récupération d'une office à partir de son id
     *
     * @param {string} oid
     * @returns {Promise<IOfficeGetApiResponse>}
     */
    public getOfficeExternal(oid: string): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/external/annuaire/offices/' + oid, 'GET');
    }


    /**
     * Permet la récupération d'un liste complète d'office
     *
     * @returns {Promise<IOfficesGetAllApiResponse>}
     */
    public getAll(): Promise<IOfficesGetAllApiResponse>{
        return this.doApiCall('api/annuaire/offices/all', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète d'office principal
     *
     * @returns {Promise<IOfficesGetAllApiResponse>}
     */
    public getAllOfficesPrincipales(): Promise<IOfficesGetAllApiResponse>{
        return this.doApiCall('api/annuaire/offices/all-principales', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète d'office principal by CRPCEN
     *
     * @returns {Promise<IOfficesGetAllApiResponse>}
     */
    public getAllOfficesPrincipalesByCRPCEN(): Promise<IOfficesGetAllApiResponse>{
        return this.doApiCall('api/annuaire/offices/all-principales-by-crpcen', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète d'office principal
     *
     * @returns {Promise<IOfficesGetAllApiResponse>}
     */
    public getAllOfficesPrincipalesExternal(): Promise<IOfficesGetAllApiResponse>{
        return this.doApiCall('api/external/annuaire/offices/all-principales', 'GET');
    }


    /**
     * Permet la récupération d'un liste complète d'office annexe
     *
     * @returns {Promise<IOfficesGetAllApiResponse>}
     */
    public getAllOfficesAnnexes(): Promise<IOfficesGetAllApiResponse>{
        return this.doApiCall('api/annuaire/offices/all-annexes', 'GET');
    }


    /**
     * Creation d'un office
     *
     * @param {IOfficeInformationFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public create(formDatas: IOfficeInformationFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/create', 'POST', formDatas);
    }


    /**
     * Creation d'une annexe
     *
     * @param {IOfficeInformationFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public createAnnexe(formDatas: IAnnexeCreateFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/annexes/create', 'POST', formDatas);
    }

    /**
     * Modification des informations d'un office
     *
     * @param {number} oid
     * @param {IOfficeInformationFormData} formDatas
     * @returns {Promise<IOfficeGetApiResponse>}
     */
    public updateInformations(oid: number, formDatas: IOfficeInformationFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/informations", 'POST', formDatas);
    }


    /**
     * Synchronisation des negociateurs
     *
     * @param {IOfficeNegociateursFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public syncNegociateurs(formDatas: IOfficeNegociateursFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/negociateurs/sync', 'POST', formDatas);
    }


    /**
     * Synchronisation des répertoire
     *
     * @param {IOfficeRepertoiresFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public syncRepertoires(formDatas: IOfficeRepertoiresFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/repertoires/sync', 'POST', formDatas);
    }


    /**
     * Synchronisation des reçus de livraison
     *
     * @param {IOfficeRecusLivraisonFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public syncRecusLivraisons(formDatas: IOfficeRecusLivraisonFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/recus-livraison/sync', 'POST', formDatas);
    }

    /**
     *
     * @param {number} oid
     * @param {INotaireIdentiteFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateCommentaire(oid: number, formDatas: IOfficeCommentairesFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/commentaires", 'POST', formDatas);
    }

    /**
     *  Mise à jour des sites, groupes et logiciels
     *
     * @param {number} oid
     * @param {INotaireIdentiteFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateSitesGroupesLogiciels(oid: number, formDatas: IOfficeSitesGroupesLogicielsFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/sites-groupes-logiciels", 'POST', formDatas);
    }

    /**
     * Mise à jour des chiffres
     *
     * @param {number} oid
     * @param {IOfficeChiffresFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateChiffres(oid: number, formDatas: IOfficeChiffresFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/chiffres", 'POST', formDatas);
    }


    /**
     * Mise à jour des informations complémentaires
     *
     * @param {number} oid
     * @param {IOfficeInformationsComplementairesFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateInformationsComplementairess(oid: number, formDatas: IOfficeInformationsComplementairesFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/informations-complementaires", 'POST', formDatas);
    }


    /**
     * Mise à jour des informations sur les labels et horaires
     *
     * @param {number} oid
     * @param {IOfficeInformationsComplementairesFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateCertificationsLabelsHoraires(oid: number, formDatas: IOfficeCertificationsLabelsHorairesFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/certifications-labels-horaires", 'POST', formDatas);
    }

    /**
     * Synchronisation des annexes
     *
     * @param {number} oid
     * @param {IOfficeAnnexesFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public syncAnnexes(oid: number, formDatas: IOfficeAnnexesFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/annexes/sync", 'POST', formDatas);
    }

    /**
     * Permet la suppression d'un office
     *
     * @param {string} oid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(oid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + '/delete', 'POST');
    }

    /**
     * Upload des documents vefa
     *
     * @param {number} oid
     * @param {IOfficeDocumentsVefaFormData} formDatas
     * @returns {Promise<IOfficeGetApiResponse>}
     */
    public updateDocumentsVefa( oid: number, formDatas: IOfficeDocumentsVefaFormData): Promise<IOfficeGetApiResponse>{
        return this.doApiCall('api/annuaire/offices/' + oid + "/documents/vefa", 'POST', formDatas);
    }
}

