import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ISuccession} from "src/Models/Succession.model";
import {ISuccessionDefuntFormData, ISuccessionGetApiResponse} from "src/Services/Successions.service";
import {SuccessionsConstants} from "src/Constants/SuccessionsConstants";
import DateHelper from "../../../Helpers/Date.helper";



type ISuccessionDefuntFormProps = {
    succession?: ISuccession,
    isOnCreationStep?: boolean

    submitMethod: (formData: ISuccessionDefuntFormData) => Promise<ISuccessionGetApiResponse>,
    onSuccessSubmit: (response: ISuccessionGetApiResponse) => void,
    onCancel: () => void,
}

export default function SuccessionDefuntFormComponent(props: ISuccessionDefuntFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);



    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const succession: ISuccession = props.succession;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "identiteNom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteNom ? succession.identiteNom : "",
            },
            {
                type: 'date',
                fieldName: "identiteNaissanceDate",

                label: "Date de naissance",
                placeholder: "Date de naissance",
                required: false,
                showFieldErrorDetail: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteNaissanceDate ? DateHelper.getFormatedDateForInput(succession.identiteNaissanceDate) : "",
            },
            {
                type: 'date',
                fieldName: "identiteDecesDate",

                label: "Date de décès",
                placeholder: "Date de décès",
                required: false,
                showFieldErrorDetail: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteDecesDate ? DateHelper.getFormatedDateForInput(succession.identiteDecesDate) : "",
            },
            {
                type: 'text',
                fieldName: "identiteAdresse",

                label: "Adresse",
                placeholder: "Adresse",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteAdresse ? succession.identiteAdresse : "",
            },
        ];

        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "identitePrenom",

                label: "Prénom",
                placeholder: "Prénom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identitePrenom ? succession.identitePrenom : "",
            },
            {
                type: 'text',
                fieldName: "identiteNaissanceLieu",

                label: "Lieu de naissance",
                placeholder: "Lieu de naissance",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteNaissanceLieu ? succession.identiteNaissanceLieu : "",
            },
            {
                type: 'text',
                fieldName: "identiteDecesLieu",

                label: "Lieu de décès",
                placeholder: "Lieu de décès",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteDecesLieu ? succession.identiteDecesLieu : "",
            },

            {
                type: 'codePostalVille',
                fieldName: "cpVille",
                label: "Code Postal / Ville",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: succession && succession.identiteAdresseCodePostal ? (succession.identiteAdresseCodePostal + ' - ' + succession.identiteAdresseVille ) : "",
                maxLength: 5,
            },
        ];

        const formElementsColumn3: FormElement[] = [
            {
                type: 'text',
                fieldName: "identiteNaissanceDepartement",

                label: "Département de naissance",
                placeholder: "Département de naissance",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : '-skip-line-1',
                oldValue: succession && succession.identiteNaissanceDepartement ? succession.identiteNaissanceDepartement : "",
            },
            {
                type: 'text',
                fieldName: "identiteDecesDepartement",

                label: "Département de décès",
                placeholder: "Département de décès",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.identiteDecesDepartement ? succession.identiteDecesDepartement : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [];
        currentFormActions = [
            {
                label: "Enregistrer",
                modificators: "-primary",
                onAction: (formData) => {onSubmit(formData, false)}
            },
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: onCancel
            }
        ];
        setFormActions(currentFormActions);

    },[props.succession]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISuccessionDefuntFormData).then((response: ISuccessionGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les informations de la succession ont bien été modifiées.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de l'état de la succession.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
