import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import ApiService, {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ISuccession} from "src/Models/Succession.model";
import {SuccessionsService} from "src/Services/Successions.service";
import useListExportPDF from "../../Hooks/UseListExportPDF";


type IAsideSuccessionComponentProps = {
    succession?: ISuccession,
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void,

    filters?: any
}

export default function AsideSuccessionComponent(props: IAsideSuccessionComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-successions-add", click: () => history.push(AppConstants.pathSuccessions + "/creation")});

        }

        if( props.type === "fiche"){
            actions.push({icon: "icon-print", click: () => printScreen()})
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        }

        setActions(actions);
    }, [props.type, props.filters]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const successionsService: SuccessionsService = new SuccessionsService();

        successionsService.delete(props.succession.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La succession a bien été supprimée.",
                type: "success"
            });

            history.push(AppConstants.pathSuccessions);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression de la succession.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }


    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathSuccessions}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            {
                showConfirmation && props.succession &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer cette succession ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
