import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ITableauBordGetApiResponse, ITableauBordUploadFormData} from "src/Services/TableauxBord.service";


type OfficeTableauBordExternalFormComponentProps = {
    submitMethod: (formData: ITableauBordUploadFormData) => Promise<ITableauBordGetApiResponse>,
    onSuccessSubmit: (response: ITableauBordGetApiResponse) => void,
}

export default function OfficeTableauBordExternalFormComponent(props: OfficeTableauBordExternalFormComponentProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const formElementsColumn1: FormElement[] = [
            {
                type: 'number',
                fieldName: "CRPCEN",

                label: "CRPCEN",
                placeholder: "CRPCEN",
                required: true,
                modificators: "-on-white",
                maxLength:5,

                oldValue: "",
            },
            {
                type: 'file',
                fieldName: "fichier",

                label: "Fichier",
                placeholder: "Fichier",
                required: true,
                modificators: "-on-white",
                extensions: ['.pdf'],

                oldValue: "",
            },
        ];
        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1,
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [];
        currentFormActions = [
            {
                label: "Envoyer",
                modificators: "-secondary",
                onAction: (formData) => {onSubmit(formData)}
            },
        ];
        setFormActions(currentFormActions);

    },[]);



    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ITableauBordUploadFormData).then((response: ITableauBordGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }
        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'envoi de votre tableau de bord'.",
                    type: "danger"
                });
            }
        });
    };


    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions="-outside-right"
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
