import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistreInformationsFormData, ISinistreGetApiResponse} from "src/Services/Sinistres.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import DateHelper from "src/Helpers/Date.helper";
import {INotaireGetAllApiResponse, NotairesService} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {CtrcsService, ICtrcGetAllApiResponse} from "../../../Services/Ctrcs.service";
import {ICtrc} from "../../../Models/Ctrc.model";
import CtrcsHelper from "../../../Helpers/Ctrcs.helper";
import CommonHelper from "../../../Helpers/Common.helper";
import {SinistresConstants} from "../../../Constants/SinistresConstants";


type ISinistreInformationsFormComponentProps = {
    sinistre?: ISinistre,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ISinistreInformationsFormData) => Promise<ISinistreGetApiResponse>,
    onSuccessSubmit: (response: ISinistreGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ISinistreGetApiResponse) => void,
    onCancel: () => void,
}

export default function SinistreInformationsFormComponent(props: ISinistreInformationsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();
    const [notaires, setNotaires] = useState<INotaire[]>(null);
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [ctrcs, setCtrcs] = useState<ICtrc[]>([]);
    const [ctrcsLoaded, setCTRCsLoaded] = useState<boolean>(false);
    const [notairesCTRC, setNotairesCTRC] = useState<INotaire[]>([]);

    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response: INotaireGetAllApiResponse) => {
            if( response && response.datas && response.datas.notaires){
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

        const ctrcsService: CtrcsService = new CtrcsService();
        ctrcsService.getAll().then((response: ICtrcGetAllApiResponse) => {
            if (response && response.datas && response.datas.ctrcs) {
                setCtrcs(response.datas.ctrcs);
                setCTRCsLoaded(true);
            }
        });

        notairesService.getCTRC().then((response: INotaireGetAllApiResponse) => {
            if( response && response.datas && response.datas.notaires){
                setNotairesCTRC(response.datas.notaires);
            }
        });

    }, []);


    /**
     * Initialisation du formulaire à partir des infos du sinistre
     */
    useEffect(() => {
        if(!notairesCTRC) return;

        const sinistre: ISinistre = props.sinistre;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'selectMultiple',
                fieldName: "notairesAssignes",

                label: "Notaire(s) mis en cause",
                options: notaires && notaires.length ? NotairesHelper.formatListForOptions(notaires): [],
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValues: sinistre && sinistre.notairesAssignes ? NotairesHelper.getIdsFromList(sinistre.notairesAssignes) : [],
            },
            ];

        if(!props.isOnCreationStep){
            formElementsColumn1.push(
                {
                    type: 'select',
                    fieldName: "rapporteurId",

                    label: "Rapporteur",
                    placeholder: "Rapporteur",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                    oldValue: sinistre && sinistre.rapporteur ? sinistre.rapporteur.id.toString() : "",
                    options: notairesCTRC ? NotairesHelper.formatListForOptions(notairesCTRC) : []
                },
            );
        }

        formElementsColumn1.push(
            {
                type: 'select',
                fieldName: "anneeOuverture",

                label: "Année d'ouverture",
                placeholder: "Année d'ouverture",
                required: true,
                hideSearchOnMultiple: true,
                hideEmptyOption: true,
                modificators: props.isOnCreationStep ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                oldValue: sinistre && sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : "",
                options: CommonHelper.getYearsOptions(SinistresConstants.minYear),
            },
        );

        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "libelle",

                label: "Libellé du dossier",
                placeholder: "Libellé du dossier",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: sinistre && sinistre.libelle ? sinistre.libelle : "",
            },
        ];

        if(!props.isOnCreationStep){
            formElementsColumn2.push(
                {
                    type: 'select',
                    fieldName: "source",

                    label: "Source",
                    placeholder: "Source",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                    oldValue: sinistre && sinistre.source ? sinistre.source : "",

                    options: SinistresConstants.sourceOptions,
                    hideSearchOnMultiple: true,
                },
                {
                    type: 'text',
                    fieldName: "numeroDossier",

                    label: "Numéro de dossier",
                    placeholder: "Numéro de dossier",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white" : '',
                    oldValue: sinistre && sinistre.numeroDossier ? sinistre.numeroDossier : "",
                },

            );
        }

        const formElementsColumn3: FormElement[] = [];

        if(!props.isOnCreationStep){
            formElementsColumn3.push(
                {
                    type: 'selectMultiple',
                    fieldName: "ctrcs",

                    label: "CTRC (multiple)",
                    options: ctrcs ? CtrcsHelper.getFormatedListForOptions(ctrcs) : [],
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white" : "",

                    oldValues: sinistre && sinistre.ctrcs ? CtrcsHelper.getIdsFromList(sinistre.ctrcs) : [],
                },
                /*
                {
                    type: 'select',
                    fieldName: "ctrcId",

                    label: "CTRC",
                    placeholder: "CTRC",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                    oldValue: sinistre && sinistre.ctrc ? sinistre.ctrc.id.toString() : "",
                    options: ctrcs ? CtrcsHelper.getFormatedListForOptions(ctrcs) : []
                },
                 */
            );
        }

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
            {
                modificators:"-full",
                elements: formElementsColumn2
            },
            {
                modificators:"-full",
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true);
        setFormActions(currentFormActions);


    }, [notaires, ctrcs, notairesCTRC]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISinistreInformationsFormData).then((response: ISinistreGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "Le sinistre a bien été créé.": "Le sinistre a bien été modifié.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive du sinistre.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                (ctrcsLoaded) &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }

        </div>
    )

}
