import React, {useEffect} from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutside = (ref:React.MutableRefObject<HTMLDivElement>, onClickOutside: ()=>void) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(t: HTMLElement) {
            if (ref.current && !ref.current.contains(t)) {
                onClickOutside();
            }
        }

        // Bind the event listener
        document.addEventListener("mouseup", (e)=> handleClickOutside(e.target as HTMLElement),false);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", (e)=> handleClickOutside(e.target as HTMLElement),false);
        };
    }, [ref]);
};

export default useClickOutside;