import {ISuccession} from "src/Models/Succession.model";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import React from "react";

type ISuccessionReponseComponentProps = {
    succession: ISuccession,
}

export default function SuccessionReponseComponent(props: ISuccessionReponseComponentProps) {
    const succession: ISuccession = props.succession;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Office</p>
                        <p className="value">{succession.office ? succession.office.CRPCEN + " - " + succession.office.nom : "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Notaire</p>
                        <p className="value">{succession.notaire ? (succession.notaire.nom + ' ' + succession.notaire.prenom) : "-"}</p>
                    </div>
                </div>
            </div>


            <div className="columns">
                <div className="column -size-2">
                    <div className="informations-block">
                        <p className="label">Commentaires</p>
                        <CommentairesComponent commentaires={succession.commentaires} />
                    </div>
                </div>
            </div>
        </div>
    );
}
