import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {
    SinistresService,
    ISinistreGetApiResponse,
    ISinistreInformationsFormData,
} from "src/Services/Sinistres.service";
import {ISinistre} from "src/Models/Sinistre.model";
import SinistreInformationsFormComponent from "../../Components/Forms/Sinistres/SinistreInformationsForm.component";
import InformationHeaderComponent from "../../Components/Informations/InformationHeader.component";

type ISinistreCreationScreenProps = {}
export default function SinistreCreationScreen(props: ISinistreCreationScreenProps) {
    const history = useHistory();
    const sinistresService: SinistresService = new SinistresService();

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: ISinistreGetApiResponse): void => {
        if (response.datas && response.datas.sinistre) {
            const sinistre: ISinistre = response.datas.sinistre;
            history.push(AppConstants.pathSinistres + '/' + sinistre.id);
        } else {
            history.push(AppConstants.pathSinistres);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathSinistres);

    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                         contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un sinistre" icon="icon-sinistres-add"
                                 modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"}
                                        modificators="-margin-bottom"/>
            <SinistreInformationsFormComponent
                submitMethod={(formData: ISinistreInformationsFormData) => sinistresService.create(formData)}
                onSuccessSubmit={onSuccessSubmitForm}
                onCancel={onCancelForm}
                isOnCreationStep={true}/>
        </LayoutComponent>
    )
}
