import {
    IOfficeGetApiResponse, IOfficeNegociateursFormData} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import {NegociateursConstants} from "src/Constants/NegociateursConstants";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {AppConstants} from "../../../../Constants/AppConstants";


type IOfficeNegociateursFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IOfficeNegociateursFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeNegociateursFormComponent(props: IOfficeNegociateursFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const office: IOffice = props.office;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'hidden',
                fieldName: "officeId",
                modificators: "-hidden",

                oldValue: office.id.toString(),
            },
            {
                type: 'repeater',
                fieldName: "negociateurs",
                label: "Negociateurs",
                oldRepeatableValues: office.negociateurs as FormComponentFormData,
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'text',
                                fieldName: "nom",

                                label: "Nom",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",

                                oldValue: "",
                            },
                            {
                                type: 'select',
                                fieldName: "type",

                                label: "Type",
                                options: NegociateursConstants.typesOptions,
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",

                                oldValue: "",
                            },
                            {
                                type: 'email',
                                fieldName: "email",

                                label: "Email",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",

                                oldValue: "",
                            },
                            {
                                type: 'radio',
                                fieldName: "affichageSiteEmail",

                                label: "Affichage de l'email sur le site",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                options: AppConstants.ouiNonOptions,
                                hideSearchOnMultiple: true,

                                oldValue: "",
                            },
                            {
                                type: 'hidden',
                                fieldName: "officeId",

                                required: false,
                                modificators:"-hidden",

                                oldValue: office.id.toString(),
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'radio',
                                fieldName: "affichageSite",

                                label: "Affichage Site",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : "-skip-line-1",
                                options: AppConstants.ouiNonOptions,
                                hideSearchOnMultiple: true,

                                oldValue: "",
                            },
                            {
                                type: 'text',
                                fieldName: "telephone",

                                label: "Téléphone",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",

                                oldValue: "",
                            },
                            {
                                type: 'radio',
                                fieldName: "affichageSiteTel",

                                label: "Affichage du téléphone sur le site",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                options: AppConstants.ouiNonOptions,
                                hideSearchOnMultiple: true,

                                oldValue: "",
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'selectMultiple',
                                fieldName: "labels",

                                label: "Labels",
                                options: NegociateursConstants.labelsOptions,
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white  -skip-line-1" : " -skip-line-1",

                                oldValues: [],
                            },
                            {
                                type: 'text',
                                fieldName: "telephonePortable",

                                label: "Téléphone Portable",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",

                                oldValue: "",
                            },
                            {
                                type: 'radio',
                                fieldName: "affichageSiteTelPortable",

                                label: "Affichage du téléphone portable sur le site",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                options: AppConstants.ouiNonOptions,
                                hideSearchOnMultiple: true,

                                oldValue: "",
                            },
                        ],
                    }
                ],
                required: false,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeNegociateursFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le notaire a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des négociateurs de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
