import ApiService, {
    IApiCustomResponse,
    IApiPagination,
    IFileDownloadApiResponse
} from "./Api.service";
import {ICorrespondance} from "src/Models/Correspondance.model";
import {ICorrespondanceParticipant} from "../Models/CorrespondanceParticipant.model";

export type ICorrespondancesGetPaginationApi = IApiPagination & {
    data: ICorrespondance[],
}

export type ICorrespondancesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICorrespondancesGetPaginationApi
    }
}

export type ICorrespondanceGetApiResponse = IApiCustomResponse & {
    datas?: {
        correspondance: ICorrespondance
    }
}

export type ICorrespondanceParticipantGetApiResponse = IApiCustomResponse & {
    datas?: {
        correspondanceParticipant: ICorrespondanceParticipant
    }
}

export type ICorrespondancesAllGetApiResponse = IApiCustomResponse & {
    datas?: {
        correspondances: ICorrespondance[]
    }
}

export type ICorrespondanceParticipantsGetPaginationApi = IApiPagination & {
    data: ICorrespondanceParticipant[],
}

export type ICorrespondanceParticipantsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICorrespondanceParticipantsGetPaginationApi
    }
}

export type ICorrespondanceFormData = {}

export type ICorrespondanceCourrierFormData = {
    numeroFacture: number,
    type: string,
    afficherEntete: string,
    contenu: string,
    contenuMail?: string,
    isDebug?: boolean,
    isPreview?: boolean,
}

export type ICorrespondancesGetFormData = {
    page: number,
    orderBy: string,
    old?: number
}
export type ICorrespondancesParticipantsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    keyword?: string,
    test?: string
}
export type ICorrespondanceSendMailListFormData = {
    ids: string[],
}
export type ICorrespondanceConfirmationFormData = {
    reunion?: string,
    cocktail?: string,
    hebergement?: string,
    transport?: string,
    presence?: string,
    collaborateur?: string,
}
export type ICorrespondanceParticipantUpdatePresenceFormData = {
    key?: string,
    presence?: string,
}

export class CorrespondancesService extends ApiService{
    /**
     * Permet la récupération d'une liste de correspondances (paginée)
     *
     * @param {ICorrespondancesGetFormData} formData
     * @returns {Promise<ICorrespondancesGetApiResponse>}
     */
    public getCorrespondances(formData: ICorrespondancesGetFormData): Promise<ICorrespondancesGetApiResponse>{
        return this.doApiCall('api/correspondances', 'GET', formData);
    }


    /**
     * Mise à jour d'un participan
     * @param {string} pId
     * @param {ICorrespondanceParticipantUpdatePresenceFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateParticipantPresence(pId: string, formDatas:ICorrespondanceParticipantUpdatePresenceFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/correspondances/participants/' + pId + "/update/presence", 'POST',formDatas);
    }


    /**
     * Récupération de toutes les correspondances
     *
     * @returns {Promise<ICorrespondancesAllGetApiResponse>}
     */
    public getAllCorrespondances(): Promise<ICorrespondancesAllGetApiResponse>{
        return this.doApiCall('api/correspondances/all', 'GET');
    }

    /**
     * Récupération d'une correspondance
     * @param {number} cid
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public getCorrespondance(cid: number): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/' + cid, 'GET');
    }

    /**
     * Récupération d'un participant
     *
     * @param {number} pid
     * @returns {Promise<ICorrespondanceParticipantGetApiResponse>}
     */
    public getCorrespondanceParticipant(pid: number): Promise<ICorrespondanceParticipantGetApiResponse>{
        return this.doApiCall('api/correspondances/participants/' + pid, 'GET');
    }

    /**
     * Mise à jour d'un participant externe
     * @param {number} pid
     * @returns {Promise<ICorrespondanceParticipantGetApiResponse>}
     */
    public getCorrespondanceParticipantExternal(pid: number): Promise<ICorrespondanceParticipantGetApiResponse>{
        return this.doApiCall('api/external/correspondances/participants/' + pid, 'GET');
    }

    /**
     * Récupération d'un participant test externe
     * @param {number} pid
     * @returns {Promise<ICorrespondanceParticipantGetApiResponse>}
     */
    public getCorrespondanceParticipantTestExternal(pid: number): Promise<ICorrespondanceParticipantGetApiResponse>{
        return this.doApiCall('api/external/correspondances/participants/tests/' + pid, 'GET');
    }

    /**
     * Mise à jour de la partie Informations
     * @param {ICorrespondanceFormData} formDatas
     * @param {number} cid
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondanceInformations(formDatas: ICorrespondanceFormData, cid: number = null): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall(`api/correspondances/${cid !== null ? `${cid}/` : ''}informations`, 'POST', formDatas);
    }

    /**
     * Mise à jour de la partie Signature
     * @param {ICorrespondanceFormData} formDatas
     * @param {number} cid
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateSignaturesInformations(formDatas: ICorrespondanceFormData, cid: number): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall(`api/correspondances/${cid}/signature`, 'POST', formDatas);
    }


    /**
     * Mise à jour de la partie Documents
     * @param {number} cid
     * @param {ICorrespondanceFormData} formDatas
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondanceDocuments(cid: number,formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall(`api/correspondances/${cid}/documents`, 'POST', formDatas);
    }

    /**
     * Mise à jour de la partie Précisions
     * @param {number} cid
     * @param {ICorrespondanceFormData} formDatas
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondancePrecisions(cid: number,formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall(`api/correspondances/${cid}/precisions`, 'POST', formDatas);
    }


    /**
     * Mise à jour de la partie compléments
     *
     * @param {number} cid
     * @param {ICorrespondanceFormData} formDatas
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondanceComplements(cid: number,formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall(`api/correspondances/${cid}/complements`, 'POST', formDatas);
    }


    /**
     * Mise à jour des invites
     *
     * @param {number} cid
     * @param {ICorrespondanceFormData} formDatas
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondanceInvites(cid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/' + cid + "/invites", 'POST', formDatas);
    }

    /**
     * Mise à jour des tests
     *
     * @param {number} cid
     * @param {ICorrespondanceFormData} formDatas
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondanceTests(cid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/' + cid + "/tests", 'POST', formDatas);
    }

    /**
     * Mise à jour d'un groupe
     *
     * @param {number} cid
     * @param {ICorrespondanceFormData} formDatas
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public updateCorrespondanceGroupes(cid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/' + cid + "/groupes", 'POST', formDatas);
    }


    /**
     * Sauvegarde d'une inscription
     *
     * @param {number} pid
     * @param {string} email
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public saveInscription(pid: number, email: string): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/participants/' + pid + '/confirmation', 'POST', {
            'email': email
        });
    }


    /**
     * Sauvegarde d'une inscription external
     *
     * @param {number} pid
     * @param {string} presence
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public saveInscriptionExternal(pid: number, formData: ICorrespondanceConfirmationFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/external/correspondances/participants/' + pid + '/confirmation', 'POST', formData);
    }

    /**
     * Sauvegarde d'une inscription de test
     *
     * @param {number} pid
     * @param {string} presence
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public saveInscriptionTestExternal(pid: number, formData: ICorrespondanceConfirmationFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/external/correspondances/participants/tests/' + pid + '/confirmation', 'POST', formData);
    }

    /**
     * Annulation d'un invite
     * @param {number} iid
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public cancelInvite(iid: number): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/invite/' + iid + '/cancel', 'POST');
    }

    /**
     * Annulation d'un test
     *
     * @param {number} tid
     * @returns {Promise<ICorrespondanceGetApiResponse>}
     */
    public cancelTest(tid: number): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/test/' + tid + '/cancel', 'POST');
    }


    /**
     * Suppression de la correspondance
     *
     * @param {number} cid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(cid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/correspondances/' + cid + '/delete', 'POST');
    }


    /**
     * Envoi d'un courrier d'une correspondance
     *
     * @param {number} cid
     * @param {ICorrespondanceCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(cid: number, formDatas: ICorrespondanceCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/correspondances/' + cid + '/courrier/send', 'POST', formDatas);
    }
    public resendCourrier(pid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/relance/participants/' + pid + "", 'POST', formDatas);
    }
    public resendCourrierTest(pid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/relance/participants/tests/' + pid + "", 'POST', formDatas);
    }
    public sendCourrierSingle(pid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/send/participants/' + pid + "", 'POST', formDatas);
    }
    public sendCourrierSingleTest(pid: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/send/participants/tests/' + pid + "", 'POST', formDatas);
    }
    public resendAllCourrierTestformDatas(correspondanceId: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/'+correspondanceId+'/relance/all/tests', 'POST', formDatas);
    }
    public resendAllCourrier(correspondanceId: number, formDatas: ICorrespondanceFormData): Promise<ICorrespondanceGetApiResponse>{
        return this.doApiCall('api/correspondances/'+correspondanceId+'/relance/all', 'POST', formDatas);
    }


    /**
     * Téléchargement d'un courrier d'une correspondance
     *
     * @param {number} cid
     * @param {ICorrespondanceCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public downloadCourrier(cid: number, formDatas: ICorrespondanceCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/correspondances/' + cid + '/courrier/download', 'POST', formDatas);
    }

    /**
     * Sauvegarde du courrier d'une correspondance
     *
     * @param {number} cid
     * @param {ICorrespondanceCourrierFormData} formDatas
     * @returns {Promise<IFileDownloadApiResponse>}
     */
    public saveCorrespondanceCourrier(cid: number, formDatas: ICorrespondanceCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/correspondances/' + cid + '/courrier/save', 'POST', formDatas);
    }


    /**
     * Envoi du mail à une liste de participant
     *
     * @param {number} cid
     * @param {string} mailType
     * @param {ICorrespondanceSendMailListFormData} data
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendMailList(cid: number, mailType: string, data: ICorrespondanceSendMailListFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/correspondances/' + cid + '/participants/mail/' + mailType, 'POST',data);
    }

    /**
     * Permet la récupération d'une liste de participants à une correspondance (paginée/filtrée)
     *
     * @param cid
     * @param {ICorrespondancesParticipantsGetFormData} formData
     * @returns {Promise<ICorrespondancesGetApiResponse>}
     */
    public getParticipantsCorrespondance(cid: number, formData: ICorrespondancesParticipantsGetFormData): Promise<ICorrespondanceParticipantsGetApiResponse>{
        return this.doApiCall('api/correspondances/' + cid + '/participants/list', 'GET', formData);
    }
}
