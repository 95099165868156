import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IInspectionGetApiResponse, InspectionsService} from "../Services/Inspections.service";
import {IInspection} from "../Models/Inspection.model";
import {ITableauBord} from "../Models/TableauBord.model";
import {IStatistiques} from "../Models/Statistiques.model";
import {IAvisMotive} from "../Models/AvisMotive.model";



const UseInspection = () => {
    const inspectionsService: InspectionsService = new InspectionsService();
    const history = useHistory();

    /**
     * Récupération d'une inspection à partir de son id
     *
     * @param {string} id
     * @param {(inspection: IInspection) => void} callback
     * @param {() => void} callbackError
     */
    const getInspectionById = (id: string, callback:(inspection: IInspection, tableauBord: ITableauBord, stats:IStatistiques[], avisList: IAvisMotive[], allInspections: IInspection[]) => void, callbackError?: () => void ) => {
        inspectionsService.getInspection(id).then((response: IInspectionGetApiResponse) => {
            if( response && response.datas.inspection){
                if(callback){
                    callback(response.datas.inspection, response.datas.tableauBord, response.datas.stats, response.datas.avisList, response.datas.allInspections);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathInspections);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de l'inspection'",type: "danger"});
        });
    };


    return {getInspectionById};
};

export default UseInspection;
