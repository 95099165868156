import React, {useEffect, useState} from "react";
import { IDifferend } from "src/Models/Differend.model";
import moment from "moment";

type Row = string[];

const DifferendsSummary = (props: {
    differendsEnCours: IDifferend[],
    differendsArchives: IDifferend[],
}) => {

    const [currentYear,setCurrrentYear] = useState<string>("");
    const [previousYear,setPreviousYear] = useState<string>("");
    const [rows,setRows] = useState<Row[]>([]);

    useEffect(()=>{
        const current = moment();
        setCurrrentYear(current.format("YYYY"));
        setPreviousYear(current.subtract(1,"years").format("YYYY"));
    },[]);

    useEffect(()=>{

        let nbCurrentYearEnCours = 0;
        let nbPreviousYearEnCours = 0;
        let nbBeforeYearEnCours = 0;
        let nbTotalYearEnCours = 0;
        props.differendsEnCours.forEach((d)=>{
            if(getYear(d.dateReception) === currentYear) nbCurrentYearEnCours++;
            else if(getYear(d.dateReception) === previousYear) nbPreviousYearEnCours++;
            else nbBeforeYearEnCours++;

            nbTotalYearEnCours++;
        });

        let nbCurrentYearTermine = 0;
        let nbPreviousYearTermine = 0;
        let nbBeforeYearTermine = 0;
        let nbTotalYearTermine = 0;
        props.differendsArchives.forEach((d)=>{
            if(getYear(d.dateReception) === currentYear) nbCurrentYearTermine++;
            else if(getYear(d.dateReception) === previousYear) nbPreviousYearTermine++;
            else nbBeforeYearTermine++;

            nbTotalYearTermine++;
        });

        setRows([
            ["En cours",nbCurrentYearEnCours.toString(),nbPreviousYearEnCours.toString(),nbBeforeYearEnCours.toString(),nbTotalYearEnCours.toString()],
            ["Actions terminées",nbCurrentYearTermine.toString(),nbPreviousYearTermine.toString(),nbBeforeYearTermine.toString(),nbTotalYearTermine.toString()],
            [
                "Total",
                (nbCurrentYearEnCours + nbCurrentYearTermine).toString(),
                (nbPreviousYearEnCours + nbPreviousYearTermine).toString(),
                (nbBeforeYearEnCours + nbBeforeYearTermine).toString(),
                (nbTotalYearEnCours + nbTotalYearTermine).toString(),
            ]
        ]);
    },[props.differendsEnCours,props.differendsArchives]);

    const getYear = (date: string) => {
        return moment(date).format("YYYY");
    };

    return <div className={"differends-summary"}>
        <table className="table">
            <thead>
            <tr>
                <th className="-left">Nombre de différends</th>
                <th>{currentYear}</th>
                <th>{previousYear}</th>
                <th>Avant</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {rows.map((row, rIndex)=><tr key={rIndex}>
                {row.map((data, dIndex)=><td className={dIndex === 0 ? '-left -label' : ''} key={dIndex}>{data}</td>)}
            </tr>)}
            </tbody>
        </table>
    </div>

};

export default DifferendsSummary;