import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import AsideSuccessionComponent from "src/Components/Aside/AsideSuccessions.component";
import {useHistory, useLocation} from "react-router";
import SelectMultipleFieldComponent from "src/Components/Fields/SelectMultiple.field.component";
import {SuccessionsConstants} from "src/Constants/SuccessionsConstants";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {ISuccessionsGetApiResponse, ISuccessionsGetFormData, SuccessionsService} from "src/Services/Successions.service";
import {ISuccession} from "src/Models/Succession.model";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import DateHelper from "src/Helpers/Date.helper";
import SuccessionsHelper from "src/Helpers/Successions.helper";
import SuccessionsExportComponent from "src/Components/Successions/SuccessionsExport.component";
import SelectFieldComponent from "../../Components/Fields/Select.field.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {Link} from "react-router-dom";
import LoadingComponent from "../../Components/Loading/Loading.component";



type ISuccessionsListScreenProps = {
};

export default function SuccessionsListScreen(props: ISuccessionsListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [successionsList, setSuccessionsList] = useState<ISuccession[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathSuccessions);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    const successionService: SuccessionsService = new SuccessionsService();



    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Successions" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters, false, true);
    }, []);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);



    /**
     * Récupération des successions
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const successionsService: SuccessionsService = new SuccessionsService();

        successionsService.getSuccessions(filters as ISuccessionsGetFormData).then((response: ISuccessionsGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newSuccessionsList = response.datas.pagination.data;
                setSuccessionsList(newSuccessionsList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);


                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }





    const changeState = (value: string, succession: ISuccession) => {
        successionService.updateEtat(succession.id.toString(), {'etat': value}).then((result) => {

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la modification.",
                type: "danger"
            });
        });
    };


    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Successions"
                    icon="icon-successions"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">
                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`} />
                                        <SelectMultipleFieldComponent fieldName="etat"
                                              label="Etats"
                                              options={SuccessionsConstants.etatsOptions}
                                              hideCurrentValuesOnTop={true}
                                              errors={null}
                                              placeholder="Tous les états"
                                              hideSearch={true}
                                              modificators={`-inline -on-white`}
                                              oldValues={filters.etat ? filters.etat as string[] : []}
                                              onChange={(values: string[]) => updateFilters({etat: values})}
                                        />
                                    </div>

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "id", label: "Identifiant"},
                                                    {slug: "etat", label: "Statut"},
                                                    {slug: "nom", label: "Défunt"},
                                                    {slug: "naissance", label: "Naissance"},
                                                    {slug: "deces", label: "Décès"},
                                                    {slug: "demandeur", label: "Demandeur"},
                                                    {slug: "date", label: "Date"},
                                                    {slug: "nomOffice", label: "Office"},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            successionsList && successionsList.map((succession: ISuccession, index: number) =>
                                                <tr key={index} className="tr">
                                                    <td className="td -w85px" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {succession.id}
                                                    </td>
                                                    <td className="td -bold">
                                                        <SelectFieldComponent
                                                            fieldName={'etat'}
                                                            modificators={'-on-white -on-list'}
                                                            label={''}
                                                            labelModificators={''}
                                                            errors={null}
                                                            onChange={(value: string) => {
                                                                changeState(value, succession)
                                                            }}
                                                            placeholder={''}
                                                            oldValue={succession.etat}
                                                            options={SuccessionsConstants.etatsOptions}
                                                            optionsGroup={[]}
                                                            required={false}
                                                            hideSearch={true}
                                                        />
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {`${succession.identiteNom} ${succession.identitePrenom}`}
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {SuccessionsHelper.getNaissanceResume(succession)}
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {SuccessionsHelper.getDecesResume(succession)}
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {SuccessionsHelper.getDemandeurResume(succession)}
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {DateHelper.getFormatedDate(succession.date)}
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathSuccessions}/${succession.id}`)}>
                                                        {
                                                            succession.office &&
                                                                <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${succession.office.id}`} onClick={(e) => e.stopPropagation()}>{succession.office.nom}</Link>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>


                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>


                                    <AsideSuccessionComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            <SuccessionsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} filters={filters} />
        </LayoutComponent>
    )
}
