import React, {useEffect, useState} from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {AutresContactsService, IDepartementsGetApiResponse} from "../../../../Services/AutresContacts.service";
import {IOption} from "../../../Fields/Select.field.component";

type OrganismesAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,

}
export default function OrganismesAdvancedSearchComponent(props: OrganismesAdvancedSearchComponentProps) {
    const [departementsOptions, setDepartementsOptions] = useState<IOption[]>([]);

    useEffect(() => {
        const autresContactsService: AutresContactsService = new AutresContactsService();

        autresContactsService.getOrganismesDepartements().then((response: IDepartementsGetApiResponse) => {
            if (response.datas && response.datas.departements) {
                setDepartementsOptions(autresContactsService.formatDepartementsForSelect(response.datas.departements));
            }
        });
    }, []);

    return (
        <AdvancedSearchComponent
            title="Organismes"
            showSummary={props.showSummary}
            modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
            showAdvancedSearch={props.showAdvancedSearch}
            filters={props.filters}
            columns={[
                {
                    elements: [
                        {
                            type: 'hidden',
                            fieldName: "advanced",
                            oldValue: "1",
                            modificators: "-hidden"
                        },
                        {
                            type: "text",
                            fieldName: "nom_organisme",
                            label: "Nom",
                            modificators: "-on-white",
                            oldValue: props.filters.nom_organisme as string || ""
                        },
                        {
                            type: "selectMultiple",
                            fieldName: "departement",
                            label: "Département",
                            modificators: "-on-white",
                            hideSearchOnMultiple: true,
                            options: departementsOptions.length > 0 ? departementsOptions : OfficesConstants.departementsOptions,
                            oldValues: props.filters.departement as string[] || []
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: "text",
                            fieldName: "ville",
                            label: "Ville",
                            modificators: "-on-white",
                            oldValue: props.filters.ville as string || ""
                        },
                        {
                            type: "text",
                            fieldName: "codepostal",
                            label: "Code postal",
                            modificators: "-on-white",
                            oldValue: props.filters.codepostal as string || ""
                        },
                    ]
                }, {
                    elements: [
                        {
                            type: "text",
                            fieldName: "nom_personneExterieures",
                            label: "Nom personne extérieure",
                            modificators: "-on-white",
                            oldValue: props.filters.nom_personneExterieures as string || ""
                        },
                        {
                            type: "text",
                            fieldName: "prenom_personneExterieures",
                            label: "Prénom personne extérieure",
                            modificators: "-on-white",
                            oldValue: props.filters.prenom_personneExterieures as string || ""
                        },
                    ]
                },
            ]}
            onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
            onClose={() => props.setShowAdvancedSearch()}
            onSearch={props.onSearch}
        />
    )

}
