import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type StatistiquesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any
}
export default function StatistiquesExportComponent(props: StatistiquesExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Statistiques"}
                         icon="icon-statistiques"
                         subTitle={"Export des statistiques"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl= {`api${AppConstants.pathStatistiques}/export`}
                         filters={props.filters}
                         columns={[
                             {
                                 elements: [
                                 ]
                             },
                         ]}
        />
    )
}
