import {DifferendsConstants} from "../Constants/DifferendsConstants";
import CommonHelper from "./Common.helper";
import {IDifferend} from "../Models/Differend.model";
import {IOption} from "../Components/Fields/Select.field.component";

export default class DifferendHelper {

    /**
     * Retourne l'état du différend de la clé passée en paramètre
     * @param {string} slug
     * @returns {string}
     */
    static getFormattedEtat = (slug: string): string => {
        for (let cat of DifferendsConstants.states) {
            if (cat.value === slug) return cat.label;
        }
        return slug;
    };

    /**
     * Retourne l'objet du différend de la clé passée en paramètre
     * @param {string} slug
     * @returns {string}
     */
    static getFormattedObjet = (slug: string): string => {
        for (let cat of DifferendsConstants.subjects) {
            if (cat.value === slug) return cat.label;
        }
        return slug;
    };

    /**
     * Retourne le type du document du différend
     * @param {string} type
     * @param {IOption[]} documentsOptions
     * @returns {string}
     */
    static getFormatedDocumentTypes = (type: string, documentsOptions: IOption[]): string => {
        return CommonHelper.getFormatedElementFromOptions(type, documentsOptions);
    };


    /**
     * Récupération du type de différend
     * Type1: Interne vs interne
     * Type2: Interne vs externe
     * Type3: Externe vs interne
     * @param {IDifferend} differend
     * @returns {string}
     */
    static getDifferendType = (differend: IDifferend): string => {
        if(differend.notairePlaignant && differend.notaireMisEnCause){
            return "type1";
        }
        else if(differend.notairePlaignant && !differend.notaireMisEnCause){
            return "type2";
        }
        else{
            return "type3";
        }
    }

}
