import React, {useEffect, useRef, useState} from "react";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "src/Services/Api.service";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";
import DateHelper from "../../Helpers/Date.helper";
import {isValid} from "date-fns";
registerLocale('fr', fr)

export type IFormField = {
    fieldName?: string,

    label?: string,
    labelModificators?: string,

    placeholder?: string,
    required?: boolean,
    disabled?: boolean,
    modificators?: string,
    maxLength?: number,
    showFieldErrorDetail?: boolean,

    errors?: IApiErrorMessage,
}

export type IDateFieldProps = IFormField & {
    oldValue?: string,
    onChange: (value: string) => void,
    withTime?: boolean,
    timeInterval?: number,
}

export default function DateFieldComponent(props: IDateFieldProps){
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const [dateLoaded, setDateLoaded] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [showDateError, setShowDateError] = useState(false);
    const inputRef = useRef<HTMLInputElement>();

    let isValidDate = false;
    let handleChangeRawTriggered = false;

    useEffect(()=> {
        if(props.oldValue) {
            const value: Date = parseISO(props.oldValue);

            setStartDate(value);
        }
        else{
            setStartDate("");
        }

        setDateLoaded(true);
    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);


    const handleCalendarClose = () => setIsOpen(false);
    const handleCalendarOpen = () => setIsOpen(true);
    const handleChange = (date: Date) => {
        setTimeout( () => {
            if(props.withTime && !handleChangeRawTriggered){
                isValidDate = true;
            }

            if(isValidDate && date && isValid(date) && DateHelper.validateInputDate(date, props.withTime)) {
                setStartDate(date);
                const value: string = formatISO(date);
                props.onChange(value);
                setShowDateError(false);
            }
            else{
                setShowDateError(true);
                setStartDate("");
                handleChangeRawTriggered = false;
                props.onChange("");
            }
        }, 50)
    };
    const handleChangeRaw = (event: any) => {
        //On effectue des vérifications sur la donnée brut de l'input
        //Afin de savoir si ce qui est entrée manuellement est ok ou ko
        //Pour ensuite passer l'info au handleChange du datepicker
        //Attention : pour un datepicker avec l'heure, lors du clic sur l'heure (pour la changer), cette méthode n'est pas déclenché (d'où l'utilisation de handleChangeRawTriggered).
        const dateString = event.target.value;
        let newDateValid: boolean = true;

        if(dateString && dateString != "") {
            const dateStringFormated = DateHelper.convertDateString(dateString, props.withTime);
            if (!dateStringFormated || (dateStringFormated && !isValid(dateStringFormated))) {
                newDateValid = false;
                setShowDateError(true);
            }
            else{
                newDateValid = true;
                setShowDateError(false);
            }
        }
        else{
            setShowDateError(false);
        }

        isValidDate = newDateValid;

        handleChangeRawTriggered = true;
    }



    return (
        <div className={`form-field ${isOpen ? '-date-picker' : ''} ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}
            <div className={`input-wrapper date-field-wrapper ${errors || showDateError ? '-error' : ''}`}>
                {
                    dateLoaded &&
                        <>
                            <DatePicker
                                todayButton="Aujourd'hui"
                                selected={startDate}
                                dateFormat={ props.withTime ? "dd/MM/yyyy HH'h'mm" : "dd/MM/yyyy"}
                                locale="fr"
                                className={`form-field-input -full-w  ${errors|| showDateError ? '-error' : ''}`}
                                timeFormat="HH:mm"
                                showWeekNumbers
                                showTimeSelect={props.withTime}
                                timeInputLabel="Horaires:"
                                timeIntervals={props.timeInterval ?? 15}
                                fixedHeight={true}
                                disabled={props.disabled || false}

                                onChange={handleChange}
                                onChangeRaw={handleChangeRaw}
                                onCalendarClose={handleCalendarClose}
                                onCalendarOpen={handleCalendarOpen}
                            />
                            <span className="icon icon-calendar-small" onClick={()=>inputRef.current.focus()} />
                        </>
                }
            </div>


            {
                showDateError &&
                <>
                    <ErrorFieldsMessagesComponent fieldsErrorsMessages={[props.withTime ? 'La date doit être au format JJ/MM/AAAA HHhMM.' : 'La date doit être au format JJ/MM/AAAA.']} />
                </>
            }

            {
                props.showFieldErrorDetail &&
                <ErrorFieldsMessagesComponent fieldsErrorsMessages={props.errors} />
            }
        </div>
    )
}
