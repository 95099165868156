import React, {createRef, SyntheticEvent, useEffect, useState} from "react";
import LabelComponent from "./Label.component";
import {IFormField} from "./Input.field.component";
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";
import FileHelper from "src/Helpers/File.helper";
import ReactHtmlParser from "react-html-parser";

const FileUploadField = (props: IFormField & {
    background: string,
    onChange: (newFile: File, oldFile: string, deleteFile?: boolean) => void,
    oldValue: string,

    uploadLabel?: string,
    hideUploadLabelIfFile?: boolean,
    showImage?: boolean,
    showImageDeleteButton?: boolean,

    showMentions?: boolean,
    textMention?: string,

    extensions?: string[]
}) => {

    const inputRef = createRef<HTMLInputElement>();
    const [existingFilename, setExistingFilename] = useState<string>("");
    const [filename, setFilename] = useState<string>("");

    useEffect(()=>{
        if(props.oldValue){
            setExistingFilename(props.oldValue);
            setFilename(props.oldValue);
        }
    },[]);

    const handleClick = (e: SyntheticEvent): void => {
        e.preventDefault();
        inputRef.current.click();
    };

    const handleFileChange = (file: File): void => {
        props.onChange(file,existingFilename);
        setFilename(file.name);
    };

    const handleDeleteFile = (e: SyntheticEvent): void => {
        e.preventDefault();
        props.onChange(null, existingFilename, true);
        setFilename(null);
    }


    return <div className={`form-field -small-file ${props.modificators || ""}`}>
        {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}
        <div className={`input-wrapper ${props.errors ? '-error' : ''}`}>

                <div className={`form-file-field`}>
                    {
                        props.showImage && props.oldValue && (props.oldValue === filename) &&
                            <figure className="image-preview">
                                <img src={FileHelper.getImageUrlFromSize(props.oldValue,"thumbnailCropped")} className="image" />
                            </figure>
                    }
                    {
                        filename &&
                            <p className="filename">
                                {FileHelper.basename(filename)}
                                {
                                    props.showImageDeleteButton &&
                                    <button className="g-button -delete-button icon-remove" onClick={handleDeleteFile}/>
                                }

                            </p>
                    }
                    {
                        (!filename || (filename && !props.hideUploadLabelIfFile)) &&
                            <div className={`button-wrapper`}>
                                <button className="button"  onClick={handleClick}>
                                    <span className="content">
                                        <span className="icon icon-import" />
                                        <span className="text">{props.uploadLabel ? props.uploadLabel : 'Importer'}</span>
                                    </span>
                                </button>
                            </div>
                    }
                    <input className="input"
                           type="file"
                           ref={inputRef}
                           accept={props.extensions ? props.extensions.join(',') : null}
                           onChange={(e) => handleFileChange(e.currentTarget.files[0])}
                    />
                </div>
        </div>


        {props.showMentions &&
            <div className="form-field-mention">
                { ReactHtmlParser(props.textMention) }
            </div>
        }

        <ErrorFieldsMessagesComponent fieldsErrorsMessages={props.errors} />
    </div>
};

export default FileUploadField;
