import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {IParametreGetApiResponse, IParametreInformationsFormData, ParametresService} from "src/Services/Parametres.service";
import {IParametre} from "src/Models/Parametre.model";
import ParametreInformationsFormComponent from "src/Components/Forms/Parametres/ParametreInformationsForm.component";


type IParametreCreationScreenProps = {
}
export default function ParametreCreationScreen(props: IParametreCreationScreenProps){
    const history = useHistory();
    const parametresService: ParametresService = new ParametresService();


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IParametreGetApiResponse): void => {
        if(response.datas && response.datas.parametre){
            const parametre: IParametre = response.datas.parametre;
            history.push(AppConstants.pathParametres + '/' + parametre.id);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathParametres);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un paramètre" icon="icon-cog" modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-cog"} title={"Paramètre"} modificators="-margin-bottom" />

            <ParametreInformationsFormComponent submitMethod={(formData: IParametreInformationsFormData)=>parametresService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={true} />
        </LayoutComponent>
    )
}
