import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";
import {IReclamation, IReclamationsStats} from "../Models/Reclamation.model";
import {IAdresseFormData, ICoordonneeFormData, ICoordonneesFormData} from "./Commons.service";

export type IReclamationGetApiResponse = IApiCustomResponse & {
    datas?: {
        reclamation: IReclamation
    }
}
export type IReclamationsGetPaginationApi = IApiPagination & {
    data: IReclamation[],
}
export type IReclamationsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IReclamationsGetPaginationApi,
        stats?: IReclamationsStats,
    }
}
export type IReclamationsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    etat?: string[],
}
export type IReclamationDocumentsFormData = {
    fichier?: string;
    date: string,
    type: string,
    commentaires?: string,
}
export type IReclamationCourrierFormData = {
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}

type IReclamationPlaignantFormData = {
    civilite: string,
    nom: string,
    prenom: string,
    fonction?: string,
}
export type IReclamationPlaignantsFormData = {
    plaignants: IReclamationPlaignantFormData[],
    IdsToDelete?: string[],
}

export type IReclamationInformationsFormData = {

}
export type IReclamationConciliationFormData = {

}
export type IReclamationCreationFormData = {

}

export type IReclamationNotairesFormData = {

}
export type IReclamationCoordonneesPlaignantFormData = {
    coordonnes?: ICoordonneeFormData[],
    idsToDelete?: number[]
}
export type IReclamationAdressesPlaignantsFormData = {
    adresses?: IAdresseFormData[],
    idsToDelete?: number[]
}



export class ReclamationsService extends ApiService{
    /**
     * Permet la suppression d'une réclamation
     *
     * @param {number} rid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(rid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/delete', 'POST');
    }

    /**
     * Permet la récupération d'une réclamation à partir de son id
     *
     * @param {string} rid
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public getReclamation(rid: string): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/' + rid, 'GET');
    }

    /**
     * Permet la récupération d'une liste de reclamations
     *
     * @returns {Promise<IReclamationsGetApiResponse>}
     */
    public getReclamations(formData: IReclamationsGetFormData): Promise<IReclamationsGetApiResponse>{
        return this.doApiCall('api/reclamations', 'GET', formData);
    }

    /**
     * Permet la récupération d'une liste de reclamations par type/etat
     *
     * @returns {Promise<IReclamationsGetApiResponse>}
     */
    public getReclamationsParType(type: string, formData: IReclamationsGetFormData): Promise<IReclamationsGetApiResponse>{
        return this.doApiCall('api/reclamations/type/' + type, 'GET', formData);
    }

    /**
     * Création d'une réclamation
     *
     * @param {IReclamationCreationFormData} formData
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public create(formData: IReclamationCreationFormData): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/create', 'POST', formData);
    }

    /**
     * Modification des plaignants
     *
     * @param {number} rid
     * @param {IReclamationPlaignantsFormData} formData
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public updatePlaignants(rid: number, formData: IReclamationPlaignantsFormData): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/plaignants', 'POST', formData);
    }

    /**
     * Mise à jour des documents d'une réclamation
     *
     * @param {number} rid
     * @param {IReclamationDocumentsFormData} formDatas
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public updateDocuments(rid: number, formDatas: IReclamationDocumentsFormData): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/documents', 'POST', formDatas);
    }

    /**
     * Mise à jour des informations d'une réclamation
     *
     * @param {number} rid
     * @param {IReclamationInformationsFormData} formDatas
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public updateInformations(rid: number, formDatas: IReclamationInformationsFormData): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/reclamation', 'POST', formDatas);
    }

    /**
     * Mise à jour de conciliation d'une réclamation
     *
     * @param {number} rid
     * @param {IReclamationInformationsFormData} formDatas
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public updateConciliation(rid: number, formDatas: IReclamationConciliationFormData): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/conciliation', 'POST', formDatas);
    }

    /**
     * Mise à jour des notaires concernés par une réclamation
     *
     * @param {number} rid
     * @param {IReclamationNotairesFormData} formDatas
     * @returns {Promise<IReclamationGetApiResponse>}
     */
    public updateNotaires(rid: number, formDatas: IReclamationNotairesFormData): Promise<IReclamationGetApiResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/notaires', 'POST', formDatas);
    }


    /**
     * Envoi d'un courrier d'une réclamation
     *
     * @param {number} rid
     * @param {IReclamationCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(rid: number, formDatas: IReclamationCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/courrier/send', 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier d'une réclamation
     *
     * @param {number} rid
     * @param {IReclamationCourrierFormData} formDatas
     * @returns {Promise<IFileDownloadApiResponse>}
     */
    public downloadCourrier(rid: number, formDatas: IReclamationCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/courrier/download', 'POST', formDatas);
    }

    /**
     * Mise à jour des coordonnées des plaignants
     *
     * @param {number} rid
     * @param {IReclamationCoordonneesPlaignantFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateCoordonneesPlaignants(rid: number, formDatas: IReclamationCoordonneesPlaignantFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/plaignants/coordonnees', 'POST', formDatas);
    }

    /**
     * Mise à jour des adresses des plaignants
     *
     * @param {number} rid
     * @param {IReclamationCoordonneesPlaignantFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateAdressesPlaignants(rid: number, formDatas: IReclamationAdressesPlaignantsFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/reclamations/' + rid + '/plaignants/adresses', 'POST', formDatas);
    }


}



