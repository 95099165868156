import {IOfficesGetAllApiResponse, OfficesService} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import {
    INotaireGetAllApiResponse,
    INotaireGetApiResponse,
    INotaireHistoriquesFormData,
    NotairesService
} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import OfficesHelper from "src/Helpers/Offices.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type INotaireHistoriquesFormComponentProps = {
    notaire?: INotaire,
    isOnCreationStep?: boolean,

    submitMethod: (formData: INotaireHistoriquesFormData) => Promise<INotaireGetApiResponse>,
    onSuccessSubmit: (response: INotaireGetApiResponse) => void,
    onSuccessSubmitNext?: (response: INotaireGetApiResponse) => void,
    onCancel: () => void,
}

export default function NotaireHistoriquesFormComponent(props: INotaireHistoriquesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [offices, setOffices] = useState<IOffice[]>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [officeLoaded, setOfficesLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();


    useEffect(() => {

        const officeService: OfficesService = new OfficesService();
        officeService.getAllOfficesPrincipales().then((response: IOfficesGetAllApiResponse) => {
            if (response && response.datas && response.datas.offices) {
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });

    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const notaire: INotaire = props.notaire;

        let formElementsColumn1: FormElement[] = [];



        formElementsColumn1.push(
            {
                type: 'repeater',
                fieldName: "historiques",
                label: "",
                oldRepeatableValues: notaire.historiques as FormComponentFormData,
                hideAddButton: true,
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'selectBrut',
                                fieldName: "type",

                                label: "Type de notaire",
                                placeholder: "Type de",
                                required: true,
                                disabled: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                optionsGroup: NotairesConstants.typesOptions,

                                oldValue: notaire && notaire.type ? notaire.type : "",
                            },
                            {
                                type: 'date',
                                fieldName: "dateArrivee",

                                label: "Date d'arrivée dans l'office",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                showFieldErrorDetail: true,

                                oldValue: "",
                            },
                            {
                                type: 'hidden',
                                fieldName: "notaireId",

                                required: false,
                                modificators: "-hidden",

                                oldValue: notaire.id.toString(),
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'selectBrut',
                                fieldName: "officeId",

                                label: "Office",
                                options: offices && offices.length ? OfficesHelper.formatListForOptions(offices) : [],
                                required: true,
                                disabled: true,
                                modificators: props.isOnCreationStep ? "-on-white" : "",

                                oldValue: "",
                            },
                            {
                                type: 'date',
                                fieldName: "dateDepart",

                                label: "Date de départ de l'office",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                showFieldErrorDetail: true,

                                oldValue: "",
                            },
                        ],
                    },
                ],
                required: false,
            },
        );


        if(NotairesConstants.typesAnciens.includes(notaire.type) ) {
            formElementsColumn1.push(
                {
                    type: 'date',
                    fieldName: "dateRetrait",

                    label: "Date de retrait",
                    placeholder: "Date de retrait",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white" : '',

                    oldValue: notaire && notaire.dateRetrait ? notaire.dateRetrait : "",
                }
            );
        }

        if(notaire.ancienHonoraire) {
            formElementsColumn1.push(
                {
                    type: 'date',
                    fieldName: "dateHonorariat",

                    label: "Date honorariat",
                    placeholder: "Date honorariat",
                    required: false,
                    modificators: props.isOnCreationStep ? "-on-white" : '',

                    oldValue: notaire && notaire.dateHonorariat ? notaire.dateHonorariat : "",
                },
            );
        }


        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true);
        setFormActions(currentFormActions);
    }, [offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as INotaireHistoriquesFormData).then((response: INotaireGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les historiques du notaire ont bien été modifiés.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des historiques du notaire.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <>
            {
                officeLoaded && formColumns &&
                <div className="form-wrapper -edit">
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages}/>
                </div>
            }
        </>
    )

}
