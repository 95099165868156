import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";


type IAsideIntervenantComponentProps = {
    modificators?: string,
    type: "list",
    exportCallback?: () => void,
}

export default function AsideIntervenantComponent(props: IAsideIntervenantComponentProps ) {

    const [actions, setActions] = useState<ListAsideAction[]>([]);

    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
        }

        setActions(actions);
    }, []);

    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions}/>
        </>
    )
}
