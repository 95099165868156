import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {CertificationsService, ICertificationGetApiResponse} from "../Services/Certifications.service";
import {ICertification} from "../Models/Certification.model";



export default function useCertifications(){
    const certificationsService: CertificationsService = new CertificationsService();
    const history = useHistory();

    /**
     * Récupération d'une certification à partir de son id
     *
     * @param {string} cid
     * @param {(certification: ICertification) => void} callback
     * @param {() => void} callbackError
     */
    const getCertificationById = (cid: string, callback:(certification: ICertification) => void, callbackError?: () => void ) => {
        certificationsService.getCertification(cid).then((response: ICertificationGetApiResponse) => {
            if( response && response.datas.certification){
                if(callback){
                    callback(response.datas.certification);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathCertifications);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la certification",type: "danger"});
        });
    };

    return [getCertificationById];
};

