import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {ILabelGetApiResponse, LabelsService} from "../Services/Labels.service";
import {ILabel} from "../Models/Label.model";



export default function UseLabels(){
    const labelsService: LabelsService = new LabelsService();
    const history = useHistory();

    /**
     * Récupération d'un label à partir de son id
     *
     * @param {string} lid
     * @param {(label: ILabel) => void} callback
     * @param {() => void} callbackError
     */
    const getLabelById = (lid: string, callback:(label: ILabel) => void, callbackError?: () => void ) => {
        labelsService.getLabel(lid).then((response: ILabelGetApiResponse) => {
            if( response && response.datas.label){
                if(callback){
                    callback(response.datas.label);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathLabels);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du label",type: "danger"});
        });
    };

    return [getLabelById];
};

