import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, { useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IFilInformations} from "src/Models/FilInformations.model";
import {FilInformationsService} from "src/Services/FilInformations.service";


type IAsideFilInformationsComponentProps = {
    filInformations?: IFilInformations,
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void
}

export default function AsideFilInformationsComponent(props: IAsideFilInformationsComponentProps ) {
    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [showArchivesConfirmation, setShowArchivesConfirmation] = useState<boolean>();


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-fil-infos-add", click: () => history.push(AppConstants.pathFilInformations + "/creation")});
        }

        if( props.type === "fiche"){
            actions.push({icon: "icon-archives-save", click: () => setShowArchivesConfirmation(true)});
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        }

        setActions(actions);
    }, []);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    };


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const filInformationsService: FilInformationsService = new FilInformationsService();

        filInformationsService.delete(props.filInformations.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le fil d'informations a bien été supprimé.",
                type: "success"
            });

            history.push(AppConstants.pathFilInformations);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du fil d'informations.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    };


    /**
     * Archivage du fil d'information
     */
    const doArchive = (): void => {
        const filInformationsService: FilInformationsService = new FilInformationsService();

        filInformationsService.archive(props.filInformations.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le fil d'informations a bien été archivé.",
                type: "success"
            });

            history.push(AppConstants.pathFilInformations);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'archivage du fil d'informations.",
                    type: "danger"
                });
            }
        });
        setShowArchivesConfirmation(false);
    };


    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    };


    return (
        <>
            {
                showConfirmation && props.filInformations &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer ce fil d'informations ?" />
            }
            {
                showArchivesConfirmation && props.filInformations &&
                <ConfirmationComponent onConfirm={doArchive} onCancel={() => setShowArchivesConfirmation(false)} text="Souhaitez-vous vraiment archiver ce fil d'informations ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions}/>
        </>
    )
}
