import React from "react";
import {Link} from "react-router-dom";
import {AppConstants} from "src/Constants/AppConstants";

export type IBreadcrumbLink = {
    link?: string,
    text: string
}

const BreadcrumbsComponent = (props: {links: IBreadcrumbLink[], separator: string}) => {
    return <div className="breadcrumbs-block">
        <Link to={AppConstants.pathHome} className="link">Accueil</Link>
        {
            props.links && props.links.map((l,lIndex)=><React.Fragment key={lIndex}>
                <span className="sep">{props.separator}</span>
                {l.link && <Link to={l.link} className="link">{l.text}</Link>}
                {!l.link && <span className="text">{l.text}</span>
            }

        </React.Fragment>)}
    </div>
};

export default BreadcrumbsComponent;
