import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IReclamation} from "src/Models/Reclamation.model";
import {IReclamationGetApiResponse, IReclamationInformationsFormData} from "src/Services/Reclamations.service";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import {INotaire} from "src/Models/Notaire.model";
import {NotairesService} from "src/Services/Notaires.service";
import DateHelper from "src/Helpers/Date.helper";
import NotairesHelper from "src/Helpers/Notaires.helper";


type IReclamationInformationsFormComponentProps = {
    reclamation?: IReclamation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IReclamationInformationsFormData) => Promise<IReclamationGetApiResponse>,
    onSuccessSubmit: (response: IReclamationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IReclamationGetApiResponse) => void,
    onCancel: () => void,
}

export default function ReclamationInformationsFormComponent(props: IReclamationInformationsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    const [notairesSyndicLoaded, setNotairesSyndicLoaded] = useState<boolean>(false);
    const [notairesSyndic, setNotairesSyndic] = useState<INotaire[]>([]);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();
        notairesService.getSyndic().then((response) => {
            if (response && response.datas && response.datas.notaires) {
                setNotairesSyndic(response.datas.notaires);
                setNotairesSyndicLoaded(true);
            }
        });
    }, []);



    useEffect(() => {
        const reclamation: IReclamation = props.reclamation;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "numeroDossier",

                label: "Numéro de dossier",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.numeroDossier ? reclamation.numeroDossier : "",
            },
            {
                type: "select",
                fieldName: "objet",

                label: "Objet",
                hideSearchOnMultiple: true,
                required: true,

                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.objet ? reclamation.objet : "",
                options: ReclamationsConstants.objetOptions,
            },
            {
                type: "select",
                fieldName: "etat",

                label: "Etat",
                hideSearchOnMultiple: true,
                required: true,

                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.etat ? reclamation.etat : "",
                options: ReclamationsConstants.etatOptions,
            },
            {
                type: "select",
                fieldName: "etatAutre",

                label: "Motif",
                hideSearchOnMultiple: true,
                required: true,

                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.etatAutre ? reclamation.etatAutre : "",
                options: ReclamationsConstants.etatAutreOptions,

                condition: {
                    fieldName: "etat",
                    value: "autre",
                }
            },
            {
                type: 'date',
                fieldName: "dateEnvoiSyndic",

                label: "Date d'envoi au syndic",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateEnvoiSyndic ? DateHelper.getFormatedDateForInput(reclamation.dateEnvoiSyndic) : "",
            },
            {
                type: 'date',
                fieldName: "dateLimiteInitial1erRappel",

                //label: "Date limite du 1er rappel",
                label: "Date limite convocation conciliation",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateLimiteInitial1erRappel ? DateHelper.getFormatedDateForInput(reclamation.dateLimiteInitial1erRappel) : "",
            },
            /*
            {
                type: 'date',
                fieldName: "date1erRappel",

                label: "Date 1er rappel",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.date1erRappel ? DateHelper.getFormatedDateForInput(reclamation.date1erRappel) : "",
            },
            {
                type: 'number',
                fieldName: "penalitesEncourues",

                label: "Pénalités encourues",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.penalitesEncourues ? reclamation.penalitesEncourues.toString() : "",
            },
            */
            {
                type: 'text',
                fieldName: "note",

                label: "Note",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.note ? reclamation.note : "",
            },

            {
                type: "select",
                fieldName: "syndicInitialId",

                label: "Syndic",
                modificators: props.isOnCreationStep ? "-on-white" : '',
                hideSearchOnMultiple: true,

                oldValue: reclamation && reclamation.syndicInitial ? reclamation.syndicInitial.id.toString() : "",
                options: notairesSyndic ? NotairesHelper.formatListForOptions(notairesSyndic) : [],
            },
            {
                type: "select",
                fieldName: "repriseDossierId",

                label: "Reprise du dossier",
                modificators: props.isOnCreationStep ? "-on-white" : '',
                hideSearchOnMultiple: true,

                oldValue: reclamation && reclamation.repriseDossier ? reclamation.repriseDossier.id.toString() : "",
                options: notairesSyndic ? NotairesHelper.formatListForOptions(notairesSyndic) : [],
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateReception",

                label: "Date de réception",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateReception ? DateHelper.getFormatedDateForInput(reclamation.dateReception) : "",
            },

            {
                type: "select",
                fieldName: "fondement",

                label: "Motif invoqué par le réclamant",
                hideSearchOnMultiple: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.fondement ? reclamation.fondement : "",
                options: ReclamationsConstants.fondementsOption,
            },
            {
                type: "select",
                fieldName: "origine",

                label: "Origine",
                hideSearchOnMultiple: true,
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.origine ? reclamation.origine : "",
                options: ReclamationsConstants.origineOptions,
            },
            {
                type: 'date',
                fieldName: "dateRetourSyndic",

                label: "Date retour du syndic",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateRetourSyndic ? DateHelper.getFormatedDateForInput(reclamation.dateRetourSyndic) : "",
            },
            /*
            {
                type: 'date',
                fieldName: "dateLimiteAccordee",

                label: "Date limite convocation",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateLimiteAccordee ? DateHelper.getFormatedDateForInput(reclamation.dateLimiteAccordee) : "",
            },
             */
            {
                type: 'date',
                fieldName: "dateDemandeExplication",

                label: "Date demande d'explication",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateDemandeExplication ? DateHelper.getFormatedDateForInput(reclamation.dateDemandeExplication) : "",
            },
            /*
            {
                type: 'number',
                fieldName: "penalitesRetenues",

                label: "Pénalités retenues",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.penalitesRetenues ? reclamation.penalitesRetenues.toString() : "",
            }
             */
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateModification",

                label: "Date de modification",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateModification ? DateHelper.getFormatedDateForInput(reclamation.dateModification) : "",
            },
            /*
            {
                type: 'text',
                fieldName: "precision",

                label: "Précision sur le fondement",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.precision ? reclamation.precision : "",
            },
            {
                type: 'datetime-local',
                withTime: true,
                fieldName: "dateConvocationBureau",

                label: "Date de convocation devant le bureau",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateConvocationBureau ? DateHelper.getFormatedDateTimeForInput(reclamation.dateConvocationBureau) : "",
            },
             */
            {
                type: 'text',
                fieldName: "reference",

                label: "Référence",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.reference ? reclamation.reference : "",
            },
            {
                type: 'date',
                fieldName: "dateClotureDossier",

                label: "Date de cloture du dossier",
                required: false,
                //disabled: (reclamation && reclamation.etat && reclamation.etat === 'action-terminee') ? false : true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateClotureDossier ? DateHelper.getFormatedDateTimeForInput(reclamation.dateClotureDossier) : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);


    }, [notairesSyndic]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IReclamationInformationsFormData).then((response: IReclamationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La modification a bien été prise en compte.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive de la réclamation.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                notairesSyndicLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }
        </div>
    )

}
