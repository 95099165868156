import {IAdresse} from "../Models/Adresse.model";
import {MapMarker} from "../Components/Map/Map.component";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";
import OrganismesHelper from "./Organismes.helper";
import {IOffice} from "../Models/Office.model";
import {IOrganisme} from "../Models/Organisme.model";

export default class  MapHelper{

    /**
     * Permet la création du text d'un marker
     *
     * @param {string} path
     * @param {string} name
     * @param {IAdresse} adresse
     * @returns {string}
     * @private
     */
    public static createMapMarkerText(path: string, name: string, adresse: IAdresse): string{
        return `<a href=${path} class="link">
            <h3 class="title">${name}</h3>
            
            ${this.createMapMarkerTextAdresse(adresse)}
        </a>`
    }


    /**
     * Permet la création du text d'un marker notaire
     *
     * @param {string} path
     * @param {string} name
     * @param {IAdresse} adresse
     * @param {IOffice} office
     * @returns {string}
     */
    public static createMapMarkerTextNotaire(path: string, name: string, adresse: IAdresse, office: IOffice): string{
        return `<a href=${path} class="link">
            <h3 class="title">${name}</h3>

            ${office ? `<h4 class="subtitle">${office.nom}</h4>` : ''}
           
            ${this.createMapMarkerTextAdresse(adresse)}
        </a>`
    }


    /**
     * Permet la création du text d'un marker Organisme
     *
     * @param {string} path
     * @param {string} name
     * @param {IAdresse} adresse
     * @param {IPersonneExterieure[]} personnesExterieures
     * @returns {string}
     */
    public static createMapMarkerTextOrganisme(path: string, name: string, adresse: IAdresse, personnesExterieures: IPersonneExterieure[]): string{
        return `<a href="${path}" class="link">
                <h3 class="title">${name}</h3>
                
                ${this.createMapMarkerTextAdresse(adresse)}

                ${personnesExterieures.length > 0 ? OrganismesHelper.getPersonnesExterieuresRattacheesHtml(personnesExterieures) : ''}
        </a>`
    }

    /**
     * Permet la création du text d'un marker Personne extérieure
     * @param {string} path
     * @param {string} name
     * @param {IAdresse} adresse
     * @param {IOrganisme} organime
     * @returns {string}
     */
    public static createMapMarkerTextPersonneExterieure(path: string, name: string, adresse: IAdresse, organime: IOrganisme): string{
        return `<a href="${path}" class="link">
                <h3 class="title">${name}</h3>
                
                ${organime ? `<h4 class="subtitle">${organime.nom}</h4>` : ''}
                
                ${this.createMapMarkerTextAdresse(adresse)}
        </a>`
    }


    /**
     * Création de la partie adresse d'un marker
     *
     * @param {IAdresse} adresse
     * @returns {string}
     * @private
     */
    private static createMapMarkerTextAdresse(adresse: IAdresse): string{
        return `<p class="text">
            ${adresse.ligne1} ${adresse.ligne2 ?? ""}<br>
            ${adresse.codePostal} ${adresse.ville}
            ${adresse.boitePostale ? `<br>BP ${adresse.boitePostale}` : ''}
        </p>`
    }

    /**
     * Permet la création d'un marker
     *
     * @param {string} markerText
     * @param {IAdresse} adresse
     * @returns {MapMarker}
     */
    public static createMapMarker(markerText: string, adresse: IAdresse): MapMarker{
        return {
            lat: adresse.latitude,
            lng: adresse.longitude,
            text: markerText
        }
    }


}
