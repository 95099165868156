import React from "react";
import {Link} from "react-router-dom";

const SearchResultComponent = (props : {
    illustration?: string,
    title: string,
    subtitle?: string,
    texts?: string[],
    link: string
}) => {
    return <Link to={props.link} className="search-result-row">
        {props.illustration && <figure className="illustration">
            <div style={{backgroundImage:`url(${props.illustration})`}} className="picture"/>
        </figure>}
        <div className="content">
            <h3 className="title">{props.title}</h3>
            {props.subtitle && <p className="subtitle">{props.subtitle}</p>}
            {props.texts && props.texts.map((t,tIndex)=> <React.Fragment key={tIndex}>{t && <p className="text" key={tIndex}>{t}</p>}</React.Fragment>)}
        </div>
    </Link>
};

export default SearchResultComponent;
