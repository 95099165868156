import ExportComponent from "src/Components/Exports/Export.component";
import React, {useEffect, useState} from "react";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {FormColumn} from "../Forms/FormCreator.component";

type ReclamationsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    listType: string,
    filters?: any,
}
export default function ReclamationsExportComponent(props: ReclamationsExportComponentProps) {
    const [formColumns, setFormClumns] = useState<FormColumn[]>([]);

    useEffect(() => {
        if (!props.showExport || !props.listType) return null;

        let newFormColumns: FormColumn[] = [];
        if(props.listType === TabsSlugs.EN_COURS || props.listType === TabsSlugs.AUTRES || props.listType === TabsSlugs.ARCHIVAGE){
            newFormColumns = [
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-reclamations',
                            fieldName: "reclamations",
                            label: "Réclamation",
                            options: [
                                {value: 'numeroDossier', label: 'Numéro de dossier'},
                                {value: 'syndic', label: 'Syndic'},
                                {value: 'repriseDossier', label: 'Reprise du dossier'},
                                {value: 'objet', label: 'Objet'},
                                {value: 'fondement', label: 'Motif invoqué par le réclamant'},
                                //{value: 'precision', label: 'Précision sur le fondement'},
                                {value: 'etat', label: 'Etat'},
                                {value: 'etatAutre', label: 'Motif (état : autre)'},
                                {value: 'origine', label: 'Origine'},
                                {value: 'reference', label: 'Référence'},
                                {value: 'nomClient', label: 'Nom du client'},
                                //{value: 'penalitesEncourues', label: 'Pénalités encourues'},
                                //{value: 'penalitesRetenues', label: 'Pénalités retenues'},
                                {value: 'note', label: 'Note'},

                            ]
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-plaignant',
                            fieldName: "plaignantGroup",
                            label: "Plaignant(s)",
                            options: [
                                {value: 'plaignant', label: 'Informations du plaignant'},
                                {value: 'plaignantTypePersonne', label: 'Type de personne du plaignant'},
                                {value: 'plaignantNationalite', label: 'Nationalité du plaignant'},
                                {value: 'plaignantDateNaissance', label: 'Date de naissance du plaignant'},
                                {value: 'plaignantLieuNaissance', label: 'Lieu de naissance du plaignant'},
                                {value: 'plaignantProfession', label: 'Profession du plaignant'},
                                {value: 'plaignantStructure', label: 'Structure du plaignant'},
                                {value: 'plaignantAdresse', label: 'Adresse(s) du plaignant'},
                                {value: 'plaignantEmail', label: 'Email(s) du plaignant'},
                                {value: 'plaignantTelephone', label: 'Téléphone(s) du plaignant'},
                            ]
                        },
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-notaires',
                            fieldName: "notaireGroup",
                            label: "Notaire(s)",
                            options: [
                                {value: 'notaire', label: 'Notaire'},
                                {value: 'crpcenNotaire', label: 'CRPCEN du notaire'},
                            ]
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-calendar',
                            fieldName: "date",
                            label: "Dates",
                            options: [
                                {value: 'dateReception', label: 'Date de réception'},
                                {value: 'dateModification', label: 'Date de modification'},
                                //{value: 'dateConvocationBureau', label: 'Date de convocation devant le bureau'},

                                {value: 'dateEnvoiSyndic', label: 'Date d\'envoi au syndic'},
                                {value: 'dateRetourSyndic', label: 'Date retour du syndic'},
                                {value: 'dateLimiteInitial1erRappel', label: 'Date limite du 1er rappel'},
                                //{value: 'dateLimiteAccordee', label: 'Date limite convocation'},
                                //{value: 'date1erRappel', label: 'Date 1er rappel'},
                                {value: 'dateDemandeExplication', label: 'Date demande d\'explication'},
                                {value: 'dateClotureDossier', label: 'Date de cloture du dossier'},

                                {value: 'delai', label: 'Délai'},
                            ]
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-commentaires',
                            fieldName: "conciliation",
                            label: "Conciliation",
                            options: [
                                {value: 'conciliationDate', label: 'Date conciliation'},
                                {value: 'conciliationHeure', label: 'Heure conciliation'},
                                {value: 'conciliation', label: 'Conciliation'},
                                {value: 'conciliationIssue', label: 'Issue de la conciliation'},
                                {value: 'conciliationMotif', label: 'Motif'},
                            ]
                        },
                    ]
                }
            ]
        }
        else if(props.listType === TabsSlugs.PENALITES){
            newFormColumns = [
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-reclamations',
                            fieldName: "reclamations",
                            label: "Réclamation",
                            options: [
                                {value: 'numeroDossier', label: 'Numéro de dossier'},
                                {value: 'syndic', label: 'Syndic'},
                                {value: 'repriseDossier', label: 'Reprise du dossier'},
                                {value: 'objet', label: 'Objet'},
                                {value: 'fondement', label: 'Motif invoqué par le réclamant'},
                                //{value: 'precision', label: 'Précision sur le fondement'},
                                {value: 'etat', label: 'Etat'},
                                {value: 'origine', label: 'Origine'},
                                {value: 'reference', label: 'Référence'},
                                {value: 'nomClient', label: 'Nom du client'},
                                {value: 'note', label: 'Note'},
                            ]
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-plaignant',
                            fieldName: "plaignantGroup",
                            label: "Plaignant(s)",
                            options: [
                                {value: 'plaignant', label: 'Plaignant'},
                                {value: 'plaignantAdresse', label: 'Adresse(s) du plaignant'},
                                {value: 'plaignantEmail', label: 'Email(s) du plaignant'},
                                {value: 'plaignantTelephone', label: 'Téléphone(s) du plaignant'},
                            ]
                        },
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-notaires',
                            fieldName: "notaireGroup",
                            label: "Notaire(s)",
                            options: [
                                {value: 'notaire', label: 'Notaire'},
                                {value: 'crpcenNotaire', label: 'CRPCEN du notaire'},
                            ]
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-calendar',
                            fieldName: "date",
                            label: "Date",
                            options: [
                                {value: 'dateReception', label: 'Date de réception'},
                                {value: 'dateModification', label: 'Date de modification'},
                                //{value: 'dateConvocationBureau', label: 'Date de convocation devant le bureau'},

                                {value: 'dateEnvoiSyndic', label: 'Date d\'envoi au syndic'},
                                {value: 'dateRetourSyndic', label: 'Date retour du syndic'},
                                {value: 'dateLimiteInitial1erRappel', label: 'Date limite du 1er rappel'},
                                //{value: 'dateLimiteAccordee', label: 'Date limite convocation'},
                                //{value: 'date1erRappel', label: 'Date 1er rappel'},
                                {value: 'dateDemandeExplication', label: 'Date demande d\'explication'},
                                {value: 'dateClotureDossier', label: 'Date de cloture du dossier'},

                                {value: 'nombreJours', label: 'Nombre de jours'},
                            ]
                        },
                        {
                            type: 'checkboxGroup',
                            icon: 'icon-penalites',
                            fieldName: "penalites",
                            label: "Penalités",
                            options: [
                                {value: 'penalitesEncourues', label: 'Pénalités encourues'},
                                {value: 'penalitesRetenues', label: 'Pénalités retenues'},
                                {value: 'envoi', label: 'Envoi'},
                            ]
                        },
                    ]
                }
            ]
        }

        setFormClumns(newFormColumns);
    }, [props.showExport, props.listType])



    if (!props.showExport) return null;


    return (
        <ExportComponent title={"Réclamations"}
                         icon="icon-reclamations"
                         subTitle={"Export des réclamations"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl= {`api${AppConstants.pathReclamations}/type/${props.listType}/export`}
                         columns={formColumns}
        />
    )
}
