import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type PersonnesExterieuresExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
}
export default function PersonnesExterieuresExportComponent(props: PersonnesExterieuresExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Personnes Extérieures"}
                         icon="icon-personne-exterieure"
                         subTitle={"Export des personnes extérieures"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl={`api${AppConstants.pathPersonnesExterieures}/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-personne-exterieure',
                                         fieldName: "identite",
                                         label: "Identité",
                                         options: [
                                             {value: 'sexe', label: 'Sexe'},
                                             {value: 'nom', label: 'Nom'},
                                             {value: 'prenom', label: 'Prénom'},
                                             {value: 'fonction', label: 'Fonction'},
                                             {value: 'civilite', label: 'Formule d\'appel'},
                                             {value: 'formulePolitesse', label: 'Formule de politesse'},
                                             {value: 'agrementComptabilite', label: 'Agrément comptabilité'},
                                             {value: 'civiliteCourte', label: 'Civilité (Bloc adresse)'},
                                             {value: 'autres.groupes', label: 'Groupes'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-effectif',
                                         fieldName: "personnes",
                                         label: "Coordonnées d’un extérieur",
                                         options: [
                                             {value: 'coordonnees.emailPrincipal', label: 'Email Principal'},
                                             {value: 'coordonnees.emailBureau', label: 'Email Bureau'},
                                             {value: 'coordonnees.emailComptabilite', label: 'Email Comptabilité'},
                                             {value: 'coordonnees.emailPerso', label: 'Email Perso'},
                                             {value: 'coordonnees.emailConvocation', label: 'Email Convocation'},
                                             {value: 'coordonnees.telephoneLigneDirecte', label: 'Téléphone Ligne directe'},
                                             {value: 'coordonnees.telephoneDomicile', label: 'Téléphone Domicile'},
                                             {value: 'coordonnees.telephoneMobile', label: 'Téléphone Mobile'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-building',
                                         fieldName: "organisme",
                                         label: "Informations de l'organisme",
                                         options: [
                                             {value: 'organismes.nomOrganisme', label: 'Nom'},
                                             {value: 'adresses.ligne1', label: 'Rue'},
                                             {value: 'adresses.ligne2', label: 'Complément d\'adresse'},
                                             {value: 'adresses.boitePostale', label: 'Boîte Postale'},
                                             {value: 'adresses.codePostal', label: 'Code Postal / CEDEX'},
                                             {value: 'adresses.ville', label: 'Ville'},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
