import {IOption} from "src/Components/Fields/Select.field.component";
import {AdresseConstants} from "../Constants/AdresseConstants";
import CommonHelper from "./Common.helper";

export default class AdressesHelper{

    /**
     * Permet de récupérer l'arrondissement formaté
     *
     * @param {string} arrondissement
     * @returns {string}
     */
    static getFormatedArrondissement(arrondissement: string): string {
        return CommonHelper.getFormatedElementFromOptions(arrondissement, AdresseConstants.arrondissementsOptions);
    }

    /**
     * Permet de récupérer le tribunal à partir de l'arrondissement
     *
     * @param {string} arrondissement
     * @returns {string}
     */
    static getTribunalFromArrondissement(arrondissement: string): string{
        let tribunal: string = "";

        switch(arrondissement){
            case "bayeux":
            case "caen":
            case "vire":
                tribunal = "caen";
                break;

            case "argentan":
                tribunal = "argentan";
                break;

            case "lisieux":
                tribunal = "lisieux";
                break;

            case "avranche":
            case "saintLo":
                tribunal = "coutances";
                break;

            case "alencon":
                tribunal = "alencon";
                break;

            case "cherbourg":
                tribunal = "cherbourg";
                break;
        }

        return tribunal;
    }


    /**
     * Permet de récupérer le type formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedType(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type,AdresseConstants.typeOptions);
    }



    /**
     * Permet de récupérer le tribunal judiciaire
     *
     * @param {string} tribunal
     * @returns {string}
     */
    static getFormatedTribunal(tribunal: string): string {
        return CommonHelper.getFormatedElementFromOptions(tribunal, AdresseConstants.tribunalJudiciaireOptions);
    }

}
