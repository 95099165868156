import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {IParametre} from "src/Models/Parametre.model";
import {ParametresService} from "src/Services/Parametres.service";
import {AppConstants} from "src/Constants/AppConstants";
import useListExportPDF from "../../Hooks/UseListExportPDF";

type IAsideParametresComponentProps = {
    modificators?: string,
    type: "list" | "fiche",
    parametre?: IParametre,

    exportCallback?: () => void,
    filters?: any,
    module?: string,
}

export default function AsideParametresComponent(props: IAsideParametresComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const {isSuperAdmin} = useContext(UserContext) as IUserContext;
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];


        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
        }
        else{
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        if( isSuperAdmin){
            if( props.type === "list") {
                actions.push({icon: "icon-export", click: () => props.exportCallback()});
            }

            if( props.type === "fiche" && props.parametre){
                actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
            }
        }

        setActions(actions);
    }, [props.filters]);


    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const parametresService: ParametresService = new ParametresService();

        parametresService.delete(props.parametre.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le paramètre a bien été supprimé.",
                type: "success"
            });

            if(props.parametre.module === "formations"){
                history.push(AppConstants.pathParametresFormations);
            }
            else{
                history.push(AppConstants.pathParametres);
            }
        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du paramètre.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }


    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url: string = props.module ? `api${AppConstants.pathParametres}/export-pdf/${props.module}` : `api${AppConstants.pathParametres}/export-pdf` ;


        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            {
                showConfirmation && props.parametre &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer ce paramètre ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading} />
        </>
    )
}
