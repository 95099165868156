import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {IGroupe} from "../../../Models/Groupe.model";

type GroupesListExportComponentProps = {
    groupe: IGroupe,
    setShowExport: () => void,
    showExport: boolean,
}
export default function GroupesListExportComponent(props: GroupesListExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Groupe"}
                         icon="icon-groupes"
                         subTitle={"Export de la liste du groupe"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl= {`api${AppConstants.pathGroupes}/${props.groupe.id}/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-formations',
                                         fieldName: "informations",
                                         label: "Informations",
                                         options: [
                                             {value: 'civilite', label: 'Civilité'},
                                             {value: 'nom', label: 'Nom'},
                                             {value: 'prenom', label: 'Prénom'},
                                             {value: 'email', label: 'Email'},
                                             {value: 'type', label: 'Type'},
                                             {value: 'fonction', label: 'Fonction'},
                                             {value: 'organisme-etude', label: 'Organisme / Etude'},
                                             {value: 'adresse', label: 'Adresse'},
                                             {value: 'codePostal', label: 'Code Postal'},
                                             {value: 'ville', label: 'Ville'},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
