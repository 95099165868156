import React, {useEffect, useState} from "react";
import LoadingComponent from "../Loading/Loading.component";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import FicheReactPdfComponent from "../Exports/FicheReactPdfComponent";

export type ListAsideAction = {
    icon: string,
    name?: string,
    click?: (filters?: any) => void,
    filename?: string,
    title?: string
}
export type IListAsideComponentProps = {
    actions: ListAsideAction[],
    modificators?: string,
    isLoading?: boolean
    filters?: any
}


const ListAsideComponent = (props: IListAsideComponentProps) => {
    const [actions, setActions] = useState<ListAsideAction[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const pdfViewerDebug = false;

    useEffect(() => {
        if (props.actions) {
            setActions(props.actions);
        }
    }, [props.actions])

    useEffect(() => {

        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    return (
        <>
            <div className={`list-aside ${props.modificators || ''}`}>
                {
                    isLoading &&
                    <LoadingComponent/>
                }

                {
                    actions && actions.map((a, index) => {
                        return (
                            a.name === "print-pdf" ?
                                <PDFDownloadLink key={index} document={<FicheReactPdfComponent/>}
                                                 fileName={`${a.filename ? a.filename : "fiche"}.pdf`} className="button">
                                    <i className={`icon ${a.icon}`}/>
                                </PDFDownloadLink>
                                :
                                <button key={index} onClick={() => a.click(props.filters)} className={`button`} title={a.title || ''}>
                                    <i className={`icon ${a.icon}`}/>
                                </button>)
                    })
                }
            </div>
        </>
    )

};

export default ListAsideComponent;
