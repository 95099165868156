import React, {useEffect, useState} from "react";
import {FilInformationsService} from "../../Services/FilInformations.service";
import LoadingComponent from "../Loading/Loading.component";
import { Doughnut } from 'react-chartjs-2';
import {Pie} from "react-chartjs-2/dist";

export type IFilInformationsStats = {
    EventClickDelay: number,
    EventClickedCount: number,
    EventOpenDelay: number,
    EventOpenedCount: number,
    EventSpamCount: number,
    EventUnsubscribedCount: number,
    EventWorkflowExitedCount: number,
    MessageBlockedCount: number,
    MessageClickedCount: number,
    MessageDeferredCount: number,
    MessageHardBouncedCount: number,
    MessageOpenedCount: number,
    MessageQueuedCount: number,
    MessageSentCount: number,
    MessageSoftBouncedCount: number,
    MessageSpamCount: number,
    MessageUnsubscribedCount: number,
    MessageWorkFlowExitedCount: number,
    Total: number,
};
const FilInformationsStats = (props: {}) => {

    const filInformationsService: FilInformationsService = new FilInformationsService();
    const [stats,setStats] = useState<IFilInformationsStats>(null);
    const [loaded,setLoaded] = useState<boolean>(false);
    const [chartDatas,setChartDatas] = useState(null);
    const [chartSendDatas,setChartSendDatas] = useState(null);

    useEffect(()=>{
        filInformationsService.getStats().then((result)=>{
            if(result.datas && result.datas.stats) {
                const currentStats = result.datas.stats;
                setStats(currentStats);
                const data = {
                    labels: ['Non ouverts', 'Ouverts et non cliqués', 'Ouverts et cliqués'],
                    datasets: [
                        {
                            //data: [currentStats.Total-currentStats.MessageOpenedCount, currentStats.MessageOpenedCount, currentStats.MessageClickedCount],
                            //data: [currentStats.Total-currentStats.MessageOpenedCount, currentStats.MessageOpenedCount - currentStats.MessageClickedCount, currentStats.MessageClickedCount],
                            data: [currentStats.Total-currentStats.MessageOpenedCount, currentStats.MessageOpenedCount - currentStats.MessageClickedCount, currentStats.MessageClickedCount],
                            backgroundColor: ["#171F46", "#DEB578", "#72CBE6"]
                            //backgroundColor: ["#DEB578","#171F46","#72CBE6"]
                        }
                    ]
                };

                const sendDatas = {
                    labels: ['Envoyés', 'Rebonds', 'En attente', 'Spam', 'Bloqués'],
                    datasets: [
                        {
                            data: [
                                currentStats.MessageSentCount,
                                currentStats.MessageHardBouncedCount+currentStats.MessageSoftBouncedCount,
                                currentStats.MessageQueuedCount,
                                currentStats.MessageSpamCount,
                                currentStats.MessageBlockedCount,
                            ],
                            backgroundColor: ["#74b265","#DEB578","#171F46","#9b6565","#F5658C"]
                        }
                    ]
                }

                setChartDatas(data);
                setChartSendDatas(sendDatas);
            }
            setLoaded(true);
        });
    },[]);

    return <>
        {!loaded && <LoadingComponent/>}
        {loaded && <>
            {stats && <div className="accordion-content g-content informations-wrapper">

	            <div className="columns">
                    <p className="title -size 2">Statistiques du dernier fil d'infos</p>
                    <div className="column">
	                    <Pie data={chartDatas} options={{plugins:{legend:{display:false}}}} />
                    </div>
		            <div className="column -max-150">
                        <div className="informations-block">
                            <p className="label">Total</p>
                            <p className="value">{stats.Total}</p>
                        </div>
                        <div className="informations-block">
                            <p className="label -with-square"><span style={{backgroundColor: "#171F46"}} className="square"></span>Non Ouverts</p>
                            <p className="value">{stats.Total-stats.MessageOpenedCount}</p>
                        </div>
                        <div className="informations-block">
                            <p className="label -with-square"><span style={{background: "linear-gradient(90deg, #72CBE6 50%, #DEB578 50%)"}} className="square"></span>Ouverts</p>
                            <p className="value">{stats.MessageOpenedCount}</p>
                        </div>
                        <div className="informations-block">
                            <p className="label -with-square"><span style={{backgroundColor: "#72CBE6"}} className="square"></span>Ouverts et cliqués</p>
                            <p className="value">{stats.MessageClickedCount}</p>
                        </div>
                        <div className="informations-block">
                            <p className="label -with-square"><span style={{backgroundColor: "#DEB578"}} className="square"></span>Ouverts et non cliqués</p>
                            <p className="value">{stats.MessageOpenedCount - stats.MessageClickedCount}</p>
                        </div>
		            </div>

                    {/*
                    <div className="column">
                        <Pie data={chartSendDatas} options={{plugins:{legend:{display:false}}}} />
                    </div>


		            <div className="column -max-150">
                        <div className="informations-block">
                            <p className="label">Total</p>
                            <p className="value">{stats.Total}</p>
                        </div>
                        <div className="informations-block">
                            <p className="label">Envoyés</p>
                            <p className="value">{stats.MessageSentCount}</p>
                        </div>
			            <div className="informations-block">
				            <p className="label">Rebonds</p>
				            <p className="value">{stats.MessageHardBouncedCount+stats.MessageSoftBouncedCount}</p>
			            </div>
                        <div className="informations-block">
                            <p className="label">En fil d'attente</p>
                            <p className="value">{stats.MessageQueuedCount}</p>
                        </div>
                        <div className="informations-block">
                            <p className="label">Spam</p>
                            <p className="value">{stats.MessageSpamCount}</p>
                        </div>

                        <div className="informations-block">
                            <p className="label -with-square"><span style={{backgroundColor: "#F5658C"}} className="square"></span>Bloqués</p>
                            <p className="value">{stats.MessageBlockedCount}</p>
                        </div>
		            </div>
		            */}
	            </div>
            </div>}
            {!stats && <div className="accordion-content g-content informations-wrapper">
                Aucune statistique
            </div>}
        </>}
    </>;
};

export default FilInformationsStats;
