import React from "react";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import {IFilInformations} from "../../Models/FilInformations.model";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";


const FilInformationsCVs = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.cvs.map((cv,cvIndex) =>
                    <div className="columns" key={cvIndex}>
                        <p className="title">{cv.titre}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Secteur</p>
                                <div className="value">
                                    <CommentairesComponent commentaires={cv.secteur || "-"}/>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Période</p>
                                <div className="value">
                                    <CommentairesComponent commentaires={cv.periode || "-"}/>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Document</p>
                                {
                                    cv.document &&
                                    <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(cv.document)} />
                                }
                                {!cv.document && <>-</>}
                            </div>
                        </div>
                    </div>
                )
            }
            {props.filInformations.cvs.length === 0 && <p>-</p>}
        </div>
    )
};

export default FilInformationsCVs;
