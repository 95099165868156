import {IUser} from "src/Models/User.model";
import UserHelper from "src/Helpers/User.helper";
import {UserConstants} from "src/Constants/UsersConstants";
import {useContext} from "react";
import {IUserContext, UserContext} from "../../Providers/User.provider";

type IUserInformationsComponentProps = {
    user: IUser,
}

export default function UserInformationsComponent(props: IUserInformationsComponentProps){
    const user: IUser = props.user;
    const {isAdmin, isSuperAdmin} = useContext(UserContext) as IUserContext;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{user.nom}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Email</p>
                        <p className="value">{user.email}</p>
                    </div>

                    {
                        ((isAdmin() || isSuperAdmin() ) && user.role === UserConstants.roles.contributor) &&
                        <div className="informations-block">
                            <p className="label">Modules</p>
                            <p className="value">{UserHelper.getFormatedAllowedModules(user.modules)}</p>
                        </div>
                    }
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Prénom</p>
                        <p className="value">{user.prenom}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Rôle</p>
                        <p className="value">{UserHelper.getFormatedRole(user.role)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
