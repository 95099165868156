import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type IntervenantsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
}
export default function IntervenantsExportComponent(props: IntervenantsExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Intervenants"}
                         icon="icon-formations"
                         subTitle={"Export des intervenants"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl= {`api${AppConstants.pathFormations}/intervenants/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-formations',
                                         fieldName: "informations",
                                         label: "Informations",
                                         options: [
                                             {value: 'personne', label: 'Personne'},
                                             {value: 'nb_formations_effectuees', label: 'Nombre de formations effectuées'},
                                             {value: "derniere_formation", label: "Dernière formation"},
                                             {value: "nb_formations_a_venir", label: "Nombre de formations à venir"},
                                             {value: "prochaine_formation", label: "Prochaine formation"},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
