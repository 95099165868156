import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import useListFilters from "src/Hooks/FiltersHandler";
import {AutresContactsService, IAutresContactsGetFormData, IOrganismeUpdatePersonnesFormData, IPersonnesExterieuresGetApiResponse} from "src/Services/AutresContacts.service";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {IApiPaginationLink} from "src/Services/Api.service";
import SearchBlockComponent, {IFormSearchData} from "src/Components/Search/SearchBlock.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {IOrganisme} from "src/Models/Organisme.model";
import useOrganismes from "src/Hooks/UseOrganismes";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";

type OrganismeAjoutPersonnesProps = {
    oid: string
};

export default function OrganismeAjoutPersonnesScreen(props: OrganismeAjoutPersonnesProps){
    const [getOrganismeById] = useOrganismes();
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder,setPath,defaultFilters] = useListFilters(AppConstants.pathOrganismes + props.oid +'/personnes/add', false);
    const [organisme, setOrganisme] = useState<IOrganisme>(null);
    const [personnes, setPersonnes] = useState<IPersonneExterieure[]>([]);
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [keyword,setKeyword] = useState<string>("");
    const [addedPersonnes,setAddedPersonnes] = useState<number[]>([]);
    const [deletedPersonnes,setDeletedPersonnes] = useState<number[]>([]);
    const [showConfirmation,setShowConfirmation] = useState<boolean>(false);


    useEffect(() => {
        updateFilters(defaultFilters);
    }, []);

    useEffect(() => {
        if (!props.oid) {
            history.push(AppConstants.path403);
        }
        getOrganisme(props.oid);
    }, [props.oid]);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    /**
     * Lancement de la recherche par mot clé
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData) => {
        const searchedKeyword = formDatas.keyword as string;
        setKeyword(searchedKeyword);
        updateFilters({keyword: searchedKeyword});
    };


    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        autresContactsService.getPersonnesExterieures(filters as IAutresContactsGetFormData).then((response: IPersonnesExterieuresGetApiResponse) => {
            if (response && response.datas.pagination) {

                // exterieurs
                const currentAutresContactsList: IPersonneExterieure[] = response.datas.pagination.data as IPersonneExterieure[];
                setPersonnes(currentAutresContactsList);

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };


    //Récupération de l'organisme
    const getOrganisme = ((oid: string) => {
        getOrganismeById(oid, (organisme) => {
            setOrganisme(organisme);
            if (!loaded) {
                setLoaded(true);
            }
        })
    });


    /**
     * Permet de savoir si une personne est déjà rattaché à l'organisme
     * @param {IPersonneExterieure} person
     * @returns {boolean}
     */
    const isAlreadySubscribed = (person: IPersonneExterieure): boolean => {
        return organisme.personnesExterieures.findIndex((p) => {
            return p && p.id === person.id;
        }) !== -1;
    };


    const isAdded = (person: IPersonneExterieure): boolean => {
        return addedPersonnes.findIndex((p)=> p === person.id) !== -1;
    };
    const isDeleted = (person: IPersonneExterieure): boolean => {
        return deletedPersonnes.findIndex((p)=> p === person.id) !== -1;
    };

    const toggleAdded = (person: IPersonneExterieure): void => {
        if(isAdded(person)){
            setAddedPersonnes(addedPersonnes.filter((p)=>p !== person.id));
        }
        else {
            const newPersonnes: number[] = [...addedPersonnes];
            newPersonnes.push(person.id);
            setAddedPersonnes(newPersonnes);
        }
    }

    /**
     * Suppression d'une personne déjà liée à cet organisme
     * @param {IPersonneExterieure} person
     */
    const toggleDeleted = (person: IPersonneExterieure): void => {
        if(isDeleted(person)){
            setDeletedPersonnes(deletedPersonnes.filter((p)=>p !== person.id));
        }
        else {
            const newPersonnes: number[] = [...deletedPersonnes];
            newPersonnes.push(person.id);
            setDeletedPersonnes(newPersonnes);
        }
    }


    /**
     * Redirection vers l'organisme
     */
    const goToOrganisme = () => {
        history.push(AppConstants.pathOrganismes+'/'+props.oid);
    };

    const onSubmit = ()=> {
        const formDatas: IOrganismeUpdatePersonnesFormData = {
            personnesToAdd: addedPersonnes,
            personnesToDelete: deletedPersonnes,
        }

        autresContactsService.updatePersonneToOrganisme(props.oid, formDatas).then((r)=>{
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les membres ont bien été enregistrées",
                type: "success"
            });
            goToOrganisme();
        },(r)=>{
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue.",
                type: "danger"
            });
        });
    }



    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
                    <PageHeaderComponent text="Organismes" subText={"Ajouter des personnes extérieures"} icon="icon-personne-exterieure-add" modificators={`-in-fullscreen -border-full-screen`}/>
                    <TabsContainerComponent>
                        <TabPanelComponent label="Personnes Exterieures" icon="icon-personne-exterieure">
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword} onSubmit={onSearch} modificators="-searchpage -list-header"/>
                            </div>

                            <table className={`list-table`}>
                                <thead className={"headers"}>
                                <tr>
                                    {[
                                        {slug: "nom", noSort: false},
                                        {slug: "prenom", noSort: false},
                                        {slug: "fonction", noSort: false},
                                        {slug: "actions", noSort: true}
                                    ].map((item, iIndex) => <th
                                        className="th" key={iIndex}>
                                        <TableHeaderButtonComponent
                                            direction={filters.orderType as string || AppConstants.orderType.asc}
                                            value={item.slug} selectedValue={filters.orderBy as string || ""}
                                            noSort={item.noSort}
                                            label={item.slug} click={(value: string) => setOrder(value)}/>
                                    </th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    personnes && personnes.map((personne: IPersonneExterieure, index: number) =>{
                                        const subscribed = isAlreadySubscribed(personne);

                                        return <tr key={index} className={`tr`}>
                                            <td className="td">
                                                {personne.nom}
                                            </td>
                                            <td className="td">
                                                {personne.prenom}
                                            </td>
                                            <td className="td">
                                                {personne.fonction}
                                            </td>
                                            <td className="td -center" width={80}>
                                                {!subscribed && isAdded(personne) && <button className="g-button -delete-button icon-remove" onClick={() => toggleAdded(personne)}/>}
                                                {!subscribed && !isAdded(personne) && <button className="g-button -is-link" onClick={() => toggleAdded(personne)}>Ajouter</button>}
                                                {subscribed && isDeleted(personne) && <button className="g-button -is-link" onClick={() => toggleDeleted(personne)}>Ajouter</button>}
                                                {subscribed && !isDeleted(personne) && <button className="g-button -delete-button icon-remove" onClick={() => toggleDeleted(personne)}/>}
                                            </td>
                                        </tr>;
                                    })
                                }
                                </tbody>
                            </table>

                            <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                        </TabPanelComponent>
                    </TabsContainerComponent>


                    <div className="form-actions -outside-right">
                        {
                            showConfirmation &&
                            <ConfirmationComponent text={"Quitter sans enregistrer les nouveaux membres ?"} onConfirm={()=>goToOrganisme()} onCancel={()=>setShowConfirmation(false)}/>
                        }

                        <button className="g-button -secondary-border-only" onClick={()=>setShowConfirmation(true)}>
                            Quitter sans enregistrer
                        </button>
                        <button className="g-button -secondary" onClick={onSubmit}>
                            Enregistrer
                        </button>
                    </div>
                </LayoutComponent>
            }
        </>
    )
}
    
