import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js";
import "regenerator-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Route, Router} from "react-router-dom";
import { createBrowserHistory } from "history";
import ErrorBoundary from "./Components/Errors/ErrorBoundary.component";
import UserProvider from "./Providers/User.provider";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ErrorPageScreen from "./Screens/ErrorPage.screen";


export const history = createBrowserHistory();

if(process.env.REACT_APP_ALLOW_SENTRY && process.env.REACT_APP_ALLOW_SENTRY == "true") {
    Sentry.init({
        dsn: "https://e8616e7370bf4a2188a2f4d13ac77eaf@o67218.ingest.sentry.io/4504315019264000",
        integrations: [new BrowserTracing()],
        environment: process.env.REACT_APP_ENV,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0,
    });
}


ReactDOM.render(
    <React.StrictMode>
        <Router history={history} >

            {
                (process.env.REACT_APP_ALLOW_SENTRY && process.env.REACT_APP_ALLOW_SENTRY === "true" ) &&
                <Sentry.ErrorBoundary fallback={ <ErrorPageScreen message="L'application a rencontré une erreur. Cette erreur a été remontée dans l'outil de monitoring." />}>
                    <UserProvider>
                        <Route path="/" component={App}/>
                    </UserProvider>
                </Sentry.ErrorBoundary>
            }
            {
                (!process.env.REACT_APP_ALLOW_SENTRY || process.env.REACT_APP_ALLOW_SENTRY !== "true" ) &&
                <ErrorBoundary>
                    <UserProvider>
                        <Route path="/" component={App}/>
                    </UserProvider>
                </ErrorBoundary>
            }



            <ReactNotifications />
        </Router>
    </React.StrictMode>,
    document.getElementById('app-wrapper')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
