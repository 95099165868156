import React, {ChangeEvent, useEffect, useState} from "react";
import {IFormField} from "./Input.field.component";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "../../Services/Api.service";
import {IOption, IOptionsGroup} from "./Select.field.component";
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";


type SelectBrutFieldComponentProps = IFormField & {
    onChange: (value: string) => void,
    options?: IOption[],
    optionsGroup?: IOptionsGroup[],
    oldValue?: string,
    disabled?: boolean,
    hideEmpty?: boolean
}

export default function SelectBrutFieldComponent(props: SelectBrutFieldComponentProps){
    const [currentValue, setCurrentValue] = useState<string>(props.oldValue || "");
    const [errors, setErrors] = useState<IApiErrorMessage>(null);

    useEffect(() => {
        if(props.oldValue){
            setCurrentValue(props.oldValue);
        }
    }, [props.oldValue])


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);


    /**
     * Ecoute du onChange sur le champ
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} e
     */
    const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const value: string = e.target.value;
        setCurrentValue(value);
        props.onChange(value);

        //On reset l'état d'erreur
        setErrors(null);
    }

    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            <div className={`select-wrapper ${errors ? '-error' : ''}`}>
                <select className="form-field-input -full-w" name={props.fieldName} required={props.required} onChange={onChangeSelect} value={currentValue} disabled={props.disabled}>
                    {!props.hideEmpty && <option value="">{props.placeholder || "Selectionnez une valeur"}</option>}

                    {
                        props.options && props.options.map( (o: IOption, index: number) =>
                            <option value={o.value} key={index}>{o.label}</option>
                        )
                    }
                    {
                        props.optionsGroup && props.optionsGroup.map( (g: IOptionsGroup, index: number) =>
                            <optgroup label={g.label} key={index}>
                                {
                                    g.options && g.options.map( (o: IOption, indexOption: number) =>
                                        <option value={o.value} key={indexOption}>{o.label}</option>
                                    )
                                }
                            </optgroup>
                        )
                    }
                </select>
                {!props.disabled &&
                    <i className={`form-field-aside -is-absolute icon-arrow-down-full`}  />
                }
            </div>
            {
                props.showFieldErrorDetail &&
                <ErrorFieldsMessagesComponent fieldsErrorsMessages={errors} />
            }
        </div>
    )
}
