import {IOffice} from "src/Models/Office.model";
import OfficesHelper from "src/Helpers/Offices.helper";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import CommonHelper from "src/Helpers/Common.helper";
import GroupesHelper from "src/Helpers/Groupes.helper";

type IOfficeSiteGroupesLogicielsInfosComponentProps = {
    office: IOffice,
}

export default function OfficeSiteGroupesLogicielsInfosComponent(props: IOfficeSiteGroupesLogicielsInfosComponentProps) {
    const office: IOffice = props.office;

    return (
        <div className="informations-wrapper">
            <div className="columns">

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Logiciel de rédaction d'acte</p>
                        <p className="value">{office.logicielRedactionActes ? OfficesHelper.getFormatedLogiciel(office.logicielRedactionActes, OfficesConstants.logicielRedactionActesOptions) : "-"}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Groupes</p>
                        <p className="value">{office.groupes ? GroupesHelper.getFormated(office.groupes) : "-"}</p>
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Logiciel Visio</p>
                        <p className="value">{office.logicielVisio ? OfficesHelper.getFormatedLogiciel(office.logicielVisio, OfficesConstants.logicielsVisioOptions) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Logiciel de négociation</p>
                        <p className="value">{office.logicielNegociation ? OfficesHelper.getFormatedLogiciel(office.logicielNegociation, OfficesConstants.logicielNegociationOptions) : "-"}</p>
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Système Compta</p>
                        <p className="value">{office.systemeCompta ? OfficesHelper.getFormatedLogiciel(office.systemeCompta, OfficesConstants.systemeComptaOptions) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Autres logiciels</p>
                        <p className="value">{office.logicielAutres ? office.logicielAutres : "-"}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}
