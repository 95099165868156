import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import CommonHelper from "src/Helpers/Common.helper";
import PersonnesExterieuresHelper from "src/Helpers/PersonnesExterieures.helper";
import GroupesHelper from "src/Helpers/Groupes.helper";

type IPersonneExterieureIdentiteComponentProps = {
    personne: IPersonneExterieure,
}

export default function PersonneExterieureIdentiteComponent(props: IPersonneExterieureIdentiteComponentProps) {
    const personne: IPersonneExterieure = props.personne;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Sexe</p>
                        <p className="value">{personne.sexe ? CommonHelper.getFormatedSexe(personne.sexe) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{personne.nom ? personne.nom : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Fonction</p>
                        <p className="value">{personne.fonction ? personne.fonction : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Organisme</p>
                        <p className="value">{personne.organisme && personne.organisme.nom ? personne.organisme.nom : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Groupes</p>
                        <p className="value">{personne.groupes && personne.groupes.length ? GroupesHelper.getFormated(personne.groupes) : "-"}</p>
                    </div>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Civilité (Bloc Adresse)</p>
                        <p className="value">{personne.civiliteCourte ? PersonnesExterieuresHelper.getFormatedCiviliteCourte(personne.civiliteCourte)  : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Prénom</p>
                        <p className="value">{personne.prenom ? personne.prenom : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Formule d'appel</p>
                        <p className="value">{personne.civilite ? PersonnesExterieuresHelper.getFormatedCivilite(personne.civilite)  : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Agrément comptabilité</p>
                        <p className="value">{personne.agrementComptabilite ?  CommonHelper.getFormatedOuiNon(personne.agrementComptabilite) : "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block -skip-line-1-2" >
                        <p className="label">Formule de politesse</p>
                        <p className="value">{personne.formulePolitesse ? PersonnesExterieuresHelper.getFormatedFormulePolitesse(personne.formulePolitesse) : "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
