import ExportComponent from "src/Components/Exports/Export.component";
import React, {useEffect, useState} from "react";
import {ICorrespondance} from "../../Models/Correspondance.model";
import {IOption} from "../Fields/Select.field.component";

type CorrespondancesInvitesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    correspondance: ICorrespondance,
    filters?: any,
}
export default function CorrespondancesInvitesExportComponent(props: CorrespondancesInvitesExportComponentProps) {
    const [presencesOptions, setPresencesOptions] = useState<IOption[]>([]);

    useEffect(() => {
        //Ajout la possibilité d'exporter les présences (réunion, repas, ...) si la correspondance a bien réunion, repas, ...
        if (props.correspondance.presence && props.correspondance.presence === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presencePresence',
                    label: 'Présence'
                }
            ]);
        }
        if (props.correspondance.reunion && props.correspondance.reunion === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presenceReunion',
                    label: 'Présence Réunion'
                }
            ]);
        }
        if (props.correspondance.repas && props.correspondance.repas === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presenceRepas',
                    label: 'Présence Repas'
                }
            ]);
        }
        if (props.correspondance.cocktail && props.correspondance.cocktail === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presenceCocktail',
                    label: 'Présence Cocktail'
                }
            ]);
        }
        if (props.correspondance.hebergement && props.correspondance.hebergement === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presenceHebergement',
                    label: 'Présence Hébergement'
                }
            ]);
        }
        if (props.correspondance.transport && props.correspondance.transport === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presenceTransport',
                    label: 'Présence Transport'
                }
            ]);
        }
        if (props.correspondance.collaborateur && props.correspondance.collaborateur === "oui") {
            setPresencesOptions(presencesOptions => [
                ...presencesOptions,
                {
                    value: 'presenceCollaborateur',
                    label: 'Présence Collaborateur'
                }
            ]);
        }
    }, [props.correspondance]);

    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Correspondances"}
                         icon="icon-correspondances"
                         subTitle={"Export des invités"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl={`api/correspondances/${props.correspondance.id.toString()}/participants/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-informations',
                                         fieldName: "informations",
                                         label: "Informations",
                                         options: [
                                             {value: 'etat', label: 'Etat'},
                                             {value: 'dateEnvoi', label: 'Date d\'envoi'},
                                             {value: 'dateRelance', label: 'Date de relance'},
                                             {value: 'nom', label: 'Nom'},
                                             {value: 'prenom', label: 'Prénom'},
                                             {value: 'email', label: 'Email'},
                                             {value: 'crpcen', label: 'CRPCEN'},
                                             {value: 'office', label: 'Office'},
                                             {value: 'organisme', label: 'Organisme'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-precisions',
                                         fieldName: "presences",
                                         label: "Présences",
                                         options: presencesOptions
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
