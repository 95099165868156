import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import bgSignature from "src/assets/images/placeholders/signature-upload.png";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistreDocumentsFormData, ISinistreGetApiResponse} from "src/Services/Sinistres.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {SinistresConstants} from "src/Constants/SinistresConstants";


type ISinistreDocumentsFormFormProps = {
    sinistre?: ISinistre,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ISinistreDocumentsFormData) => Promise<ISinistreGetApiResponse>,
    onSuccessSubmit: (response: ISinistreGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ISinistreGetApiResponse) => void,
    onCancel: () => void,
}

export default function SinistreDocumentsFormForm(props: ISinistreDocumentsFormFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const {sinistre} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'repeater',
                fieldName: "documents",
                label: "Documents",
                oldRepeatableValues: sinistre.documents as FormComponentFormData,
                formColumns: [
                    {
                        modificators: "",
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                required: true,
                                oldValue : "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'date',
                                fieldName: "date",
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                label: "Date",
                                placeholder: "Date",
                                required: true,

                                oldValue : "",
                            },
                            {
                                type: 'file',
                                fieldName: "fichier",
                                modificators: "-small-file",
                                label: "Document",
                                placeholder: "document",
                                background: bgSignature,
                                required: true,
                                extensions: ['.pdf'],

                                oldValue : "",
                            },
                        ]
                    },{
                        modificators: "",
                        elements: [
                            {
                                type: 'select',
                                fieldName: "type",
                                label: "Type de document",
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                required: true,
                                hideSearchOnMultiple: true,
                                options: SinistresConstants.documentsTypesOptions,
                                oldValue : "",
                            },
                            {
                                type: 'textarea',
                                fieldName: "commentaires",

                                label: "Commentaires",
                                placeholder: "Commentaires",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "autre",
                                },
                            },
                        ]
                    },
                ],
                required: false,
            },
        ];
        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);

    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISinistreDocumentsFormData).then((response: ISinistreGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }


            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les documents ont bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des documents.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
