import DateHelper from "src/Helpers/Date.helper";
import {IRecuLivraison} from "src/Models/RecuLivraison.model";
import RecusLivraisonHelper from "src/Helpers/RecusLivraison.helper";


type IOfficeRecusLivraisonInfosComponentProps = {
    recusLivraison: IRecuLivraison[],
}

export default function OfficeRecusLivraisonInfosComponent(props: IOfficeRecusLivraisonInfosComponentProps) {
    const recusLivraison: IRecuLivraison[] = props.recusLivraison;

    return (
        <div className="informations-wrapper -recus-wrapper">
            {
                recusLivraison && recusLivraison.map((recusLivraison: IRecuLivraison, index: number) =>
                    <div className="columns" key={index} >
                        <p className="title">{DateHelper.getFormatedDate(recusLivraison.date)}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Année</p>
                                <p className="value">{recusLivraison.annee ? recusLivraison.annee : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Numéro</p>
                                <p className="value">{recusLivraison.numero ? recusLivraison.numero : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{recusLivraison.type ? RecusLivraisonHelper.getFormatedType(recusLivraison.type) : "-"}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!recusLivraison || (recusLivraison.length === 0)) &&
                    <>-</>
            }
        </div>
    );
}
