import React from 'react';
import { Link } from 'react-router-dom';

const ForbiddenScreen = () => {
    return (
        <div>
            <h1>Page interdite</h1>

            <p className="intro">
                Vous n'avez pas accès à cette page.
            </p>

            <Link to="/">
                Retourner à l'accueil
            </Link>
        </div>
    )
};

export default ForbiddenScreen;
