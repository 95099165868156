import {IAdresse} from "src/Models/Adresse.model";
import AdressesHelper from "src/Helpers/Adresses.helper";

type IAdressesInfosComponentProps = {
    adresses: IAdresse[],
    isForOffice?: boolean,
    showTitle?:boolean
}

export default function AdressesInfosComponent(props: IAdressesInfosComponentProps) {
    const adresses: IAdresse[] = props.adresses;

    return (
        <div className="informations-wrapper -break-inside-avoid">
            {
                adresses && adresses.map((adresse: IAdresse, index: number) =>
                    <div className="columns" key={index}>
                        {
                            (adresses && props.showTitle && index === 0) &&
                            <p className="title">Adresses</p>
                        }
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Rue</p>
                                <p className="value">{adresse.ligne1 ? adresse.ligne1: "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Code Postal / CEDEX</p>
                                <p className="value">{adresse.codePostal ? adresse.codePostal: "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Ville</p>
                                <p className="value -uppercase">{adresse.ville ? adresse.ville: "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Complément d'adresse</p>
                                <p className="value">{adresse.ligne2 ? adresse.ligne2: "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Boîte Postale</p>
                                <p className="value">{adresse.boitePostale ? adresse.boitePostale: "-"}</p>
                            </div>

                            {
                                adresse.adresseEtrangere && (adresse.adresseEtrangere === "oui") &&

                                <div className="informations-block">
                                    <p className="label">Pays</p>
                                    <p className="value">{adresse.pays ? adresse.pays: "-"}</p>
                                </div>
                            }
                        </div>

                        <div className="column">
                            {
                                props.isForOffice &&
                                <>
                                    <div className="informations-block">
                                        <p className="label">Arrondissement</p>
                                        <p className="value">{adresse.arrondissement ? AdressesHelper.getFormatedArrondissement(adresse.arrondissement): "-"}</p>
                                    </div>
                                    <div className="informations-block">
                                        <p className="label">Tribunal Judiciaire</p>
                                        <p className="value">{adresse.tribunalJudiciaire ? AdressesHelper.getFormatedTribunal(adresse.tribunalJudiciaire): "-"}</p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                )
            }
            {
                (!adresses || (adresses.length === 0)) &&
                <>-</>
            }

        </div>
    );
}
