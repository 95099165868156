import { IOption } from "../Components/Fields/Select.field.component";
import { ICreationSteps } from "./AppConstants";

type IDifferendsConstants = {
    subjects: IOption[],
    states: IOption[],
    creationSteps: ICreationSteps[],
    documentsTypesOptionsInterne: IOption[],
    documentsTypesOptionsInterneExterne: IOption[],
    documentsTypesOptionsExterneInterne: IOption[],
    documentsOrderOptions: IOption[],
}

export const DifferendsConstants: IDifferendsConstants = {
    documentsTypesOptionsInterne: [
        {
            value: "differend",
            label: "Différend",
        },
        {
            value: "ar-notaire-plaignant",
            label: "AR au notaire plaignant",
        },
        {
            value: "demande-explication-notaire-mis-en-cause",
            label: "Demande explication au notaire mis en cause",
        },
        {
            value: "explication-notaire-mis-en-cause",
            label: "Explication du notaire mis en cause",
        },
        {
            value: "explication-notaire-plaignant",
            label: "Explication du notaire plaignant",
        },
        {
            value: "relance-notaire-plaignant",
            label: "Relance au notaire plaignant",
        },
        {
            value: "relance-notaire-mis-en-cause",
            label: "Relance au notaire mis en cause",
        },
        {
            value: "convocation-notaire-mis-en-cause",
            label: "Convocation notaire mis en cause",
        },
        {
            value: "reponse-notaire-plaignant",
            label: "Réponse au notaire plaignant",
        },
        {
            value: "reponse-notaire-mis-en-cause",
            label: "Réponse au notaire mis en cause",
        },
        {
            value: "courrier-libre-notaire-plaignant",
            label: "Courrier libre au notaire plaignant",
        },
        {
            value: "courrier-libre-notaire-mis-en-cause",
            label: "Courrier libre au notaire mis en cause",
        },
        {
            value: "autres",
            label: "Autre",
        },
    ],
    documentsTypesOptionsInterneExterne: [
        {
            value: "differend",
            label: "Différend",
        },
        {
            value: "ar-notaire-plaignant",
            label: "AR au notaire plaignant",
        },
        {
            value: "demande-explication-chambre-notaire-mis-en-cause",
            label: "Demande explication à la chambre du notaire mis en cause",
        },
        {
            value: "ar-chambre-notaire-mis-en-cause",
            label: "AR de la Chambre du notaire mis en cause",
        },
        {
            value: "explication-chambre-notaire-mis-en-cause",
            label: "Explication de la Chambre du notaire mis en cause",
        },
        {
            value: "relance-chambre-notaire-mis-en-cause",
            label: "Relance à la chambre du notaire mis en cause",
        },
        {
            value: "reponse-notaire-plaignant",
            label: "Réponse au notaire plaignant",
        },
        {
            value: "reponse-chambre-notaire-mis-en-cause",
            label: "Réponse à la Chambre du notaire mis en cause",
        },
        {
            value: "courrier-libre-notaire-plaignant",
            label: "Courrier libre au notaire plaignant",
        },
        {
            value: "courrier-libre-chambre-notaire-mis-en-cause",
            label: "Courrier libre à la Chambre du notaire mis en cause",
        },
        {
            value: "autres",
            label: "Autre",
        },
    ],
    documentsTypesOptionsExterneInterne: [
        {
            value: "differend",
            label: "Différend",
        },
        {
            value: "differend-hors-parcours",
            label: "Différend HORS PARCOURS",
        },
        {
            value: "ar-notaire-plaignant-hors-parcours",
            label: "AR au notaire plaignant HORS PARCOURS",
        },
        {
            value: "ar-chambre-notaire-plaignant",
            label: "AR à la Chambre du notaire plaignant",
        },
        {
            value: "demande-explication-notaire-mis-en-cause",
            label: "Demande explication au notaire mis en cause",
        },
        {
            value: "explication-notaire-mis-en-cause",
            label: "Explication du notaire mis en cause",
        },
        {
            value: "relance-notaire-mis-en-cause",
            label: "Relance notaire mis en cause",
        },
        {
            value: "convocation-notaire-mis-en-cause",
            label: "Convocation notaire mis en cause",
        },
        {
            value: "reponse-chambre-notaire-plaignant",
            label: "Réponse à la Chambre du notaire plaignant",
        },
        {
            value: "reponse-notaire-mis-en-cause",
            label: "Réponse au notaire mis en cause",
        },
        {
            value: "courrier-libre-chambre-notaire-plaignant",
            label: "Courrier libre à la Chambre du notaire plaignant",
        },
        {
            value: "courrier-libre-notaire-mis-en-cause",
            label: "Courrier libre au notaire plaignant",
        },
        {
            value: "autres",
            label: "Autre",
        },
    ],
    subjects: [
        { value: "deontologie", label: "Déontologie" },
        { value: "tarif", label: "Tarif" },
        { value: "autre", label: "Autre" }
    ],
    states: [
        { value: "en_cours", label: "En cours" },
        { value: "action_terminee", label: "Action terminée" },
    ],
    creationSteps: [
        {
            url: "/notaires",
            title: "Identité des deux parties",
            icon: "icon-plaignant",
        },
        {
            url: "/differend",
            title: "Informations sur le différend",
            icon: "icon-informations",
        },
    ],
    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "type",
            label: "Type"
        },
    ],
};
