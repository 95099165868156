import {IRepertoire} from "src/Models/Repertoire.model";
import DateHelper from "src/Helpers/Date.helper";
import RepertoiresHelper from "src/Helpers/Repertoires.helper";

type IOfficeRepertoiresInfosComponentProps = {
    repertoires: IRepertoire[],
}

export default function OfficeRepertoiresInfosComponent(props: IOfficeRepertoiresInfosComponentProps) {
    const repertoires: IRepertoire[] = props.repertoires;

    return (
        <div className="informations-wrapper -recus-wrapper">
            {
                repertoires && repertoires.map((repertoire: IRepertoire, index: number) =>
                    <div className="columns" key={index} >
                        <p className="title">{DateHelper.getFormatedDate(repertoire.date)}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Année</p>
                                <p className="value">{repertoire.annee ? repertoire.annee : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Numéro</p>
                                <p className="value">{repertoire.numero ? RepertoiresHelper.getFormatedNumero(repertoire.numero) : "-"}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!repertoires || (repertoires.length === 0)) &&
                    <>-</>
            }
        </div>
    );
}
