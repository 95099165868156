import {ICoordonnee} from "src/Models/Coordonnee.model";
import CoordonneesHelper from "src/Helpers/Coordonnees.helper";
import {useEffect} from "react";
import {CoordonneeConstants} from "../../../../Constants/CoordonneeConstants";
import {FormActions, FormColumn, FormComponentFormData, FormElement} from "../../../Forms/FormCreator.component";

type ICoordonneesInfosComponentProps = {
    coordonnees: ICoordonnee[],
    relationName?: string,
}

export default function CoordonneesInfosComponent(props: ICoordonneesInfosComponentProps) {
    const coordonnees: ICoordonnee[] = props.coordonnees;

    return (
        <div className="informations-wrapper -break-inside-avoid">
            {
                coordonnees && coordonnees.map((coordonnee: ICoordonnee, index: number) =>
                    <div className="columns" key={index} >
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{coordonnee.type ? CoordonneesHelper.getFormatedType(coordonnee.type) : "-"}</p>
                            </div>
                        </div>

                        {coordonnee.coordonnees && (coordonnee.type !== 'url') && <>
                              <div className="column">
                                <div className="informations-block">
                                  <p className="label">Destination</p>
                                  <p
                                    className="value">{coordonnee.destination ? CoordonneesHelper.getFormatedDestination(coordonnee.destination, coordonnee.type, props.relationName) : "-"}</p>
                                </div>
                              </div>
                            </>
                        }

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Détail</p>
                                <p className="value">
                                    {
                                        coordonnee.coordonnees && (coordonnee.type === 'url') &&
                                        <a href={coordonnee.coordonnees.startsWith('http') ? coordonnee.coordonnees : 'https://' + coordonnee.coordonnees}
                                           target="_blank" rel="noopener noreferrer" className={"link"} >
                                            {coordonnee.coordonnees}
                                        </a>
                                    }

                                    {
                                        coordonnee.coordonnees && (coordonnee.type === 'telephone') &&
                                        <>{CoordonneesHelper.formatPhoneNumberForDisplay(coordonnee.coordonnees)}</>
                                    }
                                    {
                                        coordonnee.coordonnees && (coordonnee.type !== 'url'&& coordonnee.type !== 'telephone') &&
                                            <>{coordonnee.coordonnees}</>
                                    }

                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!coordonnees || (coordonnees.length === 0)) &&
                    <>-</>
            }
        </div>
    );
}
