import 'moment/locale/fr';
import {CorrespondanceConstants} from "../Constants/CorrespondancesConstants";
import {ICorrespondance} from "../Models/Correspondance.model";
import {IOption} from "../Components/Fields/Select.field.component";

export default class CorrespondancesHelper{

    /**
     * Permet de formater une liste de correspondances pour les selects
     * @param {ICorrespondance[]} correspondances
     * @returns {IOption[]}
     */
    static formatListForOptions(correspondances: ICorrespondance[]): IOption[]{
        if(!correspondances || !correspondances.length) return [];

        const options: IOption[] = [];
        for(let correspondance of correspondances){
            const option: IOption = {
                value: correspondance.id.toString(),
                label: correspondance.titre
            };

            options.push(option)
        }

        return options;
    }


    /**
     * Permet de récuépérer une liste d'id à partir d'une liste de correspondances
     *
     * @param {ICorrespondance[]} correspondances
     * @returns {string[]}
     */
    public static getIdsFromList(correspondances: ICorrespondance[]): string[]{
        if(!correspondances || !correspondances.length) return [];

        const ids: string[] = correspondances.map((correspondance: ICorrespondance) => correspondance.id.toString());

        return ids;
    }

    /**
     * Permet de récupérer le type de la correspondance
     *
     * @param {string} type
     * @returns {string}
     */
    public static getTypeFormated(type: string): string{
        let formatedType: string = type;

        if (CorrespondanceConstants.types[formatedType]) {
            formatedType = CorrespondanceConstants.types[formatedType];
        }

        return formatedType;
    }

}
