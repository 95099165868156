import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {ISuccession} from "src/Models/Succession.model";
import UseSuccession from "src/Hooks/UseSuccession";
import {ISuccessionReponseExternalFormData, SuccessionsService} from "src/Services/Successions.service";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import SuccessionIdentiteComponent from "src/Components/Successions/SuccessionIdentite.component";
import SuccessionDemandeurComponent from "src/Components/Successions/SuccessionDemandeur.component";
import SuccessionReponseExternalFormComponent from "src/Components/Forms/Successions/SuccessionReponseExternalForm.component";
import DateHelper from "../../Helpers/Date.helper";


type ISuccessionExternalReponseScreenProps = {
    sid: string
}
export default function SuccessionExternalReponseScreen(props: ISuccessionExternalReponseScreenProps){
    const {getSuccessionById, getSuccessionExternalById} = UseSuccession();
    const [succession, setSuccession] = useState<ISuccession>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [answered, setAnswered] = useState<boolean>(false);
    const [answeredCurrentForm, setAnsweredCurrentForm] = useState<boolean>(false);
    const history = useHistory();
    const successionsService: SuccessionsService = new SuccessionsService();


    useEffect(() => {
        if( !props.sid ){
            history.push(AppConstants.path403);
        }

        getSuccession();

    }, [props.sid])


    /**
     * Récupération de la succession à partir de son id
     */
    const getSuccession = (): void => {
        getSuccessionExternalById(props.sid, (succession: ISuccession) => {
            setSuccession(succession);

            if(succession.notaire && succession.dateResolution){
                setAnswered(true);
            }

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getSuccession();
        setAnsweredCurrentForm(true);
    };
    

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
                    <PageHeaderComponent text="Succession" subText={"Réponse à une demande"} icon="icon-successions" modificators={`-in-fullscreen -border-full-screen`}/>

                    {
                        !answeredCurrentForm &&
                            <AccordionSimpleComponent title="Identité du défunt" icon="icon-notaires" contentShowedOnInit={true} modificators="-no-padding-top -no-padding-left-right">
                                <SuccessionIdentiteComponent succession={succession} showDate={true} />
                            </AccordionSimpleComponent>
                    }

                    {
                        !answeredCurrentForm &&
                        <AccordionSimpleComponent title="Demandeur" icon="icon-demandeur" contentShowedOnInit={true} modificators="-no-padding-left-right">
                            <SuccessionDemandeurComponent succession={succession} />
                        </AccordionSimpleComponent>
                    }

                    {
                        !answered &&
                        <AccordionSimpleComponent title="Réponse" icon="icon-commentaires" contentShowedOnInit={true} modificators="-no-padding-left-right">
                            <SuccessionReponseExternalFormComponent succession={succession} submitMethod={(formData: ISuccessionReponseExternalFormData)=>successionsService.reponseExternal(props.sid,formData)} onSuccessSubmit={onSuccessSubmitForm} />
                        </AccordionSimpleComponent>
                    }

                    {
                        answered && !answeredCurrentForm &&
                        <p className="information-text  -big">
                            Cette succession a déjà été répondu par {succession.notaire.nom} {succession.notaire.prenom} de l'office {succession.office.nom}, le {DateHelper.getFormatedDate(succession.dateResolution)}.
                        </p>
                    }

                    {
                        answered && answeredCurrentForm &&
                        <p className="information-text  -big">
                            Votre réponse a bien été prise en compte.
                        </p>
                    }
                </LayoutComponent>
            }
        </>
    )
}
