import {IOfficeGetApiResponse, IOfficeInformationFormData,} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {IOffice} from "src/Models/Office.model";
import {AppConstants} from "src/Constants/AppConstants";


type IOfficeCreationFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IOfficeInformationFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeCreationFormComponent(props: IOfficeCreationFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const office: IOffice = props.office;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'number',
                fieldName: "CRPCEN",

                label: "CRPCEN",
                placeholder: "CRPCEN",
                required: true,
                maxLength: 5,
                showFieldErrorDetail: true,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.CRPCEN ? office.CRPCEN : "",
            }
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom de l'office",
                placeholder: "Nom de l'office",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                showFieldErrorDetail: true,

                oldValue: office && office.nom ? office.nom : "",
            }
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'select',
                fieldName: "type",

                label: "Type",
                placeholder: "Type",
                required: true,
                options: OfficesConstants.typesOfficesOptions,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.type ? office.type : "",
            },
        ];


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);



        let currentFormActions: FormActions[] = [
            {
                label: "Annuler",
                modificators: "-is-link -white",
                onAction: onCancel
            },
            {
                label: "Enregistrer",
                modificators: "-secondary-border-only",
                onAction: (formData) => {
                    onSubmit(formData, false)
                }
            },
        ];
        setFormActions(currentFormActions);


    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeInformationFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'office a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
