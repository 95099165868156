import React, {Fragment, useEffect, useState} from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import FileHelper from "../../Helpers/File.helper";
import DateHelper from "../../Helpers/Date.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import {FilInformationsService, IRevuePresse} from "../../Services/FilInformations.service";
import {FormColumn} from "../Forms/FormCreator.component";


const FilInformationsPresse = (props: {
    filInformations: IFilInformations
}) => {
    const [revuesPresseList, setRevuesPresseList] = useState<IRevuePresse[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);


    useEffect(() => {
        const filInfosService: FilInformationsService = new FilInformationsService();
        filInfosService.getRevuesPresse().then((result) => {
            setRevuesPresseList(result.datas || []);
            setIsLoaded(true);
        });
    }, []);


    /**
     * Permet la récupération du titre d'une revue de presse
     *
     * @param {string} revue
     * @returns {string}
     */
    const getRevueTitleFromId = (revue: string): string => {
        const revuePresse = revuesPresseList.find((rp) => rp.nid[0].value === parseInt(revue));
        return revuePresse ? revuePresse.title[0].value : null;
    }


    return <div className="informations-wrapper">

        {
            isLoaded && props.filInformations.revues && (props.filInformations.revues.length > 0) &&
            <div className="columns">
                <p className="title">Revues de presse</p>
                <div className="column">
                    {
                        props.filInformations.revues.map((revue: string, revueIndex) =>
                            <div className="informations-block" key={revueIndex}>
                                <p className="label">Titre</p>
                                <p className="value">{getRevueTitleFromId(revue)}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        }
        {
            props.filInformations.presses.map((presse, presseIndex) =>
                <Fragment key={presseIndex}>
                    <div className="informations-block">
                        <p className="label">Titre</p>
                        <p className="value">{presse.titre}</p>
                    </div>
                    <div className="columns" key={presseIndex}>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date</p>
                                <p className="value">{presse.date ? DateHelper.getFormatedDate(presse.date, false) : '-'}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Document</p>
                                {
                                    presse.document &&
                                    <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(presse.document)}/>
                                }
                                {!presse.document && <>-</>}
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Image</p>
                                <div className="value">
                                    {
                                        presse.image &&
                                        <figure className="informations-image -resume">
                                            <img src={FileHelper.getImageUrlFromSize(presse.image, "filInfosPresse")}
                                                 alt="" className="image"/>
                                        </figure>
                                    }
                                    {!presse.image && <>-</>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    </div>

};

export default FilInformationsPresse;
