import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import UseFilInformations from "src/Hooks/UseFilInformations";
import {IFilInformations} from "src/Models/FilInformations.model";
import {FilInformationsService, IFilInformationsDetailsGetApiResponse, IFilInformationsUneFormData} from "src/Services/FilInformations.service";
import FilInformationsCreationFormComponent
    from "../../Components/Forms/FilInformations/FilInformationsCreationForm.component";

type IFilInformationsCreationStepsScreenProps = {
    fid?: string,
}


const FilInformationsCreationScreen = (props:IFilInformationsCreationStepsScreenProps) => {
    const filInformationsService: FilInformationsService = new FilInformationsService();
    const {getFilInformationsById} = UseFilInformations();
    const [filInformations, setFilInformations] = useState<IFilInformations>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.fid){
            getFilInformationsById(props.fid, (s: IFilInformations) => {
                setFilInformations(s);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [props.fid]);


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IFilInformationsGetApiResponse} response
     */
    const onSuccessSubmitForm = (response: IFilInformationsDetailsGetApiResponse): void => {
        if(response.datas && response.datas.filInformations){
            const filInformations: IFilInformations = response.datas.filInformations;
            history.push(AppConstants.pathFilInformations + '/' + filInformations.id);
        }
        else{
            history.push(AppConstants.pathFilInformations);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathFilInformations);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un fil d'informations" icon="icon-fil-infos-add" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
	                <InformationHeaderComponent icon={"icon-megaphone"} title={"A la Une"} modificators="-margin-bottom" />
	                <FilInformationsCreationFormComponent filInformations={filInformations} submitMethod={(formData: IFilInformationsUneFormData)=>filInformationsService.infos(formData)} isOnCreationStep={true} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />
                </>
            }
        </LayoutComponent>
    )
}

export default FilInformationsCreationScreen;
