import React, {useEffect, useState} from "react";
import { v4 as uuid } from 'uuid';

export const TabPanelComponent = (props: {
    label: string,
    icon?: string,
    slug?: string,
    hideTabHeader?: boolean,
    children?: React.ReactNode
}) => {
    return <></>;
};

type TabsComponentProps = {
    children: JSX.Element[] | JSX.Element,
    onClickTabCallback?: (slug: string)=>void,
    onBeforeTabChange?: (slug: string)=>boolean,
    defaultTab?: string,
}

const TabsContainerComponent = (props: TabsComponentProps) => {

    const [activeTab,setActiveTab] = useState<string>("");
    const [slugs,setSlugs] = useState<string[]>([]);

    useEffect(()=>{
        const newSlugs: string[] = [];
        // Création d'un slug pour chaque tab si besoin, ou utilisation de celui passé en props
        (Array.isArray(props.children) ? props.children : [props.children]).forEach((tab,index)=>{
            const slug = tab && tab.props && tab.props.slug ? tab.props.slug : uuid();
            if(!props.defaultTab && index === 0){
                onClickTab(slug, true);
            }
            newSlugs.push(slug);

            if(props.defaultTab){
                setActiveTab(props.defaultTab);
            }

        });
        setSlugs(newSlugs);
    },[]);

    const onClickTab = (tab: string, isInit?: boolean) =>{
        if(!isInit && typeof(props.onBeforeTabChange) != "undefined"){
            if(!props.onBeforeTabChange(tab)){
                return false
            }
        }

        setActiveTab(tab);
        if(props.onClickTabCallback){
            props.onClickTabCallback(tab);
        }
    };

    if(!activeTab || !slugs) return null;

    return <>
        <ul className={`tabs-wrapper`}>
            {
                (Array.isArray(props.children) ? props.children : [props.children]).map((c,cIndex) =>
                    <React.Fragment key={cIndex}>
                        {
                            c.props && !c.props.hideTabHeader &&
                            <li onClick={()=>onClickTab(slugs[cIndex])} className={`tab ${activeTab === slugs[cIndex] ? '-is-active' : ''}`}>
                                <i className={`icon ${c.props.icon}`} />
                                <span className="text">{c.props.label}</span>
                            </li>
                        }
                    </React.Fragment>
                )
            }
        </ul>

        {(Array.isArray(props.children) ? props.children : [props.children]).map((c,cIndex)=>{
            return (activeTab === slugs[cIndex] && <div className="prelative" key={cIndex}>{c.props.children}</div>)
        }) }
    </>
};

export default TabsContainerComponent;
