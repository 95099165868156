import {IOffice} from "src/Models/Office.model";

type IOfficeDonneesChiffreesComponentProps = {
    office: IOffice,
}

export default function OfficeDonneesChiffreesComponent(props: IOfficeDonneesChiffreesComponentProps) {
    const office: IOffice = props.office;

    return (
        <div className="informations-wrapper -chiffres">
            <div className="columns -chiffre">

                <div className="column -chiffre">
                    <div className="informations-chiffre">
                        <i className="picto icon-nombre-salaries" />
                        <p className="label">Nombre de salariés</p>
                        <p className="sublabel">(Année -1)</p>
                        <p className="chiffre">{office.nbSalaries ? office.nbSalaries : "-"}</p>
                    </div>
                </div>

                <div className="column -chiffre">
                    <div className="informations-chiffre">
                        <i className="picto icon-nombre-actes" />
                        <p className="label">Nombre d'actes</p>
                        <p className="sublabel">(Année -1)</p>
                        <p className="chiffre">{office.nbActes ? office.nbActes : "-"}</p>
                    </div>
                </div>

                <div className="column -chiffre">
                    <div className="informations-chiffre">
                        <i className="picto icon-nombre-successions" />
                        <p className="label">Nombre de déclarations de successions</p>
                        <p className="sublabel">(Année -1)</p>
                        <p className="chiffre">{office.nbSuccessions ? office.nbSuccessions : "-"}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}
