import ApiService, {IApiCustomResponse,} from "./Api.service";
import {ICourrierType} from "../Models/Courrier.model";

export type ICourriersTypesGetApiResponse = IApiCustomResponse & {
    datas: {
        types: ICourrierType[]
    }
}
export type ICourriersTypeGetApiResponse = IApiCustomResponse & {
    datas: {
        type: ICourrierType
    }
}
export class CourriersService extends ApiService{

    /**
     * Permet la récupération des types de courriers pour un modle
     *
     * @returns {Promise<ICourriersTypesGetApiResponse>}
     */
    public getCourriersTypesForModule(module: string, id:number = null): Promise<ICourriersTypesGetApiResponse>{
        return this.doApiCall(`api/${module}${id !== null ? `/${id}` : ''}/courriers/types`, 'GET');
    }

    /**
     * Permet la récupération spécifique d'un courrier pour les formations
     *
     * @param {string} type
     * @param {number} formationId
     * @returns {Promise<ICourriersTypeGetApiResponse>}
     */
    public getFormationsCourrierForPreview(type: string, formationId?: number): Promise<ICourriersTypeGetApiResponse>{
        return this.doApiCall(`api/formations/courriers/types/${type}`, 'GET', {formationId:formationId});
    }
}
