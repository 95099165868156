import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {IOfficeGetApiResponse, OfficesService} from "../Services/Offices.service";
import {IOffice} from "src/Models/Office.model";

export default function useOffice(){
    const officeService: OfficesService = new OfficesService();
    const history = useHistory();

    /**
     * Récupération d'un notaire à partir de son id
     *
     * @param {string} oid
     * @param {(office: IOffice) => void} callback
     * @param {() => void} callbackError
     */
    const getOfficeById = (oid: string, callback:(office: IOffice) => void, callbackError?: () => void ) => {
        officeService.getOffice(oid).then((response: IOfficeGetApiResponse) => {
            if( response && response.datas.office){
                if(callback){
                    callback(response.datas.office);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOffices);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de l'office",type: "danger"});
        });
    };


    /**
     * Récupération d'un notaire à partir de son id
     *
     * @param {string} oid
     * @param {(office: IOffice) => void} callback
     * @param {() => void} callbackError
     */
    const getOfficeExternalById = (oid: string, callback:(office: IOffice) => void, callbackError?: () => void ) => {
        officeService.getOfficeExternal(oid).then((response: IOfficeGetApiResponse) => {
            if( response && response.datas.office){
                if(callback){
                    callback(response.datas.office);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOffices);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de l'office",type: "danger"});
        });
    };

    return [getOfficeById, getOfficeExternalById];
};

