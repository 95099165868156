import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import useTableauBord from "src/Hooks/UseTableauBord";
import {ITableauBord} from "src/Models/TableauBord.model";
import {ITableauBordDocumentsFormData, TableauxBordService} from "src/Services/TableauxBord.service";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import TableauBordDocuments from "src/Components/TableauxBord/TableauBordDocuments.component";
import TableauBordFicheDocumentsForm from "src/Components/Forms/TableauxBord/TableauBordFicheDocumentsForm.component";
import AsideTableauxBordComponent from "src/Components/Aside/AsideTableauxBord.component";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import TableauBordOfficeComponent from "src/Components/TableauxBord/TableauBordOffice.component";


type ITableauBordFicheScreenProps = {
    tid: string
}
export default function TableauBordFicheScreen(props: ITableauBordFicheScreenProps){
    const documentsAccordionRef = useRef(null);
    const {getTableauBordById} = useTableauBord();
    const [tableauBord, setTableauBord] = useState<ITableauBord>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const tableauxBordService: TableauxBordService = new TableauxBordService();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!tableauBord) return;

        const breadcrumbLinks = [
            {text: "Tableaux de bord", link: AppConstants.pathTableauBord},
            {text: tableauBord.office.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [tableauBord, loaded])



    useEffect(() => {
        if( !props.tid ){
            history.push(AppConstants.pathTableauBord);
        }

        getTableauBord();

    }, [props.tid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getTableauBord = (): void => {
        getTableauBordById(props.tid, (tableauBord: ITableauBord) => {
            setTableauBord(tableauBord);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getTableauBord();

        closeForms();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((documentsAccordionRef && documentsAccordionRef.current) ){
            documentsAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={`Tableau de bord -  ${tableauBord.office.nom}`} icon="icon-tableau-bord" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionSimpleComponent title="Office" icon="icon-office" contentShowedOnInit={true} modificators="-no-padding-top">
                                    <TableauBordOfficeComponent office={tableauBord.office} tableauBord={tableauBord} />
                                </AccordionSimpleComponent>


                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="tableauBord"
                                    contentId={tableauBord.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<TableauBordFicheDocumentsForm tableauBord={tableauBord} submitMethod={(formData: ITableauBordDocumentsFormData)=>tableauxBordService.updateDocumentsList(tableauBord.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<TableauBordDocuments documents={tableauBord.documents}  />}
                                />

                                <AsideTableauxBordComponent type="fiche" tableauBord={tableauBord} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
