import {ICorrespondanceGetApiResponse, CorrespondancesService} from "src/Services/Correspondances.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICorrespondance} from "src/Models/Correspondance.model";
import {useHistory} from "react-router";

export default function useCorrespondance(){
    const correspondancesService: CorrespondancesService = new CorrespondancesService();
    const history = useHistory();

    /**
     * Récupération d'une correspondance à partir de son id
     *
     * @param {string} cid
     * @param {(correspondance: ICorrespondance) => void} callback
     * @param {() => void} callbackError
     */
    const getCorrespondanceById = (cid: number, callback:(correspondance: ICorrespondance) => void, callbackError?: () => void ) => {
        correspondancesService.getCorrespondance(cid).then((response: ICorrespondanceGetApiResponse) => {
            if (response && response.datas.correspondance){
                if(callback){
                    callback(response.datas.correspondance);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathCorrespondances);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la correspondance",type: "danger"});
        });
    };

    return [getCorrespondanceById];
};

