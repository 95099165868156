import ApiService, {IApiCustomResponse, IApiPagination, IExportApiResponse} from "./Api.service";
import {ICtrc} from "../Models/Ctrc.model";

export type ICtrcGetApiResponse = IApiCustomResponse & {
    datas: {
        ctrc: ICtrc
    }
}
export type ICtrcsGetPaginationApi = IApiPagination & {
    data: ICtrc[],
}
export type ICtrcsListGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICtrcsGetPaginationApi
    }
}
export type ICtrcsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}
export type ICtrcInformationsFormData = {
    nom: string,
    date: string,
    presidentId?: string,
    notairesMembresIds?: string[],//ids des notaires
}
export type ICtrcSinistresFormData = {
    sinistres: number[],//ids des sinistres à ajouter
    sinistresToDelete: number[],//ids des sinistres à supprimer
}
export type ICtrcGetAllApiResponse = IApiCustomResponse & {
    datas: {
        ctrcs: ICtrc[]
    }
}

export type ICtrcNotaireMembrePresenceFormData = {
    notaireId: string,
    presence?: string,
}

export type ICtrcDocumentsFormData = {
    fichier?: string;
    date: string,
}


export class CtrcsService extends ApiService{
    /**
     * Permet la récupération d'un liste des ctrcs
     *
     * @returns {Promise<ICtrcsListGetApiResponse>}
     */
    public getCtrcs(formData: ICtrcsGetFormData): Promise<ICtrcsListGetApiResponse>{
        return this.doApiCall('api/ctrcs', 'GET', formData);
    }


    /**
     * Permet la récupération d'un liste des ctrcs
     *
     * @returns {Promise<ICtrcsListGetApiResponse>}
     */
    public getAll(): Promise<ICtrcGetAllApiResponse>{
        return this.doApiCall('api/ctrcs/all', 'GET');
    }


    /**
     * Permet la récupération d'un ctrc à partir de son id
     *
     * @param {string} cid
     * @returns {Promise<>}
     */
    public getCtrc(cid: string): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/' + cid, 'GET');
    }

    /**
     * Creation d'un ctrc
     *
     * @param {ICtrcInformationsFormData} formDatas
     * @returns {Promise<ICtrcGetApiResponse>}
     */
    public create(formDatas: ICtrcInformationsFormData): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/create', 'POST', formDatas);
    }


    /**
     * Modification d'un ctrc (informations)
     *
     * @param {string} cid
     * @param {ICtrcInformationsFormData} formDatas
     * @returns {Promise<ICtrcGetApiResponse>}
     */
    public update(cid: string, formDatas: ICtrcInformationsFormData): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/' + cid + '/update', 'POST', formDatas);
    }


    /**
     * Rattachement des sinistres (leurs ids) à un ctrc (informations)
     * sinistres, sinistresToDelete
     *
     * @param {string} cid
     * @param {ICtrcSinistresFormData} formDatas
     * @returns {Promise<ICtrcGetApiResponse>}
     */
    public updateSinistresRattaches(cid: string, formDatas: ICtrcSinistresFormData): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/' + cid + '/sinistres', 'POST', formDatas);
    }

    /**
     * Modification de la présence d'un notaire membre à un ctrc
     *
     * @param {string} cid
     * @param {ICtrcInformationsFormData} formDatas
     * @returns {Promise<ICtrcGetApiResponse>}
     */
    public updateNotaireMembrePresence(cid: string, formDatas: ICtrcNotaireMembrePresenceFormData): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/' + cid + '/update/notaire-membre-presence', 'POST',formDatas);
    }

    /**
     * Permet l'export des notaires membres présents à un ctrc (excel)
     * @param {string} cid
     */
    public exportNotairesMembresPresences(cid: string): Promise<IExportApiResponse>{
        return this.doApiCall('api/ctrcs/'+cid+'/notaires-membres-presences/export', 'POST');
    }

    /**
     * Permet de générer le document avec les rapports des sinistres d'un ctrc
     * @param {string} cid
     */
    public generateDocumentRapports(cid: string): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/'+cid+'/generer-document-rapports', 'POST');
    }


    /**
     * Permet la suppression d'un ctrc
     *
     * @param {number} cid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(cid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/ctrcs/' + cid + '/delete', 'POST');
    }

    /**
     * Mise à jour des documents d'un sinistre
     *
     * @param {number} cid
     * @param {ICtrcDocumentsFormData} formDatas
     * @returns {Promise<ICtrcGetApiResponse>}
     */
    public updateDocuments(cid: number, formDatas: ICtrcDocumentsFormData): Promise<ICtrcGetApiResponse>{
        return this.doApiCall('api/ctrcs/' + cid + '/documents', 'POST', formDatas);
    }


}

