import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import PageHeaderComponent from "../Page/PageHeader.component";
import LayoutComponent from "../Layout/Layout.component";

type IModalWithFormProps = {
    title?: string,
    icon?: string,
    form: JSX.Element,
    skipProtectionCheck?: boolean,
    skipPrompt?: boolean,
    onCloseModal?: () => void,
}

//Basé sur AccordionWithFormSwitchComponent
function ModalWithFormCompontent(props: IModalWithFormProps) {
    const [showConfirmation, setShowConfirmation] = useState<boolean>();

    const onClickCrossModal = () => {
        setShowConfirmation(true);
    }

    /**
     * Validation de la confirmation
     * Débloquage du contenu
     */
    const onValidationConfirmation = () => {
        setShowConfirmation(false);

        if (props.onCloseModal) {
            props.onCloseModal();
        }
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    return (
        <>
            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onValidationConfirmation} onCancel={onCancelConfirmation}
                                       text="Souhaitez-vous vraiment fermer cette fenêtre ?"/>
            }
            <div className="modal-with-form">
                <div className="overlay">
                    <button className="close icon-close" onClick={onClickCrossModal}/>
                    <LayoutComponent showMenu={false}>
                        {props.title &&
                            <PageHeaderComponent text={props.title} icon={props.icon} modificators="-in-fullscreen"/>
                        }
                        {props.form}
                    </LayoutComponent>
                </div>
            </div>
        </>
    )
}

export default ModalWithFormCompontent;