import ApiService, {IApiCustomResponse} from "./Api.service";
import {IStatistiques} from "../Models/Statistiques.model";

export type IStatistiquesImportDAPFormData = {
    logo?: string,
}

export type IOfficeStatsApiCustomResponse = IApiCustomResponse & {
    datas: {
        stats: IStatistiques[]
    }
}
export type IOfficeStatsPrintApiCustomResponse = IApiCustomResponse & {
    datas: {
        url: string
    }
}



export type IStatistiquesFormData = {
    [key:string]: string
};

export class StatistiquesService extends ApiService {

    /**
     * Import du fichier DAP
     * @param {IStatistiquesImportDAPFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public importDAP(formData: IStatistiquesImportDAPFormData): Promise<IApiCustomResponse> {
        return this.doApiCall('api/inspections/statistiques/import', 'POST', formData);
    }

    /**
     * Récupération des stats pour un office
     * @param {string} oid
     * @returns {Promise<IOfficeStatsApiCustomResponse>}
     */
    public getStatsForOffice(oid: string): Promise<IOfficeStatsApiCustomResponse> {
        return this.doApiCall(`api/inspections/statistiques/${oid}`, 'GET');
    }
    /**
     * Impression des stats pour un office
     * @param {string} oid
     * @returns {Promise<IOfficeStatsPrintApiCustomResponse>}
     */
    public printStatsForOffice(oid: string): Promise<IOfficeStatsPrintApiCustomResponse> {
        return this.doApiCall(`api/inspections/statistiques/${oid}/print`, 'GET');
    }

    /**
     * Met à jour les stats
     * @param {IStatistiquesFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public update(formData: IStatistiquesFormData): Promise<IOfficeStatsApiCustomResponse> {
        return this.doApiCall(`api/inspections/statistiques/update`, 'POST', formData);
    }
}
