import React, {useEffect, useState} from "react";
import {INotaireGetApiResponse, INotaireIdentiteFormData, NotairesService} from "src/Services/Notaires.service";
import {CommonsService} from "src/Services/Commons.service";
import useNotaire from "src/Hooks/UseNotaire";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {INotaire} from "src/Models/Notaire.model";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import NotaireCreateFormComponent from "src/Components/Forms/Annuaire/Notaires/NotaireCreateForm.component";

type INotaireCreationStepsScreenProps = {
    nid?: string,
    type: string;
    anciensNotaire?: boolean,
}
export default function NotaireCreationStepsScreen(props: INotaireCreationStepsScreenProps){
    const notairesService: NotairesService = new NotairesService();
    const commonsService: CommonsService = new CommonsService();
    const [getNotaireById] = useNotaire();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [notaire, setNotaire] = useState<INotaire>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.nid){
            getNotaireById(props.nid, (notaire: INotaire) => {
                setNotaire(notaire);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [])


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: INotaireGetApiResponse): void => {
        if(response.datas && response.datas.notaire){
            const notaire: INotaire = response.datas.notaire;
            history.push(AppConstants.pathNotaires + '/' + notaire.id);
        }
        else{
            if( props.anciensNotaire){
                history.push(AppConstants.pathNotairesAnciens);
            }
            else{
                history.push(AppConstants.pathNotaires);
            }
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitNext = (response: INotaireGetApiResponse): void => {
        if(response.datas && response.datas.notaire){
            const notaire: INotaire = response.datas.notaire;
            redirectToNextCreationStep(AppConstants.pathNotaires, notaire.id, notaire.creationSteps);
        }
        else{
            if( props.anciensNotaire){
                history.push(AppConstants.pathNotairesAnciens);
            }
            else{
                history.push(AppConstants.pathNotaires);
            }
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        if( props.anciensNotaire){
            history.push(AppConstants.pathNotairesAnciens);
        }
        else{
            history.push(AppConstants.pathNotaires);
        }
    };

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>

            {
                props.anciensNotaire &&
                    <PageHeaderComponent text="Ajouter un ancien notaire" icon="icon-notaires-add" modificators={`-in-fullscreen -border-full-screen`}/>
            }
            {
                !props.anciensNotaire &&
                    <PageHeaderComponent text="Ajouter un notaire" icon="icon-notaires-add" modificators={`-in-fullscreen -border-full-screen`}/>
            }


            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-notaires"} title={"Identité"} modificators="-margin-bottom" />
                            <NotaireCreateFormComponent isAncien={props.anciensNotaire}  submitMethod={(formData: INotaireIdentiteFormData)=>notairesService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}
