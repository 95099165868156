import React, {useEffect, useState} from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import {FilInformationsService, IFilInformationsGetFormData} from "../../Services/FilInformations.service";
import useListFilters, {IFilterData} from "../../Hooks/FiltersHandler";
import {AppConstants} from "../../Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import DateHelper from "../../Helpers/Date.helper";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {IApiPaginationLink} from "../../Services/Api.service";
import PaginationComponent from "../Pagination/Pagination.component";

const ArchivesListComponent = (props: {}) => {
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathFilInformations);

    const filInfosService: FilInformationsService = new FilInformationsService();
    const [fils, setFils] = useState<IFilInformations[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [filToDelete, setFilToDelete] = useState<number>(null);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const location = useLocation();
    const history = useHistory();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        defaultFilters.page = 1;
        updateFilters(defaultFilters);
    }, []);

    useEffect(() => {
        getList();
    }, [filters]);

    const getList = (): void => {
        if (!filters) return null;
        filInfosService.getFilInformations({
            ...filters,
            isArchive: 1
        } as IFilInformationsGetFormData).then((response) => {
            if (response && response.datas.pagination) {
                setFils(response.datas.pagination.data);
                //Pagination
                setPaginationLinks(response.datas.pagination.links);
            }
        });
    }

    const onDelete = () => {
        filInfosService.delete(filToDelete).then(() => {
            setFilToDelete(null);
            setShowConfirmation(false);
            getList();
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le fil d'informations a bien été supprimé.",
                type: "success"
            });
        }, () => {
            setFilToDelete(null);
            setShowConfirmation(false);
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue",
                type: "danger"
            });
        });
    };

    const onCancel = () => {
        setShowConfirmation(false);
    };

    return <>
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                <th className="th"><span className="link">Num</span></th>
                <th className="th"><span className="link">Titre</span></th>
                <th className="th"><span className="link">Date envoi</span></th>
                <th className="th"><span className="link">Actions</span></th>
            </tr>
            </thead>
            <tbody>

            {fils.map((fil, index) => <tr key={index} className="tr">
                <td className="td">{fil.numero}</td>
                <td className="td"><a href={AppConstants.serverUrl + "fil-infos/archives/" + fil.numero}
                                      target="_blank">{fil.titre}</a></td>
                <td className="td">{DateHelper.getFormatedDate(fil.dateEnvoi)}</td>
                <td className="td" width="40">
                    <button className="g-button -primary" onClick={() => {
                        setShowConfirmation(true);
                        setFilToDelete(fil.id);
                    }}><span className="icon icon-remove"></span></button>
                </td>
            </tr>)}
            </tbody>
        </table>

        {
            showConfirmation &&
            <ConfirmationComponent text="Supprimer ce fil ?" onConfirm={onDelete} onCancel={onCancel}/>
        }

        <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
    </>;
};

export default ArchivesListComponent;
