import React from "react";
import FormationCreationStepComponent from "src/Components/Formations/Formations/FormationCreationStep.component";

const FormationCreationComplementsScreen = (props:{
    fid: number
}) => {
    return (
        <FormationCreationStepComponent fid={props.fid} type="complements" />
    )
};

export default FormationCreationComplementsScreen;
