import {INotaireGetApiResponse, NotairesService} from "src/Services/Notaires.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {INotaire} from "src/Models/Notaire.model";
import {useHistory} from "react-router";

export default function useNotaire(){
    const notairesService: NotairesService = new NotairesService();
    const history = useHistory();

    /**
     * Récupération d'un notaire à partir de son id
     *
     * @param {string} nid
     * @param {(notaire: INotaire) => void} callback
     * @param {() => void} callbackError
     */
    const getNotaireById = (nid: string, callback:(notaire: INotaire) => void, callbackError?: () => void ) => {
        notairesService.getNotaire(nid).then((response: INotaireGetApiResponse) => {
            if( response && response.datas.notaire){
                if(callback){
                    callback(response.datas.notaire);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathNotaires);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du notaire",type: "danger"});
        });
    };
    /**
     * Récupération d'un notaire à partir de son id
     *
     * @param {string} nid
     * @param {(notaire: INotaire) => void} callback
     * @param {() => void} callbackError
     */
    const getNotaireExternalById = (nid: string, callback:(notaire: INotaire) => void, callbackError?: () => void ) => {
        notairesService.getNotaireExternal(nid).then((response: INotaireGetApiResponse) => {
            if( response && response.datas.notaire){
                if(callback){
                    callback(response.datas.notaire);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathNotaires);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du notaire",type: "danger"});
        });
    };

    return [getNotaireById,getNotaireExternalById];
};

