import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideInspectionsComponent from "src/Components/Aside/AsideInspections.component";
import InspectionsExportComponent from "src/Components/Inspections/Inspections/InspectionsExport.component";
import {
    IInspectionsCheckGenerateButtonApiResponse,
    IInspectionsGetApiResponse,
    IInspectionsGetFormData,
    InspectionsService
} from "src/Services/Inspections.service";
import {IInspection} from "src/Models/Inspection.model";
import moment from "moment";
import InspectionListTableComponent from "src/Components/Inspections/Inspections/InspectionListTable.component";
import InspectionListSuiviTable from "src/Components/Inspections/Inspections/InspectionListSuiviTable.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import LoaderComponent from "src/Components/Loading/Loader.component";
import InspectionListCourrierForm
    from "../../../Components/Forms/Inspections/Inspections/InspectionListCourrierForm.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";

type IInspectionsListScreenProps = {

}


const InspectionsListScreen = (props: IInspectionsListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const location = useLocation();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathInspections);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [inspectionsList, setInspectionsList] = useState<IInspection[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.EN_COURS);
    const [showGenerateButton, setShowGenerateButton] = useState<boolean>(false);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [isErrorGenerate, setIsErrorGenerate] = useState<boolean>(false);
    const [errorGenerate, setErrorGenerate] = useState<string>(null);
    const [isErrorTestGenerate, setIsErrorTestGenerate] = useState<boolean>(false);
    const [errorTestGenerate, setErrorTestGenerate] = useState<string>(null);


    const inspectionsService: InspectionsService = new InspectionsService();

    const previousYear: number = moment().year() - 1;
    const currentYear: number = moment().year() ;
    const nextYear: number = moment().year() + 1;


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            { text: "Inspections"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

        inspectionsService.checkCanShowGenerateButton().then((response: IInspectionsCheckGenerateButtonApiResponse) => {
            if (response && response.datas) {
                setShowGenerateButton(response.datas.canShowButton || false);
            }
        });

    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);


    useEffect(()=>{
        if(!filters) return null;
        if(activeTab === TabsSlugs.COURRIER) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setInspectionsList([]);
        setPaginationLinks(null);

        inspectionsService.getInspectionsList(activeTab, filters as IInspectionsGetFormData).then((response: IInspectionsGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newInspectionsList = response.datas.pagination.data;
                setInspectionsList(newInspectionsList);
                setPaginationLinks(response.datas.pagination.links);

                inspectionsService.checkCanShowGenerateButton().then((response: IInspectionsCheckGenerateButtonApiResponse) => {
                    if (response && response.datas) {
                        setShowGenerateButton(response.datas.canShowButton || false);
                    }
                });


                if (!loaded) {
                    setLoaded(true);
                }
            }
        });

        if (!loaded) {
            setLoaded(true);
        }
    };


    /**
     * Génération des inspections
     */
    const launchGenerationInspections = () => {
        setIsGenerating(true);
        setIsErrorGenerate(false);
        setErrorGenerate(null);

        inspectionsService.generateInspection().then((result)=>{
            launchFilteredSearch();
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La génération des inspections s'est bien déroulée.",
                type: "success"
            });
            setIsGenerating(false);
        },(result)=>{
            setIsErrorGenerate(true);
            if(result.messages && result.messages[0]) {
                setErrorGenerate(result.messages[0]);
            }
            else{
                setErrorGenerate("Une erreur est survenue lors de la génération des inspections.");
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la génération des inspections.",
                type: "danger"
            });
            setIsGenerating(false);
        })
    };


    /**
     * Génération des inspections de test
     */
    const launchGenerationInspectionsTests = () => {
        setInspectionsList([]);
        setPaginationLinks(null);

        setIsGenerating(true);
        setIsErrorTestGenerate(false);
        setErrorTestGenerate(null);


        inspectionsService.generateInspectionTest().then((result)=>{
            launchFilteredSearch();
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La génération de test des inspections s'est bien déroulée.",
                type: "success"
            });
            setIsGenerating(false);
        },(result)=>{
            setIsErrorTestGenerate(true);
            setErrorTestGenerate(result.messages[0]);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la génération des inspections.",
                type: "danger"
            });
        }).catch(() => {
            setIsGenerating(false);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la génération des inspections.",
                type: "danger"
            });
        })
    };



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Inspections"
                    icon="icon-inspections"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
                        <TabsContainerComponent onClickTabCallback={(tab)=>{updateFilters({},true); setActiveTab(tab)}} defaultTab={activeTab} >
                            <TabPanelComponent label="Année passée" icon={`icon-annee-${previousYear}`} slug={TabsSlugs.PASSEES}>
								<div className="list-content -right-gutter">
                                    <InspectionListTableComponent filters={filters}
                                                                  inspections={inspectionsList}
                                                                  setOrder={setOrder}
                                                                  updateFilters={updateFilters}
                                                                  annee={previousYear}
                                    />
									<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

									<AsideInspectionsComponent type="listExportOld" exportCallback={() => setShowExport(true)} annee={previousYear} filters={filters} />
								</div>
							</TabPanelComponent>


                            <TabPanelComponent label="Année en cours" icon={`icon-annee-${currentYear}`} slug={TabsSlugs.EN_COURS}>
								<div className="list-content -right-gutter">
                                    <InspectionListTableComponent filters={filters}
                                                                  inspections={inspectionsList}
                                                                  setOrder={setOrder}
                                                                  updateFilters={updateFilters}
                                                                  annee={currentYear}
                                    />

									<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

									<AsideInspectionsComponent type="listExport" exportCallback={() => setShowExport(true)} annee={currentYear} filters={filters} />
								</div>
							</TabPanelComponent>

                            <TabPanelComponent label="Année à venir" icon={`icon-annee-${nextYear}`} slug={TabsSlugs.A_VENIR}>
								<div className="list-content -right-gutter">
                                    {
                                        showGenerateButton &&
                                        <div className="inspections-generate">
                                            <button className={`g-button -primary ${isGenerating ? '-loading' : ''}`} onClick={launchGenerationInspections}>
                                                {!isGenerating && <span className="text">Générer {currentYear+1}</span>}
                                                {isGenerating && <LoaderComponent/>}
                                            </button>
                                            {
                                                isErrorGenerate && errorGenerate &&
                                                <p className={`form-field-errors`}>{errorGenerate}</p>
                                            }
                                        </div>
                                    }
                                    {
                                        !showGenerateButton && inspectionsList.length > 0 &&
                                        <>
                                            <InspectionListTableComponent filters={filters}
                                                                          inspections={inspectionsList}
                                                                          setOrder={setOrder}
                                                                          updateFilters={updateFilters}
                                                                          annee={nextYear}
                                            />

    
                                            <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                            <AsideInspectionsComponent type="listExportNext" exportCallback={() => setShowExport(true)} annee={nextYear}  filters={filters}  />
                                        </>
                                    }
								</div>
							</TabPanelComponent>

                            <TabPanelComponent label="Test Génération" icon={`icon-annee-${currentYear}`} slug={TabsSlugs.TEST_GENERATION}>
								<div className="list-content -right-gutter">
                                    <div className="inspections-generate">
                                        <button className={`g-button -primary ${isGenerating ? '-loading' : ''}`} onClick={() => launchGenerationInspectionsTests()}>
                                            {!isGenerating && <span className="text">Générer les tests {currentYear}</span>}
                                            {isGenerating && <LoaderComponent/>}
                                        </button>
                                        {
                                            isErrorTestGenerate && errorTestGenerate &&
                                            <p className={`form-field-errors`}>{errorTestGenerate}</p>
                                        }
                                    </div>

                                    {
                                        inspectionsList.length > 0 &&
                                        <>
                                            <InspectionListTableComponent filters={filters}
                                                                          inspections={inspectionsList}
                                                                          setOrder={setOrder}
                                                                          updateFilters={updateFilters}
                                                                          annee={currentYear}
                                                                          isTest={true}
                                            />


                                            <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                            <AsideInspectionsComponent type="listExportNext" exportCallback={() => setShowExport(true)} annee={nextYear} isTest={true} filters={filters}  />
                                        </>
                                    }
								</div>
							</TabPanelComponent>


                            <TabPanelComponent label={"Suivi"} icon={"icon-suivi"} slug={TabsSlugs.SUIVI} >
                                <div className="list-content -right-gutter">
                                    <InspectionListSuiviTable filters={filters} inspections={inspectionsList} setOrder={setOrder} updateFilters={updateFilters} />

                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                    <AsideInspectionsComponent type="listSuivi" exportCallback={() => setShowExport(true)} filters={filters}  />
                                </div>
                            </TabPanelComponent>


                            <TabPanelComponent label="Courrier" icon="icon-courrier" slug={TabsSlugs.COURRIER}>
                                <div className="g-content">
                                    <InspectionListCourrierForm onSuccessSubmit={null}/>
                                    <AsideInspectionsComponent type="courrier" exportCallback={() => setShowExport(true)} />
                                </div>
                            </TabPanelComponent>

						</TabsContainerComponent>
					</>
                }
            </div>
            <InspectionsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} />

        </LayoutComponent>
    )
};

export default InspectionsListScreen;
