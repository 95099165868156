import {IReclamation} from "src/Models/Reclamation.model";
import CommonHelper from "./Common.helper";
import {ReclamationsConstants} from "../Constants/ReclamationsConstants";
import ArrayHelper from "./Array.helper";
import {INotaire} from "../Models/Notaire.model";
import {IOption} from "../Components/Fields/Select.field.component";
import {IReclamationPlaignant} from "../Models/ReclamationPlaignant.model";
import {deprecate} from "util";
import {AppConstants} from "../Constants/AppConstants";

export default class ReclamationsHelper{


    /**
     * Permet de récupérer le la formule d'appel de l'adresse
     *
     * @param {string} value
     * @returns {string}
     */
    public static getFormatedAdresseFormuleAppel(value: string){
        return CommonHelper.getFormatedElementFromOptions(value, ReclamationsConstants.formuleAppelPlaignantOptions);

    }


    /**
     * Permet de récupérer le nom du notaire syndic initial
     *
     * @param {IReclamation} reclamation
     * @returns {string}
     */
    public static getNomSyndicInitial(reclamation: IReclamation): string {
        let nom: string = "";

        if(reclamation.syndicInitial){
            nom = reclamation.syndicInitial.nom + " " + reclamation.syndicInitial.prenom;
        }

        return nom;
    }

    /**
     * Permet de récupérer les noms des plaignants
     *
     * @param {IReclamation} reclamation
     * @param {string} separator
     * @returns {string}
     */
    public static getNomPlaignants(reclamation: IReclamation, separator: string = ', '): string {
        let plaignants: string[] = [];
        let nom: string;

        for(let p of reclamation.plaignants){
            plaignants.push(`${p.nom} ${p.prenom ? p.prenom : ''}`);
        }

        plaignants = ArrayHelper.orderArrayAlphabetical(plaignants);
        nom = plaignants.join(separator);

        if (reclamation.origine == 'avocat') {
            nom += ` avocat`;
            
            if(reclamation.nomClient){
                nom += ` de ${reclamation.nomClient}`;
            }
        }

        return nom;
    }

    /**
     * Permet de récupérer les noms des notaires mis en cause
     *
     * @param {IReclamation} reclamation
     * @param {string} separator
     * @returns {string}
     */
    public static getNomMisEnCause(reclamation: IReclamation, separator: string = ' / '): string{
        let misEnCause: string[] = [];

        for(let n of reclamation.notaires){
            misEnCause.push(`${n.nom} ${n.prenom}`);
        }

        misEnCause = ArrayHelper.orderArrayAlphabetical(misEnCause);

        return misEnCause.join(separator);
    }


    /**
     * Permet de récupérer l'état formaté
     *
     * @param {string} etat
     * @returns {string}
     */
    public static getFormatedEtat(etat: string): string{
        return CommonHelper.getFormatedElementFromOptions(etat, ReclamationsConstants.etatOptions);
    }


    /**
     * Permet de récupérer l'autre état (motif) formaté
     *
     * @param {string} etat
     * @returns {string}
     */
    public static getFormatedEtatAutre(etatAutre: string): string{
        return CommonHelper.getFormatedElementFromOptions(etatAutre, ReclamationsConstants.etatAutreOptions);
    }


    /**
     * Permet de récupérer la civilite formatée
     *
     * @param {string} etat
     * @returns {string}
     */
    public static getFormatedCivilite(etat: string): string{
        return CommonHelper.getFormatedElementFromOptions(etat, ReclamationsConstants.civilitePlaignantOptions);
    }


    /**
     * Permet de récupérer le type de document formaté
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedDocumentTypes(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.documentsTypesOptions);
    }

    /**
     * Permet de récupérer l'objet formaté
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedObjet(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.objetOptions);
    }

    /**
     * Permet de récupérer le fondement formaté
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedFondement(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.fondementsOption);
    }

    /**
     * Permet de récupérer l'origine formaté'
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedOrigine(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.origineOptions);
    }

    /**
     * Permet de récupérer l'issue de la conciliation formatée
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedIssueConciliation(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.conciliationIssueOptions);
    }

    /**
     * Permet de récupérer le motif de la conciliation formaté
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedMotifConciliation(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.conciliationMotifOptions);
    }

    /**
     * Permet de formater une liste de réclamations pour les selects
     *
     * @param {IReclamationPlaignant[]} plaignants
     * @returns {IOption[]}
     */
    static formatListForOptions(plaignants: IReclamationPlaignant[]): IOption[]{
        if(!plaignants) return [];

        const options: IOption[] = [];
        for(let plaignant of plaignants){
            const option: IOption = {
                value: plaignant.id.toString(),
                label: plaignant.nom + " " + plaignant.prenom
            };

            options.push(option)

        }

        return options;
    }


    /**
     * Permet la récupération du nom d'un notaire pour un document
     *
     * @param {IReclamation} reclamation
     * @param {number} notaireId
     * @returns {string}
     */
    public static getNotaireNomForDocument(reclamation: IReclamation, notaireId: number): string{
        if(!notaireId) return null;

        const notaire = reclamation.notaires.find((n) => n.id === notaireId);
        if(!notaire) return null;

        return notaire.prenom + ' ' + notaire.nom;
    }

    /**
     * Permet de récupérer le type de personne formaté
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedTypePersonne(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, ReclamationsConstants.typesPersonnesOptions);
    }

}
