import {IApiCustomResponse} from "../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "../Constants/AppConstants";
import {ITableauBordGetApiResponse, TableauxBordService} from "../Services/TableauxBord.service";
import {ITableauBord} from "../Models/TableauBord.model";



export default function useTableauBord(){
    const tableauxBordService: TableauxBordService = new TableauxBordService();
    const history = useHistory();

    /**
     * Récupération d'un tableau de bord à partir de son id
     *
     * @param {string} tid
     * @param {(tableauBord: ITableauBord) => void} callback
     * @param {() => void} callbackError
     */
    const getTableauBordById = (tid: string, callback:(tableauBord: ITableauBord) => void, callbackError?: () => void ) => {
        tableauxBordService.getTableauBord(tid).then((response: ITableauBordGetApiResponse) => {
            if( response && response.datas.tableauBord){
                if(callback){
                    callback(response.datas.tableauBord);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathTableauBord);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du tableau de bord'",type: "danger"});
        });
    };


    /**
     * Récupération d'un tableau de bord à partir de son id
     *
     * @param {string} tid
     * @param {(tableauBord: ITableauBord) => void} callback
     * @param {() => void} callbackError
     */
    const getTableauBordExternalById = (tid: string, callback:(tableauBord: ITableauBord) => void, callbackError?: () => void ) => {
        tableauxBordService.getTableauBordExternal(tid).then((response: ITableauBordGetApiResponse) => {
            if( response && response.datas.tableauBord){
                if(callback){
                    callback(response.datas.tableauBord);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathTableauBord);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du tableau de bord'",type: "danger"});
        });
    };


    return {getTableauBordById, getTableauBordExternalById};
};

