import ApiService, {IApiCustomResponse} from "./Api.service";
import {IDocument} from "../Models/Document.model";

export type IDocumentGetApiResponse = IApiCustomResponse & {
    datas: {
        document: IDocument
    }
}


export class DocumentsService extends ApiService{
    /**
     * Permet la récupération d'un document à partir de son id
     *
     * @param {string} did
     * @returns {Promise<IDocumentGetApiResponse>}
     */
    public getDocument(did: string): Promise<IDocumentGetApiResponse>{
        return this.doApiCall('api/documents/' + did, 'GET');
    }

    /**
     * Permet la récupération d'un document à partir de son id
     *
     * @param {string} did
     * @returns {Promise<IDocumentGetApiResponse>}
     */
    public getDocumentExternal(did: string): Promise<IDocumentGetApiResponse>{
        return this.doApiCall('api/external/documents/' + did, 'GET');
    }
}




