import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {IFilInformations} from "../Models/FilInformations.model";
import {
    FilInformationsService,
    IFilInformationsDetailsGetApiResponse,
} from "../Services/FilInformations.service";

export default function UseFilInformations(){
    const filService: FilInformationsService = new FilInformationsService();
    const history = useHistory();

    /**
     * Récupération d'un fil d'informations à partir de son id
     *
     * @param {string} fid
     * @param {(filInformations: IFormation) => void} callback
     * @param {() => void} callbackError
     */
    const getFilInformationsById = (fid: string, callback:(filInformations: IFilInformations) => void, callbackError?: () => void ) => {
        filService.get(fid).then((response: IFilInformationsDetailsGetApiResponse) => {
            if( response && response.datas.filInformations){
                if(callback){
                    callback(response.datas.filInformations);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathFilInformations);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du  fil d'informations",type: "danger"});
        });
    };

    return {getFilInformationsById};
};

