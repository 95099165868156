import {IOfficeGetApiResponse, IOfficeChiffresFormData} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import useCreationSteps from "src/Hooks/useCreationSteps";


type IOfficeChiffresFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IOfficeChiffresFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeChiffresFormComponent(props: IOfficeChiffresFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const office: IOffice = props.office;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'number',
                fieldName: "nbSalaries",

                label: "Nombre de salariés",
                placeholder: "Nombre de salariés",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.nbSalaries ? office.nbSalaries.toString() : "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'number',
                fieldName: "nbActes",

                label: "Nombre d'actes",
                placeholder: "Nombre d'actes",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.nbActes ? office.nbActes.toString() : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'number',
                fieldName: "nbSuccessions",

                label: "Nombre de successions",
                placeholder: "Nombre de successions",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.nbSuccessions ? office.nbSuccessions.toString() : "",
            },
        ];


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeChiffresFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: !props.isOnCreationStep ? "Les informations de l'office ont bien été modifiée." : "L'office a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
