import ApiService, {IApiCustomResponse} from "./Api.service";
import {IModule} from "src/Models/Module.model";
import {IOption} from "src/Components/Fields/Select.field.component";

export type IGetModuleApiResponse = IApiCustomResponse & {
    datas: {
        modules: IModule[]
    }
}


export class ModulesService extends ApiService {
    /**
     * Permet la récupération de la liste des modules
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getModules(): Promise<IGetModuleApiResponse>{
        return this.doApiCall('api/modules', 'GET');
    }


    /**
     * Permet de formater une liste de module en liste d'options pour un select
     *
     * @param {IModule[]} modules
     * @returns {IOption[]}
     */
    public formatModulesForSelect(modules: IModule[]): IOption[] {
        const options: IOption[] = [];
        for(let module of modules){
            const option: IOption = {
                value: module.slug,
                label: module.nom
            }

            options.push(option)
        }

        return options;
    }

}
