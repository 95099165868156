import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {ISuccession} from "../Models/Succession.model";

export type ISuccessionGetApiResponse = IApiCustomResponse & {
    datas: {
        succession: ISuccession
    }
}

export type ISuccessionGetPaginationApi = IApiPagination & {
    data: ISuccession[],
}
export type ISuccessionsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ISuccessionGetPaginationApi
    }
}
export type ISuccessionsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    etat?: string[],
}
export type ISuccessionEtatFormData = {
    etat: string,
}
export type ISuccessionDemandeurFormData = {
    etat: string,
}
export type ISuccessionDefuntFormData = {
    etat: string,
}
export type ISuccessionInformationsFormData = {
    etat: string,
}
export type ISuccessionReponseExternalFormData = {
    officeId: string,
    notaireId: string,
    commentaires: string,
    accept: string,
}
export type ISuccessionCreationFormData = {
    identiteNom: string,
    identiteDecesDate: string,
    demandeurMotif: string,
    identitePrenom: string,
    identiteDecesDepartement: string,
}



export class SuccessionsService extends ApiService{
    /**
     * Permet la suppression d'une succession
     *
     * @param {number} sid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(sid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/successions/' + sid + '/delete', 'POST');
    }

    /**
     * Permet la récupération d'une succession à partir de son id
     *
     * @param {string} sid
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public getSuccession(sid: string): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/successions/' + sid, 'GET');
    }
    public getSuccessionExternal(sid: string): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/external/successions/' + sid, 'GET');
    }

    /**
     * Permet la récupération d'une liste de successions
     *
     * @returns {Promise<ISuccessionsGetApiResponse>}
     */
    public getSuccessions(formData: ISuccessionsGetFormData): Promise<ISuccessionsGetApiResponse>{
        return this.doApiCall('api/successions', 'GET', formData);
    }

    /**
     * Permet la creation d'une succession
     *
     * @returns {Promise<ISuccessionsGetApiResponse>}
     */
    public createInternal(formData: ISuccessionCreationFormData): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/successions/create-internal', 'POST', formData);
    }


    /**
     * Modification de l'état d'une succession
     *
     * @param {string} sid
     * @param {ISuccessionEtatFormData} formData
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public updateEtat(sid: string, formData: ISuccessionEtatFormData): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/successions/' + sid + "/etat", "POST", formData);
    }


    /**
     * Modification des informations d'une succession
     *
     * @param {string} sid
     * @param {ISuccessionInformationsFormData} formData
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public updateInformation(sid: string, formData: ISuccessionInformationsFormData): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/successions/' + sid + "/informations", "POST", formData);
    }


    /**
     * Modification de les informations du defunt d'une succession
     *
     * @param {string} sid
     * @param {ISuccessionDefuntFormData} formData
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public updateDefunt(sid: string, formData: ISuccessionDefuntFormData): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/successions/' + sid + "/defunt", "POST", formData);
    }


    /**
     * Modification des informations du demandeur d'une succession
     *
     * @param {string} sid
     * @param {ISuccessionDemandeurFormData} formData
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public updateDemandeur(sid: string, formData: ISuccessionDemandeurFormData): Promise<ISuccessionGetApiResponse>{
        return this.doApiCall('api/successions/' + sid + "/demandeur", "POST", formData);
    }


    /**
     * Réponse à une succession
     *
     * @param {string} sid
     * @param {ISuccessionReponseExternalFormData} formData
     * @param {boolean} forceAccept
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public reponse(sid: string, formData: ISuccessionReponseExternalFormData, forceAccept?: boolean): Promise<ISuccessionGetApiResponse>{
        if( forceAccept ){
            formData = {
                ...formData,
                accept: "true",
            }
        }

        return this.doApiCall('api/successions/' + sid + "/reponse", "POST", formData);
    }


    /**
     * Réponse à une succession
     *
     * @param {string} sid
     * @param {ISuccessionReponseExternalFormData} formData
     * @param {boolean} forceAccept
     * @returns {Promise<ISuccessionGetApiResponse>}
     */
    public reponseExternal(sid: string, formData: ISuccessionReponseExternalFormData, forceAccept?: boolean): Promise<ISuccessionGetApiResponse>{
        if( forceAccept ){
            formData = {
                ...formData,
                accept: "true",
            }
        }

        return this.doApiCall('api/external/successions/' + sid + "/reponse", "POST", formData);
    }
}




