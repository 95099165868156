import React from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import FileHelper from "../../Helpers/File.helper";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import DocumentLink from "../Documents/DocumentLink.component";


const FilInformationsActualites = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.actualites.map((actu, actuIndex) =>
                <React.Fragment key={actuIndex}>
                    <div className="columns">
                        <div className="column -size-2">
                            <div className="informations-block">
                                <p className="label">Titre</p>
                                <p className="value">{actu.titre}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Texte</p>
                                <div className="value">
                                    {
                                        actu.texte &&
                                        <CommentairesComponent commentaires={actu.texte} modificators={"-full"}/>
                                    }
                                    {
                                        !actu.texte &&
                                        <>-</>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="column -auto">
                            <div className="informations-block">
                                <p className="label">Image</p>
                                {
                                    actu.image &&
                                    <figure className="informations-image -resume">
                                        <img src={FileHelper.getImageUrlFromSize(actu.image, "thumbnail")} alt="" className="image"/>
                                    </figure>
                                }
                                {
                                    !actu.image &&
                                    <div className="value">
                                        -
                                    </div>
                                }
                            </div>
                            <div className="informations-block">
                                <p className="label">Lien</p>
                                <div className="value">
                                    {
                                        !actu.lien &&
                                        <>-</>
                                    }
                                    {
                                        actu.lien &&
                                        <a href={actu.lien} target="_blank" className="g-link">{actu.lien}</a>
                                    }
                                </div>
                            </div>

                            <div className="informations-block">
                                <p className="label">Documents</p>
                                <div className="value">
                                    {
                                        actu.documents && actu.documents.map((d, dIndex) =>
                                            <p className="informations-document-wrapper" key={dIndex}>
                                                <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(d.fichier)}/>
                                            </p>
                                        )
                                    }
                                    {
                                        !actu.documents || (actu.documents.length === 0) &&
                                        <>-</>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </React.Fragment>
            )}
        </div>
    )

};

export default FilInformationsActualites;
