import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";
import { IDifferend } from "../Models/Differend.model";

export type IDifferendGetApiResponse = IApiCustomResponse & {
    datas?: {
        differend: IDifferend
    }
}
export type IDifferendsGetPaginationApi = IApiPagination & {
    data: IDifferend[],
}
export type IDifferendsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IDifferendsGetPaginationApi
    }
}
export type IDifferendsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    etat?: string[],
    advanced?: number
}
export type IDifferendNotairesFormData = {
    originePlaignant: "interne" | "externe",
    origineMisEnCause: "interne" | "externe",

    notairePlaignantId?: string,
    notairePlaignantNom?: string,
    notairePlaignantGroupeId?: string,
    notairePlaignantPredidentId?: string,

    notaireMisEnCauseId?: string,
    notaireMisEnCauseNom?: string,
    notaireMisEnCauseGroupeId?: string,
    notaireMisEnCausePredidentId?: string,
}
export type IDifferendDifferendFormData = {
}
export type IDifferendDocumentsFormData = {
}

export type IDifferendCourrierFormData = {
    numeroFacture: number,
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}

export class DifferendsService extends ApiService {

    /**
     * Permet la mise à jour des notaires d'un differend
     * @param {IDifferendNotairesFormData} formData
     * @param {number} did
     * @returns {Promise<IApiCustomResponse>}
     */
    public notaires(formData: IDifferendNotairesFormData, did?: number): Promise<IApiCustomResponse> {
        return this.doApiCall(`api/differends/${typeof did !== "undefined" ? `${did}/` : ''}notaires`, 'POST', formData);
    }
    /**
     * Permet la création d'un differend
     *
     * @param {IDifferendNotairesFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public create(formData: IDifferendNotairesFormData): Promise<IApiCustomResponse> {
        return this.doApiCall(`api/differends/create`, 'POST', formData);
    }

    /**
     * Permet la mise à jour des infos d'un differend
     *
     * @param {IDifferendsGetFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public differend(formData: IDifferendDifferendFormData, did: number): Promise<IApiCustomResponse> {
        return this.doApiCall('api/differends/' + did + '/differend', 'POST', formData);
    }

    /**
     * Permet la suppression d'un differend
     *
     * @param {number} did
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(did: number): Promise<IApiCustomResponse> {
        return this.doApiCall('api/differends/' + did + '/delete', 'POST');
    }

    /**
     * Permet la récupération d'un differend à partir de son id
     *
     * @param {string} did
     * @returns {Promise<IDifferendGetApiResponse>}
     */
    public getDifferend(did: string): Promise<IDifferendGetApiResponse> {
        return this.doApiCall('api/differends/' + did, 'GET');
    }

    /**
     * Permet la récupération d'une liste de differends
     *
     * @returns {Promise<IDifferendsGetApiResponse>}
     */
    public getDifferends(formData: IDifferendsGetFormData): Promise<IDifferendsGetApiResponse> {
        return this.doApiCall('api/differends', 'GET', formData);
    }

    /**
     * Met à jour les documents d'un différend
     * @param {number} did
     * @param {IDifferendDocumentsFormData} formDatas
     * @returns {Promise<ISinistreGetApiResponse>}
     */
    public updateDocuments(did: number, formDatas: IDifferendDocumentsFormData): Promise<IDifferendGetApiResponse>{
        return this.doApiCall('api/differends/' + did + '/documents', 'POST', formDatas);
    }


    public downloadCourrier(did: number, formDatas: IDifferendCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/differends/' + did + '/courrier/download', 'POST', formDatas);
    }

    public sendCourrier(did: number, formDatas: IDifferendCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/differends/' + did + '/courrier/send', 'POST', formDatas);
    }

    public saveCourrier(did: number, formDatas: IDifferendCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/differends/' + did + '/courrier/save', 'POST', formDatas);
    }

}




