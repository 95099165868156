import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {
    IReclamationAdressesPlaignantsFormData,
    IReclamationCoordonneesPlaignantFormData,
    IReclamationDocumentsFormData,
    IReclamationInformationsFormData, IReclamationNotairesFormData,
    IReclamationPlaignantsFormData,
    ReclamationsService
} from "src/Services/Reclamations.service";
import UseReclamation from "src/Hooks/UseReclamation";
import {IReclamation} from "src/Models/Reclamation.model";
import AsideReclamationsComponent from "src/Components/Aside/AsideReclamations.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import ReclamationDocumentsComponent from "src/Components/Reclamations/ReclamationDocuments.component";
import ReclamationDocumentsFormComponent from "src/Components/Forms/Reclamations/ReclamationDocumentsForm.component";
import ReclamationCourrierForm from "src/Components/Forms/Reclamations/ReclamationCourrierForm.component";
import ReclamationPlaignantsFormComponent from "src/Components/Forms/Reclamations/ReclamationPlaignantsForm.component";
import ReclamationPlaignantsComponent from "src/Components/Reclamations/ReclamationPlaignants.component";
import ReclamationInformationsFormComponent
    from "src/Components/Forms/Reclamations/ReclamationInformationsForm.component";
import ReclamationInformationsComponent from "src/Components/Reclamations/ReclamationInformations.component";
import ReclamationCoordonneesPlaignantsFormComponent
    from "src/Components/Forms/Reclamations/ReclamationCoordonneesPlaignantForm.component";
import ReclamationCoordonneesPlaignantsComponent
    from "src/Components/Reclamations/ReclamationCoordonneesPlaignants.component";
import ReclamationAdressesPlaignantsComponent
    from "src/Components/Reclamations/ReclamationAdressesPlaignants.component";
import ReclamationAdressesPlaignantsFormComponent
    from "src/Components/Forms/Reclamations/ReclamationAdressesPlaignantsForm.component";
import ReclamationNotairesComponent from "../../Components/Reclamations/ReclamationNotaires.component";
import ReclamationNotairesFormComponent from "../../Components/Forms/Reclamations/ReclamationNotairesForm.component";
import ReclamationsHelper from "../../Helpers/Reclamations.helper";
import ReclamationConciliatonComponent from "../../Components/Reclamations/ReclamationConciliation.component";
import ReclamationConciliationFormComponent
    from "../../Components/Forms/Reclamations/ReclamationConciliationForm.component";


type IReclamationFicheScreenScreenProps = {
    rid: string
}
export default function ReclamationFicheScreen(props: IReclamationFicheScreenScreenProps) {
    const documentsAccordionRef = useRef(null);
    const plaignantsAccordionRef = useRef(null);
    const adressesPlaignantRef = useRef(null);
    const coordonneesPlaignantRef = useRef(null);
    const notairesAccordionRef = useRef(null);
    const conciliationAccordionRef = useRef(null);

    const reclamationsService: ReclamationsService = new ReclamationsService();

    const {getReclamationById} = UseReclamation();
    const [reclamation, setReclamation] = useState<IReclamation>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [formOpenList, setFormOpenList] = useState<string[]>([]);
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if (!reclamation) return;

        const breadcrumbLinks = [
            {text: "Réclamations", link: AppConstants.pathReclamations},
            {text: `Réclamation de ${getPlaignantsNames()}`},
        ];
        setBreadcrumbLinks(breadcrumbLinks);


    }, [reclamation, loaded]);


    useEffect(() => {
        if (!props.rid) {
            history.push(AppConstants.pathReclamations);
        }

        getReclamation();

    }, [props.rid])


    /**
     * Récupération d'une réclamation selon l'id
     */
    const getReclamation = (): void => {
        getReclamationById(props.rid, (reclamation: IReclamation) => {
            setReclamation(reclamation);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getReclamation();

        closeForms();
    };

    /**
     * Callback au succès du submit de l'envoi de courrier
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitCourrierForm = (response: IApiCustomResponse): void => {
        getReclamation();
    };
    const onFileDownload = (): void => {
        getReclamation();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if ((documentsAccordionRef && documentsAccordionRef.current)) {
            documentsAccordionRef.current.hideForm(isCancel);
        }
        if ((plaignantsAccordionRef && plaignantsAccordionRef.current)) {
            plaignantsAccordionRef.current.hideForm(isCancel);
        }
        if ((adressesPlaignantRef && adressesPlaignantRef.current)) {
            adressesPlaignantRef.current.hideForm(isCancel);
        }
        if ((coordonneesPlaignantRef && coordonneesPlaignantRef.current)) {
            coordonneesPlaignantRef.current.hideForm(isCancel);
        }
        if ((notairesAccordionRef && notairesAccordionRef.current)) {
            notairesAccordionRef.current.hideForm(isCancel);
        }
        if ((conciliationAccordionRef && conciliationAccordionRef.current)) {
            conciliationAccordionRef.current.hideForm(isCancel);
        }
    };

    /**
     * Permet de récupérer le nom des plaignants
     *
     * @returns {string}
     */
    const getPlaignantsNames = (): string => {
        const plaignantsNames: string[] = [];

        for (let plaignant of reclamation.plaignants) {
            plaignantsNames.push(`${ReclamationsHelper.getFormatedCivilite(plaignant.civilite)} ${plaignant.prenom ? plaignant.prenom  : ''} ${plaignant.nom}`);
        }

        return plaignantsNames.join(', ');
    }

    /**
     * Récupération du form qui vient de s'afficher
     *
     * @param {string} ref
     */
    const onFormOpen = (ref: string): void => {
        let newFormOpenList = [...formOpenList];

        if (!newFormOpenList.includes(ref)) {
            newFormOpenList.push(ref);

            setFormOpenList(newFormOpenList);
        }
    }

    /**
     * Récupération du form qui vient de se fermer
     * @param {string} ref
     */
    const onFormClose = (ref: string): void => {
        let newFormOpenList = [...formOpenList];

        if (newFormOpenList.includes(ref)) {
            newFormOpenList = newFormOpenList.filter(r => r !== ref);
            setFormOpenList(newFormOpenList);
        }
    }


    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks}
                                         text={`Réclamation de ${getPlaignantsNames()}`} icon="icon-reclamations"
                                         modificators="" onSearch={null}/>


                    <TabsContainerComponent onClickTabCallback={() => {
                        closeForms();
                        setFormOpened(false);
                    }}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={notairesAccordionRef}
                                    title="Notaire(s) concerné(s)"
                                    icon="icon-notaires"
                                    contentShowedOnInit={true}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    skipPrompt={false}
                                    onFormOpen={() => {
                                        onFormOpen('notairesAccordionRef');
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        onFormClose('notairesAccordionRef');
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationNotairesFormComponent reclamation={reclamation}
                                                                            submitMethod={(formData: IReclamationNotairesFormData) => reclamationsService.updateNotaires(reclamation.id, formData)}
                                                                            onSuccessSubmit={onSuccessSubmitForm}
                                                                            onCancel={onCancelForm}/>}
                                    infos={<ReclamationNotairesComponent reclamation={reclamation}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={plaignantsAccordionRef}
                                    title="Plaignants"
                                    icon="icon-plaignant"
                                    contentShowedOnInit={true}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    skipPrompt={false}
                                    onFormOpen={() => {
                                        onFormOpen('notairesAccordionRef');
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        onFormClose('notairesAccordionRef');
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationPlaignantsFormComponent reclamation={reclamation}
                                                                              submitMethod={(formData: IReclamationPlaignantsFormData) => reclamationsService.updatePlaignants(reclamation.id, formData)}
                                                                              onSuccessSubmit={onSuccessSubmitForm}
                                                                              onCancel={onCancelForm}/>}
                                    infos={<ReclamationPlaignantsComponent reclamation={reclamation}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={adressesPlaignantRef}
                                    title="Adresses du plaignant"
                                    icon="icon-coordonnees"
                                    contentShowedOnInit={true}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    skipPrompt={true}
                                    onFormOpen={() => {
                                        onFormOpen('adressesPlaignantRef');
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        onFormClose('adressesPlaignantRef');
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationAdressesPlaignantsFormComponent
                                        adresses={reclamation.plaignantsAdresses} plaignants={reclamation.plaignants}
                                        submitMethod={(formData: IReclamationAdressesPlaignantsFormData) => reclamationsService.updateAdressesPlaignants(reclamation.id, formData)}
                                        onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm}/>}
                                    infos={<ReclamationAdressesPlaignantsComponent
                                        adresses={reclamation.plaignantsAdresses} plaignants={reclamation.plaignants}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={coordonneesPlaignantRef}
                                    title="Coordonnées du plaignant"
                                    icon="icon-coordonnees-adresses"
                                    contentShowedOnInit={true}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    skipPrompt={false}
                                    onFormOpen={() => {
                                        onFormOpen('coordonneesPlaignantRef');
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        onFormClose('coordonneesPlaignantRef');
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationCoordonneesPlaignantsFormComponent
                                        coordonnees={reclamation.plaignantsCoordonnees}
                                        plaignants={reclamation.plaignants}
                                        submitMethod={(formData: IReclamationCoordonneesPlaignantFormData) => reclamationsService.updateCoordonneesPlaignants(reclamation.id, formData)}
                                        onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm}/>}
                                    infos={<ReclamationCoordonneesPlaignantsComponent
                                        coordonnees={reclamation.plaignantsCoordonnees}
                                        plaignants={reclamation.plaignants}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Réclamation"
                                    icon="icon-reclamations"
                                    contentShowedOnInit={true}
                                    skipPrompt={false}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    onFormOpen={() => {
                                        onFormOpen('documentsAccordionRef');
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        onFormClose('documentsAccordionRef');
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationInformationsFormComponent reclamation={reclamation}
                                                                                submitMethod={(formData: IReclamationInformationsFormData) => reclamationsService.updateInformations(reclamation.id, formData)}
                                                                                onSuccessSubmit={onSuccessSubmitForm}
                                                                                onCancel={onCancelForm}/>}
                                    infos={<ReclamationInformationsComponent reclamation={reclamation}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={conciliationAccordionRef}
                                    title="Conciliation"
                                    icon="icon-commentaires"
                                    contentShowedOnInit={true}
                                    skipPrompt={false}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    onFormOpen={() => {
                                        onFormOpen('conciliationAccordionRef');
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        onFormClose('conciliationAccordionRef');
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationConciliationFormComponent reclamation={reclamation}
                                                                                submitMethod={(formData: IReclamationInformationsFormData) => reclamationsService.updateConciliation(reclamation.id, formData)}
                                                                                onSuccessSubmit={onSuccessSubmitForm}
                                                                                onCancel={onCancelForm}/>}
                                    infos={<ReclamationConciliatonComponent reclamation={reclamation}/>}
                                />

                                <AsideReclamationsComponent type="fiche" reclamation={reclamation}
                                                            hideDeleteButton={formOpenList && formOpenList.length > 0}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Documents" icon="icon-documents">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="reclamation"
                                    contentId={reclamation.id}
                                    onFormOpen={() => {
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<ReclamationDocumentsFormComponent reclamation={reclamation}
                                                                             submitMethod={(formData: IReclamationDocumentsFormData) => reclamationsService.updateDocuments(reclamation.id, formData)}
                                                                             onSuccessSubmit={onSuccessSubmitForm}
                                                                             onCancel={onCancelForm}/>}
                                    infos={<ReclamationDocumentsComponent reclamation={reclamation}/>}
                                />

                                <AsideReclamationsComponent type="fiche" reclamation={reclamation}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Courriers" icon="icon-courrier">
                            <div className="fiche-content -right-gutter border-footer">
                                <div className="g-content">
                                    <ReclamationCourrierForm reclamation={reclamation}
                                                             onSuccessSubmit={onSuccessSubmitCourrierForm}
                                                             onFileDownload={onFileDownload}/>
                                </div>

                                <AsideReclamationsComponent type="fiche" reclamation={reclamation}/>
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
