import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {
    IApiCustomResponse,
    IApiErrorMessage,
    IApiFieldsErrorMessages,
    IFileDownloadApiResponse
} from "src/Services/Api.service";
import CommonHelper from "src/Helpers/Common.helper";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypesGetApiResponse} from "src/Services/Courriers.service";
import CourriersHelper from "src/Helpers/Courriers.helper";
import UseFileDownload from "src/Hooks/UseFileDownload";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "../../../Shared/config";
import {CotisationsService, ICotisationsSaisieNotaireCourrierFormData} from "../../../Services/Cotisations.service";
import moment, {Moment} from "moment";
import {IOption} from "../../Fields/Select.field.component";
import {IOfficesGetAllApiResponse, OfficesService} from "../../../Services/Offices.service";
import {IOffice} from "../../../Models/Office.model";
import OfficesHelper from "../../../Helpers/Offices.helper";

type ICotisationsListeOfficeCourrierFormProps = {
}

export default function CotisationsListeOfficeCourrierForm(props: ICotisationsListeOfficeCourrierFormProps){
    const [offices,setOffices] = useState<IOffice[]>([]);
    const [officeLoaded,setOfficesLoaded] = useState<boolean>(false);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const cotisationsService: CotisationsService = new CotisationsService();

    const {handleFileDownload} = UseFileDownload();

    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();

        courriersService.getCourriersTypesForModule('cotisations/offices').then((response: ICourriersTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                setCourriersTypes(response.datas.types);
            }
        });

        const officesService: OfficesService = new OfficesService();
        officesService.getAllOfficesPrincipalesByCRPCEN().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });
    }, [])




    useEffect(()=> {
        if(!courriersTypes || !courriersTypes.length) return;

        const anneesOptions: IOption[] = [];
        const currentDate:Moment = moment();
        const currentYear: string = currentDate.format("YYYY");

        for( let i = parseInt(currentYear) - 2, j = parseInt(currentYear); i <= j; i++){
            anneesOptions.push({
                    label: i.toString(),
                    value: i.toString()
                }
            )
        }


        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "annee",

                label: "Année",
                required: true,
                modificators: "-on-white",
                hideSearchOnMultiple: true,

                oldValue: "",
                options: anneesOptions,
            },
            {
                type: 'selectMultiple',
                fieldName: "offices",

                label: "Office(s)",
                optionsGroup: offices && offices.length ? OfficesHelper.formatListForOptionsGroup(offices) : [],
                required: true,
                modificators: "-on-white",

                oldValue: "",
            },
            {
                type: 'select',
                fieldName: "type",

                label: "Type de courrier",
                placeholder: "Type de courrier",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: courriersTypes ? CourriersHelper.formatCourriersTypesForOptions(courriersTypes) : []
            },
        ];

        for(let type of courriersTypes){
            formElementsColumn1.push(
                {
                    type: 'text',
                    fieldName: "subjectMail",

                    label: "Sujet du mail",
                    placeholder: "Sujet de l'email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.sujet ? type.sujet : "",

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenu",

                    label: "Contenu",
                    placeholder: "Contenu",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.modele ? type.modele : "",
                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenuMail",

                    label: "Contenu Email",
                    placeholder: "Contenu Email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.contenuMail ? type.contenuMail : "",

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
        }

        formElementsColumn1.push(
            {
                type: 'radio',
                fieldName: "afficherEntete",

                label: "Afficher l'entête",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            }
        );

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Télécharger",
                modificators: "-primary",
                icon: "icon-download",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onDownload(formData,onActionEnded)}
            },
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false,onActionEnded)}
            },
            {
                label: "Envoyer un test",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, true,onActionEnded)}
            }
        ];
        setFormActions(currentFormActions);

    }, [courriersTypes, officeLoaded]);


    /**
     * Gestion du téléchargement du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {() => void} onActionEnded
     */
    const onDownload = (formData: FormComponentFormData, onActionEnded?: ()=>void): void => {
        cotisationsService.downloadCourrierOffice(formData as ICotisationsSaisieNotaireCourrierFormData).then((response: IFileDownloadApiResponse) => {
            if(onActionEnded) onActionEnded();

            if(response && response.datas && response.datas.file){
                handleFileDownload(response.datas.file,true);
            }


        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                setErrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors du téléchargement du courrier.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     * @param {() => void} onActionEnded
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: ()=>void): void => {
        const formDataUpdated: ICotisationsSaisieNotaireCourrierFormData = {
            ...formData as ICotisationsSaisieNotaireCourrierFormData,
            isDebug: isDebug ?? false
        }

        cotisationsService.sendCourrierOffice(formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de l'envoi du courrier.",
                    type: "danger"
                });
            }
        });
    }

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
