import {INegociateur} from "src/Models/Negociateur.model";
import NegociateursHelper from "src/Helpers/Negociateurs.helper";
import OfficesHelper from "src/Helpers/Offices.helper";
import CommonHelper from "../../../Helpers/Common.helper";
import CoordonneesHelper from "../../../Helpers/Coordonnees.helper";

type IOfficeNegociateursInfosComponentProps = {
    negociateurs: INegociateur[],
}

export default function OfficeNegociateursInfosComponent(props: IOfficeNegociateursInfosComponentProps) {
    const negociateurs: INegociateur[] = props.negociateurs;

    return (
        <div className="informations-wrapper -break-inside-avoid">
            {
                negociateurs && negociateurs.map((negociateur: INegociateur, index: number) =>
                    <div className="columns" key={index} >
                        {negociateur.nom && <p className="title">{negociateur.nom}</p>}

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{negociateur.type ? NegociateursHelper.getFormatedType(negociateur.type) : "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Email</p>
                                <p className="value">{negociateur.email ? negociateur.email : "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Affichage de l'email sur le site</p>
                                <p className="value">{negociateur.affichageSiteEmail ? CommonHelper.getFormatedOuiNon(negociateur.affichageSiteEmail) : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Affichage sur le site</p>
                                <p className="value">{negociateur.affichageSite ? CommonHelper.getFormatedOuiNon(negociateur.affichageSite) : "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Téléphone</p>
                                <p className="value">{negociateur.telephone ? CoordonneesHelper.formatPhoneNumberForDisplay(negociateur.telephone) : "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Affichage du téléphone sur le site</p>
                                <p className="value">{negociateur.affichageSiteTel ? CommonHelper.getFormatedOuiNon(negociateur.affichageSiteTel) : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Label</p>
                                <p className="value">{negociateur.labels && negociateur.labels.length > 0 ? NegociateursHelper.getFormatedLabels(negociateur.labels) : "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Portable</p>
                                <p className="value">{negociateur.telephonePortable ? CoordonneesHelper.formatPhoneNumberForDisplay(negociateur.telephonePortable): "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Affichage du téléphone portable sur le site</p>
                                <p className="value">{negociateur.affichageSiteTelPortable ? CommonHelper.getFormatedOuiNon(negociateur.affichageSiteTelPortable) : "-"}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!negociateurs || (negociateurs.length === 0)) &&
                    <>-</>
            }
        </div>
    );
}
