import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "../FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import useCreationSteps from "../../../Hooks/useCreationSteps";
import {IFilInformations} from "../../../Models/FilInformations.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import {IFilInformationsDetailsGetApiResponse, IFilInformationsFormationsFormData, IFilInformationsUneFormData} from "../../../Services/FilInformations.service";
import FormationsHelper from "../../../Helpers/Formations.helper";
import {FormationsService} from "../../../Services/Formations.service";
import {IFormation} from "../../../Models/Formation.model";
import {FilInformationsConstants} from "../../../Constants/FilInformationsConstants";


export default function FilInformationsFormationsFormComponent(props: {
    filInformations?: IFilInformations,
    isOnCreationStep?: boolean,


    submitMethod: (formData: IFilInformationsFormationsFormData) => Promise<IFilInformationsDetailsGetApiResponse>,
    onSuccessSubmit: (response: IFilInformationsDetailsGetApiResponse) => void,
    onCancel: () => void,
}) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    const formationsService: FormationsService = new FormationsService();

    useEffect(() => {
        formationsService.getActivesFormations().then((result)=>{
            if(result.datas && result.datas.formations ){
                initForm(result.datas.formations);
            }
        })
    }, []);

    const initForm = (formations: IFormation[]) => {

        const {filInformations} = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "titreFormations",

                label: "Titre",
                placeholder: "",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: filInformations && filInformations.titreFormations ? filInformations.titreFormations : "",
            },
            {
                type: 'select',
                fieldName: "pictoFormations",

                label: "Picto",
                placeholder: "",
                required: false,
                options: FilInformationsConstants.formationsPictosOptions,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: filInformations && filInformations.pictoFormations ? filInformations.pictoFormations : "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'selectMultiple',
                fieldName: "formations",
                label: "Formations",
                required: true,
                oldValues: FormationsHelper.getIdsFromList(filInformations.formations),
                //options: FormationsHelper.formatListForOptions(formations),
                modificators: "-full",
                optionsGroup: [{label: "Tout sélectionner", options : FormationsHelper.formatListForOptions(formations)}],
            },
            {
                type: 'wysiwyg',
                fieldName: "texteFormations",
                label: "Texte",
                required: false,
                oldValue: filInformations.texteFormations,
                modificators: "-wysiwyg"
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"",
                elements: formElementsColumn1
            },
            {
                modificators:"-size-2",
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    };

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IFilInformationsUneFormData).then((response: IFilInformationsDetailsGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le fil d'information a bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive du fil d'information.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };




    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                isStickyAction={true}
                errorMessages={errorMessages} />
        </div>
    )
}
