import React, {useEffect, useState} from "react";
import LayoutComponent from "../../../Components/Layout/Layout.component";
import PageHeaderComponent from "../../../Components/Page/PageHeader.component";
import useListFilters, {IFilterData} from "../../../Hooks/FiltersHandler";
import {useHistory, useLocation} from "react-router";
import TabsContainerComponent, {TabPanelComponent} from "../../../Components/Tabs/TabsContainer.component";
import {
    LettreTypesService,
    ILettreTypesGetApiResponse,
    ILettreTypesGetFormData
} from "../../../Services/LettreTypes.service";
import TableHeaderButtonComponent from "../../../Components/Lists/TableHeaderButton.component";
import {AppConstants, TabsSlugs} from "../../../Constants/AppConstants";
import PaginationComponent from "../../../Components/Pagination/Pagination.component";
import {ILettreType} from "../../../Models/LettreType.model";
import {IApiPaginationLink} from "../../../Services/Api.service";
import AsideLettresTypesComponent from "../../../Components/Aside/AsideLettresTypes.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";


const LettresTypesListScreen = () => {

    const [filters,initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathLettreTypes);
    const location = useLocation();
    const [showExport] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [lettreTypes,setLettreTypes] = useState<ILettreType[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(initialFilters);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        const lettreTypesService: LettreTypesService = new LettreTypesService();

        setLettreTypes(null);
        setPaginationLinks(null);

        // A venir
        lettreTypesService.getLettreTypes(filters as ILettreTypesGetFormData).then((response: ILettreTypesGetApiResponse)=>{

            if (response && response.datas.pagination) {

                setLettreTypes(response.datas.pagination.data);

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });

    };

    return <>
        <LayoutComponent showMenu={true} wrapperModificators={`${showExport ? '-no-scroll' : ''}`}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent breadcrumbLinks={[
                                        { text: "Correspondances",  link: AppConstants.pathMosaique + '#correspondances'},
                                        { text: "Lettres types" },
                                     ]}
                                     text="Lettres types"
                                     icon="icon-lettre-type"
                                     onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}/>

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
		            <>
                        <TabsContainerComponent onClickTabCallback={(tab)=>{updateFilters({},true); setActiveTab(tab)}}>

                            <TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.LIST}>
                                <div className={`list-content -right-gutter`}>
                                    <LettreTypesListTableComponent filters={filters} lettreTypes={lettreTypes} setOrder={setOrder} isOld={false}/>
	                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                    <AsideLettresTypesComponent type='list' filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    </>
};

const LettreTypesListTableComponent = (props:{lettreTypes: ILettreType[], filters: IFilterData, setOrder:(value:string)=>void, isOld:boolean}) => {
    const history = useHistory();

    return <table className={`list-table`}>
        <thead className={"headers"}>
        <tr>
            {[
                {slug: "nom", label: "Nom"},
            ].map((item, iIndex) => <th
                className="th" key={iIndex}>
                <TableHeaderButtonComponent
                    direction={props.filters.orderType as string || AppConstants.orderType.asc}
                    value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                    label={item.label} click={(value: string) => props.setOrder(value)}/>
            </th>)}
        </tr>
        </thead>
        <tbody>
        {
            props.lettreTypes && props.lettreTypes.map((lettreType: ILettreType, index: number) =>
                <tr key={index}
                    onClick={() => {history.push(`${AppConstants.pathLettreTypes}/${lettreType.id}`)}}
                    className="tr">
                    <td className="td">{lettreType.nom}</td>
                </tr>
            )
        }
        </tbody>
    </table>
};

export default LettresTypesListScreen;
