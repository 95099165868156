import React, {useState} from "react";
import AccordionHeaderComponent from "./AccordionHeader.component";

type IAccordionSimpleComponentProps = {
    contentShowedOnInit?: boolean,
    title: string,
    icon?: string,
    modificators?: string,
    children: JSX.Element[] | JSX.Element
}

function AccordionSimpleComponent (props: IAccordionSimpleComponentProps, ref:any) {
    const [contentShowed, setContentShowed] = useState<boolean>(props.contentShowedOnInit || false);

    /**
     * Gestion du toggle de l'accordion
     */
    const onAccordionToggle = () => {
        setContentShowed(!contentShowed);
    }

    return (
       <div className={`accordion-wrapper ${props.modificators ? props.modificators : ''}`}>
           <div className="accordion-content g-content">
               <AccordionHeaderComponent isContentShowed={contentShowed} title={props.title} icon={props.icon} onToggle={onAccordionToggle} />
               {
                   contentShowed && props.children
               }
           </div>
       </div>

    )
}
export default AccordionSimpleComponent;
