import {IOption} from "src/Components/Fields/Select.field.component";
import {CoordonneeConstants} from "../Constants/CoordonneeConstants";
import CommonHelper from "./Common.helper";

export default class CoordonneesHelper{

    /**
     * Permet de récupérer la destination formaté
     *
     * @param {string} destination
     * @param {string} type
     * @param {string} relationName
     * @returns {string}
     */
    static getFormatedDestination(destination: string, type?: string, relationName?: string): string {
        let newDestinationOptionsEmail = CoordonneeConstants.destinationOptionsEmail;
        let newDestinationOptionsTelephone = CoordonneeConstants.destinationOptionsTelephone;

        if (relationName && relationName == 'office') {
            newDestinationOptionsEmail = CoordonneeConstants.destinationOptionsEmailOffice;
            newDestinationOptionsTelephone = CoordonneeConstants.destinationOptionsTelephoneOffice;
        }

        if (relationName && relationName == 'notaire') {
            newDestinationOptionsEmail = CoordonneeConstants.destinationOptionsEmailNotaire;
            newDestinationOptionsTelephone = CoordonneeConstants.destinationOptionsTelephoneNotaire;
        }

        if(type == "telephone"){
            return CommonHelper.getFormatedElementFromOptions(destination,newDestinationOptionsTelephone);
        }
        else if(type == "email"){
            return CommonHelper.getFormatedElementFromOptions(destination,newDestinationOptionsEmail);
        }
        else{
            return CommonHelper.getFormatedElementFromOptions(destination,CoordonneeConstants.destinationOptions);
        }

    }


    /**
     * Permet de récupérer le type formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedType(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type,CoordonneeConstants.typeOptions);
    }


    /**
     * Permet de formater les numéros de téléphone pour l'affichage
     * Un espace doit être ajouter tous les 2 caractères
     *
     * @param {string} phone
     * @returns {string}
     */
    static formatPhoneNumberForDisplay(phone: string): string {
        //Supprimer les espaces et les points (car l'espace et le point compte comme caractère)
        phone = phone.replaceAll(" ", "");
        phone = phone.replaceAll(".", "");
        let phoneArray: string[] = phone.match(/.{1,2}/g) ?? [];
        return phoneArray.join(' ');
    }
}
