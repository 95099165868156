import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {IFormationGetApiResponse, IFormationFormData} from "src/Services/Formations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IFormation} from "src/Models/Formation.model";
import {GroupesService} from "src/Services/Groupes.service";
import PersonnesExterieuresHelper from "src/Helpers/PersonnesExterieures.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";
import NotairesHelper from "../../../../Helpers/Notaires.helper";


type FormationFormationFormProps = {
    formation?: IFormation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IFormationFormData) => Promise<IFormationGetApiResponse>,
    onSuccessSubmit: (response: IFormationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IFormationGetApiResponse) => void,
    onCancel: () => void,
}

export default function FormationFormationForm(props: FormationFormationFormProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const groupeService: GroupesService = new GroupesService();
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        initForm();
    }, []);

    const initForm = async () => {
        // Récupération du groupe des intervenants
        const groupeIntervenantRes = await groupeService.getGroupe("38");

        let groupe = null;
        if (groupeIntervenantRes.datas && groupeIntervenantRes.datas.groupe) {
            groupe = groupeIntervenantRes.datas.groupe;
        }


        const {formation} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'selectMultiple',
                fieldName: "intervenantsPersonnesExterieures",

                label: "Intervenants (personnes extérieures)",
                placeholder: "",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                options: groupe ? PersonnesExterieuresHelper.formatPersonnesListForOptions(groupe.personnesExterieures) : [],
                oldValues: formation && formation.intervenantsPersonnesExterieures ? PersonnesExterieuresHelper.getIdsFromPersonnesArray(formation.intervenantsPersonnesExterieures) : [],
            },
            {
                type: 'selectMultiple',
                fieldName: "intervenantsNotaires",

                label: "Intervenants (notaires)",
                placeholder: "",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                options: groupe ? NotairesHelper.formatListForOptions(groupe.notaires, null, [], false) : [],
                oldValues: formation && formation.intervenantsNotaires ? NotairesHelper.getIdsFromList(formation.intervenantsNotaires) : [],
            },
            {
                type: 'text',
                fieldName: "urlQuestionnaire",

                label: "Url du questionnaire",
                placeholder: "https://",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.urlQuestionnaire ? formation.urlQuestionnaire : "",
            },
            {
                type: 'number',
                fieldName: "nbMaxParticipants",

                label: "Nb max de participants",
                placeholder: "100",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.nbMaxParticipants ? formation.nbMaxParticipants : "",
                showFieldErrorDetail: true,
            },
            {
                type: 'number',
                fieldName: "nbHeures",

                label: "Nb d'heures",
                placeholder: "21",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.nbHeures ? formation.nbHeures : "",
                showFieldErrorDetail: true,
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "lieu",

                label: "Lieu de la formation",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.lieu ? formation.lieu : "",
                showFieldErrorDetail: true,
            },
            {
                type: 'text',
                fieldName: "adresse",

                label: "Adresse",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.adresse ? formation.adresse : "",
                showFieldErrorDetail: true,
            },
            {
                type: 'text',
                fieldName: "cout",

                label: "Coût",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.cout ? formation.cout : "",
                showFieldErrorDetail: true,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit);
        setFormActions(currentFormActions);
    }


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData).then((response: IFormationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }


            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La formation a bien été mise à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )
}
