import {RecusLivraisonConstants} from "../Constants/RecusLivraisonConstants";
import CommonHelper from "./Common.helper";


export default class RecusLivraisonHelper {

    /**
     * Retourne le type formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedType(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, RecusLivraisonConstants.typesOptions);
    }
}

