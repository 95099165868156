import {IOrganisme} from "../Models/Organisme.model";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";
import {IAdresse} from "../Models/Adresse.model";

export default class OrganismesHelper{

    /**
     * Retourne l'adresse principale d'une organisme
     * @param {IOrganisme} organisme
     * @returns {string}
     */
    static getMainAddress(organisme: IOrganisme): string {
        let address = "";

        if(organisme.adressePrincipale){
            const adresse: IAdresse = organisme.adressePrincipale;
            address = `${adresse.codePostal} ${adresse.ville}`;
        }

        return address;
    }

    /**
     * Retour la liste des personnes extérieure formatée en html
     *
     * @param {IPersonneExterieure[]} personnes
     * @returns {string}
     */
    static getPersonnesExterieuresRattacheesHtml(personnes: IPersonneExterieure[]): string{
        let str = '';
        let i = 0;

        if (!personnes || !personnes.length) {
            return "";
        }

        for (let p of personnes) {
            str += `${i > 0 ? ', ' : ''}${p.nom} ${p.prenom}`;
            i++;
        }

        return '<div class="list"><h4 class="title">Personnes: </h4>' + str + '</div>';
    };
}
