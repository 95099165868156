import React, {useContext, useEffect, useState} from 'react';
import SearchBlockComponent, {IFormSearchData} from "src/Components/Search/SearchBlock.component";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import AccountBlockComponent from "../Components/Account/AccountBlock.component";
import {Link} from "react-router-dom";
import LayoutComponent from "../Components/Layout/Layout.component";
import {Module, modules} from "../Constants/ModulesConstants";
import ErrorsConstants from "../Constants/Errors";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import { useLocation, matchPath } from 'react-router-dom';

const MosaiqueScreen = () => {
    const history = useHistory();
    const {isAuthenticated, checkModuleAllowed} = useContext(UserContext) as IUserContext;
    const [selectedMozaicModule,setSelectedMozaicModule] = useState<Module>();
    const location = useLocation()

    useEffect(() => {
        let hash = location.hash ? location.hash.replace('#','') : null;

        if(hash){
            const targetedModule: Module = modules.find( (m) => m.slug === hash);
            if(targetedModule){
                setSelectedMozaicModule(targetedModule);
            }
        }

    }, [location]);


    useEffect(()=>{
        if(!isAuthenticated){
            // On redirige vers la home
            history.push(AppConstants.pathLogin);
        }
    },[isAuthenticated,history]);

    /**
     * Retourne le modificateur css à partir du nombre de sous modules
     * @param {number} subModulesNumber
     */
    const getSubmodulesCssModificatorBasedOnNumber = (subModulesNumber: number): string => {

        let modificator: string = "-is-4-more";

        if(subModulesNumber <= 2){
            modificator = "-is-2";
        }else if(subModulesNumber > 2 && subModulesNumber <= 4){
            modificator = "-is-between-2-4";
        }

        return modificator;

    };

    /**
     * Gère le retour du form de recherche
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData): void => {
        if(!formDatas || !formDatas.keyword){
            notificationSystem.addNotification({...defaultNotificationConfig, message: ErrorsConstants.messages.emptySearchField, type: "danger"});
            return;
        }

        history.push(`/recherche?keyword=${formDatas.keyword}`);
    };

    /**
     * Redirige vers un module
     * @param {string} path
     */
    const goToModule = (path: string): void => {
        history.push(path);
    };

    return (
	    <LayoutComponent wrapperModificators="" contentModificators="" showMenu={false}>
            <div className="home-wrapper">
                <header className="home-header">
                    <div className="search-wrapper">
                        <SearchBlockComponent onSubmit={onSearch} modificators="-big -on-primary"/>
                    </div>

                    <div className="toolbar-wrapper">
                        <AccountBlockComponent />
                        <span className="separator" />

                        <a className="g-picto-link -secondary icon-paper-plane" href="https://app.ar24.fr/fr/user/login/required" target="_blank"></a>

                    </div>
                </header>

                <div className="home-content">

                    <div className="mozaic-block">
                        <div className="modules">
                            {modules.map((module,moduleIndex)=>checkModuleAllowed(module.slug) &&
				                <div className="mozaic-module" key={moduleIndex}  onClick={()=>(module.link ? goToModule(module.link) : setSelectedMozaicModule(module))}>
					                <div className="content">
                                        <span className={`icon ${module.icon}`}></span>
					                    <h3 className="title">{module.name}</h3>
                                    </div>
				                </div>
                            )}
                        </div>
                    </div>

                    <div className={`mozaic-overlay ${selectedMozaicModule ? '-show' : ''}`}>
                        {selectedMozaicModule && <>
	                        <div className="left">
		                        <button className="close" onClick={()=>setSelectedMozaicModule(null)}><i className="icon-arrow-left"></i></button>
		                        <div className="content">
			                        <span className={`icon ${selectedMozaicModule.icon}`}></span>
			                        <h3 className="title">{selectedMozaicModule.name}</h3>
		                        </div>
	                        </div>
	                        <div className="right">
                                {selectedMozaicModule.submodules.map( (subModule,subModuleIndex) =>
                                    {
                                        const params: any = {};

                                        if(subModule.external){
                                            params.target = "_blank";
                                            params.target = "noopener noreferrer";
                                        }

                                        return (
                                            <a href={subModule.link} key={subModuleIndex} className={`mozaic-module ${getSubmodulesCssModificatorBasedOnNumber(selectedMozaicModule.submodules.length)}  -big`} {...params}>
                                                <div className="content">
                                                    <span className={`icon ${subModule.icon}`} />
                                                    <h3 className="title">{subModule.name}</h3>
                                                </div>
                                            </a>
                                        )
                                    }



                                )}
                                {(selectedMozaicModule.submodules.length === 1 || selectedMozaicModule.submodules.length === 3 || selectedMozaicModule.submodules.length === 5 || selectedMozaicModule.submodules.length === 7|| selectedMozaicModule.submodules.length === 8) && <div className={`mozaic-module ${getSubmodulesCssModificatorBasedOnNumber(selectedMozaicModule.submodules.length)} -placeholder`}>
			                        <div className="content">
				                        <span className={`icon icon-notaire`}></span>
			                        </div>
		                        </div>}
	                        </div>
                        </>}
                    </div>
                </div>



            </div>
	    </LayoutComponent>
    )
};

export default MosaiqueScreen;
