import {IOfficeGetApiResponse, IOfficeAnnexesFormData, OfficesService, IOfficesGetAllApiResponse} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import OfficesHelper from "src/Helpers/Offices.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type IOfficeAnnexesFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IOfficeAnnexesFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeAnnexesFormComponent(props: IOfficeAnnexesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [offices, setOffices] = useState<IOffice[]>(null);
    const [officesLoaded, setOfficesLoaded] = useState<boolean>(false);
    const [annexes, setAnnexes] = useState<any[]>([]);
    const {getFormActions} = useCreationSteps();


    useEffect(() => {
        const officesService: OfficesService = new OfficesService();
        officesService.getAllOfficesAnnexes().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });
    }, []);


    useEffect(() => {
        const annexesFormated: any[] = [];

        for(let annexe of props.office.annexes){
           annexesFormated.push({
               id: annexe.id.toString(),
               officeId: annexe.id.toString()
           });
        };

        setAnnexes(annexesFormated);

    }, [props.office, offices]);


    /**
     * Initialisation du form
     */
    useEffect(() => {
        const formElementsColumn1: FormElement[] = [
            {
                type: 'repeater',
                fieldName: "annexes",
                label: "Annexes",
                oldRepeatableValues: annexes as FormComponentFormData,
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'select',
                                fieldName: "officeId",

                                label: "Office",
                                placeholder: "Offices",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                options: OfficesHelper.formatListForOptions(offices),

                                oldValue: ""
                            },
                        ],
                    },
                ],
                required: false,
            },

        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, [offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeAnnexesFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les annexes ont bien été modifiées.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des annexes de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                officesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }
        </div>
    )
}
