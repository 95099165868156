import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {IFormField} from "./Input.field.component";
import FormCreatorComponent, {
    FormColumn,
    FormComponentFormData,
    FormElement,
    IFormfieldCondition
} from "../Forms/FormCreator.component";
import DateHelper from "src/Helpers/Date.helper";
import {IApiFieldsErrorMessages} from "src/Services/Api.service";
import LabelComponent from "./Label.component";


type RepeaterFieldProps = IFormField & {
    formColumns: FormColumn[],
    onChange: (value: FormComponentFormData, keysToDelete?: string[]) => void,
    oldValues: FormComponentFormData[],

    errorFieldsMessages?: IApiFieldsErrorMessages,
    hideDeleteButton?: boolean,
    hideAddButton?: boolean,
    isOrigineAvocat?: boolean,
}

type FormRepeater = {
    formColumns: FormColumn[],
    id?: string,
    index?: number,
}

const RepeaterFieldComponent = (props: RepeaterFieldProps) => {
    const refParent = useRef(null)

    const [fieldsFormData, setFieldsFormData] = useState<FormComponentFormData>({});
    const [formRepeaters, setFormRepeaters] = useState<FormRepeater[]>([]);
    const [oldValues, setOldValues] = useState<FormComponentFormData[]>([]);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [oldIndex, setOldIndex] = useState<number>(0);

    useEffect(()=> {
        setOldValues(props.oldValues);
    }, [props.oldValues])
    useEffect(()=> {
        const newFormRepeaters = [...formRepeaters];

        //On change l'affichage du champ "Nom Client" selon l'origine de l'avocat
        //Le traitement a été fait ainsi car le repeater n'a pas accès au formData du parent
        //Donc on ne peut pas faire de condition par rapport à une propriétée du parent
        for(let fr of newFormRepeaters){
            for(let col of fr.formColumns){
                for(let el of col.elements){
                    if(el.fieldName === "nomClient"){
                        el = editNomClientFieldFromOrigineAvocat(props.isOrigineAvocat, el);
                    }
                }
            }
        }
        setFormRepeaters(newFormRepeaters);
    }, [props.isOrigineAvocat])



    useEffect(() => {
        if(props.errorFieldsMessages) {
            setErrorFieldsMessages(props.errorFieldsMessages);
        }
    }, [props.errorFieldsMessages])


    useEffect(() =>{
        const newFormRepeaters: FormRepeater[] = [];
        let newFieldsFormData: FormComponentFormData = {};

        let index: number = 0;
        if(oldValues && oldValues.length){
            for(let values of oldValues){
                const newIndex: number = index + 1;

                const updatedFormColumns: FormColumn[] = [];
                const formData: FormComponentFormData = {};

                //Préparation des columns
                for(let column of props.formColumns){
                    const updatedColunm = {...column};
                    const elements: FormElement[] = [];

                    for(let element of updatedColunm.elements){
                        const updatedElement = {...element};

                        const fieldName = updatedElement.fieldName;
                        const fieldType = updatedElement.type;

                        if( values[fieldName] ){
                            switch(fieldType){
                                case "repeater":
                                    updatedElement.oldRepeatableValues = values[fieldName];
                                    break;
                                case "date":
                                    updatedElement.oldValue = formData[fieldName] = DateHelper.getFormatedDateForInput(values[fieldName]);
                                    break;

                                case "selectMultiple":
                                    updatedElement.oldValues = formData[fieldName] = values[fieldName];
                                    break;

                                case "file":
                                    formData[`${fieldName}_old`] = values[fieldName];
                                    formData[fieldName] = "";
                                    updatedElement.oldValue = values[fieldName];
                                    break;

                                case "select":
                                default:
                                    updatedElement.oldValue = formData[fieldName] = values[fieldName].toString();
                            }
                        }

                        elements.push(updatedElement);
                    }
                    updatedColunm.elements = elements;
                    updatedFormColumns.push(updatedColunm);
                }


                //Mise à jour du formData avec les anciennes valeurs
                for(let [k,value] of Object.entries(formData)){
                    // Pas trouvé mieux pour envoyer de sous tableaux avec une clé en chaine de caractères
                    const key: string = `${props.fieldName}[${index}][${k}]`;

                    newFieldsFormData[key] = value;
                }

                //Création du formulaire
                newFormRepeaters.push({formColumns: updatedFormColumns, id: values.id, index: index });

                index = newIndex;
            }

            setOldIndex(index);

            setFormRepeaters(newFormRepeaters);

            //On initialise le tableau d'id a supprimer
            newFieldsFormData.idsToDelete = [];

            setFieldsFormData(newFieldsFormData);
            props.onChange(newFieldsFormData);
        }

    },[oldValues, props.formColumns]);


    useEffect(()=> {
        const newFormRepeaters: FormRepeater[] = [];

        formRepeaters.forEach((repeater: FormRepeater) => {
            for(let column of repeater.formColumns){
                const updatedColumn = {...column};
                let elements: FormElement[] = [];

                for(let element of updatedColumn.elements){
                    const elementUpdated = {...element};
                    const fieldName = elementUpdated.fieldName;
                    const fieldType = elementUpdated.type;
                    const key: string = `${props.fieldName}[${repeater.index}][${fieldName}]`;
                    const keySchema: string = `${props.fieldName}[${repeater.index}]`;

                    switch(fieldType){
                        case "repeater":
                            elementUpdated.oldValues = props.oldValues[repeater.index] ? props.oldValues[repeater.index][fieldName] : "";
                            break;
                        case "selectMultiple":
                            elementUpdated.oldValues = fieldsFormData[key];
                            break;
                        default:
                            elementUpdated.oldValue = fieldsFormData[key];
                    }

                    updatedColumn.elements = elements;
                }

            }

            newFormRepeaters.push(repeater);
        });

        setFormRepeaters(newFormRepeaters);

    }, [fieldsFormData]);


    const checkCondition = (condition: IFormfieldCondition | IFormfieldCondition[],currentData: FormComponentFormData, keySchema: string) => {
        let conditions = Array.isArray(condition) ? condition : [condition];
        let passedConditionsNumber: number = 0;

        conditions.forEach((c)=>{
            const key: string = `${keySchema}[${c.fieldName}]`;

            if(typeof currentData[key] !== "undefined"){
                const result = c.value === currentData[key];
                if(result) passedConditionsNumber++;
            }
        });
        return passedConditionsNumber === conditions.length;
    };


    /**
     * Ecoute du changement des datas
     * @param {FormComponentFormData} formData
     * @param {number} index
     */
    const onFieldGroupDataChanged = (formData: FormComponentFormData, index: number) => {
        const newFieldsFormData = {...fieldsFormData};


        for(let [k,value] of Object.entries(formData)){
            // Pas trouvé mieux pour envoyer de sous tableaux avec une clé en chaine de caractères

            let key: string = `${props.fieldName}[${index}]`;

            // S'il y a des [] dans la clé, on se trouve dans le cas d'un sous repéteur
            // On récupère les champs de la sous clé et on les concatène avec la clé principale
            if(k.indexOf('[') !== -1){
                const parts = k.match(/(\w+)+/g).map((p)=>`[${p}]`);
                key += parts.join("");
            }else{
                key += `[${k}]`;
            }

            newFieldsFormData[key] = value;
        }

        setFieldsFormData(newFieldsFormData);
        props.onChange(newFieldsFormData);
    };


    /**
     * Ajout d'un nouvel élément
     *
     * @param {React.SyntheticEvent} e
     */
    const onAdd = (e: SyntheticEvent) => {
        e.preventDefault();

        const newIndex:number = oldIndex + 1;
        let newRows: FormRepeater[] = formRepeaters.concat({
                formColumns:  props.formColumns,
                index: newIndex
            }
        )

        //A la création, selon l'origine de la réclamation
        //On change l'affichage du champ "Nom Client"
        //Le traitement a été fait ainsi car le repeater n'a pas accès au formData du parent
        //Donc on ne peut pas faire de condition par rapport à une propriétée du parent
        for(let col of props.formColumns){
            for(let el of col.elements){
                if(el.fieldName === 'nomClient'){
                    el = editNomClientFieldFromOrigineAvocat(props.isOrigineAvocat, el);
                }
            }
        }


        setOldIndex(newIndex);
        setFormRepeaters(newRows);

        //Scroll vers l'élément qui vient d'être ajouté
        setTimeout(() => {
            const sections =refParent.current.querySelectorAll('.form-field-repeater-item');
            if(sections) {
                const last = sections[sections.length- 1];
                last.scrollIntoView({behavior: 'smooth', block: 'end'});
            }
        }, 100)
    };


    /**
     * Suppression d'un item
     *
     * @param {number} index
     * @param {number} realIndex
     * @param {string} id
     */
    const deleteGroup = (realIndex:number, index: number, id?: string): void => {
        const newFieldsFormData = {...fieldsFormData};
        const keysToDelete: string[] = [];

        //Suppression des données du formData
        for(let column of props.formColumns){
            for(let element of column.elements){
                let key: string = generateKey(props.fieldName, index, element.fieldName);

                if(element.type === "file"){
                    const keyold: string = generateKey(props.fieldName, index, element.fieldName + '_old');
                    delete newFieldsFormData[keyold];
                    keysToDelete.push(keyold);

                    const keyFichierDelete: string = generateKey(props.fieldName, index, "fichier_delete");
                    delete newFieldsFormData[keyFichierDelete];
                    keysToDelete.push(keyFichierDelete);

                    const keyFichierOld: string = generateKey(props.fieldName, index, "fichier_old");
                    delete newFieldsFormData[keyFichierOld];
                    keysToDelete.push(keyFichierOld);
                }

                if(element.type === "repeater"){
                    const keyIdToDelete: string = generateKey(props.fieldName, index, "idsToDelete");
                    delete newFieldsFormData[keyIdToDelete];
                    keysToDelete.push(keyIdToDelete);
                }

                delete newFieldsFormData[key];
                keysToDelete.push(key);
            }
        }

        //Suppression de l'id si c'était un ancien item
        if(id){
            newFieldsFormData.idsToDelete.push(id);
        }

        //Mise à jour des formsDatas
        setFieldsFormData(newFieldsFormData);
        props.onChange(newFieldsFormData, keysToDelete);

        const newformRepeaters: FormRepeater[] = formRepeaters;
        newformRepeaters.splice(realIndex, 1);


        setFormRepeaters(newformRepeaters);
    };


    /**
     * Modification du champ du nom du client
     * 
     * @param {boolean} isOrigineAvocat
     * @param {FormElement} el
     * @returns {FormElement}
     */
    const editNomClientFieldFromOrigineAvocat = (isOrigineAvocat: boolean, el: FormElement) => {
        if(isOrigineAvocat === true){
            el.type = "text";
            el.modificators = "-on-white";
            el.label = "Nom du client";
        }
        else{
            el.oldValue = "";
            el.type = "hidden";
            el.label = "";
            el.modificators = "-hidden";
        }
        
        return el;
    }
    

    /**
     * Génération de la clé pour un champ d'un répéteur
     *
     * @param {string} repeaterFieldName
     * @param {number} index
     * @param {string} fieldName
     * @returns {string}
     */
    const generateKey = (repeaterFieldName: string, index: number, fieldName: string): string => {
        return `${repeaterFieldName}[${index}][${fieldName}]`;
    }


    return (
        <div className="form-field-repeater" ref={refParent}>
            <p>
                {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}
            </p>

            {
                !props.hideAddButton && (formRepeaters.length >= 2) &&
                <button onClick={onAdd} className="g-button -add-button -mb-bottom"><i className="picto icon-add"/> Ajouter</button>
            }


            {
                formRepeaters && formRepeaters.map((el: FormRepeater, index: number) =>{
                        return <div className="form-field-repeater-item" key={el.index}>

                            {
                                !props.hideDeleteButton &&
                                    <i className="icon icon-remove" onClick={() => deleteGroup(index, el.index, el.id)} />
                            }

                            <FormCreatorComponent
                                key={el.index}
                                index={el.index}
                                repeaterName={props.fieldName}
                                formColumns={el.formColumns}
                                modificators="-repeater"
                                formActions={[]}
                                errorFieldsMessages={errorFieldsMessages}
                                onFormDataChange={(formData => onFieldGroupDataChanged(formData, el.index))}
                            />
                        </div>
                    }

                )
            }

            {
                !props.hideAddButton &&
                    <button onClick={onAdd} className="g-button -add-button"><i className="picto icon-add"/> Ajouter</button>
            }
        </div>
    )
};

export default RepeaterFieldComponent;
