import {AppConstants} from "../Constants/AppConstants";

export default class DocumentsHelper{


    /**
     * Permet de trier et filtrer une liste de documents
     *
     * @param {any[]} list
     * @param {string} orderBy
     * @param {string} orderDirection
     * @param {string} filterBy
     * @param {string} filterByValue
     * @returns {any[]}
     */
    static filterAndOrder(list: any[], orderBy: string, orderDirection:string,filterBy?: string, filterByValue?: string): any[]{
        let newList = [...list];

        if(filterBy && filterByValue && filterByValue.length){
            newList = newList.filter( (d: any) => d[filterBy] == filterByValue);
        }

        if(orderBy) {
            newList.sort((a: any, b: any) => {
                const aValue = a[orderBy] ?? "";
                const bValue = b[orderBy] ?? "";

                return aValue.localeCompare(bValue);
            });
        }

        if(orderDirection && orderDirection === AppConstants.orderType.desc){
            newList.reverse();
        }

        return newList;
    }
}
