import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import FileHelper from "../../../Helpers/File.helper";
import DateHelper from "../../../Helpers/Date.helper";

type INotaireDecedeResumeComponentProps = {
    notaire: INotaire,
}

export default function NotaireDecedeResumeComponent(props: INotaireDecedeResumeComponentProps) {
    const notaire: INotaire = props.notaire;

    let imagePlaceholder: string = "";
    switch (notaire.sexe) {
        case "masculin":
            imagePlaceholder = FileHelper.getPlaceholderUrl("picture-man");
            break;
        case "feminin":
            imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
            break;

        case "nonPrecise":
        default:
            imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
            break;
    }


    return (
        <div className="informations-wrapper">
            <div className="columns">

                <div className="column -auto">
                    <figure className="informations-image -resume">
                        <img
                            src={notaire.photo ? FileHelper.getImageUrlFromSize(notaire.photo, "thumbnail") : imagePlaceholder}
                            alt="" className="image"/>
                    </figure>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label"><em className="picto -decede icon-croix-deces"></em></p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Date du décés</p>
                        <p className="value">{notaire.dateDeces ? DateHelper.getFormatedDate(notaire.dateDeces) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Date serment initial</p>
                        <p className="value">{notaire.dateSermentInitial ? DateHelper.getFormatedDate(notaire.dateSermentInitial) : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Date du dernier serment</p>
                        <p className="value">{notaire.dateDernierSerment ? DateHelper.getFormatedDate(notaire.dateDernierSerment) : '-'}</p>
                    </div>


                </div>

                <div className="column">
                </div>
            </div>

        </div>
    );
}
