import React, {useEffect, useState} from "react";
import {IOfficeAnnexesFormData, IOfficeCertificationsLabelsHorairesFormData, IOfficeChiffresFormData, IOfficeCommentairesFormData, IOfficeGetApiResponse, IOfficeInformationFormData, IOfficeInformationsComplementairesFormData, IOfficeNegociateursFormData, IOfficeRecusLivraisonFormData, IOfficeRepertoiresFormData, IOfficeSitesGroupesLogicielsFormData, OfficesService} from "src/Services/Offices.service";
import {CommonsService, IAdressesFormData, ICoordonneesFormData} from "src/Services/Commons.service";
import useOffice from "src/Hooks/UseOffice";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IOffice} from "src/Models/Office.model";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import OfficeAnnexesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeAnnexesForm.component";
import OfficeNegociateursFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeNegociateursForm.component";
import OfficeRepertoiresFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeRepertoiresForm.component";
import OfficeRecusLivraisonFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeRecusLivraisonForm.component";
import OfficeCommentairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeCommentairesForm.component";
import OfficeSitesGroupesLogicielsFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeSitesGroupesLogicielsForm.component";
import OfficeInformationsComplementairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeInformationsComplementairesForm.component";
import OfficeChiffresFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeChiffresForm.component";
import OfficeCertificationsLabelsHorairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeCertificationsLabelsHorairesForm.component";
import AdressesFormComponent from "src/Components/Forms/Commons/AdressesForm.component";
import CoordonneesFormComponent from "src/Components/Forms/Commons/CoordonneesForm.component";
import OfficeCreationFormComponent from "../../../Components/Forms/Annuaire/Offices/OfficeCreationForm.component";


type IOfficeCreationStepsScreenProps = {
    oid?: string,
    type: string;
}
export default function OfficeCreationStepsScreen(props: IOfficeCreationStepsScreenProps){
    const officesService: OfficesService = new OfficesService();
    const commonsService: CommonsService = new CommonsService();
    const [getOfficeById] = useOffice();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [office, setOffice] = useState<IOffice>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.oid){
            getOfficeById(props.oid, (office: IOffice) => {
                setOffice(office);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [])


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IOfficeGetApiResponse): void => {
        if(response.datas && response.datas.office){
            const office: IOffice = response.datas.office;
            history.push(AppConstants.pathOffices + '/' + office.id);
        }
        else{
            history.push(AppConstants.pathOffices);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitNext = (response: IOfficeGetApiResponse): void => {
        if(response.datas && response.datas.office){
            const office: IOffice = response.datas.office;
            redirectToNextCreationStep(AppConstants.pathOffices, office.id, office.creationSteps);
        }
        else{
            history.push(AppConstants.pathOffices);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        if(office){
            history.push(AppConstants.pathOffices + '/' + office.id);
            return;
        }

        history.push(AppConstants.pathOffices);
    };

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un office" icon="icon-office-add" modificators={`-in-fullscreen -border-full-screen`}/>
            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-office"} title={"Identité"} modificators="-margin-bottom" />
                            <OfficeCreationFormComponent isOnCreationStep={true} submitMethod={(formData: IOfficeInformationFormData)=>officesService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} />
                        </>
                    }
                    {
                        props.type === "annexes" &&
                        <>
                            <InformationHeaderComponent icon={"icon-annexe"} title={"Annexes"} modificators="-margin-bottom" />
                            <OfficeAnnexesFormComponent office={office}
                                                        isOnCreationStep={true}
                                                        submitMethod={(formData: IOfficeAnnexesFormData) => officesService.syncAnnexes(office.id, formData)}
                                                        onSuccessSubmit={onSuccessSubmitForm}
                                                        onSuccessSubmitNext={onSuccessSubmitNext}
                                                        onCancel={onCancelForm}/>
                        </>
                    }

                    {
                        props.type === "negociateurs" &&
                        <>
                            <InformationHeaderComponent icon={"icon-negociateurs"} title={"Negociateurs"} modificators="-margin-bottom" />
                            <OfficeNegociateursFormComponent office={office}
                                                             isOnCreationStep={true}
                                                             submitMethod={(formData: IOfficeNegociateursFormData) => officesService.syncNegociateurs(formData)}
                                                             onSuccessSubmit={onSuccessSubmitForm}
                                                             onSuccessSubmitNext={onSuccessSubmitNext}
                                                             onCancel={onCancelForm}/>
                        </>
                    }

                    {
                        props.type === "repertoires" &&
                        <>
                            <InformationHeaderComponent icon={"icon-repertoire"} title={"Répertoire"} modificators="-margin-bottom" />
                            <OfficeRepertoiresFormComponent office={office}
                                                            isOnCreationStep={true}
                                                            submitMethod={(formData: IOfficeRepertoiresFormData) => officesService.syncRepertoires(formData)}
                                                            onSuccessSubmit={onSuccessSubmitForm}
                                                            onSuccessSubmitNext={onSuccessSubmitNext}
                                                            onCancel={onCancelForm}/>
                        </>
                    }

                    {
                        props.type === "recusLivraison" &&
                        <>
                            <InformationHeaderComponent icon={"icon-recus-livraison"} title={"Reçus Livraison"} modificators="-margin-bottom" />
                            <OfficeRecusLivraisonFormComponent office={office}
                                                               isOnCreationStep={true}
                                                               submitMethod={(formData: IOfficeRecusLivraisonFormData) => officesService.syncRecusLivraisons(formData)}
                                                               onSuccessSubmit={onSuccessSubmitForm}
                                                               onSuccessSubmitNext={onSuccessSubmitNext}
                                                               onCancel={onCancelForm}/>
                        </>
                    }
                    {
                        props.type === "commentaires" &&
                        <>
                            <InformationHeaderComponent icon={"icon-commentaires"} title={"Commentaires"} modificators="-margin-bottom" />
                            <OfficeCommentairesFormComponent
                                office={office}
                                isOnCreationStep={true}
                                submitMethod={(formData:IOfficeCommentairesFormData)=>officesService.updateCommentaire(office.id, formData)}
                                onSuccessSubmit={onSuccessSubmitForm}
                                onSuccessSubmitNext={onSuccessSubmitNext}
                                onCancel={onCancelForm}
                            />
                        </>
                    }
                    {
                        props.type === "logiciels" &&
                        <>
                            <InformationHeaderComponent title="Groupes et Logiciels" icon="icon-logiciels" modificators="-margin-bottom" />
                            <OfficeSitesGroupesLogicielsFormComponent office={office}
                                                                      isOnCreationStep={true}
                                                                      submitMethod={(formData: IOfficeSitesGroupesLogicielsFormData) => officesService.updateSitesGroupesLogiciels(office.id, formData)}
                                                                      onSuccessSubmit={onSuccessSubmitForm}
                                                                      onSuccessSubmitNext={onSuccessSubmitNext}
                                                                      onCancel={onCancelForm}/>
                        </>
                    }
                    {
                        props.type === "informationsComplementaires" &&
                        <>
                            <InformationHeaderComponent title="Informations complémentaires" icon="icon-informations" modificators="-margin-bottom" />
                            <OfficeInformationsComplementairesFormComponent office={office}
                                                                            isOnCreationStep={true}
                                                                            submitMethod={(formData: IOfficeInformationsComplementairesFormData) => officesService.updateInformationsComplementairess(office.id, formData)}
                                                                            onSuccessSubmit={onSuccessSubmitForm}
                                                                            onSuccessSubmitNext={onSuccessSubmitNext}
                                                                            onCancel={onCancelForm}/>
                        </>
                    }
                    {
                        props.type === "chiffres" &&
                        <>
                            <InformationHeaderComponent title="Données chiffrées" icon="icon-chiffre" modificators="-margin-bottom" />
                            <OfficeChiffresFormComponent office={office}
                                                         isOnCreationStep={true}
                                                         submitMethod={(formData: IOfficeChiffresFormData) => officesService.updateChiffres(office.id, formData)}
                                                         onSuccessSubmit={onSuccessSubmitForm}
                                                         onSuccessSubmitNext={onSuccessSubmitNext}
                                                         onCancel={onCancelForm}/>
                        </>
                    }
                    {
                        props.type === "certifications" &&
                        <>
                            <InformationHeaderComponent title="Certifications, Labels et horaires" icon="icon-certifications" modificators="-margin-bottom" />
                            <OfficeCertificationsLabelsHorairesFormComponent office={office}
                                                                             isOnCreationStep={true}
                                                                             submitMethod={(formData: IOfficeCertificationsLabelsHorairesFormData) => officesService.updateCertificationsLabelsHoraires(office.id, formData)}
                                                                             onSuccessSubmit={onSuccessSubmitForm}
                                                                             onSuccessSubmitNext={onSuccessSubmitNext}
                                                                             onCancel={onCancelForm}/>
                        </>
                    }
                    {
                        props.type === "adresses" &&
                        <>
                            <InformationHeaderComponent title="Adresses"
                                                        icon="icon-coordonnees"
                                                        modificators="-margin-bottom" />
                            <AdressesFormComponent adresses={office.adresses}
                                                   relationId={office.id}
                                                   relationName="office"
                                                   isOnCreationStep={true}
                                                   submitMethod={(formData: IAdressesFormData) => commonsService.syncAdresses(formData)}
                                                   onSuccessSubmit={onSuccessSubmitForm}
                                                   onSuccessSubmitNext={onSuccessSubmitNext}
                                                   onCancel={onCancelForm}/>
                        </>
                    }
                    {
                        props.type === "coordonnees" &&
                        <>
                            <InformationHeaderComponent title="Coordonnées"
                                                        icon="icon-coordonnees-adresses"
                                                        modificators="-margin-bottom" />
                            <CoordonneesFormComponent coordonnees={office.coordonnees}
                                                      relationId={office.id} relationName="office"
                                                      isOnCreationStep={true}
                                                      submitMethod={(formData: ICoordonneesFormData) => commonsService.syncCoordonnees(formData)}
                                                      onSuccessSubmit={onSuccessSubmitForm}
                                                      onSuccessSubmitNext={onSuccessSubmitNext}
                                                      onCancel={onCancelForm}/>
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}
