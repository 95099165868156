import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiFieldsErrorMessages, IApiPaginationLink} from "src/Services/Api.service";
import {CorrespondancesService} from "src/Services/Correspondances.service";
import useCorrespondance from "src/Hooks/UseCorrespondance";
import {ICorrespondance} from "src/Models/Correspondance.model";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {INotaireGetFormData, INotairesGetApiResponse, NotairesService} from "src/Services/Notaires.service";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import useListFilters from "src/Hooks/FiltersHandler";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {AutresContactsService,IAutresContactsGetFormData, IPersonnesExterieuresGetApiResponse} from "src/Services/AutresContacts.service";
import SearchBlockComponent, {IFormSearchData} from "src/Components/Search/SearchBlock.component";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {FormColumn, FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import RepeaterFieldComponent from "src/Components/Fields/Repeater.field.component";
import {ICorrespondanceInvite} from "src/Models/CorrespondanceInvite.model";
import {Link} from "react-router-dom";
import LoadingComponent from "../../../Components/Loading/Loading.component";

const CorrespondanceAjoutTestsScreen = (props: {
    cid: number
}) => {
    const [getCorrespondanceById] = useCorrespondance();
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder,setPath,defaultFilters] = useListFilters(`/correspondances/${props.cid}/tests`, false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [personnes, setPersonnes] = useState<IPersonneExterieure[]>([]);
    const [correspondance, setCorrespondance] = useState<ICorrespondance>(null);
    const [collaborateursFormsColumns, setCollaborateursFormsColumns] = useState<FormColumn[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.NOTAIRES);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [keyword,setKeyword] = useState<string>("");
    const [fieldsErrors,setFieldsErrors] = useState<IApiFieldsErrorMessages>(null);

    const [showConfirmation,setShowConfirmation] = useState<boolean>(false);

    const [addedNotaires,setAddedNotaires] = useState<number[]>([]);
    const [addedPersonnes,setAddedPersonnes] = useState<number[]>([]);
    const [deletedPersonnes,setDeletedPersonnes] = useState<number[]>([]);
    const [deletedNotaires,setDeletedNotaires] = useState<number[]>([]);
    const [deletedCollaborateurs,setDeletedCollaborateurs] = useState<number[]>([]);
    const [addedCollaborateurs, setAddedCollaborateurs] = useState<FormComponentFormData>({});
    const [oldCollaborateurs, setOldCollaborateurs] = useState<any[]>([]);

    const history = useHistory();

    const correspondancesService: CorrespondancesService = new CorrespondancesService();
    const notairesService: NotairesService = new NotairesService();
    const personneExterieuresService: AutresContactsService = new AutresContactsService();

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    useEffect(() => {
        updateFilters(defaultFilters);

        if (!props.cid) {
            history.push(AppConstants.path403);
        }
        getCorrespondance();
    }, [props.cid]);


    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setIsLoading(true);

        if (activeTab === TabsSlugs.NOTAIRES) {
            notairesService.getNotairesCorrespondancesWithPaginate(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // Notaires
                    const currentNotairesList: INotaire[] = response.datas.pagination.data;
                    setNotaires(currentNotairesList);
                    setIsLoading(false);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }

        if (activeTab === TabsSlugs.EXTERIEURS) {
            personneExterieuresService.getPersonnesExterieures(filters as IAutresContactsGetFormData).then((response: IPersonnesExterieuresGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // exterieurs
                    const currentAutresContactsList: IPersonneExterieure[] = response.datas.pagination.data as IPersonneExterieure[];
                    setPersonnes(currentAutresContactsList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);
                    setIsLoading(false);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }
    };


    /**
     * Récupération de la correspondance selon l'uid
     */
    const getCorrespondance = (): void => {
        getCorrespondanceById(props.cid, (newCorrespondance: ICorrespondance) => {
            setCorrespondance(newCorrespondance);

            if (!loaded) {
                const newOldCollaborateurs = generateOldCollaborateurs(newCorrespondance);
                setOldCollaborateurs(newOldCollaborateurs);

                setLoaded(true);
            }
        });
    };


    /**
     * Génération de la liste des anciens collaborateurs
     *
     * @param {ICorrespondance} currentCorrespondance
     * @returns {any}
     */
    const generateOldCollaborateurs = (currentCorrespondance: ICorrespondance) => {
        let collaborateurList: any = [];

        currentCorrespondance.tests.forEach((invite) => {
            if( invite.CRPCEN){
                collaborateurList.push({
                    'crpcen': invite.CRPCEN,
                    'id': invite.id,
                    'nom': invite.nom,
                    'email': invite.email,
                    'prenom': invite.prenom
                });
            }
        });

        const newCollaborateursFormColumns: FormColumn[] = [
            {
                elements: [
                    {
                        type: 'hidden',
                        fieldName: "id",
                        oldValue: "",
                        modificators: "-hidden"
                    },
                    {
                        type: 'text',
                        fieldName: "crpcen",
                        label: "CRPCEN",
                        oldValue : "",
                        modificators: "-on-white"
                    },
                    {
                        type: 'text',
                        fieldName: "nom",
                        label: "Nom",
                        oldValue : "",
                        modificators: "-on-white"
                    },
                ]
            },
            {
                elements: [
                    {
                        type: 'email',
                        fieldName: "email",
                        label: "Email",
                        oldValue : "",
                        modificators: "-on-white"
                    },
                    {
                        type: 'text',
                        fieldName: "prenom",
                        label: "Prénom",
                        oldValue : "",
                        modificators: "-on-white"
                    },
                ]
            }
        ];
        setCollaborateursFormsColumns(newCollaborateursFormColumns);

        return collaborateurList;
    }


    /**
     * Retourne le mail du notaire ou de la personne extérieure
     * @param {INotaire | IPersonneExterieure} person
     * @returns {string}
     */
    const getEmail = (person: INotaire | IPersonneExterieure) => {
        let email = "";

        if (person.coordonnees) {
            person.coordonnees.forEach((c) => {
                if (c.type === "email") email = c.coordonnees;
            })
        }

        return email;
    };

    /**
     * Lancement de la recherche par mot clé
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData) => {
        const searchedKeyword = formDatas.keyword as string;
        setKeyword(searchedKeyword);
        updateFilters({keyword: searchedKeyword});
    };

    /**
     * Indique si un notaire ou une personne extérieure est déjà inscrit.e
     * @param {INotaire | IPersonneExterieure} person
     * @param {ICorrespondanceInvite[]} inscrits
     * @returns {boolean}
     */
    const isAlreadySubscribed = (person: INotaire | IPersonneExterieure, inscrits: ICorrespondanceInvite[]): boolean => {
        return inscrits.findIndex((i)=>{
            const entity = i.notaire || i.personneExterieure || null;
            return entity && entity.id === person.id;
        }) !== -1;
    };

    /**
     * Indique si un notaire ou une personne extérieure est ajouté.e
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const isAdded = (person: INotaire | IPersonneExterieure): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            return addedNotaires.findIndex((n)=>n === person.id) !== -1;
        }else if(activeTab === TabsSlugs.EXTERIEURS ){
            return addedPersonnes.findIndex((p)=>p === person.id) !== -1;
        }
        return false;
    };


    /**
     * Indique si une personne est déjà supprimée
     *
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const isDeleted = (person: INotaire | IPersonneExterieure): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            return deletedNotaires.findIndex((n)=>n === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            return deletedPersonnes.findIndex((p)=>p === person.id) !== -1;
        }
        return false;
    };



    /**
     * Ajoute/retire une personne des ajouts
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const toggleAdded = (person: INotaire | IPersonneExterieure): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            if(isAdded(person)){
                setAddedNotaires(addedNotaires.filter((n)=>n !== person.id));
            }else{
                const newNotaires: number[] = [...addedNotaires];
                newNotaires.push(person.id);
                setAddedNotaires(newNotaires);
            }
        }else if(activeTab === TabsSlugs.EXTERIEURS ){
            if(isAdded(person)){
                setAddedPersonnes(addedPersonnes.filter((p)=>p !== person.id));
            }else{
                const newPersonnes: number[] = [...addedPersonnes];
                newPersonnes.push(person.id);
                setAddedPersonnes(newPersonnes);
            }
        }
        return false;
    };

    /**
     * Suppression d'une personne déjà liée à cette correspondance
     *
     * @param {IPersonneExterieure} person
     */
    const toggleDeleted = (person: INotaire | IPersonneExterieure): void => {
        if(activeTab === TabsSlugs.NOTAIRES ) {
            if (isDeleted(person)) {
                setDeletedNotaires(deletedNotaires.filter((p) => p !== person.id));
            }
            else {
                const newPersonnes: number[] = [...deletedNotaires];
                newPersonnes.push(person.id);
                setDeletedNotaires(newPersonnes);
            }
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            if (isDeleted(person)) {
                setDeletedPersonnes(deletedPersonnes.filter((p) => p !== person.id));
            }
            else {
                const newPersonnes: number[] = [...deletedPersonnes];
                newPersonnes.push(person.id);
                setDeletedPersonnes(newPersonnes);
            }
        }
    }


    /**
     * Soumission des inscriptions
     */
    const onSubmit = () => {
        const datas:{[key:string]:any} = {
            notaires: addedNotaires,
            personnes: addedPersonnes,
            personnesToDelete: deletedPersonnes,
            notairesToDelete: deletedNotaires,
            collaborateursToDelete: deletedCollaborateurs,
        };
        if(addedCollaborateurs){
            for(let [k,v] of Object.entries(addedCollaborateurs)){
                datas[k] = v;
            }
        }
        correspondancesService.updateCorrespondanceTests(props.cid,datas).then((r)=>{
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les comptes de tests ont bien été ajoutés.",
                type: "success"
            });
            goToCorrespondance();
        },(r)=>{
            if(r.fieldsMessages){
                setFieldsErrors(r.fieldsMessages);
            }
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue.",
                type: "danger"
            });
        });
    };

    const onCollaborateursAdded = (values: FormComponentFormData) => {
        const idsToDelete = [];
        const collaborateursAdded:any = [];

        for(let [k,v] of Object.entries(values)){
            if(k == 'idsToDelete'){
                idsToDelete.push(v);
            }
            else{
                collaborateursAdded[k] = v;
            }
        }

        setDeletedCollaborateurs(idsToDelete);
        setAddedCollaborateurs(collaborateursAdded);
    };

    /**
     * Retour à la correspondance
     */
    const goToCorrespondance = () => {
        history.push(AppConstants.pathCorrespondances+'/'+props.cid);
    };

    const onTabClick = (tab:string) => {
        setActiveTab(tab);
        updateFilters({},true);
        setKeyword("");
    };

    return (
        <>
            {
                correspondance && loaded &&
				<LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
				                 contentModificators={`-bg-white -no-flex`}>
					<PageHeaderComponent text="Correspondance" subText={"Ajouter des tests"}
					                     icon="icon-tests-add"
					                     modificators={`-in-fullscreen -border-full-screen`}/>

					<TabsContainerComponent onClickTabCallback={onTabClick}>
                        <TabPanelComponent label="Notaires" icon="icon-notaires" slug={TabsSlugs.NOTAIRES}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"
                                                      searchAuto={true}/>
                            </div>
                            {
                                isLoading &&
                                <LoadingComponent></LoadingComponent>
                            }
                            {
                                !isLoading &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "crpcen", noSort: false},
                                                {slug: "nom", noSort: false},
                                                {slug: "type", noSort: false},
                                                {slug: "office", noSort: false},
                                                {slug: "ville", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) =>
                                                <th
                                                    className="th -center" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={item.slug} click={(value: string) => setOrder(value)}
                                                        noSort={item.noSort}
                                                    />
                                                </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            correspondance && notaires && notaires.map((notaire: INotaire, index: number) => {
                                                const subscribed = isAlreadySubscribed(notaire, correspondance.tests);
                                                return <tr key={index} className={`tr`}>
                                                    <td className="td -bold">
                                                        {notaire.office && <>{notaire.office.CRPCEN}</>}
                                                    </td>
                                                    <td className="td">
                                                        <Link className="g-link -primary -underline-on-hover -bold"
                                                              to={`${AppConstants.pathNotaires}/${notaire.id}`}
                                                              onClick={(e) => e.stopPropagation()}>{notaire.nom} {notaire.prenom}</Link>
                                                    </td>
                                                    <td className="td">
                                                        {NotairesHelper.getFormattedType(notaire.type)}
                                                    </td>
                                                    <td className="td">
                                                        {
                                                            notaire.office &&
                                                            <Link className="g-link -primary -underline-on-hover -bold"
                                                                  to={`${AppConstants.pathOffices}/${notaire.office.id}`}
                                                                  onClick={(e) => e.stopPropagation()}>{notaire.office.nom}</Link>
                                                        }
                                                    </td>

                                                    <td className="td -uppercase">{notaire.adressePrincipale && <>{notaire.adressePrincipale.ville}</>}</td>
                                                    <td className="td">{getEmail(notaire) || '-'}</td>
                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(notaire) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(notaire)}/>}
                                                        {!subscribed && !isAdded(notaire) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(notaire)}>Ajouter</button>}
                                                        {subscribed && isDeleted(notaire) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(notaire)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(notaire) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(notaire)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Collaborateurs" icon="icon-collaborateurs"
                                           slug={TabsSlugs.COLLABORATEURS}>
                            <div className={`form-wrapper -margin-top`}>
                                <RepeaterFieldComponent fieldName="collaborateurs" errorFieldsMessages={fieldsErrors}
                                                        formColumns={collaborateursFormsColumns}
                                                        onChange={onCollaborateursAdded} oldValues={oldCollaborateurs}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Extérieurs" icon="icon-autres-contacts" slug={TabsSlugs.EXTERIEURS}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"
                                                      searchAuto={true}/>
                            </div>
                            {
                                isLoading &&
                                <LoadingComponent></LoadingComponent>
                            }
                            {
                                !isLoading &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "nom", noSort: false},
                                                {slug: "fonction", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) => <th
                                                className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    noSort={item.noSort}
                                                    label={item.slug} click={(value: string) => setOrder(value)}/>
                                            </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            personnes && personnes.map((personne: IPersonneExterieure, index: number) => {
                                                const subscribed = isAlreadySubscribed(personne, correspondance.tests);
                                                return <tr key={index} className={`tr `}>
                                                    <td className="td">
                                                        {personne.nom} {personne.prenom}
                                                    </td>
                                                    <td className="td">
                                                        {personne.fonction}
                                                    </td>
                                                    <td className="td">{getEmail(personne) || '-'}</td>
                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(personne) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(personne)}/>}
                                                        {!subscribed && !isAdded(personne) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(personne)}>Ajouter</button>}
                                                        {subscribed && isDeleted(personne) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(personne)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(personne) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(personne)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                        </TabPanelComponent>
                    </TabsContainerComponent>


                    <div className="form-actions -outside-right">
                        {showConfirmation && <ConfirmationComponent text={"Quitter sans enregistrer les inscriptions ?"}
                                                                    onConfirm={() => goToCorrespondance()}
                                                                    onCancel={() => setShowConfirmation(false)}/>}
                        <button className="g-button -secondary-border-only" onClick={() => setShowConfirmation(true)}>
                            Quitter sans enregistrer
                        </button>
	                    <button className="g-button -secondary" onClick={onSubmit}>
		                    Enregistrer
                        </button>
                    </div>
				</LayoutComponent>
            }
        </>
    )
};

export default CorrespondanceAjoutTestsScreen;
