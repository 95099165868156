import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IReclamationGetApiResponse, ReclamationsService} from "../Services/Reclamations.service";
import {IReclamation} from "../Models/Reclamation.model";

const UseReclamation = () => {
    const reclamationsService: ReclamationsService = new ReclamationsService();
    const history = useHistory();

    /**
     * Récupération d'une réclamation à partir de son id
     *
     * @param {string} rid
     * @param {(sinistre: ISinistre) => void} callback
     * @param {() => void} callbackError
     */
    const getReclamationById = (rid: string, callback:(reclamation: IReclamation) => void, callbackError?: () => void ) => {
        reclamationsService.getReclamation(rid).then((response: IReclamationGetApiResponse) => {
            if( response && response.datas.reclamation){
                if(callback){
                    callback(response.datas.reclamation);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathReclamations);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la réclamation'",type: "danger"});
        });
    };


    return {getReclamationById};
};

export default UseReclamation;
