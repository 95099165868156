import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type OfficeExportComponentProps = {
    setShowExport: () => void,
    filters?: any,
    showExport: boolean,
}
export default function OfficesExportComponent(props: OfficeExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Offices"}
                         icon="icon-office"
                         subTitle={"Export des offices"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl={`api${AppConstants.pathOffices}/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-office',
                                         fieldName: "identite",
                                         label: "Identité",
                                         options: [
                                             {value: 'nom', label: 'Nom de l\'office'},
                                             {value: 'type', label: 'Type'},
                                             {value: 'actif', label: 'Actif'},
                                             {value: 'CRPCEN', label: 'CRPCEN'},
                                             {value: 'sirenSiret', label: 'Siren/Siret'},
                                             {value: 'TVA', label: 'N° de TVA'},
                                             {value: 'anneeCreation', label: 'Année de création'},
                                             {value: 'officeCree', label: 'Office Créé'},
                                             {value: 'numeroAgrement', label: 'Num. agrément'},
                                             {value: 'dateDelivrance', label: 'Date de délivrance'},
                                             {value: 'anneeDebutActivite', label: 'Début activité'},
                                         ]
                                     },
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-logiciels',
                                         fieldName: "logiciels",
                                         label: "Groupes et Logiciels",
                                         options: [
                                             {value: 'logicielRedactionActes', label: 'Logiciel de rédaction d\'acte'},
                                             {value: 'logicielVisio', label: 'Logiciel Visio'},
                                             {value: 'systemeCompta', label: 'Système Compta'},
                                             {value: 'autres.groupes', label: 'Groupes'},
                                             {value: 'logicielNegociation', label: 'Logiciel de négociation'},
                                             {value: 'logicielAutres', label: 'Autres Logiciels'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-coordonnees',
                                         fieldName: "coordonnees",
                                         label: "Coordonnées",
                                         options: [
                                             {value: 'coordonnees.emailOffice', label: 'Email Principal'},
                                             {value: 'coordonnees.emailComptabilite', label: 'Email Comptabilité'},
                                             {value: 'coordonnees.emailAutre', label: 'Email Autre'},
                                             {value: 'coordonnees.telephoneOffice', label: 'Téléphone Principal'},
                                             {value: 'coordonnees.telephoneCompta', label: 'Téléphone Comptabilité'},
                                             {value: 'coordonnees.telephoneAutre', label: 'Téléphone Autre'},
                                             {value: 'coordonnees.siteInternet', label: 'URL'},
                                             {value: 'adresses.ligne1', label: 'Rue'},
                                             {value: 'adresses.ligne2', label: 'Complément d\'adresse'},
                                             {value: 'adresses.boitePostale', label: 'Boîte Postale'},
                                             {value: 'adresses.codePostal', label: 'Code Postal / CEDEX'},
                                             {value: 'adresses.ville', label: 'Ville'},
                                             {value: 'adresses.arrondissement', label: 'Arrondissement'},
                                             {value: 'adresses.tribunalJudiciaire', label: 'Tribunal Judiciaire'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-effectif',
                                         fieldName: "effectifs",
                                         label: "Effectifs",
                                         options: [
                                             {value: 'notaires.totalEffectif', label: 'Total notaires'},
                                             {
                                                 value: 'notaires.nbNotairesTitulaires',
                                                 label: 'Nombre de notaires titulaires'
                                             },
                                             {value: 'notaires.notairesTitulaires', label: 'Notaires titulaires'},
                                             {
                                                 value: 'notaires.nbNotairesSalaries',
                                                 label: 'Nombre de notaires salariés'
                                             },
                                             {value: 'notaires.notairesSalaries', label: 'Notaires salariés'},
                                             {value: 'autres.predecesseurs', label: 'Prédécesseurs'},
                                             {value: 'autres.negociateurs', label: 'Négociateurs'},
                                             {value: 'autres.emailNegociation', label: 'Email de négociation'},
                                         ]
                                     },
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-chiffre',
                                         fieldName: "chiffres",
                                         label: "Données chiffrées",
                                         options: [
                                             {value: 'nbSalaries', label: 'Nombre de salariés (Année n-1)'},
                                             {value: 'nbActes', label: 'Nombre d\'actes (Année n-1)'},
                                             {
                                                 value: 'nbSuccessions',
                                                 label: 'Nombre de déclarations de successions (Année n-1)'
                                             },
                                         ]
                                     },
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-certifications',
                                         fieldName: "certifications",
                                         label: "Certifications et Labels",
                                         options: [
                                             {value: 'autres.certifications', label: 'Certifications ISO'},
                                             {value: 'autres.labels', label: 'Labels'},
                                             {value: 'borneElectrique', label: 'Borne Electrique'},
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-office',
                                         fieldName: "annexe",
                                         label: "Annexes",
                                         options: [
                                             {value: 'autres.annexes', label: 'Annexes'},
                                         ]
                                     },

                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-repertoire',
                                         fieldName: "repertoire",
                                         label: "Répertoire",
                                         options: [
                                             {value: 'autres.repertoire', label: 'Répertoires'},
                                         ]
                                     },
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-recus-livraison',
                                         fieldName: "recu",
                                         label: "Reçus livraison",
                                         options: [
                                             {value: 'autres.recus', label: 'Reçus de livraison'},
                                         ]
                                     },
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-commentaires',
                                         fieldName: "commentaires",
                                         label: "Commentaires",
                                         options: [
                                             {value: 'commentaires', label: 'Commentaires'},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )

}
