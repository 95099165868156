import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {ILabelGetApiResponse, ILabelInformationsFormData, LabelsService} from "src/Services/Labels.service";
import {ILabel} from "src/Models/Label.model";
import LabelsInformationsFormComponent from "src/Components/Forms/Annuaire/Labels/LabelsInformationsForm.component";


type ILabelCreationScreenProps = {
}
export default function LabelCreationScreen(props: ILabelCreationScreenProps){
    const history = useHistory();
    const labelsService: LabelsService = new LabelsService();

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: ILabelGetApiResponse): void => {
        if(response.datas && response.datas.label){
            const label: ILabel = response.datas.label;
            history.push(AppConstants.pathLabels + '/' + label.id);
        }
        else{
            history.push(AppConstants.pathLabels);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathLabels);

    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un label" icon="icon-labels" modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />

            <LabelsInformationsFormComponent submitMethod={(formData: ILabelInformationsFormData)=>labelsService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />
        </LayoutComponent>
    )
}
