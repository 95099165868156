import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {IInspection} from "../../Models/Inspection.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {IInspectionGetInspecteursListFormData, InspectionsService} from "../../Services/Inspections.service";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {AppConstants, TabsSlugs} from "../../Constants/AppConstants";


type IAsideInspectionsComponentProps = {
    inspection?: IInspection,
    modificators?: string,
    type: "list" | "fiche" | "listExport"| "listExportOld"| "listExportNext"|"listSuivi"|"courrier",
    annee?: number,
    isTest?: boolean,

    exportCallback?: () => void,
    printCallback?: () => void,
    filters?: any,
}

export default function AsideInspectionsComponent(props: IAsideInspectionsComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const inspectionsService: InspectionsService = new InspectionsService();

    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "listExport" || props.type === "listExportOld" || props.type === "listExportNext") {
            if(props.annee !== undefined) {
                actions.push({icon: "icon-print", click: () => onPrint(props.annee, props.isTest)});
            }

            if( props.type === "listExport") {
                actions.push({icon: "icon-inspection-export-inspecteur", click: () => props.exportCallback()});
            }
        }
        else if(props.type === "listSuivi") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
        }
        else{
            actions.push({icon: "icon-print", click: () => printScreen()});
        }

        setActions(actions);
    }, [props.annee, props.type, props.filters]);

    /**
     * Print des stats de l'office
     */
    const onPrint = (year: number, isTest: boolean) =>{
        setIsLoading(true);

        const params: IInspectionGetInspecteursListFormData = {
            ...props.filters,
            annee: year,
            isTest: isTest,
        };

        inspectionsService.printPdf(params).then((response)=>{
            if (response && response.datas.url) {
                handleFileDownload(response.datas.url,true);
            }else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }
            setIsLoading(false);
        });
    };

    /**
     * Téléchargement du fichier
     *
     * @param {string} url
     * @param {boolean} blank
     */
    const handleFileDownload = (url:string,blank: boolean = false) => {
        const link = document.createElement('a');
        link.href = url;
        if(blank)
            link.target = "_blank";
        link.setAttribute('download', ``);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    /**
     * Export pdf de la page de liste de suivi
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathInspections}/${TabsSlugs.SUIVI}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
