import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiFieldsErrorMessages, IApiPaginationLink} from "src/Services/Api.service";
import {GroupesService} from "src/Services/Groupes.service";
import useGroupe from "src/Hooks/UseGroupe";
import {IGroupe} from "src/Models/Groupe.model";
import TabsContainerComponent, {TabPanelComponent} from "../../../Components/Tabs/TabsContainer.component";
import {INotaireGetFormData, INotairesGetApiResponse, NotairesService} from "../../../Services/Notaires.service";
import TableHeaderButtonComponent from "../../../Components/Lists/TableHeaderButton.component";
import {INotaire} from "../../../Models/Notaire.model";
import NotairesHelper from "../../../Helpers/Notaires.helper";
import PaginationComponent from "../../../Components/Pagination/Pagination.component";
import useListFilters from "../../../Hooks/FiltersHandler";
import {IPersonneExterieure} from "../../../Models/PersonneExterieure.model";
import {Store as notificationSystem} from "react-notifications-component";
import {
    AutresContactsService,
    IAutresContactsGetFormData,
    IOrganismesGetFormData, IOrganismesWithPaginationGetApiResponse,
    IPersonnesExterieuresGetApiResponse
} from "../../../Services/AutresContacts.service";
import SearchBlockComponent, {IFormSearchData} from "../../../Components/Search/SearchBlock.component";
import ConfirmationComponent from "../../../Components/Confirmation/Confirmation.component";
import {IOffice} from "../../../Models/Office.model";
import {IOrganisme} from "../../../Models/Organisme.model";
import {IOfficesGetApiResponse, IOfficesGetFormData, OfficesService} from "../../../Services/Offices.service";
import {defaultNotificationConfig} from "../../../Shared/config";
import {Link} from "react-router-dom";
import LoaderComponent from "../../../Components/Loading/Loader.component";

const GroupeAjoutMembresScreen = (props: {
    gid: string
}) => {
    const [getGroupeById] = useGroupe();
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder,setPath,defaultFilters] = useListFilters(`/groupes/${props.gid}/invites`, false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [anciensNotaires, setAnciensNotaires] = useState<INotaire[]>([]);
    const [offices, setOffices] = useState<IOffice[]>([]);
    const [organismes, setOrganismes] = useState<IOrganisme[]>([]);
    const [personnes, setPersonnes] = useState<IPersonneExterieure[]>([]);

    const [groupe, setGroupe] = useState<IGroupe>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.NOTAIRES);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [keyword,setKeyword] = useState<string>("");
    const [fieldsErrors,setFieldsErrors] = useState<IApiFieldsErrorMessages>(null);

    const [showConfirmation,setShowConfirmation] = useState<boolean>(false);

    const [addedNotaires,setAddedNotaires] = useState<number[]>([]);
    const [addedAnciensNotaires,setAddedAnciensNotaires] = useState<number[]>([]);
    const [addedPersonnes,setAddedPersonnes] = useState<number[]>([]);
    const [addedOffices, setAddedOffices] = useState<number[]>([]);
    const [addedOrganismes, setAddedOrganismes] = useState<number[]>([]);

    const [deletedPersonnes,setDeletedPersonnes] = useState<number[]>([]);
    const [deletedNotaires,setDeletedNotaires] = useState<number[]>([]);
    const [deletedAnciensNotaires,setDeletedAnciensNotaires] = useState<number[]>([]);
    const [deletedOffices,setDeletedOffices] = useState<number[]>([]);
    const [deletedOrganismes,setDeletedOrganismes] = useState<number[]>([]);

    const history = useHistory();

    const groupesService: GroupesService = new GroupesService();
    const notairesService: NotairesService = new NotairesService();
    const personneExterieuresService: AutresContactsService = new AutresContactsService();
    const officesService: OfficesService = new OfficesService();
    const autresContactService: AutresContactsService = new AutresContactsService();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        updateFilters(defaultFilters);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    useEffect(() => {
        if (!props.gid) {
            history.push(AppConstants.path403);
        }
        getGroupe();
    }, [props.gid]);


    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setIsSearching(true);

        if (activeTab === TabsSlugs.NOTAIRES) {
            filters.decede = "non";
            notairesService.getNotairesWithPaginate(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // Notaires
                    const currentAnciensNotairesList: INotaire[] = response.datas.pagination.data;
                    setNotaires(currentAnciensNotairesList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);


                    if (!loaded) {
                        setLoaded(true);
                    }
                }
                setIsSearching(false);
            });
        }

        if (activeTab === TabsSlugs.ANCIENS_NOTAIRES) {
            filters.decede = "non";
            notairesService.getAnciensNotairesWithPaginate(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
                if (response && response.datas.pagination) {
                    // Anciens Notaires
                    const currentNotairesList: INotaire[] = response.datas.pagination.data;
                    setAnciensNotaires(currentNotairesList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
                setIsSearching(false);
            });
        }

        if (activeTab === TabsSlugs.EXTERIEURS) {
            personneExterieuresService.getPersonnesExterieures(filters as IAutresContactsGetFormData).then((response: IPersonnesExterieuresGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // exterieurs
                    const currentAutresContactsList: IPersonneExterieure[] = response.datas.pagination.data as IPersonneExterieure[];
                    setPersonnes(currentAutresContactsList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
                setIsSearching(false);
            });
        }

        if (activeTab === TabsSlugs.OFFICES) {
            officesService.getOffices(filters as IOfficesGetFormData).then((response: IOfficesGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // offices
                    const currentOfficesList: IOffice[] = response.datas.pagination.data as IOffice[];
                    setOffices(currentOfficesList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
                setIsSearching(false);
            });
        }

        if (activeTab === TabsSlugs.ORGANISMES) {
            autresContactService.getOrganismesWithPagination(filters as IOrganismesGetFormData).then((response: IOrganismesWithPaginationGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // offices
                    const currentOrganismesList: IOrganisme[] = response.datas.pagination.data as IOrganisme[];
                    setOrganismes(currentOrganismesList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
                setIsSearching(false);
            });
        }
    };


    /**
     * Récupération de la groupe selon l'uid
     */
    const getGroupe = (): void => {
        getGroupeById(props.gid, (groupe: IGroupe) => {
            setGroupe(groupe);
            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Retourne le mail du notaire ou de la personne extérieure
     * @param {INotaire | IPersonneExterieure} person
     * @returns {string}
     */
    const getEmail = (person: INotaire | IPersonneExterieure | IOrganisme | IOffice) => {
        let email = "";

        if (person.coordonnees) {
            person.coordonnees.forEach((c) => {
                if (c.type === "email") email = c.coordonnees;
            })
        }

        return email;
    };

    /**
     * Lancement de la recherche par mot clé
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData) => {
        const searchedKeyword = formDatas.keyword as string;
        setKeyword(searchedKeyword);
        updateFilters({keyword: searchedKeyword});
    };

    /**
     * Indique si un notaire ou une personne extérieure est déjà inscrit.e
     * @param {INotaire | IPersonneExterieure} person
     * @param {any[]} inscrits
     * @returns {boolean}
     */
    const isAlreadySubscribed = (person: INotaire | IPersonneExterieure | IOrganisme | IOffice, inscrits: any[]): boolean => {
        if(!inscrits) inscrits = [];

        const findIndex = inscrits.findIndex((i) => {
            return i.id === person.id;
        });
        return findIndex !== -1;
    };


    /**
     * Indique si un notaire ou une personne extérieure est ajouté.e
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const isAdded = (person: INotaire | IPersonneExterieure | IOrganisme | IOffice): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            return addedNotaires.findIndex((n)=>n === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.ANCIENS_NOTAIRES ){
            return addedAnciensNotaires.findIndex((n)=>n === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            return addedPersonnes.findIndex((p)=>p === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.ORGANISMES ){
            return addedOrganismes.findIndex((p)=>p === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.OFFICES ){
            return addedOffices.findIndex((p)=>p === person.id) !== -1;
        }
        return false;
    };




    /**
     * Indique si une personne est déjà supprimée
     *
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */

    const isDeleted = (person: INotaire | IPersonneExterieure | IOrganisme | IOffice): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            return deletedNotaires.findIndex((n)=>n === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.ANCIENS_NOTAIRES ){
            return deletedAnciensNotaires.findIndex((n)=>n === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            return deletedPersonnes.findIndex((p)=>p === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.ORGANISMES ){
            return deletedOrganismes.findIndex((p)=>p === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.OFFICES ){
            return deletedOffices.findIndex((p)=>p === person.id) !== -1;
        }
        return false;
    };


    /**
     * Ajoute/retire une personne des ajouts
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const toggleAdded = (person: INotaire | IPersonneExterieure | IOrganisme | IOffice): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            if(isAdded(person)){
                setAddedNotaires(addedNotaires.filter((n)=>n !== person.id));
            }else{
                const newNotaires: number[] = [...addedNotaires];
                newNotaires.push(person.id);
                setAddedNotaires(newNotaires);
            }
        }
        else if(activeTab === TabsSlugs.ANCIENS_NOTAIRES ){
            if(isAdded(person)){
                setAddedAnciensNotaires(addedNotaires.filter((n)=>n !== person.id));
            }else{
                const newNotaires: number[] = [...addedAnciensNotaires];
                newNotaires.push(person.id);
                setAddedAnciensNotaires(newNotaires);
            }
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            if(isAdded(person)){
                setAddedPersonnes(addedPersonnes.filter((p)=>p !== person.id));
            }else{
                const newPersonnes: number[] = [...addedPersonnes];
                newPersonnes.push(person.id);
                setAddedPersonnes(newPersonnes);
            }
        }
        else if(activeTab === TabsSlugs.ORGANISMES ){
            if(isAdded(person)){
                setAddedOrganismes(addedOrganismes.filter((p)=>p !== person.id));
            }else{
                const newOrganismes: number[] = [...addedOrganismes];
                newOrganismes.push(person.id);
                setAddedOrganismes(newOrganismes);
            }
        }
        else if(activeTab === TabsSlugs.OFFICES ){
            if(isAdded(person)){
                setAddedOffices(addedOffices.filter((p)=>p !== person.id));
            }else{
                const newOffices: number[] = [...addedOffices];
                newOffices.push(person.id);
                setAddedOffices(newOffices);
            }
        }

        return false;
    };





    /**
     * Suppression d'une personne déjà liée à cette correspondance
     *
     * @param {IPersonneExterieure} person
     */
    const toggleDeleted = (person: INotaire | IPersonneExterieure | IOrganisme | IOffice): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            if(isDeleted(person)){
                setDeletedNotaires(deletedNotaires.filter((n)=>n !== person.id));
            }else{
                const newNotaires: number[] = [...deletedNotaires];
                newNotaires.push(person.id);
                setDeletedNotaires(newNotaires);
            }
        }
        else if(activeTab === TabsSlugs.ANCIENS_NOTAIRES ){
            if(isDeleted(person)){
                setDeletedAnciensNotaires(deletedNotaires.filter((n)=>n !== person.id));
            }else{
                const newNotaires: number[] = [...deletedAnciensNotaires];
                newNotaires.push(person.id);
                setDeletedAnciensNotaires(newNotaires);
            }
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            if(isDeleted(person)){
                setDeletedPersonnes(deletedPersonnes.filter((p)=>p !== person.id));
            }else{
                const newPersonnes: number[] = [...deletedPersonnes];
                newPersonnes.push(person.id);
                setDeletedPersonnes(newPersonnes);
            }
        }
        else if(activeTab === TabsSlugs.ORGANISMES ){
            if(isDeleted(person)){
                setDeletedOrganismes(deletedOrganismes.filter((p)=>p !== person.id));
            }else{
                const newOrganismes: number[] = [...deletedOrganismes];
                newOrganismes.push(person.id);
                setDeletedOrganismes(newOrganismes);
            }
        }
        else if(activeTab === TabsSlugs.OFFICES ){
            if(isDeleted(person)){
                setDeletedOffices(deletedOffices.filter((p)=>p !== person.id));
            }
            else{
                const newOffices: number[] = [...deletedOffices];
                newOffices.push(person.id);
                setDeletedOffices(newOffices);
            }
        }

        return false;
    };

    /**
     * Soumission des inscriptions
     */
    const onSubmit = () => {
        const datas:{[key:string]:any} = {
            notaires: addedNotaires,
            anciensNotaires: addedAnciensNotaires,
            personnes: addedPersonnes,
            offices: addedOffices,
            organismes: addedOrganismes,
            notairesToDelete: deletedNotaires,
            anciensNotairesToDelete: deletedAnciensNotaires,
            officesToDelete: deletedOffices,
            organismesToDelete: deletedOrganismes,
            personnesToDelete: deletedPersonnes,
        };

        groupesService.updateGroupeMembres(props.gid, datas).then((r)=>{
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les membres ont bien été enregistrées",
                type: "success"
            });
            goToGroupe();
        },(r)=>{
            if(r.fieldsMessages){
                setFieldsErrors(r.fieldsMessages);
            }
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue.",
                type: "danger"
            });
        });
    };

    /**
     * Retour à la groupe
     */
    const goToGroupe = () => {
        history.push(AppConstants.pathGroupes+'/'+props.gid);
    };

    const onTabClick = (tab:string) => {
        setActiveTab(tab);
        setIsSearching(true);

        updateFilters({},true);

        setKeyword("");
        setPaginationLinks(null);
        /*
        setAddedPersonnes([]);
        setAddedNotaires([]);
        setAnciensNotaires([]);
        setAddedOffices([]);
        setAddedOrganismes([]);
         */
    };

    return (
        <>
            {
                groupe && loaded &&
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                                 contentModificators={`-bg-white -no-flex`}>
                    <PageHeaderComponent text="Groupes" subText={"Ajouter des membres"}
                                         icon="icon-groupes-add"
                                         modificators={`-in-fullscreen -border-full-screen`}/>

                    <TabsContainerComponent onClickTabCallback={onTabClick}>
                        <TabPanelComponent label="Notaires" icon="icon-notaires" slug={TabsSlugs.NOTAIRES}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword} onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"/>
                            </div>
                            {
                                !isSearching && groupe && notaires &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "crpcen", noSort: false},
                                                {slug: "nom", noSort: false},
                                                {slug: "type", noSort: false},
                                                {slug: "office", noSort: false},
                                                {slug: "ville", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) =>
                                                <th
                                                    className="th -center" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={item.slug} click={(value: string) => setOrder(value)}
                                                        noSort={item.noSort}
                                                    />
                                                </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            groupe && notaires && notaires.map((notaire: INotaire, index: number) => {
                                                const subscribed = isAlreadySubscribed(notaire, groupe.notaires);

                                                return <tr key={index} className={`tr`}>
                                                    <td className="td -bold">
                                                        {notaire.office && <>{notaire.office.CRPCEN}</>}
                                                    </td>
                                                    <td className="td">
                                                        <Link className="g-link -primary -underline-on-hover -bold"
                                                              to={`${AppConstants.pathNotaires}/${notaire.id}`}
                                                              onClick={(e) => e.stopPropagation()}>{notaire.nom} {notaire.prenom}</Link>
                                                    </td>
                                                    <td className="td">
                                                        {NotairesHelper.getFormattedType(notaire.type)}
                                                    </td>
                                                    <td className="td">
                                                        {
                                                            notaire.office &&
                                                            <Link className="g-link -primary -underline-on-hover -bold"
                                                                  to={`${AppConstants.pathOffices}/${notaire.office.id}`}
                                                                  onClick={(e) => e.stopPropagation()}>{notaire.office.nom}</Link>
                                                        }
                                                    </td>
                                                    <td className="td -uppercase">{notaire.adressePrincipale && <>{notaire.adressePrincipale.ville}</>}</td>
                                                    <td className="td">{getEmail(notaire) || '-'}</td>
                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(notaire) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(notaire)}/>}
                                                        {!subscribed && !isAdded(notaire) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(notaire)}>Ajouter</button>}
                                                        {subscribed && isDeleted(notaire) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(notaire)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(notaire) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(notaire)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                            {
                                isSearching &&
                                <LoaderComponent/>
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Anciens Notaires" icon="icon-notaires"
                                           slug={TabsSlugs.ANCIENS_NOTAIRES}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"/>
                            </div>
                            {
                                !isSearching && groupe && anciensNotaires &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "crpcen", noSort: false},
                                                {slug: "nom", noSort: false},
                                                {slug: "type", noSort: false},
                                                {slug: "office", noSort: false},
                                                {slug: "ville", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) =>
                                                <th
                                                    className="th -center" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={item.slug} click={(value: string) => setOrder(value)}
                                                        noSort={item.noSort}
                                                    />
                                                </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            groupe && anciensNotaires && anciensNotaires.map((notaire: INotaire, index: number) => {
                                                const subscribed = isAlreadySubscribed(notaire, groupe.notaires);

                                                return <tr key={index} className={`tr`}>
                                                    <td className="td -bold">
                                                        {notaire.office && <>{notaire.office.CRPCEN}</>}
                                                    </td>
                                                    <td className="td">
                                                        <Link className="g-link -primary -underline-on-hover -bold"
                                                              to={`${AppConstants.pathNotairesAnciens}/${notaire.id}`}
                                                              onClick={(e) => e.stopPropagation()}>{notaire.nom} {notaire.prenom}</Link>
                                                    </td>
                                                    <td className="td">
                                                        {NotairesHelper.getFormattedType(notaire.type)}
                                                    </td>
                                                    <td className="td">
                                                        {
                                                            notaire.office &&
                                                            <Link className="g-link -primary -underline-on-hover -bold"
                                                                  to={`${AppConstants.pathOffices}/${notaire.office.id}`}
                                                                  onClick={(e) => e.stopPropagation()}>{notaire.office.nom}</Link>
                                                        }
                                                    </td>
                                                    <td className="td -uppercase">{notaire.adressePrincipale && <>{notaire.adressePrincipale.ville}</>}</td>
                                                    <td className="td">{getEmail(notaire) || '-'}</td>
                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(notaire) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(notaire)}/>}
                                                        {!subscribed && !isAdded(notaire) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(notaire)}>Ajouter</button>}
                                                        {subscribed && isDeleted(notaire) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(notaire)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(notaire) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(notaire)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                            {
                                isSearching &&
                                <LoaderComponent/>
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Extérieurs" icon="icon-autres-contacts" slug={TabsSlugs.EXTERIEURS}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"/>
                            </div>
                            {
                                !isSearching && groupe && personnes &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "nom", noSort: false},
                                                {slug: "fonction", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "organisme", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) => <th
                                                className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    noSort={item.noSort}
                                                    label={item.slug} click={(value: string) => setOrder(value)}/>
                                            </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            personnes && personnes.map((personne: IPersonneExterieure, index: number) => {
                                                const subscribed = isAlreadySubscribed(personne, groupe.personnesExterieures);
                                                return <tr key={index} className={`tr`}>
                                                    <td className="td">
                                                        {personne.nom} {personne.prenom}
                                                    </td>
                                                    <td className="td">
                                                        {personne.fonction}
                                                    </td>
                                                    <td className="td">{getEmail(personne) || '-'}</td>
                                                    <td className="td">
                                                        {
                                                            personne.organisme ? personne.organisme.nom : ''
                                                        }
                                                    </td>
                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(personne) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(personne)}/>}
                                                        {!subscribed && !isAdded(personne) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(personne)}>Ajouter</button>}
                                                        {subscribed && isDeleted(personne) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(personne)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(personne) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(personne)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                            {
                                isSearching &&
                                <LoaderComponent/>
                            }
                        </TabPanelComponent>


                        <TabPanelComponent label="Organismes" icon="icon-organismes" slug={TabsSlugs.ORGANISMES}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"/>
                            </div>
                            {
                                !isSearching && groupe && organismes &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "nom", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) => <th
                                                className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    noSort={item.noSort}
                                                    label={item.slug} click={(value: string) => setOrder(value)}/>
                                            </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            organismes && organismes.map((organisme: IOrganisme, index: number) => {
                                                const subscribed = isAlreadySubscribed(organisme, groupe.organismes);
                                                return <tr key={index} className={`tr`}>
                                                    <td className="td">
                                                        <Link className="g-link -primary -underline-on-hover -bold"
                                                              to={`${AppConstants.pathOrganismes}/${organisme.id}`}
                                                              onClick={(e) => e.stopPropagation()}>{organisme.nom}</Link>
                                                    </td>
                                                    <td className="td">{getEmail(organisme) || '-'}</td>

                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(organisme) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(organisme)}/>}
                                                        {!subscribed && !isAdded(organisme) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(organisme)}>Ajouter</button>}
                                                        {subscribed && isDeleted(organisme) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(organisme)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(organisme) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(organisme)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                            {
                                isSearching &&
                                <LoaderComponent/>
                            }
                        </TabPanelComponent>
                        <TabPanelComponent label="Offices" icon="icon-office" slug={TabsSlugs.OFFICES}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"/>
                            </div>
                            {
                                !isSearching && groupe && offices &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "nom", noSort: false},
                                                {slug: "email", noSort: true},
                                                {slug: "actions", noSort: true}
                                            ].map((item, iIndex) => <th
                                                className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    noSort={item.noSort}
                                                    label={item.slug} click={(value: string) => setOrder(value)}/>
                                            </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            offices && offices.map((office: IOffice, index: number) => {
                                                const subscribed = isAlreadySubscribed(office, groupe.offices);
                                                return <tr key={index} className={`tr`}>
                                                    <td className="td">
                                                        <Link className="g-link -primary -underline-on-hover -bold"
                                                              to={`${AppConstants.pathOffices}/${office.id}`}
                                                              onClick={(e) => e.stopPropagation()}>{office.nom}</Link>
                                                    </td>
                                                    <td className="td">{getEmail(office) || '-'}</td>

                                                    <td className="td -center" width={80}>
                                                        {!subscribed && isAdded(office) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(office)}/>}
                                                        {!subscribed && !isAdded(office) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(office)}>Ajouter</button>}
                                                        {subscribed && isDeleted(office) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(office)}>Ajouter</button>}
                                                        {subscribed && !isDeleted(office) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(office)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                            {
                                isSearching &&
                                <LoaderComponent/>
                            }
                        </TabPanelComponent>
                    </TabsContainerComponent>


                    <div className="form-actions -outside-right">
                        {showConfirmation &&
                            <ConfirmationComponent text={"Quitter sans enregistrer les nouveaux membres ?"}
                                                   onConfirm={() => goToGroupe()}
                                                   onCancel={() => setShowConfirmation(false)}/>}
                        <button className="g-button -secondary-border-only" onClick={() => setShowConfirmation(true)}>
                            Quitter sans enregistrer
                        </button>
                        <button className="g-button -secondary" onClick={onSubmit}>
                            Enregistrer
                        </button>
                    </div>
                </LayoutComponent>
            }
        </>
    )
};

export default GroupeAjoutMembresScreen;
