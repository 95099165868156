import React, {useContext, useEffect, useState} from "react";
import {IUser} from "src/Models/User.model";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {IOption} from "src/Components/Fields/Select.field.component";
import {UserConstants} from "src/Constants/UsersConstants";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {IGetUserApiResponse, IUserEditFormData, UsersService} from "src/Services/Users.service";
import {IGetModuleApiResponse, ModulesService} from "src/Services/Modules.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "../FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useCreationSteps from "../../../Hooks/useCreationSteps";


type UserEditFormComponentProps = {
    user?: IUser,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IUserEditFormData) => Promise<IGetUserApiResponse>,
    onSuccessSubmit: (response: IGetUserApiResponse) => void,
    onSuccessSubmitNext?: (response: IGetUserApiResponse) => void,
    onCancel: () => void,
}

export default function UserFormComponent(props: UserEditFormComponentProps){
    const usersService = new UsersService();
    const {authUser, isAdmin, isSuperAdmin} = useContext(UserContext) as IUserContext;
    const [modulesOption, setModulesOption] = useState<IOption[]>([]);
    const [userRolesOptions, setUserRolesOptions] = useState<IOption[]>(null);

    const roleSuperAdmin: string = UserConstants.roles.superAdmin;
    const roleAdmin: string = UserConstants.roles.admin;
    const roleContributeur: string = UserConstants.roles.contributor;

    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Récupération des modules à l'initialisation du composant
     */
    useEffect(() => {
        const modulesService = new ModulesService();
        modulesService.getModules().then((response: IGetModuleApiResponse)=> {
            if( response.datas && response.datas.modules){
                setModulesOption(modulesService.formatModulesForSelect(response.datas.modules));
            }
        });
    }, []);


    /**
     * Préparation de la liste des options
     * Dans le cas où l'utilisateur connecté n'est pas un "Super Admin", il ne peut pas mettre le rôle "Super Admin"
     * Dans le cas où l'utilisateur connecté n'est pas un "Admin", il ne peut pas mettre le rôle "Admin"
     */
    useEffect(() => {
        let userRolesOptions: IOption[] = UserConstants.rolesOptions;
        const authUserRole: string = authUser.role;

        if(authUserRole === roleSuperAdmin || authUserRole === roleAdmin){
            userRolesOptions = userRolesOptions.filter((o: IOption) => o.value <= authUserRole);
        }

        setUserRolesOptions(userRolesOptions);
    }, [authUser, roleAdmin, roleSuperAdmin]);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        if(!modulesOption || !modulesOption.length) return;
        const user: IUser = props.user;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: user && user.nom ? user.nom : "",
                showFieldErrorDetail: true,
            },
            {
                type: 'email',
                fieldName: "email",

                label: "Email",
                placeholder: "Email",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: user && user.email ? user.email : "",
                showFieldErrorDetail: true,
            },
            {
                type: 'password',
                fieldName: "password",

                label: "Mot de passe",
                placeholder: "Mot de passe",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                showFieldErrorDetail: true,

                oldValue: "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "prenom",

                label: "Prénom",
                placeholder: "Prénom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: user && user.prenom ? user.prenom : "",
                showFieldErrorDetail: true,
            }
        ];
        if(isAdmin() || isSuperAdmin()){
            formElementsColumn2.push({
                type: 'select',
                fieldName: "role",

                label: "Rôle",
                placeholder: "Choisissez un rôle",
                required: true,
                options: userRolesOptions,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                hideSearchOnMultiple: true,

                oldValue: user && user.role ? user.role : "",
            });
            formElementsColumn2.push({
                type: 'password',
                fieldName: "password_confirmation",

                label: "Confirmation du mot de passe",
                placeholder: "Confirmation du mot de passe",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                showFieldErrorDetail: true,

                oldValue: "",
            });
        }
        else{
            formElementsColumn2.push({
                type: 'password',
                fieldName: "password_confirmation",

                label: "Confirmation du mot de passe",
                placeholder: "Confirmation du mot de passe",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                showFieldErrorDetail: true,

                oldValue: "",
            });
        }



        let formElementsColumn3: FormElement[] = [
        ];

        if(isAdmin() || isSuperAdmin()){
            formElementsColumn3.push({
                type: 'selectMultiple',
                fieldName: "modules",

                label: "Modules",
                placeholder: "Choisissez un ou plusieurs module(s)",
                required: true,
                options: modulesOption,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                hideSearchOnMultiple: true,

                oldValues: user && user.modules ? usersService.getAllowedModulesSlugsFromUser(user) : [],
                condition: {
                    fieldName: "role",
                    value: roleContributeur
                }
            });
        }

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            }
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    },[props.user, modulesOption, userRolesOptions]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: IGetUserApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les données personnelles ont bien été mises à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {

            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations personnelles.",
                    type: "danger"
                });
            }
        });


    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorMessages={errorMessages} />
        </div>
    )
}
