import React, {useEffect, useState} from "react";
import {InputFieldProps} from "./Input.field.component";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "../../Services/Api.service";
import NumberExtendedFieldComponent from "./NumberExtended.field.component";

type FromToProps = InputFieldProps & {
    oldValue?: string,
    onChange: (value: string) => void,
}

const FromToField  = (props: FromToProps) => {

    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const [initialValues,setInitialValues] = useState<string[]>(["",""]);
    const [currentValues,setCurrentValues] = useState<string[]>(["",""]);

    useEffect(()=>{
        // Ex oldvalue : de XXX à XXX
        const parts = props.oldValue.split(" ");
        if(parts.length === 4){
            setCurrentValues([parts[1],parts[3]]);
            setInitialValues([parts[1],parts[3]]);
        }
    },[]);

    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);

    const onChange = (value:string, type: "from"|"to") => {
        let newValues = [...currentValues];
        newValues[(type === "from" ? 0 : 1)] = value
        setCurrentValues(newValues);
        if(props.onChange){
            props.onChange(`de ${newValues[0]} à ${newValues[1]}`);
        }
    };

    return <div className={`form-field ${props.modificators || ""}`}>
        {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

        <div className={`input-wrapper ${errors ? '-error' : ''} -fromto`}>
            <span className="text">De</span>
            <NumberExtendedFieldComponent errors={errors} oldValue={initialValues[0]}  fieldName={"numero"} fieldType={"text"} onChange={(value:string)=>onChange(value,"from")}/>
            <span className="text">à</span>
            <NumberExtendedFieldComponent errors={errors} oldValue={initialValues[1]} fieldName={"numero"} fieldType={"text"} onChange={(value:string)=>onChange(value,"to")}/>
        </div>
    </div>
};

export default FromToField;