import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistresGetApiResponse, ISinistresGetFormData, SinistresService} from "src/Services/Sinistres.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideSinistresComponent from "src/Components/Aside/AsideSinistres.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import SinistresHelper from "src/Helpers/Sinistres.helper";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import SinistresAdvancedSearchComponent from "src/Components/Sinistres/SinistresAdvancedSearch.component";
import SinistresExportComponent from "src/Components/Sinistres/SinistresExport.component";
import LoadingComponent from "../../Components/Loading/Loading.component";
import SelectFieldComponent from "../../Components/Fields/Select.field.component";
import moment, {Moment} from "moment";
import CtrcsHelper from "../../Helpers/Ctrcs.helper";
import {CtrcsService, ICtrcGetAllApiResponse} from "../../Services/Ctrcs.service";
import {ICtrc} from "../../Models/Ctrc.model";
import {Link} from "react-router-dom";


type ISinistresListScreenProps = {};

export default function SinistresListScreen(props: ISinistresListScreenProps) {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [sinistresList, setSinistresList] = useState<ISinistre[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathSinistres);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.SINISTRES_LISTING);

    const [ctrcs, setCtrcs] = useState<ICtrc[]>([]);
    const [ctrcsLoaded, setCTRCsLoaded] = useState<boolean>(false);

    const location = useLocation();
    const history = useHistory();

    const currentDate: Moment = moment();
    const currentYear: string = currentDate.format("YYYY");
    const previousYear: string = currentDate.subtract(1, "years").format("YYYY");

    const filtresParAnnees = [
        {value: currentYear, label: currentYear},
        {value: previousYear, label: previousYear},
        {value: 'before', label: 'Avant'},
    ];


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Sinistres", link: AppConstants.pathMosaiqueSinistres},
            {text: "Dossiers/Sinistres"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);

        //IMPORTANT : S'il n'y a pas de filtres appliqué, afficher les sinistres sans CTRC (au chargement)
        if (Object.keys(defaultFilters).length === 0 && defaultFilters.constructor === Object) {
            defaultFilters.ctrc = 'sans-ctrc';
        }

        //À partir des paramètres d'URLs, vérifier s'il y a un paramètre activeTab
        if (defaultFilters.activeTab) {
            setActiveTab(defaultFilters.activeTab as string);
        }

        updateFilters(defaultFilters, false, true);
    }, []);


    useEffect(() => {
        if (!filters) return null;//Permet de ne pas retirer les filtres au chargement de la page
        //Quand on change d'onglet/tab, mettre à jour le filtre activeTab (pour changer le paramètre dans l'URL)
        updateFilters({activeTab: activeTab}, false);
    }, [activeTab]);

    /**
     * Récupération des CTRCs (pour le filtre)
     */
    useEffect(() => {
        const ctrcsService: CtrcsService = new CtrcsService();
        ctrcsService.getAll().then((response: ICtrcGetAllApiResponse) => {
            if (response && response.datas && response.datas.ctrcs) {
                setCtrcs(response.datas.ctrcs);
                setCTRCsLoaded(true);
            }
        });
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters, ...formData}, true);
        setShowAdvancedSearch(false);
    };


    /**
     * Récupération des sinistres
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        if (activeTab === TabsSlugs.SINISTRES_LISTING) {
            const sinistresService: SinistresService = new SinistresService();
            sinistresService.getSinistres(filters as ISinistresGetFormData).then((response: ISinistresGetApiResponse) => {
                if (response && response.datas.pagination) {
                    const newSinistresList = response.datas.pagination.data;
                    setSinistresList(newSinistresList);

                    //Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }
    }

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Dossiers/Sinistres"
                    icon="icon-sinistres"
                    onSearch={(keyword => updateFilters({...defaultFilters, keyword, activeTab: activeTab}, true))}
                    onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent/>
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent defaultTab={activeTab}
                                                onClickTabCallback={(tab) => {
                                                    updateFilters({...defaultFilters, activeTab: activeTab}, true);
                                                    setActiveTab(tab);
                                                }}>
                            <TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.SINISTRES_LISTING}>
                                {activeTab === TabsSlugs.SINISTRES_LISTING &&
                                    <div className="list-content -right-gutter">
                                        <div className="list-filters">
                                            <i className={`icon icon-filtre`}/>
                                            {/*Filtre par CTRC, si la recherche avancée est effectuée sur le(s) CTRC(s) => masquer ce filtre*/}
                                            {(ctrcsLoaded && filters.id_ctrc == null) &&
                                                <SelectFieldComponent fieldName="ctrc"
                                                                      options={CtrcsHelper.getFormatedListForOptions(ctrcs, true)}
                                                                      errors={null}
                                                                      hideSearch={false}
                                                                      modificators="-on-white -no-margin-b"
                                                                      label="Filtrer par CTRC"
                                                                      oldValue={filters.ctrc ? filters.ctrc.toString() : ""}
                                                                      onChange={(value: string) => updateFilters({
                                                                          ctrc: value,
                                                                          advanced: 1
                                                                      })}
                                                />
                                            }

                                            {/*Filtre par année, si la recherche avancée est effectuée sur le(s) date(s) Ouverture => masquer ce filtre*/}
                                            {filters.dateOuvertureMin == null && filters.dateOuvertureMax == null &&
                                                <SelectFieldComponent fieldName="annee"
                                                                      options={filtresParAnnees}
                                                                      errors={null}
                                                                      hideSearch={true}
                                                                      modificators="-on-white -ms-auto"
                                                                      label="Filtrer par année"
                                                                      oldValue={filters.annee ? filters.annee.toString() : ""}
                                                                      onChange={(value: string) => updateFilters({
                                                                          annee: value,
                                                                          advanced: 1
                                                                      })}
                                                />
                                            }
                                        </div>

                                        <SinistresAdvancedSearchComponent
                                            showSummary={true}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={onAdvancedSearch}
                                        />

                                        <table className={`list-table`}>
                                            <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "notairesAssignesOrdered", label: "Notaire(s) mis en cause"},
                                                    {slug: "dateOuverture", label: "Année d’ouverture"},
                                                    {slug: "libelle", label: "Libellé du dossier"},
                                                    {slug: "rapporteur", label: "Rapporteur"},
                                                    {slug: "ctrc", label: "CTRC"},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                sinistresList && sinistresList.map((sinistre: ISinistre, index: number) =>
                                                    <tr key={index}
                                                        onClick={() => history.push(`${AppConstants.pathSinistres}/${sinistre.id}`)}
                                                        className="tr">
                                                        <td className="td">
                                                            {sinistre.notairesAssignes ? SinistresHelper.getFormatedNotaires(sinistre.notairesAssignes) : ''}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : ''}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.libelle ?? ''}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.rapporteur ? SinistresHelper.getFormatedNotaires([sinistre.rapporteur]) : ''}
                                                        </td>
                                                        <td className="td">
                                                            {/*Liste des CTRCs rattachés au Sinistre*/}
                                                            {(sinistre.ctrcs.length > 0) &&
                                                                sinistre.ctrcs.map((ctrc, index) =>
                                                                    <Link
                                                                        key={index}
                                                                        className="g-link -primary -underline-on-hover -bold"
                                                                        target="_blank"
                                                                        to={`${AppConstants.pathCtrcs}/${ctrc.id}`}
                                                                        onClick={(e) => e.stopPropagation()}>
                                                                        {ctrc.nomComplet}&nbsp;
                                                                    </Link>,
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>


                                        <PaginationComponent links={paginationLinks}
                                                             onPageClick={page => updateFilters({page})}/>

                                        <AsideSinistresComponent type="list" exportCallback={() => setShowExport(true)}
                                                                 filters={filters}/>
                                    </div>
                                }
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            {activeTab === TabsSlugs.SINISTRES_LISTING &&
                <SinistresExportComponent setShowExport={() => setShowExport(false)} showExport={showExport}
                                          filters={filters}/>
            }
        </LayoutComponent>
    )
}
