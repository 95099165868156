import {IOption} from "../Components/Fields/Select.field.component";
import {ICourrierType} from "../Models/Courrier.model";

export default class CourriersHelper{

    /**
     * Permet de formater une liste des types de courrier pour les selects
     *
     * @param {ICourrierType[]} types
     * @returns {IOption[]}
     */
    static formatCourriersTypesForOptions(types: ICourrierType[]): IOption[]{
        if(!types || !types.length) return [];

        const options: IOption[] = [];
        for(let type of types){
            const option: IOption = {
                value: type.id.toString(),
                label: type.nom
            }

            options.push(option)
        }


        return options;
    }


    /**
     * Permet de formater une liste des années de courrier pour les selects
     *
     * @param {number[]} years
     * @returns {IOption[]}
     */
    static formatYearsForOptions(years: number[]): IOption[]{
        if(!years || !years.length) return [];

        const options: IOption[] = [];
        for(let year of years){
            const option: IOption = {
                value: year.toString(),
                label: year.toString(),
            }

            options.push(option)
        }


        return options;
    }


}
