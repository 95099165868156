import {Document, Page, Font, Text} from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import React, {useEffect, useState} from "react";
import {AppConstants} from "../../Constants/AppConstants";
import StringHelper from "../../Helpers/String.helper";

const FicheReactPdfComponent = () => {
    const [html, setHtml] = useState<string>("");

    useEffect(() => {
        let baseFontUrl = `${AppConstants.serverUrl}assets/pdf/fonts/`;

        Font.register({
            family: 'Bebas Neue',
            src: baseFontUrl + 'BebasNeue-Bold.ttf',
            fontWeight: "bold"
        });

        //Les fonts Montserrat ont été revu (pour permettre le copier/coller)
        //(Source : https://github.com/diegomura/react-pdf/issues/629)
        //fontforge -lang=ff -c 'Open($1); Generate($2); Close();' Montserrat-Italic.ttf Montserrat-Italic-new.ttf

        Font.register({
            family: 'Montserrat',
            src: baseFontUrl + 'Montserrat-Regular.ttf',
            fontWeight: "normal",
        });

        Font.register({
            family: 'Montserrat',
            src: baseFontUrl + 'Montserrat-Bold.ttf',
            fontWeight: "bold"
        });

        Font.register({
            family: 'Montserrat',
            src: baseFontUrl + 'Montserrat-Italic.ttf',
            fontWeight: "normal",
            fontStyle: "italic"
        });
        Font.registerHyphenationCallback(hyphenationCallback);

        let htmlContent = "";
        htmlContent += `<style>
* {
    font-family: "Montserrat", sans-serif;
}
h1.title {
    font-size: 16px;
    font-weight: bold;
    color: #171F46;
    width: 95%;
    margin-bottom: 20px;
} 
h1.title .text {
}
.accordion-wrapper {
    position: relative;
    margin-bottom: 20px;
}
.informations-header {
    display: block;
    margin-bottom: 10px;
}
.informations-header > .picto {
    width:15px;
    height: 15px;
    margin-right: 10px;
}
.informations-header > .title {
    font-family: "Bebas Neue";
    display: inline-block;
    font-size: 14px;
    color: #171F46;
    font-weight: bold;
}
.informations-wrapper > .columns {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
.informations-wrapper > .columns > .column {
    flex: 1 1 22%;
    max-width: calc(22% - 40px);
    margin-right: 40px;
}
.informations-notaire {
    margin-bottom: 20px;
}
.informations-wrapper.-notaires {
    margin-top: 15px;
}
.informations-wrapper>.columns>.column.-notaire {
    flex: 1 1 77px;
    max-width: 77px;
    margin-right: 5px;
    margin-bottom: 10px;
    justify-content: center;
}
.informations-wrapper > .columns > .title {
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
    font-family: "Bebas Neue";
    font-size: 12px;
    text-transform: uppercase;
    color: #DEB578;
    font-weight: bold;
}
.informations-block {
    margin-bottom: 10px;
    font-size: 9px;
}
.informations-block > .label {
    font-weight: bold;
    color: #171F46;
}
.informations-block > .value {
    margin: 0;
    font-weight: 500;
    color: #666666;
}
.informations-image {
    overflow: hidden;
}
.informations-image.-list-notaire {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 182px;
}
.informations-image>.image {
    width: 100px;
    height: 50px;
}
.informations-image.-notaire-photo>.image {
    width: 88px;
    height: 118px;
}
.informations-notaire>.content {
    padding:10px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}
.informations-notaire>.content>.name {
    font-size: 8px;
    font-weight: bold;
    color: #171f46;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 5px;
}
.informations-notaire>.content>.info {
    margin: 0;
    font-size: 6px;
    font-weight: 500;
    color: #272727;
}
.informations-chiffre {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: flex-start;
}
.informations-chiffre>.label, 
.informations-chiffre>.sublabel {
    font-size: 9px;
    color: #171f46;
}
.informations-chiffre>.sublabel {
    margin: 0 0 10px;
}
.informations-chiffre>.chiffre {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    color: #171f46;
}
.informations-chiffre>.label {
    margin: 0 0 5px;
    font-weight: bold;
}
.picto {
}
.picto.-big {
    width: 30px;
    height: 28px;
}
.informations-image.-list-notaire {
    overflow: hidden;
}
.informations-image.-list-notaire .image {
    display: block;
    width: 75px;/*dépend de la largeur du bloc : il y a 1 px de bordure de chaque côté*/
    height: 102px;
    margin: 0;
    padding: 0;
}
.list-aside,
.accordion-header .right,
.see-more-link{
    display: none;
    opacity: 0;
    height: 0;
    width: 0;
}
            </style>`;
        //Récupérer le titre et le contenu d'une fiche (HTML)
        htmlContent += document.querySelector('.page-header h1.title').outerHTML;
        htmlContent += document.querySelector('.fiche-content').outerHTML;

        const stringHelper = new StringHelper();
        htmlContent = stringHelper.cleanHtmlReactPdf(htmlContent);

        setHtml(htmlContent);
    });

    //Source
    //https://react-pdf.org/advanced#hyphenation
    const hyphenationCallback = (word: string) : string[] => {
        return [word];
    }

    return (
        <Document>
            <Page size="A4" orientation="portrait" style={{marginRight: "25px", marginLeft: "25px", marginTop: "10px"}} wrap={false}>
                <Html resetStyles style={{fontSize: 10 }}>
                    {html}
                </Html>
                <Text render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed style={{fontFamily: "Montserrat", fontSize: 8, textAlign: "right", marginBottom: 10, color: "#666", width: "95%"}} />
            </Page>
        </Document>
    );
};

export default FicheReactPdfComponent;
