import CommonHelper from "./Common.helper";
import {InspectionsConstants} from "../Constants/InspectionsConstants";
import {IInspecteur} from "../Models/Inspecteur.model";
import {IInspecteurOption} from "../Components/Fields/SelectMultiInspecteur.field";
import {INotaire} from "../Models/Notaire.model";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";
import {IInspection} from "../Models/Inspection.model";
import {IOption, IOptionsGroup} from "../Components/Fields/Select.field.component";

export default class InspectionsHelper{

    /**
     * Permet de récupérer le type de document formaté
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedDocumentTypes(type: string): string{
        return CommonHelper.getFormatedElementFromOptions(type, InspectionsConstants.documentsTypesOptions);
    }

    public static getInspecteurOption(inspecteur: IInspecteur): IInspecteurOption{
        const isStar: boolean = inspecteur.personneExterieure !== null;
        const isObservateur: boolean = inspecteur.isObservateur;

        return isStar ? this.getInspecteurOptionFromPersonneExterieure(inspecteur.personneExterieure) : this.getInspecteurOptionFromNotaire(inspecteur.notaire, isObservateur);
    }

    public static getInspecteurOptionFromNotaire(notaire: INotaire, isObservateur?: boolean): IInspecteurOption{
        let label = `${notaire.nom.toUpperCase()} ${notaire.prenom}`;
        return {
            type: isObservateur ? 'observateur' : 'inspecteur',
            value: notaire.id.toString(),
            label,
            disabled: notaire.alreadyInspecteurForYear
        };
    }
    public static getObservateurOptionFromNotaire(notaire: INotaire): IInspecteurOption{
        let label = `${notaire.nom.toUpperCase()} ${notaire.prenom}`;

        return {
            type: 'observateur',
            value: notaire.id.toString(),
            label,
            disabled: notaire.alreadyInspecteurForYear,
        };
    }

    public static getInspecteurOptionFromPersonneExterieure(personne: IPersonneExterieure): IInspecteurOption{
        let label = `${personne.nom.toUpperCase()} ${personne.prenom}`;

        return {
            type: 'inspecteurSpec',
            value: personne.id.toString(),
            label
        };
    }


    /**
     * Formattage des inspections pour une liste déroulante
     *
     * @param {IInspection[]} inspections
     * @returns {IOption[]}
     */
    public static formatListForOptions(inspections: IInspection[]): IOption[]{
        if(!inspections || !inspections.length) return [];

        const options: IOption[] = [];
        for(let i of inspections){
            const option: IOption = {
                value: i.id.toString(),
                label: i.office.nom,
            }

            options.push(option)
        }


        return options;
    }

    /**
     * Formattage des inspections pour une liste déroulante
     *
     * @param {IInspection[]} inspections
     * @returns {IOptionsGroup[]}
     */
    public static formatListForOptionsGroup(inspections: IInspection[]): IOptionsGroup[]{
        if(!inspections || !inspections.length) return [];

        const optionGroup: IOptionsGroup = {
            "label": "Tout sélectionner",
            "options": []
        }

        const options: IOption[] = [];
        for(let i of inspections){
            const option: IOption = {
                value: i.id.toString(),
                label: i.office.CRPCEN + " - " + i.office.nom,
            }

            options.push(option)
        }
        optionGroup.options = options


        return [optionGroup];
    }

    /**
     * Formattage des inspections pour une liste déroulante selon les année
     *
     * @param {IInspection[]} inspections
     * @returns {IOption[]}
     */
    public static formatYearListForOptions(inspections: IInspection[]): IOption[]{
        if(!inspections || !inspections.length) return [];

        const options: IOption[] = [];
        for(let i of inspections){
            const option: IOption = {
                value: i.annee.toString(),
                label: i.annee.toString(),
            }

            options.push(option)
        }


        return options;
    }
}
