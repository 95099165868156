import React, {useContext, useEffect, useState} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {UsersService} from "src/Services/Users.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {ReactComponent as Logo} from "src/assets/images/logo-notaire.svg";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";


type ForgotPasswordScreenProps = {};

const ForgotPasswordScreen = (props: ForgotPasswordScreenProps) => {
    const usersService = new UsersService(null);
    const {isAuthenticated} = useContext(UserContext) as IUserContext;

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const history = useHistory();

    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);


    useEffect( () => {
        const formElementsColumn1: FormElement[] = [
            {
                type: 'email',
                fieldName: "email",

                label: "Identifiant",
                labelModificators: "-white",
                placeholder: "Identifiant",
                required: true,
            },
            {
                type: 'link',
                path: '/connexion',
                modificators: "-right -white",
                label: "Me connecter",
            },
        ];
        const currentFormColumns: FormColumn[] = [{
            modificators: "-full",
            elements: formElementsColumn1
        }];
        setFormColumns(currentFormColumns);


        const currentFormActions: FormActions[] = [
            {
                label: "Réinitialiser le mot de passe",
                modificators: "-primary -secondary-on-tablet",
                onAction: onSubmit
            }
        ];
        setFormActions(currentFormActions);
    }, []);


    useEffect(()=>{
        if(isAuthenticated){
            // On redirige vers la home
            //history.push(AppConstants.pathHome);
        }
    },[isAuthenticated, history]);


    /**
     * Ecoute du click sur le submit du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        usersService.forgotPassword( formData.email).then((response: IApiCustomResponse) => {
            setShowConfirmation(true);
        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la demande de réinitialisation de votre mot de passe.",type: "danger"});
        });
    };



    return (
        <div className="page-wrapper -full-screen">
            <div className="content -bg-primary">
                <div className="login-wrapper">
                    <header className="login-header-wrapper">
                        <figure className="logo-wrapper">
                            <Logo className="logo" />
                        </figure>

                        <h1 className="g-title -center -white">Mot de passe oublié</h1>

                        {
                            showConfirmation &&
                            <>
                                <p className="intro">
                                    Si cette adresse est associée à un espace personnel, vous recevrez un email pour renouveler votre mot de passe.
                                </p>

                                <Link to={'/connexion'} className="g-link -white -margin-top -center">Se connecter</Link>
                            </>
                        }
                    </header>

                    {
                        !showConfirmation &&
                            <FormCreatorComponent
                                formColumns={formColumns}
                                formActions={formActions}
                                errorFieldsMessages={errorFieldsMessages}
                                errorMessages={errorMessages}

                                modificatorsActions="-outside-center"
                            />
                    }
                </div>
            </div>
        </div>
    )

}

export default ForgotPasswordScreen;
