import React from "react";
import {IFilInformations} from "src/Models/FilInformations.model";
import FileHelper from "src/Helpers/File.helper";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";


const FilInformationsUne = (props: {
    filInformations: IFilInformations
}) => {

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column">
                <div className="informations-block">
                    <p className="label">Titre</p>
                    <p className="value">{props.filInformations.uneTitre || '-'}</p>
                </div>

                <div className="informations-block">
                    <p className="label">Nom</p>
                    <p className="value">{props.filInformations.uneNom || '-'}</p>
                </div>
            </div>
            <div className="column">
                <div className="informations-block">
                    <p className="label">Sous-titre</p>
                    <p className="value">{props.filInformations.uneSousTitre || '-'}</p>
                </div>

                <div className="informations-block">
                    <p className="label">Fonction</p>
                    <p className="value">{props.filInformations.uneFonction || '-'}</p>
                </div>
            </div>
            <div className="column ">
                <div className="informations-block">
                    <p className="label">Image</p>
                    {
                        props.filInformations.uneImage &&
                            <figure className="informations-image -resume">
                                <img src={FileHelper.getImageUrlFromSize(props.filInformations.uneImage, "filInfosUne")} alt="" className="image"/>
                            </figure>
                    }
                    {
                        !props.filInformations.uneImage &&
                        <div className="value">
                            -
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="columns">
            <div className="column -size-2">
                <div className="informations-block">
                    <p className="label">Texte</p>
                    <div className="value">
                        {
                            props.filInformations.uneTexte &&
                            <CommentairesComponent commentaires={props.filInformations.uneTexte}/>
                        }
                        {
                            !props.filInformations.uneTexte &&
                            <>-</>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

};

export default FilInformationsUne;
