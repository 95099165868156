import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type SinistresExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,

}
export default function CtrcsExportComponent(props: SinistresExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"CTRC"}
                         icon="icon-ctrcs"
             subTitle={"Export des CTRC"}
             onClose={() => props.setShowExport()}
             modificators={`${!props.showExport ? '-hide' : ''}`}
             exportUrl= {`api${AppConstants.pathCtrcs}/export`}
             filters={props.filters || null}

             columns={[
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-informations',
                             fieldName: "informations",
                             label: "Informations",
                             options: [
                                 //{value: 'id', label: 'Identifiant'},
                                 {value: 'nom', label: 'Nom'},
                                 {value: 'date', label: 'Date'},
                                 {value: 'notaires.president', label: 'Président'},
                                 {value: 'notaires.membres', label: 'Membres'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-sinistres',
                             fieldName: "sinistres",
                             label: "Dossiers/Sinistres",
                             options: [
                                 {value: 'sinistres', label: 'Dossiers de sinistre'},
                             ]
                         },
                     ]
                 },
             ]}
        />
    )
}
