import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {IOrganisme} from "src/Models/Organisme.model";
import {AutresContactsService, IAutresContactsGetFormData, IOrganismesWithPaginationGetApiResponse} from "src/Services/AutresContacts.service";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import MapHelper from "src/Helpers/Map.helper";
import AsideOrganismeComponent from "src/Components/Aside/AsideOrganisme.component";
import OrganismesAdvancedSearchComponent from "src/Components/Annuaire/Autres-contacts/Organismes/OrganismesAdvancedSearch.component";
import OrganismesExportComponent from "src/Components/Annuaire/Autres-contacts/Organismes/OrganismesExport.component";
import LoadingComponent from "../../../../Components/Loading/Loading.component";


type OrganismesListScreenProps = {}

export default function OrganismesListScreen(props: OrganismesListScreenProps) {
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathOrganismes);
    const [organismes, setOrganismes] = useState<IOrganisme[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const location = useLocation();
    const history = useHistory();
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(initialFilters, false, true);
    }, []);


    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters,...formData},true);
        setShowAdvancedSearch(false);
    };


    /**
     * Récupération des offices
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        //Gestion de la tab
        filters.tab = activeTab;

        const autresContactsService: AutresContactsService = new AutresContactsService();
        autresContactsService.getOrganismesWithPagination(filters as IAutresContactsGetFormData).then((response: IOrganismesWithPaginationGetApiResponse) => {
            if (response && response.datas.pagination) {
                const currentOrganismesList = response.datas.pagination.data;
                setOrganismes(currentOrganismesList);

                setPaginationLinks(response.datas.pagination.links);

                // Markers
                const markers: MapMarker[] = [];
                currentOrganismesList.forEach((ac) => {
                    if (ac.adressePrincipale && ac.adressePrincipale.latitude) {
                        const adresse = ac.adressePrincipale;

                        let markerText: string = "";
                        markerText = MapHelper.createMapMarkerTextOrganisme(`${AppConstants.pathOrganismes}/${ac.id}`,ac.nom, adresse, ac.personnesExterieures);

                        const marker: MapMarker = MapHelper.createMapMarker(markerText, adresse);
                        markers.push(marker);
                    }
                });
                setMarkers(markers);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };

    return <>
        <LayoutComponent showMenu={true} contentModificators="" wrapperModificators="">
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent breadcrumbLinks={[{text: "Annuaire", link: AppConstants.pathAnnuaire}, {text: "Organismes"}]}
                                     text="Organismes"
                                     icon="icon-organismes"
                                     onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                                     onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent defaultTab={TabsSlugs.LIST}  onClickTabCallback={(tab)=>{updateFilters({},false); setActiveTab(tab)}} >
                            <TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.LIST}>
                                <div className="list-content -right-gutter">
                                    <OrganismesAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={ onAdvancedSearch}
                                    />


                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "nom", label: "Nom"},
                                                {slug: "ville", label: "Ville"},
                                                {slug: "nbContacts", label: "Nb Contacts"}
                                            ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    label={item.label} click={(value: string) => setOrder(value)}/>
                                            </th>)}
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            organismes && organismes.map((organisme: IOrganisme, index: number) =>
                                                <tr key={index} onClick={() => history.push(`${AppConstants.pathOrganismes}/${organisme.id}`)} className="tr">
                                                    <td className="td -bold">
                                                        {organisme.nom}
                                                    </td>
                                                    <td className="td -uppercase">{organisme?.adressePrincipale?.ville}</td>

                                                    <td className="td">
                                                        {organisme.nbContacts}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>

                                    <AsideOrganismeComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>


                            <TabPanelComponent label="Carte" icon="icon-map" slug={TabsSlugs.MAP}>
                                <div className={`list-content -no-gutter`}>
                                    <OrganismesAdvancedSearchComponent
                                        showSummary={false}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={ onAdvancedSearch}
                                    />

                                    <div className="map-wrapper">
                                        <MapComponent markers={markers} key={Math.random()}/>
                                    </div>

                                    <AsideOrganismeComponent type="list" modificators="-on-map" exportCallback={() => setShowExport(true)}  filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>

        <OrganismesExportComponent filters={filters} setShowExport={() => setShowExport(false)} showExport={showExport} />
    </>
}
