import React, {useState} from "react";
import {useHistory} from "react-router";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {
    FilInformationsService, IFilInformationsAbonneFormData,
} from "src/Services/FilInformations.service";
import FilInformationsAbonneFormComponent
    from "../../Components/Forms/FilInformations/FilInformationsAbonneForm.component";

type IFilInformationsInscriptionExternalStepsScreenProps = {}


const FilInformationsInscriptionExternalScreen = (props:IFilInformationsInscriptionExternalStepsScreenProps) => {
    const filInformationsService: FilInformationsService = new FilInformationsService();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const history = useHistory();


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (): void => {
        setSubmitted(true);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Abonnement au fil d'informations" icon="icon-add" modificators={`-in-fullscreen -border-full-screen`}/>
            {
                submitted &&
                <p className="informations-header -big ">
                    <span className="title">
                        Merci, votre inscription a bien été prise en compte.
                    </span>
                </p>
            }
            {
                !submitted &&
				<>
					<InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />
					<FilInformationsAbonneFormComponent submitMethod={(formData: IFilInformationsAbonneFormData)=>filInformationsService.abonneExternal(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={()=>null} />
				</>
            }
        </LayoutComponent>
    )
}

export default FilInformationsInscriptionExternalScreen;
