import {useEffect, useState} from "react";

type IYearsListComponentProps = {
    years: number[],
    setCurrentYear: (year: number) => void,
    currentYear: number,
}
export default function YearsListComponent(props: IYearsListComponentProps){
    const [years, setYears] = useState<number[]>(null);


    useEffect(() => {
        setYears(props.years);
    }, [props.years])


    return (
        <div className="years-list-wrapper">
            {
                years && years.map( (year: number) =>
                    <span key={year} className={`year ${props.currentYear == year ? '-active' : ''}`} onClick={()=>props.setCurrentYear(year)}>{year}</span>
                )
            }
        </div>
    );
}
