import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import moment, {Moment} from "moment";
import NumberHelper from "src/Helpers/Number.helper";

type ICotisationsOfficeRecapTotalComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapTotalComponent(props: ICotisationsOfficeRecapTotalComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;
    const currentDate:Moment = moment();
    const currentYear: number = props.cotisation.annee ?? parseInt(currentDate.format("YYYY"));
    const previousYear: number = currentYear - 1;
    const nextYear: number = currentYear + 1;


    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant {previousYear}</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationsMontantNmoins1, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Hors Frais et Répertoire {previousYear}</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationsMontantHorsFraisNmoins1, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Acompte pour {currentYear} : montant Hors Frais et Répertoire</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationsAccompteN, 0,0)} €</p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant {currentYear}</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationsMontant, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Hors frais et Répertoire</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationsHorsFrais, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Acompte pour {nextYear}</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationAccompteNplus1, 0,0)} €</p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Total accomptes versés</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationTotalAcomptesVerses, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Solde</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.cotisationSolde, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
