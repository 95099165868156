import React, {useEffect, useState} from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import {FilInformationsService, IFilInformationsGetFormData} from "../../Services/FilInformations.service";
import useListFilters, {IFilterData} from "../../Hooks/FiltersHandler";
import {AppConstants} from "../../Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import DateHelper from "../../Helpers/Date.helper";

const SauvegardesListComponent = (props:{}) => {
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathFilInformations);

    const filInfosService: FilInformationsService = new FilInformationsService();
    const [fils, setFils] = useState<IFilInformations[]>([]);
    const location = useLocation();
    const history = useHistory();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        defaultFilters.page = 1;
        updateFilters(defaultFilters);
    }, []);

    useEffect(()=>{
        if(!filters) return null;
        filInfosService.getFilInformations({...filters, isArchive: 0} as IFilInformationsGetFormData).then((response)=>{
            if (response && response.datas.pagination) {
                setFils(response.datas.pagination.data);
            }
        });
    },[filters]);


    return <>
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                <th className="th"><span className="link">Num</span></th>
                <th className="th"><span className="link">Titre</span></th>
                <th className="th"><span className="link">Date Dernière sauvegarde</span></th>
                <th className="th"><span className="link">Date d'envoi</span></th>
            </tr>
            </thead>
            <tbody>
            {fils.map((f,index)=><tr onClick={()=>history.push(`${AppConstants.pathFilInformations}/${f.id}`)}  key={index} className="tr">
                <td className="td">{f.numero}</td>
                <td className="td">{f.titre}</td>
                <td className="td">{DateHelper.getFormatedDate(f.dateModification)}</td>
                <td className="td">{DateHelper.getFormatedDate(f.dateEnvoi)}</td>
            </tr>)}
            </tbody>
        </table>
    </>;
};

export default SauvegardesListComponent;
