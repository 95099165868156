import {IOption} from "../Components/Fields/Select.field.component";
import {ICertification} from "../Models/Certification.model";

export default class CertificationsHelper{


    /**
     * Permet de formater une liste de certifications pour les selects
     *
     * @param {ICertification[]} certifications
     * @returns {IOption[]}
     */
    static formatListForOptions(certifications: ICertification[]): IOption[]{
        if(!certifications || !certifications.length) return [];

        const options: IOption[] = [];
        for(let office of certifications){
            const option: IOption = {
                value: office.id.toString(),
                label: office.nom
            }

            options.push(option)
        }

        return options;
    }
}
