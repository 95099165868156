import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {CorrespondancesService, ICorrespondanceConfirmationFormData} from "src/Services/Correspondances.service";
import useCorrespondanceParticipant from "src/Hooks/UseCorrespondanceParticipant";
import {ICorrespondance} from "src/Models/Correspondance.model";
import FormCreatorComponent, {FormColumn, FormElement,} from "../../../Components/Forms/FormCreator.component";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import CalendarComponent from "../../../Components/Calendar/Calendar.component";
import {ICorrespondanceParticipant} from "../../../Models/CorrespondanceParticipant.model";
import {CorrespondanceConstants} from "../../../Constants/CorrespondancesConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import DateHelper from "../../../Helpers/Date.helper";
import useCorrespondance from "../../../Hooks/UseCorrespondance";


type ICourrierInscriptionExternalPreviewScreenProps = {
    cid: number,
}
export default function CorrespondanceInscriptionExternalPreviewScreen(props: ICourrierInscriptionExternalPreviewScreenProps) {
    const [getCorrespondanceById] = useCorrespondance();
    const [correspondance, setCorrespondance] = useState<ICorrespondance>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if (!props.cid) {
            history.push(AppConstants.path403);
        }

        getCorrespondance();

    }, [props.cid]);


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getCorrespondance = (): void => {
        getCorrespondanceById(props.cid, (c: ICorrespondance) => {
            setCorrespondance(c);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    return (
        <>
            {
                loaded &&
				<LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
					<PageHeaderComponent text="Présence" subText={"Aperçu de la page de réponse"} icon="icon-correspondances" modificators={`-in-fullscreen -border-full-screen`}/>

                    <div className="informations-header">
                        <h3 className="title">{correspondance.titre}</h3>
                    </div>


                    <div className="informations-wrapper correspondances-wrapper">
                        <div className="columns">
                            <div className="column -auto">
                                <CalendarComponent date1={correspondance.date} date2={null}/>
                            </div>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Horaire</p>
                                    <p className="value">
                                        {correspondance.date ? DateHelper.getFormatedHour(correspondance.date) : ""}
                                    </p>
                                </div>

                                <div className="informations-block">
                                    <p className="label">Type</p>
                                    <p className="value mb-2">
                                        {CorrespondanceConstants.types[correspondance.type]}
                                    </p>
                                </div>
                                {
                                    correspondance.lieu &&
                                    <div className="informations-block">
                                        <p className="label">Lieu</p>
                                        <p className="value">{correspondance.lieu}</p>
                                    </div>
                                }
                            </div>

                            <div>
                                {
                                    correspondance.repas && correspondance.repas === "oui" &&
                                    <div className="informations-block">
                                        <p className="label">Repas</p>
                                        <p className="value">
                                            {
                                                correspondance.repasNom &&
                                                <>Nom: <strong>{correspondance.repasNom}</strong><br/></>
                                            }
                                            {
                                                correspondance.repasHeure &&
                                                <>Heure: <strong>{correspondance.repasHeure}</strong><br/></>
                                            }
                                            {
                                                correspondance.repasLieu &&
                                                <>Lieu: <strong>{correspondance.repasLieu}</strong><br/></>
                                            }
                                            {
                                                !correspondance.repasNom && !correspondance.repasHeure && !correspondance.repasLieu &&
                                                <>Oui</>
                                            }
                                        </p>
                                    </div>
                                }
                                {
                                    correspondance.cocktail && correspondance.cocktail === "oui" &&
                                    <div className="informations-block">
                                        <p className="label">Cocktail</p>
                                        <p className="value">
                                            {
                                                correspondance.cocktailNom &&
                                                <>Nom: <strong>{correspondance.cocktailNom}</strong><br/></>
                                            }
                                            {
                                                correspondance.cocktailHeure &&
                                                <>Heure: <strong>{correspondance.cocktailHeure}</strong><br/></>
                                            }
                                            {
                                                correspondance.cocktailLieu &&
                                                <>Lieu: <strong>{correspondance.cocktailLieu}</strong><br/></>
                                            }
                                            {
                                                !correspondance.cocktailNom && !correspondance.cocktailHeure && !correspondance.cocktailLieu &&
                                                <>Oui</>
                                            }
                                        </p>
                                    </div>
                                }
                                {
                                    correspondance.hebergement && correspondance.hebergement === "oui" &&
                                    <div className="informations-block">
                                        <p className="label">Hébergement</p>
                                        <p className="value">
                                            {
                                                correspondance.hebergementNom &&
                                                <>Nom: <strong>{correspondance.hebergementNom}</strong><br/></>
                                            }
                                            {
                                                correspondance.hebergementLieu &&
                                                <>Lieu: <strong>{correspondance.hebergementLieu}</strong><br/></>
                                            }
                                            {
                                                !correspondance.hebergementLieu && !correspondance.hebergementNom &&
                                                <>Oui</>
                                            }
                                        </p>
                                    </div>
                                }
                                {
                                    correspondance.transport && correspondance.transport === "oui" &&
                                    <div className="informations-block">
                                        <p className="label">Transport</p>
                                        <p className="value">
                                            {
                                                correspondance.transportDetail &&
                                                <>Détail: <strong>{correspondance.transportDetail}</strong><br/></>
                                            }
                                        </p>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

				</LayoutComponent>
            }
        </>
    )
}
