import React from "react";
import {Route, RouteProps} from "react-router";


type IExternalRouteProps = RouteProps & {
}

const ExternalRoute = (props: IExternalRouteProps) => {
    // On récupère uniquement les propriétés de props qu'on souhaite. Le reste est stocké dans rest et est envoyé tel quel au composant route
    // component: Component ici n'est pas un typage mais une assignation de la propriété component à la variable "Component"
    const {component: Component, ...rest} = props;


    // Droits suffisants on retourne la route
    return <Route {...rest} render={(props) => <Component {...props} />}/>;
};

export default ExternalRoute;
