import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants, ICreationSteps} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {
    IOfficeAnnexesFormData,
    IOfficeCertificationsLabelsHorairesFormData,
    IOfficeChiffresFormData,
    IOfficeCommentairesFormData, IOfficeDocumentsVefaFormData,
    IOfficeInformationFormData,
    IOfficeInformationsComplementairesFormData,
    IOfficeNegociateursFormData,
    IOfficeRecusLivraisonFormData,
    IOfficeRepertoiresFormData,
    IOfficeSitesGroupesLogicielsFormData,
    OfficesService
} from "src/Services/Offices.service";
import {IOffice} from "src/Models/Office.model";
import useOffice from "src/Hooks/UseOffice";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import OfficeIdentiteComponent from "src/Components/Annuaire/Offices/OfficeIdentite.component";
import OfficeInformationsFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeInformationsForm.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import {IAdresse} from "src/Models/Adresse.model";
import MapHelper from "src/Helpers/Map.helper";
import AsideOfficeComponent from "src/Components/Aside/AsideOffice.component";
import {CommonsService, IAdressesFormData, ICoordonneesFormData} from "src/Services/Commons.service";
import AdressesFormComponent from "src/Components/Forms/Commons/AdressesForm.component";
import AdressesInfosComponent from "src/Components/Annuaire/Commons/Adresses/AdressesInfos.component";
import CoordonneesFormComponent from "src/Components/Forms/Commons/CoordonneesForm.component";
import CoordonneesInfosComponent from "src/Components/Annuaire/Commons/Coordonnees/CoordonneesInfos.component";
import OfficeNegociateursFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeNegociateursForm.component";
import OfficeNegociateursInfosComponent from "src/Components/Annuaire/Offices/OfficeNegociateursInfos.component";
import OfficeRepertoiresFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeRepertoiresForm.component";
import OfficeRepertoiresInfosComponent from "src/Components/Annuaire/Offices/OfficeRepertoiresInfos.component";
import OfficeRecusLivraisonFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeRecusLivraisonForm.component";
import OfficeRecusLivraisonInfosComponent from "src/Components/Annuaire/Offices/OfficeRecusLivraisonInfos.component";
import CommentairesComponent from "src/Components/Annuaire/Commons/Commentaires/Commentaires.component";
import OfficeCommentairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeCommentairesForm.component";
import OfficeSiteGroupesLogicielsInfosComponent from "src/Components/Annuaire/Offices/OfficeSiteGroupesLogicielsInfos.component";
import OfficeSitesGroupesLogicielsFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeSitesGroupesLogicielsForm.component";
import OfficeCertificationsLabelsHorairesInfosComponent from "src/Components/Annuaire/Offices/OfficeCertificationsLabelsHorairesInfos.component";
import OfficeInformationsComplementairesComponent from "src/Components/Annuaire/Offices/OfficeInformationsComplementaires.component";
import OfficeDonneesChiffreesComponent from "src/Components/Annuaire/Offices/OfficeDonneesChiffrees.component";
import OfficeInformationsComplementairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeInformationsComplementairesForm.component";
import OfficeChiffresFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeChiffresForm.component";
import OfficeCertificationsLabelsHorairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeCertificationsLabelsHorairesForm.component";
import OfficeAnnexesComponent from "src/Components/Annuaire/Offices/OfficeAnnexes.component";
import OfficeAnnexesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeAnnexesForm.component";
import OfficeNotairesComponent from "src/Components/Annuaire/Offices/OfficeNotaires.component";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import OfficePredecesseursComponent from "src/Components/Annuaire/Offices/OfficePredecesseurs.component";
import OfficeDocuments from "src/Components/Annuaire/Offices/OfficeDocuments.component";
import OfficeDocumentsVefaForm from "src/Components/Forms/Annuaire/Offices/OfficeDocumentsVefaForm.component";


type OfficeFicheScreenProps = {
    oid: string
}


export default function OfficeFicheScreen(props: OfficeFicheScreenProps){
    const identiteAccordionRef = useRef(null);
    const adressesAccordionRef = useRef(null);
    const coordonneesAccordionRef = useRef(null);
    const negociateursAccordionRef = useRef(null);
    const repertoiresAccordionRef = useRef(null);
    const recuLivraisonAccordionRef = useRef(null);
    const commentaireAccordionRef = useRef(null);
    const siteGroupeLogicielsAccordionRef = useRef(null);
    const chiffresAccordionRef = useRef(null);
    const informationsComplementairesAccordionRef = useRef(null);
    const certificationsAccordionRef = useRef(null);
    const annexesAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);

    const [office, setOffice] = useState<IOffice>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [creationSteps, setCreationSteps] = useState<ICreationSteps[]>([]);
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const officesService: OfficesService = new OfficesService();
    const commonsService: CommonsService = new CommonsService();
    const history = useHistory();
    const [getOfficeById] = useOffice();
    const [formOpened, setFormOpened] = useState<boolean>(false);



    useEffect(() => {
        if(!office) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Offices", link: AppConstants.pathOffices},
            {text: office.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

        setCreationSteps( OfficesConstants.creationSteps);


    }, [office, loaded])



    useEffect(() => {
        if( !props.oid ){
            history.push(AppConstants.pathOffices);
        }

        getOffice();
    }, [props.oid])


    /**
     * Récupération de l'office selon l'oid
     */
    const getOffice = (): void => {
        getOfficeById(props.oid, (office: IOffice) => {
            setOffice(office);

            if(office.type === "annexe"){
                history.push(AppConstants.pathAnnexes + '/' + office.id);
            }

            const markers: MapMarker[] = [];
            if (office.adressePrincipale && office.adressePrincipale.latitude) {
                const adressePrincipale: IAdresse = office.adressePrincipale;
                const markerText: string = MapHelper.createMapMarkerText(`${AppConstants.pathOffices}/${office.id}`, office.nom, adressePrincipale);
                const marker: MapMarker = MapHelper.createMapMarker(markerText, adressePrincipale);
                markers.push(marker);
            }
            setMarkers(markers);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getOffice();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     */
    const closeForms = (isCancel?: boolean): void => {
        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm(isCancel);
        }
        if((adressesAccordionRef && adressesAccordionRef.current) ){
            adressesAccordionRef.current.hideForm(isCancel);
        }
        if((coordonneesAccordionRef && coordonneesAccordionRef.current) ){
            coordonneesAccordionRef.current.hideForm(isCancel);
        }
        if((negociateursAccordionRef && negociateursAccordionRef.current) ){
            negociateursAccordionRef.current.hideForm(isCancel);
        }
        if((repertoiresAccordionRef && repertoiresAccordionRef.current) ){
            repertoiresAccordionRef.current.hideForm(isCancel);
        }
        if((recuLivraisonAccordionRef && recuLivraisonAccordionRef.current) ){
            recuLivraisonAccordionRef.current.hideForm(isCancel);
        }
        if((commentaireAccordionRef && commentaireAccordionRef.current) ){
            commentaireAccordionRef.current.hideForm(isCancel);
        }
        if((siteGroupeLogicielsAccordionRef && siteGroupeLogicielsAccordionRef.current) ){
            siteGroupeLogicielsAccordionRef.current.hideForm(isCancel);
        }
        if((chiffresAccordionRef && chiffresAccordionRef.current) ){
            chiffresAccordionRef.current.hideForm(isCancel);
        }
        if((informationsComplementairesAccordionRef && informationsComplementairesAccordionRef.current) ){
            informationsComplementairesAccordionRef.current.hideForm(isCancel);
        }
        if((certificationsAccordionRef && certificationsAccordionRef.current) ){
            certificationsAccordionRef.current.hideForm(isCancel);
        }
        if((annexesAccordionRef && annexesAccordionRef.current) ){
            annexesAccordionRef.current.hideForm(isCancel);
        }
        if((documentsAccordionRef && documentsAccordionRef.current) ){
            documentsAccordionRef.current.hideForm(isCancel);
        }
    }


    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={office.nom} icon="icon-office" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={identiteAccordionRef}
                                    title="Identité"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeInformationsFormComponent office={office} submitMethod={(formData: IOfficeInformationFormData)=>officesService.updateInformations(office.id, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<OfficeIdentiteComponent office={office} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={adressesAccordionRef}
                                    title="Adresse"
                                    icon="icon-coordonnees"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<AdressesFormComponent adresses={office.adresses}
                                                                 relationId={office.id} relationName="office"
                                                                 submitMethod={(formData: IAdressesFormData) => commonsService.syncAdresses(formData)}
                                                                 onSuccessSubmit={onSuccessSubmitForm}
                                                                 onCancel={onCancelForm}/>}
                                    infos={<AdressesInfosComponent adresses={office.adresses || null} isForOffice={true}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={coordonneesAccordionRef}
                                    title="Coordonnées"
                                    icon="icon-coordonnees-adresses"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<CoordonneesFormComponent coordonnees={office.coordonnees}
                                                                    relationId={office.id} relationName="office"
                                                                    submitMethod={(formData: ICoordonneesFormData) => commonsService.syncCoordonnees(formData)}
                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                    onCancel={onCancelForm}/>}
                                    infos={<CoordonneesInfosComponent coordonnees={office.coordonnees || null} relationName="office"/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={annexesAccordionRef}
                                    title="Annexes"
                                    icon="icon-office"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeAnnexesFormComponent office={office}
                                                                      submitMethod={(formData: IOfficeAnnexesFormData) => officesService.syncAnnexes(office.id, formData)}
                                                                      onSuccessSubmit={onSuccessSubmitForm}
                                                                      onCancel={onCancelForm}/>}
                                    infos={<OfficeAnnexesComponent annexes={ office.annexes || null}/>}
                                />

                                {
                                    office.notaires && office.notaires.length > 0 &&
                                    <AccordionSimpleComponent title="Notaires" icon="icon-notaires" contentShowedOnInit={true}>
                                        <>
                                            <OfficeNotairesComponent notaires={office.notaires} />
                                        </>
                                    </AccordionSimpleComponent>
                                }

                                <AccordionWithFormSwitchComponent
                                    ref={chiffresAccordionRef}
                                    title="Données Chiffrées"
                                    icon="icon-chiffre"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeChiffresFormComponent office={office}
                                                                       submitMethod={(formData: IOfficeChiffresFormData) => officesService.updateChiffres(office.id, formData)}
                                                                       onSuccessSubmit={onSuccessSubmitForm}
                                                                       onCancel={onCancelForm}/>}
                                    infos={<OfficeDonneesChiffreesComponent office={office}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={informationsComplementairesAccordionRef}
                                    title="Informations complémentaires"
                                    icon="icon-informations"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeInformationsComplementairesFormComponent office={office}
                                                                                          submitMethod={(formData: IOfficeInformationsComplementairesFormData) => officesService.updateInformationsComplementairess(office.id, formData)}
                                                                                          onSuccessSubmit={onSuccessSubmitForm}
                                                                                          onCancel={onCancelForm}/>}
                                    infos={<OfficeInformationsComplementairesComponent office={office}/>}
                                />


                                <AccordionSimpleComponent
                                    title="Prédécesseurs"
                                    icon="icon-predecesseur"
                                    contentShowedOnInit={true}
                                    children={<OfficePredecesseursComponent predecesseurs={office.predecesseurs}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={certificationsAccordionRef}
                                    title="Certifications et Labels"
                                    icon="icon-certifications"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeCertificationsLabelsHorairesFormComponent office={office}
                                                                                           submitMethod={(formData: IOfficeCertificationsLabelsHorairesFormData) => officesService.updateCertificationsLabelsHoraires(office.id, formData)}
                                                                                           onSuccessSubmit={onSuccessSubmitForm}
                                                                                           onCancel={onCancelForm}/>}
                                    infos={<OfficeCertificationsLabelsHorairesInfosComponent office={office}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={siteGroupeLogicielsAccordionRef}
                                    title="Groupes et Logiciels"
                                    icon="icon-logiciels"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeSitesGroupesLogicielsFormComponent office={office}
                                                                                    submitMethod={(formData: IOfficeSitesGroupesLogicielsFormData) => officesService.updateSitesGroupesLogiciels(office.id, formData)}
                                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                                    onCancel={onCancelForm}/>}
                                    infos={<OfficeSiteGroupesLogicielsInfosComponent office={office}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={negociateursAccordionRef}
                                    title="Négociateurs"
                                    icon="icon-negociateurs"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeNegociateursFormComponent office={office}
                                                                           submitMethod={(formData: IOfficeNegociateursFormData) => officesService.syncNegociateurs(formData)}
                                                                           onSuccessSubmit={onSuccessSubmitForm}
                                                                           onCancel={onCancelForm}/>}
                                    infos={<OfficeNegociateursInfosComponent negociateurs={office.negociateurs}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={repertoiresAccordionRef}
                                    title="Répertoires"
                                    icon="icon-repertoire"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeRepertoiresFormComponent office={office}
                                                                          submitMethod={(formData: IOfficeRepertoiresFormData) => officesService.syncRepertoires(formData)}
                                                                          onSuccessSubmit={onSuccessSubmitForm}
                                                                          onCancel={onCancelForm}/>}
                                    infos={<OfficeRepertoiresInfosComponent repertoires={office.repertoires}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={recuLivraisonAccordionRef}
                                    title="Reçus livraison"
                                    icon="icon-recus-livraison"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeRecusLivraisonFormComponent office={office}
                                                                             submitMethod={(formData: IOfficeRecusLivraisonFormData) => officesService.syncRecusLivraisons(formData)}
                                                                             onSuccessSubmit={onSuccessSubmitForm}
                                                                             onCancel={onCancelForm}/>}
                                    infos={<OfficeRecusLivraisonInfosComponent recusLivraison={office.recusLivraison}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={commentaireAccordionRef}
                                    title="Commentaires"
                                    icon="icon-commentaires"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeCommentairesFormComponent office={office} submitMethod={(formData:IOfficeCommentairesFormData)=>officesService.updateCommentaire(office.id, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<CommentairesComponent commentaires={office.commentaires} />}
                                />
                                
                                <AsideOfficeComponent type="fiche" office={office}  isPrincipales={true} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Carte" icon="icon-map">
                            <div className="fiche-content -with-map border-footer">
                                <div className="map-wrapper">
                                    <MapComponent markers={markers} key={Math.random()}/>
                                </div>

                                <AsideOfficeComponent type="ficheCarte" modificators="-on-map" office={office} isPrincipales={true} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Vefa" icon="icon-vefa">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeDocumentsVefaForm office={office} submitMethod={(formData: IOfficeDocumentsVefaFormData)=>officesService.updateDocumentsVefa(office.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<OfficeDocuments documents={office.documentsVefa}  />}
                                />

                                <AsideOfficeComponent type="ficheVefa" office={office} isPrincipales={true} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>

                </LayoutComponent>
            }
        </>
    )
}
