import React, {RefObject, useEffect, useRef, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import {
    IFilInformationsArretDeliberationFormData,
    IFilInformationsImagesFormData, IFilInformationsPrestationsFormData, IFilInformationsVieCompagnieFormData
} from "src/Services/FilInformations.service";
import {AppConstants} from "src/Constants/AppConstants";
import UseFilInformations from "src/Hooks/UseFilInformations";
import {useHistory} from "react-router";
import {IFilInformations} from "src/Models/FilInformations.model";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import FilInformationsInfosFormComponent from "src/Components/Forms/FilInformations/FilInformationsInfosForm.component";
import {FilInformationsService, IFilInformationsInfosFormData} from "src/Services/FilInformations.service";
import {IApiCustomResponse, IFileDownloadApiResponse} from "src/Services/Api.service";
import AsideFilInformationsComponent from "src/Components/Aside/AsideFilInformations.component";
import FilInformationsInfos from "src/Components/FilInformations/FilInformationsInfos.component";
import FilInformationsUneFormComponent from "src/Components/Forms/FilInformations/FilInformationsUneForm.component";
import FilInformationsUne from "src/Components/FilInformations/FilInformationsUne.component";
import FilInformationsActusFormComponent from "src/Components/Forms/FilInformations/FilInformationsActusForm.component";
import FilInformationsActualites from "src/Components/FilInformations/FilInformationsActualites.component";
import FilInformationsCVs from "src/Components/FilInformations/FilInformationsCVs.component";
import FilInformationsCvsFormComponent from "src/Components/Forms/FilInformations/FilInformationsCvsForm.component";
import FilInformationsReal from "src/Components/FilInformations/FilInformationsReal.component";
import FilInformationsRealFormComponent from "src/Components/Forms/FilInformations/FilInformationsRealForm.component";
import FilInformationsPresseFormComponent
    from "src/Components/Forms/FilInformations/FilInformationsPresseForm.component";
import FilInformationsPresse from "src/Components/FilInformations/FilInformationsPresse.component";
import FilInformationsFormationsFormComponent
    from "src/Components/Forms/FilInformations/FilInformationsFormationsForm.component";
import FilInformationsFormations from "src/Components/FilInformations/FilInformationsFormations.component";
import FilInformationsPrestationsFormComponent
    from "src/Components/Forms/FilInformations/FilInformationsPrestationsForm.component";
import FilInformationsPrestations from "src/Components/FilInformations/FilInformationsPrestations.component";
import FilInformationsAgenda from "src/Components/FilInformations/FilInformationsAgenda.component";
import FilInformationsAgendaFormComponent
    from "src/Components/Forms/FilInformations/FilInformationsAgendaForm.component";
import CommentairesComponent from "src/Components/Annuaire/Commons/Commentaires/Commentaires.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import UseFileDownload from "src/Hooks/UseFileDownload";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import FilInformationsArretDeliberationFormComponent
    from "../../Components/Forms/FilInformations/FilInformationsArretDeliberationForm.component";
import FilInformationsArretDeliberationComponent
    from "../../Components/FilInformations/FilInformationsArretDeliberation.component";
import FilInformationsVieCompagnieFormComponent
    from "../../Components/Forms/FilInformations/FilInformationsVieCompagnieForm.component";
import FilInformationsVieCompagnie from "../../Components/FilInformations/FilInformationsVieCompagnie.component";
import FilInformationsBandeauAccueilFormComponent
    from "../../Components/Forms/FilInformations/FilInformationsBandeauAccueilForm.component";
import FilInformationsBandeauAccueilComponent
    from "../../Components/FilInformations/FilInformationsBandeauAccueil.component";


type IFilInformationsFicheScreenProps = {
    fid: string
}
export default function FilInformationsFicheScreen(props: IFilInformationsFicheScreenProps) {
    const informationsAccordionRef = useRef(null);
    const bandeauAccueilAccordionRef = useRef(null);
    const uneAccordionRef = useRef(null);
    const actualiteAccordionRef = useRef(null);
    const cleRealAccordionRef = useRef(null);
    const formationAccordionRef = useRef(null);
    const presseAccordionRef = useRef(null);
    const cvAccordionRef = useRef(null);
    const prestationAccordionRef = useRef(null);
    const compagnieAccordionRef = useRef(null);
    const arreteAccordionRef = useRef(null);
    const agendaAccordionRef = useRef(null);


    const filInformationsService: FilInformationsService = new FilInformationsService();
    const {getFilInformationsById} = UseFilInformations();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [fil, setFil] = useState<IFilInformations>(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [previsualisation, setPrevisualisation] = useState<string>("");
    const [showConfirmationSend, setShowConfirmationSend] = useState<boolean>();
    const [showConfirmationSendTest, setShowConfirmationSendTest] = useState<boolean>();
    const [showConfirmationLeaveEditionForPreview, setShowConfirmationLeaveEditionForPreview] = useState<boolean>();
    const [formOpenList, setFormOpenList] = useState<any[]>([]);
    const {handleFileDownload} = UseFileDownload();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if (!fil) return;

        const breadcrumbLinks = [
            {text: "Fil d'informations", link: AppConstants.pathFilInformations},
            {text: fil.titre},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

    }, [fil, loaded]);

    useEffect(() => {
        if (!props.fid) {
            history.push(AppConstants.pathFilInformations);
        }

        getFilInformations();

    }, [props.fid]);


    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getFilInformations();

        closeForms();
    };

    const getFilInformations = (): void => {
        getFilInformationsById(props.fid, (filInformations) => {
            setFil(filInformations);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    const closeForms = (isCancel?: boolean): void => {
        if ((informationsAccordionRef && informationsAccordionRef.current)) {
            informationsAccordionRef.current.hideForm(isCancel);
        }
        if ((bandeauAccueilAccordionRef && bandeauAccueilAccordionRef.current)) {
            bandeauAccueilAccordionRef.current.hideForm(isCancel);
        }
        if ((uneAccordionRef && uneAccordionRef.current)) {
            uneAccordionRef.current.hideForm(isCancel);
        }
        if ((actualiteAccordionRef && actualiteAccordionRef.current)) {
            actualiteAccordionRef.current.hideForm(isCancel);
        }
        if ((cleRealAccordionRef && cleRealAccordionRef.current)) {
            cleRealAccordionRef.current.hideForm(isCancel);
        }
        if ((formationAccordionRef && formationAccordionRef.current)) {
            formationAccordionRef.current.hideForm(isCancel);
        }
        if ((presseAccordionRef && presseAccordionRef.current)) {
            presseAccordionRef.current.hideForm(isCancel);
        }
        if ((cvAccordionRef && cvAccordionRef.current)) {
            cvAccordionRef.current.hideForm(isCancel);
        }
        if ((prestationAccordionRef && prestationAccordionRef.current)) {
            prestationAccordionRef.current.hideForm(isCancel);
        }
        if ((compagnieAccordionRef && compagnieAccordionRef.current)) {
            compagnieAccordionRef.current.hideForm(isCancel);
        }
        if ((arreteAccordionRef && arreteAccordionRef.current)) {
            arreteAccordionRef.current.hideForm(isCancel);
        }
        if ((agendaAccordionRef && agendaAccordionRef.current)) {
            agendaAccordionRef.current.hideForm(isCancel);
        }

        setFormOpenList([]);
    };

    const onCancelForm = (): void => {
        closeForms(true);
    };

    const onTabChange = (slug: string) => {
        if (slug === "previsualisation") {
            filInformationsService.getPrevisualisation(props.fid).then((result) => {
                if (result.datas && result.datas.html) setPrevisualisation(result.datas.html);
            });
        }

        closeForms();
        setFormOpened(false);
    };

    const onBeforeTabChange = (slug: string): boolean => {
        if (slug === "previsualisation") {
            if (formOpenList && formOpenList.length) {
                setShowConfirmationLeaveEditionForPreview(true);
                return false;
            }
        }

        return true;
    }


    const onFormOpen = (ref: React.MutableRefObject<any>) => {
        let newFormOpenList = [...formOpenList];
        newFormOpenList.push(ref);

        setFormOpenList(newFormOpenList);
    }

    const onFormClose = (ref: React.MutableRefObject<any>) => {
        let newFormOpenList = [...formOpenList];
        newFormOpenList = newFormOpenList.filter((r) => r != ref);

        setFormOpenList(newFormOpenList);
    }


    /**
     * Gestion du téléchargement du courrier
     */
    const onDownload = (): void => {
        filInformationsService.download(props.fid).then((response: IFileDownloadApiResponse) => {

            if (response && response.datas && response.datas.file) {
                handleFileDownload(response.datas.file, true);
            }

        }, (error: IApiCustomResponse) => {
            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue.",
                    type: "danger"
                });
            }
        });
    };
    /**
     * Gestion de l'envoi
     */
    const onSend = (sendType: "all" | "test"): void => {
        filInformationsService.sendMail(props.fid, {sendType}).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: sendType === "all" ? "Le fil d'informations a bien été envoyé" : "Le test du fil d'informations a bien été envoyé",
                type: "success"
            });
        }, (error: IApiCustomResponse) => {
            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue.",
                    type: "danger"
                });
            }
        });
    };

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={fil.titre} icon="icon-filInformationss"
                                         modificators="" onSearch={null}/>

					<TabsContainerComponent onClickTabCallback={onTabChange} onBeforeTabChange={onBeforeTabChange}>
						<TabPanelComponent label="Fiche" icon="icon-fiche" slug="fiche">
							<div className="fiche-content -right-gutter border-footer">

								<AccordionWithFormSwitchComponent
									ref={informationsAccordionRef}
									title="Informations"
									icon="icon-informations"
									contentShowedOnInit={true}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsInfosFormComponent filInformations={fil}
                                                                             submitMethod={(formData: IFilInformationsInfosFormData) => filInformationsService.infos(formData, fil.id)}
                                                                             onSuccessSubmit={onSuccessSubmitForm}
                                                                             onCancel={onCancelForm}/>}
                                    infos={<FilInformationsInfos filInformations={fil}/>}
								/>

                                <AccordionWithFormSwitchComponent
                                    ref={bandeauAccueilAccordionRef}
                                    title="Bandeau Accueil"
                                    icon="icon-informations"
                                    contentShowedOnInit={false}
                                    contentType="filInformations"
                                    contentId={fil.id}
                                    onFormClose={() => {onFormClose(uneAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(uneAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsBandeauAccueilFormComponent filInformations={fil}
                                                                                      submitMethod={(formData: IFilInformationsInfosFormData) => filInformationsService.une(formData, fil.id)}
                                                                                      onSuccessSubmit={onSuccessSubmitForm}
                                                                                      onCancel={onCancelForm}/>}
                                    infos={<FilInformationsBandeauAccueilComponent filInformations={fil}/>}
                                />

								<AccordionWithFormSwitchComponent
									ref={uneAccordionRef}
									title="Une"
									icon="icon-megaphone"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(uneAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(uneAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsUneFormComponent filInformations={fil}
                                                                           submitMethod={(formData: IFilInformationsInfosFormData) => filInformationsService.une(formData, fil.id)}
                                                                           onSuccessSubmit={onSuccessSubmitForm}
                                                                           onCancel={onCancelForm}/>}
                                    infos={<FilInformationsUne filInformations={fil}/>}
								/>

								<AccordionWithFormSwitchComponent
									ref={actualiteAccordionRef}
									title="Actualités"
									icon="icon-fil-infos"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(actualiteAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(actualiteAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsActusFormComponent filInformations={fil}
                                                                             submitMethod={(formData: IFilInformationsInfosFormData) => filInformationsService.actus(formData, fil.id)}
                                                                             onSuccessSubmit={onSuccessSubmitForm}
                                                                             onCancel={onCancelForm}/>}
                                    infos={<FilInformationsActualites filInformations={fil}/>}
								/>

                                <AccordionWithFormSwitchComponent
                                    ref={cleRealAccordionRef}
                                    title="Clés REAL"
                                    icon="icon-usb"
                                    contentShowedOnInit={false}
                                    contentType="filInformations"
                                    contentId={fil.id}
                                    onFormClose={() => {onFormClose(cleRealAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(cleRealAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsRealFormComponent filInformations={fil}
                                                                            submitMethod={(formData: IFilInformationsInfosFormData) => filInformationsService.real(formData, fil.id)}
                                                                            onSuccessSubmit={onSuccessSubmitForm}
                                                                            onCancel={onCancelForm}/>}
                                    infos={<FilInformationsReal filInformations={fil}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={formationAccordionRef}
                                    title="Formations"
                                    icon="icon-formations"
                                    contentShowedOnInit={false}
                                    contentType="filInformations"
                                    contentId={fil.id}
                                    onFormClose={() => {onFormClose(formationAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(formationAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={
                                        <FilInformationsFormationsFormComponent filInformations={fil}
                                                                                submitMethod={(formData: IFilInformationsImagesFormData) => filInformationsService.formations(formData, fil.id)}
                                                                                onSuccessSubmit={onSuccessSubmitForm}
                                                                                onCancel={onCancelForm} />
                                    }
                                    infos={<FilInformationsFormations filInformations={fil}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={presseAccordionRef}
                                    title="Vu dans la presse"
                                    icon="icon-megaphone"
                                    contentShowedOnInit={false}
                                    contentType="filInformations"
                                    contentId={fil.id}
                                    onFormClose={() => {onFormClose(presseAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(presseAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsPresseFormComponent filInformations={fil}
                                                                              submitMethod={(formData: IFilInformationsImagesFormData) => filInformationsService.presses(formData, fil.id)}
                                                                              onSuccessSubmit={onSuccessSubmitForm}
                                                                              onCancel={onCancelForm}/>}
                                    infos={<FilInformationsPresse filInformations={fil}/>}
                                />

								<AccordionWithFormSwitchComponent
									ref={cvAccordionRef}
									title="CVthèque"
									icon="icon-fiche"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(cvAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(cvAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsCvsFormComponent filInformations={fil}
                                                                           submitMethod={(formData: IFilInformationsInfosFormData) => filInformationsService.cvs(formData, fil.id)}
                                                                           onSuccessSubmit={onSuccessSubmitForm}
                                                                           onCancel={onCancelForm}/>}
                                    infos={<FilInformationsCVs filInformations={fil}/>}
								/>

								<AccordionWithFormSwitchComponent
									ref={prestationAccordionRef}
									title="Prestations de serment"
									icon="icon-megaphone"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(prestationAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(prestationAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsPrestationsFormComponent filInformations={fil}
                                                                                   submitMethod={(formData: IFilInformationsPrestationsFormData) => filInformationsService.prestations(formData, fil.id)}
                                                                                   onSuccessSubmit={onSuccessSubmitForm}
                                                                                   onCancel={onCancelForm}/>}
                                    infos={<FilInformationsPrestations filInformations={fil}/>}
								/>

								<AccordionWithFormSwitchComponent
									ref={compagnieAccordionRef}
									title="Vie de la compagnie"
									icon="icon-megaphone"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(compagnieAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(compagnieAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsVieCompagnieFormComponent filInformations={fil}
                                                                                    submitMethod={(formData: IFilInformationsVieCompagnieFormData) => filInformationsService.vies(formData, fil.id)}
                                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                                    onCancel={onCancelForm}/>}
                                    infos={<FilInformationsVieCompagnie filInformations={fil}/>}
								/>

								<AccordionWithFormSwitchComponent
									ref={arreteAccordionRef}
									title="Arrêtés et délibérations"
									icon="icon-megaphone"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(arreteAccordionRef); setFormOpened(false);}}
                                    onFormOpen={() => {onFormOpen(arreteAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsArretDeliberationFormComponent filInformations={fil}
                                                                                         submitMethod={(formData: IFilInformationsArretDeliberationFormData) => filInformationsService.arretes(formData, fil.id)}
                                                                                         onSuccessSubmit={onSuccessSubmitForm}
                                                                                         onCancel={onCancelForm}/>}
                                    infos={<FilInformationsArretDeliberationComponent filInformations={fil}/>}
								/>

								<AccordionWithFormSwitchComponent
									ref={agendaAccordionRef}
									title="Agenda"
									icon="icon-agenda"
									contentShowedOnInit={false}
									contentType="filInformations"
									contentId={fil.id}
                                    onFormClose={() => {onFormClose(agendaAccordionRef); setFormOpened(false)}}
                                    onFormOpen={() => {onFormOpen(agendaAccordionRef); setFormOpened(true);}}
                                    hideEditButton={formOpened}
                                    form={<FilInformationsAgendaFormComponent filInformations={fil}
                                                                              submitMethod={(formData: IFilInformationsImagesFormData) => filInformationsService.evenements(formData, fil.id)}
                                                                              onSuccessSubmit={onSuccessSubmitForm}
                                                                              onCancel={onCancelForm}/>}
                                    infos={<FilInformationsAgenda filInformations={fil}/>}
								/>


                                <AsideFilInformationsComponent type="fiche" filInformations={fil}/>
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Prévisualisation" icon="icon-voir" slug="previsualisation">
                            <div className="form-actions -alone">
                                <button className="g-button -primary -with-icon"
                                        onClick={() => setShowConfirmationSend(true)}>
                                    <i className="picto icon-paper-plane"/>
                                    Envoyer (créer dans Mailjet)
                                </button>
                                <button className="g-button -primary -with-icon"
                                        onClick={() => setShowConfirmationSendTest(true)}>
                                    <i className="picto icon-paper-plane"/>
                                    Envoyer un test
                                </button>
                            </div>

							<div className="fiche-content border-footer">
                                <div className="mail-preview">
                                    <CommentairesComponent commentaires={previsualisation} modificators={"-full"}/>
                                </div>
                            </div>
                        </TabPanelComponent>

                    </TabsContainerComponent>

                    {
                        showConfirmationSend &&
                        <ConfirmationComponent text={"Envoyer le fil d'informations à toute la liste d'abonnés ? "}
                                               onConfirm={() => {
                                                   onSend("all");
                                                   setShowConfirmationSend(false);
                                               }}
                                               onCancel={() => setShowConfirmationSend(false)}
                        />
                    }
                    {
                        showConfirmationSendTest &&
                        <ConfirmationComponent
                            text={"Envoyer le fil d'informations de test à l'adresse indiquée dans le paramètre \"Envoi des tests du fil d'info\" "}
                            onConfirm={() => {
                                onSend("test");
                                setShowConfirmationSendTest(false);
                            }}
                            onCancel={() => setShowConfirmationSendTest(false)}
                        />
                    }
                    {
                        showConfirmationLeaveEditionForPreview &&
                        <ConfirmationComponent
                            text={"Attention vous avez des modifications non enregistrées. Merci de valider/annuler vos modifications avant d'accéder à la prévisualisation."}
                            onConfirm={() => {
                                setShowConfirmationLeaveEditionForPreview(false)
                            }}
                            hideConfirm={true}
                            onCancel={() => setShowConfirmationLeaveEditionForPreview(false)}
                        />
                    }

                </LayoutComponent>
            }
        </>
    )
}
