import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ILabel} from "src/Models/Label.model";
import {LabelsService} from "src/Services/Labels.service";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import StringHelper from "../../Helpers/String.helper";


type IAsideLabelsComponentProps = {
    label?: ILabel,
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void,

    filters?: any
}

export default function AsideLabelsComponent(props: IAsideLabelsComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];
        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-labels-add", click: () => history.push(AppConstants.pathLabels + "/creation")});
        }

        if( props.type === "fiche"){

            let stringHelper = new StringHelper();
            let filename = "label-fiche-" + stringHelper.slugify(props.label.nom);
            actions.push({name: "print-pdf", filename: filename , icon: "icon-print"});
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        } else if( props.type !== "list") {
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        setActions(actions);
    }, [props.type, props.label, props.filters]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }

    /**
     * Suppression
     */
    const doDelete = (): void => {
        const labelsService: LabelsService = new LabelsService();

        labelsService.delete(props.label.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le label a bien été supprimé.",
                type: "success"
            });

            history.push(AppConstants.pathLabels);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du label.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathLabels}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }

    return (
        <>
            {
                showConfirmation && props.label &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer ce label ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
