import DateHelper from "src/Helpers/Date.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import FileHelper from "src/Helpers/File.helper";
import React from "react";
import {IReclamation} from "src/Models/Reclamation.model";
import ReclamationsHelper from "src/Helpers/Reclamations.helper";
import {IReclamationPlaignant} from "../../Models/ReclamationPlaignant.model";
import CommonHelper from "../../Helpers/Common.helper";

type IReclamationPlaignantsComponentProps = {
    reclamation: IReclamation,
}

export default function ReclamationPlaignantsComponent(props: IReclamationPlaignantsComponentProps) {
    const reclamation: IReclamation = props.reclamation;
    const plaignants: IReclamationPlaignant[] = reclamation.plaignants;

    return (
        <div className="informations-wrapper">
            {
                plaignants && (plaignants.length > 0) && plaignants.map((plaignant, index) =>
                    <div className="columns" key={index}>
                        <p className="title -alternative">{`${plaignant.civilite ? ReclamationsHelper.getFormatedCivilite(plaignant.civilite) : '-'} ${plaignant.prenom ? plaignant.prenom : ''} ${plaignant.nom}`}</p>

                        {/*
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Civilite</p>
                                <p className="value">{p.civilite ? ReclamationsHelper.getFormatedCivilite(p.civilite) : '-'}</p>
                            </div>
                        </div>
                        */}

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Fonction</p>
                                <p className="value">{plaignant.fonction || '-'}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type de personne</p>
                                <p className="value">{ReclamationsHelper.getFormatedTypePersonne(plaignant.typePersonne) || '-'}</p>
                            </div>

                            {
                                (plaignant && plaignant.typePersonne && plaignant.typePersonne === "physique") &&
                                <div className="informations-block">
                                    <p className="label">Nationalité</p>
                                    <p className="value">{CommonHelper.getFormatedTypeNationalite(plaignant.nationalite) || '-'}</p>
                                </div>
                            }
                        </div>

                        <div className="column">
                            {
                                (plaignant && plaignant.typePersonne && plaignant.typePersonne === "physique") &&
                                <>
                                    <div className="informations-block">
                                        <p className="label">Date de naissance</p>
                                        <p className="value">
                                            {
                                                plaignant.dateNaissance ? DateHelper.getFormatedDate(plaignant.dateNaissance) : '-'
                                            }
                                        </p>
                                    </div>
                                    <div className="informations-block">
                                        <p className="label">Lieu de naissance</p>
                                        <p className="value">{plaignant.lieuNaissance || '-'}</p>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="column">
                            {
                                (reclamation && reclamation.origine && reclamation.origine === "avocat") &&
                                <div className="informations-block">
                                    <p className="label">Nom du client</p>
                                    <p className="value">{plaignant.nomClient || '-'}</p>
                                </div>
                            }
                            {
                                (plaignant && plaignant.typePersonne && plaignant.typePersonne === "physique") &&
                                <div className="informations-block">
                                    <p className="label">Profession</p>
                                    <p className="value">{plaignant.profession || '-'}</p>
                                </div>
                            }
                            {
                                (plaignant && plaignant.typePersonne && plaignant.typePersonne === "morale") &&
                                <div className="informations-block">
                                    <p className="label">Structure</p>
                                    <p className="value">{plaignant.structure || '-'}</p>
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}
