import React, {useEffect, useState} from "react";
import {ICorrespondance} from "src/Models/Correspondance.model";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import DateHelper from "../../../Helpers/Date.helper";
import {IDocument} from "../../../Models/Document.model";
import {AppConstants} from "../../../Constants/AppConstants";
import DocumentsHelper from "src/Helpers/Documents.helper";
import SelectFieldComponent from "../../Fields/Select.field.component";
import {CorrespondanceConstants} from "../../../Constants/CorrespondancesConstants";


const CorrespondanceDocuments = (props: {
    correspondance: ICorrespondance
}) => {
    const [documentsList, setDocumentsList] = useState<IDocument[]>([]);
    const [documentsFiltered, setDocumentsFiltered] = useState<IDocument[]>([]);
    const [orderDirection, setOrderDirection] = useState<string>(AppConstants.orderType.asc);
    const [orderBy, setOrderBy] = useState<string>('date');
    const [filterBy, setFilterBy] = useState<string>(null);
    const [filterByValue, setFilterByValue] = useState<string>(null);

    useEffect(() => {
        if(props.correspondance && props.correspondance.documents){
            const newDocumentsList: IDocument[] = props.correspondance.documents;
            setDocumentsList(newDocumentsList);
        }
    }, [props.correspondance])


    useEffect(() => {
        filterAndOrderDocuments();
    }, [documentsList, orderBy, orderDirection, filterByValue])

    /**
     * Permet le tri et le filtre des documents
     */
    const filterAndOrderDocuments = (): void => {
        const newDocuments = DocumentsHelper.filterAndOrder(documentsList, orderBy, orderDirection, filterBy, filterByValue);
        setDocumentsFiltered(newDocuments);
    }



    return (
        <>
            {
                documentsList && (documentsList.length > 0) &&
                <div className="list-filters -documents-list">
                    <i className={`icon icon-filtre`}/>
                    <SelectFieldComponent fieldName="correspondancesDocumentsOrder"
                                          label="Trier par"
                                          options={CorrespondanceConstants.documentsOrderOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white`}
                                          oldValue={orderBy ? orderBy : ""}
                                          onChange={(value: string) => setOrderBy(value)}
                    />
                    <SelectFieldComponent fieldName="correspondancesDocumentsOrderDirectement"
                                          label="Ordre du tri"
                                          options={AppConstants.orderDirectionOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white -small-select`}
                                          oldValue={orderDirection ? orderDirection : ""}
                                          onChange={(value: string) => setOrderDirection(value)}
                    />
                </div>
            }

            <div className="informations-wrapper">
                {
                    documentsFiltered.map((d,dIndex) =>
                        <div className="columns" key={dIndex}>
                            <p className="title">{ d.nom ? d.nom : DateHelper.getFormatedDate(d.date)}</p>

                            <div className="column -auto">
                                <div className="informations-block">
                                    <p className="label">Fichier</p>
                                    <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(d.fichier)} />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
};

export default CorrespondanceDocuments;
