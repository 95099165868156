import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {IParametreGetApiResponse, ParametresService} from "../Services/Parametres.service";
import {IParametre} from "../Models/Parametre.model";

const UseParametre = () => {
    const parametresService: ParametresService = new ParametresService();
    const history = useHistory();

    /**
     * Récupération d'un parametre à partir de son id
     *
     * @param {string} pid
     * @param {(parametre: IParametre) => void} callback
     * @param {() => void} callbackError
     */
    const getParametreById = (pid: string, callback:(parametre: IParametre) => void, callbackError?: () => void ) => {
        parametresService.getParametre(pid).then((response: IParametreGetApiResponse) => {
            if( response && response.datas.parametre){
                if(callback){
                    callback(response.datas.parametre);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.goBack();

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du paramètre'",type: "danger"});
        });
    };


    return {getParametreById};
};

export default UseParametre;
