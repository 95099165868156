import {IDifferend} from "src/Models/Differend.model";
import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages, IFileDownloadApiResponse} from "src/Services/Api.service";
import CommonHelper from "src/Helpers/Common.helper";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypesGetApiResponse} from "src/Services/Courriers.service";
import CourriersHelper from "src/Helpers/Courriers.helper";
import {
    DifferendsService,
    IDifferendCourrierFormData,
    IDifferendGetApiResponse,
} from "src/Services/Differends.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import UseFileDownload from "src/Hooks/UseFileDownload";

type IDifferendCourrierFormProps = {
    differend?: IDifferend,
    onSuccessSubmit?: (response: IDifferendGetApiResponse) => void,
}

export default function DifferendCourrierForm(props: IDifferendCourrierFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const differendsService: DifferendsService = new DifferendsService();

    const {handleFileDownload} = UseFileDownload();

    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();

        courriersService.getCourriersTypesForModule('differends', props.differend.id).then((response: ICourriersTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                setCourriersTypes(response.datas.types);
            }
        });
    }, [props.differend]);




    useEffect(()=> {
        if(!courriersTypes || !courriersTypes.length) return;

        const {differend} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'date',
                fieldName: "date",

                label: "Date du courrier",
                modificators: "-on-white",
                required: false,
            },
            {
                type: 'select',
                fieldName: "type",

                label: "Type de courrier",
                placeholder: "Type de courrier",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: courriersTypes ? CourriersHelper.formatCourriersTypesForOptions(courriersTypes) : []
            },
        ];

        for(let type of courriersTypes){
            formElementsColumn1.push(
                {
                    type: 'text',
                    fieldName: "subjectMail",

                    label: "Sujet du mail",
                    placeholder: "Sujet de l'email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: (differend.courriersSaved && differend.courriersSaved[type.slug] && differend.courriersSaved[type.slug].subjectMail) ? differend.courriersSaved[type.slug].subjectMail : (type.sujet ? type.sujet : "" ),

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                },
                {
                    type: 'wysiwyg',
                    fieldName: "contenu",

                    label: "Contenu",
                    placeholder: "Contenu",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: (differend.courriersSaved && differend.courriersSaved[type.slug] && differend.courriersSaved[type.slug].contenu) ? differend.courriersSaved[type.slug].contenu : (type.modele ? type.modele : "" ),

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                },
                {
                    type: 'wysiwyg',
                    fieldName: "contenuMail",

                    label: "Contenu Email",
                    placeholder: "Contenu Email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: (differend.courriersSaved && differend.courriersSaved[type.slug] && differend.courriersSaved[type.slug].contenuMail) ? differend.courriersSaved[type.slug].contenuMail : (type.contenuMail ? type.contenuMail : "" ),

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
        }

        formElementsColumn1.push(
            {
                type: 'radio',
                fieldName: "afficherEntete",

                label: "Afficher l'entête",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "sauvegarderCourrier",

                label: "Sauvegarder le courrier dans les documents",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            }
        );

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Sauvegarder",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSaveCourrier(formData,onActionEnded)}
            },
            {
                label: "Télécharger",
                modificators: "-primary",
                icon: "icon-download",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onDownload(formData,onActionEnded)}
            },
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false,onActionEnded)}
            },
            {
                label: "Envoyer un test",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, true,onActionEnded)}
            }
        ];
        setFormActions(currentFormActions);

    }, [courriersTypes]);


    /**
     * Gestion du téléchargement du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {() => void} onActionEnded
     */
    const onDownload = (formData: FormComponentFormData, onActionEnded?: ()=>void): void => {
        setErrorsMessage([]);
        differendsService.downloadCourrier(props.differend.id, formData as IDifferendCourrierFormData).then((response: IFileDownloadApiResponse) => {
            if(onActionEnded) onActionEnded();
            if(props.onSuccessSubmit) props.onSuccessSubmit(response as IApiCustomResponse);

            if(response && response.datas && response.datas.file){
                handleFileDownload(response.datas.file,true);
            }

        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors du téléchargement du courrier.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     * @param {() => void} onActionEnded
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: ()=>void): void => {
        setErrorsMessage([]);
        const formDataUpdated: IDifferendCourrierFormData = {
            ...formData as IDifferendCourrierFormData,
            isDebug: isDebug || false
        };

        differendsService.sendCourrier(props.differend.id, formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if(props.onSuccessSubmit) props.onSuccessSubmit(response);

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de l'envoi du courrier.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'enregistrement du courrier
     */
    const onSaveCourrier = (formData: FormComponentFormData, onActionEnded?: ()=>void): void => {
        setErrorsMessage([]);
        const formDataUpdated: IDifferendCourrierFormData = {
            ...formData as IDifferendCourrierFormData,
        };

        differendsService.saveCourrier(props.differend.id, formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if(props.onSuccessSubmit) props.onSuccessSubmit(response);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le courrier a bien été sauvegardé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'enregistrement du courrier.",
                    type: "danger"
                });
            }
        });
    }

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
