import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import {ICotisationsSaisieOfficeFormData, IGetCotisationsOffice} from "src/Services/Cotisations.service";



type ICotisationsOfficeRecapFraisTraitementFormProps = {
    cotisation?: ICotisationsOffice,
    isOnCreationStep?: boolean

    submitMethod: (formData: ICotisationsSaisieOfficeFormData) => Promise<IApiCustomResponse>,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onCancel: () => void,
}

export default function CotisationsOfficeRecapFraisTraitementFormComponent(props: ICotisationsOfficeRecapFraisTraitementFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const cotisation: ICotisationsOffice = props.cotisation;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'number',
                label: "Montant",
                placeholder: "Montant",
                fieldName: "montantFraisTraitement",
                oldValue: cotisation && cotisation.montantFraisTraitement ? cotisation.montantFraisTraitement.toString() : '',
                modificators: props.isOnCreationStep ? "-on-white" : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [];
        currentFormActions = [
            {
                label: "Enregistrer",
                modificators: "-primary",
                onAction: (formData) => {onSubmit(formData, false)}
            },
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: onCancel
            }
        ];
        setFormActions(currentFormActions);

    },[props.cotisation]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ICotisationsSaisieOfficeFormData).then((response: IApiCustomResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les données ont bien été modifiées.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des données.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
