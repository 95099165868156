import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideParametresComponent from "src/Components/Aside/AsideParametres.component";
import {IParametresGetApiResponse, IParametresGetFormData, ParametresService} from "src/Services/Parametres.service";
import {IParametre} from "src/Models/Parametre.model";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import ParametresExportComponent from "src/Components/Parametres/ParametresExport.component";
import NumberHelper from "../../Helpers/Number.helper";
import CommonHelper from "../../Helpers/Common.helper";
import LoadingComponent from "../../Components/Loading/Loading.component";

type IParametresListScreenProps = {
    module?: string,
}


const ParametresListScreen = (props: IParametresListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [parametresList, setParametresList] = useState<IParametre[]>([]);

    const [path, setPath] = useState<string>(null);
    const location = useLocation();
    const history = useHistory();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(props.module == 'formations' ? AppConstants.pathParametresFormations : (props.module == 'cotisations' ? AppConstants.pathParametresCotisations : AppConstants.pathParametres ));
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);


    //Génération des breadcrumb links
    useEffect(() => {
        let breadcrumbLinks: IBreadcrumbLink[] = [];

        if( props.module  == 'formations') {
            breadcrumbLinks = [
                {link: AppConstants.pathMosaique + '#formations', text: "Formations"},
                {text: "Paramètres"},
            ];
            setPath(AppConstants.pathParametresFormations);
        }
        else if( props.module  == 'cotisations') {
            breadcrumbLinks = [
                {text: "Cotisations", link: AppConstants.pathCotisationsListeOffices},
                {text: "Paramètres"},
            ];
            setPath(AppConstants.pathParametresFormations);
        }
        else{
            breadcrumbLinks = [
                {text: "Paramètres"},
            ];
            setPath(AppConstants.pathParametres);
        }


        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);


    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const parametresService: ParametresService = new ParametresService();

        parametresService.getListForModule(props.module, filters as IParametresGetFormData).then((response: IParametresGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newParametresList = response.datas.pagination.data;
                setParametresList(newParametresList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Paramètres"
                    icon="icon-cog"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
						<TabsContainerComponent>
							<TabPanelComponent label="Liste" icon="icon-menu">
								<div className="list-content -right-gutter">


									<table className={`list-table`}>
										<thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "nom", label: "Nom"},
                                                    {slug: "valeur", label: "Valeur"},
                                                    {slug: "dateModification", label: "Date de modification"},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
										</thead>
										<tbody>
                                            {
                                                parametresList && parametresList.map((parametre: IParametre, index: number) =>
                                                    <tr key={index} onClick={() => history.push(`${AppConstants.pathParametres}/${parametre.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {parametre.nom}
                                                        </td>
                                                        <td className="td">
                                                            {
                                                                parametre.type == "date" &&
                                                                DateHelper.getFormatedDate(parametre.valeur)
                                                            }
                                                            {
                                                                parametre.type == "number" &&
                                                                NumberHelper.formatThousand(parseFloat(parametre.valeur))
                                                            }
                                                            {
                                                                parametre.type == "ouiNon" &&
                                                                CommonHelper.getFormatedOuiNon(parametre.valeur)
                                                            }
                                                            {
                                                                parametre.type == "text" &&
                                                                parametre.valeur
                                                            }
                                                        </td>
                                                        <td className="td">
                                                            {DateHelper.getFormatedDate(parametre.dateModification)}
                                                        </td>
                                                    </tr>
                                                )
                                            }
										</tbody>
									</table>

									<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

									<AsideParametresComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} module={props.module} />
								</div>
							</TabPanelComponent>
						</TabsContainerComponent>
					</>
                }
            </div>

            <ParametresExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} module={props.module || null} filters={filters} />

        </LayoutComponent>
    )
};

export default ParametresListScreen;
