import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";

type ICotisationsOfficeRecapComptaComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapComptaComponent(props: ICotisationsOfficeRecapComptaComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Compta</p>
                        <p className="value">{cotisation.compta || "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
