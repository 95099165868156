import {IOrganisme} from "src/Models/Organisme.model";
import GroupesHelper from "src/Helpers/Groupes.helper";

type IOrganismeIdentiteComponentProps = {
    organisme: IOrganisme,
}

export default function OrganismeIdentiteComponent(props: IOrganismeIdentiteComponentProps) {
    const organisme: IOrganisme = props.organisme;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{organisme.nom ? organisme.nom  : "-"}</p>
                    </div>
                </div>
                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Groupes</p>
                        <p className="value">{organisme.groupes && organisme.groupes.length ? GroupesHelper.getFormated(organisme.groupes) : "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
