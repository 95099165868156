import React from "react";
import {IReclamationsStats} from "src/Models/Reclamation.model";
import moment, {Moment} from "moment";

type ReclamationsListStatsComponentProps = {
    stats: IReclamationsStats
}
export default function ReclamationsListStatsComponent(props: ReclamationsListStatsComponentProps) {
    const {stats} = props;

    const currentDate: Moment = moment();
    const currentYear: string = currentDate.format("YYYY");
    const previousYear: string = currentDate.subtract(1, "years").format("YYYY");

    if (!stats) {
        return null;
    }

    return (
        <div className={"differends-summary"}>
            <table className="table">
                <thead>
                <tr>
                    <th className="-left">Nombre de réclamations</th>
                    <th>{currentYear}</th>
                    <th>{previousYear}</th>
                    <th>Avant</th>
                    <th>Total</th>
                </tr>

                <tr>
                    <td className="-left -label">En cours</td>
                    <td>{stats.enCours[currentYear]}</td>
                    <td>{stats.enCours[previousYear]}</td>
                    <td>{stats.enCours.before}</td>
                    <td>{stats.enCours.total}</td>
                </tr>

                <tr>
                    {/* Autre + RC */}
                    <td className="-left -label">Autres</td>
                    <td>{stats.autre[currentYear] + stats.rc[currentYear]}</td>
                    <td>{stats.autre[previousYear] + stats.rc[previousYear]}</td>
                    <td>{stats.autre.before + stats.rc.before}</td>
                    <td>{stats.autre.total + stats.rc.total}</td>
                </tr>

                <tr style={{fontStyle: "italic"}}>
                    <td className="-left -label" style={{paddingLeft: "15px"}}>dont RC</td>
                    <td>{stats.rc[currentYear]}</td>
                    <td>{stats.rc[previousYear]}</td>
                    <td>{stats.rc.before}</td>
                    <td>{stats.rc.total}</td>
                </tr>

                <tr>
                    <td className="-left -label">Archivage (Actions terminées)</td>
                    <td>{stats.terminee[currentYear]}</td>
                    <td>{stats.terminee[previousYear]}</td>
                    <td>{stats.terminee.before}</td>
                    <td>{stats.terminee.total}</td>
                </tr>

                <tr>
                    <td className="-left -label">Total</td>
                    <td>{stats.total[currentYear]}</td>
                    <td>{stats.total[previousYear]}</td>
                    <td>{stats.total.before}</td>
                    <td>{stats.total.total}</td>
                </tr>
                </thead>
            </table>
        </div>
    )

}
