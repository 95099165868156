import ApiService, {IApiCustomResponse} from "./Api.service";

export type IEvenementFormData = {
    dateDebut: string,
    dateFin: string,
    commentaire: string,
}
export type IEvenementsFormData = {
    historiques?: IEvenementFormData[],
    relationName: string,
    relationId: number,
    idsToDelete?: number[]
}
export type ICoordonneeFormData = {
    type: string,
    plaignantId?: string,
    destination: string,
    coordonnees: string,
}
export type ICoordonneesFormData = {
    coordonnes?: ICoordonneesFormData[],
    relationName: string,
    relationId: number,
    idsToDelete?: number[]
}

export type IAdresseFormData = {
    plaignantId?: string,
    ligne1: string,
    ligne2?: string,
    boitePostale?: string,
    codePostal: string,
    ville: string,
    arrondissement: string,
    tribunalJudiciaire: string,
}
export type IAdressesFormData = {
    adresses?: IAdresseFormData[],
    relationName: string,
    relationId: number,
    idsToDelete?: number[]
}
export type IProtectionFormData = {
    contentType: string,
    contentId: number|string,
}
export type IProtectionCheckApiResponse = IApiCustomResponse & {
    datas?: {
        isLocked: boolean,
        name?: string,
        sameUserLogged?: boolean,
    }
}

export class CommonsService extends ApiService{
    /**
     * Permet la synchronisation avec une liste d'évenements
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public syncEvenements(formData: IEvenementsFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/evenements/sync', 'POST', formData);
    }

    /**
     * Permet la synchronisation avec une liste d'adresses
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public syncAdresses(formData: IAdressesFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/adresses/sync', 'POST', formData);
    }

    /**
     * Permet la synchronisation avec une liste de coordonnées
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public syncCoordonnees(formData: ICoordonneesFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/coordonnees/sync', 'POST', formData);
    }


    /**
     * Permet de vérifier si un contenu est bloqué en écriture
     *
     * @param {IProtectionFormData} formData
     * @returns {Promise<IProtectionCheckApiResponse>}
     */
    public protectionCheck(formData: IProtectionFormData): Promise<IProtectionCheckApiResponse>{
        return this.doApiCall('api/protection/check', "POST", formData);
    }

    /**
     * Permet de débloquer un contenu
     *
     * @param {IProtectionFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public protectionUnlock(formData: IProtectionFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/protection/unlock', "POST", formData);
    }

    /**
     * Permet de bloquer un contenu en écriture
     *
     * @param {IProtectionFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public protectionLock(formData: IProtectionFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/protection/lock', "POST", formData);
    }

}

