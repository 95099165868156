import React, {useContext, useEffect, useState} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import { ReactComponent as Logo } from "src/assets/images/logo-notaire.svg"
import LayoutComponent from "src/Components/Layout/Layout.component";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";



type LoginScreenProps = {};

const LoginScreen = (props: LoginScreenProps) => {
    const {login, isAuthenticated} = useContext(UserContext) as IUserContext;
    const history = useHistory();

    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);


    useEffect( () => {
        const formElementsColumn1: FormElement[] = [
            {
                type: 'email',
                fieldName: "email",

                label: "Identifiant",
                labelModificators: "-white",
                placeholder: "Identifiant",
                required: false,
                oldValue: "",
            },
            {
                type: 'password',
                fieldName: "password",

                label: "Mot de passe",
                labelModificators: "-white",
                placeholder: "Mot de passe",
                required: false,
                oldValue: "",
            },
            {
                type: 'link',
                path: '/mot-de-passe-oublie',
                modificators: "-right -white",
                label: "Mot de passe oublié ?",
            },
        ];
        const currentFormColumns: FormColumn[] = [{
            modificators: "-full",
            elements: formElementsColumn1
        }];
        setFormColumns(currentFormColumns);


        const currentFormActions: FormActions[] = [
            {
                label: "Me connecter",
                modificators: "-primary -secondary-on-tablet",
                onAction: onSubmit
            }
        ];
        setFormActions(currentFormActions);
    }, []);

    useEffect(()=>{
        if(isAuthenticated){
            // On redirige vers la home
            history.push(AppConstants.pathHome);
        }
    },[isAuthenticated, history]);


    /**
     * Ecoute du click sur le submit du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        login(formData.email, formData.password).then((response:IApiCustomResponse) => {
            history.push(AppConstants.pathHome);
        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la connexion",type: "danger"});
        });
    };



    return (
        <LayoutComponent wrapperModificators="-full-screen -login" contentModificators="-bg-primary" showMenu={false}>
	        <div className="login-wrapper">
		        <header className="login-header-wrapper">
			        <figure className="logo-wrapper">
				        <Logo className="logo" />
			        </figure>

			        <h1 className="g-title -center -white">Connexion</h1>
		        </header>

                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages}

                    modificatorsActions="-outside-center"
                />
	        </div>
        </LayoutComponent>
    )
};

export default LoginScreen;
