import {IOfficeGetApiResponse, IOfficeSitesGroupesLogicielsFormData} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {IOffice} from "src/Models/Office.model";
import {IGroupe} from "src/Models/Groupe.model";
import {GroupesService, IGroupesGetApiResponse} from "src/Services/Groupes.service";
import GroupesHelper from "src/Helpers/Groupes.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type IOfficeSitesGroupesLogicielsFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IOfficeSitesGroupesLogicielsFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeSitesGroupesLogicielsFormComponent(props: IOfficeSitesGroupesLogicielsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [groupes, setGroupes] = useState<IGroupe[]>([]);
    const [groupesLoaded, setGroupesLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();


    useEffect(() => {
        const groupesServices: GroupesService = new GroupesService();

        groupesServices.getAllNonAutomatique().then((response:IGroupesGetApiResponse) => {
            if(response && response.datas && response.datas.groupes){
                setGroupes(response.datas.groupes);
                setGroupesLoaded(true);
            }
        });

    }, [])

    useEffect(() => {
        if(!groupes || !groupes.length) return;

        const office: IOffice = props.office;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "logicielRedactionActes",

                label: "Logiciel rédaction d'actes",
                placeholder: "Logiciel rédaction d'actes",
                required: false,
                options: OfficesConstants.logicielRedactionActesOptions,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.logicielRedactionActes ? office.logicielRedactionActes : "" ,
            },
            {
                type: 'selectMultiple',
                fieldName: "groupes",

                label: "Groupes",
                placeholder: "Groupes",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                //Les groupes automatique ne doivent pas être présent dans la liste déroulante
                //Leur gestion est automatique à l'enregistrement d'un office
                oldValues: office && office.groupes ? GroupesHelper.getIdsFromListWithoutAutomatique(office.groupes) : [],
                oldValuesReadOnly: office && office.groupes ? GroupesHelper.getNamesFromListAutomatique(office.groupes) : [],

                options: groupes ? GroupesHelper.formatListForOptions(groupes): []
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'select',
                fieldName: "logicielVisio",

                label: "Logiciel Visio",
                placeholder: "Logiciel Visio",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: OfficesConstants.logicielsVisioOptions,

                oldValue: office && office.logicielVisio ? office.logicielVisio : "",
            },
            {
                type: 'select',
                fieldName: "logicielNegociation",

                label: "Logiciel de négociation",
                placeholder: "Logiciel de négociation",
                required: false,
                options: OfficesConstants.logicielNegociationOptions,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.logicielNegociation ? office.logicielNegociation : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'select',
                fieldName: "systemeCompta",

                label: "Système Compta",
                placeholder: "Système Compta",
                required: false,
                options: OfficesConstants.systemeComptaOptions,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.systemeCompta ? office.systemeCompta : "",
            },
            {
                type: 'text',
                fieldName: "logicielAutres",

                label: "Autres logiciels",
                placeholder: "Autres logiciels",
                required: false,
                options: OfficesConstants.logicielNegociationOptions,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.logicielAutres ? office.logicielAutres : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    }, [groupes]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeSitesGroupesLogicielsFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: !props.isOnCreationStep ? "Les informations de l'office ont bien été modifiée." : "L'office a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                groupesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }
        </div>
    )

}
