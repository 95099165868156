import {IGroupe} from "src/Models/Groupe.model";
import CommonHelper from "src/Helpers/Common.helper";
import GroupesHelper from "src/Helpers/Groupes.helper";
import ReactHtmlParser from "react-html-parser";

type IGroupeInformationsComponentProps = {
    groupe: IGroupe,
}

export default function GroupeInformationsComponent(props: IGroupeInformationsComponentProps) {
    const groupe: IGroupe = props.groupe;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{groupe.nom || "-"}</p>
                    </div>
                </div>

                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Automatique</p>
                        <p className="value">{groupe.automatique ? CommonHelper.getFormatedOuiNon(groupe.automatique) : "-"}</p>
                    </div>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Types automatique</p>
                        <p className="value">{groupe.types && groupe.types.length ? GroupesHelper.getFormated(groupe.types) : "-"}</p>
                    </div>
                </div>
            </div>

            <div className="columns">
                <div className="column -size-2">
                    <div className="informations-block">
                        <p className="label">Commentaires</p>
                        {
                            groupe.commentaires &&
                            <div className="informations-text">
                                { ReactHtmlParser(groupe.commentaires) }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
