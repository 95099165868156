import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import StatistiquesImportDAPFormComponent from "src/Components/Forms/Inspections/Statistiques/StatistiquesImportDAPForm.component";
import {IStatistiquesImportDAPFormData, StatistiquesService} from "src/Services/StatistiquesService.service";


type IStatistiquesImportProps = {
}


const StatistiquesImport = (props:IStatistiquesImportProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const statistiquesService: StatistiquesService = new StatistiquesService();



    useEffect( ()=> {
        setLoaded(true);
    }, []);


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {any} response
     */
    const onSuccessSubmitForm = (response: any): void => {
        history.push(AppConstants.pathStatistiques);
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathStatistiques);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Importer des données DAP" icon="icon-import" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
                    <StatistiquesImportDAPFormComponent submitMethod={(formData: IStatistiquesImportDAPFormData)=>statistiquesService.importDAP(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />
                </>
            }
        </LayoutComponent>
    )
}

export default StatistiquesImport;
