import {IOffice} from "src/Models/Office.model";
import CommonHelper from "src/Helpers/Common.helper";
import FileHelper from "src/Helpers/File.helper";
import {useEffect, useState} from "react";

type IOfficeCertificationsLabelsHorairesInfosComponentProps = {
    office: IOffice,
}

export default function OfficeCertificationsLabelsHorairesInfosComponent(props: IOfficeCertificationsLabelsHorairesInfosComponentProps) {
    const office: IOffice = props.office;
    const [labelsNames, setLabelsNames] = useState<string[]>([]);
    const [labelsFiles, setLabelsFiles] = useState<string[]>([]);
    const [certificationsNames, setCertificationsNames] = useState<string[]>([]);
    const [certificationsFiles, setCertificationsFiles] = useState<string[]>([]);



    useEffect(() => {
        let newCertificationsNames: string[] = [];
        let newCertificationsFiles: string[] = [];
        office.certifications.forEach( (c) => {
            newCertificationsNames.push(c.nom);
            newCertificationsFiles.push(c.fichier);
        });
        setCertificationsNames(newCertificationsNames);
        setCertificationsFiles(newCertificationsFiles);


        let newLabelsNames: string [] = [];
        let newLabelsFiles: string [] = [];
        office.labels.forEach( (l) => {
            newLabelsNames.push(l.nom);
            newLabelsFiles.push(l.fichier);
        });
        setLabelsNames(newLabelsNames);
        setLabelsFiles(newLabelsFiles);

    }, [office]);



    return (
        <div className="informations-wrapper">
            <div className="columns">

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Certifications ISO</p>
                        <div className="informations-image-wrapper -labels">
                            {
                                certificationsFiles && certificationsFiles.map((fichier: string, index: number) =>
                                    <figure className="informations-image -certification" key={index}>
                                        <img src={FileHelper.getImageUrlFromSize(fichier,"certification")} alt="" className="image" />
                                    </figure>
                                )
                            }
                        </div>
                        {
                            certificationsNames &&
                                <>{certificationsNames.join(', ')}</>
                        }
                        {
                            !certificationsNames && !certificationsFiles &&
                            <>-</>
                        }
                    </div>

                    <div className="informations-block">
                        <p className="label">Labels</p>

                        <div className="informations-image-wrapper -labels">
                            {
                                labelsFiles && labelsFiles.map((fichier: string, index: number) =>
                                    <figure className="informations-image -certification" key={index}>
                                        <img src={FileHelper.getImageUrlFromSize(fichier,"certification")} alt="" className="image" />
                                    </figure>
                                )
                            }
                        </div>
                        {
                            labelsNames &&
                                <>{labelsNames.join(', ')}</>
                        }
                        {
                            (!labelsNames && !labelsFiles) &&
                                <>-</>
                        }
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Borne électrique</p>
                        <p className="value">{office.borneElectrique ? CommonHelper.getFormatedOuiNon(office.borneElectrique) : "-"}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}
