import {IOption} from "../Components/Fields/Select.field.component";
import {ISignature} from "../Models/Signature.model";

export default class SignaturesHelper{


    /**
     * Permet de formater une liste de signatures pour les selects
     *
     * @param {ISignature[]} signatures
     * @returns {IOption[]}
     */
    static formatListForOptions(signatures: ISignature[]): IOption[]{
        if(!signatures || !signatures.length) return [];

        const options: IOption[] = [];
        for(let signature of signatures){
            const option: IOption = {
                value: signature.id.toString(),
                label: signature.nom
            }

            options.push(option)
        }

        return options;
    }
}
