import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IFormationGetApiResponse, IFormationFormData} from "src/Services/Formations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IFormation} from "src/Models/Formation.model";
import CommonHelper from "src/Helpers/Common.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type FormationPrecisionsFormProps = {
    formation?: IFormation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IFormationFormData) => Promise<IFormationGetApiResponse>,
    onSuccessSubmit: (response: IFormationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IFormationGetApiResponse) => void,
    onCancel: () => void,
}

export default function FormationPrecisionsForm(props: FormationPrecisionsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const {formation} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'radio',
                fieldName: "covoiturage",
                label: "Covoiturage",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.covoiturage ? formation.covoiturage : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'radio',
                fieldName: "repas",
                label: "Repas",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.repas ? formation.repas : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'text',
                fieldName: "repasNom",
                label: "Nom du repas",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.repasNom ? formation.repasNom : "",
                condition: {
                    fieldName: "repas",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "repasLieu",
                label: "Lieu du repas",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.repasLieu ? formation.repasLieu : "",
                condition: {
                    fieldName: "repas",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "repasHeure",
                label: "Heure du repas",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.repasHeure ? formation.repasHeure : "",
                condition: {
                    fieldName: "repas",
                    value: "oui"
                }
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'radio',
                fieldName: "cocktail",
                label: "Cocktail",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.cocktail ? formation.cocktail : "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'text',
                fieldName: "cocktailNom",
                label: "Nom du cocktail",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.cocktailNom ? formation.cocktailNom : "",
                condition: {
                    fieldName: "cocktail",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "cocktailLieu",
                label: "Lieu du cocktail",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.cocktailLieu ? formation.cocktailLieu : "",
                condition: {
                    fieldName: "cocktail",
                    value: "oui"
                }
            },
            {
                type: 'text',
                fieldName: "cocktailHeure",
                label: "Heure du cocktail",
                placeholder: "",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.cocktailHeure ? formation.cocktailHeure : "",
                condition: {
                    fieldName: "cocktail",
                    value: "oui"
                }
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);

    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: IFormationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }



            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La formation a bien été mise à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
