import ApiService, {IApiCustomResponse} from "./Api.service";
import {ITribunalJudiciaire} from "../Models/TribunalJudiciaire.model";

export type IParametresGetFormData = {
    cpVille: string,
}

export type ITribunalGetApiResponse = IApiCustomResponse & {
    datas: {
        tribunal: ITribunalJudiciaire
    }
}


export class AdressesService extends ApiService {

    /**
     * Permet de récupérer le tribunal pour un code Postal
     *
     * @param {string} module
     * @param {IParametresGetFormData} formDatas
     * @returns {Promise<IParametresGetApiResponse>}
     */
    public getTribunalForCodePostal(formDatas: IParametresGetFormData): Promise<ITribunalGetApiResponse>{
        const url: string = 'api/tribunal';

        return this.doApiCall(url, 'GET', formDatas);
    }
}




