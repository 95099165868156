import React from "react";

const SearchResultHeaderComponent = (props : {
    icon: string,
    text: string,
    children?: React.ReactNode
}) => {
    return <div className="search-result-header">
        <i className={`icon ${props.icon}`}></i>
        <span className="sep"></span>
        <span className="text">{props.text}</span>

        <div className="more">
            {props.children}
        </div>
    </div>
};

export default SearchResultHeaderComponent;