import React, {useEffect, useState} from "react";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {AppConstants} from "src/Constants/AppConstants";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import {useHistory, useLocation} from "react-router";
import {CotisationsService, ICotisationsSaisieNotaireGetApiResponse, ICotisationsSaisieNotaireGetFormData} from "src/Services/Cotisations.service";
import {ICotisationsNotaire} from "src/Models/CotisationsNotaire.model";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import YearsListComponent from "src/Components/Cotisations/YearsList.component";
import moment, {Moment} from "moment";
import CotisationSaisieAssuranceMaladieNotaireTableComponent from "src/Components/Cotisations/CotisationSaisieAssuranceMaladieNotaireTable.component";
import CotisationSaisieDecesInvaliditeNotairesComponent from "src/Components/Cotisations/CotisationSaisieDecesInvaliditeNotaires.component";
import CotisationSaisieCNAFNotairesComponent from "src/Components/Cotisations/CotisationSaisieCNAFNotaires.component";
import CotisationSaisieFraisTraitementNotairesComponent from "src/Components/Cotisations/CotisationSaisieFraisTraitementNotaires.component";
import CotisationSaisieRecapitulatifNotairesComponent from "src/Components/Cotisations/CotisationSaisieRecapitulatifNotaires.component";
import PaginationComponent from "../../Components/Pagination/Pagination.component";
import AsideCotisationsOfficesComponent from "../../Components/Aside/AsideCotisationsOffices.component";
import CotisationsNotairesExportComponent from "../../Components/Cotisations/CotisationsNotairesExport.component";
import FormationCourrierForm from "../../Components/Forms/Formations/Formations/FormationCourrierForm.component";
import AsideFormationComponent from "../../Components/Aside/AsideFormations.component";
import CotisationsNotairesCourrierForm
    from "../../Components/Forms/Cotisations/CotisationsNotairesCourrierForm.component";
import LoadingComponent from "../../Components/Loading/Loading.component";


type ICotisationsSaisieNotairesScreenProps = {
};

export default function CotisationsSaisieNotairesScreen(props: ICotisationsSaisieNotairesScreenProps){
    const currentDate:Moment = moment();

    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [cotisationsNotairesList, setCotisationsNotairesList] = useState<ICotisationsNotaire[]>([]);
    const [cotisationsYears, setCotisationsYears] = useState<number[]>(null);
    const [currentYear, setCurrentYear] = useState<number>(parseInt(currentDate.format("YYYY")));

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathCotisationsSaisieNotaires);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [editingStatus, setEditingStatus] = useState<boolean>(false);
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState<string>("assuranceMaladie");



    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Cotisations",  link: AppConstants.pathMosaique + '#cotisations'},
            { text: "Offices", link: AppConstants.pathCotisationsListeOffices },
            { text: "Saisie Notaires" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
        const newCotisationsYears: number[] = [];
        for(let i = 0, j = 5; i < j; i++){
            const newYear: number = currentYear - i;
            newCotisationsYears.push(newYear);
        }

        setCotisationsYears(newCotisationsYears)
    }, []);


    useEffect(() => {
        const params: any = location.search;

        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        defaultFilters.year = currentYear;

        updateFilters(defaultFilters);
    }, [currentYear])


    useEffect(()=>{
        if(!filters) return null;
        setEditingStatus(false);

        launchFilteredSearch();
    },[filters]);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[currentTab]);




    /**
     * Récupération des sinistres
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const cotisationsService: CotisationsService = new CotisationsService();
        filters.currentTab = currentTab;
        setCotisationsNotairesList([]);
        setPaginationLinks([]);


        cotisationsService.getCotisationsForNotaires(filters as ICotisationsSaisieNotaireGetFormData).then((response: ICotisationsSaisieNotaireGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newCotisationsNotairesList = response.datas.pagination.data;
                setCotisationsNotairesList(newCotisationsNotairesList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }



    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        launchFilteredSearch();
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
    };

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Cotisations"
                    subText="Saisie Notaires"
                    icon="icon-cotisations"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />

                <YearsListComponent years={cotisationsYears} setCurrentYear={setCurrentYear} currentYear={currentYear} />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent onClickTabCallback={(slug) => { setCurrentTab(slug);}}>
                            <TabPanelComponent label="Assurance Maladie" icon="icon-assurance-maladie" slug="assuranceMaladie">
                                <CotisationSaisieAssuranceMaladieNotaireTableComponent
                                    cotisationsNotaire={cotisationsNotairesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieNotaire" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Décès invalidité" icon="icon-assurance-maladie" slug="decesInvalidite">
                                <CotisationSaisieDecesInvaliditeNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieNotaire" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Adhésion CNAF" icon="icon-cnaf" slug="adhesionCNAF">
                                <CotisationSaisieCNAFNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieNotaire" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Pénalités de retard" icon="icon-frais-traitement" slug="fraisTraitement">
                                <CotisationSaisieFraisTraitementNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieNotaire" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>

                            <TabPanelComponent label="Récapitulatif" icon="icon-recapitulatif" slug="recapitulatif">
                                <CotisationSaisieRecapitulatifNotairesComponent
                                    cotisationsNotaire={cotisationsNotairesList}
                                    currentYear={currentYear}
                                    onSuccessSubmit={onSuccessSubmitForm}
                                    onCancel={onCancelForm}
                                    filters={filters}
                                    setOrder={setOrder}
                                    editingStatus={editingStatus}
                                    setEditingStatus={setEditingStatus}
                                />

                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                <AsideCotisationsOfficesComponent type="list" sousType="saisieNotaire" exportCallback={() => setShowExport(true)} filters={filters} />
                            </TabPanelComponent>


                            <TabPanelComponent label="Courriers" icon="icon-courrier" slug="courriers">
                                <div className="fiche-content -right-gutter">

                                    <div className="g-content">
                                        <CotisationsNotairesCourrierForm />
                                    </div>

                                    <AsideCotisationsOfficesComponent type="list" sousType="saisieNotaire" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }

            </div>

            <CotisationsNotairesExportComponent cotisationsYears={cotisationsYears} setShowExport={() => setShowExport(false)} showExport={showExport} />
        </LayoutComponent>
    )
}
