import React, {useEffect, useState} from "react";
import {IStatistiques} from "../../../Models/Statistiques.model";
import {Bar, Line} from "react-chartjs-2/dist";
import CommonHelper from "../../../Helpers/Common.helper";

const StatistiquesGraphiques = (props: {
    stats: IStatistiques[]
}) => {

    const [dataEvolution, setDataEvolution] = useState(null);
    const [dataVentilation, setDataVentilation] = useState(null);

    useEffect(() => {
        const newDataEvolution: any = {
            labels: [],
            datasets: [
                {
                    label: "Produits Courants",
                    data: [],
                    backgroundColor: "#171F46",
                    borderColor: "#171F46",
                },
                {
                    label: "Charges courantes",
                    data: [],
                    backgroundColor: "#72CBE6",
                    borderColor: "#72CBE6",
                },
                {
                    label: "Résultat courant",
                    data: [],
                    backgroundColor: "#DEB578",
                    borderColor: "#DEB578",
                },
            ]
        };
        const newDataVentilation: any = {
            labels: [],
            datasets: [
                {
                    label: "Honoraires de négociation",
                    data: [],
                    backgroundColor: "#F48FB1"
                },
                {
                    label: "Honoraires de transaction",
                    data: [],
                    backgroundColor: "#AB47BC"
                },
                {
                    label: "Honoraires du secteur commercial",
                    data: [],
                    backgroundColor: "#7986CB"
                },
                {
                    label: "Honoraires de consultation détachables",
                    data: [],
                    backgroundColor: "#66BB6A"
                }
            ]
        };
        props.stats.map((s: IStatistiques, sIndex: number) => {
            const officeCode = CommonHelper.getObjectValueFromPath(s, "data.dap.code_office_csn");
            const currentYear = CommonHelper.getObjectValueFromPath(s, "dapYear");
            if (newDataEvolution.labels.indexOf(currentYear) === -1) {
                newDataEvolution.labels.push(currentYear)
            }
            if (newDataVentilation.labels.indexOf(currentYear) === -1) {
                newDataVentilation.labels.push(currentYear)
            }


            const hn = CommonHelper.getObjectValueFromPath(s, `data.office.${officeCode}.total_des_honoraires_de_negociation`);
            const ht = CommonHelper.getObjectValueFromPath(s, `data.office.${officeCode}.honoraires_de_transaction`);
            const h1 = CommonHelper.getObjectValueFromPath(s, `data.office.${officeCode}.sous_total_h1`);
            const h2 = CommonHelper.getObjectValueFromPath(s, `data.office.${officeCode}.sous_total_h2`);
            const honoraires = hn + ht + h1 + h2;

            // Honoraires  de négociation
            newDataVentilation.datasets[0].data.push(((hn * 100) / honoraires).toFixed(0));
            // Honoraires de transaction
            newDataVentilation.datasets[1].data.push(((ht * 100) / honoraires).toFixed(0));
            // Honoraires du secteur commercial
            newDataVentilation.datasets[2].data.push(((h1 * 100) / honoraires).toFixed(0));
            // Honoraires de consultation détachables
            newDataVentilation.datasets[3].data.push(((h2 * 100) / honoraires).toFixed(0));

            // Produits courants
            newDataEvolution.datasets[0].data.push(CommonHelper.getObjectValueFromPath(s, `produitCourant`));
            // Charges courantes
            newDataEvolution.datasets[1].data.push(CommonHelper.getObjectValueFromPath(s, `data.office.${officeCode}.charges_courantes_iiiv`));
            // Résultat courant
            newDataEvolution.datasets[2].data.push(CommonHelper.getObjectValueFromPath(s, `data.office.${officeCode}.3resultat_courant_i_iiiii_iv`));

        });

        setDataEvolution(newDataEvolution);
        setDataVentilation(newDataVentilation);
    }, [props.stats]);

    return <div className="statistiques-graphiques">

        {
            dataEvolution &&
            <div className="graphique">
                <h2 className="title">Evolution sur 5 ans</h2>
                <Line data={dataEvolution} options={{plugins: {legend: {position: "bottom"}}}}/>
            </div>
        }

        {
            dataVentilation &&
            <div className="graphique">
                <h2 className="title">Ventilation des honoraires</h2>
                <Bar data={dataVentilation} options={{
                    plugins: {
                        legend:
                            {position: "bottom"}
                    },
                    scales: {
                        yAxis:{
                            min: 0,
                            max: 100
                        }
                    }
                }}/>
            </div>
        }
    </div>
};

export default StatistiquesGraphiques;
