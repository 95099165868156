import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import UseLabels from "src//Hooks/UseLabels";
import {ILabel} from "src//Models/Label.model";
import AsideLabelsComponent from "src//Components/Aside/AsideLabels.component";
import {ILabelInformationsFormData, LabelsService} from "src//Services/Labels.service";
import LabelInformationsComponent from "src/Components/Annuaire/Labels/LabelInformations.component";
import LabelsInformationsFormComponent from "src/Components/Forms/Annuaire/Labels/LabelsInformationsForm.component";


type ILabelFicheScreenProps = {
    lid: string
}
export default function LabelFicheScreen(props: ILabelFicheScreenProps){
    const informationAccordionRef = useRef(null);
    const [getLabelById] = UseLabels();
    const [label, setLabel] = useState<ILabel>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const labelsServices: LabelsService = new LabelsService();
    const history = useHistory();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!label) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Labels", link: AppConstants.pathLabels},
            {text: label.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [label, loaded])



    useEffect(() => {
        if( !props.lid ){
            history.push(AppConstants.pathLabels);
        }

        getLabel();
    }, [props.lid])


    const getLabel = (): void => {
        getLabelById(props.lid, (label: ILabel) => {
            setLabel(label);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getLabel();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationAccordionRef && informationAccordionRef.current) ){
            informationAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent
                        breadcrumbLinks={breadcrumbLinks}
                        text={label.nom}
                        icon="icon-labels"
                        modificators=""
                        onSearch={null}
                    />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={informationAccordionRef}
                                    title="Informations"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="label"
                                    contentId={label.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<LabelsInformationsFormComponent label={label} submitMethod={(formData: ILabelInformationsFormData)=>labelsServices.update(props.lid, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<LabelInformationsComponent label={label} />}
                                />

                                <AsideLabelsComponent type='fiche' label={label} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
