import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiFieldsErrorMessages, IApiPaginationLink} from "src/Services/Api.service";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import useListFilters from "src/Hooks/FiltersHandler";
import SearchBlockComponent, {IFormSearchData} from "src/Components/Search/SearchBlock.component";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import {Link, useLocation} from "react-router-dom";
import LoadingComponent from "src/Components/Loading/Loading.component";
import SinistresHelper from "../../Helpers/Sinistres.helper";
import DateHelper from "../../Helpers/Date.helper";
import {ISinistre} from "../../Models/Sinistre.model";
import {ICtrc} from "../../Models/Ctrc.model";
import useCtrcs from "../../Hooks/useCtrcs";
import {CtrcsService, ICtrcGetAllApiResponse, ICtrcSinistresFormData} from "../../Services/Ctrcs.service";
import {ISinistresGetApiResponse, ISinistresGetFormData, SinistresService} from "../../Services/Sinistres.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import SelectFieldComponent from "../../Components/Fields/Select.field.component";
import CtrcsHelper from "../../Helpers/Ctrcs.helper";
import moment, {Moment} from "moment/moment";


const CtrcRattachementSinistres = (props: {
    cid: string
}) => {
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, setPath, defaultFilters] = useListFilters(`/correspondances/${props.cid}/invites`, false);
    const [sinistres, setSinistres] = useState<ISinistre[]>([]);
    const [ctrc, setCtrc] = useState<ICtrc>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.SINISTRES_LISTING);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [keyword, setKeyword] = useState<string>("");
    const [fieldsErrors, setFieldsErrors] = useState<IApiFieldsErrorMessages>(null);

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const [addedSinistres, setAddedSinistres] = useState<number[]>([]);
    const [deletedSinistres, setDeletedSinistres] = useState<number[]>([]);

    const [ctrcs, setCtrcs] = useState<ICtrc[]>([]);
    const [ctrcsLoaded, setCTRCsLoaded] = useState<boolean>(false);

    const history = useHistory();
    const {hash} = useLocation();
    const [getCtrcById] = useCtrcs();

    const ctrcsService: CtrcsService = new CtrcsService();
    const sinistresService: SinistresService = new SinistresService();

    const currentDate: Moment = moment();
    const currentYear: string = currentDate.format("YYYY");
    const previousYear: string = currentDate.subtract(1, "years").format("YYYY");

    const filtresParAnnees = [
        {value: currentYear, label: currentYear},
        {value: previousYear, label: previousYear},
        {value: 'before', label: 'Avant'},
    ];

    /**
     * Récupération des CTRCs (pour le filtre)
     */
    useEffect(() => {
        const ctrcsService: CtrcsService = new CtrcsService();
        ctrcsService.getAll().then((response: ICtrcGetAllApiResponse) => {
            if (response && response.datas && response.datas.ctrcs) {
                setCtrcs(response.datas.ctrcs);
                setCTRCsLoaded(true);
            }
        });
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    useEffect(() => {
        updateFilters(defaultFilters);

        if (!props.cid) {
            history.push(AppConstants.path403);
        }
        getCTCR();
    }, [props.cid]);


    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setIsLoading(true);

        if (activeTab === TabsSlugs.SINISTRES_LISTING) {
            sinistresService.getSinistres(filters as ISinistresGetFormData).then((response: ISinistresGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // Sinistres
                    const currentSinistresList: ISinistre[] = response.datas.pagination.data;
                    setSinistres(currentSinistresList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);
                    setIsLoading(false);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }
    };


    /**
     * Récupération du CTRC selon l'cid
     */
    const getCTCR = (): void => {
        getCtrcById(props.cid, (ctrc: ICtrc) => {
            setCtrc(ctrc);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Lancement de la recherche par mot clé
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData) => {
        const searchedKeyword = formDatas.keyword as string;
        setKeyword(searchedKeyword);
        updateFilters({keyword: searchedKeyword});
    };


    /**
     * Indique si un sinistre est déjà rattaché au CTRC
     * @param {ISinistre} sinistre
     * @param {ISinistre[]} sinistres
     * @returns {boolean}
     */
    const isAlreadyAttached = (sinistre: ISinistre, sinistres: ISinistre[]): boolean => {
        return sinistres.findIndex((i) => {
            const entity = i;
            return entity && entity.id === sinistre.id;
        }) !== -1;
    };

    /**
     * Indique si un sinistre est ajouté au CTRC
     * @param {ISinistre} sinistre
     * @returns {boolean}
     */
    const isAdded = (sinistre: ISinistre): boolean => {
        if (activeTab === TabsSlugs.SINISTRES_LISTING) {
            return addedSinistres.findIndex((s) => s === sinistre.id) !== -1;
        }
        return false;
    };


    /**
     * Indique si un sinistre est déjà supprimé
     *
     * @param {ISinistre} sinistre
     * @returns {boolean}
     */
    const isDeleted = (sinistre: ISinistre): boolean => {
        if (activeTab === TabsSlugs.SINISTRES_LISTING) {
            return deletedSinistres.findIndex((s) => s === sinistre.id) !== -1;
        }
        return false;
    };


    /**
     * Ajoute/retire un sinistre à la liste des sinistres ajoutés
     * @param {ISinistre} sinistre
     * @returns {boolean}
     */
    const toggleAdded = (sinistre: ISinistre): boolean => {
        if (activeTab === TabsSlugs.SINISTRES_LISTING) {
            let newSinistres: number[];
            if (isAdded(sinistre)) {
                newSinistres = addedSinistres.filter((s) => s !== sinistre.id);
            } else {
                newSinistres = [...addedSinistres];
                newSinistres.push(sinistre.id);
            }
            setAddedSinistres(newSinistres);
        }
        return false;
    };


    /**
     * Suppression d'un sinistre déjà liée à ce CTRC
     *
     * @param {ISinistre} sinistre
     */
    const toggleDeleted = (sinistre: ISinistre): void => {
        if (activeTab === TabsSlugs.SINISTRES_LISTING) {
            let newSinistres: number[];
            if (isDeleted(sinistre)) {
                newSinistres = deletedSinistres.filter((s) => s !== sinistre.id);
            } else {
                newSinistres = [...deletedSinistres];
                newSinistres.push(sinistre.id);
            }
            setDeletedSinistres(newSinistres);
        }
    }


    /**
     * Soumission des inscriptions
     */
    const onSubmit = () => {
        const datas: ICtrcSinistresFormData = {
            sinistres: addedSinistres,
            sinistresToDelete: deletedSinistres,
        };

        ctrcsService.updateSinistresRattaches(props.cid, datas).then((r) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les sinistres ont bien été rattaché.",
                type: "success"
            });
            goToSinistre();
        }, (r) => {
            if (r.fieldsMessages) {
                setFieldsErrors(r.fieldsMessages);
            }
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue.",
                type: "danger"
            });
        });
    };

    /**
     * Redirection vers le sinistre
     */
    const goToSinistre = () => {
        let url: string = AppConstants.pathCtrcs + '/' + props.cid;

        if (hash) {
            url += '/' + hash;
        }

        history.push(url);
    };

    /**
     * Changement d'onglets
     *
     * @param {string} tab
     */
    const onTabClick = (tab: string) => {
        setActiveTab(tab);
        updateFilters({}, true);
        setKeyword("");
    };


    return (
        <>
            {
                ctrc && loaded &&
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                                 contentModificators={`-bg-white -no-flex`}>
                    <PageHeaderComponent text="CTRC" subText={"Rattacher des sinistres"}
                                         icon="icon-sinistres-add"
                                         modificators={`-in-fullscreen -border-full-screen`}/>

                    <TabsContainerComponent onClickTabCallback={onTabClick}>
                        <TabPanelComponent label="Sinistres" icon="icon-sinistres" slug={TabsSlugs.SINISTRES_LISTING}>
                            <div className="list-filters -margin-top">
                                <SearchBlockComponent placeholder={"Recherche"} keyword={keyword}
                                                      onSubmit={onSearch}
                                                      modificators="-searchpage -list-header"
                                                      searchAuto={true}/>

                                {ctrcsLoaded &&
                                    <SelectFieldComponent fieldName="ctrc"
                                                          options={CtrcsHelper.getFormatedListForOptions(ctrcs, true)}
                                                          errors={null}
                                                          hideSearch={false}
                                                          modificators="-on-white -no-margin-b -ms-auto -me-20"
                                                          label="Filtrer par CTRC"
                                                          oldValue={filters.ctrc ? filters.ctrc.toString() : ""}
                                                          onChange={(value: string) => updateFilters({
                                                              ctrc: value,
                                                              advanced: 1
                                                          })}
                                    />
                                }

                                <SelectFieldComponent fieldName="annee"
                                                      options={filtresParAnnees}
                                                      errors={null}
                                                      hideSearch={true}
                                                      modificators="-on-white"
                                                      label="Filtrer par année"
                                                      oldValue={filters.annee ? filters.annee.toString() : ""}
                                                      onChange={(value: string) => updateFilters({
                                                          annee: value,
                                                          advanced: 1
                                                      })}
                                />
                            </div>
                            {
                                isLoading &&
                                <LoadingComponent></LoadingComponent>
                            }
                            {
                                !isLoading &&
                                <>
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "notairesAssignesOrdered", label: "Notaire(s) mis en cause"},
                                                {slug: "dateOuverture", label: "Année d’ouverture"},
                                                {slug: "libelle", label: "Libellé du dossier"},
                                                {slug: "rapporteur", label: "Rapporteur"},
                                                {slug: "ctrc", label: "CTRC (actuel)"},
                                                {slug: "actions", label: "Actions"}
                                            ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                <span className="link"><span className="text">{item.label}</span></span>
                                            </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            sinistres && sinistres.map((sinistre: ISinistre, index: number) => {
                                                const attached = isAlreadyAttached(sinistre, ctrc.sinistres);
                                                return <tr key={index} className={`tr`}>
                                                    <td className="td -bold">
                                                        <Link className="g-link -primary -underline-on-hover -bold"
                                                              target="_blank"
                                                              to={`${AppConstants.pathSinistres}/${sinistre.id}`}
                                                              onClick={(e) => e.stopPropagation()}>
                                                            {sinistre.notairesAssignes ? SinistresHelper.getFormatedNotaires(sinistre.notairesAssignes) : ''}
                                                        </Link>
                                                    </td>
                                                    <td className="td">
                                                        {sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : ''}
                                                    </td>
                                                    <td className="td">
                                                        {sinistre.libelle ?? ''}
                                                    </td>
                                                    <td className="td">
                                                        {sinistre.rapporteur ? SinistresHelper.getFormatedNotaires([sinistre.rapporteur]) : ''}
                                                    </td>
                                                    <td className="td">
                                                        {sinistre.ctrcs.length > 0 ? SinistresHelper.getFormatedCtrcs(sinistre.ctrcs) : ''}
                                                    </td>
                                                    <td className="td -center" width={80}>
                                                        {!attached && isAdded(sinistre) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleAdded(sinistre)}/>}
                                                        {!attached && !isAdded(sinistre) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleAdded(sinistre)}>Ajouter</button>}
                                                        {attached && isDeleted(sinistre) &&
                                                            <button className="g-button -is-link"
                                                                    onClick={() => toggleDeleted(sinistre)}>Ajouter</button>}
                                                        {attached && !isDeleted(sinistre) &&
                                                            <button className="g-button -delete-button icon-remove"
                                                                    onClick={() => toggleDeleted(sinistre)}/>}
                                                    </td>
                                                </tr>;
                                            })
                                        }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </>
                            }
                        </TabPanelComponent>
                    </TabsContainerComponent>


                    <div className="form-actions -outside-right">
                        {showConfirmation && <ConfirmationComponent text={"Quitter sans enregistrer les inscriptions ?"}
                                                                    onConfirm={() => goToSinistre()}
                                                                    onCancel={() => setShowConfirmation(false)}/>}
                        <button className="g-button -secondary-border-only" onClick={() => setShowConfirmation(true)}>
                            Quitter sans enregistrer
                        </button>
                        <button className="g-button -secondary" onClick={onSubmit}>
                            Enregistrer
                        </button>
                    </div>
                </LayoutComponent>
            }
        </>
    )
};

export default CtrcRattachementSinistres;
