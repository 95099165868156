import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICoordonnee} from "src/Models/Coordonnee.model";
import {CoordonneeConstants} from "src/Constants/CoordonneeConstants";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IReclamationPlaignant} from "src/Models/ReclamationPlaignant.model";
import ReclamationsHelper from "src/Helpers/Reclamations.helper";
import {IReclamationCoordonneesPlaignantFormData} from "src/Services/Reclamations.service";


type ReclamationCoordonneesPlaignantsFormComponentProps = {
    coordonnees?: ICoordonnee[],
    plaignants?: IReclamationPlaignant[],
    isOnCreationStep?: boolean,

    submitMethod: (formData: IReclamationCoordonneesPlaignantFormData) => Promise<IApiCustomResponse>,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onSuccessSubmitNext?: (response: IApiCustomResponse) => void,
    onCancel: () => void,
}

export default function ReclamationCoordonneesPlaignantsFormComponent(props: ReclamationCoordonneesPlaignantsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const coordonnees: ICoordonnee[] = props.coordonnees;
        const plaignants: IReclamationPlaignant[] = props.plaignants;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'repeater',
                fieldName: "coordonnees",
                label: "Coordonnées",
                oldRepeatableValues: coordonnees as FormComponentFormData,
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'selectMultiple',
                                fieldName: "plaignantId",

                                label: "Plaignant",
                                options: ReclamationsHelper.formatListForOptions(plaignants),
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                shouldAddClassWithCountToParent: true,
                                required: true,

                                oldValue: "",
                            },
                            {
                                type: 'select',
                                fieldName: "type",

                                label: "Type",
                                options: CoordonneeConstants.typeOptions,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'tel',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : ' -skip-line-1',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "telephone",
                                }
                            },
                            {
                                type: 'email',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : ' -skip-line-1',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "email",
                                }
                            },
                            {
                                type: 'tel',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : ' -skip-line-1',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "fax",
                                }
                            },
                            {
                                type: 'url',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : ' -skip-line-1',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "url",
                                }
                            }
                        ],
                    }
                ],
                required: false,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);

    }, [props.coordonnees]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IReclamationCoordonneesPlaignantFormData).then((response: IApiCustomResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les informations des coordonnées ont bien été mises à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des coordonnées.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorMessages={errorMessages}/>
        </div>
    )
}
