import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";

type ICotisationsOfficeRecapConventionAssuranceCyberComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapConventionAssuranceCyberComponent(props: ICotisationsOfficeRecapConventionAssuranceCyberComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant Cyber 1</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantCyber1, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant Cyber 2</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantCyber2, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Total Cyber</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantCyber1 + cotisation.montantCyber2, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
