import {IFormation} from "src/Models/Formation.model";
import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages, IFileDownloadApiResponse} from "src/Services/Api.service";
import CommonHelper from "src/Helpers/Common.helper";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypesGetApiResponse} from "src/Services/Courriers.service";
import CourriersHelper from "src/Helpers/Courriers.helper";
import {FormationsService, IFormationCourrierFormData,} from "src/Services/Formations.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import UseFileDownload from "src/Hooks/UseFileDownload";
import {ICorrespondanceCourrierFormData} from "../../../../Services/Correspondances.service";

type IFormationCourrierFormProps = {
    formation?: IFormation,
}

export default function FormationCourrierForm(props: IFormationCourrierFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const formationsService: FormationsService = new FormationsService();

    const {handleFileDownload} = UseFileDownload();

    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();

        courriersService.getCourriersTypesForModule('formations').then((response: ICourriersTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                setCourriersTypes(response.datas.types);
            }
        });
    }, [])




    useEffect(()=> {
        if(!courriersTypes || !courriersTypes.length) return;

        const {formation} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "numFacture",

                label: "Numéro de facture",
                placeholder: "Numéro de facture",
                required: false,
                modificators: "-on-white",
                oldValue: formation && formation.numFactureInitial ? formation.numFactureInitial : "",
            },
            {
                type: 'select',
                fieldName: "type",

                label: "Type de courrier",
                placeholder: "Type de courrier",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: courriersTypes ? CourriersHelper.formatCourriersTypesForOptions(courriersTypes) : []
            },
        ];

        for(let type of courriersTypes){
            let courrierContenu = "";
            let mailSujet = "";
            let mailContenu = "";

            if(type.slug === "formation-facture"){
                courrierContenu = formation.saveCourrierFactureContenu || "";
                mailSujet = formation.saveFactureSubjectMail || "";
                mailContenu = formation.saveFactureMail || "";
            }
            else if (type.slug === "formation-attestation"){
                courrierContenu = formation.saveCourrierAttestationContenu || "";
                mailSujet = formation.saveAttestationSubjectMail || "";
                mailContenu = formation.saveAttestationMail || "";
            }
            else if (type.slug === "formation-personnalisable"){
                courrierContenu = formation.saveCourrierPersonnalisableContenu || "";
                mailSujet = formation.savePersonnalisableSubjectMail || "";
                mailContenu = formation.savePersonnalisableMail || "";
            }

            formElementsColumn1.push(
                {
                    type: 'text',
                    fieldName: "subjectMail",
                    key: "subjectMail" + type.id.toString(),

                    label: "Sujet du mail",
                    placeholder: "Sujet de l'email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: mailSujet !== "" ? mailSujet : (type.sujet ? type.sujet : ""),

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );

            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenu",

                    label: "Contenu",
                    placeholder: "Contenu",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: courrierContenu ? courrierContenu : (type.modele ? type.modele : ""),
                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );

            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenuMail",

                    label: "Contenu Email",
                    placeholder: "Contenu Email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: mailContenu ? mailContenu : (type.contenuMail ? type.contenuMail : ""),

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
        }

        formElementsColumn1.push(
            {
                type: 'radio',
                fieldName: "afficherEntete",

                label: "Afficher l'entête (et le pied de page)",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            }
        );

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Sauvegarder",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSave(formData,false, onActionEnded);}
            },
            {
                label: "Télécharger",
                modificators: "-primary",
                icon: "icon-download",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onDownload(formData,onActionEnded)}
            },
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false,onActionEnded)}
            },
            {
                label: "Envoyer un test",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, true,onActionEnded)}
            }
        ];
        setFormActions(currentFormActions);

    }, [courriersTypes]);


    /**
     * Gestion du téléchargement du courrier
     * @param {FormComponentFormData} formData
     * @param {() => void} onActionEnded
     */
    const onDownload = (formData: FormComponentFormData, onActionEnded?: ()=>void): void => {
        formationsService.downloadCourrier(props.formation.id, formData as IFormationCourrierFormData).then((response: IFileDownloadApiResponse) => {
            if(onActionEnded) onActionEnded();

            if(response && response.datas && response.datas.file){
                handleFileDownload(response.datas.file,true);
            }


        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: ()=>void): void => {
        const formDataUpdated: IFormationCourrierFormData = {
            ...formData as IFormationCourrierFormData,
            isDebug: isDebug ?? false
        }

        formationsService.sendCourrier(props.formation.id, formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    }


    /**
     * Gestion de l'enregistrement du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     * @param {() => void} onActionEnded
     */
    const onSave = (formData: FormComponentFormData, isDebug: boolean, onActionEnded?: ()=>void): void => {
        const formDataUpdated: ICorrespondanceCourrierFormData = {
            ...formData as ICorrespondanceCourrierFormData,
            isDebug: isDebug ?? false
        }

        formationsService.saveCourrier(props.formation.id, formDataUpdated).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le courrier a bien été enregistré.",
                type: "success"
            });
        }).catch((error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'enregistrement du courrier'",
                    type: "danger"
                });
            }
        });
    };


    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
