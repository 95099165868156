import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {ILettreType} from "../../Models/LettreType.model";


type IAsideLettresTypesComponentProps = {
    lettreType?: ILettreType,
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void,
    filters?: any,
}

export default function AsideLettresTypesComponent(props: IAsideLettresTypesComponentProps ) {
    const {exportListPDF} = useListExportPDF();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
        }

        setActions(actions);
    }, [props.filters]);



    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathLettreTypes}/export-pdf`

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
