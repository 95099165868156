import React, {useEffect, useState} from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {NotairesService} from "../../Services/Notaires.service";
import {INotaire} from "../../Models/Notaire.model";
import NotairesHelper from "../../Helpers/Notaires.helper";

type CtrcsAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,
}
export default function CtrcsAdvancedSearchComponent(props: CtrcsAdvancedSearchComponentProps){
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAll().then((response) => {
            if( response && response.datas && response.datas.notaires){
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });
    }, []);




    return (
        <>
            {
                notairesLoaded &&
                <AdvancedSearchComponent
                    title="CTRC"
                    showSummary={props.showSummary}
                    modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
                    showAdvancedSearch={props.showAdvancedSearch}
                    filters={props.filters}
                    columns={[
                        {
                            elements: [
                                {
                                    type: 'hidden',
                                    fieldName: "advanced",
                                    oldValue: "1",
                                    modificators: "-hidden"
                                },
                                {
                                    type: "text",
                                    fieldName: "nom",
                                    label: "Nom",
                                    modificators:"-on-white",
                                    oldValue: props.filters.nom as string || ""
                                },
                                {
                                    type: 'date',
                                    label: "Date (min)",
                                    fieldName: "dateCTRCMin",
                                    oldValue: props.filters.dateCTRCMin as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date (max)",
                                    fieldName: "dateCTRCMax",
                                    oldValue: props.filters.dateCTRCMax as string || "",
                                    modificators: "-on-white",
                                },
                            ]
                        },
                        {
                            elements: [

                                {
                                    type: "select",
                                    fieldName: "ctrc_notaireId_president",
                                    label: "Président",
                                    modificators:"-on-white",
                                    oldValues: props.filters.ctrc_notaireId_president as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                                {
                                    //Uniquement des notaires
                                    type: "selectMultiple",
                                    fieldName: "ctrc_notaireId_membres",
                                    label: "Membres",
                                    modificators:"-on-white",
                                    oldValues: props.filters.ctrc_notaireId_membres as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                            ]
                        },
                    ]}
                    onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
                    onClose={() => props.setShowAdvancedSearch()}
                    onSearch={props.onSearch}
                />
            }

        </>
    )

}
