import React from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import FileHelper from "../../Helpers/File.helper";
import placeholder from 'src/assets/images/placeholders/picture-woman.png';
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import {IFormation} from "../../Models/Formation.model";
import DocumentLink from "../Documents/DocumentLink.component";
import FilInformationsHelper from "../../Helpers/FilInformations.helper";


const FilInformationsFormations = (props: {
    filInformations: IFilInformations
}) => {

    const getFormationsListText = (formations: IFormation[]) => {
        return formations.map((f, fIndex) => `${fIndex > 0 ? ',' : ''} ${f.titre}`).join("");
    };

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column -size-2">
                <div className="informations-block">
                    <p className="label">Formations</p>
                    <div className="value">
                        {getFormationsListText(props.filInformations.formations)}
                    </div>
                </div>
                <div className="informations-block">
                    <p className="label">Titre</p>
                    <div className="value">
                        {props.filInformations.titreFormations ? props.filInformations.titreFormations : "-" }
                    </div>
                </div>
                <div className="informations-block">
                    <p className="label">Picto</p>
                    <div className="value">
                        {props.filInformations.pictoFormations ? FilInformationsHelper.getFormatedPicto(props.filInformations.pictoFormations) : "-" }
                    </div>
                </div>

                <div className="informations-block">
                    <p className="label">Texte</p>
                    <div className="value"><CommentairesComponent modificators="-full" commentaires={props.filInformations.texteFormations}/>
                    </div>
                </div>
            </div>
        </div>

    </div>

};

export default FilInformationsFormations;
