import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type GroupesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
}
export default function GroupesExportComponent(props: GroupesExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Groupes"}
                         icon="icon-groupes"
                         subTitle={"Export des groupes"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         exportUrl= {`api${AppConstants.pathGroupes}/export`}
                         filters={props.filters || null}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-formations',
                                         fieldName: "informations",
                                         label: "Informations",
                                         options: [
                                             {value: 'nom', label: 'Nom'},
                                             {value: 'automatique', label: 'Automatique'},
                                             {value: 'commentaires', label: 'Commentaires'},
                                             {value: 'nbContacts', label: 'Nombre de contacts'},
                                         ]
                                     },
                                 ]
                             },
                         ]}
        />
    )
}
