import React from "react";
import {ICorrespondance} from "../../../Models/Correspondance.model";


const CorrespondancePrecisions = (props: {
    correspondance: ICorrespondance
}) => {

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column">
                <div className="informations-block">
                    <p className="label">Inclure le courrier</p>
                    <p className="value">{props.correspondance.inclureCourrier || '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Présence</p>
                    <p className="value">{props.correspondance.presence || '-'}</p>
                </div>
            </div>
            <div className="column">
                <div className="informations-block">
                    <p className="label">Réunion</p>
                    <p className="value">{props.correspondance.reunion || '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Collaborateur</p>
                    <p className="value">{props.correspondance.collaborateur || '-'}</p>
                </div>
            </div>
        </div>


        <div className="columns">
            <div className="column">
                <div className="informations-block">
                    <p className="label">Transport</p>
                    <p className="value">{props.correspondance.transport || '-'}</p>
                </div>

                {props.correspondance.transport === "oui" && <>
                  <div className="informations-block">
                    <p className="label">Détail du transport</p>
                    <p className="value">{props.correspondance.transportDetail}</p>
                  </div>
                </>}
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Repas</p>
                    <p className="value">
                        {props.correspondance.repas}
                    </p>
                </div>
                {props.correspondance.repas === "oui" && <>
	                <div className="informations-block">
		                <p className="label">Nom du repas</p>
		                <p className="value">{props.correspondance.repasNom}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Lieu du repas</p>
		                <p className="value">{props.correspondance.repasLieu}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Heure du repas</p>
		                <p className="value">{props.correspondance.repasHeure}</p>
	                </div>
                </>}
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Cocktail</p>
                    <p className="value">{props.correspondance.cocktail}</p>
                </div>
                {props.correspondance.cocktail === "oui" && <>
	                <div className="informations-block">
		                <p className="label">Nom du cocktail</p>
		                <p className="value">{props.correspondance.cocktailNom}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Lieu du cocktail</p>
		                <p className="value">{props.correspondance.cocktailLieu}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Heure du cocktail</p>
		                <p className="value">{props.correspondance.cocktailHeure}</p>
	                </div>
	            </>}
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Hébergement</p>
                    <p className="value">{props.correspondance.hebergement}</p>
                </div>
                {props.correspondance.hebergement === "oui" && <>
                  <div className="informations-block">
                    <p className="label">Nom de l'hébergement</p>
                    <p className="value">{props.correspondance.hebergementNom}</p>
                  </div>
                  <div className="informations-block">
                    <p className="label">Lieu de l'hébergement</p>
                    <p className="value">{props.correspondance.hebergementLieu}</p>
                  </div>
                </>}
            </div>
        </div>

    </div>

};

export default CorrespondancePrecisions;
