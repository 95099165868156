import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {IGetUserApiResponse, IUserEditFormData, UsersService} from "../../Services/Users.service";
import {IUser} from "src/Models/User.model";
import UserFormComponent from "src/Components/Forms/Users/UserForm.component";


type IUserCreationScreenProps = {
}
export default function UserCreationScreen(props: IUserCreationScreenProps){
    const history = useHistory();
    const usersService: UsersService = new UsersService();

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IGetUserApiResponse): void => {
        if(response.datas && response.datas.user){
            const user: IUser = response.datas.user;
            history.push(AppConstants.pathUtilisateurs + '/' + user.id);
        }
        else{
            history.push(AppConstants.pathUtilisateurs);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathUtilisateurs);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un utilisateur" icon="icon-notaires" modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />

            <UserFormComponent
                isOnCreationStep={true}
                submitMethod={(formData: IUserEditFormData)=>usersService.create(formData)}
                onSuccessSubmit={onSuccessSubmitForm}
                onCancel={onCancelForm}
            />
        </LayoutComponent>
    )
}
