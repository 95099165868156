import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type OrganismesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
}
export default function OrganismesExportComponent(props: OrganismesExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Organismes"}
                         icon="icon-organismes"
                         subTitle={"Export des organismes"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl={`api${AppConstants.pathOrganismes}/export`}
                         columns={[
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-organismes',
                                         fieldName: "organisme",
                                         label: "Organisme",
                                         options: [
                                             {value: 'nom', label: 'Nom'},
                                             {value: 'contactOrganisme', label: 'Contacts'},
                                             {value: 'autres.groupes', label: 'Groupes'}
                                         ]
                                     },
                                 ]
                             },
                             {
                                 elements: [
                                     {
                                         type: 'checkboxGroup',
                                         icon: 'icon-coordonnees',
                                         fieldName: "personnes",
                                         label: "Coordonnées d'un organisme",
                                         options: [
                                             {value: 'adresses.ligne1', label: 'Rue'},
                                             {value: 'adresses.ligne2', label: 'Complément d\'adresse'},
                                             {value: 'adresses.boitePostale', label: 'Boîte Postale'},
                                             {value: 'adresses.codePostal', label: 'Code Postal / CEDEX'},
                                             {value: 'adresses.ville', label: 'Ville'},
                                             {value: 'coordonnees.emailPrincipal', label: 'Email Principal'},
                                             {value: 'coordonnees.emailBureau', label: 'Email Bureau'},
                                             {value: 'coordonnees.emailComptabilite', label: 'Email Comptabilité'},
                                             {value: 'coordonnees.emailPerso', label: 'Email Perso'},
                                             {value: 'coordonnees.emailConvocation', label: 'Email Convocation'},
                                             {value: 'coordonnees.telephoneLigneDirecte', label: 'Téléphone Ligne directe'},
                                             {value: 'coordonnees.telephoneDomicile', label: 'Téléphone Domicile'},
                                             {value: 'coordonnees.telephoneMobile', label: 'Téléphone Mobile'},
                                         ]
                                     },
                                 ]
                             }
                         ]}
        />
    )
}
