import React, {ChangeEvent, useEffect, useState} from "react";
import LabelComponent from "./Label.component";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

type ICheckboxFieldProps = {
    value: string,
    id: string,
    name?: string,
    label?: string,
    labelModificators ?: string,
    modificators ?: string,
    disabled?: boolean,

    isChecked?: boolean

    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
}


export default function CheckboxFieldComponent(props: ICheckboxFieldProps){
    const [isChecked, setIsChecked] = useState<boolean>(props.isChecked || false);


    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);

    const suffix = generateUniqueID();

    return (
        <label className={`form-field-checkbox ${props.modificators || ""}`} key={props.id} htmlFor={props.id+suffix}>
            <div className="checkbox-wrapper">
                <input className="checkbox" id={props.id+suffix} name={props.name} onChange={props.onChange} type="checkbox" checked={isChecked} value={props.value} disabled={props.disabled || false}/>

                <i className="picto icon-check" />
            </div>
            {props.label && <LabelComponent fieldName={props.id+suffix} label={props.label} modificators={`-no-margin-b -got-hover ${props.labelModificators || "-fw-medium -size-2"}`} />}
        </label>
    )
}
