import React, {useState} from "react";
import LayoutComponent from "../../Components/Layout/Layout.component";
import PageHeaderComponent from "../../Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "../../Components/Tabs/TabsContainer.component";
import SauvegardesListComponent from "../../Components/FilInformations/SauvegardesList.component";
import ArchivesListComponent from "../../Components/FilInformations/ArchivesList.component";
import AsideFilInformationsComponent from "../../Components/Aside/AsideFilInformations.component";
import AbonnesListComponent from "../../Components/FilInformations/AbonnesList.component";
import FilInformationsStats from "../../Components/FilInformations/FilInformationsStats.component";
import {TabsSlugs} from "../../Constants/AppConstants";
import LoadingComponent from "../../Components/Loading/Loading.component";


const FilInformationsListScreen = () => {
    const [loaded, setLoaded] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<string>("list");
    const [keywordAbonnes, setKeywordAbonnes] = useState<string>("");

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent
                    breadcrumbLinks={[
                        {text: "Fil d'informations"},
                    ]}
                    text="Fil d'informations"
                    icon="icon-fil-infos"
                    onSearch={ activeTab === "listAbonnes" ? (keyword => setKeywordAbonnes(keyword)) : null}
                />

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
						<TabsContainerComponent  onClickTabCallback={(tab)=>{setActiveTab(tab)}}>
							<TabPanelComponent label="Sauvegardes" icon="icon-sauvegarde" slug="list">
								<div className="list-content -right-gutter">
                                    <SauvegardesListComponent />
									<AsideFilInformationsComponent type="list" />
								</div>

								<FilInformationsStats />
							</TabPanelComponent>


							<TabPanelComponent label="Archives" icon="icon-archives" slug="archive">
								<div className="list-content -right-gutter">
									<ArchivesListComponent />
								</div>
							</TabPanelComponent>


							<TabPanelComponent label="Abonnés" icon="icon-abonnes" slug="listAbonnes">
								<div className="list-content -right-gutter">
									<AbonnesListComponent keywordAbonnes={keywordAbonnes} />
								</div>
							</TabPanelComponent>
						</TabsContainerComponent>
					</>
                }
            </div>

        </LayoutComponent>
    )
};

export default FilInformationsListScreen;
