import {IApiErrorMessage} from "src/Services/Api.service";
import React from "react";

type FormErrorsMessagesProps = {
    messages: IApiErrorMessage
}

export default function ErrorFormsMessagesComponent(props: FormErrorsMessagesProps){
    return (
        <>
            {
                props.messages && props.messages.length > 0 &&
                <div className="form-messages">
                    {
                        props.messages.map((errorMessage: string, index: number) =>
                            <p className="message" key={index}>{errorMessage}</p>
                        )
                    }
                </div>
            }
        </>
    )
}
