import React, {useContext} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {Redirect, Route, RouteProps} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {UserConstants} from "src/Constants/UsersConstants";


type AuthRouteProps = RouteProps & {
}


const AuthAdminRoute = (authRouteProps: AuthRouteProps) => {
    // On récupère uniquement les propriétés de props qu'on souhaite. Le reste est stocké dans rest et est envoyé tel quel au composant route
    // component: Component ici n'est pas un typage mais une assignation de la propriété component à la variable "Component"
    const {component: Component, ...rest} = authRouteProps;
    const {isAuthenticated, authUser} = useContext(UserContext) as IUserContext;


    // Utilisateur n'ayant pas les droits nécessaires
    if (!isAuthenticated || !authUser) {
        return <Redirect push to={AppConstants.pathLogin} />
    }

    //On check le role de l'utilsateur
    //Le role doit être au minimum admin
    if( authUser.role < UserConstants.roles.admin) {
        return <Redirect push to={AppConstants.path403} />
    }

    // Droits suffisants on retourne la route
    return <Route {...rest} render={(props) => <Component {...props} />}/>;
};

export default AuthAdminRoute;
