import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";
import {ISinistre} from "../Models/Sinistre.model";

export type ISinistreGetApiResponse = IApiCustomResponse & {
    datas?: {
        sinistre: ISinistre
    }
}
export type ISinistresGetPaginationApi = IApiPagination & {
    data: ISinistre[],
}
export type ISinistresGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ISinistresGetPaginationApi
    }
}
export type ISinistresGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    etat?: string[],
}
export type ISinistreCourrierFormData = {
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}
export type ISinistreDocumentsFormData = {
    fichier?: string;
    date: string,
}
export type ISinistreInformationsFormData = {
    numeroDossier?: string,
    libelle: string,
    dateOuverture?: string,
    notairesAssignes?: string[],
    source?: string,
    rapporteur?: string,
}



export class SinistresService extends ApiService{
    /**
     * Permet la suppression d'un sinistre
     *
     * @param {number} sid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(sid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/sinistres/' + sid + '/delete', 'POST');
    }

    /**
     * Permet la récupération d'un sinistre à partir de son id
     *
     * @param {string} sid
     * @returns {Promise<ISinistreGetApiResponse>}
     */
    public getSinistre(sid: string): Promise<ISinistreGetApiResponse>{
        return this.doApiCall('api/sinistres/' + sid, 'GET');
    }

    /**
     * Permet la récupération d'une liste de sinistres
     *
     * @returns {Promise<ISinistresGetApiResponse>}
     */
    public getSinistres(formData: ISinistresGetFormData): Promise<ISinistresGetApiResponse>{
        return this.doApiCall('api/sinistres', 'GET', formData);
    }


    /**
     * Envoi d'un courrier d'un sinistre
     *
     * @param {number} sid
     * @param {ISinistreCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(sid: number, formDatas: ISinistreCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/sinistres/' + sid + '/courrier/send', 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier d'un sinistre
     *
     * @param {number} sid
     * @param {ISinistreCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public downloadCourrier(sid: number, formDatas: ISinistreCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/sinistres/' + sid + '/courrier/download', 'POST', formDatas);
    }

    /**
     * Création d'un sinistre
     *
     * @param {ISinistreInformationsFormData} formDatas
     * @returns {Promise<ISinistreGetApiResponse>}
     */
    public create(formDatas: ISinistreInformationsFormData): Promise<ISinistreGetApiResponse>{
        return this.doApiCall('api/sinistres/create', 'POST', formDatas);
    }

    /**
     * Mise à jour des informations d'un sinistre
     *
     * @param {number} sid
     * @param {ISinistreInformationsFormData} formDatas
     * @returns {Promise<ISinistreGetApiResponse>}
     */
    public updateInformations(sid: number, formDatas: ISinistreInformationsFormData): Promise<ISinistreGetApiResponse>{
        return this.doApiCall('api/sinistres/' + sid + '/informations', 'POST', formDatas);
    }

    /**
     * Mise à jour des documents d'un sinistre
     *
     * @param {number} sid
     * @param {ISinistreDocumentsFormData} formDatas
     * @returns {Promise<ISinistreGetApiResponse>}
     */
    public updateDocuments(sid: number, formDatas: ISinistreDocumentsFormData): Promise<ISinistreGetApiResponse>{
        return this.doApiCall('api/sinistres/' + sid + '/documents', 'POST', formDatas);
    }



}




