import React from "react";
import {IFilInformations} from "src/Models/FilInformations.model";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import DateHelper from "../../Helpers/Date.helper";


const FilInformationsVieCompagnie = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.vies.map((vie, vieIndex) =>
                    <div className="columns" key={vieIndex}>
                        <p className="title">{vie.titre}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date</p>
                                <div className="value">
                                    {vie.date ? DateHelper.getFormatedDate(vie.date) : ""}
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Texte</p>
                                <div className="value">
                                    <CommentairesComponent modificators="-full" commentaires={vie.text}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {props.filInformations.vies.length === 0 && <p>-</p>}
        </div>
    )
};

export default FilInformationsVieCompagnie;
