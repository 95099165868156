import React from "react";

type ILabelProps = {
    fieldName: string,

    label: string;
    modificators?: string;
    isRequired?: boolean;
}


export default function LabelComponent(props: ILabelProps){
    const {fieldName, label, modificators} = props;

    return (
        <label htmlFor={fieldName} className={`form-field-label ${modificators || ""}`}>{props.isRequired}{label}{`${props.isRequired ? ' *' : ''}`}</label>
    )
}
