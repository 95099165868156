import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {AppConstants} from "../../Constants/AppConstants";
import moment from "moment/moment";
import ApiService from "../../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {HabilitationsService} from "../../Services/Habilitations.service";


type IAsideHabilitationsComponentProps = {
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void,
    filters?: any
}

export default function AsideHabilitationsComponent(props: IAsideHabilitationsComponentProps ) {
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const {exportListPDF} = useListExportPDF();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        const currentYear = moment().year();
        const nextYear = moment().year() + 1;

        let actions: ListAsideAction[] = [];
        if( props.type === "list") {
            actions.push({icon: "icon-lock-" + currentYear, click: () => lock(currentYear)});
            actions.push({icon: "icon-export-" + currentYear, click: () => exportPDF(currentYear)});
            actions.push({icon: "icon-export-" + nextYear, click: () => exportPDF(nextYear)});
        }

        setActions(actions);
    }, [props.filters]);


    /**
     * Export pdf de la page de liste
     *
     * @param {number} year
     */
    const exportPDF = (year: number): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathHabilitations}/export/${year}`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    /**
     * Lock une année de la page de liste
     *
     * @param {number} year
     */
    const lock = (year: number): void => {
        setIsLoading(true);

        const habilitationsService: HabilitationsService = new HabilitationsService();
        habilitationsService.lockHabilitationsYears(year).then((response)=> {
            if (response) {
                notificationSystem.addNotification({...defaultNotificationConfig, message: "L'année a été bloquée.",type: "success"});
                setIsLoading(false);

                if(props.exportCallback){
                    props.exportCallback();
                }
            }
            else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors du blockage de l'année",type: "danger"});
            }
        }, (error) => {
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors du blockage de l'année",type: "danger"});

            setIsLoading(false);
        });
    }


    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
