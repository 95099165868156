import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";

type ICotisationsOfficeRecapPertesExploitationComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapPertesExploitationComponent(props: ICotisationsOfficeRecapPertesExploitationComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant Perte 1</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantPerte1, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant Perte 2</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantPerte2, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Total Perte</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantPerte1 + cotisation.montantPerte2, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
