import {IParametre} from "src/Models/Parametre.model";
import DateHelper from "src/Helpers/Date.helper";
import CommonHelper from "src/Helpers/Common.helper";
import NumberHelper from "src/Helpers/Number.helper";

type IParametreInformationsComponentProps = {
    parametre: IParametre,
}

export default function ParametreInformationsComponent(props: IParametreInformationsComponentProps) {
    const parametre: IParametre = props.parametre;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{parametre.nom}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Valeur</p>

                        {
                            parametre.type == "date" &&
                            <p className="value">{DateHelper.getFormatedDate(parametre.dateFormated)}</p>
                        }
                        {
                            parametre.type == "number" &&
                            <p className="value">{NumberHelper.formatThousand(parseFloat(parametre.valeur))}</p>
                        }
                        {
                            parametre.type == "ouiNon" &&
                            <p className="value">{CommonHelper.getFormatedOuiNon(parametre.valeur)}</p>
                        }
                        {
                            parametre.type == "text" &&
                            <p className="value">{parametre.valeur}</p>
                        }

                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de modification</p>
                        <p className="value">{DateHelper.getFormatedDate(parametre.dateModification)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
