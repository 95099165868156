import React from "react";


type IInformationHeaderProps = {
    icon?: string,
    modificators?: string,
    title: string,
}

export default function InformationHeaderComponent(props: IInformationHeaderProps){
    return (
        <header className={`informations-header  ${props.modificators ? props.modificators : ''}`}>
            {
                props.icon &&
                <i className={`icon ${props.icon}`} />
            }

            <span className="title">{props.title}</span>
        </header>
    )
}
