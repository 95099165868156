import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {useHistory, useLocation} from "react-router";

import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {CorrespondancesService, ICorrespondancesGetApiResponse, ICorrespondancesGetFormData} from "src/Services/Correspondances.service";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {ICorrespondance} from "src/Models/Correspondance.model";
import {IApiPaginationLink} from "src/Services/Api.service";
import DateHelper from "src/Helpers/Date.helper";
import AsideCorrespondanceComponent from "src/Components/Aside/AsideCorrespondances.component";
import CorrespondancesHelper from "src/Helpers/Correspondances.helper";
import CorrespondancesExportComponent from "src/Components/Correspondances/Courriers/CorrespondancesExport.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";


const CourriersListScreen = () => {
    const [filters,initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathCorrespondances);
    const location = useLocation();
    const [showExport, setShowExport] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [correspondances,setCorrespondances] = useState<ICorrespondance[]>([]);
    const [correspondancesPassees,setCorrespondancesPassees] = useState<ICorrespondance[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [paginationLinksPassees, setPaginationLinksPassees] = useState<IApiPaginationLink[]>([]);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.A_VENIR);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(initialFilters);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    useEffect(() => {
        if(filters) {
            filters.old = (activeTab && activeTab == TabsSlugs.PASSEES) ? 1 : 0;
        }
    }, [activeTab])

    /**
     * Lancement de la recherche
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        const correspondancesService: CorrespondancesService = new CorrespondancesService();

        // A venir
        if(activeTab === TabsSlugs.A_VENIR){
            correspondancesService.getCorrespondances(filters as ICorrespondancesGetFormData).then((response: ICorrespondancesGetApiResponse)=>{

                if (response && response.datas.pagination) {

                    setCorrespondances(response.datas.pagination.data);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }

        // Passees
        if(activeTab === TabsSlugs.PASSEES){
            correspondancesService.getCorrespondances({...filters,old: 1} as ICorrespondancesGetFormData).then((response: ICorrespondancesGetApiResponse)=>{

            if (response && response.datas.pagination) {

                setCorrespondancesPassees(response.datas.pagination.data);

                // Pagination
                setPaginationLinksPassees(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
        }
    };

    return <>
        <LayoutComponent showMenu={true} wrapperModificators={`${showExport ? '-no-scroll' : ''}`}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent breadcrumbLinks={[
                                    { text: "Correspondances", link: AppConstants.pathMosaique + '#correspondances'},
                                    { text: "Correspondances" },
                     ]}
                     text="Correspondances"
                     icon="icon-correspondances"
                     currentKeyword={ filters && filters.keyword ? filters.keyword as string : ""}
                     onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}/>

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
		            <>
                        <TabsContainerComponent onClickTabCallback={(tab)=>{updateFilters({},true); setActiveTab(tab)}}>
                            <TabPanelComponent label="À venir" icon="icon-a-venir" slug={TabsSlugs.A_VENIR}>
                                <div className={`list-content -right-gutter`}>
                                    <CorrespondancesListTableComponent filters={filters} correspondances={correspondances} setOrder={setOrder} isOld={false}/>
	                                <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

	                                <AsideCorrespondanceComponent type={"list"} exportCallback={() => setShowExport(true)} filters={filters}  />
                                </div>
                            </TabPanelComponent>


                            <TabPanelComponent label="Passées" icon="icon-termine" slug={TabsSlugs.PASSEES}>
                                <div className={`list-content -right-gutter`}>
	                                <CorrespondancesListTableComponent filters={filters} correspondances={correspondancesPassees} setOrder={setOrder} isOld={true}/>
	                                <PaginationComponent links={paginationLinksPassees} onPageClick={page => updateFilters({page})}/>

	                                <AsideCorrespondanceComponent type={"list"} exportCallback={() => setShowExport(true)} filters={{...filters,old: 1} as ICorrespondancesGetFormData} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            {<CorrespondancesExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} filters={filters} />}
        </LayoutComponent>
    </>
};

const CorrespondancesListTableComponent = (props:{correspondances: ICorrespondance[], filters: IFilterData, setOrder:(value:string)=>void, isOld:boolean}) => {
    const history = useHistory();

    return <table className={`list-table`}>
        <thead className={"headers"}>
        <tr>
            {[
                {slug: "envoye", label: "Envoyé"},
                {slug: "titre", label: "Libellé"},
                {slug: "date", label: "Date"},
                {slug: "type", label: "Type"},
            ].map((item, iIndex) => <th
                className="th" key={iIndex}>
                <TableHeaderButtonComponent
                    direction={props.filters.orderType as string || AppConstants.orderType.asc}
                    value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                    label={item.label} click={(value: string) => props.setOrder(value)}/>
            </th>)}
        </tr>
        </thead>
        <tbody>
        {
            props.correspondances && props.correspondances.map((correspondance: ICorrespondance, index: number) =>
                <tr key={index}
                    onClick={() => history.push(`${AppConstants.pathCorrespondances}/${correspondance.id}`)}
                    className="tr">
                    <td className="td -bullet"><span className={`state-bullet icon-bullet -${correspondance.envoye}`}>&nbsp;</span></td>
                    <td className="td">
                        {correspondance.titre}
                    </td>
                    <td className="td">{ correspondance.date ? DateHelper.getFormatedDate(correspondance.date) : ''}</td>
                    <td className="td">{ correspondance.type ? CorrespondancesHelper.getTypeFormated(correspondance.type) : ""}</td>

                </tr>
            )
        }
        </tbody>
    </table>
};

export default CourriersListScreen;
