import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect,useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse} from "src/Services/Api.service";
import useNotaire from "src/Hooks/UseNotaire";
import {INotaire} from "src/Models/Notaire.model";
import NotaireUrssafExternalFormComponent from "src/Components/Forms/Annuaire/Notaires/NotaireUrssafExternalForm.compontent";
import {INotaireUrssafUploadFormData, NotairesService} from "src/Services/Notaires.service";
import DateHelper from "../../../Helpers/Date.helper";


type INotaireUrssafExternalScreenProps = {
    nid: number,
    aid: number
}
export default function NotaireUrssafExternalScreen(props: INotaireUrssafExternalScreenProps){
    const [getNotaireById, getNotaireExternalById] = useNotaire();
    const [notaire, setNotaire] = useState<INotaire>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [answered, setAnswered] = useState<boolean>(false);
    const [answeredCurrentForm, setAnsweredCurrentForm] = useState<boolean>(false);
    const history = useHistory();
    const notairesServices: NotairesService = new NotairesService();


    useEffect(() => {
        if( !props.nid ){
            history.push(AppConstants.path403);
        }

        getNotaire();

    }, [props.nid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getNotaire = (): void => {
        getNotaireExternalById(props.nid.toString(), (notaire: INotaire) => {
            setNotaire(notaire);

            if(notaire.urssaf && notaire.urssaf.fichier){
                setAnswered(true);
            }

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getNotaire();
        setAnsweredCurrentForm(true);
    };


    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
                    {/*<PageHeaderComponent text="Notaire" subText={`Attestation Urssaf ${DateHelper.getYearFromDate(notaire.urssaf.date)} `} icon="icon-urssaf" modificators={`-in-fullscreen -border-full-screen`}/>*/}
                    <PageHeaderComponent text="Notaire" subText={`Attestation Urssaf ${DateHelper.getLastYear()} `} icon="icon-urssaf" modificators={`-in-fullscreen -border-full-screen`}/>

                    {
                        !answered &&
                        <NotaireUrssafExternalFormComponent notaire={notaire}
                                                            submitMethod={(formData: INotaireUrssafUploadFormData)=>notairesServices.uploadUrssafAttestationExternal(props.nid,props.aid,formData)}
                                                            onSuccessSubmit={onSuccessSubmitForm} />
                    }

                    {
                        answered && !answeredCurrentForm &&
                        <p className="information-text">
                            Vous avez déjà envoyée votre attestation Urssaf.
                        </p>
                    }

                    {
                        answered && answeredCurrentForm &&
                        <p className="information-text">
                            Votre attestation Urssaf a bien été envoyée.
                        </p>
                    }
                </LayoutComponent>
            }
        </>
    )
}
