import React, {useEffect, useRef, useState} from "react";

import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {AppConstants} from "src/Constants/AppConstants";
import {IFormation} from "src/Models/Formation.model";
import useFormation from "src/Hooks/UseFormation";
import {useHistory} from "react-router";
import AsideFormationComponent from "src/Components/Aside/AsideFormations.component";
import FormationCourrierForm from "src/Components/Forms/Formations/Formations/FormationCourrierForm.component";
import FormationInformations from "src/Components/Formations/Formations/FormationInformations.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import FormationInformationsForm from "src/Components/Forms/Formations/Formations/FormationInformationsForm.component";
import {FormationsService, IFormationFormData} from "src/Services/Formations.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import FormationFormation from "src/Components/Formations/Formations/FormationFormation.component";
import FormationFormationForm from "src/Components/Forms/Formations/Formations/FormationFormationForm.component";
import FormationDocumentsForm from "src/Components/Forms/Formations/Formations/FormationDocumentsForm.component";
import FormationDocuments from "src/Components/Formations/Formations/FormationDocuments.component";
import FormationPrecisionsForm from "src/Components/Forms/Formations/Formations/FormationPrecisionsForm.component";
import FormationPrecisions from "src/Components/Formations/Formations/FormationPrecisions.component";
import FormationComplementsForm from "src/Components/Forms/Formations/Formations/FormationComplementsForm.component";
import FormationComplements from "src/Components/Formations/Formations/FormationComplements.component";

import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import FormationParticipantsList from "src/Components/Formations/Formations/FormationParticipantsList.component";
import FormationCourrierConfirmationForm
    from "../../../Components/Forms/Formations/Formations/FormationCourrierConfirmationForm.component";
import FormationCourrierQuestionnaireForm
    from "../../../Components/Forms/Formations/Formations/FormationCourrierQuestionnaireForm.component";
import FormationCourrierDocumentsForm
    from "../../../Components/Forms/Formations/Formations/FormationCourrierDocumentsForm.component";

export type IFormationFicheScreenProps = {
    fid: number,
}

export default function FormationFicheScreen(props: IFormationFicheScreenProps){

    const [loaded, setLoaded] = useState<boolean>(false);
    const [getFormationById] = useFormation();
    const [formation, setFormation] = useState<IFormation>(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [showFormCourrierParticipant, setShowFormCourrierParticipant] = useState<boolean>(false);
    const [formCourrierParticipant, setFormCourrierParticipant] = useState<string>(null);
    const [participantsSelectedIds, setParticipantsSelectedIds] = useState<string[]>([]);
    const [formOpened, setFormOpened] = useState<boolean>(false);

    const formationsService: FormationsService = new FormationsService();

    const participantListRef = useRef(null);
    const informationsAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);
    const formationAccordionRef = useRef(null);
    const precisionsAccordionRef = useRef(null);
    const complementAccordionRef = useRef(null);

    useEffect(() => {
        if( !props.fid ){
            history.push(AppConstants.pathFormations);
        }

        getFormation();

    }, [props.fid])


    useEffect(() => {
        if(!formation) return;

        const breadcrumbLinks = [
            {text: "Formations", link: AppConstants.pathMosaique + "#formations"},
            {text: "Liste des formations", link: AppConstants.pathFormations},
            {text: formation.titre},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [formation, loaded]);


    /**
     * Récupération de la formation selon l'id
     */
    const getFormation = (): void => {
        getFormationById(props.fid, (f: IFormation) => {
            setFormation(f);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };



    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationsAccordionRef && informationsAccordionRef.current) ){
            informationsAccordionRef.current.hideForm(isCancel);
        }
        if((formationAccordionRef && formationAccordionRef.current) ){
            formationAccordionRef.current.hideForm(isCancel);
        }
        if((documentsAccordionRef && documentsAccordionRef.current) ){
            documentsAccordionRef.current.hideForm(isCancel);
        }
        if((precisionsAccordionRef && precisionsAccordionRef.current) ){
            precisionsAccordionRef.current.hideForm(isCancel);
        }
        if((complementAccordionRef && complementAccordionRef.current) ){
            complementAccordionRef.current.hideForm(isCancel);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getFormation();

        closeForms();
    };


    /**
     * Affichage de la préview du courrier des participants
     *
     * @param {string} courrierType
     */
    const previewCourrierParticipant = ( courrierType: string): void => {
        setShowFormCourrierParticipant(true);
        setFormCourrierParticipant(courrierType)
    }
    /**
     * Désactivation de la préview du courriers des participants
     */
    const disablePreviewCourrierParticipant = (): void => {
        setShowFormCourrierParticipant(false);
        setFormCourrierParticipant(null)
    }


    /**
     * Ecoute du chantement de selection de participants
     *
     * @param {string[]} updatedParticipantsIds
     */
    const onSelectedParticipantsChange = (updatedParticipantsIds: string[]) => {
        setParticipantsSelectedIds(null);

        setTimeout(()=> {
            setParticipantsSelectedIds(updatedParticipantsIds);
        },50)
    }





    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };


    /**
     *
     */
    const onExportParticipants = (etat : string) =>{
        formationsService.exportParticipants(props.fid, etat).then((response)=>{
            if (response && response.datas.url) {
                handleFileDownload(response.datas.url);
            }else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }
        });
    };

    /**
     *
     */
    const onEmargement = () =>{
        formationsService.emargement(props.fid).then((response)=>{
            if (response && response.datas.url) {
                handleFileDownload(response.datas.url,true);
            }else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }
        });
    };


    /**
     * Téléchargement du fichier
     *
     * @param {string} url
     * @param {boolean} blank
     */
    const handleFileDownload = (url:string,blank: boolean = false) => {
        const link = document.createElement('a');
        link.href = url;
        if(blank)
            link.target = "_blank";
        link.setAttribute('download', ``);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };


    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={formation.titre} icon="icon-formations" modificators="" onSearch={null}/>

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

	                            <AccordionWithFormSwitchComponent
		                            ref={informationsAccordionRef}
		                            title="Informations"
		                            icon="icon-informations"
		                            contentShowedOnInit={true}
		                            contentType="formation"
		                            contentId={formation.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
		                            form={<FormationInformationsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationInformations(formData,formation.id)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
		                            infos={<FormationInformations formation={formation} />}
                                />


                                <AccordionWithFormSwitchComponent
                                    ref={formationAccordionRef}
                                    title="Formation"
                                    icon="icon-formations"
                                    contentShowedOnInit={true}
                                    contentType="formation"
                                    contentId={formation.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<FormationFormationForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationFormation(formation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<FormationFormation formation={formation} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="formation"
                                    contentId={formation.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<FormationDocumentsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationDocuments(formation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<FormationDocuments formation={formation} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={precisionsAccordionRef}
                                    title="Précisions"
                                    icon="icon-precisions"
                                    contentShowedOnInit={true}
                                    contentType="formation"
                                    contentId={formation.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<FormationPrecisionsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationPrecisions(formation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<FormationPrecisions formation={formation} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={complementAccordionRef}
                                    title="Compléments"
                                    icon="icon-complements"
                                    contentShowedOnInit={true}
                                    contentType="formation"
                                    contentId={formation.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<FormationComplementsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationComplements(formation.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<FormationComplements formation={formation} />}
                                />


	                            <AsideFormationComponent type='fiche' formation={formation} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Inscrits" icon="icon-inscrits">
                            <div className="fiche-content -right-gutter border-footer">
                                <div className="list-resume">
                                    <div className="column -full">
                                        <p className="line">
                                            <span className="title">Nombre de personnes inscrites:&nbsp;</span>
                                            <span className="value">{formation.inscrits.length}</span>
                                        </p>
                                        <p className="line">
                                            <span className="title">Nombre de personnes en attente:&nbsp;</span>
                                            <span className="value">{formation.enAttente.length}</span>
                                        </p>
                                        <p className="line">
                                            <span className="title">Nombre d'inscriptions annulées:&nbsp;</span>
                                            <span className="value">{formation.annulation.length}</span>
                                        </p>
                                    </div>
                                </div>

                                <FormationParticipantsList ref={participantListRef} formation={formation} countParticipants={formation.inscrits.length || 0} participants={formation.inscrits} state="inscrit" onSelectedParticipantsChange={onSelectedParticipantsChange} onFormationChange={(updatedFormation)=>setFormation(updatedFormation)} nbMax={parseInt(formation.nbMaxParticipants)}/>

                                {
                                    !showFormCourrierParticipant &&
                                        <div className="formation-fiche-sendings -flex">
                                            <button className="button -primary -with-icon" onClick={()=>previewCourrierParticipant('confirmation')}>
                                                <i className="picto icon-email-confirmation" />
                                                <span className="text">Envoi Confirmation</span>
                                            </button>
                                            <button className="button -primary -with-icon" onClick={()=>previewCourrierParticipant('questionnaire')}>
                                                <i className="picto icon-email-questionnaire" />
                                                <span className="text">Envoi questionnaire</span>
                                            </button>
                                            <button className="button -primary -with-icon" onClick={()=>previewCourrierParticipant('documents')}>
                                                <i className="picto icon-email-document" />
                                                <span className="text">Envoi document</span>
                                            </button>
                                        </div>
                                }

                                {
                                    showFormCourrierParticipant &&
                                    <div className="formation-fiche-sendings">
                                        {
                                            (formCourrierParticipant && formCourrierParticipant === 'confirmation') &&
                                                <FormationCourrierConfirmationForm formation={formation} ids={participantsSelectedIds} onCancelForm={disablePreviewCourrierParticipant} onFormSuccess={ () => { getFormation(); disablePreviewCourrierParticipant(); participantListRef.current.resetSelectedParticipants() }} />
                                        }
                                        {
                                            (formCourrierParticipant && formCourrierParticipant === 'questionnaire') &&
                                                <FormationCourrierQuestionnaireForm formation={formation} ids={participantsSelectedIds} onCancelForm={disablePreviewCourrierParticipant} onFormSuccess={ () => { getFormation(); disablePreviewCourrierParticipant(); participantListRef.current.resetSelectedParticipants() }} />
                                        }
                                        {
                                            (formCourrierParticipant && formCourrierParticipant === 'documents') &&
                                                <FormationCourrierDocumentsForm formation={formation} ids={participantsSelectedIds} onCancelForm={disablePreviewCourrierParticipant} onFormSuccess={ () => { getFormation(); disablePreviewCourrierParticipant(); participantListRef.current.resetSelectedParticipants() }} />
                                        }
                                    </div>
                                }

                                <AsideFormationComponent type='inscriptions' formation={formation} exportCallback={() => {onExportParticipants('inscrits')}} emargementCallback={onEmargement}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Liste D’attente" icon="icon-liste-attente">
                            <div className="fiche-content -right-gutter border-footer">
                                <div className="list-resume">
                                    <div className="column -full">
                                        <p className="line">
                                            <span className="title">Nombre de personnes inscrites:&nbsp;</span>
                                            <span className="value">{formation.inscrits.length}</span>
                                        </p>
                                        <p className="line">
                                            <span className="title">Nombre de personnes en attente:&nbsp;</span>
                                            <span className="value">{formation.enAttente.length}</span>
                                        </p>
                                        <p className="line">
                                            <span className="title">Nombre d'inscriptions annulées:&nbsp;</span>
                                            <span className="value">{formation.annulation.length}</span>
                                        </p>
                                    </div>
                                </div>

	                            <FormationParticipantsList formation={formation} countParticipants={formation.inscrits.length || 0} participants={formation.enAttente} state="enAttente" onSelectedParticipantsChange={()=>null} onFormationChange={(updatedFormation)=>setFormation(updatedFormation)} nbMax={parseInt(formation.nbMaxParticipants)}/>
                                <AsideFormationComponent type='liste-d-attente' formation={formation} exportCallback={() => {onExportParticipants('enAttente')}} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Annulations" icon="icon-close">
                            <div className="fiche-content -right-gutter border-footer">

                                <div className="list-resume">
                                    <div className="column -full">
                                        <p className="line">
                                            <span className="title">Nombre de personnes inscrites:&nbsp;</span>
                                            <span className="value">{formation.inscrits.length}</span>
                                        </p>
                                        <p className="line">
                                            <span className="title">Nombre de personnes en attente:&nbsp;</span>
                                            <span className="value">{formation.enAttente.length}</span>
                                        </p>
                                        <p className="line">
                                            <span className="title">Nombre d'inscriptions annulées:&nbsp;</span>
                                            <span className="value">{formation.annulation.length}</span>
                                        </p>
                                    </div>
                                </div>

	                            <FormationParticipantsList formation={formation} countParticipants={formation.inscrits.length || 0} participants={formation.annulation} state="annulation" onSelectedParticipantsChange={()=>null} onFormationChange={(updatedFormation)=>setFormation(updatedFormation)} nbMax={parseInt(formation.nbMaxParticipants)}/>
                                <AsideFormationComponent type='annulations' formation={formation} exportCallback={() => {onExportParticipants('annulation')}} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Courriers" icon="icon-courrier">
                            <div className="fiche-content -right-gutter border-footer">

                                <div className="g-content">
                                    <FormationCourrierForm formation={formation} />
                                </div>

                                <AsideFormationComponent type='courrier' formation={formation} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}

