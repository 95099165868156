import ApiService, {
    IApiCustomResponse,
    IApiPagination,
    IExportApiResponse,
    IFileDownloadApiResponse
} from "./Api.service";
import {IFormation} from "src/Models/Formation.model";

export type IFormationsGetPaginationApi = IApiPagination & {
    data: IFormation[],
}

export type IFormationsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IFormationsGetPaginationApi
    }
}

export type IFormationGetApiResponse = IApiCustomResponse & {
    datas?: {
        formation: IFormation
    }
}

export type IFormationsAllGetApiResponse = IApiCustomResponse & {
    datas?: {
        formations: IFormation[]
    }
}

export type IFormationFormData = {}

export type IFormationCourrierFormData = {
    numeroFacture: number,
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}

export type IFormationsGetFormData = {
    page: number,
    orderBy: string,
    old?: number
}
export type IFormationSendMailListFormData = {
    contenu?: string,
    contenuMail: string,
    subjectMail: string,
    ids?: string[],
    documentsIds?: string[],
}
export type IFormationSendUrssafCourrierFormData = {
    isTest?: boolean
}

export class FormationsService extends ApiService{
    /**
     * Permet la récupération d'une liste de formations
     * @param {IFormationsGetFormData} formData
     * @returns {Promise<IFormationsGetApiResponse>}
     */
    public getFormations(formData: IFormationsGetFormData): Promise<IFormationsGetApiResponse>{
        return this.doApiCall('api/formations', 'GET', formData);
    }

    public getAllFormations(): Promise<IFormationsAllGetApiResponse>{
        return this.doApiCall('api/formations/all', 'GET');
    }

    public getActivesFormations(): Promise<IFormationsAllGetApiResponse>{
        return this.doApiCall('api/formations/actives', 'GET');
    }


    public getFormation(fid: number): Promise<IFormationGetApiResponse>{
        return this.doApiCall('api/formations/' + fid, 'GET');
    }
    public getFormationExternal(fid: number): Promise<IFormationGetApiResponse>{
        return this.doApiCall('api/external/formations/' + fid, 'GET');
    }

    public updateFormationInformations(formDatas: IFormationFormData, fid: number = null): Promise<IFormationGetApiResponse>{
        return this.doApiCall(`api/formations/${fid !== null ? `${fid}/` : ''}informations`, 'POST', formDatas);
    }

    public updateFormationFormation(fid: number,formDatas: IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall(`api/formations/${fid}/formation`, 'POST', formDatas);
    }

    public updateFormationDocuments(fid: number,formDatas: IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall(`api/formations/${fid}/documents`, 'POST', formDatas);
    }

    public updateFormationPrecisions(fid: number,formDatas: IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall(`api/formations/${fid}/precisions`, 'POST', formDatas);
    }

    public updateFormationComplements(fid: number,formDatas: IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall(`api/formations/${fid}/complements`, 'POST', formDatas);
    }

    public updateFormationParticipantEmailId(fid: number, pid:number, formDatas: IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall(`api/formations/${fid}/participants/${pid}/edit-email`, 'POST', formDatas);
    }

    public delete(fid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/formations/' + fid + '/delete', 'POST');
    }

    public updateParticipant(pId: string, formDatas:IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall('api/formations/participants/' + pId, 'POST',formDatas);
    }
    public deleteParticipant(pId: string): Promise<IFormationGetApiResponse>{
        return this.doApiCall('api/formations/participants/' + pId + "/delete", 'POST');
    }

    public saveInscriptionsExternes(fid: number, formDatas:IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall('api/external/formations/'+fid+'/participants/inscriptions-externes', 'POST',formDatas);
    }

    public saveInscriptions(fid: number, formDatas:IFormationFormData): Promise<IFormationGetApiResponse>{
        return this.doApiCall('api/formations/'+fid+'/participants/inscriptions', 'POST',formDatas);
    }

    public exportParticipants(fid: number, etat: string): Promise<IExportApiResponse>{
        return this.doApiCall('api/formations/'+fid+'/participants/export/' + etat, 'POST');
    }

    public emargement(fid: number): Promise<IExportApiResponse>{
        return this.doApiCall('api/formations/'+fid+'/participants/emargement', 'POST');
    }

    /**
     * Envoi d'un courrier d'une formation
     *
     * @param {number} fid
     * @param {IFormationCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(fid: number, formDatas: IFormationCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/formations/' + fid + '/courrier/send', 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier d'une formation
     *
     * @param {number} fid
     * @param {IFormationCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public downloadCourrier(fid: number, formDatas: IFormationCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/formations/' + fid + '/courrier/download', 'POST', formDatas);
    }


    /**
     * Enregistrement d'un courrier d'une formation
     *
     * @param {number} fid
     * @param {IFormationCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public saveCourrier(fid: number, formDatas: IFormationCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/formations/' + fid + '/courrier/save', 'POST', formDatas);
    }

    /**
     * Envoi du mail à une liste de participant
     *
     * @param {number} fid
     * @param {string} mailType
     * @param {IFormationSendMailListFormData} data
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendMailList(fid: number, mailType: string, data: IFormationSendMailListFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/formations/' + fid + '/participants/mail/' + mailType, 'POST',data);
    }

    /**
     * Sauvegarde des mails et des sujets des mails pour les participants
     *
     * @param {number} fid
     * @param {string} mailType
     * @param {IFormationSendMailListFormData} data
     * @returns {Promise<IApiCustomResponse>}
     */
    public saveMailListContent(fid: number, mailType: string, data: IFormationSendMailListFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/formations/' + fid + '/participants/mail/' + mailType + '/save', 'POST',data);
    }


    /**
     * Envoi d'un courrier d'une inspection
     *
     * @param {IFormationSendUrssafCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendUrssafCourrier(formDatas: IFormationSendUrssafCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/formations/urssaf/courrier/send', 'POST', formDatas);
    }
}
