import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IGetUserApiResponse, IUserEditFormData, UsersService} from "src/Services/Users.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IUser} from "src/Models/User.model";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import UserFormComponent from "src/Components/Forms/Users/UserForm.component";
import UserInformationsComponent from "src/Components/Users/UserInformations.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import AsideUtilisateursComponent from "src/Components/Aside/AsideUtilisateurs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IUserContext, UserContext} from "../../Providers/User.provider";

type UserFicheScreenProps = {
    uid: string
}
export default function UserFicheScreen(props: UserFicheScreenProps){
    const identiteAccordionRef = useRef(null);
    const [user, setUser] = useState<IUser>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const usersService: UsersService = new UsersService();
    const history = useHistory();
    const {isAdmin, isSuperAdmin} = useContext(UserContext) as IUserContext;
    const [formOpened, setFormOpened] = useState<boolean>(false);



    useEffect(() => {
        if(!user) return;

        let breadcrumbLinks = [];
        if(isAdmin() || isSuperAdmin()){
            breadcrumbLinks.push( {text: "Utilisateurs", link:"/utilisateurs"});
            breadcrumbLinks.push({text: user.nom + ' ' + user.prenom});
        }
        else{
            breadcrumbLinks.push( {text: "Utilisateurs"});
            breadcrumbLinks.push({text: user.nom + ' ' + user.prenom});
        }


        setBreadcrumbLinks(breadcrumbLinks);
    }, [user, loaded])



    useEffect(() => {
        if( !props.uid ){
            // On redirige vers la home
            history.push(AppConstants.pathUtilisateurs);
        }

        getUser();

    }, [props.uid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getUser = (): void => {
        usersService.getUser(props.uid).then((response: IGetUserApiResponse) => {
            if( response && response.datas.user){
                setUser(response.datas.user);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        },(error: IApiCustomResponse) => {
            history.push(AppConstants.pathUtilisateurs);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de l'utilisateur",type: "danger"});
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmit = (): void => {
        getUser();

        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm();
        }
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancel = (): void => {
        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm(true);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={user.nom + ' ' + user.prenom} icon="icon-notaires" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={identiteAccordionRef}
                                    title="Informations"
                                    icon="icon-informations"
                                    contentShowedOnInit={true}
                                    contentType="user"
                                    contentId={user.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<UserFormComponent user={user} submitMethod={(formData:IUserEditFormData)=>usersService.updateUser(user.id, formData)} onSuccessSubmit={onSuccessSubmit} onCancel={onCancel} />}
                                    infos={<UserInformationsComponent user={user} />}
                                />


                                <AsideUtilisateursComponent type="fiche" user={user} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
