import React, {useContext} from "react";
import {IUserContext, UserContext} from "../Providers/User.provider";
import {Redirect, Route, RouteProps} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";


type AuthRouteProps = RouteProps & {
}

const AuthRoute = (authRouteProps: AuthRouteProps) => {
    // On récupère uniquement les propriétés de props qu'on souhaite. Le reste est stocké dans rest et est envoyé tel quel au composant route
    // component: Component ici n'est pas un typage mais une assignation de la propriété component à la variable "Component"
    const {component: Component, ...rest} = authRouteProps;
    const {isAuthenticated} = useContext(UserContext) as IUserContext;


    // Utilisateur n'ayant pas les droits nécessaires
    if (!isAuthenticated) {
        return <Redirect push to={AppConstants.pathLogin} />
    }
    else {
        // Droits suffisants on retourne la route
        return <Route {...rest} render={(props) => <Component {...props} />}/>;
    }
};

export default AuthRoute;
