import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {CorrespondancesService, ICorrespondanceConfirmationFormData} from "src/Services/Correspondances.service";
import useCorrespondanceParticipant from "src/Hooks/UseCorrespondanceParticipant";
import {ICorrespondance} from "src/Models/Correspondance.model";
import FormCreatorComponent, {FormColumn, FormElement,} from "../../../Components/Forms/FormCreator.component";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import CalendarComponent from "../../../Components/Calendar/Calendar.component";
import {ICorrespondanceParticipant} from "../../../Models/CorrespondanceParticipant.model";
import {CorrespondanceConstants} from "../../../Constants/CorrespondancesConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import DateHelper from "../../../Helpers/Date.helper";


type ICorrespondanceInscriptionExternalScreenProps = {
    pid: number,
    type: 'normal' | 'test';
}
export default function CorrespondanceInscriptionExternalScreen(props: ICorrespondanceInscriptionExternalScreenProps) {
    const {getExternalCorrespondanceParticipantById, getExternalCorrespondanceParticipantTestById} = useCorrespondanceParticipant();
    const correspondancesServices: CorrespondancesService = new CorrespondancesService();
    const [correspondance, setCorrespondance] = useState<ICorrespondance>(null);
    const [correspondanceParticipant, setCorrespondanceParticipant] = useState<ICorrespondanceParticipant>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const history = useHistory();
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);


    useEffect(() => {
        if (!props.pid) {
            history.push(AppConstants.path403);
        }

        getCorrespondance();

    }, [props.pid]);

    useEffect(()=>{
        if(!correspondance) return;
        let nextColumn: number = 1;

        let formElementsColumn1: FormElement[] = [
        ];
        let formElementsColumn2: FormElement[] = [
        ];
        let formElementsColumn3: FormElement[] = [
        ];

        if(correspondance.reunion === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "reunion",
                label: "Réunion",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }
        if(correspondance.repas === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "repas",
                label: "Repas",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }
        if(correspondance.cocktail === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "cocktail",
                label: "Cocktail",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }
        if(correspondance.hebergement === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "hebergement",
                label: "Hébergement",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }
        if(correspondance.transport === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "transport",
                label: "Transport",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }
        if(correspondance.presence === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "presence",
                label: "Presence",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }
        if(correspondance.collaborateur === "oui"){
            const column: FormElement = {
                type: 'radio',
                fieldName: "collaborateur",
                label: "Collaborateur",
                required: true,
                modificators: "-on-white",
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            };

            if(nextColumn === 1){
                formElementsColumn1.push(column);
                nextColumn = 2;
            }
            else if(nextColumn === 2){
                formElementsColumn2.push(column);
                nextColumn = 3;
            }
            else{
                formElementsColumn3.push(column);
                nextColumn = 1;
            }
        }

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];

        setFormColumns(currentFormColumns);
    },[loaded, correspondance]);

    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getCorrespondance = (): void => {
        const callback = (correspondanceParticipant: ICorrespondanceParticipant) => {
            setCorrespondanceParticipant(correspondanceParticipant);
            setCorrespondance(correspondanceParticipant.correspondance);
            if (!loaded) {
                setLoaded(true);
            }
        };


        if( props.type === 'test'){
            getExternalCorrespondanceParticipantTestById(props.pid, callback );
        }
        else{
            getExternalCorrespondanceParticipantById(props.pid, callback );
        }

    };


    /**
     * Soumission du formulaire
     *
     * @param {ICorrespondanceConfirmationFormData} data
     */
    const onSubmit = (data: ICorrespondanceConfirmationFormData) => {
        const submitMethod = props.type === 'test' ? correspondancesServices.saveInscriptionTestExternal(props.pid, data) : correspondancesServices.saveInscriptionExternal(props.pid, data);

        submitMethod.then(() => {
            setSubmitted(true);
        }, (error) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue.",
                    type: "danger"
                });
            }
        })
    };

    return (
        <>
            {
                loaded &&
				<LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
					<PageHeaderComponent text="Présence" icon="icon-correspondances" modificators={`-in-fullscreen -border-full-screen`}/>

                    {
                        submitted &&
                        <p className="informations-header">
                            <span className="title">
                                Merci, votre réponse a bien été prise en compte.
                            </span>
                        </p>
                    }

                    {
                        !submitted &&
                        <>
                            <div className="informations-header">
                                <h3 className="title">{correspondance.titre}</h3>
                            </div>


                            <div className="informations-wrapper correspondances-wrapper">
                                <div className="columns">
                                    <div className="column -auto">
                                        <CalendarComponent date1={correspondance.date} date2={null}/>
                                    </div>

                                    <div className="column">
                                        <div className="informations-block">
                                            <p className="label">Horaire</p>
                                            <p className="value">
                                                {correspondance.date ? DateHelper.getFormatedHour(correspondance.date) : ""}
                                            </p>
                                        </div>

                                        <div className="informations-block">
                                            <p className="label">Type</p>
                                            <p className="value mb-2">
                                                {CorrespondanceConstants.types[correspondance.type]}
                                            </p>
                                        </div>
                                        {
                                            correspondance.lieu &&
                                            <div className="informations-block">
                                                <p className="label">Lieu</p>
                                                <p className="value">{correspondance.lieu}</p>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        {
                                            correspondance.repas && correspondance.repas === "oui" &&
                                            <div className="informations-block">
                                                <p className="label">Repas</p>
                                                <p className="value">
                                                    {
                                                        correspondance.repasNom &&
                                                        <>Nom: <strong>{correspondance.repasNom}</strong><br/></>
                                                    }
                                                    {
                                                        correspondance.repasHeure &&
                                                        <>Heure: <strong>{correspondance.repasHeure}</strong><br/></>
                                                    }
                                                    {
                                                        correspondance.repasLieu &&
                                                        <>Lieu: <strong>{correspondance.repasLieu}</strong><br/></>
                                                    }
                                                    {
                                                        !correspondance.repasNom && !correspondance.repasHeure && !correspondance.repasLieu &&
                                                        <>Oui</>
                                                    }
                                                </p>
                                            </div>
                                        }
                                        {
                                            correspondance.cocktail && correspondance.cocktail === "oui" &&
                                            <div className="informations-block">
                                                <p className="label">Cocktail</p>
                                                <p className="value">
                                                    {
                                                        correspondance.cocktailNom &&
                                                        <>Nom: <strong>{correspondance.cocktailNom}</strong><br/></>
                                                    }
                                                    {
                                                        correspondance.cocktailHeure &&
                                                        <>Heure: <strong>{correspondance.cocktailHeure}</strong><br/></>
                                                    }
                                                    {
                                                        correspondance.cocktailLieu &&
                                                        <>Lieu: <strong>{correspondance.cocktailLieu}</strong><br/></>
                                                    }
                                                    {
                                                        !correspondance.cocktailNom && !correspondance.cocktailHeure && !correspondance.cocktailLieu &&
                                                        <>Oui</>
                                                    }
                                                </p>
                                            </div>
                                        }
                                        {
                                            correspondance.hebergement && correspondance.hebergement === "oui" &&
                                            <div className="informations-block">
                                                <p className="label">Hébergement</p>
                                                <p className="value">
                                                    {
                                                        correspondance.hebergementNom &&
                                                        <>Nom: <strong>{correspondance.hebergementNom}</strong><br/></>
                                                    }
                                                    {
                                                        correspondance.hebergementLieu &&
                                                        <>Lieu: <strong>{correspondance.hebergementLieu}</strong><br/></>
                                                    }
                                                    {
                                                        !correspondance.hebergementLieu && !correspondance.hebergementNom &&
                                                        <>Oui</>
                                                    }
                                                </p>
                                            </div>
                                        }
                                        {
                                            correspondance.transport && correspondance.transport === "oui" &&
                                            <div className="informations-block">
                                                <p className="label">Transport</p>
                                                <p className="value">
                                                    {
                                                        correspondance.transportDetail &&
                                                        <>Détail: <strong>{correspondance.transportDetail}</strong><br/></>
                                                    }
                                                </p>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="informations-header -margin-top">
                                <h3 className="title">Confirmation de présence</h3>
                            </div>

                            <FormCreatorComponent formColumns={formColumns} formActions={[
                                {
                                    label: "Enregistrer",
                                    modificators: "-secondary",
                                    onAction: onSubmit
                                }
                            ]}
                              errorFieldsMessages={errorFieldsMessages}
                              errorMessages={errorMessages}
                              modificatorsActions="-outside-right"/>
                        </>
                    }
				</LayoutComponent>
            }
        </>
    )
}
