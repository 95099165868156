import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {
    ICorrespondanceGetApiResponse,
    ICorrespondanceFormData,
    CorrespondancesService
} from "src/Services/Correspondances.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICorrespondance} from "src/Models/Correspondance.model";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {GroupesService, IGroupesGetApiResponse} from "../../../../Services/Groupes.service";
import {IGroupe} from "../../../../Models/Groupe.model";
import GroupesHelper from "../../../../Helpers/Groupes.helper";
import {AppConstants} from "../../../../Constants/AppConstants";
import {ICorrespondanceInvite} from "../../../../Models/CorrespondanceInvite.model";
import {ICorrespondanceTest} from "../../../../Models/CorrespondanceTest.model";
import ConfirmationComponent from "../../../Confirmation/Confirmation.component";


type CorrespondanceInvitesFormProps = {
    correspondance?: ICorrespondance,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ICorrespondanceFormData) => Promise<ICorrespondanceGetApiResponse>,
    onSuccessSubmit: (response: ICorrespondanceGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ICorrespondanceGetApiResponse) => void,
    onCancel: () => void,
}

export default function CorrespondanceInvitesForm(props: CorrespondanceInvitesFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [groupes, setGroupes] = useState<IGroupe[]>([]);
    const [groupesLoaded, setGroupesLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();

    const correspondancesService: CorrespondancesService = new CorrespondancesService();
    const [showConfirmationAnnulation, setShowConfirmationAnnulation] = useState<boolean>(false);
    const [showTestConfirmationAnnulation, setShowTestConfirmationAnnulation] = useState<boolean>(false);
    const [inviteSelected, setInviteSelected] = useState<ICorrespondanceInvite>(null);
    const [testSelected, setTestSelected] = useState<ICorrespondanceTest>(null);


    /**
     * Initialisation du formulaire à partir des infos de la correspondance
     */
    useEffect(()=>{
        const groupesServices: GroupesService = new GroupesService();
        groupesServices.getAll().then((response:IGroupesGetApiResponse) => {
            if(response && response.datas && response.datas.groupes){
                setGroupes(response.datas.groupes);
                setGroupesLoaded(true);
            }
        });
    }, [])

    useEffect(() => {
        const {correspondance} = props;
        if(!groupes || !groupes.length) return;

        const elementsInvite: FormElement[] = [];
        for(let invite of correspondance.invites){
            let label: string = "";

            if( invite.notaire){
                label = invite.notaire.nom + " " + invite.notaire.prenom;
            }
            else if( invite.personneExterieure){
                label = invite.personneExterieure.nom + " " + invite.personneExterieure.prenom;
            }
            else{
                label = invite.nom + " " + invite.prenom;
            }

            elementsInvite.push({
                type: "buttonDeleteAction",
                label: label,
                action: () => {
                    setInviteSelected(invite);
                    setShowConfirmationAnnulation(true)
                }
            });
        }

        const elementsTests: FormElement[] = [];
        for(let test of correspondance.tests){
            let labelTest: string = "";

            if( test.notaire){
                labelTest = test.notaire.nom + " " + test.notaire.prenom;
            }
            else if( test.personneExterieure){
                labelTest = test.personneExterieure.nom + " " + test.personneExterieure.prenom;
            }
            else{
                labelTest = test.nom + " " + test.prenom;
            }

            elementsTests.push({
                type: "buttonDeleteAction",
                label: labelTest,
                action: () => {
                    setTestSelected(test);
                    setShowTestConfirmationAnnulation(true)
                }
            });
        }


        const currentFormColumns: FormColumn[] = [
            {
                elements: [
                    {
                        type: 'selectMultiple',
                        fieldName: "groupes",

                        label: "Groupes",
                        placeholder: "Groupes",
                        required: false,
                        modificators: "-full",
                        oldValues: GroupesHelper.getIdsFromList(correspondance.groupes),
                        options: groupes ? GroupesHelper.formatListForOptions(groupes): []
                    },
                ]
            },
            {
                elements: [
                    {
                        type: "title",
                        oldValue: "Invité(s) individuel(s)"
                    },
                    ...elementsInvite,
                    {
                        type: "link",
                        label: "Gérer les invités individuels",
                        path: `${AppConstants.pathCorrespondances}/${props.correspondance.id}/invites-individuel`,
                        linkClick: (formData) => onSubmit(formData, false)
                    }
                ]
            },
            {
                elements: [
                    {
                        type: "title",
                        oldValue: "Test(s) individuel(s)"
                    },
                    ...elementsTests,
                    {
                        type: "link",
                        label: "Gérer les tests",
                        path: `${AppConstants.pathCorrespondances}/${props.correspondance.id}/tests`,
                        linkClick: (formData) => onSubmit(formData, false)
                    }
                ]
            }
        ];

        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);
    },[groupes]);




    /**
     * Annule la demande d'annulation
     */
    const onCancelAnnulation = () => {
        setInviteSelected(null);
        setShowConfirmationAnnulation(false);
    };
    const onCancelTest = () => {
        setTestSelected(null);
        setShowTestConfirmationAnnulation(false);
    };

    const cancelInvite = (id?: number) => {
        let inviteSelectedId = null;
        if(id){
            inviteSelectedId = id;
        } else {
            inviteSelectedId = inviteSelected.id
        }

        correspondancesService.cancelInvite(inviteSelectedId).then((result) => {
            setInviteSelected(null);
            setTestSelected(null);
            setShowConfirmationAnnulation(false);
            setShowTestConfirmationAnnulation(false);


            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'invitation a bien été supprimée",
                type: "success"
            });
            if(props.onSuccessSubmit){
                props.onSuccessSubmit(result);
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression.",
                type: "danger"
            });
        });
    };
    const cancelTest = () => {
        correspondancesService.cancelTest(testSelected.id).then((result) => {
            setInviteSelected(null);
            setTestSelected(null);
            setShowConfirmationAnnulation(false);
            setShowTestConfirmationAnnulation(false);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le contact de test a bien été supprimé",
                type: "success"
            });
            if(props.onSuccessSubmit){
                props.onSuccessSubmit(result);
            }
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression.",
                type: "danger"
            });
        });
    };



    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: ICorrespondanceGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }



            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "La correspondance a bien été créée." : "La correspondance a bien été mise à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                groupesLoaded &&
                <FormCreatorComponent
                  formColumns={formColumns}
                  formActions={formActions}
                  modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                  errorFieldsMessages={errorFieldsMessages}
                  errorMessages={errorMessages}
                />
            }

            {
                showConfirmationAnnulation &&
                <ConfirmationComponent onConfirm={()=>cancelInvite()} onCancel={onCancelAnnulation} text="Souhaitez-vous vraiment supprimer cette invitation ?"/>
            }
            {
                showTestConfirmationAnnulation &&
                <ConfirmationComponent onConfirm={()=>cancelTest()} onCancel={onCancelTest} text="Souhaitez-vous vraiment supprimer ce test ?"/>
            }
        </div>
    )
}
