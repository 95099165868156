import {INotaire} from "src/Models/Notaire.model";
import {IOffice} from "../../../Models/Office.model";
import {IHistorique} from "../../../Models/Historique.model";
import DateHelper from "../../../Helpers/Date.helper";
import AdressesHelper from "../../../Helpers/Adresses.helper";
import {ICoordonnee} from "../../../Models/Coordonnee.model";
import CoordonneesHelper from "../../../Helpers/Coordonnees.helper";
import {AppConstants} from "../../../Constants/AppConstants";
import {Link} from "react-router-dom";

type INotaireOfficeComponentProps = {
    notaire: INotaire,
    isAncienNotaire?: boolean,
}

export default function NotaireOfficeComponent(props: INotaireOfficeComponentProps) {
    const notaire: INotaire = props.notaire;
    const historiqueEnCours: IHistorique = notaire.historiqueEnCours || null;
    const office: IOffice = historiqueEnCours && historiqueEnCours.office ? historiqueEnCours.office : null;

    return (


        <div className="informations-wrapper -break-inside-avoid">
            {
                office &&
                <>
                    <div className="columns">
                        <p className="title"><Link to={`${AppConstants.pathOffices}/${office.id}`} target="_blank" rel="noopener noreferrer">{ office.nom}</Link></p>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">CRPCEN</p>
                                <p className="value">{office.CRPCEN}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Office</p>
                                <p className="value"><Link to={`${AppConstants.pathOffices}/${office.id}`} target="_blank" rel="noopener noreferrer">{ office.nom}</Link></p>
                            </div>
                        </div>
                        {!props.isAncienNotaire &&
                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Date d'arrivée</p>
                                    <p className="value">{historiqueEnCours.dateArrivee ? DateHelper.getFormatedDate(historiqueEnCours.dateArrivee) : '-'}</p>
                                </div>
                            </div>
                        }
                        {props.isAncienNotaire && office.adressePrincipale && office.adressePrincipale.arrondissement &&
                            <div className="informations-block">
                                <p className="label">Arrondissement</p>
                                <p className="value">{AdressesHelper.getFormatedArrondissement(office.adressePrincipale.arrondissement)}</p>
                            </div>
                        }
                    </div>

                    {
                        ! props.isAncienNotaire && office.adressePrincipale &&
                        <div className="columns" >
                            <p className="title">Adresse</p>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Rue</p>
                                    <p className="value">{office.adressePrincipale.ligne1 ? office.adressePrincipale.ligne1: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Code Postal</p>
                                    <p className="value">{office.adressePrincipale.codePostal ? office.adressePrincipale.codePostal: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Tribunal Judiciaire</p>
                                    <p className="value">{office.adressePrincipale.tribunalJudiciaire ? AdressesHelper.getFormatedTribunal(office.adressePrincipale.tribunalJudiciaire): "-"}</p>
                                </div>
                            </div>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Complément d'adresse</p>
                                    <p className="value">{office.adressePrincipale.ligne2 ? office.adressePrincipale.ligne2: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Ville</p>
                                    <p className="value -uppercase">{office.adressePrincipale.ville ? office.adressePrincipale.ville: "-"}</p>
                                </div>
                            </div>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Boîte Postale</p>
                                    <p className="value">{office.adressePrincipale.boitePostale ? office.adressePrincipale.boitePostale: "-"}</p>
                                </div>
                                <div className="informations-block">
                                    <p className="label">Arrondissement</p>
                                    <p className="value">{office.adressePrincipale.arrondissement ? AdressesHelper.getFormatedArrondissement(office.adressePrincipale.arrondissement): "-"}</p>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        ! props.isAncienNotaire && office.coordonnees && office.coordonnees.map((coordonnee: ICoordonnee, index: number) =>
                            <div className="columns" key={index} >
                                <div className="column">
                                    <div className="informations-block">
                                        <p className="label">Type</p>
                                        <p className="value">{coordonnee.type ? CoordonneesHelper.getFormatedType(coordonnee.type) : "-"}</p>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="informations-block">
                                        <p className="label">Destination</p>
                                        <p className="value">{coordonnee.destination ? CoordonneesHelper.getFormatedDestination(coordonnee.destination) : "-"}</p>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="informations-block">
                                        <p className="label">Détail</p>
                                        <p className="value">
                                            {
                                                coordonnee.coordonnees && (coordonnee.type === 'url') &&
                                                <a href={coordonnee.coordonnees} target="_blank" rel="noopener noreferrer" className={"link"} >{coordonnee.coordonnees}</a>
                                            }
                                            {
                                                coordonnee.coordonnees && (coordonnee.type === 'telephone') &&
                                                <>{CoordonneesHelper.formatPhoneNumberForDisplay(coordonnee.coordonnees)}</>
                                            }
                                            {
                                                coordonnee.coordonnees && (coordonnee.type !== 'url'&& coordonnee.type !== 'telephone') &&
                                                <>{coordonnee.coordonnees}</>
                                            }
                                            {
                                                !coordonnee.coordonnees && "-"
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>

            }
        </div>
    );
}
