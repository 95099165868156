import NumberHelper from "src/Helpers/Number.helper";
import NumberExtendedFieldComponent from "src/Components/Fields/NumberExtended.field.component";
import {useEffect, useState} from "react";

type ICotisationSaisieItemComponentProps = {
    isEditing: boolean,
    value: number,
    fieldName: string,
    onValueChange: (fieldName: string, newValue: string) => void,
    digits?: number
}

export default function CotisationSaisieItemComponent(props: ICotisationSaisieItemComponentProps) {
    const {value, fieldName, onValueChange} = props;
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect( () => {
        setIsEditing(props.isEditing);
        }, [props.isEditing]);


    const onChange = (newValue: string) => {
        props.onValueChange(props.fieldName, newValue);
    }


    return (
        <>
            {
                !isEditing &&
                    <>{NumberHelper.formatThousand(value, props.digits ? props.digits : 2, props.digits ? props.digits : 2)} €</>
            }
            {
                isEditing &&
                <NumberExtendedFieldComponent fieldType="number" fieldName={fieldName} onChange={onChange} oldValue={value ? value.toString() : "0"} modificators="-in-table" />
            }
        </>
    )
}
