import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ISuccession} from "src/Models/Succession.model";
import {ISuccessionGetApiResponse, ISuccessionReponseExternalFormData} from "src/Services/Successions.service";
import OfficesHelper from "src/Helpers/Offices.helper";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {IOfficesGetAllApiResponse, OfficesService} from "src/Services/Offices.service";
import {IOffice} from "src/Models/Office.model";



type ISuccessionReponseFormProps = {
    succession?: ISuccession,
    isOnCreationStep?: boolean

    submitMethod: (formData: ISuccessionReponseExternalFormData) => Promise<ISuccessionGetApiResponse>,
    onSuccessSubmit: (response: ISuccessionGetApiResponse) => void,
    onCancel: () => void,
}

export default function SuccessionReponseFormComponent(props: ISuccessionReponseFormProps){
    const [offices,setOffices] = useState<IOffice[]>([]);
    const [officeLoaded,setOfficesLoaded] = useState<boolean>(false);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);




    useEffect(() => {
        const officesService: OfficesService = new OfficesService();
        officesService.getAllOfficesPrincipales().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });
    }, []);



    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const succession: ISuccession = props.succession;
        if(!offices && !offices.length) return;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "officeId",

                label: "Office",
                placeholder: "Sélectionnez un office",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.office && succession.office.id ? succession.office.id.toString() : "",

                options: offices ? OfficesHelper.formatListForOptions(offices) : [],
            },
        ];
        for(let office of offices){
            formElementsColumn1.push({
                type: 'select',
                fieldName: "notaireId",

                label: "Notaire",
                placeholder: "Notaire",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.notaire && succession.notaire.id ? succession.notaire.id.toString() : "",
                condition:{
                    fieldName: "officeId",
                    value: office.id.toString()
                },

                options: office.notaires ? NotairesHelper.formatListForOptions(office.notaires) : [],
            })
        }

        formElementsColumn1.push({
            type: 'wysiwyg',
            fieldName: "commentaires",

            label: "Commentaires",
            placeholder: "Commentaires",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white -wysiwyg" : '-wysiwyg',

            oldValue: succession && succession.commentaires ? succession.commentaires : "",
        });


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1,
                modificators: "-size-2"
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [];
        currentFormActions = [
            {
                label: "Enregistrer",
                modificators: "-primary",
                onAction: (formData) => {onSubmit(formData, false)}
            },
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: onCancel
            }
        ];
        setFormActions(currentFormActions);

    },[props.succession, offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISuccessionReponseExternalFormData).then((response: ISuccessionGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Votre réponse a bien été prise en compte.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de l'état de la succession.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                officeLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }

        </div>
    )
}
