import {ISuccession} from "../Models/Succession.model";
import DateHelper from "./Date.helper";
import {SuccessionsConstants} from "../Constants/SuccessionsConstants";
import CommonHelper from "./Common.helper";

export default class SuccessionsHelper{


    /**
     * Récupération de l'état formaté
     *
     * @param {string} etat
     * @returns {string}
     */
    static getFormatedEtat(etat: string): string{
        return CommonHelper.getFormatedElementFromOptions(etat, SuccessionsConstants.etatsOptions);
    }


    /**
     * Permet d'avoir le résume sur la naissance de la personne décédé
     * @param {ISuccession} succession
     * @returns {string}
     */
    static getNaissanceResume(succession: ISuccession): string{
        let resume: string = "";

        if(succession.identiteNaissanceDate){
            resume +=  "Le " + DateHelper.getFormatedDate(succession.identiteNaissanceDate);
        }

        if(succession.identiteNaissanceLieu){
            resume += " à " +succession.identiteNaissanceLieu;

            if(succession.identiteNaissanceDepartement){
                resume += " (" + succession.identiteNaissanceDepartement.toString().substr(0,2) + ")";
            }
        }

        return resume;
    }

    /**
     * Permet d'avoir le résumé sur les informations du deces de la succession
     *
     * @param {ISuccession} succession
     * @returns {string}
     */
    static getDecesResume(succession: ISuccession): string{
        let resume: string = "";

        if(succession.identiteDecesDate){
            resume += "Le " + DateHelper.getFormatedDate(succession.identiteDecesDate);
        }

        if(succession.identiteDecesLieu){
            resume += " à " + succession.identiteDecesLieu;
        }

        if(succession.identiteDecesDepartement){
            resume += " (" + succession.identiteDecesDepartement.toString().substr(0,2) + ")";
        }

        return resume;

    }

    /**
     * Permet d'avoir le résume sur le demandeur de la succession
     *
     * @param {ISuccession} succession
     * @returns {string}
     */
    static getDemandeurResume(succession: ISuccession): string{
        let resume: string = "";
        if( succession.demandeurRaisonSociale ){
            resume += succession.demandeurRaisonSociale + " - ";
        }
        if( succession.demandeurNom ){
            resume += succession.demandeurNom + "  ";
        }
        if( succession.demandeurPrenom ){
            resume += succession.demandeurPrenom;
        }

        return resume;
    }
}
