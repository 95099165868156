import React, {useEffect, useState} from "react";
import SeeMoreLinkComponent from "src/Components/Links/SeeMoreLink.component";
import InformationHeaderComponent from "../Informations/InformationHeader.component";
import DateHelper from "../../Helpers/Date.helper";

type IAccordionHeaderProps = {
    isContentShowed: boolean,
    contentTypeShowed?: string,
    showEditButton?: boolean,
    hideToggleButton?: boolean,
    icon?: string,
    updatedDate?: string,
    title: string,

    onToggle: () => void,
    onClickEditButton?: () => void,
}


export default function AccordionHeaderComponent(props: IAccordionHeaderProps){
    const [active, setActive] = useState<boolean>(props.isContentShowed);

    useEffect(() => {
        setActive(props.isContentShowed);
    }, [props.isContentShowed])


    /**
     * Gestion de l'état actif ou non de l'accordeon
     *
     * @param {boolean} show
     */
    const onToggle = (): void => {
        props.onToggle();
    }


    return (
        <header className={`accordion-header ${active ? '-active' : ''}`}>
            <div className="left" onClick={ onToggle }>
                <InformationHeaderComponent icon={props.icon} title={props.title} />
                {
                    !props.hideToggleButton &&
                    <i className="arrow icon-arrow-small-down" />
                }

            </div>
            {
                props.showEditButton && active && props.contentTypeShowed !== "form" &&
                <div className="right">
                    <SeeMoreLinkComponent text="Modifier" icon="icon-cog" modificators="-small" callback={props.onClickEditButton} />
                </div>
            }



            {
                active && props.contentTypeShowed !== "form" && props.updatedDate &&
                <div className="date-modification">
                    Date de modification : {DateHelper.getFormatedDate(props.updatedDate, true)}
                </div>
            }

        </header>

    )
}
