import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {ICorrespondance} from "src/Models/Correspondance.model";
import {CorrespondancesService} from "src/Services/Correspondances.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {ICorrespondanceParticipant} from "src/Models/CorrespondanceParticipant.model";

type IAsideCorrespondanceComponentProps = {
    correspondance?: ICorrespondance,
    participants?: ICorrespondanceParticipant[],
    modificators?: string,
    type: "list" | "fiche" | "inscriptions" | 'ficheCourriers' | 'ficheListInvite' | 'ficheListInviteTest',

    exportCallback?: () => void,
    emargementCallback?: () => void,
    activeTab?: string,
    filters?: any,
}

export default function AsideCorrespondanceComponent(props: IAsideCorrespondanceComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if(props.type == "ficheListInvite"){
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-courriers-add", click: () => {
                history.push(`${AppConstants.pathCorrespondances}/${props.correspondance.id}/invites-individuel/#${props.activeTab}`)
            }});

        }
        else if(props.type === "ficheListInviteTest") {
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            const correspondance: ICorrespondance = props.correspondance;
            if (correspondance && correspondance.envoye === 'non') {
                actions.push({icon: "icon-tests-add", click: () => {
                    history.push(`${AppConstants.pathCorrespondances}/${props.correspondance.id}/tests/#${props.activeTab}`);
                }});
            }
        }
        else if(props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
        }
        else{
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        if(props.type === "list") {
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-correspondances-add", click: () => history.push(AppConstants.pathCorrespondances + "/creation")});
        }

        if(props.type === "fiche") {
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
            const correspondance: ICorrespondance = props.correspondance;

            if (correspondance && correspondance.envoye === 'non') {
                actions.push({icon: "icon-courriers-add", click: () => {
                    history.push(`${AppConstants.pathCorrespondances}/${props.correspondance.id}/invites-individuel/#${props.activeTab}`);
                }});

                actions.push({icon: "icon-tests-add", click: () => {
                    history.push(`${AppConstants.pathCorrespondances}/${props.correspondance.id}/tests/#${props.activeTab}`)
                }});
            }
        }

        setActions(actions);
    }, [props.type, props.filters, props.activeTab, props.participants]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    };

    /**
     * Suppression
     */
    const doDelete = (): void => {
        const correspondancesService: CorrespondancesService = new CorrespondancesService();

        correspondancesService.delete(props.correspondance.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La correspondance a bien été supprimée.",
                type: "success"
            });

            history.push(AppConstants.pathCorrespondances);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression de la correspondance.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    };

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    };


    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathCorrespondances}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }

    return (
        <>
            {
                showConfirmation && props.correspondance &&
				<ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer cette correspondance ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
