import {ISuccession} from "src/Models/Succession.model";
import SuccessionsHelper from "src/Helpers/Successions.helper";
import DateHelper from "../../Helpers/Date.helper";
import {AppConstants} from "../../Constants/AppConstants";
import {Link} from "react-router-dom";
import React from "react";

type ISuccessionEtatComponentProps = {
    succession: ISuccession,
}

export default function SuccessionEtatComponent(props: ISuccessionEtatComponentProps) {
    const succession: ISuccession = props.succession;
    const succesionReponseLien: string = `${AppConstants.pathSuccessions}/${succession.id}/reponse`;


    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date</p>
                        <p className="value">{succession.date ? DateHelper.getFormatedDate(succession.date) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Identifiant</p>
                        <p className="value">{succession.id}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Etat</p>
                        <p className="value">{succession.etat ? SuccessionsHelper.getFormatedEtat(succession.etat) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Lien de réponse</p>
                        <p className="value"><Link to={succesionReponseLien} target="_blank" rel="noopener noreferrer">{succesionReponseLien}</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
