import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IDifferend} from "src/Models/Differend.model";
import {IDifferendGetApiResponse, DifferendsService} from "src/Services/Differends.service";

const UseDifferend = () => {
    const differendsService: DifferendsService = new DifferendsService();
    const history = useHistory();

    /**
     *
     * Récupération d'un differend à partir de son id
     *
     * @param {string} did
     * @param {(differend: IDifferend) => void} callback
     * @param {() => void} callbackError
     */
    const getDifferendById = (did: string, callback:(differend: IDifferend) => void, callbackError?: () => void ) => {
        differendsService.getDifferend(did).then((response: IDifferendGetApiResponse) => {
            if( response && response.datas.differend){
                if(callback){
                    callback(response.datas.differend);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathDifferends);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du differend'",type: "danger"});
        });
    };


    return {getDifferendById};
};

export default UseDifferend;
