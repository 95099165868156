import React, {useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import FormCreatorComponent, { FormColumn, FormComponentFormData } from "src/Components/Forms/FormCreator.component";
import ApiService from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import LoaderComponent from "../Loading/Loader.component";

const ExportComponent = (props: {
    children?: React.ReactNode,
    onClose: () => void,
    modificators?: string,
    title: string,
    subTitle?:string,
    icon?:string,
    columns: FormColumn[],
    exportUrl: string,
    sendFullObject?: boolean,

    filters?: any
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const apiService = new ApiService();

    const onFormSubmit = (formData: FormComponentFormData): void => {
        setIsLoading(true);
        let fields: string[]|FormComponentFormData = [];

        for (let values of Object.values(formData)) {
            if (values) {
                fields = fields.concat(values);
            }
        }


        apiService.export(props.exportUrl,props.sendFullObject ? formData : fields, props.sendFullObject, props.filters ).then((response)=>{
            setIsLoading(false);

            if (response && response.datas.url) {
                const url = response.datas.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ``);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }
        }).catch((e)=>{
            setIsLoading(false);

            console.error(e);
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
        });
    };

    return <div className={`export-screen ${props.modificators}`}>
        <button className="export-close icon-close" onClick={props.onClose} />

        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-secondary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text={props.title} subText={props.subTitle || ''} icon={props.icon || ''} modificators={`-in-fullscreen`}/>

            {
                isLoading &&
                <div className="form-loading -export">
                    <LoaderComponent />
                </div>
            }
            <FormCreatorComponent
                formColumns={props.columns}
                formActions={[
                    {
                        label: "Annuler",
                        modificators: "-is-link -on-second",
                        onAction: props.onClose
                    },
                    {
                        label: "Exporter",
                        modificators: "-primary -on-second",
                        onAction: onFormSubmit
                    }
                ]}
                errorFieldsMessages={null}
                errorMessages={null}
                modificatorsActions="-outside-right"
            />
        </LayoutComponent>
    </div>
};

export default ExportComponent;
