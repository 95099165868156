import {IDifferend} from "../../Models/Differend.model";
import DateHelper from "../../Helpers/Date.helper";
import DifferendHelper from "../../Helpers/Differend.helper";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import React from "react";

type IDifferendDifferendComponentProps = {
    differend: IDifferend,
}

export default function DifferendDifferendComponent(props: IDifferendDifferendComponentProps) {
    const differend: IDifferend = props.differend;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de réception</p>
                        <p className="value">{ differend.dateReception ? DateHelper.getFormatedDate(differend.dateReception) : '-' }</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Etat</p>
                        <p className="value">{DifferendHelper.getFormattedEtat(differend.etat)}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Objet</p>
                        <p className="value">{DifferendHelper.getFormattedObjet(differend.objet)}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Référence</p>
                        <p className="value">{differend.reference}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date de modification</p>
                        <p className="value">{ differend.dateModification ? DateHelper.getFormatedDate(differend.dateModification) : '-' }</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date de convocation devant le bureau</p>
                        <p className="value">{ differend.dateConvocationBureau ? DateHelper.getFormatedDate(differend.dateConvocationBureau, true) : '-' }</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Note</p>
                        <div className="value">
                            <CommentairesComponent commentaires={differend.note} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
