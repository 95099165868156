import DateHelper from "../../Helpers/Date.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import FileHelper from "../../Helpers/File.helper";
import React, {useEffect, useState} from "react";
import {IDocument} from "../../Models/Document.model";
import {AppConstants} from "../../Constants/AppConstants";
import DocumentsHelper from "src/Helpers/Documents.helper";
import SelectFieldComponent from "../Fields/Select.field.component";
import {ICtrc} from "../../Models/Ctrc.model";
import {CtrcsConstants} from "../../Constants/CtrcsConstants";
import CtrcsHelper from "../../Helpers/Ctrcs.helper";

type ICtrcDocumentsComponentProps = {
    ctrc: ICtrc,
}

export default function CtrcDocumentsComponent(props: ICtrcDocumentsComponentProps) {
    const [documentsList, setDocumentsList] = useState<IDocument[]>([]);

    useEffect(() => {
        if (props.ctrc && props.ctrc.documents) {
            const newDocumentsList: IDocument[] = props.ctrc.documents;
            setDocumentsList(newDocumentsList);
        }
    }, [props.ctrc])


    return (
        <>
            <div className="informations-wrapper">
                {
                    documentsList && (documentsList.length > 0) && documentsList.map((d, index) =>
                        <div className="columns" key={index}>
                            <p className="title">{`${DateHelper.getMonthFromDate(d.date)} ${DateHelper.getYearFromDate(d.date)}`}</p>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Date</p>
                                    <p className="value">{d.date ? DateHelper.getFormatedDate(d.date) : '-'}</p>
                                </div>

                                <div className="informations-block">
                                    <p className="label">Document</p>
                                    {
                                        !d.fichier &&
                                        <p className="value">-</p>
                                    }
                                    {
                                        d.fichier &&
                                        <DocumentLink icon="icon-documents"
                                                      link={d.fichier ? FileHelper.getFileUrl(d.fichier) : '-'}/>
                                    }
                                </div>
                            </div>

                            <div className="column">
                                <div className="informations-block">
                                    <p className="label">Type</p>
                                    <p className="value">{d.type ? CtrcsHelper.getFormatedDocumentTypes(d.type) : '-'}</p>
                                </div>

                                {d.type === 'autre' &&
                                    <div className="informations-block">
                                        <p className="label">Commentaires</p>
                                        <p className="value">
                                            {d.commentaires ? d.commentaires : '-'}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}
