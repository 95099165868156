export default class NumberHelper {


    /**
     * Permet de formater un nombre avec les espaces entre les milliers
     *
     * @param {number} value
     * @param {number} digits
     * @param {number} minimumDigits
     * @returns {string}
     */
    static formatThousand = (value: number, digits?: number, minimumDigits?: number): string => {
        if(!value) return null;

        if(typeof(digits) == "undefined"){
            digits = 0;

            //Dans le cas où le nombre de décimals n'a pas été indiqué
            //On regarde la présence de décimals dans la valeur et on récumère le nombre de décimals
            const valueArray: string[] = value.toString().split('.');
            if(valueArray.length > 1){
                digits = valueArray[1].length <= 4 ? valueArray[1].length : 2;
            }
        }

        const options: any = {maximumFractionDigits: digits};
        if(minimumDigits){
            options.minimumFractionDigits = minimumDigits;
        }

        const formatedNumber: string = new Intl.NumberFormat('fr-FR',options).format(value);
        const formatedNumberUpdated = formatedNumber.replaceAll(' ', '  ');

        return formatedNumberUpdated;
    }
}
