import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type IReclamationsConstants = {
    etatOptions: IOption[],
    etatAutreOptions: IOption[],
    documentsTypesOptions: IOption[],
    documentsOrderOptions: IOption[],
    fondementsOption: IOption[],
    objetOptions: IOption[],
    origineOptions: IOption[],
    prochaineEtapeOptions: IOption[],
    civilitePlaignantOptions: IOption[],
    formuleAppelPlaignantOptions: IOption[],
    creationSteps: ICreationSteps[],
    typesPersonnesOptions: IOption[],
    conciliationOptions: IOption[],
    conciliationIssueOptions: IOption[],
    conciliationMotifOptions: IOption[],
}
export const ReclamationsConstants: IReclamationsConstants = {
    etatOptions : [
        {
            value: "secretariat",
            label: "Secrétariat",
        },
        {
            value: "syndic",
            label: "Syndic",
        },
        {
            value: "signature",
            label: "À la signature",
        },
        {
            value: "a-surveiller",
            label: "À surveiller",
        },
        /*
        {
            value: "rc",
            label: "RC",
        },
         */
        {
            value: "action-terminee",
            label: "Action terminée",
        },
        {
            value: "autre",
            label: "Autre",
        },
    ],
    etatAutreOptions: [
        {
            value: 'rc',
            label: 'RC',
        },
        {
            value: "abusif",
            label: "Abusif",
        },
        {
            value: "mal-fonde",
            label: "Mal fondé",
        },
        {
            value: "autre-notaire",
            label: "Autre notaire",
        },
        {
            value: "incomplet",
            label: "Incomplet",
        },
        {
            value: "demande-reparation",
            label: "Demande réparation",
        }
    ],
    objetOptions : [

        {
            value: "droit-immobilier",
            label: "Droit immobilier (ventes, prêts, VEFA, …)",
        },
        {
            value: "negociation-immobiliere",
            label: "Négociation immobilière",
        },
        {
            value: "succession",
            label: "Droit de la famille – succession",
        },

        {
            value: "droit-famille-autres",
            label: "Droit de la famille – autres",
        },
        {
            value: "droit-affaires",
            label: "Droit des affaires (société, fonds de commerce,…)",
        },
        {
            value: "liquidation",
            label: "Liquidation (ancienne réclamation)",
        },
        {
            value: "immobilier",
            label: "Immobilier (ancienne réclamation)",
        },
        {
            value: "autre",
            label: "Autre (ancienne réclamation)",
        },
    ],

    origineOptions : [
        {
            value: "plaignant",
            label: "Plaignant",
        },
        {
            value: "procureur",
            label: "Procureur",
        },
        {
            value: "avocat",
            label: "Avocat",
        },
        /*
        {
            value: "relation-client",
            label: "Relation Client",
        },
         */
    ],

    /*
    prochaineEtapeOptions : [
        {
            value: "",
            label: "Tous",
        },
        {
            value: "reception",
            label: "AR au plaignant",
            modificators: "-with-circle-icon",
            circleColorModificators: "-color-actif",
        },
        {
            value: "rappel",
            label: "1er rappel au notaire",
            modificators: "-with-circle-icon",
            circleColorModificators: "-color-rappel",
        },
        {
            value: "convocation",
            label: "Convocation notaire",
            modificators: "-with-circle-icon",
            circleColorModificators: "-color-report",
        },
        {
            value: "bureau",
            label: "À soumettre au bureau",
            modificators: "-with-circle-icon",
            circleColorModificators: "-color-annule",
        },
    ],
     */
    prochaineEtapeOptions : [
        {
            value: "",
            label: "Tous",
        },
        {
            value: "ar-plaignant",
            label: "AR au plaignant",
            modificators: "-with-circle-icon",
            circleLetter: "A",
            circleColorModificators: "-color-actif",
        },
        {
            value: "convocation-conciliation",
            label: "Convocation conciliation",
            modificators: "-with-circle-icon",
            circleLetter: "C",
            circleColorModificators: "-color-report",
        },
        {
            value: "ar-plaignant&&convocation-conciliation",
            label: "AR au plaignant et Convocation conciliation",
            modificators: "-with-circle-icon",
            circleLetter: "A&C",
            circleColorModificators: "-color-actif-border-report",
        },
    ],

    civilitePlaignantOptions: [
        {
            value: "madame",
            label: "Madame",
        },
        {
            value: "monsieur",
            label: "Monsieur",
        },
        {
            value: "maitre",
            label: "Maître",
        },
    ],

    formuleAppelPlaignantOptions: [
        {
            value: "madame",
            label: "Madame",
        },
        {
            value: "monsieur",
            label: "Monsieur",
        },
        {
            value: "maitre",
            label: "Maître",
        },
        {
            value: "madame-monsieur",
            label: "Madame, Monsieur",
        },
        {
            value: "mesdames-messieurs",
            label: "Mesdames et Messieurs",
        },
        {
            value: "messieurs",
            label: "Messieurs",
        },
        {
            value: "mesdames",
            label: "Mesdames",
        },
    ],

    fondementsOption: [
        {
            value: "delai-traitement-dossier-trop-long",
            label: "Délai de traitement du dossier trop long",
        },
        {
            value: "aucune-communication",
            label: "Absence d’explication, de communication ou d’échange d’information sur le dossier",
        },
        {
            value: "erreur-notaire-affaire",
            label: "Erreur ou faute présumée du notaire dans un acte ou dans le déroulement d’une affaire",
        },
        {
            value: "contestation-honoraires",
            label: "Contestation sur la rémunération des actes et des prestations autres (honoraires)",
        },
        {
            value: "negligence-presumee-notaire",
            label: "Négligence présumée du notaire dans le dossier",
        },
        {
            value: "probleme-juridique",
            label: "Problème juridique (ancienne réclamation)",
        },
        {
            value: "autre",
            label: "Autre (ancienne réclamation)",
        },
    ],

    documentsTypesOptions: [
        {
            value: "reclamation",
            label: "Réclamation",
        },
        {
            value: "ar-au-plaignant",
            label: "AR au Plaignant ",
        },
        {
            value: "ar-au-procureur",
            label: "AR au Procureur ",
        },
        {
            value: "demande-explication-notaire",
            label: "Demande explication notaire",
        },
        /*
        {
            value: "demande-explication-notaire-transmise-par-le-procureur",
            label: "Demande explication notaire transmise par le procureur",
        },
         */
        {
            value: "explication-notaire",
            label: "Explication du notaire",
        },
        /*
        {
            value: "explication-du-notaire-non-conforme",
            label: "Explication du notaire non conforme",
        },
        {
            value: "courrier-notaire-reponse-conforme",
            label: "Courrier notaire réponse non conforme",
        },
        */
        {
            value: "reponse-syndic-plaignant",
            label: "Réponse syndic au plaignant",
        },
        {
            value: "reponse-syndic-au-notaire",
            label: "Réponse syndic au notaire",
        },
        {
            value: "courrier-dattente-au-plaignant",
            label: "Courrier d’attente au plaignant",
        },
        {
            value: "courrier-rc",
            label: "Courrier RC",
        },
        /*
        {
            value: "1er-rappel-au-notaire",
            label: "1er rappel au notaire",
        },
        {
            value: "convocation",
            label: "Convocation",
        },
         */
        {
            value: "courrier-au-plaignant",
            label: "Courrier au plaignant",
        },
        {
            value: "reclamation-incomplete",
            label: "Réclamation incomplète",
        },
        {
            value: "reclamation-autre-notaire",
            label: "Réclamation autre notaire",
        },
        {
            value: "reclamation-rc",
            label: "Réclamation RC - demande de réparation",
        },
        {
            value: "reclamation-irrecevable",
            label: "Réclamation irrecevable",
        },
        {
            value: "courrier-demande-complement",
            label: "Courrier demande de complément",
        },
        {
            value: "courrier-autre-notaire",
            label: "Courrier autre notaire",
        },
        {
            value: "courrier-procedure-rc-client",
            label: "Courrier procédure RC client",
        },
        {
            value: "courrier-procedure-rc-notaire",
            label: "Courrier procédure RC notaire",
        },
        {
            value: "courrier-reclamation-irrecevable",
            label: "Courrier réclamation irrecevable",
        },
        {
            value: "convocation-conciliation-notaire",
            label: "Convocation conciliation notaire",
        },
        {
            value: "convocation-conciliation-plaignant",
            label: "Convocation conciliation plaignant",
        },
        {
            value: "pv-conciliation",
            label: "PV de conciliation",
        },
        {
            value: "pv-non-conciliation",
            label: "PV de non-conciliation",
        },
        {
            value: "attestation-carence",
            label: "Attestation carence",
        },
        {
            value: "courrier-attestation-carence",
            label: "Courrier attestation carence",
        },
        /*
        {
            value: "courrier-au-notaire-pour-penalites-encourues",
            label: "Courrier au notaire pour pénalités encourues",
        },
        {
            value: "courrier-au-notaire-pour-penalites-retenues",
            label: "Courrier au notaire pour pénalités retenues",
        },
         */
        {
            value: "reclamation-autre",
            label: "Autre",
        },
    ],

    creationSteps: [
        {
            url: "/plaignants-adresses",
            title: "Adresses des plaignants",
            icon: "icon-coordonnees",
        },
        {
            url: "/plaignants-coordonnees",
            title: "Coordonnées des plaignants",
            icon: "icon-coordonnees-adresses",
        },
        {
            url: "/reclamation",
            title: "Reclamation",
            icon: "icon-reclamations",
        },
        {
            url: "/documents",
            title: "Documents",
            icon: "icon-documents",
        },
    ],
    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "commentaires",
            label: "Commentaires"
        },
        {
            value: "type",
            label: "Type"
        },
    ],

    typesPersonnesOptions: [
        {
            'value': 'physique',
            'label': 'Personne physique'
        },
        {
            'value': 'morale',
            'label': 'Personne morale'
        },
    ],

    conciliationOptions: [
        {
            value: "oui",
            label: "Oui"
        },
        {
            value: "non",
            label: "Non"
        },
        {
            value: "",
            label: "Pas encore",
        },
    ],
    conciliationIssueOptions: [
        {
            value: "pv-conciliation",
            label: "PV de conciliation",
        },
        {
            value: "pv-non-conciliation",
            label: "PV de non-conciliation",
        },
        {
            value: "carence",
            label: "Carence",
        },
    ],
    conciliationMotifOptions: [
        {
            value: "mal-fonde",
            label: "Mal fondé",
        },
        {
            value: "accord-trouve",
            label: "Accord trouvé",
        },
        {
            value: "conciliation-prejudiciable-aux-parties",
            label: "Conciliation préjudiciable aux parties",
        },
        {
            value: "disciplinaire",
            label: "Disciplinaire",
        },
    ],
};
