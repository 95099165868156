import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {IUser} from "src/Models/User.model";


export type IUserServiceLoginDatas ={
    email: string,
    password: string,
}
export type IUserServiceResetPasswordDatas = {
    token: string,
    password: string,
    password_confirmation: string,
}
export type IUserEditFormData = {
    nom?: string,
    prenom?: string,
    email?: string,
    password?: string,
    password_confirmation?: string,
    role?: string,
    modules?: string[]
}
export type IGetUserApiResponse = IApiCustomResponse & {
    datas: {
        user: IUser
    }
}
export type IUsersGetPaginationApi = IApiPagination & {
    data: IUser[],
}
export type IUsersGetListApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IUsersGetPaginationApi
    }
}
export type IUsersGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    role?: string[],
}


export class UsersService extends ApiService{
    constructor(authUser: IUser = null){
        super(authUser);
    }

    /**
     * Permet la connexion de l'utilisateur
     *
     * @param {IUserServiceLoginDatas} data
     * @returns {Promise<IApiCustomResponse>}
     */
    public login(data: IUserServiceLoginDatas): Promise<IApiCustomResponse>{
        return this.doApiCall('api/login', 'POST', data);
    }


    /**
     * Mot de passe oublié
     *
     * @param {string} email
     * @returns {Promise<IApiCustomResponse>}
     */
    public forgotPassword(email: string): Promise<IApiCustomResponse>{
        return this.doApiCall('api/password/forgot', 'POST', {email});
    }


    /**
     * Permet la déconnection d'un utilisateur
     *
     * @returns {Promise<IApiCustomResponse>}
     */
    public logout(): Promise<IApiCustomResponse> {
        return this.doApiCall('api/logout', 'GET');
    }


    /**
     * Permet la récupération de l'utilisateur connecté
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getMe(): Promise<IGetUserApiResponse>{
        return this.doApiCall('api/users/me', 'GET');
    }


    /**
     * Permet la récupération d'un utilisateur
     *
     * @param {IUsersGetFormData} data
     * @returns {Promise<IUsersGetListApiResponse>}
     */
    public getUsers(data: IUsersGetFormData): Promise<IUsersGetListApiResponse>{
        return this.doApiCall('api/users', 'GET', data);
    }

    /**
     * Permet la récupération d'un utilisateur
     *
     * @param {string} uid
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getUser(uid: string): Promise<IGetUserApiResponse>{
        return this.doApiCall('api/users/' + uid, 'GET' );
    }


    /**
     * Réinitialisation du mot de passe
     *
     * @param {IUserServiceResetPasswordDatas} data
     * @returns {Promise<IApiCustomResponse>}
     */
    public resetPassword(data: IUserServiceResetPasswordDatas): Promise<IApiCustomResponse>{
        return this.doApiCall('api/password/reset', 'POST', data);
    }


    /**
     * Mise à jour des données de l'utilisateur connecté
     *
     * @param {IUserEditFormData} data
     * @returns {Promise<IGetUserApiResponse>}
     */
    public updateAuthUser(data: IUserEditFormData): Promise<IGetUserApiResponse>{
        return this.doApiCall('api/users/me/update', 'POST', data);
    }


    /**
     * Mise à jour des données d'un utilisateur
     *
     * @param {string} uid
     * @param {IUserEditFormData} data
     * @returns {Promise<IGetUserApiResponse>}
     */
    public updateUser(uid: string, data: IUserEditFormData): Promise<IGetUserApiResponse>{
        return this.doApiCall('api/users/'+uid+'/update', 'POST', data);
    }



    /**
     * Mise à jour des données d'un utilisateur
     *
     * @param {IUserEditFormData} data
     * @returns {Promise<IApiCustomResponse>}
     */
    public create(data: IUserEditFormData): Promise<IGetUserApiResponse>{
        return this.doApiCall('api/users/create', 'POST', data);
    }


    /**
     * Permet de récupérer la liste des slugs des modules autorisé pour un utilisateur
     *
     * @param {IUser} user
     * @returns {string[]}
     */
    public getAllowedModulesSlugsFromUser(user: IUser): string[]{
        const allowedModules: string[] = [];
        if(user.modules){
            for(let module of user.modules){
                if(module.active){
                    allowedModules.push(module.slug);
                }
            }
        }

        return allowedModules;
    }

    /**
     * Permet la suppression d'un utilisateur
     *
     * @param {string} uid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(uid: string): Promise<IApiCustomResponse>{
        return this.doApiCall('api/users/' + uid + '/delete', 'POST');
    }
}

