import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "../FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IFilInformations} from "src/Models/FilInformations.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {
    IFilInformationsBandeauAccueilFormData,
    IFilInformationsDetailsGetApiResponse,
    IFilInformationsUneFormData
} from "src/Services/FilInformations.service";
import bgPhoto from "src/assets/images/placeholders/picture-upload.png";


export default function FilInformationsBandeauAccueilFormComponent(props: {
    filInformations?: IFilInformations,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IFilInformationsUneFormData) => Promise<IFilInformationsDetailsGetApiResponse>,
    onSuccessSubmit: (response: IFilInformationsDetailsGetApiResponse) => void,
    onCancel: () => void,
}) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    useEffect(() => {
        const {filInformations} = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'file',
                fieldName: "imageBandeauAccueil",

                label: "Photo",
                placeholder: "Photo",
                required: false,
                background: bgPhoto,
                modificators: props.isOnCreationStep ? "-on-white -small-file" : '-small-file',
                showImage: true,
                showImageDeleteButton: true,
                extensions: ['.jpeg', '.jpg', '.png', '.gif'],
                showMentions: true,
                textMention: "ATTENTION<br/>Le titre et le logo doivent être intégrés à l'image.<br/>La taille de l'image doit être 640x357.",

                oldValue: filInformations && filInformations.imageBandeauAccueil ? filInformations.imageBandeauAccueil : "",
            },
        ];


        const currentFormColumns: FormColumn[] = [
            {
                modificators:"",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, []);

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IFilInformationsBandeauAccueilFormData).then((response: IFilInformationsDetailsGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le bandeau du fil d'accueil a été modifié.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification du bandeau du fil d'information.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };




    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                isStickyAction={true}
                errorMessages={errorMessages} />
        </div>
    )
}
