import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type SinistresExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,

}
export default function SinistresExportComponent(props: SinistresExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Dossiers/Sinistres"}
                         icon="icon-sinistres"
             subTitle={"Export des sinistres"}
             onClose={() => props.setShowExport()}
             modificators={`${!props.showExport ? '-hide' : ''}`}
             exportUrl= {`api${AppConstants.pathSinistres}/export`}
             filters={props.filters || null}

             columns={[
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-informations',
                             fieldName: "informations",
                             label: "Informations",
                             options: [
                                 {value: 'notaires.assignes', label: 'Notaire(s) mis en cause'},
                                 {value: 'libelle', label: 'Libellé du dossier'},
                                 {value: 'dateOuverture', label: 'Année d\'ouverture'},
                                 {value: 'numeroDossier', label: 'Numéro de dossier'},
                                 //{value: 'notaires.demandeurs', label: 'Notaire(s) demandeur(s)'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-description',
                             fieldName: "description",
                             label: "Description",
                             options: [
                                 {value: 'source', label: 'Source'},
                                 {value: 'notaires.rapporteur', label: 'Rapporteur'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-ctrcs',
                             fieldName: "ctrcs",
                             label: "CTRC",
                             options: [
                                 {value: 'sinistre.ctrc', label: 'CTRC'},
                             ]
                         },
                     ]
                 },
             ]}
        />
    )
}
