import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {ISignaturesGetFormData, ISignaturesListGetApiResponse, SignaturesService} from "src/Services/Signatures.service";
import {ISignature} from "src/Models/Signature.model";
import AsideSignaturesComponent from "src/Components/Aside/AsideSignatures.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";

type SignaturesListScreenProps = {

}
export default function SignaturesListScreen(props: SignaturesListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [signaturesList, setSignaturesList] = useState<ISignature[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder] = useListFilters("/correspondances/signatures");
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    /**
     * Initialisation
     */
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Correspondances", link: AppConstants.pathMosaique + '#correspondances'},
            { text: "Signatures" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);

        const params: any = location.search;
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);

    /**
     * Changement des filtres
     */
    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Lancement de la recherche
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const signaturesService: SignaturesService = new SignaturesService();

        signaturesService.getSignatures(filters as ISignaturesGetFormData).then((response: ISignaturesListGetApiResponse) => {
            if( response && response.datas.pagination){
                setSignaturesList(response.datas.pagination.data);
                setPaginationLinks(response.datas.pagination.links);

                if(!loaded){
                    setLoaded(true);
                }
            }
        });
    }



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text="Signatures" icon="icon-signature"/>


                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {["Nom"].map((item, iIndex)=><th className="th" key={iIndex}>
                                                <TableHeaderButtonComponent direction={filters.orderType as string || AppConstants.orderType.asc} value={item} selectedValue={filters.orderBy as string || ""} label={item} click={(value:string)=>setOrder(value)} />
                                            </th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            signaturesList && signaturesList.map( (signature: ISignature, index: number) =>
                                                <tr key={index} onClick={()=>history.push(`/correspondances/signatures/${signature.id}`)} className="tr">
                                                    <td className="td -bold">
                                                        {signature.nom}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    <PaginationComponent links={paginationLinks}  onPageClick={page=>updateFilters({page})}/>


                                    <AsideSignaturesComponent type='list' />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    )
}
