import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "../FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import useCreationSteps from "../../../Hooks/useCreationSteps";
import {IFilInformations} from "../../../Models/FilInformations.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import {
    FilInformationsService,
    IFilInformationsDetailsGetApiResponse,
    IFilInformationsPresseFormData,
    IFilInformationsUneFormData, IRevuePresse
} from "../../../Services/FilInformations.service";
import FilInformationsHelper from "../../../Helpers/FilInformations.helper";


export default function FilInformationsPresseFormComponent(props: {
    filInformations?: IFilInformations,
    isOnCreationStep?: boolean,


    submitMethod: (formData: IFilInformationsPresseFormData) => Promise<IFilInformationsDetailsGetApiResponse>,
    onSuccessSubmit: (response: IFilInformationsDetailsGetApiResponse) => void,
    onCancel: () => void,
}) {
    const filInfosService: FilInformationsService = new FilInformationsService();
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    useEffect(()=>{
        filInfosService.getRevuesPresse().then((result)=>{
            generateForm(result.datas || []);
        },()=>{
            generateForm([]);
        });
    },[]);

    const generateForm = (revues: IRevuePresse[]) => {
        const {filInformations} = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: "selectMultiple",
                fieldName: "revues",
                label: "Revues de presse",
                hideSearchOnMultiple: true,
                oldValues: filInformations.revues || [],
                options: FilInformationsHelper.getRevuesPresseOptionsForSelect(revues),
            },
            {
                type: 'repeater',
                fieldName: "presses",
                label: "Article",
                oldRepeatableValues: filInformations.presses as FormComponentFormData,
                formColumns: [
                    {
                        modificators: '',
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: ""
                            },
                            {
                                type: 'text',
                                fieldName: "titre",
                                label: "Titre",
                                required: true,
                                oldValue: "",
                                modificators: ""
                            },
                            {
                                type: 'date',
                                fieldName: "date",
                                label: "Date",
                                required: false,
                                oldValue: "",

                                modificators: ""
                            }
                        ],
                    },
                    {
                        modificators: '',
                        elements: [
                            {
                                type: 'file',
                                fieldName: "document",
                                modificators: '-small-file',
                                label: "Document",
                                required: true, 
                                oldValue: "",
                                extensions: ['.jpeg', '.jpg', '.png', '.gif', '.pdf'],
                            }
                        ],
                    },
                    {
                        modificators: '',
                        elements: [
                            {
                                type: 'file',
                                fieldName: "image",
                                modificators: '-small-file',
                                label: "Image",
                                required: false,
                                showImage: true,
                                showImageDeleteButton: true,
                                oldValue: "",
                                extensions: ['.jpeg', '.jpg', '.png', '.gif'],
                            }
                        ],
                    },
                ],
                required: false,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    };

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IFilInformationsUneFormData).then((response: IFilInformationsDetailsGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le fil d'information a bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification du fil d'information.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };




    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                isStickyAction={true}
                errorMessages={errorMessages} />
        </div>
    )
}
