import {IOption} from "../Components/Fields/Select.field.component";
import {ILabel} from "../Models/Label.model";
import CommonHelper from "./Common.helper";
import {LabelsConstants} from "../Constants/LabelsConstants";

export default class LabelsHelper{


    /**
     * Permet de formater une liste de labels pour les selects
     *
     * @param {ILabel[]} labels
     * @returns {IOption[]}
     */
    static formatListForOptions(labels: ILabel[]): IOption[]{
        if(!labels || !labels.length) return [];

        const options: IOption[] = [];
        for(let office of labels){
            const option: IOption = {
                value: office.id.toString(),
                label: office.nom
            }

            options.push(option)
        }

        return options;
    }



    /**
     * Permet de récupérer le module formaté
     *
     * @param {string} module
     * @returns {string}
     */
    static getFormatedModule(module: string): string {
        return CommonHelper.getFormatedElementFromOptions(module, LabelsConstants.moduleOptions);
    }
}
