import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {useHistory, useLocation} from "react-router";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import SinistresHelper from "src/Helpers/Sinistres.helper";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import LoadingComponent from "../../Components/Loading/Loading.component";
import CtrcsHelper from "../../Helpers/Ctrcs.helper";
import {
    CtrcsService,
    ICtrcGetAllApiResponse,
    ICtrcsGetFormData,
    ICtrcsListGetApiResponse
} from "../../Services/Ctrcs.service";
import {ICtrc} from "../../Models/Ctrc.model";
import AsideCtrcsComponent from "../../Components/Aside/AsideCtrcs.component";
import CtrcsExportComponent from "../../Components/Ctrc/CtrcsExport.component";
import CtrcsAdvancedSearchComponent from "../../Components/Ctrc/CtrcsAdvancedSearch.component";
import {INotaire} from "../../Models/Notaire.model";
import {INotaireGetFormData, INotairesGetApiResponse, NotairesService} from "../../Services/Notaires.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import RadioButtonsField from "../../Components/Fields/RadioButtons.field.component";
import NotairesAdvancedSearchComponent from "../../Components/Annuaire/Notaires/NotairesAdvancedSearch.component";
import {Link} from "react-router-dom";
import SelectFieldComponent from "../../Components/Fields/Select.field.component";
import AsideNotaireComponent from "../../Components/Aside/AsideNotaire.component";
import NotairesExportComponent from "../../Components/Annuaire/Notaires/NotairesExport.component";


type ICtrcsListScreenProps = {};

export default function CtrcsListScreen(props: ICtrcsListScreenProps) {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [ctrcsList, setCtrcsList] = useState<ICtrc[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathCtrcs);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);

    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [ctrcs, setCtrcs] = useState<ICtrc[]>([]);
    const [ctrcsLoaded, setCTRCsLoaded] = useState<boolean>(false);

    const location = useLocation();
    const history = useHistory();

    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Sinistres", link: AppConstants.pathMosaiqueSinistres},
            {text: "CTRC"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);

        //À partir des paramètres d'URLs, vérifier s'il y a un paramètre activeTab
        if (defaultFilters.activeTab) {
            setActiveTab(defaultFilters.activeTab as string);
        }

        updateFilters(defaultFilters, false, true);
    }, []);


    useEffect(() => {
        if (!filters) return null;//Permet de ne pas retirer les filtres au chargement de la page
        //Quand on change d'onglet/tab, mettre à jour le filtre activeTab (pour changer le paramètre dans l'URL)
        updateFilters({activeTab: activeTab}, false);

        //Récupération des CTRCs complet (pour l'onglet des notaires observateurs)
        if (activeTab === TabsSlugs.SINISTRES_NOTAIRES_OBSERVATEURS) {
            const ctrcsService: CtrcsService = new CtrcsService();
            ctrcsService.getAll().then((response: ICtrcGetAllApiResponse) => {
                if (response && response.datas && response.datas.ctrcs) {
                    setCtrcs(response.datas.ctrcs);
                    setCTRCsLoaded(true);
                }
            });
        }
    }, [activeTab]);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters, ...formData}, true);
        setShowAdvancedSearch(false);
    };


    /**
     * Récupération des ctrcs
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        if (activeTab === TabsSlugs.LIST) {
            const ctrcsService: CtrcsService = new CtrcsService();
            ctrcsService.getCtrcs(filters as ICtrcsGetFormData).then((response: ICtrcsListGetApiResponse) => {
                if (response && response.datas.pagination) {
                    const newCtrcsList = response.datas.pagination.data;
                    setCtrcsList(newCtrcsList);

                    //Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }

        if (activeTab === TabsSlugs.SINISTRES_NOTAIRES_OBSERVATEURS) {
            const notairesServices: NotairesService = new NotairesService();
            notairesServices.getNotairesWithPaginate(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // Notaires
                    const currentNotairesList: INotaire[] = response.datas.pagination.data;
                    setNotaires(currentNotairesList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }
    };

    //Sur le listing des notaires observateurs, permettre de modifier le CTRC observé (dernière colonne)
    const changeNotaireCTRCObserve = (value: string, notaire: INotaire) => {
        const notairesServices: NotairesService = new NotairesService();

        let formData = null;
        if (value !== '') {
            formData = {'ctrcObserveId': parseInt(value)};
        }

        notairesServices.updateCTRCObserve(notaire.id, formData).then((result) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La modification du CTRC du notaire a bien été prise en compte.",
                type: "success"
            });
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la modification.",
                type: "danger"
            });
        });
    };


    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="CTRC"
                    icon="icon-ctrcs"
                    onSearch={(keyword => updateFilters({...defaultFilters, keyword, activeTab: activeTab}, true))}
                    onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent/>
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent defaultTab={activeTab}
                                                onClickTabCallback={(tab) => {
                                                    updateFilters({...defaultFilters, activeTab: activeTab}, true);
                                                    setActiveTab(tab);
                                                }}>
                            <TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.LIST}>
                                {activeTab === TabsSlugs.LIST &&
                                    <div className="list-content -right-gutter">
                                        <CtrcsAdvancedSearchComponent
                                            showSummary={true}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={onAdvancedSearch}
                                        />

                                        <table className={`list-table`}>
                                            <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "nom", label: "Nom"},
                                                    {slug: "date", label: "Date"},
                                                    //{slug: "president", label: "Président"},
                                                    //{slug: "notairesMembresPresences", label: "Membres"},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug}
                                                        selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                ctrcsList && ctrcsList.map((ctrc: ICtrc, index: number) =>
                                                    <tr key={index}
                                                        onClick={() => history.push(`${AppConstants.pathCtrcs}/${ctrc.id}`)}
                                                        className="tr">
                                                        <td className="td -bold">
                                                            {ctrc.nom}
                                                        </td>
                                                        <td className="td">
                                                            {ctrc.date ? DateHelper.getFormatedDate(ctrc.date, false) : ''}
                                                            {ctrc.heure ? ` - ${ctrc.heure}` : ''}
                                                        </td>
                                                        {/*
                                                        <td className="td">
                                                            {ctrc.president ? SinistresHelper.getFormatedNotaires([ctrc.president]) : ''}
                                                        </td>
                                                        <td className="td">
                                                            {ctrc.notairesMembresPresences ? CtrcsHelper.getFormatedNotairesMembresPresences(ctrc.notairesMembresPresences) : ""}
                                                        </td>
                                                        */}
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>


                                        <PaginationComponent links={paginationLinks}
                                                             onPageClick={page => updateFilters({page})}/>

                                        <AsideCtrcsComponent type="list" exportCallback={() => setShowExport(true)}
                                                             filters={filters}/>
                                    </div>
                                }
                            </TabPanelComponent>

                            {/*Repris en grande partie du listing des notaires (Annuaire) */}
                            <TabPanelComponent label="Notaires observateurs" icon="icon-notaires"
                                               slug={TabsSlugs.SINISTRES_NOTAIRES_OBSERVATEURS}>
                                {activeTab === TabsSlugs.SINISTRES_NOTAIRES_OBSERVATEURS &&
                                    <div className="list-content -right-gutter">

                                        <div className="list-filters">
                                            <i className={`icon icon-filtre`}/>
                                            {/*Filtre "rapide" pour ctrcObservé (s'il n'y a pas le filtre avancé par ctrcObserve) */}
                                            {(filters.ctrcObserveId == null) &&
                                                <RadioButtonsField fieldName="ctrcObserve"
                                                                   label="Présence"
                                                                   options={AppConstants.ouiNonPasDeFiltreOptions}
                                                                   modificators="-full -no-margin-b"
                                                                   oldValue={filters.ctrcObserve ? filters.ctrcObserve.toString() : ""}
                                                                   onChange={(value: string) => updateFilters({
                                                                       ctrcObserves: null,
                                                                       ctrcObserve: value
                                                                   })}
                                                />
                                            }
                                        </div>

                                        {/*Recherche avancée repris du listing des notaires, avec le param ctrcs en plus */}
                                        <NotairesAdvancedSearchComponent
                                            title={"Notaires observateurs"}
                                            showSummary={true}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            isActifs={true}
                                            ctrcFilter={true}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={onAdvancedSearch}
                                        />

                                        <table className={`list-table`}>
                                            <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "crpcen", label: "CRPCEN"},
                                                    {slug: "nom", label: "Nom"},
                                                    {slug: "office", label: "Office"},
                                                    {slug: "ctrcObserve", label: "CTRC"},
                                                ].map((item, iIndex) =>
                                                    <th
                                                        className="th" key={iIndex}>
                                                        <TableHeaderButtonComponent
                                                            direction={filters.orderType as string || AppConstants.orderType.asc}
                                                            value={item.slug}
                                                            selectedValue={filters.orderBy as string || ""}
                                                            label={item.label}
                                                            click={(value: string) => setOrder(value)}/>
                                                    </th>)}
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                notaires && notaires.map((notaire: INotaire, index: number) =>
                                                    <tr key={index}
                                                        className="tr">
                                                        <td className="td -bold -w100px">
                                                            <span className={`state-bullet icon-bullet -${notaire.ctrcObserve ? 'oui':'non'}`}>
                                                                &nbsp;
                                                            </span>&nbsp;
                                                            <Link className="g-link -primary -underline-on-hover -bold"
                                                                  target="_blank"
                                                                  to={`${AppConstants.pathNotaires}/${notaire.id}`}
                                                                  onClick={(e) => e.stopPropagation()}>
                                                                {notaire.office && <>{notaire.office.CRPCEN}</>}
                                                            </Link>
                                                        </td>
                                                        <td className="td">
                                                        <Link className="g-link -underline-on-hover"
                                                                  target="_blank"
                                                                  to={`${AppConstants.pathNotaires}/${notaire.id}`}
                                                                  onClick={(e) => e.stopPropagation()}>
                                                                {notaire.prenom} {notaire.nom}
                                                            </Link>
                                                        </td>
                                                        <td className="td">
                                                            {
                                                                notaire.office &&
                                                                <Link className="g-link -underline-on-hover"
                                                                      target="_blank"
                                                                      to={`${AppConstants.pathOffices}/${notaire.office.id}`}
                                                                      onClick={(e) => e.stopPropagation()}>
                                                                    {notaire.office.nom}
                                                                </Link>
                                                            }
                                                        </td>
                                                        <td className="td -w200px">
                                                            {/*Permettre de modifier le CTRC du notaire, le fonctionnement est repris de SuccessionsList */}
                                                            <SelectFieldComponent fieldName="ctrc"
                                                                                  options={CtrcsHelper.getFormatedListForOptions(ctrcs)}
                                                                                  errors={null}
                                                                                  hideSearch={false}
                                                                                  modificators="-on-white -no-margin-b"
                                                                                  oldValue={notaire.ctrcObserve ? notaire.ctrcObserve.id.toString() : ''}
                                                                                  onChange={(value: string) => {
                                                                                      changeNotaireCTRCObserve(value, notaire)
                                                                                  }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>

                                        <div className="list-table">
                                            <em>
                                                Attention : Le cercle de couleur est mis à jour uniquement au chargement de la page. Si un CTRC est spécifié ou retiré, la couleur n'est pas mise à jour.
                                            </em>
                                        </div>

                                        <PaginationComponent links={paginationLinks}
                                                             onPageClick={page => updateFilters({page})}/>


                                        <AsideNotaireComponent type="listNotairesCtrcObserves"
                                                               exportCallback={() => setShowExport(true)}
                                                               filters={filters}/>

                                    </div>
                                }
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            {activeTab === TabsSlugs.LIST &&
                <CtrcsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport}
                                      filters={filters}/>
            }
            {activeTab === TabsSlugs.SINISTRES_NOTAIRES_OBSERVATEURS &&
                <NotairesExportComponent filters={filters} setShowExport={() => setShowExport(false)}
                                         isNotairesObservateurs={true}
                                         showExport={showExport}/>
            }
        </LayoutComponent>
    )
}
