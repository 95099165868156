import React, {useEffect, useState} from "react";
import InputFieldComponent, {IFormField, InputFieldProps} from "./Input.field.component";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "../../Services/Api.service";
import NumberExtendedFieldComponent from "./NumberExtended.field.component";
import {GeoApiService, GeoApiServiceResponse, IVille} from "../../Services/GeoApi.service";
import SelectFieldComponent, {IOption} from "./Select.field.component";

type FromToProps = IFormField & {
    onChange: (value:string) => void,
    oldValue: string
}

const CodePostalVille  = (props: FromToProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [oldValue, setOldValue] = useState<string>(null);
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const [codePostal,setCodePostal] = useState<string>("");
    const [villes,setVilles] = useState<IVille[]>([]);
    const geoService: GeoApiService = new GeoApiService();
    const [showNoResult,setShowNoResult] = useState<boolean>(false);

    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);

    useEffect(()=> {
        if(props.oldValue){
            const parts = props.oldValue.split(" ");
            if(parts.length >= 3){
                setCodePostal(parts[0]);
                onCpUpdate(parts[0], false);
            }

            setOldValue(props.oldValue.toUpperCase());
        }

        setLoaded(true);
    }, [props.oldValue]);

    const onCpUpdate = (value:string, triggerOnPropChange: boolean = true) => {
        //Quand on spécifie le code postal, déclencher le onChange
        //(pour que le formData soit mis à jour même si on modifie que le code postal => permettra de déclencher les vérifications (API))
        //(quand il y a déjà une valeur (oldValue correct), ne pas déclencher)
        if(villes.length === 0 && triggerOnPropChange) {
            props.onChange(value);
        }
        if(value.length < 5){
            setVilles([]);
            setShowNoResult(false);
            return;
        }
        setCodePostal(value);
        geoService.getVilles(value).then((result:GeoApiServiceResponse)=>{
            if(result.records && result.records.length > 0){
                setVilles(result.records)
            }else{
                setShowNoResult(true);
            }
        });
    };

    const onSelectChange = (value:string)=>{
        props.onChange(value);
    };

    const getCpVilleStr = (cp: string, ville: string) => {
        return `${cp} - ${ville}`;
    };

    const getOptions = (villes: IVille[])=>{
        let options = [];
        for (let i = 0; i < villes.length; i++) {
            //Pour chaque résultat de l'API, vérifier que c'est dans le même département (code Insee et le code renvoyé (code postal, code cedex))
            //Exemple : 14012 renvoit 2 résultats : 1 dans le 76 et 1 dans 14
            if(villes[i].fields.insee.substring(0, 2) === villes[i].fields.code.substring(0, 2)) {
                const value = getCpVilleStr(codePostal, villes[i].fields.libelle);
                options.push({value, label: villes[i].fields.libelle});
            }
        }

        let uniqueOptions: any[] = [];
        options.forEach((item) => {
            if( !uniqueOptions.includes(item)){
                uniqueOptions.push(item);
            }
        });

        return uniqueOptions;
    };

    return (
        <>
            {
                loaded &&
                <div className={`form-field ${props.modificators || ""}`}>
                    <LabelComponent fieldName={"cp"} label={"Code Postal"} isRequired={props.required} />

                    <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                        <NumberExtendedFieldComponent modificators={`-full ${props.modificators || ""}`} oldValue={codePostal} fieldType={"text"} onChange={onCpUpdate} maxLength={5}/>
                    </div>
                </div>
            }

            {
                villes.length > 0 &&
                <div className={`form-field ${props.modificators || ""}`}>
                    <LabelComponent fieldName={"ville"} label={"Ville"} isRequired={props.required} />
                    <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                        <SelectFieldComponent oldValue={oldValue} modificators={`-full ${props.modificators || ""}`} hideSearch={true} onChange={onSelectChange} options={getOptions(villes)} />
                    </div>
                </div>
            }
            {showNoResult && <LabelComponent fieldName={""} label={"Aucun résultat"} />}
        </>
    )
};

export default CodePostalVille;
