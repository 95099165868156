import React from "react";
import {IFilInformations} from "../../Models/FilInformations.model";
import DateHelper from "../../Helpers/Date.helper";
import FileHelper from "../../Helpers/File.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import CommonHelper from "../../Helpers/Common.helper";


const FilInformationsInfos = (props: {
    filInformations: IFilInformations
}) => {

    return <div className="informations-wrapper">
        <div className="columns">

            <div className="column">
                <div className="informations-block">
                    <p className="label">Numéro</p>
                    <p className="value">{props.filInformations.numero}</p>
                </div>

                <div className="informations-block">
                    <p className="label">Titre</p>
                    <p className="value">{props.filInformations.titre}</p>
                </div>
            </div>
            <div className="column">
                <div className="informations-block">
                    <p className="label">Date d'envoi</p>
                    <p className="value">
                        {DateHelper.formatDate(props.filInformations.dateEnvoi, "DD/MM/YYYY")}
                    </p>
                </div>
                <div className="informations-block">
                    <p className="label">Envoi automatique (à 15h)</p>
                    <p className="value">
                        { props.filInformations.envoiAuto ? CommonHelper.getFormatedOuiNon(props.filInformations.envoiAuto) : '-'}
                    </p>
                </div>
            </div>

            {props.filInformations.pdf && <div className="column">
                <div className="informations-block">
                    <p className="label">Pdf</p>
                    <div className="value">
                        <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(props.filInformations.pdf)}/>
                    </div>
                </div>
            </div>}
        </div>

    </div>

};

export default FilInformationsInfos;
