import React from "react";
import FormationCreationStepComponent
    from "../../../Components/Formations/Formations/FormationCreationStep.component";

const FormationCreationInformationsScreen = (props:{
    fid: number
}) => {
    return (
        <FormationCreationStepComponent fid={props.fid} type="informations" />
    )
};

export default FormationCreationInformationsScreen;