import {IInspecteur} from "../../../Models/Inspecteur.model";
import InspectionListInspecteurItemComponent from "./InspectionListInspecteurItem.component";
import React, {useContext, useEffect, useState} from "react";
import SeeMoreLinkComponent from "../../Links/SeeMoreLink.component";
import {Redirect} from "react-router";
import {AppConstants} from "../../../Constants/AppConstants";
import {IUserContext, UserContext} from "../../../Providers/User.provider";
import SelectMultiInspecteurField from "../../Fields/SelectMultiInspecteur.field";
import {InspectionsService} from "../../../Services/Inspections.service";
import {INotaire} from "../../../Models/Notaire.model";
import {IPersonneExterieure} from "../../../Models/PersonneExterieure.model";
import LoadingComponent from "../../Loading/Loading.component";

type IInspectionHistoriquesInspecteurListComponentProps = {
    onSuccessSubmitForm: any,
    inspecteurs: IInspecteur[],
    inspectionId: number,
    annee: number,
    index: number,
    pageEditingStatus?: boolean,
    setPageEditingStatus?: (status: boolean) => void,
}

export default function InspectionHistoriquesInspecteurListComponent(props: IInspectionHistoriquesInspecteurListComponentProps) {
    const inspectionsService: InspectionsService = new InspectionsService();
    const inspecteurs: IInspecteur[] = props.inspecteurs;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [pageEditingStatus, setPageEditingStatus] = useState<boolean>(false);
    const {isAuthenticated} = useContext(UserContext) as IUserContext;
    const [inspecteursList,setInspecteursList] = useState<INotaire[]>([]);
    const [observateursList,setObservateursList] = useState<INotaire[]>([]);
    const [inspecteursStarList,setInspecteursStarList] = useState<IPersonneExterieure[]>([]);

    useEffect(()=>{
        const index = props.index || 1;

        setTimeout(() => {
            inspectionsService.getInspecteursList({annee: props.annee}).then((result)=>{
                if(result.datas){
                    setInspecteursList(result.datas.inspecteurs);
                    setInspecteursStarList(result.datas.inspecteursCompta);
                    setObservateursList(result.datas.observateurs);
                    setIsLoaded(true);
                }
            })
        }, index * 300)

    },[props.index]);


    useEffect(() => {
        setPageEditingStatus(props.pageEditingStatus || false);
    }, [pageEditingStatus]);


    //Affichage du formulaire
    const showForm = () => {
        //Vérification si la personne est loggée
        if (!isAuthenticated) {
            return <Redirect push to={AppConstants.pathLogin} />
        }

        setIsEditing(true);
        props.setPageEditingStatus(true);
    };

    //Affichage du formulaire
    const hideForm = () => {
        setIsEditing(false);
        props.setPageEditingStatus(false);
        props.onSuccessSubmitForm();
    };


    return (
        <div className="informations-inspections-historique-wrapper">

            <div className="inspecteurs-list">
                {
                    isLoaded && !isEditing &&
                    <div className="edit-button">
                        <div className="list-edit-button">
                            <SeeMoreLinkComponent text="Modifier" icon="icon-cog" modificators="-small" callback={()=>showForm()} />
                        </div>
                    </div>
                }

                {
                    !isLoaded &&
                    <LoadingComponent />
                }

                {
                    isLoaded && inspecteurs && inspecteurs.map((inspecteur: IInspecteur, index: number) =>
                        <React.Fragment key={index}>
                            {
                                !isEditing &&
                                <InspectionListInspecteurItemComponent inspecteur={inspecteur} isHistorique={true} />
                            }
                        </React.Fragment>
                    )
                }

                {
                    isLoaded && isEditing &&
		            <div className="line">
			            <SelectMultiInspecteurField
                            hideForm={hideForm}
                            inspectionId={props.inspectionId}
                            inspecteursList={inspecteursList}
                            inspecteursStarList={inspecteursStarList}
                            inspecteursObservateurList={observateursList}
                            inspecteursSelected={props.inspecteurs}
                        />
		            </div>
                }
            </div>


        </div>
    );
}
