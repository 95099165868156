import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useProtection from "src/Hooks/UseProtection";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {ICtrc} from "../../Models/Ctrc.model";
import {CtrcsService} from "../../Services/Ctrcs.service";


type IAsideCtrcsComponentProps = {
    ctrc?: ICtrc,
    modificators?: string,
    type: "list" | "fiche" | "fiche-documents" | "fiche-notaires-membres" | "fiche-sinistres-rattaches",

    exportCallback?: () => void,
    generateDocumentRapportsCallback?: () => void,

    activeTab?: string,
    filters?: any
}

export default function AsideCtrcsComponent(props: IAsideCtrcsComponentProps) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const {checkLockProtection, unlockProtection, initProtection} = useProtection();
    const {isAdmin} = useContext(UserContext) as IUserContext;
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        let actions: ListAsideAction[] = [];

        if (props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-ctrcs-add", click: () => history.push(AppConstants.pathCtrcs + "/creation")});
        }

        if (props.type === 'fiche') {
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        }

        if (props.type.startsWith("fiche")) {
            actions.unshift({icon: "icon-print", click: () => printScreen()});
        }

        if (props.type === "fiche-notaires-membres") {
            actions.unshift({icon: "icon-export", click: () => props.exportCallback()});
        }

        if (props.type === 'fiche-documents') {
            actions.unshift({
                icon: "icon-complements",//todo revoir le picto ?
                title: "Générer le document avec les rapports des sinistres",
                click: () => props.generateDocumentRapportsCallback()
            });
        }

        if (props.type === 'fiche-sinistres-rattaches') {
            actions.push({
                icon: "icon-sinistres-add",
                title: "Rattacher un sinistre",
                click: () => {
                    history.push(`${AppConstants.pathCtrcs}/${props.ctrc.id}/sinistres/#${props.activeTab}`)
                }
            });
        }

        setActions(actions);
    }, [props.type, props.filters]);


    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = (): void => {
        setShowConfirmation(true);
    }


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const ctrcsService: CtrcsService = new CtrcsService();

        ctrcsService.delete(props.ctrc.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le CTRC a bien été supprimé.",
                type: "success"
            });

            //TODO Rediriger vers la liste des CTRC
            history.push(AppConstants.pathCtrcs);

        }, (error: IApiCustomResponse) => {
            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du CTRC.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }


    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url: string = `api${AppConstants.pathCtrcs}/export-pdf`;

        exportListPDF(props.filters, url, () => {
            setIsLoading(false)
        });
    }

    return (
        <>
            {
                showConfirmation && props.ctrc &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation}
                                       text="Souhaitez-vous vraiment supprimer ce CTRC ?"/>
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
