import {ICtrc, ICtrcNotaireMembrePresence} from "../Models/Ctrc.model";
import {IOption} from "../Components/Fields/Select.field.component";
import CommonHelper from "./Common.helper";
import {CtrcsConstants} from "../Constants/CtrcsConstants";


export default class CtrcsHelper {
    /**
     * Récupère une liste de ctrcs formatés (pour le sinistre)
     *
     * @param {ICtrc[]} ctrcs
     * @returns {IOption[]}
     */
    static getFormatedListForOptions(ctrcs: ICtrc[], addOptionWithoutCtrc: boolean = false): IOption[] {
        if (!ctrcs || !ctrcs.length) return [];

        const options: IOption[] = [];

        if(addOptionWithoutCtrc) {
            options.push({
                value: 'sans-ctrc',
                label: 'Sans CTRC',
            });
        }

        for (let ctrc of ctrcs) {
            const option: IOption = {
                value: ctrc.id.toString(),
                label: ctrc.nomComplet,
            };

            /*
            const keywords: string[] = [];
            keywords.push(ctrc.nom);
            keywords.push(ctrc.slug);
            option.keywords = keywords;
             */

            options.push(option)
        }

        return options;
    }

    /**
     * Récupère une liste de notaire "simple" (à partir des notairesMembresPresences) formatée pour le CTRC
     * (en retirant le président si demandé)
     *
     * @param {ICtrcNotaireMembrePresence[]} notairesMembresPresences
     * @returns {string}
     */
    static getFormatedNotairesMembresPresences(notairesMembresPresences: ICtrcNotaireMembrePresence[]) : string{
        const notairesFormated = [];

        for(let notaireMembrePresence of notairesMembresPresences){
            if(notaireMembrePresence.notaire != null) {
                notairesFormated.push(`${notaireMembrePresence.notaire.nom} ${notaireMembrePresence.notaire.prenom}`);
            }
        }

        return notairesFormated.join(', ');
    }

    /**
     * Retourne le type de document formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedDocumentTypes(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, CtrcsConstants.documentsTypesOptions);
    }

    /**
     * Permet de récupérer une liste d'id à partir d'une liste de CTRC
     *
     * @param {ICtrc[]} ctrcs
     * @returns {string[]}
     */
    public static getIdsFromList(ctrcs: ICtrc[]): string[]{
        if(!ctrcs || !ctrcs.length) return [];

        const ids: string[] = ctrcs.map((ctrc: ICtrc) => ctrc.id.toString());

        return ids;
    }
}

