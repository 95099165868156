import React, {MouseEvent, useContext, useState} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {Link} from "react-router-dom";

type AccountBlockProps = {
    modificators?: string
}

export default function AccountBlockComponent(props: AccountBlockProps){
    const {authUser, logout} = useContext(UserContext) as IUserContext;
    const [showLinks, setShowLinks] = useState<boolean>(false);

    /**
     * Déconnexion de l'utilisateur
     * @param {MouseEvent} e
     */
    const onLogout = (e: MouseEvent): void => {
        e.preventDefault();
        logout();
    }

    return (
        <div className={`account-block ${props.modificators || ''}`}>
            <p className="name"><span className="lower">{authUser.prenom}</span> <span className="upper">{authUser.nom}</span></p>
            <i className={`picto -secondary -size-2 icon-cog ${showLinks ? '-active' : '' }`} onClick={e => setShowLinks(!showLinks) } />

            <div className="account-block-links">
                <Link to="/mon-compte/modification" className="g-link -right -on-column-list -primary">Modifier mon compte</Link>
                <Link to="" onClick={(e: MouseEvent) => onLogout(e)} className="g-link -right -on-column-list -primary">Se déconnecter</Link>
            </div>
        </div>
    )
}
