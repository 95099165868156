import {IOffice} from "../Models/Office.model";
import {OfficesConstants} from "../Constants/OfficesConstants";
import {IOption, IOptionsGroup} from "../Components/Fields/Select.field.component";
import {IAdresse} from "../Models/Adresse.model";
import CommonHelper from "./Common.helper";
import {ICoordonnee} from "../Models/Coordonnee.model";

export default class OfficesHelper{

    /**
     * Retourne l'adresse principale d'une office
     * @param {IOffice} office
     * @returns {string}
     */
    static getMainAddress(office: IOffice): string {
        let address = "";
        const adresses: IAdresse[] = office.adresses;

        if(adresses && adresses.length) {
            for(let a of adresses){
                if (a.type === "principale") {
                    address = `${a.codePostal} ${a.ville}`;
                }
            }
        }

        return address;
    }

    /**
     * Permet de formater une liste d'organismes pour les selects
     *
     * @param {IOffice[]} offices
     * @returns {IOption[]}
     */
    static formatListForOptions(offices: IOffice[]): IOption[]{
        if(!offices || !offices.length) return [];

        const options: IOption[] = [];
        for(let office of offices){
            const option: IOption = {
                value: office.id.toString(),
                label: office.CRPCEN + ' - ' + office.nom
            }

            options.push(option)
        }

        return options;
    }

    /**
     * Permet de formater une liste d'organismes pour les selects dans un optionGroup
     *
     * @param {IOffice[]} offices
     * @returns {IOptionsGroup[]}
     */
    static formatListForOptionsGroup(offices: IOffice[]): IOptionsGroup[]{
        const optionGroup: IOptionsGroup = {
            "label": "Tout sélectionner",
            "options": []
        }

        if(offices && offices.length > 0) {
            const options: IOption[] = [];
            for (let office of offices) {
                const option: IOption = {
                    value: office.id.toString(),
                    label: office.CRPCEN + ' - ' + office.nom
                }

                options.push(option)
            }

            optionGroup.options = options
        }

        return [optionGroup];
    }



    /**
     * Retourne le type d'office formaté
     * @param {string} type
     * @returns {string}
     */
    static getFormatedType(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, OfficesConstants.typesOptions);
    }


    /**
     * Retourne le logiciel formaté
     *
     * @param {string} langue
     * @param {IOption[]} logicielsOptions
     * @returns {string}
     */
    static getFormatedLogiciel(langue: string, logicielsOptions: IOption[]): string {
        return CommonHelper.getFormatedElementFromOptions(langue, logicielsOptions);
    }
    /**
     * Retourne le logiciel compta formaté
     *
     * @param {string} systemeCompta
     * @returns {string}
     */
    static getFormatedSystemeCompta(systemeCompta: string): string {
        return CommonHelper.getFormatedElementFromOptions(systemeCompta, OfficesConstants.systemeComptaOptions);
    }

    /**
     * Retour les labels formatés
     *
     * @param {string[]} labels
     * @returns {string}
     */
    static getFormatedLabels(labels: string[]): string {
        return CommonHelper.getFormatedElementsFromOptions(labels, OfficesConstants.labelsOptions);
    }



    /**
     * Retour les jours de fermetures
     *
     * @param {string[]} jours
     * @returns {string}
     */
    static getFormatedJourFermetures(jours: string[]): string {
        return CommonHelper.getFormatedElementsFromOptions(jours, OfficesConstants.joursFermetureOptions);
    }


    /**
     * Permet de récupérer les coordonnées de comptabilité de l'office
     *
     * @param {ICoordonnee[]} coordoonnees
     * @returns {ICoordonnee[]}
     */
    static getComptabiliteCoordonnees(coordoonnees: ICoordonnee[]): ICoordonnee[]{
        const filteredCoordonnees = coordoonnees.filter((c:ICoordonnee) => c.destination === "comptabilite");

        return filteredCoordonnees;
    }

}
