import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {INotaireGetApiResponse, INotaireIdentiteFormData} from "src/Services/Notaires.service";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useCreationSteps from "src/Hooks/useCreationSteps";
import OfficesHelper from "src/Helpers/Offices.helper";
import {IOfficesGetAllApiResponse, OfficesService} from "src/Services/Offices.service";
import {IOffice} from "src/Models/Office.model";


type NotaireCreateFormComponentProps = {
    isAncien?: boolean,
    submitMethod: (formData: INotaireIdentiteFormData) => Promise<INotaireGetApiResponse>,
    onSuccessSubmit: (response: INotaireGetApiResponse) => void,
    onSuccessSubmitNext: (response: INotaireGetApiResponse) => void,
    onCancel: () => void,
}

export default function NotaireCreateFormComponent(props: NotaireCreateFormComponentProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();
    const [offices, setOffices] = useState<IOffice[]>(null);
    const [officesLoaded, setOfficesLoaded] = useState<boolean>(false);


    useEffect(() => {
        const officeService: OfficesService = new OfficesService();
        officeService.getAllOfficesPrincipales().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });
    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                showFieldErrorDetail: true,
            },
            {
                type: 'text',
                fieldName: "CSN",
                label: "N° CSN",
                placeholder: "N° CSN",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                showFieldErrorDetail: true,
            },
            {
                type: 'select',
                fieldName: "type",

                label: "Type de notaire",
                placeholder: "Type de notaire",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                options: props.isAncien ? NotairesConstants.typesAnciensOptions :NotairesConstants.typesActifsOptions,
            },
            {
                type: 'date',
                fieldName: "dateArrivee",
                label: "Date d'arrivée",
                placeholder: "Date d'arrivée",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                showFieldErrorDetail: true,
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "prenom",

                label: "Prénom",
                placeholder: "Prénom",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                showFieldErrorDetail: true,
            },
            {
                type: 'date',
                fieldName: "dateSermentInitial",
                label: "Date serment initial",
                placeholder: "Date serment initial",
                required: true,
                modificators: "-on-white",
                oldValue: "",
                showFieldErrorDetail: true,
            },
            {
                type: 'select',
                fieldName: "officeId",

                label: "Office",
                options: offices && offices.length ? OfficesHelper.formatListForOptions(offices) : [],
                required: true,
                modificators: "-on-white",

                oldValue: "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(true, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    },[offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: INotaireGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le notaire a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.fieldsMessages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création des informations du notaire.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                officesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions="-outside-right"
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }
        </div>
    )
}
