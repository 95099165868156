import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {useLocation} from "react-router";
import {
    INotaireGetApiResponse,
    INotaireGetFormData,
    INotairesGetApiResponse,
    INotaireUrssafUploadFormData,
    NotairesService
} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import DateHelper from "src/Helpers/Date.helper";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import FileUploadField from "src/Components/Fields/FileUpload.field";
import {IDocument} from "src/Models/Document.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import NotairesUrssafAdvancedSearchComponent from "src/Components/Annuaire/Notaires/NotairesUrssafAdvancedSearch.component";
import ConfirmationComponent from "src/Components/Confirmation/Confirmation.component";
import {Link} from "react-router-dom";
import LoadingComponent from "../../../Components/Loading/Loading.component";


type IUrssafListScreenProps = {};

export default function UrssafListScreen(props: IUrssafListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);

    const [filters,initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathUrssaf);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const location = useLocation();
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [notaireSelected, setNotaireSelected] = useState<INotaire>(null);
    const [showConfirmationDelete, setShowConfirmationDelete] = useState<boolean>(false);



    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {link: AppConstants.pathMosaique + '#formations', text: "Formations"},
            { text: "Attestations Urssaf" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);


    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters,...formData},true);
        setShowAdvancedSearch(false);
    };



    /**
     * Récupération de la liste des notaires pouvant avoir des attestations urssaf
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const notairesServices: NotairesService = new NotairesService();
        notairesServices.getNotairesAttestationsUrssaf(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
            if (response && response.datas.pagination) {

                // Notaires
                const currentNotairesList: INotaire[] = response.datas.pagination.data;
                setNotaires(currentNotairesList);

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }


    /**
     * Upload d'un fichier urssaf pour un notaire
     *
     * @param {INotaire} notaire
     * @param {IDocument} attestation
     * @param {File} fichier
     */
    const uploadFile = (notaire: INotaire, attestation: IDocument, fichier:File): void => {
        const notairesService: NotairesService = new NotairesService();
        const formData: INotaireUrssafUploadFormData = {
            fichier: fichier,
            CRPCEN: notaire.office ? notaire.office.CRPCEN : null,
        }

        notairesService.uploadUrssafAttestation(notaire.id, attestation.id, formData).then((response: INotaireGetApiResponse) => {
            launchFilteredSearch();
        }, (error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors l'ajout de l'attestation Ursaff du notaire.",
                    type: "danger"
                });
            }
        });
    }

    const onCancelDelete = () => {
        setNotaireSelected(null);
        setShowConfirmationDelete(false);
    };

    const deleteDocument = () => {
        const notaireServices: NotairesService = new NotairesService();
        notaireServices.deleteUrssafAttestation(notaireSelected.id, notaireSelected.urssaf.id).then((result) => {
            setNotaireSelected(null);
            setShowConfirmationDelete(false);
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'attestation Ursaff a bien été supprimée",
                type: "success"
            });

            launchFilteredSearch();

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression.",
                type: "danger"
            });
        });
    };

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Attestations Urssaf"
                    icon="icon-urssaf"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                    onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">

                                    <NotairesUrssafAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={ onAdvancedSearch}
                                    />


                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "crpcen", label: "CRPCEN"},
                                                    {slug: "nom", label: "Nom"},
                                                    {slug: "attestation", label: `Fichier ${DateHelper.getLastYear()}`}
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                notaires && notaires.map((notaire: INotaire, index: number) =>
                                                    <tr key={index} className="tr -no-hover">
                                                        <td className="td -bold">
                                                            {notaire.office && <>{notaire.office.CRPCEN}</>}
                                                        </td>
                                                        <td className="td">
                                                            <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathNotaires}/${notaire.id}`} onClick={(e) => e.stopPropagation()}>{notaire.nom} {notaire.prenom}</Link>
                                                        </td>
                                                        <td className="td">
                                                            {
                                                                notaire.urssaf && notaire.urssaf.fichier &&
                                                                    <div className="flex-wrapper">
                                                                        <span className={`state-bullet -before-element icon-bullet -actif`} />
                                                                        <DocumentLink modificators={"-table"} link={FileHelper.getFileUrl(notaire.urssaf.fichier)} />
                                                                          <button className="g-button -delete-button icon-remove" onClick={() => {
                                                                              setNotaireSelected(notaire);
                                                                              setShowConfirmationDelete(true)
                                                                          }}/>
                                                                    </div>
                                                            }
                                                            {
                                                                notaire.urssaf && !notaire.urssaf.fichier &&
                                                                    <div className="flex-wrapper">
                                                                        <span className={`state-bullet  -before-element icon-bullet -annule`} />
                                                                        <FileUploadField
                                                                            fieldName="attestation"
                                                                            oldValue=""
                                                                            onChange={(f: File) => uploadFile(notaire, notaire.urssaf, f)}
                                                                            background=""
                                                                            modificators='-inline'
                                                                            hideUploadLabelIfFile={true}
                                                                            uploadLabel="Uploader"
                                                                            extensions={['.pdf']}
                                                                        />
                                                                    </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
            {showConfirmationDelete && <ConfirmationComponent onConfirm={()=> deleteDocument()} onCancel={onCancelDelete} text="Souhaitez-vous vraiment supprimer cette attestation ?"/>}
        </LayoutComponent>
    )
}
