import React, {useEffect, useState} from "react";
import {AutresContactsService, IOrganismeGetApiResponse, IOrganismeInformationsFormData} from "src/Services/AutresContacts.service";
import useOrganismes from "src/Hooks/UseOrganismes";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IOrganisme} from "src/Models/Organisme.model";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import OrganismeInformationsFormComponent from "src/Components/Forms/Annuaire/Autres-contacts/Organismes/OrganismeInformationsForm.component";

type IOrganismeCreationStepsScreenProps = {
    oid?: string,
    type: string;
}
export default function OrganismeCreationStepsScreen(props: IOrganismeCreationStepsScreenProps){
    const autresContactsService: AutresContactsService = new AutresContactsService();
    const [getOrganismeById] = useOrganismes();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [organisme, setOrganisme] = useState<IOrganisme>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.oid){
            getOrganismeById(props.oid, (organisme: IOrganisme) => {
                setOrganisme(organisme);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [])


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IOrganismeGetApiResponse): void => {
        if(response.datas && response.datas.organisme){
            const organisme: IOrganisme = response.datas.organisme;
            history.push(AppConstants.pathOrganismes + '/' + organisme.id);
        }
        else{
            history.push(AppConstants.pathOrganismes);
        }
    };

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitNext = (response: IOrganismeGetApiResponse): void => {
        if(response.datas && response.datas.organisme){
            const organisme: IOrganisme = response.datas.organisme;
            redirectToNextCreationStep(AppConstants.pathOrganismes, organisme.id, organisme.creationSteps);
        }
        else{
            history.push(AppConstants.pathOrganismes);
        }
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathOrganismes);
    };

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un organisme" icon="icon-organismes" modificators={`-in-fullscreen -border-full-screen`}/>
            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />
                            <OrganismeInformationsFormComponent isOnCreationStep={true} submitMethod={(formData: IOrganismeInformationsFormData)=>autresContactsService.createOrganisme(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}
