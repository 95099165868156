import {IOption} from "src/Components/Fields/Select.field.component";

export type IRecusLivraisonConstants = {
    typesOptions: IOption[],
}
export const RecusLivraisonConstants: IRecusLivraisonConstants = {
    typesOptions: [
        {
            value: "",
            label: "Aucun"
        },
        {
            value: "carnet",
            label: "Carnet"
        },
        {
            value: "liasse",
            label: "Liasse"
        },
    ],
};
