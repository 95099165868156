import React, {useEffect, useState} from "react";
import {IInspecteur} from "src/Models/Inspecteur.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {INotaire} from "src/Models/Notaire.model";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";

type InspectionListInspecteurItemComponentProps = {
    inspecteur: IInspecteur,
    isHistorique?: boolean,
    modificators?: string,
}
export default function InspectionListInspecteurItemComponent(props: InspectionListInspecteurItemComponentProps) {
    const [notaire, setNotaire] = useState<INotaire>(null);
    const [personneExterieure, setPersonneExterieure] = useState<IPersonneExterieure>(null);

    useEffect( ()=>{
        if(props.inspecteur.notaire){
            setNotaire(props.inspecteur.notaire);
        }
        else if(props.inspecteur.personneExterieure){
            setPersonneExterieure(props.inspecteur.personneExterieure);
        }

    }, [props.inspecteur]);

    return (
        <p className={`line ${props.modificators || ''} ${props.isHistorique ? '-historique -historique-flex' : '' }`}>
            {
                notaire &&
                <>
                    <span className={`text ${props.inspecteur.isObservateur ? '-with-picto' : ''}`}>
                        {
                            props.inspecteur.isObservateur &&
                                <i className="picto icon-voir" />
                        }
                        {`${notaire.nom} ${notaire.prenom}`}
                    </span>

                    {props.isHistorique ? " - " : <br/>}

                    <span className={"text -secondary"}>{notaire.type ? NotairesHelper.getFormattedType(notaire.type) : ''}</span>

                    {
                        props.isHistorique &&
                            <span className={"text"}>{notaire.adressePrincipale ? ` - ${notaire.adressePrincipale.ville}` : ''}</span>
                    }
                    {
                        !props.isHistorique &&
                            <span className={"text"}>{notaire.office ? ` - ${notaire.office.CRPCEN}` : ''}</span>
                    }

                </>
            }
            {
                !notaire && personneExterieure &&
                <>
                            <span className={"text -with-picto"}>
                                <i className="picto icon-star" />
                                {`${personneExterieure.nom} ${personneExterieure.prenom}`}
                            </span>
                </>
            }
        </p>
    )
}
