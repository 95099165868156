import React, {useEffect, useState} from "react";
import PageHeaderComponent from "../../../Components/Page/PageHeader.component";
import LayoutComponent from "../../../Components/Layout/Layout.component";
import {IBreadcrumbLink} from "../../../Components/Breadcrumbs/Breadcrumbs.component";
import {AppConstants} from "../../../Constants/AppConstants";
import useOffice from "../../../Hooks/UseOffice";
import {IOffice} from "../../../Models/Office.model";
import {useHistory} from "react-router";
import {IStatistiquesFormData, StatistiquesService} from "../../../Services/StatistiquesService.service";
import {IStatistiques} from "../../../Models/Statistiques.model";
import OfficesHelper from "../../../Helpers/Offices.helper";
import TabsContainerComponent, {TabPanelComponent} from "../../../Components/Tabs/TabsContainer.component";
import StatistiquesTable from "../../../Components/Inspections/Statistiques/StatistiquesTable.component";
import SeeMoreLinkComponent from "../../../Components/Links/SeeMoreLink.component";
import StatistiquesGraphiques from "../../../Components/Inspections/Statistiques/StatistiquesGraphiques.component";
import {IAdresse} from "../../../Models/Adresse.model";
import AsideStatistiquesComponent from "../../../Components/Aside/AsideStatistiques.component";

type StatistiquesFicheScreenProps = {
    oid: string
};

const StatistiquesFicheScreen = (props: StatistiquesFicheScreenProps) => {
    const statsService: StatistiquesService = new StatistiquesService();
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [getOfficeById] = useOffice();
    const [office, setOffice] = useState<IOffice>(null);
    const [stats, setStats] = useState<IStatistiques[]>(null);
    const [firstYear,setFirstYear] = useState(0);
    const [lastYearStats, setLastYearStats] = useState<IStatistiques>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const [editMode,setEditMode] = useState<boolean>(false);

    const [formData,setFormData] = useState<IStatistiquesFormData>({});



    useEffect(() => {
        if (!office) return;

        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            {text: "Statistiques", link: AppConstants.pathStatistiques},
            {text: office.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

    }, [office, loaded]);

    useEffect(() => {
        if (!props.oid) {
            // On redirige vers la home
            history.push(AppConstants.pathStatistiques);
        }
        getOffice();
        getStats();

    }, [props.oid]);


    /**
     * Récupération de l'office selon l'oid
     */
    const getOffice = (): void => {
        getOfficeById(props.oid, (office: IOffice) => {
            setOffice(office);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Récupération des stats de l'office
     */
    const getStats = (): void => {
        statsService.getStatsForOffice(props.oid).then((result) => {
            if (result.datas.stats) {
                const officeStats = result.datas.stats;
                setStats(officeStats);
                const lastYear = officeStats[officeStats.length - 1];
                setLastYearStats(lastYear);
                setFirstYear(lastYear.dapYear as number-5);
                setEditMode(false);
            }
        });
    };


    /**
     *
     * @param {string} sid
     * @param {string} path
     * @param {string} value
     */
    const onValueChange = (sid:string,path: string, value:string) => {
        const newFormData = {...formData};

        newFormData[`${sid}-${path.replaceAll(".","-")}`] = value;

        setFormData(newFormData);
    };


    /**
     *
     */
    const onSubmit = () => {
        statsService.update(formData).then((result) => {
            getStats();
        });
    };


    /**
     *
     * @param {IAdresse} adresse
     * @returns {string}
     */
    const getAdress = (adresse: IAdresse): string => {
        if(!adresse) return "";
        return `${adresse.ligne1 || ""} ${adresse.ligne2 || ''} - ${adresse.codePostal} ${adresse.ville}`;
    }

    return <>
        {loaded && <LayoutComponent showMenu={true}>
			<PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={office.nom} subText={getAdress(office.adressePrincipale)} icon="icon-office" onSearch={null} modificators="-subtext-under"/>

            {stats && lastYearStats && <>
	            <TabsContainerComponent>
		            <TabPanelComponent label="Statistiques" icon="icon-statistiques">

                        <div className="list-content -right-gutter border-footer">
                            <div className="statistiques-office">
                                <div className="item">
                                    <span className="title">Nombres de notaires : </span>
                                    {!editMode && <span className="value">{lastYearStats.nombreNotaires}</span>}
                                    {editMode && <input defaultValue={lastYearStats.nombreNotaires ? parseFloat(lastYearStats.nombreNotaires.toString()).toFixed(0) : ""}  onChange={(e) => onValueChange(lastYearStats.id as string,"nombreNotaires",e.currentTarget.value)}/>}
                                </div>
                                <div className="item">
                                    <span className="title">Nombres de salariés : </span>
                                    {!editMode && <span className="value">{lastYearStats.nombreSalaries}</span>}
                                    {editMode && <input defaultValue={lastYearStats.nombreSalaries ? parseFloat(lastYearStats.nombreSalaries.toString()).toFixed(0) : ""}  onChange={(e) => onValueChange(lastYearStats.id as string,"nombreSalaries",e.currentTarget.value)}/>}
                                </div>
                                <div className="item">
                                    <span className="title">Type d’office : </span>
                                    <span className="value">{OfficesHelper.getFormatedType(office.type)}</span>
                                </div>
                                <div className="item">
                                    <span className="title">Equiv. Temps pleins : </span>
                                    {!editMode && <span className="value">{lastYearStats?.data?.dap.equivalent_temps_plein_en_fin_dexercice || 0}</span>}
                                    {editMode && <input defaultValue={lastYearStats?.data?.dap.equivalent_temps_plein_en_fin_dexercice ? parseFloat(lastYearStats?.data?.dap.equivalent_temps_plein_en_fin_dexercice.toString()).toFixed(0) : ""}  onChange={(e) => onValueChange(lastYearStats.id as string,"data.dap.equivalent_temps_plein_en_fin_dexercice",e.currentTarget.value)}/>}
                                </div>
                                {editMode && <div className={`form-actions -stats-table`}>
                                    <button className={`g-button -primary`} onClick={(e) => onSubmit()} >
                                        Enregistrer
                                    </button>
                                    <button className={`g-button -is-link`} onClick={(e) => setEditMode(false)} >
                                        Annuler
                                    </button>
                                </div>}
                            </div>

                            <div className="statistiques-actions">
                                <SeeMoreLinkComponent text={editMode ? "Arrêter de modifier" : "Modifier"} icon="icon-cog" modificators="-small" callback={()=>setEditMode(!editMode)} />
                            </div>

                            <StatistiquesTable firstYear={firstYear} onSubmit={onSubmit} onEditModeChange={(isActive)=>setEditMode(isActive)} stats={stats} office={office} editMode={editMode} onValueChange={onValueChange} />

                            <AsideStatistiquesComponent type="fiche" officeId={props.oid || null} />
                        </div>

		            </TabPanelComponent>



		            <TabPanelComponent label="Graphiques" icon="icon-camembert">
                        <div className="list-content -right-gutter">
                            <StatistiquesGraphiques stats={stats} />
                            <AsideStatistiquesComponent type="screen"/>
                        </div>
		            </TabPanelComponent>
	            </TabsContainerComponent>
			</>}

		</LayoutComponent>}
    </>;
};

export default StatistiquesFicheScreen;
