import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type SuccessionsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,

}
export default function SuccessionsExportComponent(props: SuccessionsExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Successions"}
                         icon="icon-successions"
             subTitle={"Export des successions"}
             onClose={() => props.setShowExport()}
             modificators={`${!props.showExport ? '-hide' : ''}`}
             exportUrl= {`api${AppConstants.pathSuccessions}/export`}
             filters={props.filters || null}

             columns={[
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-informations',
                             fieldName: "informations",
                             label: "Informations",
                             options: [
                                 {value: 'etat', label: 'Etat'},
                                 {value: 'date', label: 'Date'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-notaires',
                             fieldName: "identite",
                             label: "Identité",
                             options: [
                                 {value: 'identiteNom', label: 'Nom'},
                                 {value: 'identitePrenom', label: 'Prénom'},
                                 {value: 'identiteNaissanceDate', label: 'Date de naissance'},
                                 {value: 'identiteNaissanceDepartement', label: 'Département de naissance'},
                                 {value: 'identiteNaissanceLieu', label: 'Lieu de naissance'},
                             ]
                         },
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-coordonnees',
                             fieldName: "adresse",
                             label: "Adresse",
                             options: [
                                 {value: 'identiteAdresse', label: 'Adresse'},
                                 {value: 'identiteAdresseCodePostal', label: 'Code Postal'},
                                 {value: 'identiteAdresseVille', label: 'Ville'},
                             ]
                         },
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-deces',
                             fieldName: "deces",
                             label: "deces",
                             options: [
                                 {value: 'identiteDecesDate', label: 'Date de décès'},
                                 {value: 'identiteDecesDepartement', label: 'Département de décès'},
                                 {value: 'identiteDecesLieu', label: 'Lieu de décès'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-notaires',
                             fieldName: "demandeur",
                             label: "Demandeur",
                             options: [
                                 {value: 'demandeurRaisonSociale', label: 'Raison Sociale'},
                                 {value: 'demandeurNom', label: 'Nom'},
                                 {value: 'demandeurPrenom', label: 'Prénom'},
                                 {value: 'demandeurAdresse', label: 'Adresse'},
                                 {value: 'demandeurCodePostal', label: 'Code Postale'},
                                 {value: 'demandeurVille', label: 'Ville'},
                                 {value: 'demandeurTelephone', label: 'Téléphone'},
                                 {value: 'demandeurEmail', label: 'Email'},
                                 {value: 'demandeurMotif', label: 'Motif'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-commentaires',
                             fieldName: "reponse",
                             label: "Réponse",
                             options: [
                                 {value: 'office', label: 'Office'},
                                 {value: 'notaire', label: 'Notaire'},
                                 {value: 'commentaires', label: 'Commentaires'},
                             ]
                         },
                     ]
                 },

             ]}
        />
    )
}
