import DateHelper from "src/Helpers/Date.helper";
import {IAvisMotive} from "src/Models/AvisMotive.model";
import ReactHtmlParser from "react-html-parser";

type IAvisMotiveInfosComponentProps = {
    avis: IAvisMotive,
}

export default function AvisMotiveInfosComponent(props: IAvisMotiveInfosComponentProps) {
    const avisMotive: IAvisMotive = props.avis;

    return (
        <div className="informations-wrapper">
            <>
                <div className="columns">
                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Date de saisie</p>
                            <p className="value">{avisMotive.dateSaisie ? DateHelper.getFormatedDate(avisMotive.dateSaisie): "-"}</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Notaire</p>
                            <p className="value">{avisMotive.notaire ? `${avisMotive.notaire.nom} ${avisMotive.notaire.prenom}`: "-"}</p>
                        </div>
                    </div>
                </div>
                <div className="columns" >
                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Texte</p>
                            {
                                avisMotive.text &&
                                <div className="informations-text">
                                    { ReactHtmlParser(avisMotive.text) }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}
