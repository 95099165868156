import React, {useEffect, useState} from "react";
import {
    IAnnexeCreateFormData,
    IOfficeGetApiResponse,
    OfficesService
} from "src/Services/Offices.service";
import {CommonsService} from "src/Services/Commons.service";
import useOffice from "src/Hooks/UseOffice";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IOffice} from "src/Models/Office.model";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import AnnexeCreationFormComponent from "src/Components/Forms/Annuaire/Offices/AnnexeCreationForm.component";


type IAnnexeCreationStepsScreenProps = {
    oid?: string,
    type: string;
}
export default function AnnexeCreationStepsScreen(props: IAnnexeCreationStepsScreenProps){
    const officesService: OfficesService = new OfficesService();
    const commonsService: CommonsService = new CommonsService();
    const [getOfficeById] = useOffice();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [office, setOffice] = useState<IOffice>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.oid){
            getOfficeById(props.oid, (office: IOffice) => {
                setOffice(office);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [])


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IOfficeGetApiResponse): void => {
        if(response.datas && response.datas.office){
            const office: IOffice = response.datas.office;
            history.push(AppConstants.pathAnnexes + '/' + office.id);
        }
        else{
            history.push(AppConstants.pathAnnexes);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitNext = (response: IOfficeGetApiResponse): void => {
        if(response.datas && response.datas.office){
            const office: IOffice = response.datas.office;
            redirectToNextCreationStep(AppConstants.pathAnnexes, office.id, office.creationSteps);
        }
        else{
            history.push(AppConstants.pathAnnexes);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        if(office){
            history.push(AppConstants.pathAnnexes + '/' + office.id);
            return;
        }

        history.push(AppConstants.pathAnnexes);
    };

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une annexe" icon="icon-annexe-add" modificators={`-in-fullscreen -border-full-screen`}/>
            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-building"} title={"Identité"} modificators="-margin-bottom" />
                            <AnnexeCreationFormComponent
                                isOnCreationStep={true}
                                submitMethod={(formData: IAnnexeCreateFormData)=>officesService.createAnnexe(formData)}
                                onSuccessSubmit={onSuccessSubmitForm}
                                onSuccessSubmitNext={onSuccessSubmitNext}
                                onCancel={onCancelForm}
                            />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}
