import React from "react";
import {IApiPaginationLink} from "src/Services/Api.service";


type PaginationComponentProps = {
    links: IApiPaginationLink[],
    onPageClick: (page:string)=>void
}

export default function PaginationComponent(props: PaginationComponentProps){
    if( !props.links || props.links.length <= 1) return null;


    return (
        <div className="pagination-wrapper">
            {
                props.links.map((page: IApiPaginationLink, index: number) =>
                    <button className={`link ${page.active ? '-active' : '' }`} key={index} onClick={()=>props.onPageClick(page.label)}>{page.label}</button>
                )
            }
        </div>
    )
}
