import React, {useEffect, useRef, useState} from "react";

import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import UseSignatures from "src/Hooks/UseSignatures";
import {ISignature} from "src/Models/Signature.model";
import SignaturesForm from "../../../Components/Forms/Correspondances/Signatures/SignaturesForm.component";
import {ISignatureInformationsFormData, SignaturesService} from "../../../Services/Signatures.service";
import AsideSignaturesComponent from "../../../Components/Aside/AsideSignatures.component";

export type ISignatureFicheScreenProps = {
    sid: number,
}

export default function SignatureFicheScreen(props: ISignatureFicheScreenProps){
    const [loaded, setLoaded] = useState<boolean>(false);
    const [getSignatureByid] = UseSignatures();
    const [signature, setSignature] = useState<ISignature>(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const signaturesService: SignaturesService = new SignaturesService();

    const informationsAccordionRef = useRef(null);

    useEffect(() => {
        if( !props.sid ){
            history.push(AppConstants.pathSignatures);
        }
        getSignature();

    }, [props.sid])


    useEffect(() => {
        if(!signature) return;

        const breadcrumbLinks = [
            { text: "Correspondances", link: AppConstants.pathMosaique + '#correspondances'},
            { text: "Signatures", link: AppConstants.pathSignatures },
            {text: signature.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [signature, loaded]);


    /**
     * Récupération de la signature selon l'id
     */
    const getSignature = (): void => {
        getSignatureByid(props.sid.toString(), (s: ISignature) => {
            setSignature(s);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationsAccordionRef && informationsAccordionRef.current) ){
            informationsAccordionRef.current.hideForm(isCancel);
        }
    };

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getSignature();

        closeForms();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={signature.nom} icon="icon-signature" modificators="" onSearch={null}/>

                    <TabsContainerComponent>
                        <TabPanelComponent label="Fiche" icon="icon-lettre-type">
                              <div className="g-content border-footer">
                                  <SignaturesForm signature={signature} submitMethod={(formData: ISignatureInformationsFormData)=>signaturesService.update(props.sid.toString(), formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />

                                  <AsideSignaturesComponent type='fiche' signature={signature} />
                              </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>

                </LayoutComponent>
            }
        </>
    )
}

