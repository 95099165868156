import React, {useContext, useEffect, useState} from 'react';
import SearchBlockComponent, {IFormSearchData} from "src/Components/Search/SearchBlock.component";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import AccountBlockComponent from "../Components/Account/AccountBlock.component";
import {Link} from "react-router-dom";
import LayoutComponent from "../Components/Layout/Layout.component";
import ErrorsConstants from "../Constants/Errors";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import AccueilStatsComponent from "../Components/Accueil/AccueilStats.component";
import AccueilCompositionsComponent from "../Components/Accueil/AccueilCompositions.component";
import AccueilNotesComponent from "../Components/Accueil/AccueilNotes.component";
import {IApiCustomResponse} from "../Services/Api.service";
import {AccueilService, IAccueilGetApiResponse} from "../Services/Accueil.service";
import LoadingComponent from "../Components/Loading/Loading.component";
import {IAccueilNote} from "../Models/AccueiNote.model";




const HomeScreen = () => {
    const history = useHistory();
    const {isAuthenticated} = useContext(UserContext) as IUserContext;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [stats, setStats] = useState<any>(null);
    const [note, setNote] = useState<IAccueilNote>(null);
    const [compositions, setCompositions] = useState<any>(null);


    useEffect(() => {
        const accueilService: AccueilService = new AccueilService();
        accueilService.getAccueilInfos().then((response: IAccueilGetApiResponse) => {
            if( response && response.datas){
                setStats(response.datas.stats)
                setCompositions(response.datas.compositions)
                setNote(response.datas.note)

                if (!loaded) {
                    setLoaded(true);
                }
            }
        },(error: IApiCustomResponse) => {
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération des infos de la page d'accueil",type: "danger"});
        });
    }, []);



    useEffect(()=>{
        if(!isAuthenticated){
            // On redirige vers la home
            history.push(AppConstants.pathLogin);
        }
    },[isAuthenticated,history]);



    /**
     * Gère le retour du form de recherche
     *
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData): void => {
        if(!formDatas || !formDatas.keyword){
            notificationSystem.addNotification({...defaultNotificationConfig, message: ErrorsConstants.messages.emptySearchField, type: "danger"});
            return;
        }

        history.push(`/recherche?keyword=${formDatas.keyword}`);
    };



    return (
	    <LayoutComponent wrapperModificators="-menu-alternative" contentModificators="" showMenu={true}>
            <div className="home-wrapper ">
                <header className="home-header">
                    <div className="search-wrapper">
                        <SearchBlockComponent onSubmit={onSearch} modificators="-big -on-primary"/>
                    </div>

                    <div className="toolbar-wrapper">
                        <AccountBlockComponent />
                        <span className="separator" />

                        <a className="g-picto-link -secondary icon-paper-plane" href="https://app.ar24.fr/fr/user/login/required" target="_blank"></a>
                    </div>
                </header>

                {
                    loaded &&
                    <div className="home-content">
                        <div className="home-details-wrapper  border-footer">
                            <div className="left">
                                <AccueilStatsComponent stats={stats} />
                            </div>
                            <div className="right">
                                <AccueilNotesComponent note={note} />
                                <AccueilCompositionsComponent compositions={compositions} />
                            </div>
                        </div>
                    </div>
                }
                {
                    !loaded &&
                    <LoadingComponent />
                }

            </div>
	    </LayoutComponent>
    )
};

export default HomeScreen;
