import {IOption, IOptionsGroup} from "../Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type IFormationConstants = {
    states: IFormationStates,
    statePassee: string,
    stateActif: string,
    participantStates: IParticipantStates,
    statesOptions: IOptionsGroup[],
    participantStatesOptions: IOption[],
    documentsTypesOptions: IOption[],
    documentsOrderOptions: IOption[],
    categoriesOptions: IOptionsGroup[],
    creationSteps: ICreationSteps[],
    categories: IFormationCategories,
}

export type IFormationStates = {
    [key: string]: string
}

export type IParticipantStates = {
    [key: string]: string
}

export type IFormationCategories = {
    [key: string]: string
}

export const FormationConstants: IFormationConstants = {
    creationSteps: [
        {
            url: "/formation",
            title: "Formation",
            icon: "icon-formations",
        },
        {
            url: "/documents",
            title: "Documents",
            icon: "icon-documents",
        },
        {
            url: "/precisions",
            title: "Précisions",
            icon: "icon-precisions",
        },
        {
            url: "/complements",
            title: "Compléments",
            icon: "icon-complements",
        },
    ],
    states: {
        "actif": "Active",
        "report": "A reporter",
        "annule": "Annulée",
        "passee": "Passée"
    },
    statePassee: "passee",
    stateActif: "actif",
    participantStates: {
        "inscrit" : "inscrit",
        "enAttente" : "enAttente",
        "annulation" : "annulation",
    },
    participantStatesOptions : [
        {value: "inscrit", label: "Inscrit"},
        {value: "enAttente", label: "En Attente"},
        {value: "annulation", label: "Annulation"},
    ],
    statesOptions: [
        {
            label: "",
            options: [
                {value: "actif", label: "Active"},
                {value: "report", label: "A reporter"},
                {value: "annule", label: "Annulée"},
                //{value: "passee", label: "Passée"},//Masquer des options le choix "Passée" qui est automatique, tâche CRON
            ]
        },
    ],
    categoriesOptions: [
        {
            label: "",
            options: [
                {value: "droit_de_la_famille", label: "Droit de la famille"},
                {value: "droits_immobilier_et_rural", label: "Droits immobilier et rural"},
                {value: "management", label: "Management"},
                {value: "pratique_notarial", label: "Pratique notariale"},
                {value: "entreprise", label: "Entreprise"},
                {value: "fiscalite_et_actualite_juridique", label: "Fiscalité et actualité juridique"},
            ]
        },
    ],
    categories:{
        "droit_de_la_famille": "Droit de la famille",
        "droits_immobilier_et_rural": "Droits immobilier et rural",
        "management": "Management",
        "pratique_notarial": "Pratique notariale",
        "entreprise": "Entreprise",
        "fiscalite_et_actualite_juridique": "Fiscalité et actualité juridique",
    },

    documentsTypesOptions: [
        {value: "devis", label: "Devis"},
        {value: "programme", label: "Programme"},
        {value: "autre", label: "Autre"},
    ],

    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "type",
            label: "Type"
        },
    ],
};
