import React, {useEffect, useState} from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {SinistresConstants} from "src/Constants/SinistresConstants";
import {NotairesService} from "../../Services/Notaires.service";
import {INotaire} from "../../Models/Notaire.model";
import NotairesHelper from "../../Helpers/Notaires.helper";
import {ICtrc} from "../../Models/Ctrc.model";
import {CtrcsService, ICtrcGetAllApiResponse} from "../../Services/Ctrcs.service";
import CtrcsHelper from "../../Helpers/Ctrcs.helper";

type SinistresAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,
}
export default function SinistresAdvancedSearchComponent(props: SinistresAdvancedSearchComponentProps){
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notairesCTRCLoaded, setNotairesCTRCLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [notairesCTRC, setNotairesCTRC] = useState<INotaire[]>([]);
    const [ctrcs, setCtrcs] = useState<ICtrc[]>([]);
    const [ctrcsLoaded, setCTRCsLoaded] = useState<boolean>(false);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAll().then((response) => {
            if( response && response.datas && response.datas.notaires){
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

        notairesService.getCTRC().then((response) => {
            if( response && response.datas && response.datas.notaires){
                setNotairesCTRC(response.datas.notaires);
                setNotairesCTRCLoaded(true);
            }
        });

        const ctrcsService: CtrcsService = new CtrcsService();
        ctrcsService.getAll().then((response: ICtrcGetAllApiResponse) => {
            if (response && response.datas && response.datas.ctrcs) {
                setCtrcs(response.datas.ctrcs);
                setCTRCsLoaded(true);
            }
        });
    }, []);




    return (
        <>
            {
                notairesLoaded && notairesCTRCLoaded &&
                <AdvancedSearchComponent
                    title="Dossiers/Sinistres"
                    showSummary={props.showSummary}
                    modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
                    showAdvancedSearch={props.showAdvancedSearch}
                    filters={props.filters}
                    columns={[
                        {
                            elements: [
                                {
                                    type: 'hidden',
                                    fieldName: "advanced",
                                    oldValue: "1",
                                    modificators: "-hidden"
                                },
                                {
                                    type: "text",
                                    fieldName: "numeroDossier",
                                    label: "Numéro de dossier",
                                    modificators:"-on-white",
                                    oldValue: props.filters.numeroDossier as string || ""
                                },
                                /*
                                {
                                    type: "selectMultiple",
                                    fieldName: "notairesDemandeurs",
                                    label: "Notaire(s) demandeur(s)",
                                    modificators:"-on-white",
                                    oldValues: props.filters.notairesDemandeurs as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                                 */
                                {
                                    type: "select",
                                    fieldName: "source",
                                    label: "Source",
                                    modificators:"-on-white",
                                    hideSearchOnMultiple: true,
                                    oldValue: props.filters.source as string || "",
                                    options: SinistresConstants.sourceOptions,
                                },
                                {
                                    type: 'date',
                                    label: "Année (min)",
                                    fieldName: "dateOuvertureMin",
                                    oldValue: props.filters.dateOuvertureMin as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Année (max)",
                                    fieldName: "dateOuvertureMax",
                                    oldValue: props.filters.dateOuvertureMax as string || "",
                                    modificators: "-on-white",
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "text",
                                    fieldName: "libelle",
                                    label: "Libellé",
                                    modificators:"-on-white",
                                    oldValue: props.filters.libelle as string || ""
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "sin_notaireId_assignes",
                                    label: "Notaire(s) mis en cause",
                                    modificators:"-on-white",
                                    oldValues: props.filters.sin_notaireId_assignes as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },

                                {
                                    type: "select",
                                    fieldName: "sin_notaireId_rapporteur",
                                    label: "Rapporteur",
                                    modificators:"-on-white",
                                    oldValues: props.filters.sin_notaireId_rapporteur as string[] || [],
                                    options: notairesCTRC ? NotairesHelper.formatListForOptions(notairesCTRC) : [],
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "selectMultiple",
                                    fieldName: "sin_ctrcsIds",
                                    label: "CTRC",
                                    modificators:"-on-white",
                                    oldValues: props.filters.id_ctrc as string[] || [],
                                    options: ctrcs ? CtrcsHelper.getFormatedListForOptions(ctrcs) : [],
                                },
                                /*
                                {
                                    type: 'date',
                                    label: "Date CTRC (min)",
                                    fieldName: "dateCTRCMin",
                                    oldValue: props.filters.dateCTRCMin as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date CTRC (max)",
                                    fieldName: "dateCTRCMax",
                                    oldValue: props.filters.dateCTRCMax as string || "",
                                    modificators: "-on-white",
                                },
                                 */
                            ]
                        },
                    ]}
                    onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
                    onClose={() => props.setShowAdvancedSearch()}
                    onSearch={props.onSearch}
                />
            }

        </>
    )

}
