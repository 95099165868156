import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {ISuccession} from "src/Models/Succession.model";
import AsideSuccessionComponent from "src/Components/Aside/AsideSuccessions.component";
import UseSuccession from "src/Hooks/UseSuccession";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import SuccessionEtatComponent from "src/Components/Successions/SuccessionEtat.component";
import SuccessionIdentiteComponent from "src/Components/Successions/SuccessionIdentite.component";
import SuccessionDemandeurComponent from "src/Components/Successions/SuccessionDemandeur.component";
import {ISuccessionDefuntFormData, ISuccessionDemandeurFormData, ISuccessionInformationsFormData, ISuccessionReponseExternalFormData, SuccessionsService} from "src/Services/Successions.service";
import SuccessionDefuntFormComponent from "src/Components/Forms/Successions/SuccessionDefuntForm.component";
import SuccessionDemandeurFormComponent from "src/Components/Forms/Successions/SuccessionDemandeurForm.component";
import SuccessionReponseComponent from "src/Components/Successions/SuccessionReponse.component";
import SuccessionReponseFormComponent from "src/Components/Forms/Successions/SuccessionReponseForm.component";
import SuccessionInformationsFormComponent from "src/Components/Forms/Successions/SuccessionInformationsForm.component";


type ISuccessionFicheScreenProps = {
    sid: string
}
export default function SuccessionFicheScreen(props: ISuccessionFicheScreenProps){
    const informationAccordionRef = useRef(null);
    const defuntAccordionRef = useRef(null);
    const demandeurAccordionRef = useRef(null);
    const reponseAccordionRef = useRef(null);

    const {getSuccessionById} = UseSuccession();
    const [succession, setSuccession] = useState<ISuccession>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const successionsService: SuccessionsService = new SuccessionsService();
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!succession) return;

        const breadcrumbLinks = [
            {text: "Successions", link: AppConstants.pathSuccessions},
            {text: succession.identiteNom + " " + succession.identitePrenom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [succession, loaded])



    useEffect(() => {
        if( !props.sid ){
            history.push(AppConstants.pathSuccessions);
        }

        getSuccession();

    }, [props.sid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getSuccession = (): void => {
        getSuccessionById(props.sid, (succession: ISuccession) => {
            setSuccession(succession);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getSuccession();

        closeForms();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationAccordionRef && informationAccordionRef.current) ){
            informationAccordionRef.current.hideForm(isCancel);
        }
        if((defuntAccordionRef && defuntAccordionRef.current) ){
            defuntAccordionRef.current.hideForm(isCancel);
        }
        if((demandeurAccordionRef && demandeurAccordionRef.current) ){
            demandeurAccordionRef.current.hideForm(isCancel);
        }
        if((reponseAccordionRef && reponseAccordionRef.current) ){
            reponseAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={`${succession.identiteNom} ${succession.identitePrenom}`} icon="icon-successions" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={informationAccordionRef}
                                    title="Etat"
                                    icon="icon-informations"
                                    contentShowedOnInit={true}
                                    contentType="succession"
                                    contentId={succession.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SuccessionInformationsFormComponent succession={succession} submitMethod={(formData: ISuccessionInformationsFormData)=>successionsService.updateInformation(props.sid,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<SuccessionEtatComponent succession={succession} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={defuntAccordionRef}
                                    title="Identité du défunt"
                                    icon="icon-notaires"
                                    contentShowedOnInit={true}
                                    contentType="succession"
                                    contentId={succession.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SuccessionDefuntFormComponent succession={succession} submitMethod={(formData: ISuccessionDefuntFormData)=>successionsService.updateDefunt(props.sid,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<SuccessionIdentiteComponent succession={succession} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={demandeurAccordionRef}
                                    title="Demandeur"
                                    icon="icon-demandeur"
                                    contentShowedOnInit={true}
                                    contentType="succession"
                                    contentId={succession.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SuccessionDemandeurFormComponent succession={succession} submitMethod={(formData: ISuccessionDemandeurFormData)=>successionsService.updateDemandeur(props.sid,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<SuccessionDemandeurComponent succession={succession} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={reponseAccordionRef}
                                    title="Réponse"
                                    icon="icon-reponse"
                                    contentShowedOnInit={true}
                                    contentType="succession"
                                    contentId={succession.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SuccessionReponseFormComponent succession={succession} submitMethod={(formData: ISuccessionReponseExternalFormData)=>successionsService.reponse(props.sid,formData, true)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<SuccessionReponseComponent succession={succession} />}
                                />

                                <AsideSuccessionComponent type="fiche" succession={succession} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
