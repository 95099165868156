import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {CtrcsService, ICtrcGetApiResponse} from "../Services/Ctrcs.service";
import {ICtrc} from "../Models/Ctrc.model";



export default function useCtrcs(){
    const ctrcsService: CtrcsService = new CtrcsService();
    const history = useHistory();

    /**
     * Récupération d'une ctrc à partir de son id
     *
     * @param {string} cid
     * @param {(ctrc: ICtrc) => void} callback
     * @param {() => void} callbackError
     */
    const getCtrcById = (cid: string, callback:(ctrc: ICtrc) => void, callbackError?: () => void ) => {
        ctrcsService.getCtrc(cid).then((response: ICtrcGetApiResponse) => {
            if( response && response.datas.ctrc){
                if(callback){
                    callback(response.datas.ctrc);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathCtrcs);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du ctrc",type: "danger"});
        });
    };

    return [getCtrcById];
};

