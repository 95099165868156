import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {IOrganisme} from "src/Models/Organisme.model";
import {IOption} from "../Components/Fields/Select.field.component";
import {IDepartement} from "../Models/Departement.model";

export type IOrganismesGetPaginationApi = IApiPagination & {
    data: IOrganisme[],
}
export type IOrganismesWithPaginationGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IOrganismesGetPaginationApi
    }
}
export type IOrganismesGetApiResponse = IApiCustomResponse & {
    datas: {
        organismes: IOrganisme[]
    }
}

export type IDepartementsGetApiResponse = IApiCustomResponse & {
    datas: {
        departements: IDepartement[]
    }
}

export type IPersonnesExterieuresGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IPersonnesExterieuresGetPaginationApi
    }
}
export type IPersonnesExterieuresGetPaginationApi = IApiPagination & {
    data: IPersonneExterieure[],
}
export type IAutresContactsGetPaginationApi = IApiPagination & {
    data: IOrganisme[] | IPersonneExterieure[],
}
export type IAutresContactsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IAutresContactsGetPaginationApi
    }
}
export type IOrganismeGetApiResponse = IApiCustomResponse & {
    datas?: {
        organisme: IOrganisme
    }
}
export type IPersonneExterieureGetApiResponse = IApiCustomResponse & {
    datas?: {
        personneExterieure: IPersonneExterieure
    }
}
export type IPresidentsGetApiResponse = IApiCustomResponse & {
    datas?: {
        presidents: IPersonneExterieure[]
    }
}
export type IInspecteursComptablesGetApiResponse = IApiCustomResponse & {
    datas?: {
        inspecteurs: IPersonneExterieure[]
    }
}
export type IAutresContactsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}
export type IOrganismeInformationsFormData = {
    nom: string,
}
export type IPersonneExterieureIdentiteFormData = {
}
export type IOrganismeUpdatePersonnesFormData = {
    personnesToAdd?: number[],
    personnesToDelete?: number[];
}

export type IOrganismesGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}


export class AutresContactsService extends ApiService{
    /**
     * Permet la récupération d'une liste des autresContacts
     *
     * @param {IAutresContactsGetFormData} formData
     * @returns {Promise<IAutresContactsGetApiResponse>}
     */
    public getAutresContacts(formData: IAutresContactsGetFormData): Promise<IAutresContactsGetApiResponse>{
        return this.doApiCall('api/annuaire/autres-contacts', 'GET', formData);
    }


    /**
     * Permet la récupération de toutes les personnes
     *
     * @param {IAutresContactsGetFormData} formData
     * @returns {Promise<IPersonnesExterieuresGetApiResponse>}
     */
    public getPersonnesExterieures(formData: IAutresContactsGetFormData): Promise<IPersonnesExterieuresGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/all', 'GET',formData);
    }

    /**
     * Permet la récupération de toutes les personnes qui sont inspecteurs comptablles
     *
     * @returns {Promise<IPersonnesExterieuresGetApiResponse>}
     */
    public getInspecteursComptables(): Promise<IInspecteursComptablesGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/inspecteurs-comptables', 'GET');
    }

    /**
     * Permet la récupération de tous les intervenants
     *
     * @param {IAutresContactsGetFormData} formData
     * @returns {Promise<IPersonnesExterieuresGetApiResponse>}
     */
    public getIntervenants(formData: IAutresContactsGetFormData): Promise<IPersonnesExterieuresGetApiResponse>{
        return this.doApiCall('api/formations/intervenants', 'GET',formData);
    }

    /**
     * Permet la récupération de tous les présidents de chambre
     *
     * @returns {Promise<IPresidentsGetApiResponse>}
     */
    public getPresidents(): Promise<IPresidentsGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/presidents', 'GET');
    }

    /**
     * Permet la récupération de tous les présidents de chambre
     *
     * @returns {Promise<IPresidentsGetApiResponse>}
     */
    public getPresidentsChambres(): Promise<IPresidentsGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/presidents-chambres', 'GET');
    }




    /**
     * Permet la récupération de toutes les organismes
     *
     * @returns {Promise<IOrganismesGetApiResponse>}
     */
    public getOrganismes(): Promise<IOrganismesGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes/all', 'GET');
    }

    /**
     * Permet la récupération de tous les départements des organismes
     *
     * @returns {Promise<IDepartementsGetApiResponse>}
     */
    public getOrganismesDepartements(): Promise<IDepartementsGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes/departements', 'GET');
    }

    /**
     * Permet la récupération de tous les départements des personnes extérieures
     *
     * @returns {Promise<IDepartementsGetApiResponse>}
     */
    public getPersonnesExterieuresDepartements(): Promise<IDepartementsGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/departements', 'GET');
    }

    /**
     * Permet la récupération d'une liste d'organismes
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getOrganismesWithPagination(formData: IOrganismesGetFormData): Promise<IOrganismesWithPaginationGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes', 'GET', formData);
    }

    /**
     * Permet la récupération d'un organisme à partir de son id
     *
     * @param {string} oid
     * @returns {Promise<IOrganismeGetApiResponse>}
     */
    public getOrganisme(oid: string): Promise<IOrganismeGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes/' + oid, 'GET');
    }

    /**
     * Permet la récupération d'une personne extérieure à partir de son id
     *
     * @param {string} pid
     * @returns {Promise<IPersonneExterieureGetApiResponse>}
     */
    public getPersonneExterieure(pid: string): Promise<IPersonneExterieureGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/' + pid, 'GET');
    }


    /**
     * Creation d'un organisme
     *
     * @param {IOrganismeInformationsFormData} formDatas
     * @returns {Promise<IOrganismeGetApiResponse>}
     */
    public createOrganisme(formDatas: IOrganismeInformationsFormData): Promise<IOrganismeGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes/create', 'POST', formDatas);
    }


    /**
     * Creation d'une personne extérieure
     *
     * @param {IPersonneExterieureIdentiteFormData} formDatas
     * @returns {Promise<IPersonneExterieureGetApiResponse>}
     */
    public createPersonne(formDatas: IPersonneExterieureIdentiteFormData): Promise<IPersonneExterieureGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/create', 'POST', formDatas);
    }

    /**
     * Modification d'une personne extérieure
     * @param {string} pid
     * @param {IPersonneExterieureIdentiteFormData} formDatas
     * @returns {Promise<IPersonneExterieureGetApiResponse>}
     */
    public updatePersonne(pid: string, formDatas: IPersonneExterieureIdentiteFormData): Promise<IPersonneExterieureGetApiResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/'+pid+'/update', 'POST', formDatas);
    }


    /**
     * Modification d'unorganisme
     *
     * @param {string} oid
     * @param {IOrganismeInformationsFormData} formDatas
     * @returns {Promise<IOrganismeGetApiResponse>}
     */
    public updateOrganisme(oid: string, formDatas: IOrganismeInformationsFormData): Promise<IOrganismeGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes/'+oid+'/update', 'POST', formDatas);
    }


    /**
     * Suppression d'une personne d'un organisme
     *
     * @param {string} oid
     * @param {string} pid
     * @returns {Promise<IOrganismeGetApiResponse>}
     */
    public deletePersonneFromOrganisme(oid: string, pid: string): Promise<IOrganismeGetApiResponse>{
        return this.doApiCall('api/annuaire/organismes/'+oid+'/personnes/'+pid+'/delete', 'POST');
    }

    /**
     * Mise à jour des personnes d'un organisme
     *
     * @param {string} oid
     * @param {IOrganismeUpdatePersonnesFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updatePersonneToOrganisme(oid: string, formDatas: IOrganismeUpdatePersonnesFormData){
        return this.doApiCall('api/annuaire/organismes/'+oid+'/personnes/update', 'POST', formDatas);
    }



    /**
     * Permet de formater une liste d'organismes pour les selects
     *
     * @param {IOrganisme[]} organismes
     * @returns {IOption[]}
     */
    public formatOrganismesForSelect(organismes: IOrganisme[]): IOption[]{
        const options: IOption[] = [];
        for(let organisme of organismes){
            const option: IOption = {
                value: organisme.id.toString(),
                label: organisme.nom
            }

            options.push(option)
        }

        return options;
    }

    /**
     * Permet de formater une liste de départements pour les selects
     *
     * @param {IDepartement[]} departements
     * @returns {IOption[]}
     */
    public formatDepartementsForSelect(departements: IDepartement[]): IOption[]{
        const options: IOption[] = [];
        for(let departement of departements){
            const option: IOption = {
                value: departement.code,
                label: `${departement.nom} - ${departement.code}`
            }

            options.push(option);
        }

        return options;
    }




    /**
     * Permet la suppression d'un organisme
     *
     * @param {string} oid
     * @returns {Promise<IApiCustomResponse>}
     */
    public deleteOrganisme(oid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/organismes/' + oid + '/delete', 'POST');
    }

    /**
     * Permet la suppression d'une personne
     *
     * @param {string} pid
     * @returns {Promise<IApiCustomResponse>}
     */
    public deletePersonneExterieure(pid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/personnes-exterieures/' + pid + '/delete', 'POST');
    }

}

