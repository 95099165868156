import React, {useContext, useEffect, useRef, useState} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import UserFormComponent from "src/Components/Forms/Users/UserForm.component";
import {IUserEditFormData, UsersService} from "src/Services/Users.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import UserInformationsComponent from "src/Components/Users/UserInformations.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import AsideUtilisateursComponent from "src/Components/Aside/AsideUtilisateurs.component";

type UserAuthFicheScreenProps = {
}

export default function UserAuthFicheScreen(props: UserAuthFicheScreenProps){
    const identiteAccordionRef = useRef(null);
    const usersService = new UsersService(null);
    const {authUser, isAuthenticated, getUser} = useContext(UserContext) as IUserContext;
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const {isAdmin, isSuperAdmin} = useContext(UserContext) as IUserContext;
    const [formOpened, setFormOpened] = useState<boolean>(false);



    useEffect(() => {
        let breadcrumbLinks = []

        if(isAdmin() || isSuperAdmin()){
            breadcrumbLinks.push( {text: "Utilisateurs", link:"/utilisateurs"});
            breadcrumbLinks.push({text: authUser.nom + ' ' + authUser.prenom});
        }
        else{
            breadcrumbLinks.push( {text: "Utilisateurs"});
            breadcrumbLinks.push({text: authUser.nom + ' ' + authUser.prenom});
        }

        setBreadcrumbLinks(breadcrumbLinks);
    }, [authUser])


    useEffect(() => {
        if(!isAuthenticated){
            // On redirige vers la home
            history.push(AppConstants.pathLogin);
        }
    }, [isAuthenticated, history]);


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmit = (): void => {
        //On refresh les infos de l'utilisateur
        getUser();

        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm();
        }
    };
    /**
     * Callbackà l'annulation du formulaire
     */
    const onCancel = (): void => {
        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm(true);
        }
    }

    return (
        <LayoutComponent showMenu={true}>
            <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={authUser.nom + ' ' + authUser.prenom} icon="icon-notaires"  onSearch={null} />

            <TabsContainerComponent onClickTabCallback={() => {setFormOpened(false);}}>
                <TabPanelComponent label="Fiche" icon="icon-fiche">
                    <div className="fiche-content -right-gutter">
                        <AccordionWithFormSwitchComponent
                            ref={identiteAccordionRef}
                            title="Identité"
                            icon="icon-informations"
                            contentShowedOnInit={true}
                            contentType="user"
                            contentId={authUser.id}
                            onFormOpen={() => {setFormOpened(true);}}
                            onFormClose={() => {setFormOpened(false);}}
                            hideEditButton={formOpened}
                            form={<UserFormComponent user={authUser} submitMethod={(formData:IUserEditFormData)=>usersService.updateAuthUser(formData)} onSuccessSubmit={onSuccessSubmit} onCancel={onCancel} />}
                            infos={<UserInformationsComponent user={authUser} />}
                        />


                        <AsideUtilisateursComponent type="fiche" />
                    </div>
                </TabPanelComponent>
            </TabsContainerComponent>
        </LayoutComponent>
    )
}
