import {
    IOfficeGetApiResponse,
    IOfficeInformationFormData,
    IOfficesGetAllApiResponse,
    OfficesService
} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {IOffice} from "src/Models/Office.model";
import OfficesHelper from "src/Helpers/Offices.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type IOfficeInformationsFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IOfficeInformationFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeInformationsFormComponent(props: IOfficeInformationsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [offices, setOffices] = useState<IOffice[]>(null);
    const [officesLoaded, setOfficesLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();

    useEffect(() => {
        const officesService: OfficesService = new OfficesService();
        officesService.getAllOfficesPrincipales().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });
    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const office: IOffice = props.office;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'number',
                fieldName: "CRPCEN",

                label: "CRPCEN",
                placeholder: "CRPCEN",
                required: true,
                maxLength: 5,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                showFieldErrorDetail: true,

                oldValue: office && office.CRPCEN ? office.CRPCEN : "",
            },
            {
                type: 'select',
                fieldName: "type",

                label: "Type",
                placeholder: "Type",
                required: true,
                options: OfficesConstants.typesOfficesOptions,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.type ? office.type : "",
            },
            {
                type: 'select',
                fieldName: "officeId",

                label: "Office principal",
                placeholder: "Office principal",
                required: true,
                options: OfficesHelper.formatListForOptions(offices),
                modificators: props.isOnCreationStep ? "-on-white" : "",
                condition: {
                    fieldName: "type",
                    value: "annexe",
                },

                oldValue: office && office.officePrincipale && office.officePrincipale.id ? office.officePrincipale.id.toString() : "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom de l'office",
                placeholder: "Nom de l'office",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                showFieldErrorDetail: true,

                oldValue: office && office.nom ? office.nom : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'file',
                fieldName: "logo",

                label: "Logo",
                placeholder: "Logo",
                required: false,
                //showImage:true,
                showImageDeleteButton:true,

                oldValue: office && office.logo ? office.logo : "",
            },
        ];


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, [offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOfficeInformationFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: !props.isOnCreationStep ? "Les informations de l'office ont bien été modifiée." : "L'office a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                officesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }

        </div>
    )

}
