import {IOption} from "src/Components/Fields/Select.field.component";

export type ICoordonneeConstants = {
    typeOptions: IOption[],
    typeOptionsNotaire: IOption[],
    destinationOptions: IOption[],
    destinationOptionsTelephone: IOption[],
    destinationOptionsTelephoneNotaire: IOption[],
    destinationOptionsTelephoneOffice: IOption[],
    destinationOptionsEmail: IOption[],
    destinationOptionsEmailOffice: IOption[],
    destinationOptionsEmailNotaire: IOption[],
    destinationOptionsFax: IOption[],
    destinationOptionsUrl: IOption[],
}
export const CoordonneeConstants: ICoordonneeConstants = {
    typeOptions: [
        {
            value: "email",
            label: "Email"
        },
        {
            value: "telephone",
            label: "Téléphone"
        },
        // {
        //     value: "fax",
        //     label: "Fax"
        // },
        {
            value: "url",
            label: "URL"
        },
    ],
    typeOptionsNotaire: [
        {
            value: "email",
            label: "Email"
        },
        {
            value: "telephone",
            label: "Téléphone"
        },
    ],
    destinationOptions: [
        {
            value: "bureau",
            label: "Bureau (affichage sur le site)"
        },
        {
            value: "comptabilite",
            label: "Comptabilité"
        },
        {
            value: "domicile",
            label: "Domicile"
        },
        {
            value: "mobile",
            label: "Mobile"
        },
        {
            value: "perso",
            label: "Perso (affichage sur le site)"
        },
        {
            value: "convocation",
            label: "Envoi des convocations"
        },
        {
            value: "principal",
            label: "Principal"
        },
        {
            value: "redirige",
            label: "Redirigé"
        },
        {
            value: "ligne-directe",
            label: "Ligne directe"
        },
    ],
    destinationOptionsTelephone: [
        {
            value: "ligne-directe",
            label: "Ligne directe"
        },
        {
            value: "domicile",
            label: "Domicile"
        },
        {
            value: "mobile",
            label: "Mobile"
        },
    ],
    destinationOptionsTelephoneNotaire: [
        {
            value: "ligne-directe",
            label: "Ligne directe"
        },
        {
            value: "mobile",
            label: "Mobile"
        },
        {
            value: "autre",
            label: "Autre"
        },
    ],
    destinationOptionsTelephoneOffice: [
        {
            value: "principal",
            label: "Principal"
        },
        {
            value: "comptabilite",
            label: "Comptabilité"
        },
        {
            value: "autre",
            label: "Autre"
        },
    ],
    destinationOptionsEmail: [
        {
            value: "principal",
            label: "Principal"
        },
        {
            value: "bureau",
            label: "Bureau (affichage sur le site)"
        },
        {
            value: "comptabilite",
            label: "Comptabilité"
        },
        {
            value: "perso",
            label: "Perso (affichage sur le site)"
        },
        {
            value: "convocation",
            label: "Envoi des convocations"
        },
    ],
    destinationOptionsEmailOffice: [
        {
            value: "principal",
            label: "Principal"
        },
        {
            value: "comptabilite",
            label: "Comptabilité"
        },
        {
            value: "autre",
            label: "Autre"
        },
    ],
    destinationOptionsEmailNotaire: [
        {
            value: "principal",
            label: "Principal (affichage sur le site)"
        },
        {
            value: "perso",
            label: "Perso"
        },
        {
            value: "convocation",
            label: "Envoi des convocations"
        },
    ],
    destinationOptionsFax: [
        {
            value: "bureau",
            label: "Bureau (affichage sur le site)"
        },
        {
            value: "comptabilite",
            label: "Comptabilité"
        },
    ],
    destinationOptionsUrl: [
        {
            value: "bureau",
            label: "Bureau (affichage sur le site)"
        },
    ],
};
