import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type ISinistresConstants = {
    minYear: number,
    sourceOptions: IOption[],
    civiliteOptions: IOption[],
    creationSteps: ICreationSteps[],
    documentsTypesOptions: IOption[],
    documentsOrderOptions: IOption[],
}
export const SinistresConstants: ISinistresConstants = {
    minYear: 2000,
    sourceOptions: [
        {
            value: "assignation",
            label: "Assignation",
        },
        {
            value: "courrier-une-partie-ou-un-tiers",
            label: "Courrier d’une partie ou d’un tiers",
        },
        {
            value: "autre",
            label: "Autre",
        },
    ],

    civiliteOptions: [
        {
            value: "madame-monsieur",
            label: "Madame et Monsieur",
        },
        {
            value: "monsieur",
            label: "Monsieur",
        },
        {
            value: "madame",
            label: "Madame",
        },
        {
            value: "maitre",
            label: "Cher Maître",
        },
    ],


    creationSteps: [
        {
            url: "/description",
            title: "Description",
            icon: "icon-description",
        },
        {
            url: "/documents",
            title: "Documents",
            icon: "icon-documents",
        },
    ],

    documentsTypesOptions: [
        {
            value: "presentation",
            label: "Fiche synthétique",
        },
        {
            value: "rapport",
            label: "Rapport",
        },
        {
            value: "autre",
            label: "Autre",
        },
    ],

    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "type",
            label: "Type"
        },
    ]
};
