import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {IFormField} from "./Input.field.component";
import LabelComponent from "./Label.component";
import StringHelper from "../../Helpers/String.helper";
import useClickOutside from "../../Hooks/useClickOutside";
import RadioFieldComponent from "./Radio.field.component";
import {IApiErrorMessage} from "../../Services/Api.service";

export type IOption = {
    value: string,
    label: string,
    modificators?: string,
    keywords?: string[],
    circleLetter?: string,
    circleColorModificators?: string,
    isChecked?: boolean
    disabled?: boolean
}
export type IOptionsGroup = {
    label: string,
    options: IOption[],
    isChecked?: boolean,
}

type SelectFieldComponentProps = IFormField & {
    onChange: (value: string) => void,
    options?: IOption[],
    optionsGroup?: IOptionsGroup[],
    oldValue?: string,
    placeholderKeyword?: string,
    hideSearch?: boolean,
    hideEmptyOption?: boolean,
}

export default function SelectFieldComponent(props: SelectFieldComponentProps){
    const [currentValue, setCurrentValue] = useState<string>("");
    const [selectedLabel, setSelectedLabel] = useState<string>(props.placeholder || "");
    const [dropdownShowed, setDropdownShowed] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>("");
    const [filteredOptions, setFilteredOptions] = useState<IOption[]>(props.options || []);
    const [filteredOptionsGroup, setFilteredOptionsGroup] = useState<IOptionsGroup[]>(props.optionsGroup || []);
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const ref = useRef<HTMLDivElement>();
    useClickOutside(ref,()=>setDropdownShowed(false));


    useEffect(()=> {
        setCurrentValue(props.oldValue);
    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);



    /**
     * Génération des listes d'option
     * Si le keyword est pas vide, on filtre les options dispnibles
     * Sinon on prend toutes les options disponibles
     */
    useEffect(() => {
        const stringHelper = new StringHelper();
        let newFilteredOptions: IOption[] = [];
        let newFilteredOptionsGroup: IOptionsGroup[] = [];

        if( keyword) {
            const keywordCleaned = stringHelper.cleanString(keyword);

            if (props.options && props.options.length) {
                for (let option of props.options) {
                    const labelCleaned = stringHelper.cleanString(option.label);
                    const optionKeywords: string[] = option.keywords || [];

                    if( !keywordCleaned.length || labelCleaned.includes(keywordCleaned)){
                        newFilteredOptions.push(option);
                    }
                    else {
                        optionKeywords.forEach((k: string) => {
                            if(newFilteredOptions.includes(option)) return;
                            const kCleaned = stringHelper.cleanString(k);

                            if (kCleaned.includes(keywordCleaned)) {
                                newFilteredOptions.push(option);
                            }
                        });
                    }
                }
            }

            if (props.optionsGroup && props.optionsGroup.length) {
                for (let group of props.optionsGroup) {
                    const optionsList: IOption[] = [];

                    for (let option of group.options) {
                        const labelCleaned = stringHelper.cleanString(option.label);
                        const optionKeywords: string[] = option.keywords || [];

                        if( labelCleaned.includes(keywordCleaned)){
                            optionsList.push(option);
                        }
                        else {
                            optionKeywords.forEach((k: string) => {
                                if(optionsList.includes(option)) return;

                                const kCleaned = stringHelper.cleanString(k);

                                if (kCleaned.includes(keywordCleaned)) {
                                    optionsList.push(option);
                                }
                            });
                        }
                    }
                    if( optionsList && optionsList.length){
                        group.options = optionsList;
                        newFilteredOptionsGroup.push(group);
                    }
                }
            }
        }
        else{
            newFilteredOptions = props.options || [];
            newFilteredOptionsGroup = props.optionsGroup || [];
        }

        setFilteredOptions(newFilteredOptions);
        setFilteredOptionsGroup(newFilteredOptionsGroup);
    }, [props.options, props.optionsGroup, keyword]);



    /**
     * On ecouté le changement des currentValues
     * Afin de générer un label pour le faux select
     *
     */
    useEffect(() => {
        generateSelectedLabel();
    }, [currentValue,props.options, props.optionsGroup]);


    useEffect(() => {
        if(!dropdownShowed) setKeyword("");
    }, [dropdownShowed]);


    /**
     * Ecoute du changement de mot clé
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onKeywordChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setKeyword(e.target.value);
    };


    const onChangeRadio = (e: ChangeEvent<HTMLInputElement>): void => {
        const value: string = e.target.value;
        setCurrentValue(value);
        setDropdownShowed(false);
        generateSelectedLabel();

        //On envoi les valeurs au parent
        if(props.onChange) {
            props.onChange(value);
        }

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    }


    /**
     * Permet la génération du label contenant les label des options choisis
     * Affiché dans le faux select
     */
    const generateSelectedLabel = (): void => {
        let label: string = "";

        if(currentValue){
            const labels: string[] = [];

            if (props.options && props.options.length) {
                for (let option of props.options) {
                    if(option.value === currentValue){
                        labels.push(option.label);
                    }

                }
            }
            if (props.optionsGroup && props.optionsGroup.length) {
                for (let group of props.optionsGroup) {
                    for (let option of group.options) {
                        if(option.value === currentValue){
                            labels.push(option.label);
                        }
                    }
                }
            }

            label = labels.join(', ');
        }

        if( !label.length){
            label = props.placeholder || "";
        }

        setSelectedLabel(label);
    };



    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            <div className={`select-multiple-wrapper ${errors ? '-error' : ''} ${dropdownShowed ? '-opened' : ''}`}>

                <div className={`select-line ${dropdownShowed ? '-options-showed' : ""}`} onClick={() => setDropdownShowed(!dropdownShowed)} >
                    <div className={`form-field-input`}><div>{selectedLabel}</div></div>
                    <i className="form-field-aside icon-arrow-down-full"  />
                </div>

                {
                    dropdownShowed &&
                    <div className="options-wrapper" ref={ref}>
                        {
                            !props.hideSearch &&
                            <div className="search-wrapper">
                                <input type="text" className="form-field-input -full-w" name="search-option" onChange={onKeywordChange} placeholder={props.placeholderKeyword ? props.placeholderKeyword :''} />
                            </div>
                        }

                        <div className="list">
                            {
                                !props.hideEmptyOption &&
                                <RadioFieldComponent value="" id={props.fieldName + '_empty'} name={props.fieldName} label="Aucun(e)" onChange={onChangeRadio} isChecked={!currentValue} key={'radio_empty'} modificators="-with-border-bottom -hide-radio"/>
                            }

                            {
                                filteredOptions && filteredOptions.map( (o: IOption, index: number) =>
                                    <RadioFieldComponent
                                        value={o.value}
                                        id={props.fieldName + '_' + o.value}
                                        name={props.fieldName}
                                        label={o.label}
                                        onChange={onChangeRadio}
                                        isChecked={currentValue && currentValue === o.value}
                                        key={'radio_' + index}
                                        isDisabled={o.disabled || false}
                                        modificators={index !== filteredOptions.length-1 ? '-with-border-bottom -hide-radio' : '-hide-radio' } />
                                )
                            }
                            {
                                filteredOptionsGroup && filteredOptionsGroup.map( (g: IOptionsGroup, index: number) =>
                                    <div className="form-field-group" key={index}>
                                        <div className="group-title">
                                            <LabelComponent fieldName={props.fieldName + "_group_" + index} label={g.label} modificators={"-group"} />
                                        </div>
                                        {
                                            g.options && g.options.map( (o: IOption, indexOption: number) =>
                                                <RadioFieldComponent
                                                    value={o.value}
                                                    id={props.fieldName + '_' + o.value}
                                                    name={props.fieldName}
                                                    label={o.label}
                                                    onChange={onChangeRadio}
                                                    isDisabled={o.disabled || false}
                                                    isChecked={currentValue && currentValue === o.value} key={'radio_' + indexOption}
                                                    modificators={`${index !== filteredOptions.length-1 ? '-with-border-bottom -hide-radio' : '-hide-radio'} ${o.disabled ? '-disabled' : ''}`} />
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
