import {IOption} from "src/Components/Fields/Select.field.component";

export type ILabelsConstants = {
    moduleOptions: IOption[],
}
export const LabelsConstants: ILabelsConstants = {
    moduleOptions: [
        {
            value: "notaire",
            label: "Notaire"
        },
        {
            value: "office",
            label: "Office"
        },
    ],
};
