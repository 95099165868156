import {ISuccession} from "src/Models/Succession.model";
import DateHelper from "../../Helpers/Date.helper";

type ISuccessionIdentiteComponentProps = {
    succession: ISuccession,
    showDate?: boolean,
}

export default function SuccessionIdentiteComponent(props: ISuccessionIdentiteComponentProps) {
    const succession: ISuccession = props.succession;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                {
                    props.showDate &&
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date de la demande</p>
                                <p className="value">{succession.date ? DateHelper.getFormatedDate(succession.date) : '-'}</p>
                            </div>
                        </div>
                }

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{succession.identiteNom || "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Prénom</p>
                        <p className="value">{succession.identitePrenom || "-"}</p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <p className="title">Naissance</p>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de naissance</p>
                        <p className="value">{succession.identiteNaissanceDate ? DateHelper.getFormatedDate(succession.identiteNaissanceDate) : "-" }</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Lieu de naissance</p>
                        <p className="value">{succession.identiteNaissanceLieu || "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Département</p>
                        <p className="value">{succession.identiteNaissanceDepartement || "-"}</p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <p className="title">Décès</p>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de décès</p>
                        <p className="value">{succession.identiteDecesDate ? DateHelper.getFormatedDate(succession.identiteDecesDate) : "-" }</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Lieu</p>
                        <p className="value">{succession.identiteDecesLieu || "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Département</p>
                        <p className="value">{succession.identiteDecesDepartement ? (succession.identiteDecesDepartement != '00' ? succession.identiteDecesDepartement : 'Autre' ) : "-"}</p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <p className="title">Adresse</p>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Adresse</p>
                        <p className="value">{succession.identiteAdresse || "-" }</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Code Postal</p>
                        <p className="value">{succession.identiteAdresseCodePostal || "-"}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Ville</p>
                        <p className="value">{succession.identiteAdresseVille || "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
