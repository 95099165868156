const usePrint = () =>{

    const printScreen = () => {
        window.print();
    };

    return [printScreen];
};

export default usePrint;
