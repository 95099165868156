import React, {useEffect, useState} from "react";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {IApiPaginationLink} from "src/Services/Api.service";
import {useHistory, useLocation} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import OfficesHelper from "src/Helpers/Offices.helper";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideCotisationsOfficesComponent from "src/Components/Aside/AsideCotisationsOffices.component";
import CotisationsOfficesExportComponent from "src/Components/Cotisations/CotisationsOfficesExport.component";
import {Link} from "react-router-dom";
import CotisationsListeOfficeCourrierForm from "../../Components/Forms/Cotisations/CotisationsListeOfficeCourrierForm.component";
import {
    CotisationsService,
    ICotisationsSaisieOfficesGetApiResponse,
    ICotisationsSaisieOfficesGetFormData
} from "../../Services/Cotisations.service";
import {ICotisationsOffice} from "../../Models/CotisationsOffice.model";
import LoadingComponent from "../../Components/Loading/Loading.component";


type ICotisationsListeOfficesScreenProps = {
};

export default function CotisationsListeOfficesScreen(props: ICotisationsListeOfficesScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathCotisationsListeOffices);
    const [cotisationsOfficesList, setCotisationsOfficesList] = useState<ICotisationsOffice[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     * Génération des breadcrumb links
     */
    useEffect(() => {
        //Génération des breadcrumb links
        const breadcrumbLinks = [
            { text: "Cotisations",  link: AppConstants.pathMosaique + '#cotisations'},
            { text: "Liste des Offices" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);


        // Mise à jour des paramètres par défaut
        const params: any = location.search;
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(initialFilters);
    }, []);


    /**
     * Lancement de la recherche lors de la modification des filtres
     */
    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    /**
     * Récupération des offices
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const cotisationsService: CotisationsService = new CotisationsService();
        cotisationsService.getCotisationsForOffices(filters as ICotisationsSaisieOfficesGetFormData).then((response: ICotisationsSaisieOfficesGetApiResponse) => {
            if (response && response.datas && response.datas.pagination) {
                const newCotisationsOfficesList = response.datas.pagination.data;
                setCotisationsOfficesList(newCotisationsOfficesList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };





    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Liste des Offices"
                    icon="icon-cotisation-liste-office"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />


                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">
                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "officeCRPCEN", label: "CRPCEN"},
                                                    {slug: "officeNom", label: "nom"},
                                                    {slug: "officeType", label: "type"},
                                                    {slug: "officeVille", label: "ville"},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cotisationsOfficesList && cotisationsOfficesList.map((cotisationsOffice: ICotisationsOffice, index: number) =>
                                                    <tr key={index}
                                                        onClick={() => history.push(`${AppConstants.pathCotisationsListeOffices}/${cotisationsOffice.office.id}`)}
                                                        className="tr">
                                                        <td className="td -bold">
                                                            {cotisationsOffice.office.CRPCEN}
                                                        </td>
                                                        <td className="td">
                                                            <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${cotisationsOffice.office.id}`} onClick={(e) => e.stopPropagation()}>{cotisationsOffice.office.nom}</Link>
                                                        </td>
                                                        <td className="td">
                                                            {OfficesHelper.getFormatedType(cotisationsOffice.office.type)}
                                                        </td>
                                                        <td className="td -uppercase">
                                                            {cotisationsOffice.office.adressePrincipale ? cotisationsOffice.office.adressePrincipale.ville : "-"}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>

                                    <AsideCotisationsOfficesComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>


                            <TabPanelComponent label="Courriers" icon="icon-courrier">
                                <div className="fiche-content -right-gutter">
                                    <div className="g-content">
                                        <CotisationsListeOfficeCourrierForm />
                                    </div>
                                </div>
                            </TabPanelComponent>

                        </TabsContainerComponent>
                    </>
                }

            </div>

            <CotisationsOfficesExportComponent cotisationsYears={[]} setShowExport={() => setShowExport(false)} showExport={showExport} />
        </LayoutComponent>
    )
}
