import DateHelper from "src/Helpers/Date.helper";
import NotairesHelper from "src/Helpers/Notaires.helper";
import FileHelper from "src/Helpers/File.helper";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IPredecesseur} from "src/Models/Predecesseur.model";
import React from "react";


type IOfficePredecesseursComponentProps = {
    predecesseurs: IPredecesseur[],
}

export default function OfficePredecesseursComponent(props: IOfficePredecesseursComponentProps) {
    const predecesseurs: IPredecesseur[] = props.predecesseurs;
    const history = useHistory();

    const getImagePlaceholder = function(sexe?: string): string{
        let imagePlaceholder: string = "";

        switch (sexe) {
            case "masculin":
                imagePlaceholder = FileHelper.getPlaceholderUrl("picture-man");
                break;
            case "feminin":
                imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
                break;

            case "nonPrecise":
            default:
                imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
                break;
        }

        return imagePlaceholder;
    }

    return (
        <section className="informations-wrapper -notaires">
            <div className="columns"  >
            {
                predecesseurs && predecesseurs.map((predecesseur: IPredecesseur, index: number) =>
                    <React.Fragment key={index}>
                        {
                            predecesseur.notaire &&
                                <div className="column -notaire informations-notaire" onClick={() => history.push(AppConstants.pathNotaires + "/" + predecesseur.notaire.id)}>
                                    <div className={"image-wrapper"}>
                                        <figure className="informations-image -list-notaire">
                                            <img src={predecesseur.notaire.photo ? FileHelper.getImageUrlFromSize(predecesseur.notaire.photo,"officeNotaire") : getImagePlaceholder(predecesseur.notaire.sexe)} alt="" className="image" />
                                        </figure>
                                    </div>

                                    <div className={"content"}>
                                        <p className="name">{predecesseur.notaire.nom} {predecesseur.notaire.prenom}</p>
                                        {
                                            predecesseur.type &&
                                            <p className="info">{NotairesHelper.getFormattedType(predecesseur.type)}</p>
                                        }
                                        {
                                            predecesseur.notaire.historiqueEnCours && predecesseur.notaire.historiqueEnCours.officeType &&
                                            <>
                                                <p className="info">{DateHelper.getFormatedDate(predecesseur.notaire.historiqueEnCours.dateArrivee)}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                        }
                        {
                            !predecesseur.notaire &&
                            <div className="column -notaire informations-notaire">
                                <div className={"image-wrapper"}>
                                    <figure className="informations-image -list-notaire">
                                        <img src={getImagePlaceholder()} alt="" className="image" />
                                    </figure>
                                </div>

                                <div className="content">
                                    <p className="name">{predecesseur.nom} {predecesseur.prenom}</p>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
            </div>
        </section>
    );
}
