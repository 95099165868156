import FileHelper from "src/Helpers/File.helper";
import {ILabel} from "src/Models/Label.model";
import LabelsHelper from "src/Helpers/Labels.helper";

type ILabelInformationsComponentProps = {
    label: ILabel,
}

export default function LabelInformationsComponent(props: ILabelInformationsComponentProps) {
    const label: ILabel = props.label;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column -auto">
                    <figure className="informations-image -resume -border">
                        {
                            label.fichier &&
                            <img src={FileHelper.getImageUrlFromSize(label.fichier,"thumbnail")} alt="" className="image" />
                        }
                        {
                            !label.fichier &&
                            <img src={FileHelper.getPlaceholderUrl()} alt="" className="image" />
                        }
                    </figure>
                </div>

                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{label.nom || "-"}</p>
                    </div>
                </div>

                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Module</p>
                        <p className="value">{label.module ? LabelsHelper.getFormatedModule(label.module) : '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
