import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {IApiPaginationLink} from "src/Services/Api.service";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {useHistory, useLocation} from "react-router";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {AutresContactsService, IAutresContactsGetFormData} from "src/Services/AutresContacts.service";
import DateHelper from "src/Helpers/Date.helper";
import IntervenantsExportComponent from "src/Components/Formations/Intervenants/IntervenantsExport.component";
import AsideIntervenantComponent from "src/Components/Aside/AsideIntervenants.component";
import {INotaire} from "../../../Models/Notaire.model";
import LoadingComponent from "../../../Components/Loading/Loading.component";


const IntervenantsScreen = () => {

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathFormations + '/intervenants');
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [intervenants, setIntervenants] = useState<IPersonneExterieure[]>([]);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const autresContacts: AutresContactsService = new AutresContactsService();


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(initialFilters);
    }, []);

    useEffect(() => {
        launchFilteredSearch();
    }, [filters]);

    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setLoaded(false);


        // Récupération du groupe des intervenants
        autresContacts.getIntervenants(filters as IAutresContactsGetFormData).then((response) => {
            if (response && response.datas.pagination) {

                setIntervenants(response.datas.pagination.data);

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                setLoaded(true);
            }
        }, () => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la récupération des intervenants.",
                type: "danger"
            });
        });
    };

    if (!filters) return null;

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent
                    breadcrumbLinks={[{link: AppConstants.pathMosaique + '#formations', text: "Formations"}, {text: "Intervenants"}]}
                    text="Intervenants"
                    icon="icon-univers-formations"/>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-autres-contacts">
                                <div className={`list-content -right-gutter`}>
                                    {
                                        !loaded &&
                                        <LoadingComponent />
                                    }

                                    {
                                        loaded &&
                                        <>
                                            <table className={`list-table`}>
                                                <thead className={"headers"}>
                                                <tr>
                                                    {[
                                                        {slug: "nomPersonne", label: "Personne"},
                                                        {slug: "nbFormationsEffectuees", label: "Nb formations effectuées"},
                                                        {slug: "derniereFormation", label: "Dernière formation"},
                                                        {slug: "nbProchainesFormations", label: "Nb formations à venir"},
                                                        {slug: "prochaineFormation", label: "Prochaine formation"},
                                                    ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                        <TableHeaderButtonComponent
                                                            direction={filters.orderType as string || AppConstants.orderType.asc}
                                                            value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                            label={item.label} click={(value: string) => setOrder(value)}/>
                                                    </th>)}
                                                </tr>

                                                </thead>

                                                <tbody>
                                                {
                                                    intervenants && intervenants.map((intervenant: IPersonneExterieure, index: number) =>
                                                        <React.Fragment key={index}>
                                                            <tr key={index}
                                                                className="tr">
                                                                <td className="td"
                                                                    onClick={() => history.push(`/annuaire/${intervenant.type === "personne" ? "personnes-exterieures":"notaires"}/${intervenant.id}`)}>
                                                                    {intervenant.nom} {intervenant.prenom}
                                                                </td>
                                                                <td className="td">{intervenant.nbFormationsEffectuees}</td>
                                                                <td className="td">
                                                                    {intervenant.derniereFormation ? DateHelper.getFormatedDate(intervenant.derniereFormation) : "-"}
                                                                </td>
                                                                <td className="td">{intervenant.nbProchainesFormations}</td>
                                                                <td className="td">
                                                                    {intervenant.prochaineFormation ? DateHelper.getFormatedDate(intervenant.prochaineFormation) : "-"}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>

                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
                                            <AsideIntervenantComponent type={"list"} exportCallback={() => setShowExport(true)}  />

                                        </>
                                    }
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
            </div>

            <IntervenantsExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} />
        </LayoutComponent>
    )

};

export default IntervenantsScreen;
