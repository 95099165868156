import React, {useEffect, useState} from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {NotairesService} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import {AppConstants} from "../../Constants/AppConstants";

type IReclamationsAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,
}
export default function ReclamationsAdvancedSearchComponent(props: IReclamationsAdvancedSearchComponentProps) {
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notairesSyndicLoaded, setNotairesSyndicLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [notairesSyndic, setNotairesSyndic] = useState<INotaire[]>([]);

    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response) => {
            if (response && response.datas && response.datas.notaires) {
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });
        notairesService.getSyndic().then((response) => {
            if (response && response.datas && response.datas.notaires) {
                setNotairesSyndic(response.datas.notaires);
                setNotairesSyndicLoaded(true);
            }
        });
    }, []);


    return (
        <>
            {
                notairesLoaded && notairesSyndicLoaded && <AdvancedSearchComponent
                    title="Réclamations"
                    showSummary={props.showSummary}
                    modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
                    showAdvancedSearch={props.showAdvancedSearch}
                    columns={[
                        {
                            elements: [
                                {
                                    type: 'hidden',
                                    fieldName: "advanced",
                                    oldValue: "1",
                                    modificators: "-hidden"
                                },
                                {
                                    type: 'text',
                                    label: "Nom du plaignant",
                                    fieldName: "nom_complet_plaignant",
                                    oldValue: props.filters.nom_complet_plaignant as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "id_notaires",
                                    label: "Notaire.s concerné.s",
                                    modificators: "-on-white",
                                    oldValues: props.filters.id_notaires as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                                {
                                    type: 'date',
                                    label: "Date de réception (min)",
                                    fieldName: "dateReceptionMin",
                                    oldValue: props.filters.dateReceptionMin as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date d'envoi au syndic (min)",
                                    fieldName: "dateEnvoiSyndicMin",
                                    oldValue: props.filters.dateEnvoiSyndicMin as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date de retour du syndic (min)",
                                    fieldName: "dateRetourSyndicMin",
                                    oldValue: props.filters.dateRetourSyndicMin as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date conciliation (min)",
                                    fieldName: "conciliationDateMin",
                                    oldValue: props.filters.conciliationDateMin as string || "",
                                    modificators: "-on-white",
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "selectMultiple",
                                    fieldName: "objet",
                                    label: "Objet",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.objet as string[] || [],
                                    options: ReclamationsConstants.objetOptions,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "id_syndic",
                                    label: "Syndic",
                                    modificators: "-on-white",
                                    oldValues: props.filters.id_syndic as string[] || [],
                                    options: notairesSyndic ? NotairesHelper.formatListForOptions(notairesSyndic) : [],
                                },
                                {
                                    type: 'date',
                                    label: "Date de réception (max)",
                                    fieldName: "dateReceptionMax",
                                    oldValue: props.filters.dateReceptionMax as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date d'envoi au syndic (max)",
                                    fieldName: "dateEnvoiSyndicMax",
                                    oldValue: props.filters.dateEnvoiSyndicMax as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date de retour du syndic (max)",
                                    fieldName: "dateRetourSyndicMax",
                                    oldValue: props.filters.dateRetourSyndicMax as string || "",
                                    modificators: "-on-white",
                                },
                                {
                                    type: 'date',
                                    label: "Date conciliation (max)",
                                    fieldName: "conciliationDateMax",
                                    oldValue: props.filters.conciliationDateMax as string || "",
                                    modificators: "-on-white",
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "selectMultiple",
                                    fieldName: "fondement",
                                    label: "Motif invoqué par le réclamant",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.fondement as string[] || [],
                                    options: ReclamationsConstants.fondementsOption,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "origine",
                                    label: "Origine",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.origine as string[] || [],
                                    options: ReclamationsConstants.origineOptions,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "etat",
                                    label: "Etats",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.etat as string[] || [],
                                    options: ReclamationsConstants.etatOptions,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "etatAutre",
                                    label: "Motif (état : autre)",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.etatAutre as string[] || [],
                                    options: ReclamationsConstants.etatAutreOptions,
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "selectMultiple",
                                    fieldName: "conciliation",
                                    label: "Conciliation",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.conciliation as string[] || [],
                                    options: AppConstants.ouiNonOptions,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "conciliationIssue",
                                    label: "Issue de la conciliation",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.conciliationIssue as string[] || [],
                                    options: ReclamationsConstants.conciliationIssueOptions,
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "conciliationMotif",
                                    label: "Motif",
                                    hideSearchOnMultiple: true,
                                    modificators: "-on-white",
                                    oldValues: props.filters.conciliationMotif as string[] || [],
                                    options: ReclamationsConstants.conciliationMotifOptions,
                                },
                            ]
                        }
                    ]}
                    onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
                    filters={props.filters}
                    onClose={() => props.setShowAdvancedSearch()}
                    onSearch={props.onSearch}
                />
            }
        </>
    )

}
