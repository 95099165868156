import React, {useState} from "react";
import {ICorrespondance} from "../../../Models/Correspondance.model";
import {ICorrespondanceInvite} from "../../../Models/CorrespondanceInvite.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import {IApiCustomResponse} from "../../../Services/Api.service";
import {CorrespondancesService} from "../../../Services/Correspondances.service";
import ConfirmationComponent from "../../Confirmation/Confirmation.component";
import {ICorrespondanceTest} from "../../../Models/CorrespondanceTest.model";
import {AppConstants} from "../../../Constants/AppConstants";
import SeeMoreLinkComponent from "../../Links/SeeMoreLink.component";
import {Link} from "react-router-dom";


const CorrespondanceInvites = (props: {
    correspondance: ICorrespondance
    onCorrespondanceChange: (correspondance: ICorrespondance) => void,
}) => {
    const correspondancesService: CorrespondancesService = new CorrespondancesService();
    const [showConfirmationAnnulation, setShowConfirmationAnnulation] = useState<boolean>(false);
    const [showTestConfirmationAnnulation, setShowTestConfirmationAnnulation] = useState<boolean>(false);
    const [inviteSelected, setInviteSelected] = useState<ICorrespondanceInvite>(null);
    const [testSelected, setTestSelected] = useState<ICorrespondanceTest>(null);

    /**
     * Retourne l'identité de l'invité
     * @param {ICorrespondanceInvite} participant
     * @returns {string}
     */
    const getIdentite = (participant: ICorrespondanceInvite) => {
        let name = "";

        if (participant.notaire && participant.notaire.coordonnees) {
            name = `${participant.notaire.prenom} ${participant.notaire.nom}`;
        }

        if (participant.personneExterieure && participant.personneExterieure.coordonnees) {
            name = `${participant.personneExterieure.prenom} ${participant.personneExterieure.nom}`;
        }

        if (participant.email) {
            name = `${participant.prenom} ${participant.nom}`;
        }

        return name;
    };

    /**
     * Annule la demande d'annulation
     */
    const onCancelAnnulation = () => {
        setInviteSelected(null);
        setShowConfirmationAnnulation(false);
    };
    const onCancelTest = () => {
        setTestSelected(null);
        setShowTestConfirmationAnnulation(false);
    };


    const cancelInvite = () => {
        correspondancesService.cancelInvite(inviteSelected.id).then((result) => {
            setInviteSelected(null);
            setTestSelected(null);
            setShowConfirmationAnnulation(false);
            setShowTestConfirmationAnnulation(false);


            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'invitation a bien été supprimée",
                type: "success"
            });

            if (result.datas && result.datas.correspondance) {
                props.onCorrespondanceChange(result.datas.correspondance);
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression.",
                type: "danger"
            });
        });
    };
    const cancelTest = () => {
        correspondancesService.cancelTest(testSelected.id).then((result) => {
            setInviteSelected(null);
            setTestSelected(null);
            setShowConfirmationAnnulation(false);
            setShowTestConfirmationAnnulation(false);
            
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le contact de test a bien été supprimé",
                type: "success"
            });

            if (result.datas && result.datas.correspondance) {
                props.onCorrespondanceChange(result.datas.correspondance);
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression.",
                type: "danger"
            });
        });
    };


    return <>
        <div className="informations-wrapper">
        <div className="columns">
            <div className="column">
                <div className="informations-block" >
                    <p className="label">Groupes</p>
                    {
                        props.correspondance.groupes && props.correspondance.groupes.map((g,gIndex) =>
                             <p className="value" key={gIndex}>{g.nom}</p>
                        )
                    }
                    {
                        !props.correspondance.groupes || (!props.correspondance.groupes.length) &&
                        <p className="value">-</p>
                    }
                </div>
            </div>
            <div className="column">
                <div className="informations-block" >
                    <p className="label">Invité(s) individuel(s)</p>
                    {
                        props.correspondance.invites.map((i,iIndex) =>
                            <p className="value" key={iIndex}>
                                {
                                    (props.correspondance.envoye === "non") &&
                                        <button className="g-button -delete-button icon-remove" onClick={() => {
                                            setInviteSelected(i);
                                            setShowConfirmationAnnulation(true)
                                        }}/>
                                }

                                {getIdentite(i)}
                            </p>
                        )
                    }
                </div>

                {
                    props.correspondance.envoye !== 'oui' &&
                        <div className="informations-block" >
                            <p className="value">
                                <Link to={`${AppConstants.pathCorrespondances}/${props.correspondance.id}/invites-individuel`} className="form-link">Gérer les invités individuels</Link>
                            </p>
                        </div>
                }
            </div>

            <div className="column">
                <div className="informations-block" >
                    <p className="label">Test(s) individuel(s)</p>
                    {
                        props.correspondance.tests.map((i,iIndex) =>
                            <p className="value" key={iIndex}>
                                {
                                    (props.correspondance.envoye === "non") &&
                                        <button className="g-button -delete-button icon-remove" onClick={() => {
                                            setTestSelected(i);
                                            setShowTestConfirmationAnnulation(true)
                                        }}/>
                                }

                                {getIdentite(i)}
                            </p>
                        )
                    }
                </div>

                {
                    props.correspondance.envoye !== 'oui' &&
                    <div className="informations-block" >
                        <p className="value">
                            <Link to={`${AppConstants.pathCorrespondances}/${props.correspondance.id}/tests`} className="form-link">Gérer les tests</Link>
                        </p>
                    </div>
                }
            </div>
        </div>
    </div>

        {
            showConfirmationAnnulation &&
            <ConfirmationComponent onConfirm={()=>cancelInvite()} onCancel={onCancelAnnulation} text="Souhaitez-vous vraiment supprimer cette invitation ?"/>
        }
        {
            showTestConfirmationAnnulation &&
            <ConfirmationComponent onConfirm={()=>cancelTest()} onCancel={onCancelTest} text="Souhaitez-vous vraiment supprimer ce test ?"/>
        }
    </>
};

export default CorrespondanceInvites;
