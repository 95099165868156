import React, {useEffect, useState} from "react";
import DateHelper from "../../Helpers/Date.helper";

const CalendarComponent = (props: {
    date1: string,
    date2: string
}) => {

    const [show2dates,setShow2dates] = useState<boolean>(true);
    const [show2years,setShow2years] = useState<boolean>(true);

    useEffect(()=>{

        if(!props.date2){
            setShow2dates(false);
            setShow2years(false);
        }
        else{
            setShow2dates(!DateHelper.isSameDay(props.date1, props.date2));
            setShow2years(!DateHelper.isSameYear(props.date1, props.date2));

        }

    }, [props.date1,props.date2]);


    return <div className="calendar-block">
        <span className="icon icon-calendar"></span>
        <div className="dates">
            <div className="calendar-date">
                <div className="day">{DateHelper.formatDate(props.date1,"DD")}</div>
                <div className="month">{DateHelper.formatDate(props.date1,"MMM")}</div>
            </div>
            {show2dates && <div className="calendar-date">
                <div className="day">{DateHelper.formatDate(props.date2,"DD")}</div>
                <div className="month">{DateHelper.formatDate(props.date2,"MMM")}</div>
            </div>}
        </div>
        <div className="years">
            <div className="year">{DateHelper.formatDate(props.date1,"YYYY")}</div>
            {show2years && <div className="year">{DateHelper.formatDate(props.date2,"YYYY")}</div>}
        </div>
    </div>

};

export default CalendarComponent;
