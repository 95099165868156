import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import CommonHelper from "src/Helpers/Common.helper";
import DateHelper from "src/Helpers/Date.helper";
import FileHelper from "src/Helpers/File.helper";
import GroupesHelper from "src/Helpers/Groupes.helper";
import {useEffect, useState} from "react";
import {NotairesConstants} from "../../../Constants/NotairesConstants";

type INotaireIdentiteComponentProps = {
    notaire: INotaire,
    isAncienNotaire?: boolean,
}

export default function NotaireIdentiteComponent(props: INotaireIdentiteComponentProps) {
    const notaire: INotaire = props.notaire;
    const [labelsNames, setLabelsNames] = useState<string[]>([]);
    const [labelsFiles, setLabelsFiles] = useState<string[]>([]);

    useEffect(() => {
        let newLabelsNames: string [] = [];
        let newLabelsFiles: string [] = [];
        notaire.labels.forEach( (l) => {
            newLabelsNames.push(l.nom);
            newLabelsFiles.push(l.fichier);
        });
        setLabelsNames(newLabelsNames);
        setLabelsFiles(newLabelsFiles);

    }, [notaire]);

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{notaire.nom}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Sexe</p>
                        <p className="value">{notaire.sexe ? CommonHelper.getFormatedSexe(notaire.sexe): "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date de Naissance</p>
                        <p className="value">{notaire.dateNaissance ? DateHelper.getFormatedDate(notaire.dateNaissance) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date serment initial</p>
                        <p className="value">{notaire.dateSermentInitial ? DateHelper.getFormatedDate(notaire.dateSermentInitial) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date JO (1e nomination)</p>
                        <p className="value">{notaire.dateNominationJournalOfficiel ? DateHelper.getFormatedDate(notaire.dateNominationJournalOfficiel) : '-'}</p>
                    </div>
                    {notaire.dateRetrait &&
                        <div className="informations-block">
                            <p className="label">Date de retrait</p>
                            <p className="value">{DateHelper.getFormatedDate(notaire.dateRetrait)}</p>
                        </div>
                    }
                    <div className="informations-block">
                        <p className="label">Labels</p>
                        {
                            labelsFiles && labelsFiles.map((fichier: string, index: number) =>
                                <figure className="informations-image -certification" key={index}>
                                    <img src={FileHelper.getImageUrlFromSize(fichier,"certification")} alt="" className="image" />
                                </figure>
                            )
                        }
                        {
                            labelsNames && labelsNames.length > 0 &&
                            <>{labelsNames.join(', ')}</>
                        }
                        {
                            (labelsNames.length === 0 && labelsFiles.length === 0) &&
                                <>-</>
                        }
                    </div>
                    <div className="informations-block">
                        <p className="label">Agrément inspection</p>
                        <p className="value">{notaire.agrementInspection ? CommonHelper.getFormatedOuiNon(notaire.agrementInspection) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Groupes</p>
                        <p className="value">{notaire.groupes && notaire.groupes.length ? GroupesHelper.getFormated(notaire.groupes) : "-"}</p>
                    </div>

                    {
                        (props.isAncienNotaire && notaire.type === 'deces') &&
                        <div className="informations-block">
                            <p className="label">Notaire Décédé</p>
                            <p className="value">
                                {/*{notaire.decede ? CommonHelper.getFormatedOuiNon(notaire.decede) : ''}*/}
                                {notaire.dateDeces ? ', le ' + DateHelper.getFormatedDate(notaire.dateDeces) : ''}
                            </p>
                        </div>
                    }

                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Prénom</p>
                        <p className="value">{notaire.prenom}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Type de notaire</p>
                        <p className="value">{notaire.type ? NotairesHelper.getFormattedType(notaire.type) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Lieu de naissance</p>
                        <p className="value">{notaire.lieuNaissance || '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Date du dernier serment</p>
                        <p className="value">{notaire.dateDernierSerment ? DateHelper.getFormatedDate(notaire.dateDernierSerment) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">N° CSN</p>
                        <p className="value">{notaire.CSN || '-'}</p>
                    </div>
                    {notaire.dateHonorariat &&
                        <div className="informations-block">
                            <p className="label">Date honorariat</p>
                            <p className="value">{DateHelper.getFormatedDate(notaire.dateHonorariat)}</p>
                        </div>
                    }
                    <div className="informations-block">
                        <p className="label">Langue(s) parlée(s)</p>
                        <p className="value">{notaire.languesParlees && notaire.languesParlees.length ? NotairesHelper.getFormatedLangues(notaire.languesParlees) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Agrément comptabilité</p>
                        <p className="value">{notaire.agrementComptabilite ? CommonHelper.getFormatedOuiNon(notaire.agrementComptabilite) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">N° Siret</p>
                        <p className="value">{notaire.siret || '-'}</p>
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Fonction(s)</p>
                        <p className="value">{notaire.fonctionsSexedString ? notaire.fonctionsSexedString : '-'}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Signature</p>
                        <div className="value">
                            {notaire.signature &&
                                <figure className="informations-image -resume">
                                    <img src={FileHelper.getImageUrlFromSize(notaire.signature,"signature")} alt="" className="image" />
                                </figure>
                            }
                            {!notaire.signature && <>--</>}
                        </div>
                    </div>
                </div>
            </div>


            <div className="columns">
                <div className="column">

                </div>
            </div>

        </div>
    );
}
