import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {ISignature} from "../Models/Signature.model";

export type ISignatureGetApiResponse = IApiCustomResponse & {
    datas: {
        signature: ISignature
    }
}
export type ISignaturesGetPaginationApi = IApiPagination & {
    data: ISignature[],
}
export type ISignaturesListGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ISignaturesGetPaginationApi
    }
}
export type ISignaturesGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}
export type ISignatureInformationsFormData = {
    nom: string,
    module: string,
    fichier?: string
}
export type ISignatureGetAllApiResponse = IApiCustomResponse & {
    datas: {
        signatures: ISignature[]
    }
}



export class SignaturesService extends ApiService{
    /**
     * Permet la récupération d'une liste paginée des signatures
     *
     * @returns {Promise<ISignaturesListGetApiResponse>}
     */
    public getSignatures(formData: ISignaturesGetFormData): Promise<ISignaturesListGetApiResponse>{
        return this.doApiCall('api/correspondances/signatures', 'GET', formData);
    }


    /**
     * Permet la récupération d'une liste des signatures
     *
     * @returns {Promise<ISignaturesListGetApiResponse>}
     */
    public getAll(): Promise<ISignatureGetAllApiResponse>{
        return this.doApiCall('api/correspondances/signatures/all', 'GET');
    }


    /**
     * Permet la récupération d'une signature à partir de son id
     *
     * @param {string} sid
     * @returns {Promise<>}
     */
    public getSignature(sid: string): Promise<ISignatureGetApiResponse>{
        return this.doApiCall('api/correspondances/signatures/' + sid, 'GET');
    }

    /**
     * Creation d'une signature
     *
     * @param {ISignatureInformationsFormData} formDatas
     * @returns {Promise<ISignatureGetApiResponse>}
     */
    public create(formDatas: ISignatureInformationsFormData): Promise<ISignatureGetApiResponse>{
        return this.doApiCall('api/correspondances/signatures/create', 'POST', formDatas);
    }


    /**
     * Modification d'une signature
     *
     * @param {string} sid
     * @param {ISignatureInformationsFormData} formDatas
     * @returns {Promise<ISignatureGetApiResponse>}
     */
    public update(sid: string, formDatas: ISignatureInformationsFormData): Promise<ISignatureGetApiResponse>{
        return this.doApiCall('api/correspondances/signatures/' + sid + '/update', 'POST', formDatas);
    }


    /**
     * Permet la suppression d'une signature
     *
     * @param {number} sid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(sid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/correspondances/signatures/' + sid + '/delete', 'POST');
    }
}

