import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {
    IApiCustomErrorResponse,
    IApiCustomResponse,
    IApiErrorMessage,
    IApiFieldsErrorMessages,
    IFileDownloadApiResponse
} from "src/Services/Api.service";
import CommonHelper from "src/Helpers/Common.helper";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypesGetApiResponse} from "src/Services/Courriers.service";
import CourriersHelper from "src/Helpers/Courriers.helper";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import UseFileDownload from "src/Hooks/UseFileDownload";
import {IReclamation} from "src/Models/Reclamation.model";
import {IReclamationCourrierFormData, IReclamationGetApiResponse, ReclamationsService} from "src/Services/Reclamations.service";
import NotairesHelper from "../../../Helpers/Notaires.helper";

type IReclamationCourrierFormProps = {
    reclamation?: IReclamation,
    onSuccessSubmit: (response: IReclamationGetApiResponse) => void,
    onFileDownload?: () => void,
}

export default function ReclamationCourrierForm(props: IReclamationCourrierFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const reclamationsService: ReclamationsService = new ReclamationsService();
    const {handleFileDownload} = UseFileDownload();


    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();

        courriersService.getCourriersTypesForModule('reclamations').then((response: ICourriersTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                setCourriersTypes(response.datas.types);
            }
        });
    }, [])




    useEffect(()=> {
        if(!courriersTypes || !courriersTypes.length) return;
        const {reclamation} = props;

        const notairesIDs: string[] = [];
        if(reclamation && reclamation.notaires){
            for( let not of reclamation.notaires){
                notairesIDs.push(not.id.toString());
            }
        }

        let formElementsColumn1: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateCourrier",

                label: "Date du courrier (uniquement si besoin)",
                required: false,
                modificators: "-on-white",

                oldValue: "",
            },
        ];
        if(reclamation.notaires.length > 1){
            formElementsColumn1.push(
                {
                    type: 'selectMultiple',
                    fieldName: "notaireId",

                    label: "Notaire(s) concerné(s)",
                    required: false,
                    options: reclamation && reclamation.notaires ? NotairesHelper.formatListForOptions(reclamation.notaires): [],
                    modificators: "-on-white",

                    oldValues: notairesIDs,
                });
        }
        else{
            formElementsColumn1.push(
                {
                    type: 'hidden',
                    fieldName: "notaireId",

                    label: "",
                    required: false,
                    modificators: "-on-white",

                    oldValue: (reclamation && reclamation.notaires && reclamation.notaires[0]) ? reclamation.notaires[0].id.toString() : '',
                });
            formElementsColumn1.push(
                {
                    type: 'informations',
                    fieldName: "notaireName",

                    label: "Notaire concerné",
                    required: false,
                    modificators: "-on-white",

                    oldValue: (reclamation && reclamation.notaires && reclamation.notaires[0]) ? reclamation.notaires[0].prenom + ' ' + reclamation.notaires[0].nom : '' ,
                });
        }


        formElementsColumn1.push(
            {
                type: 'select',
                fieldName: "type",

                label: "Type de courrier",
                placeholder: "Type de courrier",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: courriersTypes ? CourriersHelper.formatCourriersTypesForOptions(courriersTypes) : []
            },
        );

        for(let type of courriersTypes){
            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenu",

                    label: "Contenu",
                    placeholder: "Contenu",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.modele || "",
                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenuMail",

                    label: "Contenu Email",
                    placeholder: "Contenu Email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.contenuMail || "",
                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
        }

        formElementsColumn1.push(
            {
                type: 'radio',
                fieldName: "afficherEntete",

                label: "Afficher l'entête",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "sauvegarderCourrier",

                label: "Sauvegarder le courrier dans les documents",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            }
        );

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Télécharger",
                modificators: "-primary",
                icon: "icon-download",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onDownload(formData, onActionEnded)}
            },
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false, onActionEnded)}
            },
            {
                label: "Envoyer un test",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, true, onActionEnded)}
            }
        ];
        setFormActions(currentFormActions);

    }, [courriersTypes]);


    /**
     * Gestion du téléchargement du courrier
     *
     * @param {FormComponentFormData} formData
     */
    const onDownload = (formData: FormComponentFormData, onActionEnded?: ()=>void): void => {
        reclamationsService.downloadCourrier(props.reclamation.id, formData as IReclamationCourrierFormData).then((response: IFileDownloadApiResponse) => {
            if(onActionEnded) onActionEnded();
            if(response && response.datas && response.datas.file){
                handleFileDownload(response.datas.file,true);

                if(props.onFileDownload){
                    props.onFileDownload();
                }
            }
        }, (error: IApiCustomErrorResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if(error.datas && error.datas.warningMessage != null){
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: error.datas.warningMessage,
                    type: "warning"
                });
            } else if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'enregistrement du courrier.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: ()=>void): void => {
        const formDataUpdated: IReclamationCourrierFormData = {
            ...formData as IReclamationCourrierFormData,
            isDebug: isDebug || false
        }

        reclamationsService.sendCourrier(props.reclamation.id, formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });
        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de l'envoi du courrier.",
                    type: "danger"
                });
            }
        });
    }


    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
