import {ICtrc} from "../../Models/Ctrc.model";
import DateHelper from "../../Helpers/Date.helper";
import SinistresHelper from "../../Helpers/Sinistres.helper";
import CtrcsHelper from "../../Helpers/Ctrcs.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import FileHelper from "../../Helpers/File.helper";
import React from "react";

type ICertificationInformationsComponentProps = {
    ctrc: ICtrc,
}

export default function CtrcInformationsComponent(props: ICertificationInformationsComponentProps) {
    const ctrc: ICtrc = props.ctrc;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date</p>
                        <p className="value">
                            {ctrc.date ? DateHelper.getFormatedDate(ctrc.date) : "-"}
                            {(ctrc.date && ctrc.heure) ? " " + ctrc.heure : ""}
                        </p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{ctrc.nom || "-"}</p>
                    </div>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Ordre du jour (issu de Documents)</p>
                        <p className="value">
                            {ctrc.documents && ctrc.documents.length > 0 ?
                                ctrc.documents.map(document =>  document.type === "ordre-du-jour" ?
                                    <DocumentLink icon="icon-documents" link={document.fichier ? FileHelper.getFileUrl(document.fichier) : '-'} />
                                    : "-") :
                                "-"}
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Président</p>
                        <p className="value">
                            {ctrc.president ? SinistresHelper.getFormatedNotaires([ctrc.president]) : '-'}<br />
                            {/*<em>(ajouté automatiquement lors de la création)</em>*/}
                        </p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Membres</p>
                        <p className="value">
                            {/*Afficher les membres (avec ou sans président) */}
                            {ctrc.notairesMembresPresencesSansPresident ? CtrcsHelper.getFormatedNotairesMembresPresences(ctrc.notairesMembresPresencesSansPresident) : '-'}<br />
                            {/*<em>(ajouté automatiquement lors de la création)</em>*/}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
