import {IFormationGetApiResponse, FormationsService} from "src/Services/Formations.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IFormation} from "src/Models/Formation.model";
import {useHistory} from "react-router";

export default function useFormation(){
    const formationsService: FormationsService = new FormationsService();
    const history = useHistory();

    /**
     * Récupération d'un formation à partir de son id
     *
     * @param {string} nid
     * @param {(formation: IFormation) => void} callback
     * @param {() => void} callbackError
     */
    const getFormationById = (nid: number, callback:(formation: IFormation) => void, callbackError?: () => void ) => {
        formationsService.getFormation(nid).then((response: IFormationGetApiResponse) => {
            if( response && response.datas.formation){
                if(callback){
                    callback(response.datas.formation);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathFormations);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la formation",type: "danger"});
        });
    };
    /**
     * Récupération d'un formation à partir de son id
     *
     * @param {string} nid
     * @param {(formation: IFormation) => void} callback
     * @param {() => void} callbackError
     */
    const getFormationExternalById = (nid: number, callback:(formation: IFormation) => void, callbackError?: () => void ) => {
        formationsService.getFormationExternal(nid).then((response: IFormationGetApiResponse) => {
            if( response && response.datas.formation){
                if(callback){
                    callback(response.datas.formation);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathFormations);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la formation",type: "danger"});
        });
    };

    return [getFormationById, getFormationExternalById];
};

