import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IReclamation} from "src/Models/Reclamation.model";
import {IReclamationGetApiResponse, IReclamationPlaignantsFormData} from "src/Services/Reclamations.service";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import {AppConstants} from "../../../Constants/AppConstants";


type IReclamationPlaignantsFormComponentProps = {
    reclamation?: IReclamation,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IReclamationPlaignantsFormData) => Promise<IReclamationGetApiResponse>,
    onSuccessSubmit: (response: IReclamationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IReclamationGetApiResponse) => void,
    onCancel: () => void,
}

export default function ReclamationPlaignantsFormComponent(props: IReclamationPlaignantsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    useEffect(() => {
        const reclamation: IReclamation = props.reclamation;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'repeater',
                fieldName: "plaignants",
                label: "Plaignants",
                oldRepeatableValues: reclamation ? reclamation.plaignants as FormComponentFormData: {},
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'hidden',
                                fieldName: "origine",
                                oldValue: reclamation.origine,
                                modificators: "-hidden"
                            },
                            {
                                type: 'select',
                                fieldName: "civilite",

                                label: "Civilite",
                                options: ReclamationsConstants.civilitePlaignantOptions,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                            },
                            {
                                type: 'text',
                                fieldName: "fonction",

                                label: "Fonction",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'date',
                                fieldName: "dateNaissance",

                                label: "Date de naissance",
                                placeholder: "Date de naissance",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                            {
                                type: 'text',
                                fieldName: "lieuNaissance",

                                label: "Lieu de naissance",
                                placeholder: "Lieu de naissance",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'text',
                                fieldName: "prenom",

                                label: "Prénom",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'radio',
                                fieldName: "typePersonne",

                                label: "Type de personne",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                options: ReclamationsConstants.typesPersonnesOptions,

                                oldValue: "",
                            },
                            {
                                type: 'select',
                                fieldName: "nationalite",

                                label: "Nationalité",
                                placeholder: "Nationalité",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                options: AppConstants.nationalitesOptions,
                                oldValue: "",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'text',
                                fieldName: "nom",

                                label: "Nom",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'text',
                                fieldName: "nomClient",

                                label: "Nom du client",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                                condition: {
                                    fieldName: "origine",
                                    value: "avocat",
                                }
                            },
                            {
                                type: 'text',
                                fieldName: "profession",

                                label: "Profession",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                            //Ajout d'un champ caché "inutile", sans celui-ci lorsque l'on switch entre personne morale/physique,
                            //la valeur du champ "structure"/"profession" reste la même (si elle a été renseigné)
                            {
                                type: 'hidden',
                                fieldName: "hideField",

                                label: "hideField",
                                required: false,
                                disabled: true,
                                modificators: "-hide",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "morale",
                                }
                            },
                            {
                                type: 'text',
                                fieldName: "structure",

                                label: "Structure",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "morale",
                                }
                            },
                        ],
                    },
                ],
                required: false,
            },
        ];
        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);


    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IReclamationPlaignantsFormData).then((response: IReclamationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "Les informations de la réclamations ont bien été modifiées." : "La réclamation a bien été créée.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive de la réclamation.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
