import {ICorrespondance} from "src/Models/Correspondance.model";
import React, {useEffect, useState} from "react";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {
    IApiCustomResponse,
    IApiErrorMessage,
    IApiFieldsErrorMessages,
    IFileDownloadApiResponse
} from "src/Services/Api.service";
import CommonHelper from "src/Helpers/Common.helper";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypesGetApiResponse} from "src/Services/Courriers.service";
import {CorrespondancesService, ICorrespondanceCourrierFormData,} from "src/Services/Correspondances.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import UseFileDownload from "src/Hooks/UseFileDownload";

type ICorrespondanceCourrierFormProps = {
    correspondance?: ICorrespondance,
    onActionSuccess?: () => void,
}

export default function CorrespondanceCourrierForm(props: ICorrespondanceCourrierFormProps) {
    const [isSending, setIsSending] = useState<boolean>(false);
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const [pdfPreviewSrc, setPdfPreviewSrc] = useState<string>(null);
    const correspondancesService: CorrespondancesService = new CorrespondancesService();

    const {handleFileDownload} = UseFileDownload();

    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();
        courriersService.getCourriersTypesForModule('correspondances').then((response: ICourriersTypesGetApiResponse) => {
            if (response && response.datas && response.datas.types) {
                setCourriersTypes(response.datas.types);
            }
        });
    }, [])

    useEffect(() => {
        if (!courriersTypes || !courriersTypes.length) return;
        const {correspondance} = props;
        const formElementsColumn1: FormElement[] = [];

        for (let type of courriersTypes) {
            if (type.slug === 'correspondances-' + correspondance.type.replaceAll('_', '-').toLowerCase()) {
                formElementsColumn1.push(
                    {
                        type: 'text',
                        fieldName: "subjectMail",

                        label: "Sujet du mail",
                        placeholder: "Sujet de l'email",
                        required: false,
                        modificators: "-wysiwyg -on-white",

                        oldValue: correspondance.subjectMail ? correspondance.subjectMail : (type.sujet ? type.sujet : ""),

                        condition: {
                            fieldName: "type",
                            value: type.id.toString()
                        }
                    }
                );

                if (correspondance.inclureCourrier != "non") {
                    formElementsColumn1.push(
                        {
                            type: 'wysiwyg',
                            fieldName: "contenu",

                            label: "Contenu",
                            placeholder: "Contenu",
                            required: false,
                            modificators: "-wysiwyg -on-white",

                            oldValue: correspondance.messageComplementaire ? correspondance.messageComplementaire : (type.modele ? type.modele : ""),
                        });
                }


                //Demande du client
                //Le lien avec le token doit permettre une préview de la page de réponse
                //On ajoute donc l'id de la correspondance avant le token pour l'affichage
                //Il doit être supprimer à la soumission
                let contenuMail: string = correspondance.messageComplementaireEmail ? correspondance.messageComplementaireEmail : (type.contenuMail ? type.contenuMail : "");
                contenuMail = contenuMail.replaceAll('[correspondance_lien]', correspondance.id + "/[correspondance_lien]");


                formElementsColumn1.push(
                    {
                        type: 'wysiwyg',
                        fieldName: "contenuMail",

                        label: "Contenu Email",
                        placeholder: "Contenu Email",
                        required: false,
                        modificators: "-wysiwyg -on-white",

                        oldValue: contenuMail,

                        condition: {
                            fieldName: "type",
                            value: type.id.toString()
                        }
                    }
                );

                formElementsColumn1.push(
                    {
                        type: 'hidden',
                        fieldName: "type",
                        required: true,
                        oldValue: type.id.toString() ?? "",
                    },
                );
            }
        }

        if (correspondance.envoye !== 'oui') {
            formElementsColumn1.push(
                {
                    type: 'radio',
                    fieldName: "afficherEntete",

                    label: "Afficher l'entête",
                    required: true,
                    modificators: "-on-white",
                    oldValue: correspondance.afficherEntete ? correspondance.afficherEntete : "non",
                    options: CommonHelper.generateOuiNonRadiosOptions('non'),
                }
            );
        }

        const formElementsColumn2: FormElement[] = [];

        formElementsColumn2.push({
            type: 'previewPDF',
            oldValue: pdfPreviewSrc,
            label: "Prévisualisation",
            icon: "icon-voir",
            action: () => setPdfPreviewSrc(null),
        });

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-size-2 -size-2-w-margin",
                elements: formElementsColumn1
            },
            {
                modificators: "-size-2 -pos-sticky",
                elements: formElementsColumn2
            },
        ];

        setFormColumns(currentFormColumns);


        if (correspondance.envoye !== 'oui') {
            //Préparation des actions du formulaires
            let currentFormActions: FormActions[] = [];

            currentFormActions.push({
                label: "Sauvegarder",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {
                    onSave(formData, false, onActionEnded)
                }
            });


            //if(correspondance.signatureNom && correspondance.signaturePrenom) {
            if ((correspondance.invites && correspondance.invites.length) || (correspondance.groupes && correspondance.groupes.length)) {

                //Ajout du bouton de prévisualisation au début
                currentFormActions.unshift({
                    label: "Prévisualiser",
                    modificators: "-primary",
                    icon: "icon-voir",
                    hasLoading: true,
                    onAction: (formData, onActionEnded) => {
                        onDownload(formData, false, onActionEnded, true);
                    }
                });

                currentFormActions.push({
                    label: "Télécharger",
                    modificators: "-primary",
                    icon: "icon-download",
                    hasLoading: true,
                    onAction: (formData, onActionEnded) => {
                        onDownload(formData, false, onActionEnded)
                    }
                });

                currentFormActions.push({
                    label: "Envoyer",
                    modificators: "-primary",
                    icon: "icon-paper-plane",
                    hasLoading: true,
                    onAction: (formData, onActionEnded) => {
                        onSend(formData, false, onActionEnded);
                    }
                });
            }
            //}

            if (correspondance.tests && correspondance.tests.length) {
                currentFormActions.push({
                    label: "Télécharger un test",
                    modificators: "-primary",
                    icon: "icon-download",
                    hasLoading: true,
                    onAction: (formData, onActionEnded) => {
                        onDownload(formData, true, onActionEnded)
                    }
                });
                currentFormActions.push({
                    label: "Envoyer un test",
                    modificators: "-primary",
                    icon: "icon-paper-plane",
                    hasLoading: true,
                    onAction: (formData, onActionEnded) => {
                        onSend(formData, true, onActionEnded)
                    }
                });
            }

            setFormActions(currentFormActions);
        }

    }, [courriersTypes, pdfPreviewSrc]);//Re-générer le formulaire quand la src/url de la preview est changée


    /**
     * Gestion du téléchargement du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     * @param {boolean} isPreview
     * @param {() => void} onActionEnded
     */
    const onDownload = (formData: FormComponentFormData, isDebug: boolean, onActionEnded?: () => void, isPreview?: boolean): void => {
        setIsSending(true);

        setPdfPreviewSrc(null);

        const formDataUpdated: ICorrespondanceCourrierFormData = {
            ...formData as ICorrespondanceCourrierFormData,
            isDebug: isDebug ?? false,
            isPreview: isPreview ?? false,
        }


        //On remet le token du lien comme c'était avant
        if (formDataUpdated.contenuMail) {
            formDataUpdated.contenuMail = formData.contenuMail.replaceAll(props.correspondance.id + "/[correspondance_lien]", '[correspondance_lien]');
        }


        correspondancesService.downloadCourrier(props.correspondance.id, formDataUpdated).then((response: IFileDownloadApiResponse) => {
            setIsSending(false);

            if (onActionEnded) onActionEnded();

            if (response && response.datas && response.datas.file) {
                if (isPreview) {
                    setPdfPreviewSrc(response.datas.file);
                } else {
                    handleFileDownload(response.datas.file, true);
                }
            }

            if (props.onActionSuccess) {
                props.onActionSuccess();
            }
        }, (error: IApiCustomResponse) => {
            setIsSending(false);

            if (onActionEnded) onActionEnded();

            if (error.messages) {
                setErrorsMessage(error.messages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de la sauvegarde du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     * @param {() => void} onActionEnded
     */
    const onSave = (formData: FormComponentFormData, isDebug: boolean, onActionEnded?: () => void): void => {
        setIsSending(true);

        setPdfPreviewSrc(null);

        const formDataUpdated: ICorrespondanceCourrierFormData = {
            ...formData as ICorrespondanceCourrierFormData,
            isDebug: isDebug ?? false
        }

        //On remet le token du lien comme c'était avant
        if (formDataUpdated.contenuMail) {
            formDataUpdated.contenuMail = formData.contenuMail.replaceAll(props.correspondance.id + "/[correspondance_lien]", '[correspondance_lien]');
        }


        correspondancesService.saveCorrespondanceCourrier(props.correspondance.id, formDataUpdated).then((response: IFileDownloadApiResponse) => {
            setIsSending(false);

            if (onActionEnded) onActionEnded();

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le courrier a bien été enregistré.",
                type: "success"
            });

            if (props.onActionSuccess) {
                props.onActionSuccess();
            }
        }, (error: IApiCustomResponse) => {
            setIsSending(false);

            if (onActionEnded) onActionEnded();

            if (error.messages) {
                setErrorsMessage(error.messages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     * @param {() => void} onActionEnded
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: () => void): void => {
        setIsSending(true);

        setPdfPreviewSrc(null);

        const formDataUpdated: ICorrespondanceCourrierFormData = {
            ...formData as ICorrespondanceCourrierFormData,
            isDebug: isDebug ?? false
        }


        //On remet le token du lien comme c'était avant
        if (formDataUpdated.contenuMail) {
            formDataUpdated.contenuMail = formData.contenuMail.replaceAll(props.correspondance.id + "/[correspondance_lien]", '[correspondance_lien]');
        }


        correspondancesService.sendCourrier(props.correspondance.id, formDataUpdated).then((response: IApiCustomResponse) => {
            setIsSending(false);

            if (onActionEnded) onActionEnded();
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: isDebug ? "Le courrier de test a bien été envoyé." : "Le courrier a bien été envoyé.",
                type: "success"
            });

            if (props.onActionSuccess) {
                props.onActionSuccess();
            }
        }, (error: IApiCustomResponse) => {
            setIsSending(false);


            if (onActionEnded) onActionEnded();
            if (error.messages) {
                setErrorsMessage(error.messages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: isDebug ? "Une erreur est survenue lors de l'envoi du courrier de test." : "ne erreur est survenue lors de l'envoi du courrier.",
                    type: "danger"
                });
            }
        });
    }


    return (
        <div className="form-wrapper -edit">

            {/*
            {
                (!props.correspondance.signatureNom || !props.correspondance.signaturePrenom) &&
                <p className="mention -bigger -warning">Attention, l'envoi est désactivé car la signature est incomplète.</p>
            }
*/}

            <FormCreatorComponent
                formColumns={formColumns}
                formActions={!isSending ? formActions : null}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
