import React, {useEffect, useState} from "react";
import {IFilInformationsAbonne} from "../../Models/FilInformations.model";
import {
    FilInformationsService,
    IFilInformationsAbonneFormData,
    IFilInformationsGetFormData
} from "../../Services/FilInformations.service";
import useListFilters, {IFilterData} from "../../Hooks/FiltersHandler";
import {AppConstants} from "../../Constants/AppConstants";
import {useLocation} from "react-router";
import {IApiPaginationLink} from "../../Services/Api.service";
import PaginationComponent from "../Pagination/Pagination.component";
import TableHeaderButtonComponent from "../Lists/TableHeaderButton.component";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import AccordionSimpleComponent from "../Accordions/AccordionSimple.component";
import FilInformationsAbonneFormComponent from "../Forms/FilInformations/FilInformationsAbonneForm.component";
import SelectBrutFieldComponent from "../Fields/SelectBrut.field.component";
import {FilInformationsConstants} from "../../Constants/FilInformationsConstants";

const AbonnesListComponent = (props:{
    keywordAbonnes?: string,
}) => {
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathFilInformations);

    const filInfosService: FilInformationsService = new FilInformationsService();
    const [abonnes, setAbonnes] = useState<IFilInformationsAbonne[]>([]);
    const [abonnesWithErrors, setAbonnesWithErrors] = useState<IFilInformationsAbonne[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [abonneToDelete, setAbonneToDelete] = useState<string>(null);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const location = useLocation();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);


    useEffect( () => {
        updateFilters({...filters, keyword:props.keywordAbonnes});
    }, [props.keywordAbonnes]);


    useEffect(()=>{
        getList();
    },[filters]);

    const onAbonneSubmit = () => {
        getList();
    };

    const getList = (): void => {
        if(!filters) return null;
        filInfosService.getFilInformationsAbonnes(filters as IFilInformationsGetFormData).then((response)=>{
            if (response && response.datas.pagination) {
                setAbonnes(response.datas.pagination.data);
                // Pagination
                setPaginationLinks(response.datas.pagination.links);
            }

            //Récupération des abonnés invalid
            if( response && response.datas.abonnesWithErrors ){
                setAbonnesWithErrors(response.datas.abonnesWithErrors);
            }
        });
    };

    const onDelete = () => {
        filInfosService.deleteAbonne(abonneToDelete).then(()=>{
            setAbonneToDelete(null);
            setShowConfirmation(false);
            getList();
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'abonné.e a bien été supprimé.e",
                type: "success"
            });
        },()=>{
            setAbonneToDelete(null);
            setShowConfirmation(false);
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue",
                type: "danger"
            });
        });
    };

    const onCancel = () => {
        setShowConfirmation(false);
    };

    const onChangeabonneEtat = (aid: string, etat: string) => {
        filInfosService.abonneEtat(aid,etat).then(()=>{
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'état de l'abonné a bien été modifié",
                type: "success"
            });
        },()=>{
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue",
                type: "danger"
            });
        });
    };

    return <>
        <AccordionSimpleComponent title="Ajouter un abonné" icon={"icon-personne-exterieure-add"}>
            <FilInformationsAbonneFormComponent  onCancel={()=>null} onSuccessSubmit={onAbonneSubmit} submitMethod={(formData: IFilInformationsAbonneFormData)=>filInfosService.abonne(formData)}/>
        </AccordionSimpleComponent>

        {
            abonnesWithErrors.length > 0 &&
            <AccordionSimpleComponent title="Emails invalides" icon={"icon-close"}>
                    <table className={`list-table`}>
                        <thead className={"headers"}>
                            <tr>
                                <th className="th -small"><span className="text">Email</span></th>
                                <th className="th -small"><span className="text">Actions</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {abonnesWithErrors.map((abonne,index)=><tr key={index} className="tr">
                                <td className="td -small -error">{abonne.email}</td>
                                <td className="td -small" width="40">
                                    <button className="g-button -primary" onClick={()=>{setShowConfirmation(true);setAbonneToDelete(abonne.id);}}><span className="icon icon-remove"></span></button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            </AccordionSimpleComponent>
        }

        {
            abonnes.length > 0 && <>

	            <table className={`list-table`}>
		            <thead className={"headers"}>
		            <tr>
			            <th className="th">
				            <TableHeaderButtonComponent
					            direction={filters.orderType as string || AppConstants.orderType.asc}
					            value="email" selectedValue={filters.orderBy as string || ""}
					            label="Email" click={(value: string) => setOrder(value)}/>
			            </th>
			            <th className="th">
				            <TableHeaderButtonComponent
					            direction={filters.orderType as string || AppConstants.orderType.asc}
					            value="etat" selectedValue={filters.orderBy as string || ""}
					            label="État" click={(value: string) => setOrder(value)}/>
			            </th>
			            <th className="th"><span className="link">Actions</span></th>
		            </tr>
		            </thead>
		            <tbody>
                    {abonnes.map((abonne,index)=><tr key={index} className="tr">
                        <td className="td">{abonne.email}</td>
                        <td className="td" width="200">
                            <SelectBrutFieldComponent modificators={'-on-white'} hideEmpty={true} disabled={abonne.etat === 'error'} onChange={(value)=>onChangeabonneEtat(abonne.id,value)} options={FilInformationsConstants.abonnesStatesOptions} oldValue={abonne.etat} />
                        </td>
                        <td className="td" width="40">
                            <button className="g-button -primary" onClick={()=>{setShowConfirmation(true);setAbonneToDelete(abonne.id);}}><span className="icon icon-remove"></span></button>
                        </td>
                    </tr>)}
		            </tbody>
	            </table>

	            <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                {showConfirmation && <ConfirmationComponent text="Supprimer cet abonné.e ?" onConfirm={onDelete} onCancel={onCancel}/>}
            </>
        }
    </>;
};

export default AbonnesListComponent;
