import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type DifferendsExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
}

export default function DifferendsExportComponent(props: DifferendsExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Différends"}
                         icon="icon-differends"
             subTitle={"Export des différends"}
             onClose={() => props.setShowExport()}
             modificators={`${!props.showExport ? '-hide' : ''}`}
             exportUrl= {`api${AppConstants.pathDifferends}/export`}
             filters={props.filters || null}

             columns={[
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-informations',
                             fieldName: "informations",
                             label: "Informations",
                             options: [
                                 {value: 'dateReception', label: 'Date de réception'},
                                 {value: 'plaignant', label: 'Notaire plaignant'},
                                 {value: 'misEnCause', label: 'Notaire mis en cause'},
                                 {value: 'etat', label: 'Etat'},
                                 {value: 'reference', label: 'Référence'},
                                 {value: 'objet', label: 'Objet'},
                                 {value: 'dateModification', label: 'Date de modification'},
                                 {value: 'dateConvocationBureau', label: 'Date de convocation devant le bureau'},
                                 {value: 'note', label: 'Note'},
                             ]
                         },
                     ]
                 }
             ]}
        />
    )
}
