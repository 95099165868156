import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants, ICreationSteps} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {
    IOfficeCommentairesFormData,
    IOfficeDocumentsVefaFormData,
    IOfficeInformationFormData,
    OfficesService
} from "src/Services/Offices.service";
import {IOffice} from "src/Models/Office.model";
import useOffice from "src/Hooks/UseOffice";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import OfficeIdentiteComponent from "src/Components/Annuaire/Offices/OfficeIdentite.component";
import OfficeInformationsFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeInformationsForm.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import {IAdresse} from "src/Models/Adresse.model";
import MapHelper from "src/Helpers/Map.helper";
import AsideOfficeComponent from "src/Components/Aside/AsideOffice.component";
import {CommonsService, IAdressesFormData, ICoordonneesFormData} from "src/Services/Commons.service";
import AdressesFormComponent from "src/Components/Forms/Commons/AdressesForm.component";
import AdressesInfosComponent from "src/Components/Annuaire/Commons/Adresses/AdressesInfos.component";
import CoordonneesFormComponent from "src/Components/Forms/Commons/CoordonneesForm.component";
import CoordonneesInfosComponent from "src/Components/Annuaire/Commons/Coordonnees/CoordonneesInfos.component";
import CommentairesComponent from "src/Components/Annuaire/Commons/Commentaires/Commentaires.component";
import OfficeCommentairesFormComponent from "src/Components/Forms/Annuaire/Offices/OfficeCommentairesForm.component";
import OfficeDocuments from "src/Components/Annuaire/Offices/OfficeDocuments.component";
import OfficeDocumentsVefaForm from "src/Components/Forms/Annuaire/Offices/OfficeDocumentsVefaForm.component";


type AnnexeFicheScreenProps = {
    oid: string
}

export default function AnnexeFicheScreen(props: AnnexeFicheScreenProps){
    const identiteAccordionRef = useRef(null);
    const adressesAccordionRef = useRef(null);
    const coordonneesAccordionRef = useRef(null);
    const commentaireAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);
    const informationsComplementairesAccordionRef = useRef(null);

    const [office, setOffice] = useState<IOffice>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [creationSteps, setCreationSteps] = useState<ICreationSteps[]>([]);
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const officesService: OfficesService = new OfficesService();
    const commonsService: CommonsService = new CommonsService();
    const history = useHistory();
    const [getOfficeById] = useOffice();
    const [formOpened, setFormOpened] = useState<boolean>(false);



    useEffect(() => {
        if(!office) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Annexes", link: AppConstants.pathAnnexes},
            {text: office.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

        setCreationSteps( OfficesConstants.creationStepsAnnexe);

    }, [office, loaded])



    useEffect(() => {
        if( !props.oid ){
            history.push(AppConstants.pathAnnexes);
        }

        getOffice();

    }, [props.oid])


    /**
     * Récupération de l'office selon l'oid
     */
    const getOffice = (): void => {
        getOfficeById(props.oid, (office: IOffice) => {
            setOffice(office);

            if(office.type != "annexe"){
                history.push(AppConstants.pathOffices + '/' + office.id);
            }

            const markers: MapMarker[] = [];
            if (office.adressePrincipale && office.adressePrincipale.latitude) {
                const adressePrincipale: IAdresse = office.adressePrincipale;
                const markerText: string = MapHelper.createMapMarkerText(`${AppConstants.pathOffices}/${office.id}`, office.nom, adressePrincipale);
                const marker: MapMarker = MapHelper.createMapMarker(markerText, adressePrincipale);
                markers.push(marker);
            }
            setMarkers(markers);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getOffice();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     */
    const closeForms = (isCancel?: boolean): void => {
        if((identiteAccordionRef && identiteAccordionRef.current) ){
            identiteAccordionRef.current.hideForm(isCancel);
        }
        if((adressesAccordionRef && adressesAccordionRef.current) ){
            adressesAccordionRef.current.hideForm(isCancel);
        }
        if((coordonneesAccordionRef && coordonneesAccordionRef.current) ){
            coordonneesAccordionRef.current.hideForm(isCancel);
        }
        if((commentaireAccordionRef && commentaireAccordionRef.current) ){
            commentaireAccordionRef.current.hideForm(isCancel);
        }
        if((documentsAccordionRef && documentsAccordionRef.current) ){
            documentsAccordionRef.current.hideForm(isCancel);
        }
        if((informationsComplementairesAccordionRef && informationsComplementairesAccordionRef.current) ){
            informationsComplementairesAccordionRef.current.hideForm(isCancel);
        }
    }


    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={office.nom} icon="icon-annexe" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={identiteAccordionRef}
                                    title="Identité"
                                    icon="icon-building"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeInformationsFormComponent office={office} submitMethod={(formData: IOfficeInformationFormData)=>officesService.updateInformations(office.id, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<OfficeIdentiteComponent office={office} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={adressesAccordionRef}
                                    title="Adresse"
                                    icon="icon-coordonnees"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<AdressesFormComponent adresses={office.adresses}
                                                                 relationId={office.id}
                                                                 relationName="annexe"
                                                                 isForOffice={false}
                                                                 submitMethod={(formData: IAdressesFormData) => commonsService.syncAdresses(formData)}
                                                                 onSuccessSubmit={onSuccessSubmitForm}
                                                                 onCancel={onCancelForm}/>}
                                    infos={<AdressesInfosComponent adresses={office.adresses || null} isForOffice={false}/>}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={coordonneesAccordionRef}
                                    title="Coordonnées"
                                    icon="icon-coordonnees-adresses"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<CoordonneesFormComponent coordonnees={office.coordonnees}
                                                                    relationId={office.id} relationName="office"
                                                                    submitMethod={(formData: ICoordonneesFormData) => commonsService.syncCoordonnees(formData)}
                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                    onCancel={onCancelForm}/>}
                                    infos={<CoordonneesInfosComponent coordonnees={office.coordonnees || null} relationName="office" />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={commentaireAccordionRef}
                                    title="Commentaires"
                                    icon="icon-commentaires"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeCommentairesFormComponent office={office} submitMethod={(formData:IOfficeCommentairesFormData)=>officesService.updateCommentaire(office.id, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<CommentairesComponent commentaires={office.commentaires} />}
                                />

                                <AsideOfficeComponent type="fiche" office={office} isPrincipales={false} />
                            </div>
                        </TabPanelComponent>


                        { /* ONGLET MAP */}
                        <TabPanelComponent label="Carte" icon="icon-map">
                            <div className="fiche-content -with-map  border-footer">
                                <div className="map-wrapper">
                                    <MapComponent markers={markers} key={Math.random()}/>
                                </div>

                                <AsideOfficeComponent type="ficheCarte" modificators="-on-map" office={office} isPrincipales={false} />
                            </div>
                        </TabPanelComponent>


                        { /* ONGLET VEFA */}
                        <TabPanelComponent label="Vefa" icon="icon-vefa">
                            <div className="fiche-content -right-gutter  border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="office"
                                    contentId={office.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<OfficeDocumentsVefaForm office={office} submitMethod={(formData: IOfficeDocumentsVefaFormData)=>officesService.updateDocumentsVefa(office.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<OfficeDocuments documents={office.documentsVefa}  />}
                                />

                                <AsideOfficeComponent type="ficheVefa" office={office} isPrincipales={false} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>

                </LayoutComponent>
            }
        </>
    )
}
