import React, {useContext} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {Redirect, Route, RouteProps} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IUserModule} from "src/Models/User.model";
import {UserConstants} from "src/Constants/UsersConstants";


type AuthRouteProps = RouteProps & {
    module: string
}


const AuthModuleCheckRoute = (authRouteProps: AuthRouteProps) => {
    // On récupère uniquement les propriétés de props qu'on souhaite. Le reste est stocké dans rest et est envoyé tel quel au composant route
    // component: Component ici n'est pas un typage mais une assignation de la propriété component à la variable "Component"
    const {component: Component, ...rest} = authRouteProps;
    const {isAuthenticated, authUser} = useContext(UserContext) as IUserContext;


    // Utilisateur n'ayant pas les droits nécessaires
    if (!isAuthenticated || !authUser) {
        return <Redirect push to={AppConstants.pathLogin} />
    }

    //On check le role de l'utilsateur
    //La vérification du module s'effectue uniquement pour les contributeurs
    if( authUser.role === UserConstants.roles.contributor) {
        //On va chercher le module souhaité dans la liste des modules de l'utilisateur
        const moduleData: IUserModule = authUser.modules ? authUser.modules.find((m: IUserModule) => m.slug === authRouteProps.module) : null;

        if (!moduleData || !moduleData.active) {
            return <Redirect push to={AppConstants.path403}/>
        }
    }

    // Droits suffisants on retourne la route
    return <Route {...rest} render={(props) => <Component {...props} />}/>;
};

export default AuthModuleCheckRoute;
