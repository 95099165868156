import React from "react";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import {IFilInformations} from "src/Models/FilInformations.model";
import CommentairesComponent from "../Annuaire/Commons/Commentaires/Commentaires.component";
import DateHelper from "../../Helpers/Date.helper";


const FilInformationsPrestations = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.prestations.map((prestation, prestationIndex) =>
                    <div className="columns" key={prestationIndex}>
                        <p className="title">{prestation.nom}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Fonction</p>
                                <div className="value">
                                    {
                                        prestation.fonction &&
                                        <CommentairesComponent commentaires={prestation.fonction} modificators={"-full"}/>
                                    }
                                    {
                                        !prestation.fonction &&
                                        <>-</>
                                    }
                                </div>
                            </div>

                            <div className="informations-block">
                                <p className="label">Date</p>
                                <div className="value">
                                    {prestation.date ? DateHelper.getFormatedDate(prestation.date) : ""}
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Texte</p>
                                <div className="value">
                                    {
                                        prestation.text &&
                                        <CommentairesComponent commentaires={prestation.text} modificators={"-full"}/>
                                    }
                                    {
                                        !prestation.text &&
                                        <>-</>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {props.filInformations.prestations.length === 0 && <p>-</p>}
        </div>
    )
};

export default FilInformationsPrestations;
