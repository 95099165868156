import React from "react";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import {IFilInformations} from "src/Models/FilInformations.model";


const FilInformationsArretDeliberationComponent = (props: {
    filInformations: IFilInformations
}) => {

    return (
        <div className="informations-wrapper">
            {
                props.filInformations.arretes && props.filInformations.arretes.map((arrete, arreteIndex) =>
                    <div className="columns" key={arreteIndex}>
                        <p className="title">{arrete.titre}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Document</p>
                                <div className="value">
                                    <DocumentLink icon="icon-documents" link={FileHelper.getFileUrl(arrete.document)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            { (!props.filInformations.arretes || props.filInformations.arretes.length === 0) && <p>-</p>}
        </div>
    )
};

export default FilInformationsArretDeliberationComponent;
