import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type CorrespondancesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,

}
export default function CorrespondancesExportComponent(props: CorrespondancesExportComponentProps) {
    if (!props.showExport) return null;

    return (
        <ExportComponent title={"Correspondances"}
                         icon="icon-correspondances"
             subTitle={"Export des courriers"}
             onClose={() => props.setShowExport()}
             modificators={`${!props.showExport ? '-hide' : ''}`}
             exportUrl= {`api${AppConstants.pathCorrespondances}/export`}
             filters={props.filters || null}

             columns={[
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-informations',
                             fieldName: "informations",
                             label: "Informations",
                             options: [
                                 {value: 'date', label: 'Date'},
                                 {value: 'titre', label: 'Titre'},
                                 {value: 'envoye', label: 'Envoyé'},
                                 {value: 'type', label: 'Type'},
                                 {value: 'dateLimiteReponse', label: 'Date limite de réponse'},
                                 {value: 'lieu', label: 'Lieu'},
                                 {value: 'adresse', label: 'Adresse'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-signature',
                             fieldName: "signature",
                             label: "Signature",
                             options: [
                                 {value: 'signatureNom', label: 'Signature - Nom'},
                                 {value: 'signaturePrenom', label: 'Signature - Prénom'},
                                 {value: 'signatureFonction', label: 'Signature - Fonction'},
                             ]
                         },
                     ]
                 },
                 {
                     elements: [
                         {
                             type: 'checkboxGroup',
                             icon: 'icon-precisions',
                             fieldName: "precisions",
                             label: "Précisions",
                             options: [
                                 {value: 'inclureCourrier', label: 'Inclure le courrier'},
                                 {value: 'reunion', label: 'Réunion'},
                                 {value: 'presence', label: 'Présence'},
                                 {value: 'collaborateur', label: 'Collaborateur'},
                                 {value: 'transport', label: 'Transport'},
                                 {value: 'transportDetail', label: 'Détail du transport'},
                                 {value: 'repas', label: 'Repas'},
                                 {value: 'repasNom', label: 'Nom du repas'},
                                 {value: 'repasLieu', label: 'Lieu du repas'},
                                 {value: 'repasHeure', label: 'Heure du repas'},
                                 {value: 'cocktail', label: 'Cocktail'},
                                 {value: 'cocktailNom', label: 'Nom du cocktail'},
                                 {value: 'cocktailLieu', label: 'Lieu du cocktail'},
                                 {value: 'cocktailHeure', label: 'Heure du cocktail'},
                                 {value: 'hebergement', label: 'Hébergement'},
                                 {value: 'hebergementNom', label: 'Nom de l\'hébergement'},
                                 {value: 'hebergementLieu', label: 'Lieu de l\'hébergement'},
                             ]
                         },
                     ]
                 },
             ]}
        />
    )
}
