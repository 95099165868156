import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IReclamation} from "src/Models/Reclamation.model";
import {
    IReclamationCreationFormData,
    IReclamationGetApiResponse,
    IReclamationInformationsFormData
} from "src/Services/Reclamations.service";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import {INotaire} from "src/Models/Notaire.model";
import {NotairesService} from "src/Services/Notaires.service";
import DateHelper from "src/Helpers/Date.helper";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {NotairesConstants} from "../../../Constants/NotairesConstants";
import {AppConstants} from "../../../Constants/AppConstants";


type IReclamationCreationFormComponentProps = {
    reclamation?: IReclamation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IReclamationCreationFormData) => Promise<IReclamationGetApiResponse>,
    onSuccessSubmit: (response: IReclamationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IReclamationGetApiResponse) => void,
    onCancel: () => void,
}

export default function ReclamationCreationFormComponent(props: IReclamationCreationFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response) => {
            if (response && response.datas && response.datas.notaires) {
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });
    }, []);

    useEffect(() => {
        const reclamation: IReclamation = props.reclamation;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateReception",

                label: "Date de réception",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.dateReception ? DateHelper.getFormatedDateForInput(reclamation.dateReception) : "",
            },
            {
                type: "selectMultiple",
                fieldName: "notairesIds",
                label: "Notaire.s concerné.s",
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValues: reclamation && reclamation.notaires ? NotairesHelper.getIdsFromList(reclamation.notaires) : [],
                options: notaires ? NotairesHelper.formatListForOptions(notaires, null, NotairesConstants.typesAnciensLight) : [],
            },
            {
                type: "select",
                fieldName: "objet",

                label: "Objet",
                hideSearchOnMultiple: true,
                required: true,

                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.objet ? reclamation.objet : "",
                options: ReclamationsConstants.objetOptions,
            },
            {
                type: "select",
                fieldName: "origine",

                label: "Origine",
                hideSearchOnMultiple: true,
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.origine ? reclamation.origine : "",
                options: ReclamationsConstants.origineOptions,
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'repeater',
                fieldName: "plaignants",
                label: "Plaignants",
                oldRepeatableValues: reclamation ? reclamation.plaignants as FormComponentFormData: {},
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'select',
                                fieldName: "civilite",

                                label: "Civilite",
                                options: ReclamationsConstants.civilitePlaignantOptions,
                                modificators: props.isOnCreationStep ? "-on-white -sm-min-width" : '-sm-min-width',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                            },
                            {
                                type: 'text',
                                fieldName: "fonction",

                                label: "Fonction",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'date',
                                fieldName: "dateNaissance",

                                label: "Date de naissance",
                                placeholder: "Date de naissance",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                            {
                                type: 'text',
                                fieldName: "lieuNaissance",

                                label: "Lieu de naissance",
                                placeholder: "Lieu de naissance",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'text',
                                fieldName: "prenom",

                                label: "Prénom",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'radio',
                                fieldName: "typePersonne",

                                label: "Type de personne",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : "",
                                options: ReclamationsConstants.typesPersonnesOptions,

                                oldValue: "",
                            },
                            {
                                type: 'select',
                                fieldName: "nationalite",

                                label: "Nationalité",
                                placeholder: "Nationalité",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                options: AppConstants.nationalitesOptions,
                                oldValue: "",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'text',
                                fieldName: "nom",

                                label: "Nom",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'text',
                                fieldName: "nomClient",

                                label: "Nom du client",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                            },
                            {
                                type: 'text',
                                fieldName: "profession",

                                label: "Profession",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "physique",
                                }
                            },
                            {
                                type: 'text',
                                fieldName: "structure",

                                label: "Structure",
                                required: false,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",

                                condition: {
                                    fieldName: "typePersonne",
                                    value: "morale",
                                }
                            },
                        ],
                    }
                ],
                required: false,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2,
                modificators:"-full",
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Annuler",
                modificators: "-is-link -white",
                onAction: onCancel
            },
            {
                label: "Enregistrer",
                modificators: "-secondary-border-only",
                onAction: (formData) => {
                    onSubmit(formData, false)
                }
            },
        ];
        setFormActions(currentFormActions);


    }, [notaires]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IReclamationCreationFormData).then((response: IReclamationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "Les informations de la réclamation ont bien été modifiées." : "La réclamation a bien été créée.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive de la réclamation.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                notairesLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }
        </div>
    )

}
