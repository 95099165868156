import {IHistorique} from "src/Models/Historique.model";
import {INotaire} from "../../../Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import DateHelper from "src/Helpers/Date.helper";
import {AppConstants} from "../../../Constants/AppConstants";
import {Link} from "react-router-dom";

type INotaireHistoriqueComponentProps = {
    notaire: INotaire,
    historiques: IHistorique[],
}

export default function NotaireHistoriqueComponent(props: INotaireHistoriqueComponentProps) {
    const historiques: IHistorique[] = props.historiques;
    const notaire: INotaire = props.notaire;

    return (

        <div className="informations-wrapper">
            

            {
                historiques && historiques.map((historique: IHistorique, index: number) =>
                    <div className="columns" key={index}>
                        <p className="title">{historique.office ? <Link to={`${AppConstants.pathOffices}/${historique.office.id}`} target="_blank" rel="noopener noreferrer">{historique.office.nom}</Link> : 'Historique'}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{historique.type ? NotairesHelper.getFormattedType(historique.type) : "-"}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date d'arrivée</p>
                                <p className="value">{historique.dateArrivee ? DateHelper.getFormatedDate(historique.dateArrivee) : "-"}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date de départ</p>
                                <p className="value">{historique.dateDepart ? DateHelper.getFormatedDate(historique.dateDepart) : "-"}</p>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                notaire.ancienHonoraire && notaire.type === 'deces' &&
                <div className="columns">
                    <p className="title">Honorariat</p>

                    <div className="column">
                        <div className="informations-block">
                            <p className="label">Date d'honorariat</p>
                            <p className="value">{ notaire.dateHonorariat ? DateHelper.getFormatedDate(notaire.dateHonorariat) : '-'}</p>
                        </div>
                    </div>
                </div>
            }
            {
                (!historiques || (historiques.length === 0)) &&
                <>-</>
            }
        </div>
    );
}
