import {RepertoiresConstants} from "../Constants/RepertoiresConstants";
import CommonHelper from "./Common.helper";


export default class RepertoiresHelper {

    /**
     * Retourne le numéro formaté
     *
     * @param {string} numero
     * @returns {string}
     */
    static getFormatedNumero(numero: string): string {
        return CommonHelper.getFormatedElementFromOptions(numero, RepertoiresConstants.numerosOptions);
    }
}

