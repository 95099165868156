import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";
import {IInspection} from "../Models/Inspection.model";
import {ITableauBord} from "../Models/TableauBord.model";
import {IStatistiques} from "../Models/Statistiques.model";
import {IAvisMotive} from "../Models/AvisMotive.model";
import {INotaire} from "../Models/Notaire.model";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";

export type IInspectionsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    etat?: string[],
    departement?: string[],
}
export type IInspectionsGetPaginationApi = IApiPagination & {
    data: IInspection[],
}
export type IInspectionsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IInspectionsGetPaginationApi,
    }
}
export type IInspectionsCheckGenerateButtonApiResponse = IApiCustomResponse & {
    datas: {
        canShowButton: boolean,
    }
}
export type IInspecteursGetApiResponse = IApiCustomResponse & {
    datas: {
        inspecteurs: INotaire[],
        inspecteursCompta: IPersonneExterieure[],
        observateurs: INotaire[],
    }
}
export type IInspectionsPrintGetApiResponse = IApiCustomResponse & {
    datas: {
        url: string
    }
}
export type IInspectionGetApiResponse = IApiCustomResponse & {
    datas?: {
        inspection: IInspection,
        tableauBord: ITableauBord,
        stats?: IStatistiques[],
        avisList?: IAvisMotive[],
        allInspections: IInspection[],
    }
}
export type IInspectionsListForCourrierApiResponse = IApiCustomResponse & {
    datas: {
        activePreviousYear?: IInspection[],
        activeCurrentYear: IInspection[],
        wantedYears: number[],
    }
}

export type IAvisMotiveFormData = {
    annee: string,
    officeId: string,
    notaireId?: string,
    dateSaisie?: string,
    text?: string,
}
export type IUpdateInspecteursFormData = {
    inspecteurs?: string[]
    inspecteursCompta?: string[]
    observateurs?: string[]
}

export type IInspectionCourrierFormData = {
    type: string,
    afficherEntete: string,
    contenu: string,
    isDebug?: boolean,
}
export type IInspectionDocumentsFormData = {
    fichier?: string;
    date: string,
    type: string,
    commentaires?: string,
}
export type IInspectionGetInspecteursListFormData = {
    annee?: number,
    isTest?: boolean,
    filters?: any,
}







export class InspectionsService extends ApiService{
    /**
     * Permet la récupération d'une liste de inspections
     *
     * @returns {Promise<IInspectionsGetFormData>}
     */
    public getInspectionsList(activeTab: string, formData: IInspectionsGetFormData): Promise<IInspectionsGetApiResponse>{
        return this.doApiCall('api/inspections/' + activeTab, 'GET', formData);
    }

    /**
     * Permet la récupération d'une liste de inspections
     *
     * @returns {Promise<IInspectionsGetFormData>}
     */
    public checkCanShowGenerateButton(): Promise<IInspectionsCheckGenerateButtonApiResponse>{
        return this.doApiCall('api/inspections/check-generate-button', 'POST');
    }

    /**
     * Permet la récupérations de la liste des inspecteurs disponible pour une année
     *
     * @param {IInspectionGetInspecteursListFormData} formData
     * @returns {Promise<IInspecteursGetApiResponse>}
     */
    public getInspecteursList(formData: IInspectionGetInspecteursListFormData): Promise<IInspecteursGetApiResponse>{
        return this.doApiCall('api/inspections/inspecteurs', 'GET', formData);
    }

    /**
     * Permet la récupération d'une inspection à partir de son id
     *
     * @param {string} id
     * @returns {Promise<IInspectionGetApiResponse>}
     */
    public getInspection(id: string): Promise<IInspectionGetApiResponse>{
        return this.doApiCall('api/inspections/' + id, 'GET');
    }


    /**
     * Mise à jour d' avis motivé
     *
     * @param {number} id
     * @param {IAvisMotiveFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateAvisMotive(id: number, formDatas: IAvisMotiveFormData): Promise<IApiCustomResponse> {
        return this.doApiCall(`api/inspections/avis-motive/${id}/update`, 'POST', formDatas);
    }


    /**
     * Envoi d'un courrier d'une inspection
     *
     * @param {number} id
     * @param {IInspectionCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(id: number, formDatas: IInspectionCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/inspections/' + id + '/courrier/send', 'POST', formDatas);
    }

    public updateInspecteurs(id: number, formDatas: IUpdateInspecteursFormData): Promise<IInspectionGetApiResponse>{
        return this.doApiCall('api/inspections/' + id + '/inspecteurs', 'POST', formDatas);
    }

    /**
     * Impression d'une liste
     *
     * @param {IInspectionGetInspecteursListFormData} formDatas
     * @returns {Promise<IInspectionsPrintGetApiResponse>}
     */
    public printPdf(formDatas: IInspectionGetInspecteursListFormData): Promise<IInspectionsPrintGetApiResponse> {
        return this.doApiCall(`api/inspections/print`, 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier d'une inspection
     *
     * @param {number} id
     * @param {IInspectionCourrierFormData} formDatas
     * @returns {Promise<IFileDownloadApiResponse>}
     */
    public downloadCourrier(id: number, formDatas: IInspectionCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/inspections/' + id + '/courrier/download', 'POST', formDatas);
    }


    /**
     * Envoi d'un courrier pour une liste d'inspection
     *
     * @param {IInspectionCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrierFromLlist(formDatas: IInspectionCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/inspections/courrier/send', 'POST', formDatas);
    }

    /**
     * Téléchargement d'un courrier pour une liste d'inspection
     *
     * @param {IInspectionCourrierFormData} formDatas
     * @returns {Promise<IFileDownloadApiResponse>}
     */
    public downloadCourrierFromList(formDatas: IInspectionCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall('api/inspections/courrier/download', 'POST', formDatas);
    }


    /**
     * Récupération des inspections pour les courriers
     * @returns {Promise<IInspectionsListForCourrierApiResponse>}
     */
    public getInspectionsListForCourriers(): Promise<IInspectionsListForCourrierApiResponse>{
        return this.doApiCall('api/inspections/liste-pour-courriers', 'GET');
    }


    /**
     * Mise à jour des documents d'une inspection
     *
     * @param {number} rid
     * @param {IInspectionDocumentsFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateDocuments(rid: number, formDatas: IInspectionDocumentsFormData): Promise<IApiCustomResponse>{
        return this.doApiCall('api/inspections/' + rid + '/documents', 'POST', formDatas);
    }

    /**
     * Génération des inspections
     * @returns {Promise<IApiCustomResponse>}
     */
    public generateInspection(): Promise<IApiCustomResponse> {
        return this.doApiCall('api/inspections/generation', 'GET');
    }

    /**
     * Génération des inspections de test
     *
     * @returns {Promise<IApiCustomResponse>}
     */
    public generateInspectionTest(): Promise<IApiCustomResponse> {
        return this.doApiCall('api/inspections/generation-test', 'GET');
    }
}



