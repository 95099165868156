import {IOption} from "../Components/Fields/Select.field.component";
import {IGroupe, IGroupeType} from "../Models/Groupe.model";

export default class GroupesHelper{

    /**
     * Permet de formater une liste des types de groupes pour les selects
     *
     * @param {IGroupe[] | IGroupeType[]} types
     * @returns {IOption[]}
     */
    static formatListForOptions(types: IGroupe[]|IGroupeType[]): IOption[]{
        if(!types || !types.length) return [];

        const options: IOption[] = [];
        for(let type of types){
            const option: IOption = {
                value: type.id.toString(),
                label: type.nom
            }

            options.push(option)
        }

        return options;
    }


    /**
     * Permet de récupérer la liste formatée
     *
     * @param {IGroupeType[]} list
     * @returns {string}
     */
    static getFormated(list: IGroupe[]|IGroupeType[]): string {
        const formatedTypes: string[] = [];

        for(let type of list){
            formatedTypes.push(type.nom);
        }

        return formatedTypes.join(', ');
    }


    /**
     * Permet de récupérer une liste d'ids à partir d'une liste
     *
     * @param {IGroupe[] | IGroupeType[]} list
     * @returns {string[]}
     */
    static getIdsFromList(list: IGroupe[] | IGroupeType[]): string[] {
        const ids: string[] = [];

        for(let type of list){
            ids.push(type.id.toString());
        }

        return ids;
    }


    /**
     * Permet de récupérer une liste d'ids des groupes non automatique à partir d'une liste de groupe
     *
     * @param {IGroupe[]} list
     * @returns {string[]}
     */
    static getIdsFromListWithoutAutomatique(list: IGroupe[]): string[] {
        const ids: string[] = [];

        for(let group of list){
            if( group.automatique === "non") {
                ids.push(group.id.toString());
            }
        }

        return ids;
    }

    /**
     * Permet de récupérer une liste d'ids des groupes automatique à partir d'une liste de groupe
     *
     * @param {IGroupe[]} list
     * @returns {string[]}
     */
    static getNamesFromListAutomatique(list: IGroupe[]): string[] {
        const ids: string[] = [];

        for(let group of list){
            if( group.automatique === "oui") {
                ids.push(group.nom.toString());
            }
        }

        return ids;
    }
}
