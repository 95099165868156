import React, {useEffect, useState} from "react";
import {withRouter, useLocation, useHistory} from "react-router";
import {INotaire} from "src/Models/Notaire.model";
import {INotairesGetApiResponse, INotaireGetFormData, NotairesService} from "src/Services/Notaires.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {IApiPaginationLink} from "src/Services/Api.service";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import SelectMultipleFieldComponent from "src/Components/Fields/SelectMultiple.field.component";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import NotairesHelper from "src/Helpers/Notaires.helper";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {IAdresse} from "src/Models/Adresse.model";
import MapHelper from "src/Helpers/Map.helper";
import AsideNotaireComponent from "src/Components/Aside/AsideNotaire.component";
import NotairesExportComponent from "src/Components/Annuaire/Notaires/NotairesExport.component";
import NotairesAdvancedSearchComponent from "src/Components/Annuaire/Notaires/NotairesAdvancedSearch.component";
import {Link} from "react-router-dom";
import LoadingComponent from "../../../Components/Loading/Loading.component";


type NotairesListScreenProps = {}

const NotairesListScreen = (props: NotairesListScreenProps) => {
    const [filters,initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters("/annuaire/notaires");
    const history = useHistory();

    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const location = useLocation();
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),["departement","type","langues_parlees","fonctions"]);

        updateFilters(initialFilters, false, true);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    /**
     * Lancement de la recherche
     *
     * @returns {string}
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        //Gestion de la tab
        filters.tab = activeTab;


        const notairesServices: NotairesService = new NotairesService();
        notairesServices.getNotairesWithPaginate(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
            if (response && response.datas.pagination) {

                // Notaires
                const currentNotairesList: INotaire[] = response.datas.pagination.data;
                setNotaires(currentNotairesList);

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                // Markers
                const markers: MapMarker[] = [];
                currentNotairesList.forEach((n) => {
                    if (n.adressePrincipale && n.adressePrincipale.latitude) {
                        const adressePrincipale: IAdresse = n.adressePrincipale;

                        const markerText: string = MapHelper.createMapMarkerTextNotaire(`${AppConstants.pathNotaires}/${n.id}`, `${n.nom} ${n.prenom}`, adressePrincipale, n.office);
                        const marker: MapMarker = MapHelper.createMapMarker(markerText, adressePrincipale);
                        markers.push(marker);
                    }
                });
                setMarkers(markers);


                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };

    /**
     * Exécution de la recherche avancée
     *
     * @param {FormComponentFormData} formData
     */
    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters,...formData},true);
        setShowAdvancedSearch(false);
    };



    return (
        <>

            <LayoutComponent showMenu={true} wrapperModificators={`${showExport || showAdvancedSearch ? '-no-scroll' : ''}`}>
                <div className={`list ${loaded ? 'border-footer' : ''}`}>
                    <PageHeaderComponent breadcrumbLinks={[ {text: "Annuaire", link: AppConstants.pathAnnuaire}, {text: "Notaires"},]}
                                         text="Notaires"
                                         icon="icon-notaires"
                                         onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                                         onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}/>

                    {
                        !loaded &&
                        <LoadingComponent />
                    }
                    {
                        loaded &&
						<>
							<TabsContainerComponent defaultTab={TabsSlugs.LIST} onClickTabCallback={(tab)=>{ updateFilters({},false); setActiveTab(tab)}} >
								<TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.LIST}>
									<div className={`list-content -right-gutter`}>
										<div className="list-filters">
											<i className={`icon icon-filtre`} />
											<SelectMultipleFieldComponent fieldName="departement"
											                              label="Départements"
											                              options={OfficesConstants.departementsOptions}
                                                                          hideCurrentValuesOnTop={true}
											                              errors={null}
											                              placeholder="Tous les départements"
											                              hideSearch={true}
											                              modificators={`-inline -on-white`}
											                              oldValues={filters.departement ? filters.departement as string[] : []}
											                              onChange={(values: string[]) => updateFilters({departement: values})}
											/>
											<SelectMultipleFieldComponent fieldName="type"
											                              label="Type"
                                                                          options={NotairesConstants.typesActifsOptions}
                                                                          hideCurrentValuesOnTop={true}
											                              errors={null}
											                              placeholder="Tous les types"
											                              hideSearch={true}
											                              modificators={`-inline -on-white`}
											                              oldValues={filters.type ? filters.type as string[] : []}
											                              onChange={(values: string[]) => updateFilters({type: values})}
											/>
										</div>

                                        <NotairesAdvancedSearchComponent
                                            showSummary={true}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            isActifs={true}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={ onAdvancedSearch}
                                        />

										<table className={`list-table`}>
											<thead className={"headers"}>
                                                <tr>
                                                    {["crpcen", "nom", "type", "office", "ville"].map((item, iIndex) => <th
                                                        className="th" key={iIndex}>
                                                        <TableHeaderButtonComponent
                                                            direction={filters.orderType as string || AppConstants.orderType.asc}
                                                            value={item} selectedValue={filters.orderBy as string || ""}
                                                            label={item} click={(value: string) => setOrder(value)}/>
                                                    </th>)}
                                                </tr>
											</thead>

											<tbody>
                                            {
                                                notaires && notaires.map((notaire: INotaire, index: number) =>
                                                    <tr key={index}
                                                        onClick={() => history.push(`/annuaire/notaires/${notaire.id}`)}
                                                        className="tr">
                                                        <td className="td -bold -w100px">
                                                            {notaire.office && <>{notaire.office.CRPCEN}</>}
                                                        </td>
                                                        <td className="td -w350px">
                                                            {notaire.prenom} {notaire.nom}
                                                        </td>
                                                        <td className="td -w200px">
                                                            {NotairesHelper.getFormattedType(notaire.type)}
                                                        </td>
                                                        <td className="td">
                                                            {
                                                                notaire.office &&
                                                                <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${notaire.office.id}`} onClick={(e) => e.stopPropagation()}>{notaire.office.nom}</Link>
                                                            }
                                                        </td>
                                                        <td className="td -uppercase -w200px">{notaire.adressePrincipale && <>{notaire.adressePrincipale.ville}</>}</td>
                                                    </tr>
                                                )
                                            }
											</tbody>
										</table>

										<PaginationComponent links={paginationLinks}
										                     onPageClick={page => updateFilters({page})}/>


                                        <AsideNotaireComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />

									</div>
								</TabPanelComponent>




								<TabPanelComponent label="Carte" icon="icon-map" slug={TabsSlugs.MAP}>
									<div className={`list-content -no-gutter`} >
										<div className="list-filters">
											<i className={`icon icon-filtre`} />
											<SelectMultipleFieldComponent fieldName="departement"
											                              label="Départements"
											                              options={OfficesConstants.departementsOptions}
                                                                          hideCurrentValuesOnTop={true}
											                              errors={null}
											                              placeholder="Tous les départements"
											                              hideSearch={true}
											                              modificators={`-inline -on-white`}
											                              oldValues={filters.departement ? filters.departement as string[] : []}
											                              onChange={(values: string[]) => updateFilters({departement: values})}
											/>
											<SelectMultipleFieldComponent fieldName="type"
											                              label="Type"
                                                                          options={NotairesConstants.typesActifsOptions}
                                                                          hideCurrentValuesOnTop={true}
											                              errors={null}
											                              placeholder="Tous les types"
											                              hideSearch={true}
											                              modificators={`-inline -on-white`}
											                              oldValues={filters.type ? filters.type as string[] : []}
											                              onChange={(values: string[]) => updateFilters({type: values})}
											/>
										</div>



                                        <NotairesAdvancedSearchComponent
                                            showSummary={false}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={ onAdvancedSearch}
                                        />

										<div className="map-wrapper">
											<MapComponent markers={markers} key={Math.random()}/>
										</div>

                                        <AsideNotaireComponent type="list" modificators="-on-map" exportCallback={() => setShowExport(true)}  filters={filters} />
									</div>
								</TabPanelComponent>
							</TabsContainerComponent>
						</>
                    }


                </div>
            </LayoutComponent>

            <NotairesExportComponent filters={filters} setShowExport={() => setShowExport(false)} showExport={showExport} />
        </>
    )
}

export default withRouter(NotairesListScreen);
