import {
    ICorrespondanceGetApiResponse,
    CorrespondancesService,
    ICorrespondanceParticipantGetApiResponse
} from "src/Services/Correspondances.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICorrespondance} from "src/Models/Correspondance.model";
import {useHistory} from "react-router";
import {ICorrespondanceParticipant} from "../Models/CorrespondanceParticipant.model";

export default function useCorrespondance(){
    const correspondancesService: CorrespondancesService = new CorrespondancesService();
    const history = useHistory();


    /**
     * Fonction de success de l'appel à l'API
     *
     * @param {ICorrespondanceParticipantGetApiResponse} response
     * @param {(correspondanceParticipant: ICorrespondanceParticipant) => void} callback
     */
    const onSuccess = (response: ICorrespondanceParticipantGetApiResponse, callback: (correspondanceParticipant: ICorrespondanceParticipant) => void ) => {
        if (response && response.datas.correspondanceParticipant){
            if(callback){
                callback(response.datas.correspondanceParticipant);
            }
        }
    }

    /**
     * Fonction d'error de l'appel à l'API
     *
     * @param {IApiCustomResponse} error
     * @param {() => void} callbackError
     */
    const onError = (error: IApiCustomResponse, callbackError?: () => void) => {
        if(callbackError){
            callbackError();
        }
        history.push(AppConstants.pathCorrespondances);

        notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la correspondance",type: "danger"});
    }


    /**
     * Récupération d'une correspondance à partir de son id
     *
     * @param {number} pid
     * @param {(correspondance: ICorrespondance) => void} callback
     * @param {() => void} callbackError
     */
    const getCorrespondanceParticipantById = (pid: number, callback:(correspondanceParticipant: ICorrespondanceParticipant) => void, callbackError?: () => void ) => {
        correspondancesService.getCorrespondanceParticipant(pid).then((response: ICorrespondanceParticipantGetApiResponse) => {
            onSuccess(response, callback);
        },(error: IApiCustomResponse) => {
            onError(error, callbackError);
        });
    };
    const getExternalCorrespondanceParticipantById = (pid: number, callback:(correspondanceParticipant: ICorrespondanceParticipant) => void, callbackError?: () => void ) => {
        correspondancesService.getCorrespondanceParticipantExternal(pid).then((response: ICorrespondanceParticipantGetApiResponse) => {
            onSuccess(response, callback);
        },(error: IApiCustomResponse) => {
            onError(error, callbackError);
        });
    };


    /**
     * Récupération d'un participant de test par son id
     *
     * @param {number} pid
     * @param {(correspondance: ICorrespondance) => void} callback
     * @param {() => void} callbackError
     */
    const getExternalCorrespondanceParticipantTestById = (pid: number, callback:(correspondanceParticipant: ICorrespondanceParticipant) => void, callbackError?: () => void ) => {
        correspondancesService.getCorrespondanceParticipantTestExternal(pid).then((response: ICorrespondanceParticipantGetApiResponse) => {
            onSuccess(response, callback);
        },(error: IApiCustomResponse) => {
            onError(error, callbackError);
        });
    };




    return {getCorrespondanceParticipantById, getExternalCorrespondanceParticipantById, getExternalCorrespondanceParticipantTestById};
};

