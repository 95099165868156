import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IEvenement} from "src/Models/Evenement.model";
import {IEvenementsFormData} from "src/Services/Commons.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {NotairesConstants} from "../../../Constants/NotairesConstants";


type EvenementsFormComponentProps = {
    evenements?: IEvenement[],
    isOnCreationStep?: boolean,

    relationName: string,
    relationId: number,

    submitMethod: (formData: IEvenementsFormData) => Promise<IApiCustomResponse>,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onSuccessSubmitNext?: (response: IApiCustomResponse) => void,
    onCancel: () => void,
}

export default function EvenementsFormComponent(props: EvenementsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const evenements: IEvenement[] = props.evenements;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'hidden',
                fieldName: "relationName",
                modificators: "-hidden",

                oldValue: props.relationName,
            },
            {
                type: 'hidden',
                fieldName: "relationId",
                modificators: "-hidden",

                oldValue: props.relationId.toString(),
            },
            {
                type: 'repeater',
                fieldName: "evenements",
                label: "Evénements",
                oldRepeatableValues: evenements as FormComponentFormData,
                required: false,
                formColumns: [{
                    elements: [
                        {
                            type: 'hidden',
                            fieldName: "id",
                            oldValue: "",
                            modificators: "-hidden"
                        },
                        {
                            type: 'date',
                            fieldName: "dateDebut",

                            label: "Date de début",
                            modificators: props.isOnCreationStep ? "-on-white" : '',
                            required: false,
                            showFieldErrorDetail: true,

                            oldValue: "",
                        },
                    ],
                }, {
                    elements: [

                        {
                            type: 'date',
                            fieldName: "dateFin",

                            label: "Date de fin",
                            modificators: props.isOnCreationStep ? "-on-white" : '',
                            required: false,
                            showFieldErrorDetail: true,

                            oldValue: "",
                        },
                    ],
                },
                {
                    elements: [

                        {
                            type: 'text',
                            fieldName: "commentaire",

                            label: "Commentaire",
                            modificators: props.isOnCreationStep ? "-on-white" : '',
                            required: false,

                            oldValue: "",
                        }
                    ],
                }],
            },
        ];

        if(props.relationName === 'notaire') {
            //Pour l'historique des fonctions notaires, le champ commentaire est utilisé pour la "Fonction"
            //Retirer le champ commentaire et le remplacer par un select pour les fonctions
            formElementsColumn1[2].formColumns[2].elements = [];
            formElementsColumn1[2].formColumns[2].elements.push({
                type: 'select',
                fieldName: "commentaire",

                label: "Fonction (parmi les fonctions courantes)",
                placeholder: "Fonction",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                optionsGroup: NotairesConstants.fonctionsOptions,
                hideCheckAllGroupOptions: true,
                hideSearchOnMultiple: true,
                hideEmptyOption: true,

                oldValue: "",
            });
            formElementsColumn1[2].formColumns[2].elements.push({
                type: 'text',
                fieldName: "commentaire",

                label: "Intitulé fonction",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: "",
            });

        }


        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    }, [props.evenements]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IEvenementsFormData).then((response: IApiCustomResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Vos données personnelles ont bien été mises à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de vos informations personnelles.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorMessages={errorMessages}/>
        </div>
    )
}
