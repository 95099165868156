import {CommonsService, IProtectionCheckApiResponse, IProtectionFormData} from "../Services/Commons.service";
import {IApiCustomResponse} from "../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";

const useProtection = () => {
    const commonsService: CommonsService = new CommonsService();
    let formData: IProtectionFormData = null;


    /**
     * Déblocage d'un contenu
     *
     * @returns {Promise<void>}
     */
    const unlockProtection = (): Promise<void> => {
        return new Promise( (resolve, reject) => {
            commonsService.protectionUnlock(formData).then(function(response: IApiCustomResponse){
                resolve();
            }, (error) => {
                const message: string = `Impossible de bloquer le contenu en écriture.`;
                notificationSystem.addNotification({...defaultNotificationConfig, message: message ,type: "danger"});
                reject();
            });
        });
    };


    /**
     * Blocage d'un contenu
     *
     * @returns {Promise<void>}
     */
    const lockProtection = (): Promise<void> => {
        return new Promise( (resolve, reject) => {
            commonsService.protectionLock(formData).then(function(response: IApiCustomResponse){
                resolve();
            }, (error) => {
                const message: string = `Impossible de bloquer le contenu en écriture.`;
                notificationSystem.addNotification({...defaultNotificationConfig, message: message ,type: "danger"});
                reject();
            });
        });
    }


    /**
     * Permet la vérification si un contenu est bloquée
     *
     * @param {boolean} showNotificationIfLocked
     * @returns {Promise<boolean>}
     */
    const checkLockProtection = (showNotificationIfLocked?: boolean): Promise<boolean> => {
        return new Promise( (resolve, reject) => {
            commonsService.protectionCheck(formData).then(function(response: IProtectionCheckApiResponse) {
                if (!response.datas.isLocked) {
                    resolve(false);
                }
                else{
                    if(showNotificationIfLocked){
                        const name: string = response.datas.name || "une personne";
                        const message: string = response.datas.sameUserLogged ? "Vous êtes déjà en train d'éditer un contenu sur cette page." : `Le contenu est déjà en cours de modification par ${name}.`;

                        notificationSystem.addNotification({
                            ...defaultNotificationConfig,
                            message: message,
                            type: "danger"
                        });
                    }

                    resolve(true);
                }
            }, (error) => {
                const message: string = `Impossible de vérifier le blocage en écriture du contenu en écriture.`;
                notificationSystem.addNotification({...defaultNotificationConfig, message: message ,type: "danger"});
                reject();
            });
        });
    }


    /**
     * Initialisation du hook
     *
     * @param {string} contentType
     * @param {string | number} contentId
     */
    const initProtection = (contentType: string, contentId: string|number) => {
        formData = {
            contentType: contentType,
            contentId: contentId
        }
    }


    return {unlockProtection, lockProtection, checkLockProtection, initProtection};
};

export default useProtection;
