import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "../FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IFilInformations} from "src/Models/FilInformations.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {
    IFilInformationsArretDeliberationFormData,
    IFilInformationsDetailsGetApiResponse,} from "src/Services/FilInformations.service";


export default function FilInformationsArretDeliberationFormComponent(props: {
    filInformations?: IFilInformations,
    isOnCreationStep?: boolean,


    submitMethod: (formData: IFilInformationsArretDeliberationFormData) => Promise<IFilInformationsDetailsGetApiResponse>,
    onSuccessSubmit: (response: IFilInformationsDetailsGetApiResponse) => void,
    onCancel: () => void,
}) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    useEffect(() => {
        const {filInformations} = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'repeater',
                fieldName: "arretes",
                label: "Arrêtés et délibérations",
                oldRepeatableValues: filInformations.arretes as FormComponentFormData,
                formColumns: [
                    {
                        modificators: '',
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'text',
                                fieldName: "titre",
                                label: "Titre",
                                required: true,
                                oldValue: "",
                                modificators: "-full"
                            },
                            {
                                type: 'number',
                                fieldName: "ordre",
                                label: "Ordre",
                                required: false,
                                oldValue: "",
                            },
                        ],
                    },
                    {
                        modificators: '',
                        elements: [
                            {
                                type: 'file',
                                fieldName: "document",
                                modificators: "-small-file",
                                label: "Document",
                                required: false,
                                oldValue: "",
                                extensions: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'],
                            },
                        ],
                    },
                ],
                required: false,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    }, []);

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IFilInformationsArretDeliberationFormData).then((response: IFilInformationsDetailsGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le fil d'information a bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification du fil d'information.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };




    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                isStickyAction={true}
                errorMessages={errorMessages} />
        </div>
    )
}
