import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IOrganisme} from "src/Models/Organisme.model";
import {AutresContactsService} from "src/Services/AutresContacts.service";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import StringHelper from "../../Helpers/String.helper";


type IAsideOrganismeComponentProps = {
    modificators?: string,
    type: "list" | "fiche" | "fichePersonnesExterieures" | "ficheCarte",

    organisme?: IOrganisme,

    exportCallback?: () => void;
    filters?: any,
}

export default function AsideOrganismeComponent(props: IAsideOrganismeComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list"){
            actions.push({icon: "icon-print", click: () => exportPDF()})

            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-organisme-add", click: () => history.push(AppConstants.pathOrganismes + "/creation")});
        }

        if( props.type === "fiche") {
            let stringHelper = new StringHelper();
            let filename = "organisme-fiche-" + stringHelper.slugify(props.organisme.nom);
            actions.push({name: "print-pdf", filename: filename , icon: "icon-print"});
        } else if( props.type !== "list") {
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        if(props.type.startsWith("fiche")){
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});

            if (props.organisme) {
                actions.push({icon: "icon-personne-exterieure-add", click: () => history.push(AppConstants.pathOrganismes + '/' + props.organisme.id + '/personnes/ajout')});
            }
        }

        setActions(actions);
    }, [props.filters])


    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }


    /**
     * Suppression
     */
    const doDelete = (): void => {
        const autresContactsService: AutresContactsService = new AutresContactsService();

        autresContactsService.deleteOrganisme(props.organisme.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'organisme a bien été supprimé.",
                type: "success"
            });

            history.push(AppConstants.pathPersonnesExterieures);

        }, (error: IApiCustomResponse) => {
            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression de l'organisme.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }



    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathOrganismes}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            {
                showConfirmation && ( props.organisme) &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer cet organisme ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
