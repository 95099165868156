import FileHelper from "src/Helpers/File.helper";
import {IOffice} from "src/Models/Office.model";
import OfficesHelper from "src/Helpers/Offices.helper";
import {Link} from "react-router-dom";
import React from "react";
import {AppConstants} from "../../../Constants/AppConstants";

type IOfficeIdentiteComponentProps = {
    office: IOffice,
}

export default function OfficeIdentiteComponent(props: IOfficeIdentiteComponentProps) {
    const office: IOffice = props.office;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column -auto">
                    <figure className="informations-image -resume -border">
                        {
                            office.logo &&
                            <img src={FileHelper.getImageUrlFromSize(office.logo,"thumbnail")} alt="" className="image" />
                        }
                        {
                            !office.logo &&
                            <img src={FileHelper.getPlaceholderUrl()} alt="" className="image" />
                        }
                    </figure>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">CRPCEN</p>
                        <p className="value">{office.CRPCEN ? office.CRPCEN : "-"}</p>
                    </div>

                    {
                        office.type && office.type != 'annexe' &&
                        <div className="informations-block">
                            <p className="label">Type</p>
                            <p className="value">{office.type ? OfficesHelper.getFormatedType(office.type) : "-"}</p>
                        </div>
                    }

                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nom de l'office</p>
                        <p className="value">{office.nom}</p>
                    </div>
                    {
                        office.type === "annexe" && office.officePrincipale &&
                            <div className="informations-block">
                                <p className="label">Office principal</p>
                                <p className="value">
                                    <Link to={`${AppConstants.pathOffices}/${office.officePrincipale.id}`}>
                                        {office.officePrincipale.nom}
                                    </Link>
                                </p>
                            </div>
                    }

                </div>
            </div>

        </div>
    );
}
