import React from "react";
import FormationCreationStepComponent from "src/Components/Formations/Formations/FormationCreationStep.component";

const FormationParticipantEditEmailScreen = (props:{
    fid: number,
    pid: number,
}) => {
    return (
        <FormationCreationStepComponent fid={props.fid} pid={props.pid} type="editParticipantMail" />
    )
};

export default FormationParticipantEditEmailScreen;
