import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";

type ICotisationsOfficeRecapConseilSuperieurNotariatComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapConseilSuperieurNotariatComponent(props: ICotisationsOfficeRecapConseilSuperieurNotariatComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Accompte</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.acompteConseilSuperieur, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Solde</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.soldeConseilSuperieur, 2,2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
