import React from "react";
import {IFormation} from "../../../Models/Formation.model";
import DateHelper from "../../../Helpers/Date.helper";
import CalendarComponent from "../../Calendar/Calendar.component";
import {FormationConstants} from "../../../Constants/FormationsConstants";
import {Link} from "react-router-dom";
import {AppConstants} from "../../../Constants/AppConstants";


const FormationInformations = (props: {
    formation: IFormation
}) => {

    return <div className="informations-wrapper">
        <div className="columns">

            <div className="column -auto">
                <CalendarComponent date1={props.formation.date} date2={props.formation.dateFin}/>
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Catégorie</p>
                    <p className="value">{FormationConstants.categories[props.formation.categorie]}</p>
                </div>

                <div className="informations-block">
                    <p className="label">État</p>
                    <p className="value -flex">
                        <span
                            className={`state-bullet icon-bullet -before-element -${props.formation.etat}`}>&nbsp;</span>
                        {FormationConstants.states[props.formation.etat]}
                    </p>
                </div>
                <div className="informations-block">
                    <p className="label">Titre</p>
                    <p className="value">{props.formation.titre}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Url d'inscription</p>
                    <p className="value">
                        <Link
                            to={`/formations/${props.formation.id}/inscription-exterieure`}>{`${AppConstants.serverUrl}formations/${props.formation.id}/inscription-exterieure`}</Link>
                    </p>
                </div>
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Date</p>
                    <p className="value">{DateHelper.getFormatedDate(props.formation.date, true)}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Date de fin</p>
                    <p className="value">{DateHelper.getFormatedDate(props.formation.dateFin, true)}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Date limite de réponse</p>
                    <p className="value">{props.formation.dateLimiteReponse ? DateHelper.getFormatedDate(props.formation.dateLimiteReponse) : '-'}</p>
                </div>

                <div className="informations-block">
                    <p className="label">Date de confirmation</p>
                    <p className="value">{DateHelper.getFormatedDate(props.formation.dateConfirmation)}</p>
                </div>

                {/*Si la formation est passée, afficher la date de clôture du dossier (qui est définie automatiquement quand la formation obtient l'état passée) */}
                {
                    props.formation.etat && props.formation.etat === FormationConstants.statePassee &&
                    <div className="informations-block">
                        <p className="label">Date de clôture du dossier</p>
                        <p className="value">{props.formation.dateClotureDossier ? DateHelper.getFormatedDate(props.formation.dateClotureDossier) : '-'}</p>
                    </div>
                }
            </div>
        </div>

    </div>

};

export default FormationInformations;
