import {IOption} from "../Components/Fields/Select.field.component";

export type IInspectionsConstants = {
    etatsOptions: IOption[],
    documentsTypesOptions: IOption[],
}
export const InspectionsConstants: IInspectionsConstants = {
    etatsOptions: [
        {
            value: "a-faire",
            label: "A faire"
        },
        {
            value: "realisee",
            label: "Réalisée"
        },
    ],

    documentsTypesOptions: [
        {
            value: "inspection-lettre-mission",
            label: "Lettre de mission",
        },
        {
            value: "inspection-arrete-caisse",
            label: "Arreté caisse",
        },
        {
            value: "inspection-date-inspection",
            label: "Date d'inspection",
        },
        {
            value: "inspection-rapport-procureur",
            label: "Rapport Procureur ",
        },
        {
            value: "inspection-avis-motive",
            label: "Avis motivé",
        },
    ],
};






