import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type IPersonneExterieureConstants = {
    civiliteOptions: IOption[],
    formulesPolitesseOptions: IOption[],
    creationSteps: ICreationSteps[],
}
export const PersonneExterieureConstants: IPersonneExterieureConstants = {
    civiliteOptions: [
        {
            value: "monsieur",
            label: "Monsieur"
        },
        {
            value: "madame",
            label: "Madame"
        },
        {
            value: "maitre",
            label: "Cher Maître"
        },
        {
            value: "maitre-simple",
            label: "Maître"
        },
        {
            value: "president",
            label: "Monsieur le Président"
        },
        {
            value: "presidente",
            label: "Madame la Présidente"
        },
        {
            value: "vicePresident",
            label: "Monsieur le Vice-Président"
        },
        {
            value: "videPresidente",
            label: "Madame la Vice-Présidente"
        },
        {
            value: "directeur",
            label: "Monsieur le Directeur"
        },
        {
            value: "directrice",
            label: "Madame la Directrice"
        },
        {
            value: "procureur",
            label: "Monsieur le Procureur"
        },
        {
            value: "procureure",
            label: "Madame la Procureure"
        },
        {
            value: "procureurAdjoint",
            label: "Monsieur le Procureur adjoint"
        },
        {
            value: "procureurAdjointe",
            label: "Madame la Procureure adjointe"
        },
        {
            value: "avocatGeneral",
            label: "Monsieur l’Avocat général"
        },
        {
            value: "maire",
            label: "Monsieur le Maire"
        },
        {
            value: "maireMadame",
            label: "Madame le Maire"
        },
        {
            value: "prefet",
            label: "Monsieur le Préfet"
        },
        {
            value: "prefete",
            label: "Madame la Préfète"
        },
        {
            value: "secretaireGeneral",
            label: "Monsieur le Secrétaire Général"
        },
        {
            value: "secretaireGenerale",
            label: "Madame la Secrétaire Générale"
        },
        {
            value: "batonnier",
            label: "Monsieur le Bâtonnier"
        },
        {
            value: "batonniere",
            label: "Madame la Bâtonnière"
        },
        {
            value: "confrere",
            label: "Mon Cher Confrère"
        },
    ],


    formulesPolitesseOptions: [
        {
            value: "considerationDistinguee",
            label: "Je vous prie d’agréer, [civilité], l’expression de ma considération distinguée"
        },
        {
            value: "hauteConsideration",
            label: "Je vous prie d’agréer, [civilité], l’expression de ma plus haute considération"
        },
        {
            value: "sinceresSalutation",
            label: "Je vous prie d’agréer, [civilité], l’expression de mes respectueuses et sincères salutations"
        },
        {
            value: "devoueConfrere",
            label: "Votre bien dévoué confrère."
        },
    ],

    creationSteps: [
        {
            url: "/coordonnees",
            title: "Coordonnées",
            icon: "icon-coordonnees-adresses",
        },
        {
            url: "/adresses",
            title: "Adresses",
            icon: "icon-coordonnees",
        },
    ],
};
