import {NotairesConstants} from "../Constants/NotairesConstants";
import {IOption, IOptionsGroup} from "../Components/Fields/Select.field.component";
import {INotaire} from "../Models/Notaire.model";
import CommonHelper from "./Common.helper";

export default class NotairesHelper{

    /**
     * Retourne le type de notaire à partir de la clé passée en paramètre
     * @param {string} type
     * @returns {string}
     */
    static getFormattedType(type:string): string {
        let formattedType: string = type;

        for(let cat of NotairesConstants.typesFullOptions){
            const foundedType = cat.options.find((opt)=>opt.value === type);
            if(foundedType) formattedType = foundedType.label;
        }

        return formattedType;
    }

    /**
     * Retourne l'identité d'un Notaire (nom et prénom et type)
     * @returns {string}
     * @param {INotaire} notaire
     * @param showType
     */
    static getFormattedIdentite(notaire:INotaire, showType: boolean = true): string {
        let label = notaire.prenom + " " + notaire.nom;
        if(showType) {
            label += ' (' + this.getFormattedType(notaire.type) + ')';
        }

        return label;
    }

    /**
     * Permet de formater une liste de notaire pour les selects
     * Si le paramètre officeId est passé, limite aux notaires de cet office
     * @param {INotaire[]} notaires
     * @param {number} officeId
     * @param {string[]} exclude
     * @param {boolean} showType
     * @returns {IOption[]}
     */
    static formatListForOptions(notaires: INotaire[], officeId: number = null, exclude: string[] = [], showType: boolean = true): IOption[]{
        if(!notaires || !notaires.length) return [];

        const options: IOption[] = [];
        for(let notaire of notaires){
            if(officeId === null || (notaire.office && officeId === notaire.office.id)){
                let label = notaire.prenom + " " + notaire.nom;
                if(showType) {
                    label += ' (' + this.getFormattedType(notaire.type) + ')';
                }
                const option: IOption = {
                    value: notaire.id.toString(),
                    label: label,
                };

                const keywords: string[] = [];
                keywords.push(notaire.nom);
                keywords.push(notaire.prenom);
                keywords.push(notaire.nom + " " + notaire.prenom);
                keywords.push(notaire.prenom + " " + notaire.nom);
                if(notaire.office){
                    keywords.push(notaire.office.nom);
                    keywords.push(notaire.office.CRPCEN.toString());
                }
                option.keywords = keywords;


                if(exclude.length > 0 && exclude.includes(notaire.type)) {
                    continue;
                }

                options.push(option)
            }
        }

        return options;
    }

    /**
     * Retourne les fonctions du notaire
     *
     * @param {string[]} fonctions
     * @returns {string}
     */
    static getFormatedFonctions(fonctions: string[]): string {
        let formatedValues: string[] = [];
        const fonctionsOptionsGroup: IOptionsGroup[] = NotairesConstants.fonctionsOptions;

        for(let l of fonctions){
            for(let group of fonctionsOptionsGroup){
                const foundedOption: IOption = group.options.find((opt)=> opt.value === l);
                if(foundedOption) formatedValues.push(foundedOption.label);
            }
        }

        const formatedStringValue: string = formatedValues.join(', ');

        return formatedStringValue;
    }


    /**
     * Retourne les langues du notaire
     *
     * @param {string[]} langues
     * @returns {string}
     */
    static getFormatedLangues(langues: string[]): string {
        return CommonHelper.getFormatedElementsFromOptions(langues, NotairesConstants.languesParleesOptions);
    }


    /**
     * Retourne les types de document du notaire
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedDocumentType(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, NotairesConstants.documentsTypesOptions);
    }


    /**
     * Retourne les labels du notaire
     *
     * @param {string[]} labels
     * @returns {string}
     */
    static getFormatedLabels(labels: string[]): string {
        return CommonHelper.getFormatedElementsFromOptionsGroup(labels, NotairesConstants.labelsOptions);
    }


    /**
     * Permet de récuépérer une liste d'id à partir d'une liste de notaires
     *
     * @param {INotaire[]} notaires
     * @returns {string[]}
     */
    public static getIdsFromList(notaires: INotaire[]): string[]{
        if(!notaires || !notaires.length) return [];

        const ids: string[] = notaires.map((notaire: INotaire) => notaire.id.toString());

        return ids;
    }


}
