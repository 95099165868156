import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {ISignatureGetApiResponse, ISignatureInformationsFormData, SignaturesService} from "src/Services/Signatures.service";
import {ISignature} from "src/Models/Signature.model";
import SignaturesForm from "src/Components/Forms/Correspondances/Signatures/SignaturesForm.component";


type ISignatureCreationScreenProps = {
}
export default function SignatureCreationScreen(props: ISignatureCreationScreenProps){
    const history = useHistory();
    const signatureService: SignaturesService = new SignaturesService();

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: ISignatureGetApiResponse): void => {
        if(response.datas && response.datas.signature){
            const signature: ISignature = response.datas.signature;
            history.push(AppConstants.pathSignatures + '/' + signature.id);
        }
        else{
            history.push(AppConstants.pathSignatures);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathSignatures);

    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une signatyre" icon="icon-signature" modificators={`-in-fullscreen -border-full-screen`}/>

            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />

            <SignaturesForm submitMethod={(formData: ISignatureInformationsFormData)=>signatureService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />
        </LayoutComponent>
    )
}
