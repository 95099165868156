import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import AccordionHeaderComponent from "./AccordionHeader.component";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {Redirect} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {Prompt} from "react-router-dom";


type IAccordionWithFormSwitchProps = {
    contentShowedOnInit?: boolean,
    title: string,
    icon?: string,
    updatedDate?: string,
    form: JSX.Element,
    infos: JSX.Element,
    contentId: number|string,
    contentType: string,
    skipProtectionCheck?: boolean,
    hideEditButton?: boolean,
    hideEditButtonIfInfosEmpty?: boolean,
    skipPrompt?: boolean,

    accordionModificators?: string,


    onFormOpen?: () => void,
    onFormClose?: () => void,
}

function AccordionWithFormSwitchComponent (props: IAccordionWithFormSwitchProps, ref:any) {
    const typeInformation: string = "information";
    const typeForm: string = "form";
    const {isAuthenticated} = useContext(UserContext) as IUserContext;

    const [contentShowed, setContentShowed] = useState<boolean>(props.contentShowedOnInit || false);
    const [contentTypeShowed, setContentTypeShowed] = useState<string>(typeInformation);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();

    /**
     * On rend la fonction de l'enfant accessible au parent
     */
    useImperativeHandle(ref, () => ({
        hideForm: hideForm,
    }));

    /**
     * Gestion du toggle de l'accordion
     */
    const onAccordionToggle = () => {
        //Si le formulaire est affiché, on ne doit pas pouvoir cacher le contenu
        if(contentTypeShowed === typeForm) return;

        setContentShowed(!contentShowed);
    }

    //Affichage du formulaire
    const showForm = () => {
        //Vérification si la personne est loggée
        if (!isAuthenticated) {
            return <Redirect push to={AppConstants.pathLogin} />
        }

        setContentTypeShowed(typeForm);

        if(props.onFormOpen){
            props.onFormOpen();
        }
    }

    //Cache du formulaire
    const hideForm = (isCancel?: boolean) => {
        if(contentTypeShowed === typeInformation) return;

        if(!isCancel || props.skipProtectionCheck){
            setContentTypeShowed(typeInformation);

            if(props.onFormClose){
                props.onFormClose();
            }
            return;
        }

        setShowConfirmation(true);
    }

    /**
     * Validation de la confirmation
     * Débloquage du contenu
     */
    const onValidationConfirmation = () => {
        setContentTypeShowed(typeInformation);
        setShowConfirmation(false);

        if(props.onFormClose){
            props.onFormClose();
        }
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }


    return (
        <>
            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onValidationConfirmation} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment annuler vos modifications ?" />
            }
            <div className={`accordion-wrapper -type-${contentTypeShowed} ${props.accordionModificators ||""}` }>
                <div className="accordion-content g-content">
                    <AccordionHeaderComponent
                        isContentShowed={contentShowed}
                        contentTypeShowed={contentTypeShowed}
                        title={props.title}
                        updatedDate={props.updatedDate}
                        icon={props.icon}
                        showEditButton={!props.hideEditButton && !props.hideEditButtonIfInfosEmpty}
                        onClickEditButton={showForm}
                        onToggle={onAccordionToggle}
                        hideToggleButton={contentTypeShowed === typeForm}
                    />

                    {
                        contentShowed &&
                        <>
                            {
                                (contentTypeShowed === typeInformation) && props.infos
                            }
                            {
                                (contentTypeShowed === typeForm) && props.form
                            }
                        </>
                    }
                </div>
            </div>

            {
                !props.skipPrompt &&
                <Prompt
                    when={contentTypeShowed === typeForm}
                    message="Vous avez du contenu en cours d'édition, êtes vous sur de vouloir quitter la page ?"
                />
            }

        </>

    )
}
export default forwardRef(AccordionWithFormSwitchComponent);
