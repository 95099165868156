import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import FileHelper from "../../../Helpers/File.helper";

type INotaireResumeComponentProps = {
    notaire: INotaire,
}

export default function NotaireResumeComponent(props: INotaireResumeComponentProps) {
    const notaire: INotaire = props.notaire;

    let imagePlaceholder: string = "";
    switch (notaire.sexe) {
        case "masculin":
            imagePlaceholder = FileHelper.getPlaceholderUrl("picture-man");
            break;
        case "feminin":
            imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
            break;

        case "nonPrecise":
        default:
            imagePlaceholder = FileHelper.getPlaceholderUrl("picture-woman");
            break;
    }


    const getEmailPrincipal = (): string => {
        if(!notaire.coordonnees || !notaire.coordonnees.length) return null;
        let email: string = null;

        for(let e of notaire.coordonnees){
            if( e.type == "email" && e.destination == "principal"){
                email = e.coordonnees;
                break;
            }
        }

        if(!email){
            for(let e of notaire.coordonnees){
                if( e.type == "email" && e.destination == "perso"){
                    email = e.coordonnees;
                    break;
                }
            }
        }

        return email
    }



    return (
        <div className="informations-wrapper">
            <div className="columns">

                <div className="column -auto">
                    <figure className="informations-image -resume -notaire-photo">
                        <img src={notaire.photo ? FileHelper.getImageUrlFromSize(notaire.photo,"thumbnail") : imagePlaceholder} alt="" className="image" />
                    </figure>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Type de notaire</p>
                        <p className="value">{notaire.type ? NotairesHelper.getFormattedType(notaire.type) : '-'}</p>
                    </div>

                    {
                        notaire.office &&
                        <>
                            <div className="informations-block">
                                <p className="label">Nom de l'office</p>
                                <p className="value">{notaire.office.nom}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">CRPCEN</p>
                                <p className="value">{notaire.office.CRPCEN}</p>
                            </div>
                        </>
                    }
                    {
                        notaire.adressePrincipale &&
                        <>
                            <div className="informations-block">
                                <p className="label">Ville</p>
                                <p className="value -uppercase">{notaire.adressePrincipale.ville}</p>
                            </div>
                        </>
                    }
                </div>

                <div className="column">
                    {
                        (notaire.phonesMobile && notaire.phonesMobile.length > 0) &&
                            <div className="informations-block">
                                <p className="label">N° de portable</p>
                                <p className="value">{notaire.phonesMobile[0]}</p>
                            </div>
                    }
                    {
                        (notaire.phonesDirect && notaire.phonesDirect.length > 0) &&
                        <div className="informations-block">
                            <p className="label">N° de ligne directe</p>
                            <p className="value">{notaire.phonesDirect[0]}</p>
                        </div>
                    }
                    {
                        getEmailPrincipal() &&
                            <div className="informations-block">
                                <p className="label">Mail</p>
                                <p className="value">{getEmailPrincipal()}</p>
                            </div>
                    }
                </div>
            </div>

        </div>
    );
}
