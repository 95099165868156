import React, {useEffect, useRef, useState} from "react";

import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {AppConstants} from "src/Constants/AppConstants";
import {ICorrespondance} from "src/Models/Correspondance.model";
import useCorrespondance from "src/Hooks/UseCorrespondance";
import {useHistory, useLocation} from "react-router";
import AsideCorrespondanceComponent from "src/Components/Aside/AsideCorrespondances.component";
import CorrespondanceInformations from "src/Components/Correspondances/Courriers/CorrespondanceInformations.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import CorrespondanceInformationsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceInformationsForm.component";
import {CorrespondancesService, ICorrespondanceFormData, ICorrespondanceParticipantsGetApiResponse, ICorrespondancesParticipantsGetFormData} from "src/Services/Correspondances.service";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import CorrespondancePrecisionsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondancePrecisionsForm.component";
import CorrespondancePrecisions from "src/Components/Correspondances/Courriers/CorrespondancePrecisions.component";
import CorrespondanceDocumentsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceDocumentsForm.component";
import CorrespondanceDocuments from "src/Components/Correspondances/Courriers/CorrespondanceDocuments.component";
import CorrespondanceCourrierForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceCourrierForm.component";
import CorrespondanceInvites from "src/Components/Correspondances/Courriers/CorrespondanceInvites.component";
import CorrespondanceInvitesForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceInvitesForm.component";
import CorrespondanceSendList from "src/Components/Correspondances/Courriers/CorrespondanceSendList.component";
import CorrespondanceSignatureInformationsComponent from "src/Components/Correspondances/Courriers/CorrespondanceSignatureInformations.component";
import CorrespondanceSignatureInformationsForm from "src/Components/Forms/Correspondances/Courriers/CorrespondanceSignatureInformationsForm.component";
import {ICorrespondanceParticipant} from "src/Models/CorrespondanceParticipant.model";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import CorrespondancesInvitesExportComponent
    from "../../../Components/Correspondances/CorrespondancesInvitesExport.component";

export type ICorrespondanceFicheScreenProps = {
    cid: number,
}


export default function CorrespondanceFicheScreen(props: ICorrespondanceFicheScreenProps) {
    const { hash } = useLocation();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, setPath, defaultFilters] = useListFilters(`/correspondances/${props.cid}`, false);
    const [getCorrespondanceById] = useCorrespondance();
    const [correspondance, setCorrespondance] = useState<ICorrespondance>(null);
    const [participants, setParticipants] = useState<ICorrespondanceParticipant[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>(hash ? hash.replace('#', "") : 'fiche');
    const [participantsFiltered, setParticipantsFiltered] = useState<ICorrespondanceParticipant[]>([]);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [formOpened, setFormOpened] = useState<boolean>(false);
    const correspondancesService: CorrespondancesService = new CorrespondancesService();

    const informationsAccordionRef = useRef(null);
    const signatureAccordionRef = useRef(null);
    const destinatairesAccordionRef = useRef(null);
    const precisionsAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);

    useEffect(() => {
        if (!props.cid) {
            history.push(AppConstants.pathCorrespondances);
        }
        getCorrespondance();
    }, [props.cid]);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);

        updateFilters(initialFilters, false, true);
    }, []);



    useEffect(() => {
        if (!filters) return null;
        launchListingFilteredSearch();
    }, [filters, activeTab]);


    useEffect(() => {
        if(correspondance) {
            launchListingFilteredSearch()
        }
    }, [correspondance])


    /**
     * Lancement de la recherche/changement de page (pour le listing : listes d'envoi et liste d'envoi des tests)
     *
     * @returns {string}
     */
    const launchListingFilteredSearch = (): void => {
        setParticipants([]);
        setPaginationLinks(null);

        let isListing:boolean = false;
        let currentFilters: ICorrespondancesParticipantsGetFormData = filters as ICorrespondancesParticipantsGetFormData;

        if (activeTab === "inscrits") {
            isListing = true;
        }
        else if (activeTab === "tests") {
            isListing = true;
            //Ajout de la notion de test au filtre (sans modifier le state filters actuel)
            currentFilters = {...filters, test: "oui"} as ICorrespondancesParticipantsGetFormData;
        }

        if(isListing) {
            correspondancesService.getParticipantsCorrespondance(props.cid, currentFilters).then((response: ICorrespondanceParticipantsGetApiResponse) => {
                if (response && response.datas.pagination) {
                    setParticipants(response.datas.pagination.data);
                    setPaginationLinks(response.datas.pagination.links);
                }
            });
        }
    }



    useEffect(() => {
        if (!correspondance) return;

        const breadcrumbLinks = [
            {text: "Correspondances", link: AppConstants.pathMosaique + '#correspondances'},
            {text: "Correspondances", link: AppConstants.pathCorrespondances},
            {text: correspondance.titre},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [correspondance, loaded]);


    /**
     * Récupération de la correspondance selon l'id
     */
    const getCorrespondance = (): void => {
        getCorrespondanceById(props.cid, (c: ICorrespondance) => {
            setCorrespondance(c);

            setParticipantsFiltered(c.participants);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if ((informationsAccordionRef && informationsAccordionRef.current)) {
            informationsAccordionRef.current.hideForm(isCancel);
        }
        if((signatureAccordionRef && signatureAccordionRef.current) ){
            signatureAccordionRef.current.hideForm(isCancel);
        }
        if((destinatairesAccordionRef && destinatairesAccordionRef.current) ){
            destinatairesAccordionRef.current.hideForm(isCancel);
        }
        if((precisionsAccordionRef && precisionsAccordionRef.current) ){
            precisionsAccordionRef.current.hideForm(isCancel);
        }
        if((documentsAccordionRef && documentsAccordionRef.current) ){
            documentsAccordionRef.current.hideForm(isCancel);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCorrespondance();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent
                        breadcrumbLinks={breadcrumbLinks}
                        text={correspondance.titre}
                        icon="icon-correspondances"
                        modificators=""
                        onSearch={activeTab === "inscrits" ? (keyword =>  updateFilters({...defaultFilters,keyword},true)) : null}

                    />


                    <TabsContainerComponent defaultTab={hash ? hash.replace('#', "") : 'fiche'}  onClickTabCallback={(tab)=>{setActiveTab(tab); closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche" slug="fiche">
                            <div className="fiche-content -right-gutter border-footer">


	                            <AccordionWithFormSwitchComponent
		                            ref={informationsAccordionRef}
		                            title="Informations"
		                            icon="icon-informations"
		                            contentShowedOnInit={true}
		                            contentType="correspondance"
		                            contentId={correspondance.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened || correspondance.envoye === 'oui'}
		                            form={<CorrespondanceInformationsForm correspondance={correspondance} submitMethod={(formData: ICorrespondanceFormData)=>correspondancesService.updateCorrespondanceInformations(formData, correspondance.id)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
		                            infos={<CorrespondanceInformations correspondance={correspondance} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={signatureAccordionRef}
                                    title="Signature"
                                    icon="icon-signature"
                                    contentShowedOnInit={true}
                                    contentType="correspondance"
                                    contentId={correspondance.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    form={<CorrespondanceSignatureInformationsForm correspondance={correspondance} submitMethod={(formData: ICorrespondanceFormData)=>correspondancesService.updateSignaturesInformations(formData, correspondance.id)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<CorrespondanceSignatureInformationsComponent correspondance={correspondance} />}
                                    hideEditButton={formOpened || correspondance.envoye === 'oui'}
                                    skipPrompt={true}
                                />


                                <AccordionWithFormSwitchComponent
                                    ref={destinatairesAccordionRef}
                                    title="Destinataires"
                                    icon="icon-courriers"
                                    contentShowedOnInit={true}
                                    contentType="correspondance"
                                    contentId={correspondance.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    form={<CorrespondanceInvitesForm correspondance={correspondance} submitMethod={(formData: ICorrespondanceFormData)=>correspondancesService.updateCorrespondanceGroupes(correspondance.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<CorrespondanceInvites correspondance={correspondance} onCorrespondanceChange={(updatedCorrespondance)=>setCorrespondance(updatedCorrespondance)} />}
                                    hideEditButton={formOpened || correspondance.envoye === 'oui'}
                                    skipPrompt={true}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={precisionsAccordionRef}
                                    title="Précisions"
                                    icon="icon-precisions"
                                    contentShowedOnInit={true}
                                    contentType="correspondance"
                                    contentId={correspondance.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened || correspondance.envoye === 'oui'}
                                    form={<CorrespondancePrecisionsForm correspondance={correspondance} submitMethod={(formData: ICorrespondanceFormData)=>correspondancesService.updateCorrespondancePrecisions(correspondance.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<CorrespondancePrecisions correspondance={correspondance} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={documentsAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="correspondance"
                                    contentId={correspondance.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened || correspondance.envoye === 'oui'}
                                    form={<CorrespondanceDocumentsForm correspondance={correspondance} submitMethod={(formData: ICorrespondanceFormData)=>correspondancesService.updateCorrespondanceDocuments(correspondance.id,formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} isOnCreationStep={false} />}
                                    infos={<CorrespondanceDocuments correspondance={correspondance} />}
                                />

                                <AsideCorrespondanceComponent type='fiche' correspondance={correspondance} activeTab={activeTab} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Courriers" icon="icon-courrier" slug="courriers">
                            <div className="fiche-content -right-gutter border-footer">

                                <div className="g-content">
                                    {
                                        correspondance.type &&
                                        <CorrespondanceCourrierForm correspondance={correspondance}
                                                                    onActionSuccess={getCorrespondance}/>
                                    }
                                    {
                                        !correspondance.type &&
                                        <p>Aucun type de courrier sélectionné</p>
                                    }
                                </div>

                                <AsideCorrespondanceComponent type='ficheCourriers' correspondance={correspondance}  activeTab={activeTab} />
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Liste d'envoi" icon="icon-inscrits" slug="inscrits">
                            <div className="fiche-content -right-gutter border-footer">
                                <>
                                    <CorrespondanceSendList type="normal"
                                                            correspondance={correspondance} participants={participants}
                                                            onCorrespondanceChange={(updatedCorrespondance) => setCorrespondance(updatedCorrespondance)}/>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                    <AsideCorrespondanceComponent type="ficheListInvite" correspondance={correspondance} participants={participants}
                                                                  exportCallback={() => setShowExport(true)}  activeTab={activeTab}/>
                                </>
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Liste d'envoi des tests" icon="icon-inscrits" slug="tests">
                            <div className="fiche-content -right-gutter border-footer">
                                <>
                                    <CorrespondanceSendList type="test" correspondance={correspondance}
                                                            participants={participants}
                                                            onCorrespondanceChange={(updatedCorrespondance) => setCorrespondance(updatedCorrespondance)}/>
                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                    <AsideCorrespondanceComponent type="ficheListInviteTest" correspondance={correspondance} exportCallback={() => setShowExport(true)}  activeTab={activeTab}   />
                                </>
                            </div>
                        </TabPanelComponent>

                    </TabsContainerComponent>

                    <CorrespondancesInvitesExportComponent setShowExport={() => setShowExport(false)}
                                                           correspondance={correspondance} showExport={showExport}
                                                           filters={activeTab === "tests" ? {...filters, test: "oui"}:filters} />
                </LayoutComponent>
            }
        </>
    )
}

