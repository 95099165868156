import React from "react";
import {IReclamation} from "src/Models/Reclamation.model";
import DateHelper from "../../Helpers/Date.helper";
import ReclamationsHelper from "../../Helpers/Reclamations.helper";
import CommonHelper from "../../Helpers/Common.helper";

type IReclamationConciliationComponentProps = {
    reclamation: IReclamation,
}

export default function ReclamationConciliationComponent(props: IReclamationConciliationComponentProps) {
    const reclamation: IReclamation = props.reclamation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date conciliation</p>
                        <p className="value">
                            {reclamation.conciliationDate ? DateHelper.getFormatedDate(reclamation.conciliationDate) : "-"}
                            {(reclamation.conciliationDate && reclamation.conciliationHeure) ? " " + reclamation.conciliationHeure : ""}
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Conciliation</p>
                        <p className="value">{reclamation.conciliation ? CommonHelper.getFormatedOuiNon(reclamation.conciliation) : "-"}</p>
                    </div>
                </div>


                <div className="column">
                    {(reclamation && reclamation.conciliation === 'oui') &&
                        <div className="informations-block">
                            <p className="label">Issue de la conciliation</p>
                            <p className="value">{reclamation.conciliationIssue ? ReclamationsHelper.getFormatedIssueConciliation(reclamation.conciliationIssue) : '-'}</p>
                        </div>
                    }
                    {(reclamation && reclamation.conciliation === 'non') &&
                        <div className="informations-block">
                            <p className="label">Motif</p>
                            <p className="value">{reclamation.conciliationMotif ? ReclamationsHelper.getFormatedMotifConciliation(reclamation.conciliationMotif) : '-'}</p>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}
