import {IAdresse} from "src/Models/Adresse.model";
import {IReclamationPlaignant} from "src/Models/ReclamationPlaignant.model";
import ReclamationsHelper from "../../Helpers/Reclamations.helper";

type IReclamationAdressesPlaignantsComponentProps = {
    plaignants: IReclamationPlaignant[],
    adresses?: IAdresse[],
}

export default function ReclamationAdressesPlaignantsComponent(props: IReclamationAdressesPlaignantsComponentProps) {
    const plaignants: IReclamationPlaignant[] = props.plaignants;



    /**
     * Permet la récupération des noms des plaignants d'une adresse
     *
     * @param {IAdresse} adresse
     * @returns {string}
     */
    const getPlaignantNomForAdresse = (adresse: IAdresse) => {
        const plaignantIds: string[] = adresse.plaignantId;
        const plaignantNom: string[] = [];

        const plaignantsFound = plaignants.filter( (p) => plaignantIds.includes(p.id));
        for(let p of plaignantsFound){
            plaignantNom.push(`${p.nom} ${p.prenom}`);
        }

        return plaignantNom.join(' et ');
    };



    return (
        <div className="informations-wrapper">
            {
                props.adresses && props.adresses.map((adresse: IAdresse, index: number) =>
                    <div className="columns" key={'a_' + index} >
                        <p className="title -alternative">
                            {getPlaignantNomForAdresse(adresse)}
                        </p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Formule d'appel</p>
                                <p className="value">{adresse.formuleAppel ? ReclamationsHelper.getFormatedAdresseFormuleAppel(adresse.formuleAppel): ""}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Rue</p>
                                <p className="value">{adresse.ligne1 ? adresse.ligne1: "-"}</p>
                            </div>
                            <div className="informations-block">
                                <p className="label">Boîte Postale</p>
                                <p className="value">{adresse.boitePostale ? adresse.boitePostale: "-"}</p>
                            </div>

                            <div className="informations-block">
                                <p className="label">Code Postal</p>
                                <p className="value">{adresse.codePostal ? adresse.codePostal: "-"}</p>
                            </div>
                            {
                                adresse.adresseEtrangere && (adresse.adresseEtrangere === "oui") &&

                                <div className="informations-block">
                                    <p className="label">Pays</p>
                                    <p className="value">{adresse.pays ? adresse.pays: "-"}</p>
                                </div>
                            }
                        </div>

                        <div className="column">

                            <div className="informations-block">
                                <p className="label">Complément d'adresse</p>
                                <p className="value">{adresse.ligne2 ? adresse.ligne2: "-"}</p>
                            </div>
                            <div className="informations-block -skip-line-1">
                                <p className="label">Ville</p>
                                <p className="value -uppercase">{adresse.ville ? adresse.ville: "-"}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!plaignants || (plaignants.length === 0)) &&
                <>-</>
            }

        </div>
    );
}
