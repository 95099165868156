import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IGroupe} from "src/Models/Groupe.model";
import {GroupesService} from "src/Services/Groupes.service";
import useListExportPDF from "../../Hooks/UseListExportPDF";


type IAsideGroupesComponentProps = {
    groupe?: IGroupe,
    modificators?: string,
    type: "list" | "fiche",

    exportCallback?: () => void,

    filters?: any
}

export default function AsideGroupesComponent(props: IAsideGroupesComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];
        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-groupes-add", click: () => history.push(AppConstants.pathGroupes + "/creation")});
        }

        if( props.type === "fiche"){
            actions.push({icon: "icon-print", click: () => printScreen()})

            if(props.groupe.canBeDeleted){
                actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
            }

            actions.push({icon: "icon-export", click: () => props.exportCallback()});

            if(props.groupe.automatique == 'non'){
                actions.push({icon: "icon-groupes-add", click: () => history.push(`${AppConstants.pathGroupes}/${props.groupe.id}/membres`)});
            }
        }
        setActions(actions);
    }, [props.filters]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }

    /**
     * Suppression
     */
    const doDelete = (): void => {
        const groupesService: GroupesService = new GroupesService();

        groupesService.delete(props.groupe.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le groupe a bien été supprimé.",
                type: "success"
            });

            history.push(AppConstants.pathGroupes);

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du groupe.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);
        const url:string = `api${AppConstants.pathGroupes}/export-pdf`;

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }

    return (
        <>
            {
                showConfirmation && props.groupe &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer ce groupe ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
