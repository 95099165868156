import React, {useEffect, useRef, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {INotaire} from "src/Models/Notaire.model";
import {INotaireGetApiResponse, INotaireIdentiteFormData} from "src/Services/Notaires.service";
import {AppConstants} from "src/Constants/AppConstants";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import bgPhoto from "src/assets/images/placeholders/picture-upload.png";
import bgSignature from "src/assets/images/placeholders/signature-upload.png";
import GroupesHelper from "src/Helpers/Groupes.helper";
import {GroupesService, IGroupesGetApiResponse} from "src/Services/Groupes.service";
import {IGroupe} from "src/Models/Groupe.model";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {ILabelGetAllApiResponse, LabelsService} from "../../../../Services/Labels.service";
import {ILabel} from "../../../../Models/Label.model";
import LabelsHelper from "../../../../Helpers/Labels.helper";
import NotairesHelper from "../../../../Helpers/Notaires.helper";


type NotaireIdentiteFormComponentProps = {
    notaire?: INotaire,
    isAncienNotaire?: boolean,
    isOnCreationStep?: boolean,

    submitMethod: (formData: INotaireIdentiteFormData) => Promise<INotaireGetApiResponse>,
    onSuccessSubmit: (response: INotaireGetApiResponse) => void,
    onSuccessSubmitNext?: (response: INotaireGetApiResponse) => void,
    onCancel: () => void,
    onUpdateFieldButtonClick?: () => void,
}

export default function NotaireIdentiteFormComponent(props: NotaireIdentiteFormComponentProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [groupes, setGroupes] = useState<IGroupe[]>([]);
    const [groupesLoaded, setGroupesLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();
    const titleRef = useRef(null);
    const [labels, setLabels] = useState<ILabel[]>([]);
    const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false);

    const errorScroll = () => titleRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'nearest'
    })


    useEffect(() => {
        const groupesServices: GroupesService = new GroupesService();
        groupesServices.getAllNonAutomatique().then((response:IGroupesGetApiResponse) => {
            if(response && response.datas && response.datas.groupes){
                setGroupes(response.datas.groupes);
                setGroupesLoaded(true);
            }
        });


        const labelsService: LabelsService = new LabelsService();
        labelsService.getAllForModule('notaire').then((response: ILabelGetAllApiResponse) => {
            if( response && response.datas && response.datas.labels){
                setLabels(response.datas.labels);
                setLabelsLoaded(true);
            }
        });

    }, [])

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const notaire: INotaire = props.notaire;


        const labelsIds: string[] = [];
        if(notaire && notaire.labels){
            for(let certif of notaire.labels){
                labelsIds.push(certif.id.toString());
            }
        }


        let formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.nom ? notaire.nom : "",
            },
            {
                type: 'select',
                fieldName: "sexe",

                label: "Sexe",
                placeholder: "Sexe",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: AppConstants.sexeOptions,
                hideSearchOnMultiple: true,

                oldValue: notaire && notaire.sexe ? notaire.sexe : "",
            },
            {
                type: 'date',
                fieldName: "dateNaissance",

                label: "Date de naissance",
                placeholder: "Date de naissance",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.dateNaissance ? notaire.dateNaissance : "",
            },
            {
                type: 'date',
                fieldName: "dateSermentInitial",

                label: "Date serment initial",
                placeholder: "Date serment initial",
                required: props.isAncienNotaire ? false :  true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.dateSermentInitial ? notaire.dateSermentInitial : "",
            },
            {
                type: 'date',
                fieldName: "dateNominationJournalOfficiel",

                label: "Date JO (1e nomination)",
                placeholder: "Date JO (1e nomination)",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.dateNominationJournalOfficiel ? notaire.dateNominationJournalOfficiel : "",
            },
            {
                type: 'selectMultiple',
                fieldName: "labels",

                label: "Labels",

                placeholder: "Labels",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: labels ? LabelsHelper.formatListForOptions(labels) : [],

                oldValues: notaire && notaire.labels ? labelsIds : []
            },
            {
                type: 'radio',
                fieldName: "agrementInspection",

                label: "Agrément inspection",
                placeholder: "Agrément inspection",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: notaire && notaire.agrementInspection ? notaire.agrementInspection : "",
            },
            {
                type: 'selectMultiple',
                fieldName: "groupes",

                label: "Groupes",
                placeholder: "Groupes",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                //Les groupes automatique ne doivent pas être présent dans la liste déroulante
                //Leur gestion est automatique à l'enregistrement d'un notaire
                oldValues: notaire && notaire.groupes ? GroupesHelper.getIdsFromListWithoutAutomatique(notaire.groupes) : [],
                oldValuesReadOnly: notaire && notaire.groupes ? GroupesHelper.getNamesFromListAutomatique(notaire.groupes) : [],

                options: groupes ? GroupesHelper.formatListForOptions(groupes): []
            },
        ];
        let formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "prenom",

                label: "Prénom",
                placeholder: "Prénom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.prenom ? notaire.prenom : "",
            },
            {
                type: 'updateFieldButton',
                label: "Type de notaire",
                oldValue: notaire && notaire.type ? NotairesHelper.getFormattedType(notaire.type): "",
                //labelModificators: "Modifier",
                action: () => {
                    if(props.onUpdateFieldButtonClick){
                        props.onUpdateFieldButtonClick();
                    }
                }
            },
            {
                type: 'text',
                fieldName: "lieuNaissance",

                label: "Lieu de naissance",
                placeholder: "Lieu de naissance",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.lieuNaissance ? notaire.lieuNaissance : "",
            },
            {
                type: 'date',
                fieldName: "dateDernierSerment",

                label: "Date du dernier serment",
                placeholder: "Date du dernier serment",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.dateDernierSerment ? notaire.dateDernierSerment : "",
            },
            {
                type: 'text',
                fieldName: "CSN",

                label: "N° CSN",
                placeholder: "N° CSN",
                required: props.isAncienNotaire ? false :  true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.CSN ? notaire.CSN : "",
            },
            {
                type: 'selectMultiple',
                fieldName: "languesParlees",

                label: "Langue(s) parlée(s)",
                placeholder: "Langue(s) parlée(s)",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: NotairesConstants.languesParleesOptions,

                oldValues: notaire && notaire.languesParlees ? notaire.languesParlees : [],
            },
            {
                type: 'radio',
                fieldName: "agrementComptabilite",

                label: "Agrément comptabilité",
                placeholder: "Agrément comptabilité",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: notaire && notaire.agrementComptabilite ? notaire.agrementComptabilite : "",
            },
            {
                type: 'text',
                fieldName: "siret",

                label: "N° Siret",
                placeholder: "N° Siret",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: notaire && notaire.siret ? notaire.siret : "",
            },
        ];


        const formElementsColumn3: FormElement[] = [
            {
                type: 'selectMultiple',
                fieldName: "fonctions",

                label: "Fonction(s)",
                placeholder: "Fonction(s)",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                optionsGroup: NotairesConstants.fonctionsOptions,
                hideCheckAllGroupOptions: true,

                oldValues: notaire && notaire.fonctions ? notaire.fonctions : [],
            },
            {
                type: 'file',
                fieldName: "photo",

                label: "Photo",
                placeholder: "Photo",
                required: false,
                background: bgPhoto,
                showImage: true,
                showImageDeleteButton: true,

                oldValue: notaire && notaire.photo ? notaire.photo : "",
            },
            {
                type: 'file',
                fieldName: "signature",

                label: "Signature",
                placeholder: "Signature",
                background: bgSignature,
                showImage: true,
                showImageDeleteButton: true,
                required: false,

                oldValue: notaire && notaire.signature ? notaire.signature : "",
            }
        ];

        //Dans le cas d'un notaire en éxercice, les champs dateRetrait et dateHonorariat ne doivent pas être affichés
        if(!props.isAncienNotaire){
            formElementsColumn1 = formElementsColumn1.filter((e) => e.fieldName !== 'dateHonorariat');
            formElementsColumn2 = formElementsColumn2.filter((e) => e.fieldName !== 'dateRetrait');
            formElementsColumn2 = formElementsColumn2.filter((e) => e.fieldName !== 'decede');
        }


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            }
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    },[props.notaire, groupes, labels]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: INotaireGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les données du notaire ont bien été mises à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
                errorScroll();
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de vos informations personnelles.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div ref={titleRef} className="form-wrapper -edit">
            {
                groupesLoaded && labelsLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        errorFieldsMessages={errorFieldsMessages}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorMessages={errorMessages} />
            }
        </div>
    )
}
