import React, {useEffect} from "react";
import "leaflet/dist/leaflet.css";
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import marker from "../../assets/images/marker.png";
import markerRetina from "../../assets/images/marker@2x.png";
import L, {latLngBounds, MarkerCluster} from 'leaflet';
import Parser from 'html-react-parser';
import MarkerClusterGroup from 'react-leaflet-cluster';

export type MapMarker = {
    lat: number,
    lng: number,
    text?: string
}

interface IChangeView {
    center: MapMarker;
    markers: MapMarker[];
}

/**
 * Composant créé pour accéder à la carte car le hook useMap ne peut pas être utilisé en dehors d'un MapContainer
 * @param {IChangeView} props
 * @returns {null | any}
 * @constructor
 */
const ChangeView = (props: IChangeView) => {
    const map = useMap();
    map.setView({lng: props.center.lng, lat: props.center.lat}, 13);

    if(!props.markers.length) return null;

    let markerBounds = latLngBounds([]);
    props.markers.forEach(marker => {
        markerBounds.extend([marker.lat, marker.lng])
    });
    map.fitBounds(markerBounds);
    return <></>;
};

const MapComponent = (props: {
    markers?: MapMarker[]
}) => {

    useEffect(() => {
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: markerRetina,
            iconUrl: marker,
            shadowUrl: null,
            iconSize:     [24, 32],
            iconAnchor:   [12, 32]
        });

    }, [props]);

    // NOTE: iconCreateFunction is running by leaflet, which is not support ES6 arrow func syntax
    // eslint-disable-next-line
    const createClusterCustomIcon = function (cluster: MarkerCluster) {
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: 'map-marker-cluster',
            iconSize: L.point(36, 36, true),
        })
    };

    // Coordonnées de Caen
    return <MapContainer zoom={13} scrollWheelZoom={false}>
        <TileLayer
            attribution='Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.'
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        <ChangeView center={{lat:49.182863,lng: -0.370679}} markers={props.markers} />
        <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
            {props.markers && props.markers.map((m,mIndex)=>{
                return <Marker position={[m.lat, m.lng]} key={mIndex}>
                    <Popup>
                        {Parser(m.text)}
                    </Popup>
                </Marker>})}
        </MarkerClusterGroup>
    </MapContainer>;
};

export default MapComponent;