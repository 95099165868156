import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {useHistory, useLocation} from "react-router";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import {ITableauBord} from "src/Models/TableauBord.model";
import {ITableauxBordGetFormData, ITableauxBordGetApiResponse, TableauxBordService, ITableauBordGetApiResponse, ITableauBordUploadFormData} from "src/Services/TableauxBord.service";
import SelectMultipleFieldComponent from "src/Components/Fields/SelectMultiple.field.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import FileHelper from "src/Helpers/File.helper";
import FileUploadField from "src/Components/Fields/FileUpload.field";
import {IDocument} from "src/Models/Document.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {Link} from "react-router-dom";
import CoordonneesHelper from "../../Helpers/Coordonnees.helper";
import LoadingComponent from "../../Components/Loading/Loading.component";

type ITableauBordsListScreenProps = {
};

export default function TableauBordsListScreen(props: ITableauBordsListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);

    const [filters,initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathTableauBord);
    const [tableauxBord, setTableauxBord] = useState<ITableauBord[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [resumeObjectifsFichier, setResumeObjectifsFichier] = useState<number>(0);
    const [resumeNombreFichiers, setResumeNombreFichiers] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Tableaux de bord" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);


    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);



    /**
     * Récupération de la liste des notaires pouvant avoir des attestations urssaf
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const tableauxBordService: TableauxBordService = new TableauxBordService();
        tableauxBordService.getTableauxBord(filters as ITableauxBordGetFormData).then((response: ITableauxBordGetApiResponse) => {
            if (response && response.datas.pagination) {

                // Notaires
                const currentList: ITableauBord[] = response.datas.pagination.data;
                setTableauxBord(currentList);

                if (!response.datas.pagination.countTotalFichierRecus && !response.datas.pagination.countTotalFichiers) {
                    setResumeNombreFichiers(0);
                    setResumeObjectifsFichier(0);
                } else {
                    setResumeNombreFichiers(response.datas.pagination.countTotalFichierRecus);
                    setResumeObjectifsFichier(response.datas.pagination.countTotalFichiers);
                }

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }


    /**
     * Upload d'un document de tableau de bord pour un office
     *
     * @param {ITableauBord} tableauBord
     * @param {IDocument} document
     * @param {File} fichier
     */
    const uploadFile = (tableauBord: ITableauBord, document: IDocument, fichier:File): void => {
        const tableauxBordService: TableauxBordService = new TableauxBordService();
        const formData: ITableauBordUploadFormData = {
            fichier: fichier,
            CRPCEN: tableauBord.office ? tableauBord.office.CRPCEN : null,
        }

        tableauxBordService.uploadTableauBord(tableauBord.id, tableauBord.office.id,document.id, formData).then((response: ITableauBordGetApiResponse) => {
            launchFilteredSearch();
        }, (error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors l'ajout de l'ajout du tableau de bord de l'office.",
                    type: "danger"
                });
            }
        });
    }

    const getDateTableHeader = ():string => {
        const lastMonth: string = DateHelper.getLastMonth();
        const year = (lastMonth.toLowerCase() == "décembre") ? DateHelper.getLastYear() : DateHelper.getCurrentYear();

        return `${lastMonth} ${year}`;
    }



    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Tableau de bord"
                    icon="icon-tableau-bord"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu" hideTabHeader={true}>
                                <div className="list-content">

                                    <div className="list-resume">
                                        <div className="column">
                                            <p className="line">
                                                <span className="title">{`${getDateTableHeader()}`}</span>
                                            </p>
                                        </div>
                                        <div className="column -double">
                                            <p className="line">
                                                <span className="title">Tableaux de bord reçus:&nbsp;</span>
                                                <span className="value">{resumeNombreFichiers}</span>
                                            </p>
                                            <p className="line">
                                                <span className="title">Tableaux de bord à recevoir:&nbsp;</span>
                                                <span className="value">{resumeObjectifsFichier - resumeNombreFichiers}</span>
                                            </p>
                                            <p className="line">
                                                <span className="title">Total:&nbsp;</span>
                                                <span className="value">{resumeObjectifsFichier}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`} />
                                        <SelectMultipleFieldComponent fieldName="departement"
                                              label="Départements"
                                              options={OfficesConstants.departementsOptions}
                                              hideCurrentValuesOnTop={true}
                                              errors={null}
                                              placeholder="Tous les départements"
                                              hideSearch={true}
                                              modificators={`-inline -on-white`}
                                              oldValues={filters.departement ? filters.departement as string[] : []}
                                              onChange={(values: string[]) => updateFilters({departement: values})}
                                        />
                                    </div>

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "CRPCEN", label: "CRPCEN"},
                                                    {slug: "nomOffice", label: "Office"},
                                                    {slug: "emailsComptables", label: "Email comptablilité"},
                                                    {slug: "dernierFichier", label: "Dernier PDF Reçu"},
                                                    {slug: "fichierMoisEnCours", label: `Fichier ${getDateTableHeader()} `},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            tableauxBord && tableauxBord.map((tableauBord: ITableauBord, index: number) =>
                                                <tr key={index} className="tr">
                                                    <td className="td -bold -w85px"  onClick={() => history.push(`${AppConstants.pathTableauBord}/${tableauBord.id}`)} >
                                                        {tableauBord.office.CRPCEN}
                                                    </td>
                                                    <td className="td" onClick={() => history.push(`${AppConstants.pathTableauBord}/${tableauBord.id}`)} >

                                                        <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${tableauBord.office.id}`} onClick={(e) => e.stopPropagation()}>{tableauBord.office.nom}</Link>

                                                        {
                                                            tableauBord.office.adressePrincipale && tableauBord.office.adressePrincipale.ville &&
                                                                <>
                                                                    <br/>{tableauBord.office.adressePrincipale.ville}
                                                                </>
                                                        }
                                                        {
                                                            tableauBord.office.telephonePrincipal &&
                                                                <>
                                                                    <br/>{ CoordonneesHelper.formatPhoneNumberForDisplay(tableauBord.office.telephonePrincipal)}
                                                                </>
                                                        }
                                                    </td>
                                                    <td className="td -w500px" onClick={() => history.push(`${AppConstants.pathTableauBord}/${tableauBord.id}`)} >
                                                        { Array.isArray(tableauBord.office.emailsComptables) ? tableauBord.office.emailsComptables.join(', ') : tableauBord.office.emailsComptables}
                                                    </td>
                                                    <td className="td -w150px -uppercase" onClick={() => history.push(`${AppConstants.pathTableauBord}/${tableauBord.id}`)} >
                                                        {tableauBord.dernierTableauComplet ?  DateHelper.getFormatedDateForTableauBord(tableauBord.dernierTableauComplet.date) : "-"}
                                                    </td>
                                                    <td className="td -w300px">
                                                        {
                                                            tableauBord.tableauEnCours && tableauBord.tableauEnCours.fichier &&
                                                            <div className="flex-wrapper">
                                                                <span className={`state-bullet -before-element icon-bullet -actif`} />
                                                                <DocumentLink modificators={"-table"} link={FileHelper.getFileUrl(tableauBord.tableauEnCours.fichier)} />
                                                            </div>
                                                        }
                                                        {
                                                            tableauBord.tableauEnCours && !tableauBord.tableauEnCours.fichier &&
                                                            <div className="flex-wrapper">
                                                                <span className={`state-bullet  -before-element icon-bullet -annule`} />
                                                                <FileUploadField
                                                                    fieldName="fichier"
                                                                    oldValue=""
                                                                    onChange={(f: File) => uploadFile(tableauBord, tableauBord.tableauEnCours, f)}
                                                                    background=""
                                                                    modificators='-inline'
                                                                    hideUploadLabelIfFile={true}
                                                                    uploadLabel="Uploader"
                                                                    extensions={['.pdf']}
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>

                                    </table>


                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    )
}
