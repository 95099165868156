import {IOption} from "src/Components/Fields/Select.field.component";

export type IAdresseConstants = {
    typeOptions: IOption[],
    arrondissementsOptions: IOption[],
    tribunalJudiciaireOptions: IOption[],
    tribunalJudiciaireOptionsFull: IOption[],
}
export const AdresseConstants: IAdresseConstants = {
    typeOptions: [
        {
            value: "principale",
            label: "Adresse Principale"
        },
        {
            value: "postale",
            label: "Adresse Postale"
        },
        {
            value: "autre",
            label: "Autre adresse"
        },
    ],

    arrondissementsOptions: [
        {
            value: "bayeux",
            label: "Bayeux"
        },
        {
            value: "caen",
            label: "Caen"
        },
        {
            value: "argentan",
            label: "Argentan - Flers - Falaise"
        },
        {
            value: "lisieux",
            label: "Lisieux - Pont l’Evêque"
        },
        {
            value: "vire",
            label: "Vire"
        },
        {
            value: "avranche",
            label: "Avranches - Mortain"
        },
        {
            value: "saintLo",
            label: "Saint-Lô - Coutances"
        },
        {
            value: "alencon",
            label: "Alençon - Mortagne"
        },
        {
            value: "cherbourg",
            label: "Cherbourg - Valognes"
        },
    ],

    tribunalJudiciaireOptions: [
        {
            value: "100151",
            label: "Tribunal Judiciaire d'Alençon"
        },
        {
            value: "100152",
            label: "Tribunal Judiciaire d'Argentan"
        },
        {
            value: "100061",
            label: "Tribunal Judiciaire de Caen"
        },
        {
            value: "100125",
            label: "Tribunal Judiciaire de Cherbourg-en-Cotentin"
        },
        {
            value: "100126",
            label: "Tribunal Judiciaire de Coutances"
        },
        {
            value: "100062",
            label: "Tribunal Judiciaire de Lisieux"
        },
    ],

    tribunalJudiciaireOptionsFull: [
        {
            value: "100119",
            label: "Tribunal Judiciaire d'Agen"
        },
        {
            value: "100058",
            label: "Tribunal Judiciaire d'Aix En Provence"
        },
        {
            value: "100071",
            label: "Tribunal Judiciaire d'Ajaccio"
        },
        {
            value: "100174",
            label: "Tribunal Judiciaire d'Albertville"
        },
        {
            value: "100192",
            label: "Tribunal Judiciaire d'Albi"
        },
        {
            value: "100151",
            label: "Tribunal Judiciaire d'Alençon"
        },
        {
            value: "100089",
            label: "Tribunal Judiciaire d'Ales"
        },
        {
            value: "100190",
            label: "Tribunal Judiciaire d'Amiens"
        },
        {
            value: "100122",
            label: "Tribunal Judiciaire d'Angers"
        },
        {
            value: "100064",
            label: "Tribunal Judiciaire d'Angoulême"
        },
        {
            value: "100176",
            label: "Tribunal Judiciaire d'Annecy"
        },
        {
            value: "100152",
            label: "Tribunal Judiciaire d'Argentan"
        },
        {
            value: "100153",
            label: "Tribunal Judiciaire d'Arras"
        },
        {
            value: "100093",
            label: "Tribunal Judiciaire d'Auch"
        },
        {
            value: "100063",
            label: "Tribunal Judiciaire d'Aurillac"
        },
        {
            value: "100205",
            label: "Tribunal Judiciaire d'Auxerre"
        },
        {
            value: "100141",
            label: "Tribunal Judiciaire d'Avesnes-sur-Helpe"
        },
        {
            value: "100197",
            label: "Tribunal Judiciaire d'Avignon"
        },
        {
            value: "100133",
            label: "Tribunal Judiciaire de Bar-le-duc"
        },
        {
            value: "100213",
            label: "Tribunal Judiciaire de Basse-terre"
        },
        {
            value: "100072",
            label: "Tribunal Judiciaire de Bastia"
        },
        {
            value: "100159",
            label: "Tribunal Judiciaire de Bayonne"
        },
        {
            value: "100148",
            label: "Tribunal Judiciaire de Beauvais"
        },
        {
            value: "100207",
            label: "Tribunal Judiciaire de Belfort"
        },
        {
            value: "100078",
            label: "Tribunal Judiciaire de Bergerac"
        },
        {
            value: "100080",
            label: "Tribunal Judiciaire de Besançon"
        },
        {
            value: "100154",
            label: "Tribunal Judiciaire de Bethune"
        },
        {
            value: "100096",
            label: "Tribunal Judiciaire de Beziers"
        },
        {
            value: "100109",
            label: "Tribunal Judiciaire de Blois"
        },
        {
            value: "100210",
            label: "Tribunal Judiciaire de Bobigny"
        },
        {
            value: "100177",
            label: "Tribunal Judiciaire de Bonneville"
        },
        {
            value: "100094",
            label: "Tribunal Judiciaire de Bordeaux"
        },
        {
            value: "100155",
            label: "Tribunal Judiciaire de Boulogne-sur-mer"
        },
        {
            value: "100039",
            label: "Tribunal Judiciaire de Bourg en Bresse"
        },
        {
            value: "100068",
            label: "Tribunal Judiciaire de Bourges"
        },
        {
            value: "100102",
            label: "Tribunal Judiciaire de Bourgoin Jallieu"
        },
        {
            value: "100086",
            label: "Tribunal Judiciaire de Brest"
        },
        {
            value: "100069",
            label: "Tribunal Judiciaire de Brive la Gaillarde"
        },
        {
            value: "100061",
            label: "Tribunal Judiciaire de Caen"
        },
        {
            value: "100118",
            label: "Tribunal Judiciaire de Cahors"
        },
        {
            value: "100142",
            label: "Tribunal Judiciaire de Cambrai"
        },
        {
            value: "100054",
            label: "Tribunal Judiciaire de Carcassonne"
        },
        {
            value: "100198",
            label: "Tribunal Judiciaire de Carpentras"
        },
        {
            value: "100193",
            label: "Tribunal Judiciaire de Castres"
        },
        {
            value: "100216",
            label: "Tribunal Judiciaire de Cayenne"
        },
        {
            value: "100171",
            label: "Tribunal Judiciaire de Chalon sur Saone"
        },
        {
            value: "100127",
            label: "Tribunal Judiciaire de Chalons en Champagne"
        },
        {
            value: "100175",
            label: "Tribunal Judiciaire de Chambéry"
        },
        {
            value: "100051",
            label: "Tribunal Judiciaire de Charleville-Mézières"
        },
        {
            value: "100085",
            label: "Tribunal Judiciaire de Chartres"
        },
        {
            value: "100100",
            label: "Tribunal Judiciaire de Chateauroux"
        },
        {
            value: "100129",
            label: "Tribunal Judiciaire de Chaumont"
        },
        {
            value: "100125",
            label: "Tribunal Judiciaire de Cherbourg-en-Cotentin"
        },
        {
            value: "100157",
            label: "Tribunal Judiciaire de Clermont-Ferrand"
        },
        {
            value: "100165",
            label: "Tribunal Judiciaire de Colmar"
        },
        {
            value: "100149",
            label: "Tribunal Judiciaire de Compiègne"
        },
        {
            value: "100126",
            label: "Tribunal Judiciaire de Coutances"
        },
        {
            value: "100211",
            label: "Tribunal Judiciaire de Créteil"
        },
        {
            value: "100043",
            label: "Tribunal Judiciaire de Cusset"
        },
        {
            value: "100107",
            label: "Tribunal Judiciaire de Dax"
        },
        {
            value: "100180",
            label: "Tribunal Judiciaire de Dieppe"
        },
        {
            value: "100046",
            label: "Tribunal Judiciaire de Digne les Bains"
        },
        {
            value: "100073",
            label: "Tribunal Judiciaire de Dijon"
        },
        {
            value: "100143",
            label: "Tribunal Judiciaire de Douai"
        },
        {
            value: "100195",
            label: "Tribunal Judiciaire de Draguignan"
        },
        {
            value: "100144",
            label: "Tribunal Judiciaire de Dunkerque"
        },
        {
            value: "100203",
            label: "Tribunal Judiciaire d'Epinal"
        },
        {
            value: "100084",
            label: "Tribunal Judiciaire d'Evreux"
        },
        {
            value: "100208",
            label: "Tribunal Judiciaire d'Évry-Courcouronnes"
        },
        {
            value: "100052",
            label: "Tribunal Judiciaire de Foix"
        },
        {
            value: "100183",
            label: "Tribunal Judiciaire de Fontainebleau"
        },
        {
            value: "100215",
            label: "Tribunal Judiciaire de Fort de France"
        },
        {
            value: "100047",
            label: "Tribunal Judiciaire de Gap"
        },
        {
            value: "100048",
            label: "Tribunal Judiciaire de Grasse"
        },
        {
            value: "100103",
            label: "Tribunal Judiciaire de Grenoble"
        },
        {
            value: "100077",
            label: "Tribunal Judiciaire de Gueret"
        },
        {
            value: "100199",
            label: "Tribunal Judiciaire de La Roche sur Yon"
        },
        {
            value: "100066",
            label: "Tribunal Judiciaire de La Rochelle"
        },
        {
            value: "100040",
            label: "Tribunal Judiciaire de Laon"
        },
        {
            value: "100130",
            label: "Tribunal Judiciaire de Laval"
        },
        {
            value: "100181",
            label: "Tribunal Judiciaire du Havre"
        },
        {
            value: "100173",
            label: "Tribunal Judiciaire du Mans"
        },
        {
            value: "100113",
            label: "Tribunal Judiciaire de Puy en Velay"
        },
        {
            value: "100095",
            label: "Tribunal Judiciaire de Libourne"
        },
        {
            value: "100146",
            label: "Tribunal Judiciaire de Lille"
        },
        {
            value: "100202",
            label: "Tribunal Judiciaire de Limoges"
        },
        {
            value: "100062",
            label: "Tribunal Judiciaire de Lisieux"
        },
        {
            value: "100106",
            label: "Tribunal Judiciaire de Lons le Saunier"
        },
        {
            value: "100135",
            label: "Tribunal Judiciaire de Lorient"
        },
        {
            value: "100167",
            label: "Tribunal Judiciaire de Lyon"
        },
        {
            value: "100172",
            label: "Tribunal Judiciaire de Macon"
        },
        {
            value: "941809",
            label: "Tribunal Judiciaire de Mamoudzou"
        },
        {
            value: "100059",
            label: "Tribunal Judiciaire de Marseille"
        },
        {
            value: "100184",
            label: "Tribunal Judiciaire de Meaux"
        },
        {
            value: "100185",
            label: "Tribunal Judiciaire de Melun"
        },
        {
            value: "100121",
            label: "Tribunal Judiciaire de Mende"
        },
        {
            value: "100137",
            label: "Tribunal Judiciaire de Metz"
        },
        {
            value: "100108",
            label: "Tribunal Judiciaire de Mont de Marsan"
        },
        {
            value: "100116",
            label: "Tribunal Judiciaire de Montargis"
        },
        {
            value: "100194",
            label: "Tribunal Judiciaire de Montauban"
        },
        {
            value: "100081",
            label: "Tribunal Judiciaire de Montbeliard"
        },
        {
            value: "100044",
            label: "Tribunal Judiciaire de Montluçon"
        },
        {
            value: "100097",
            label: "Tribunal Judiciaire de Montpellier"
        },
        {
            value: "100045",
            label: "Tribunal Judiciaire de Moulins"
        },
        {
            value: "100166",
            label: "Tribunal Judiciaire de Mulhouse"
        },
        {
            value: "100132",
            label: "Tribunal Judiciaire de Nançy"
        },
        {
            value: "100209",
            label: "Tribunal Judiciaire de Nanterre"
        },
        {
            value: "100114",
            label: "Tribunal Judiciaire de Nantes"
        },
        {
            value: "100055",
            label: "Tribunal Judiciaire de Narbonne"
        },
        {
            value: "100140",
            label: "Tribunal Judiciaire de Nevers"
        },
        {
            value: "100049",
            label: "Tribunal Judiciaire de Nice"
        },
        {
            value: "100090",
            label: "Tribunal Judiciaire de Nîmes"
        },
        {
            value: "100188",
            label: "Tribunal Judiciaire de Niort"
        },
        {
            value: "100117",
            label: "Tribunal Judiciaire d'Orléans"
        },
        {
            value: "915273",
            label: "Tribunal Judiciaire de Paris"
        },
        {
            value: "100160",
            label: "Tribunal Judiciaire de Pau"
        },
        {
            value: "100079",
            label: "Tribunal Judiciaire de Périgueux"
        },
        {
            value: "100162",
            label: "Tribunal Judiciaire de Perpignan"
        },
        {
            value: "100214",
            label: "Tribunal Judiciaire de Pointe à Pitre"
        },
        {
            value: "100201",
            label: "Tribunal Judiciaire de Poitiers"
        },
        {
            value: "100212",
            label: "Tribunal Judiciaire de Pointoise"
        },
        {
            value: "100050",
            label: "Tribunal Judiciaire de Privas"
        },
        {
            value: "100088",
            label: "Tribunal Judiciaire de Quimper"
        },
        {
            value: "100128",
            label: "Tribunal Judiciaire de Reims"
        },
        {
            value: "100098",
            label: "Tribunal Judiciaire de Rennes"
        },
        {
            value: "100111",
            label: "Tribunal Judiciaire de Roanne"
        },
        {
            value: "100057",
            label: "Tribunal Judiciaire de Rodez"
        },
        {
            value: "100182",
            label: "Tribunal Judiciaire de Rouen"
        },
        {
            value: "100200",
            label: "Tribunal Judiciaire des Sables-d'Olonne"
        },
        {
            value: "100067",
            label: "Tribunal Judiciaire de Saintes"
        },
        {
            value: "100138",
            label: "Tribunal Judiciaire de Sarreguemines"
        },
        {
            value: "948312",
            label: "Tribunal Judiciaire de Saumur"
        },
        {
            value: "100163",
            label: "Tribunal Judiciaire de Saverne"
        },
        {
            value: "100150",
            label: "Tribunal Judiciaire de Senlis"
        },
        {
            value: "100206",
            label: "Tribunal Judiciaire de Sens"
        },
        {
            value: "100042",
            label: "Tribunal Judiciaire de Soissons"
        },
        {
            value: "100076",
            label: "Tribunal Judiciaire de Saint-Brieuc"
        },
        {
            value: "100217",
            label: "Tribunal Judiciaire de Saint-Denis"
        },
        {
            value: "100112",
            label: "Tribunal Judiciaire de Saint-Etienne"
        },
        {
            value: "948313",
            label: "Tribunal Judiciaire de Saint-Gaudens"
        },
        {
            value: "100099",
            label: "Tribunal Judiciaire de Saint-Malo"
        },
        {
            value: "100115",
            label: "Tribunal Judiciaire de Saint-Nazaire"
        },
        {
            value: "100156",
            label: "Tribunal Judiciaire de Saint-Omer"
        },
        {
            value: "100218",
            label: "Tribunal Judiciaire de Saint-Pierre"
        },
        {
            value: "100041",
            label: "Tribunal Judiciaire de Saint-Quentin"
        },
        {
            value: "100164",
            label: "Tribunal Judiciaire de Strasbourg"
        },
        {
            value: "100060",
            label: "Tribunal Judiciaire de Tarascon"
        },
        {
            value: "100161",
            label: "Tribunal Judiciaire de Tarbes"
        },
        {
            value: "100139",
            label: "Tribunal Judiciaire de Thionville"
        },
        {
            value: "100178",
            label: "Tribunal Judiciaire de Thonons les Bains"
        },
        {
            value: "100196",
            label: "Tribunal Judiciaire de Toulon"
        },
        {
            value: "100092",
            label: "Tribunal Judiciaire de Toulouse"
        },
        {
            value: "100101",
            label: "Tribunal Judiciaire de Tours"
        },
        {
            value: "100053",
            label: "Tribunal Judiciaire de Troyes"
        },
        {
            value: "948314",
            label: "Tribunal Judiciaire de Tulle"
        },
        {
            value: "100131",
            label: "Tribunal Judiciaire de Val de Briey"
        },
        {
            value: "100082",
            label: "Tribunal Judiciaire de Valence"
        },
        {
            value: "100147",
            label: "Tribunal Judiciaire de Valenciennes"
        },
        {
            value: "100136",
            label: "Tribunal Judiciaire de Vannes"
        },
        {
            value: "100134",
            label: "Tribunal Judiciaire de Verdun"
        },
        {
            value: "100186",
            label: "Tribunal Judiciaire de Versailles"
        },
        {
            value: "100170",
            label: "Tribunal Judiciaire de Vesoul"
        },
        {
            value: "100104",
            label: "Tribunal Judiciaire de Vienne"
        },
        {
            value: "100168",
            label: "Tribunal Judiciaire de Villefranche sur Saone"
        },
    ]
};
