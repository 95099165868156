import {IPersonneExterieure} from "../Models/PersonneExterieure.model";
import {PersonneExterieureConstants} from "../Constants/PersonneExterieureConstants";
import {IOption} from "../Components/Fields/Select.field.component";
import CommonHelper from "./Common.helper";
import {AppConstants} from "../Constants/AppConstants";

export default class PersonnesExterieuresHelper{

    /**
     * Retourne l'adresse principale d'une organisme
     * @param {IPersonneExterieure} pe
     * @returns {string}
     */
    static getMainMail(pe: IPersonneExterieure): string {
        let mail = "";

        for(let c of pe.coordonnees){
            if(c.destination === "principal"){
                mail = c.coordonnees;
            }
        }

        return mail;
    }


    /**
     * Retourne la formule de politesse formattée d'une personne
     *
     * @param {string} formule
     * @returns {string}
     */
    static getFormatedFormulePolitesse(formule: string): string {
        return CommonHelper.getFormatedElementFromOptions(formule, PersonneExterieureConstants.formulesPolitesseOptions);
    }

    /**
     * Retourne la civilité formatée d'une personne
     * @param {string} civilite
     * @returns {string}
     */
    static getFormatedCivilite(civilite: string): string{
        return CommonHelper.getFormatedElementFromOptions(civilite, PersonneExterieureConstants.civiliteOptions);
    }
    /**
     * Retourne la civilité courte formatée d'une personne
     * @param {string} civilite
     * @returns {string}
     */
    static getFormatedCiviliteCourte(civilite: string): string{
        return CommonHelper.getFormatedElementFromOptions(civilite, AppConstants.civiliteCourteOptions);
    }

    /**
     * Permet de formater une liste de personnes pour les selects
     *
     * @param {IPersonneExterieure[]} persons
     * @returns {IOption[]}
     */
    static formatPersonnesListForOptions(persons: IPersonneExterieure[]): IOption[]{
        if(!persons || !persons.length) return [];

        const options: IOption[] = [];
        for(let person of persons){
            const option: IOption = {
                value: person.id.toString(),
                label: `${person.nom} ${person.prenom}`
            };

            options.push(option)
        }

        return options;
    }

    /**
     * Permet de formater une liste de presidents pour les selects
     *
     * @param {IPersonneExterieure[]} persons
     * @returns {IOption[]}
     */
    static formatPresidentListForOptions(persons: IPersonneExterieure[]): IOption[]{
        if(!persons || !persons.length) return [];

        const options: IOption[] = [];
        for (let person of persons) {
            let label = `${person.nom} ${person.prenom}`;
            if (person.organisme) {
                label += ` - ${person.organisme.nom}`;
            }

            const option: IOption = {
                value: person.id.toString(),
                label: label
            };

            options.push(option)
        }

        return options;
    }

    /**
     * Retourne un tableau d'id à partir d'un tableau de personnes
     * @param {IPersonneExterieure[]} persons
     * @returns {string[]}
     */
    static getIdsFromPersonnesArray(persons: IPersonneExterieure[]): string[]{
        return persons.map((p)=>p.id.toString());
    }


}
