import React from "react";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import NumberHelper from "../../Helpers/Number.helper";

type ICotisationsOfficeRecapRepertoiresComponentProps = {
    cotisation: ICotisationsOffice,
}

export default function CotisationsOfficeRecapRepertoiresComponent(props: ICotisationsOfficeRecapRepertoiresComponentProps) {
    const cotisation: ICotisationsOffice = props.cotisation;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Montant</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.montantRepertoires, 2,2)} €</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Nombre</p>
                        <p className="value">{NumberHelper.formatThousand(cotisation.nombreRepertoires, 2,2)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
