import React from "react";
import {IFormation} from "../../../Models/Formation.model";


const FormationPrecisions = (props: {
    formation: IFormation
}) => {

    return <div className="informations-wrapper">
        <div className="columns">
            <div className="column">
                <div className="informations-block">
                    <p className="label">Covoiturage</p>
                    <p className="value">{props.formation.covoiturage}</p>
                </div>
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Repas</p>
                    <p className="value">
                        {props.formation.repas}
                    </p>
                </div>
                {props.formation.repas === "oui" && <>
	                <div className="informations-block">
		                <p className="label">Nom du repas</p>
		                <p className="value">{props.formation.repasNom}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Lieu du repas</p>
		                <p className="value">{props.formation.repasLieu}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Heure du repas</p>
		                <p className="value">{props.formation.repasHeure}</p>
	                </div>
                </>}
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Cocktail</p>
                    <p className="value">{props.formation.cocktail}</p>
                </div>
                {props.formation.cocktail === "oui" && <>
	                <div className="informations-block">
		                <p className="label">Nom du cocktail</p>
		                <p className="value">{props.formation.cocktailNom}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Lieu du cocktail</p>
		                <p className="value">{props.formation.cocktailLieu}</p>
	                </div>
	                <div className="informations-block">
		                <p className="label">Heure du cocktail</p>
		                <p className="value">{props.formation.cocktailHeure}</p>
	                </div>
	            </>}
            </div>
        </div>

    </div>

};

export default FormationPrecisions;
