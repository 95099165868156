import {IOffice} from "../Models/Office.model";
import {StatistiquesConstants} from "../Constants/StatistiquesConstants";
import {IStatistiques} from "../Models/Statistiques.model";
import CommonHelper from "./Common.helper";

export default class StatistiquesHelper{
    /**
     * Permet de savoir si un office est soumis à l'impot sur les sociétés
     *
     * @param {IOffice} office
     * @param {IStatistiques[]} stats
     * @returns {boolean}
     */
    static isOfficeWithImpotsSociete(office: IOffice, stats: IStatistiques[]): boolean{
        if(!office) return false;

        //L'office possède directement un type qui définit son type d'impot
        if(StatistiquesConstants.typesImpotsSocietes.includes(office.type)){
            return true;
        }

        //Dans le cas ou le type de l'office indique pas implicitement qu'il est soumis à l'IS
        //On regarde les stats si au moins une année, le régime de l'office correspond à l'IS
        for(let s of stats){
            const typeRegime = CommonHelper.getObjectValueFromPath(s,"data.dap.regime_fiscal");

            if(typeRegime === 'IS'){
                return true;
            }
        }

        return false;
    }
}
