import {useEffect, useState} from "react";
import {AppConstants} from "../Constants/AppConstants";
import * as queryString from "querystring";
import {useHistory} from "react-router";

export type IFilterData = {
    [key: string]: string | string[] | number
}

const useListFilters = (defaultPath: string, pushHistory: boolean = true) => {
    const history = useHistory();
    const defaultFilters: IFilterData = {
        page: 1,
        advanced: 0
    };
    const [path,setPath] = useState(defaultPath);
    const [bypassPushHistory,setByPassPushHistory] = useState<boolean>(false);
    const [filters, setFilters] = useState<IFilterData>(null);

    //Mise à jour des filtres
    useEffect(()=>{
        if(filters) {
            if (!bypassPushHistory && pushHistory) {
                history.push(getPathWithFilters());
            }

            //Dans le cas d'un bypass, on reset l'état
            if (bypassPushHistory) {
                setByPassPushHistory(false);
            }
        }
    },[filters]);


    /**
     * Mise à jour des filtres
     *
     * @param {IFilterData} data
     * @param {boolean} reset
     * @param {boolean} doBypassPushHistory
     */
    const updateFilters = (data: IFilterData, reset: boolean = false, doBypassPushHistory: boolean = false): void => {
        //Dans certains cas, on doit pouvoir désactivé l'ajout à l'historique pour une mise à jour
        if(doBypassPushHistory){
            setByPassPushHistory(true);
        }

        if(reset){
            const newFilters = getCleanedFilters(data);
            setFilters(newFilters);
        }
        else{
            let newFilters = {...filters};
            newFilters.page = data.page || 1;
            newFilters = {...newFilters,...data};

            //On nettoie les filtres des valeurs vides
            const cleanedFilters = getCleanedFilters(newFilters);

            setFilters(cleanedFilters);
        }
    };

    /**
     * Récupération du patch avec les filtres
     *
     * @returns {string}
     */
    const getPathWithFilters = (): string => {
        const pathParams: string = queryString.encode(filters);
        return path + "/?" + pathParams;
    };

    /**
     * Récupération des filtres non vides
     *
     * @param {IFilterData} currentFilters
     * @returns {IFilterData}
     */
    const getCleanedFilters = (currentFilters:IFilterData): IFilterData => {
        const cleanedFilters = {...currentFilters};
        for(let [k,v] of Object.entries(cleanedFilters)){
            if((Array.isArray(v) && !v.length) || !v) delete(cleanedFilters[k]);
        }
        return cleanedFilters;
    };

    /**
     * Changement d'ordre
     *
     * @param {string} orderBy
     */
    const setOrder = (orderBy: string): void => {
        const isNewOrder: boolean = filters.orderBy !== orderBy;
        const orderType: string = (!isNewOrder && filters.orderType === AppConstants.orderType.asc) ? AppConstants.orderType.desc : AppConstants.orderType.asc;
        updateFilters({
            orderBy,
            orderType
        });
    };

    /**
     * Génère les filtres par défaut à partir des infos de l'url
     *
     * @param {URLSearchParams} params
     * @param {string[]} multichoiceFilters
     * @returns {IFilterData}
     */
    const initialFiltersFromUrlQuery = (params: URLSearchParams, multichoiceFilters: string[]): IFilterData => {
        let initialFilters: IFilterData = {};
        params.forEach((value,key)=>{
            if(typeof initialFilters[key] !== "undefined"){
                if(!Array.isArray(initialFilters[key])){
                    const oldValue = initialFilters[key];
                    (initialFilters[key] as string[]) = [oldValue as string];
                }

                (initialFilters[key] as string[]).push(value);
            }else{
                initialFilters[key] = multichoiceFilters.includes(key) && !Array.isArray(value) ? [value] : value;
            }
        });
        return initialFilters;
    };


    return [filters,initialFiltersFromUrlQuery,updateFilters,setOrder,setPath,defaultFilters] as const;
};

export default useListFilters;
