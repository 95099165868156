import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IAvisMotive} from "src/Models/AvisMotive.model";
import {IAvisMotiveFormData} from "src/Services/Inspections.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {INotaireGetAllApiResponse, NotairesService} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";


type AvisMotiveFormComponentProps = {
    avis?: IAvisMotive,
    isOnCreationStep?: boolean,

    submitMethod: (id: number, formData: IAvisMotiveFormData) => Promise<IApiCustomResponse>,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onCancel: () => void,
}

export default function AvisMotiveFormComponent(props: AvisMotiveFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notairesPresidentsVicePresidents, setNotairesPresidentsVicePresidents] = useState<INotaire[]>([]);
    const {getFormActions} = useCreationSteps();


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getPresidentsVicePresidents().then((response: INotaireGetAllApiResponse) => {
            if( response && response.datas && response.datas.notaires){
                setNotairesPresidentsVicePresidents(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const avisMotive: IAvisMotive = props.avis;

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: [
            {
                type: 'hidden',
                fieldName: "id",
                oldValue: avisMotive.id.toString(),
                modificators: "-hidden"
            },
            {
                type: 'title',
                fieldName: "annee",
                oldValue: avisMotive.annee.toString(),
                modificators: "-hidden"
            },
            {
                type: 'hidden',
                fieldName: "officeId",
                oldValue: avisMotive.office.id.toString(),
                modificators: "-hidden"
            },
            {
                type: 'date',
                fieldName: "dateSaisie",

                label: "Date de saisie",
                modificators: props.isOnCreationStep ? "-on-white" : '',
                required: true,

                oldValue: avisMotive.dateSaisie || '',
            },
            {
                type: 'select',
                fieldName: "notaireId",

                label: "Saisie par",
                modificators: props.isOnCreationStep ? "-on-white" : '',
                required: true,
                options: notairesPresidentsVicePresidents ? NotairesHelper.formatListForOptions(notairesPresidentsVicePresidents) : [],
                oldValue: avisMotive.notaire ? avisMotive.notaire.id.toString() : '',
            },
            {
                type: 'wysiwyg',
                fieldName: "text",

                label: "Texte",
                modificators:  props.isOnCreationStep ? "-on-white -wysiwyg" : "-wysiwyg",
                required: true,
                oldValue: avisMotive.text ? avisMotive.text : '',
            }
        ],
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);

    }, [props.avis, notairesPresidentsVicePresidents, notairesLoaded]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        if (!props.submitMethod) return;

        props.submitMethod(props.avis.id, formData as IAvisMotiveFormData).then((response: IApiCustomResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'avis a bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de l'avis motivé.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                notairesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        errorFieldsMessages={errorFieldsMessages}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorMessages={errorMessages}/>
            }
        </div>
    )
}
