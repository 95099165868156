import React from "react";
import LoaderComponent from "./Loader.component";

type LoadingProps = {
    message?: string,
}

export default function LoadingComponent(props:LoadingProps){
    return (
        <div className="loading-block">
            {typeof props.message !== "undefined" && <p className="message">{props.message}</p>}
            <LoaderComponent/>
        </div>
    )
}
