import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IAdressesFormData} from "src/Services/Commons.service";
import {IAdresse} from "src/Models/Adresse.model";
import {AdresseConstants} from "src/Constants/AdresseConstants";
import useCreationSteps from "../../../Hooks/useCreationSteps";
import {AppConstants} from "../../../Constants/AppConstants";


type AdressesFormComponentProps = {
    adresses?: IAdresse[],
    isOnCreationStep?: boolean,
    isForOffice?: boolean,

    relationName: string,
    relationId: number,

    submitMethod: (formData: IAdressesFormData) => Promise<IApiCustomResponse>,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onSuccessSubmitNext?: (response: IApiCustomResponse) => void,
    onCancel: () => void,
}

export default function AdressesFormComponent(props: AdressesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const adresses: IAdresse[] = props.adresses;
        const isForOffice: boolean = props.relationName === "office" || props.isForOffice;
        const isForNotaire: boolean = props.relationName === "notaire" || props.isForOffice;


        //Préparation des columns du formulaire
        let repeaterFormElementsColumn1: FormElement[] = [];
        repeaterFormElementsColumn1.push({
            type: 'radio',
            fieldName: "adresseCPVilleSepare",

            label: "Dé-lier la ville et le Code postal",
            required: false,
            modificators: props.isOnCreationStep ? "-on-white" : "",
            options: AppConstants.ouiNonOptions,
            hideSearchOnMultiple: true,

            oldValue: "",
        });
        repeaterFormElementsColumn1.push({
            type: 'text',
            fieldName: "ligne1",

            label: "Rue",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white" : '',
            showFieldErrorDetail: true,

            oldValue: "",
        });


        let repeaterFormElementsColumn2: FormElement[] = [];
        if(!isForOffice && !isForNotaire){
            repeaterFormElementsColumn2.push({
                type: 'radio',
                fieldName: "adresseEtrangere",

                label: "Adresse Etrangère",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: "",
                condition: {
                    fieldName: "adresseCPVilleSepare",
                    value: "oui",
                }
            });
        }

        repeaterFormElementsColumn2.push({
            type: 'text',
            fieldName: "ligne2",

            label: "Complément d'adresse",
            required: false,
            modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : '-skip-line-1',

            oldValue: "",
        });

        repeaterFormElementsColumn2.push({
            type: 'text',
            fieldName: "boitePostale",

            label: "Boîte Postale",
            modificators: props.isOnCreationStep ? " -on-white" : '',
            required: false,

            oldValue: "",
        });


        let repeaterFormElementsColumn3: FormElement[] = [];
        repeaterFormElementsColumn3.push({
            type: 'hidden',
            fieldName: "isForOffice",

            modificators: props.isOnCreationStep ? "-hidden -on-white" : '-hidden',
            required: false,

            oldValue: isForOffice ? "oui" : "non",
        });
        repeaterFormElementsColumn3.push({
            type: isForOffice ? "select" : "hidden",
            fieldName: "arrondissement",

            label: "Arrondissement",
            options: AdresseConstants.arrondissementsOptions,
            modificators: `${props.isOnCreationStep ? "-on-white -skip-line-1" : '-skip-line-1'} ${ !isForOffice ? '-hidden' : ''}` ,
            labelModificators: `${ !isForOffice ? '-hidden' : ''}` ,
            required: true,
            showFieldErrorDetail: true,

            oldValue: "",
        });
        repeaterFormElementsColumn3.push({
                type: isForOffice ? "selectBrut" : "hidden",
                fieldName: "tribunalJudiciaire",

                label: "Tribunal Judiciaire (automatique)",
                options: AdresseConstants.tribunalJudiciaireOptionsFull,
                placeholder:"Défini à partir du code postal et de la ville",
                modificators: `${props.isOnCreationStep ? "-on-white" : ''} ${ !isForOffice ? '-hidden' : ''}` ,
                labelModificators: `${ !isForOffice ? '-hidden' : ''}` ,
                required: false,
                disabled: true,
                showFieldErrorDetail: true,

                oldValue: "",
            }
        );



        repeaterFormElementsColumn1.push({
            type: 'codePostalVille',
            fieldName: "cpVille",
            label: "Code Postal / Ville",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white" : '',
            showFieldErrorDetail: true,

            oldValue: "",
            maxLength: 5,
            condition: {
                fieldName: "adresseCPVilleSepare",
                value: "non",
            }
        });

        repeaterFormElementsColumn1.push({
            type: 'text',
            fieldName: "codePostal",
            label: "Code Postal",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white" : '',
            showFieldErrorDetail: true,

            oldValue: "",
            condition: {
                fieldName: "adresseCPVilleSepare",
                value: "oui",
            }
        });
        repeaterFormElementsColumn1.push({
            type: 'text',
            fieldName: "ville",
            label: "Ville",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white" : '',
            showFieldErrorDetail: true,

            oldValue: "",
            condition: {
                fieldName: "adresseCPVilleSepare",
                value: "oui",
            }
        });
        repeaterFormElementsColumn1.push({
            type: 'text',
            fieldName: "pays",
            label: "Pays",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white" : '',

            oldValue: "",
            condition: {
                fieldName: "adresseEtrangere",
                value: "oui",
            }
        });

        //Préparation du formulaire
        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: [
                    {
                        type: 'hidden',
                        fieldName: "relationName",
                        modificators: "-hidden",

                        oldValue: props.relationName,
                    },
                    {
                        type: 'hidden',
                        fieldName: "relationId",
                        modificators: "-hidden",

                        oldValue: props.relationId.toString(),
                    },
                    {
                        type: 'repeater',
                        fieldName: "adresses",
                        label: "Adresses",
                        oldRepeatableValues: adresses as FormComponentFormData,
                        formColumns: [
                            {
                                elements: repeaterFormElementsColumn1,
                            },
                            {
                                elements: repeaterFormElementsColumn2,
                            },
                            {
                                elements: repeaterFormElementsColumn3,
                            }
                        ],
                        required: false,
                    },
                ]
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    }, [props.adresses]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IAdressesFormData).then((response: IApiCustomResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les informations des adresses ont bien été mises à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des adresses.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )
}
