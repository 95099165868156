export default class ArrayHelper {

    /**
     * Permer de trier un table par ordre alphabetique
     *
     * @param {string[]} array
     * @returns {string[]}
     */
    public static orderArrayAlphabetical = (array: string[]): string[] => {
        array.sort(function (a: string, b: string) {
            return a.localeCompare(b);
        });

        return array;
    }

}
