import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import SelectMultipleFieldComponent from "src/Components/Fields/SelectMultiple.field.component";
import {UserConstants} from "src/Constants/UsersConstants";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import UserHelper from "src/Helpers/User.helper";
import {useHistory, useLocation} from "react-router";
import {IUsersGetFormData, IUsersGetListApiResponse, UsersService} from "src/Services/Users.service";
import {IApiPaginationLink} from "src/Services/Api.service";
import {IUser} from "src/Models/User.model";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideUtilisateursComponent from "../../Components/Aside/AsideUtilisateurs.component";
import LoadingComponent from "../../Components/Loading/Loading.component";

type UsersListScreenProps = {

}
export default function UsersListScreen(props: UsersListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [usersList, setUsersList] = useState<IUser[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder] = useListFilters("/utilisateurs");
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Utilisateurs"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters);
    }, []);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);


    /**
     * Récupération des utilisateurs
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const usersService: UsersService = new UsersService();

        usersService.getUsers(filters as IUsersGetFormData).then((response: IUsersGetListApiResponse) => {
            if( response && response.datas.pagination){
                setUsersList(response.datas.pagination.data);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if(!loaded){
                    setLoaded(true);
                }
            }
        });
    };




    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text="Utilisateurs" icon="icon-notaires"/>

                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">
                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`} />
                                        <SelectMultipleFieldComponent fieldName="role"
                                                                      label="Rôle"
                                                                      options={UserConstants.rolesOptions}
                                                                      errors={null}
                                                                      placeholder="Tous les rôles"
                                                                      hideSearch={true}
                                                                      modificators={`-inline -on-white`}
                                                                      hideCurrentValuesOnTop={true}
                                                                      oldValues={filters.role ? filters.role as string[] : []}
                                                                      onChange={(values: string[]) => updateFilters({role: values})}
                                        />
                                    </div>

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {["Nom","Prenom","email","role"].map((item, iIndex)=><th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent direction={filters.orderType as string || AppConstants.orderType.asc} value={item} selectedValue={filters.orderBy as string || ""} label={item} click={(value:string)=>setOrder(value)} />
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                usersList && usersList.map( (user: IUser, index: number) =>
                                                    <tr key={index} onClick={()=>history.push(`/utilisateurs/${user.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {user.nom}
                                                        </td>
                                                        <td className="td">
                                                            {user.prenom}
                                                        </td>
                                                        <td className="td">
                                                            {user.email }
                                                        </td>
                                                        <td className="td">
                                                            {UserHelper.getFormatedRole(user.role) }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <PaginationComponent links={paginationLinks}  onPageClick={page=>updateFilters({page})}/>

                                    <AsideUtilisateursComponent type="list" filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    )
}
