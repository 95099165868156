import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {ISuccessionCreationFormData, ISuccessionGetApiResponse, SuccessionsService} from "../../Services/Successions.service";
import {ISuccession} from "../../Models/Succession.model";
import SuccessionCreationFormComponent from "../../Components/Forms/Successions/SuccessionCreationForm.component";

type ISuccessionCreationStepsScreenProps = {
    type: string,
}


const SuccessionCreationStepsScreen = (props:ISuccessionCreationStepsScreenProps) => {
    const successionsService: SuccessionsService = new SuccessionsService();
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        setLoaded(true);
    }, []);


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {ISuccessionGetApiResponse} response
     */
    const onSuccessSubmitForm = (response: ISuccessionGetApiResponse): void => {
        if(response.datas && response.datas.succession){
            const succession: ISuccession = response.datas.succession;
            history.push(AppConstants.pathSuccessions + '/' + succession.id);
        }
        else{
            history.push(AppConstants.pathSuccessions);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathSuccessions);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une succession" icon="icon-successions-add" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
                    {
                        props.type === "creation" &&
                        <>
                            <InformationHeaderComponent icon={"icon-successions"} title={"Succession"} modificators="-margin-bottom" />
                            <SuccessionCreationFormComponent isOnCreationStep={true} submitMethod={(formData: ISuccessionCreationFormData)=>successionsService.createInternal(formData)} onSuccessSubmit={onSuccessSubmitForm}  onCancel={onCancelForm} />

                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}

export default SuccessionCreationStepsScreen;
