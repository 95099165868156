import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {ICtrc} from "src/Models/Ctrc.model";
import {CtrcsService, ICtrcDocumentsFormData, ICtrcInformationsFormData} from "src/Services/Ctrcs.service";
import CtrcInformationsFormComponent from "src/Components/Forms/Ctrcs/CtrcInformationsForm.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import useCtrcs from "src/Hooks/useCtrcs";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import CtrcInformationsComponent from "../../Components/Ctrc/CtrcInformations.component";
import CtrcNotairesMembresListComponent from "../../Components/Ctrc/CtrcNotairesMembresList.component";
import AsideCtrcsComponent from "../../Components/Aside/AsideCtrcs.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import FileHelper from "../../Helpers/File.helper";
import {ISinistre} from "../../Models/Sinistre.model";
import DateHelper from "../../Helpers/Date.helper";
import SinistresHelper from "../../Helpers/Sinistres.helper";
import {Link} from "react-router-dom";
import CtrcDocumentsFormComponent from "../../Components/Forms/Ctrcs/CtrcDocumentsForm.component";
import CtrcDocumentsComponent from "../../Components/Ctrc/CtrcDocuments.component";

type CtrcFicheProps = {
    cid: string
}
export default function CtrcFicheScreen(props: CtrcFicheProps) {
    const informationAccordionRef = useRef(null);
    const documentAccordionRef = useRef(null);

    const [getCtrcById] = useCtrcs();
    const history = useHistory();
    const { hash } = useLocation();

    const ctrcsService: CtrcsService = new CtrcsService();

    const [ctrc, setCtrc] = useState<ICtrc>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [formOpened, setFormOpened] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(hash ? hash.replace('#', "") : 'fiche');


    useEffect(() => {
        if (!ctrc) return;

        const breadcrumbLinks = [
            {text: "Sinistres", link: AppConstants.pathMosaiqueSinistres},
            {text: "CTRC", link: AppConstants.pathCtrcs},
            {text: ctrc.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [ctrc, loaded])


    useEffect(() => {
        if (!props.cid) {
            history.push(AppConstants.pathCtrcs);
        }

        getCtrc();

    }, [props.cid])


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getCtrc = (): void => {
        getCtrcById(props.cid, (ctrc: ICtrc) => {
            setCtrc(ctrc);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCtrc();

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if (informationAccordionRef && informationAccordionRef.current) {
            informationAccordionRef.current.hideForm(isCancel);
        }
        if (documentAccordionRef && documentAccordionRef.current) {
            documentAccordionRef.current.hideForm(isCancel);
        }
    };

    const onExportNotairesMembresPresences = () =>{
        ctrcsService.exportNotairesMembresPresences(props.cid).then((response)=>{
            if (response && response.datas.url) {
                FileHelper.handleFileDownload(response.datas.url);
            }else{
                notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
            }
        });
    };

    const onGenerateDocumentRapports = () => {
        //Sur l'onglet Documents, quand on clique sur le bouton "Générer le document de rapports",
        //un appel API est fait pour générer le document de rapports
        ctrcsService.generateDocumentRapports(props.cid).then((response)=>{
            if(response && response.datas.ctrc) {
                setCtrc(response.datas.ctrc);
                notificationSystem.addNotification({...defaultNotificationConfig, message: response.messages, type: "success"});
            } else {
                notificationSystem.addNotification({...defaultNotificationConfig, message: response.messages, type: "danger"});
            }
        }).catch((error) => {
            notificationSystem.addNotification({...defaultNotificationConfig, message: error.messages, type: "danger"});
        });
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={ctrc.nom} icon="icon-ctrcs"
                                         modificators="" onSearch={null}/>

                    <TabsContainerComponent defaultTab={hash ? hash.replace('#', "") : 'fiche'}
                                            onClickTabCallback={(tab)=>{setActiveTab(tab); closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche" slug="fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={informationAccordionRef}
                                    title="Informations"
                                    icon="icon-informations"
                                    contentShowedOnInit={true}
                                    contentType="ctrc"
                                    contentId={ctrc.id}
                                    onFormOpen={() => {
                                        setFormOpened(true);
                                    }}
                                    onFormClose={() => {
                                        setFormOpened(false);
                                    }}
                                    hideEditButton={formOpened}
                                    form={<CtrcInformationsFormComponent ctrc={ctrc}
                                                                         submitMethod={(formData: ICtrcInformationsFormData) => ctrcsService.update(props.cid, formData)}
                                                                         onSuccessSubmit={onSuccessSubmitForm}
                                                                         onCancel={onCancelForm}/>}
                                    infos={<CtrcInformationsComponent ctrc={ctrc}/>}
                                />


                                <AsideCtrcsComponent type='fiche' ctrc={ctrc} activeTab={activeTab} />
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Présence membres" icon="icon-inscrits" slug="fiche-notaires-membres">
                            <div className="fiche-content -right-gutter border-footer">
                                <CtrcNotairesMembresListComponent ctrc={ctrc}
                                                                  onCtrcChange={(updatedCtrc) => setCtrc(updatedCtrc)}/>

                                <AsideCtrcsComponent type='fiche-notaires-membres' ctrc={ctrc}
                                                     exportCallback={() => {onExportNotairesMembresPresences()}}
                                                     activeTab={activeTab} />
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Dossiers de sinistre" icon="icon-sinistres" slug="fiche-sinistres-rattaches">
                            <div className="fiche-content -right-gutter border-footer">
                                <table className={`list-table`}>
                                    <thead className={"headers"}>
                                    <tr>
                                        {[
                                            {slug: "notairesAssignesOrdered", label: "Notaire(s) mis en cause"},
                                            {slug: "dateOuverture", label: "Année d’ouverture"},
                                            {slug: "libelle", label: "Libellé du dossier"},
                                            {slug: "rapporteur", label: "Rapporteur"},
                                        ].map((item, iIndex) => <th className="th" key={iIndex}>
                                            <span className="link"><span className="text">{item.label}</span></span>
                                        </th>)}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        ctrc.sinistres && ctrc.sinistres.map((sinistre: ISinistre, index: number) =>
                                            <tr key={index}
                                                className="tr">
                                                <td className="td -bold">
                                                    <Link className="g-link -primary -underline-on-hover -bold"
                                                          target="_blank"
                                                          to={`${AppConstants.pathSinistres}/${sinistre.id}`}
                                                          onClick={(e) => e.stopPropagation()}>
                                                        {sinistre.notairesAssignes ? SinistresHelper.getFormatedNotaires(sinistre.notairesAssignes) : ''}
                                                    </Link>
                                                </td>
                                                <td className="td">
                                                    {sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : ''}
                                                </td>
                                                <td className="td">
                                                    {sinistre.libelle ?? ''}
                                                </td>
                                                <td className="td">
                                                    {sinistre.rapporteur ? SinistresHelper.getFormatedNotaires([sinistre.rapporteur]) : ''}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>

                                <AsideCtrcsComponent type='fiche-sinistres-rattaches' ctrc={ctrc} activeTab={activeTab} />
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Documents" icon="icon-documents" slug="fiche-documents">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={documentAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="ctrc"
                                    contentId={ctrc.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<CtrcDocumentsFormComponent ctrc={ctrc}
                                                                     submitMethod={(formData: ICtrcDocumentsFormData) => ctrcsService.updateDocuments(ctrc.id, formData)}
                                                                     onSuccessSubmit={onSuccessSubmitForm}
                                                                     onCancel={onCancelForm}/>}
                                    infos={<CtrcDocumentsComponent ctrc={ctrc}/>}
                                />

                                <AsideCtrcsComponent type='fiche-documents' ctrc={ctrc}
                                                     generateDocumentRapportsCallback={onGenerateDocumentRapports}
                                                     activeTab={activeTab} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
