import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {GroupesService, IGroupeGetApiResponse} from "../Services/Groupes.service";
import {IGroupe} from "../Models/Groupe.model";

export default function useGroupe(){
    const groupesService: GroupesService = new GroupesService();
    const history = useHistory();

    /**
     * Récupération d'un groupe à partir de son id
     *
     * @param {string} gid
     * @param {(groupe: IGroupe) => void} callback
     * @param {() => void} callbackError
     */
    const getGroupeById = (gid: string, callback:(groupe: IGroupe) => void, callbackError?: () => void ) => {
        groupesService.getGroupe(gid).then((response: IGroupeGetApiResponse) => {
            if( response && response.datas.groupe){
                if(callback){
                    callback(response.datas.groupe);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathGroupes);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du groupe'",type: "danger"});
        });
    };

    return [getGroupeById];
};

