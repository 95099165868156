import React, {useState} from "react";
import {IClassementHeader, IClassementTotauxData} from "../../../Services/ClassementsService.service";
import AccordionSimpleComponent from "../../Accordions/AccordionSimple.component";
import NumberHelper from "../../../Helpers/Number.helper";

const Totaux = (props: {
    headers: IClassementHeader[],
    totaux: IClassementTotauxData
}) => {

    const [stats, setStats] = useState();

    if (!props.totaux) return null;

    return <div className="statistiques-totaux">
        <AccordionSimpleComponent title={"Les Totaux"} icon={"icon-calculette"} modificators={"-classement"}>

            <table className={"list-table -totaux"}>
                <thead className={"headers"}>
                <tr>
                    <th className="th">&nbsp;</th>
                    {
                        Object.keys(props.totaux).map((year, yIndex) =>
                            <th className={`th -right`} key={yIndex}>
                                <span className="link">
                                    <span className="text">{year}</span>
                                </span>
                            </th>
                        )}

                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="td">TOTAL</td>
                    {Object.keys(props.totaux).map((year, yIndex) => <td className="td -right -w150px" key={yIndex}>{NumberHelper.formatThousand(props.totaux[year].total)}</td>)}

                </tr>
                <tr>
                    <td className="td">Nombre d'office</td>

                    {Object.keys(props.totaux).map((year, yIndex) => <td className="td -right -w150px" key={yIndex}>{props.totaux[year].nbOffices}</td>)}

                </tr>
                <tr>
                    <td className="td">Montant moyen</td>

                    {Object.keys(props.totaux).map((year, yIndex) => <td className="td -right -w150px" key={yIndex}>{NumberHelper.formatThousand(props.totaux[year].moyenne,0)}</td>)}

                </tr>
                <tr>
                    <td className="td">Montant minimum</td>

                    {Object.keys(props.totaux).map((year, yIndex) => <td className="td -right -w150px" key={yIndex}>{NumberHelper.formatThousand(props.totaux[year].min,0)}</td>)}

                </tr>
                <tr>
                    <td className="td">Montant maximum</td>

                    {Object.keys(props.totaux).map((year, yIndex) => <td className="td -right -w130px" key={yIndex}>{NumberHelper.formatThousand(props.totaux[year].max,0)}</td>)}

                </tr>
                </tbody>
            </table>
        </AccordionSimpleComponent>
    </div>
};

export default Totaux;
