import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {GroupesService, IGroupeGetApiResponse, IGroupesAbonnesGetApiResponse} from "../Services/Groupes.service";
import {IGroupe} from "../Models/Groupe.model";

type IGroupeAbonnes = string[];

export default function UseAbonnes(){
    const groupesService: GroupesService = new GroupesService();
    const history = useHistory();

    /**
     * Récupération d'un groupe à partir de son id
     *
     * @param {(abonnes: IGroupeAbonnes) => void} callback
     * @param {() => void} callbackError
     */
    const getGroupeAbonnes = (callback:(abonnes: IGroupeAbonnes) => void, callbackError?: () => void) => {
        groupesService.getAllAbonnes().then((response: IGroupesAbonnesGetApiResponse) => {
            if (response && response.datas.abonnes) {
                if (callback) {
                    callback(response.datas.abonnes);
                }
            }
        }, (error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }

            history.push(AppConstants.pathGroupes);
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du groupe'",type: "danger"});
        });
    };

    return [getGroupeAbonnes];
};

