import {
    ILettreTypeGetApiResponse,
    ILettreTypesGetApiResponse,
    LettreTypesService
} from "src/Services/LettreTypes.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ILettreType} from "src/Models/LettreType.model";
import {useHistory} from "react-router";

export default function useLettreType(){
    const lettreTypesService: LettreTypesService = new LettreTypesService();
    const history = useHistory();

    /**
     * Récupération d'une lettreType à partir de son id
     *
     * @param {string} cid
     * @param {(lettreType: ILettreType) => void} callback
     * @param {() => void} callbackError
     */
    const getLettreTypeById = (cid: number, callback:(lettreType: ILettreType) => void, callbackError?: () => void ) => {
        lettreTypesService.getLettreType(cid).then((response: ILettreTypeGetApiResponse) => {
            if (response && response.datas.lettreType){
                if(callback){
                    callback(response.datas.lettreType);
                }
            }
        }, (error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathLettreTypes);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la lettre",type: "danger"});
        });
    };

    return [getLettreTypeById];
};

