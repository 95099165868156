import React, {useEffect, useState} from "react";
import {IDifferend} from "../../../Models/Differend.model";
import {
    IDifferendGetApiResponse,
    IDifferendNotairesFormData,
} from "../../../Services/Differends.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "../FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import useCreationSteps from "../../../Hooks/useCreationSteps";
import {INotaire} from "../../../Models/Notaire.model";
import {NotairesService} from "../../../Services/Notaires.service";
import NotairesHelper from "../../../Helpers/Notaires.helper";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import {GroupesService} from "../../../Services/Groupes.service";
import {IGroupe} from "../../../Models/Groupe.model";
import PersonnesExterieuresHelper from "../../../Helpers/PersonnesExterieures.helper";
import DateHelper from "../../../Helpers/Date.helper";
import {AppConstants} from "../../../Constants/AppConstants";

const DifferendCreationForm = (props: {
    differend: IDifferend,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IDifferendNotairesFormData) => Promise<IDifferendGetApiResponse>,
    onSuccessSubmit: (response: IDifferendGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IDifferendGetApiResponse) => void,
    onCancel: () => void,
}) => {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();
    const [defaultFormData,setDefaultFormData] = useState<FormComponentFormData>(null);

    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();
        const groupesService: GroupesService = new GroupesService();

        const notairesCall = notairesService.getAllForSelect();
        const groupesCall = groupesService.getGroupe("51"); //L'id 51 est fixe et représente le groupe des Présidents de chambre

        Promise.all([notairesCall, groupesCall]).then(([responseNotaires, responseGroup]) => {
            initForm(responseNotaires.datas.notaires, responseGroup.datas.groupe);
        });
    }, []);


    /**
     * Initialisation du formulaire
     *
     * @param {INotaire[]} notaires
     * @param {IGroupe} groupe
     */
    const initForm = (notaires: INotaire[], groupe: IGroupe) => {
        const {differend} = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateReception",
                label: "Date de réception",
                placeholder: "Date",
                required: true,
                oldValue: differend && differend.dateReception ? DateHelper.getFormatedDateForInput(differend.dateReception) : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
            {
                type: 'radio',
                fieldName: "originePlaignant",
                label: "Origine du notaire plaignant",
                required: true,
                oldValue: differend ? differend.originePlaignant : "",
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: [
                    {value: "externe", label: "Externe"},
                    {value: "interne", label: "Interne"},
                ],
            },
            {
                type: "select",
                fieldName: "notairePlaignantId",
                label: "Notaire plaignant",
                modificators: props.isOnCreationStep ? "-on-white" : "",
                oldValue: differend && differend.notairePlaignant ? differend.notairePlaignant.id.toString() : '',
                options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                required: true,
                condition: {
                    fieldName: "originePlaignant",
                    value: "interne",
                }
            },
            {
                type: 'text',
                label: "Nom du plaignant",
                fieldName: "notairePlaignantNom",
                required: true,
                oldValue: differend && differend.notairePlaignant ? differend.notairePlaignant.nom : '',
                modificators: props.isOnCreationStep ? "-on-white" : "",
                condition: {
                    fieldName: "originePlaignant",
                    value: "externe",
                }
            },
            {
                type: 'radio',
                fieldName: "notairePlaignantRechercheCpVille",

                label: "Rechercher la ville par code postal ?",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: "",

                condition: {
                    fieldName: "originePlaignant",
                    value: "externe",
                }
            },
            {
                type: 'codePostalVille',
                fieldName: "notairePlaignantCpVille",
                label: "Code Postal / Ville",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: "",
                maxLength: 5,
                condition: [
                    {
                        fieldName: "notairePlaignantRechercheCpVille",
                        value: "oui",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "externe",
                    }
                ]
            },
            {
                type: 'text',
                label: "Ville du plaignant",
                fieldName: "notairePlaignantVille",
                required: false,
                oldValue: differend && differend.notairePlaignantVille ? differend.notairePlaignantVille : '',
                modificators: props.isOnCreationStep ? "-on-white" : "",
                condition: [
                    {
                        fieldName: "notairePlaignantRechercheCpVille",
                        value: "non",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "externe",
                    },
                ]
            },
            {
                type: "select",
                fieldName: "notairePlaignantPresidentId",
                label: "Président",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                oldValue: differend && differend.notairePlaignantPresident ? differend.notairePlaignantPresident.id.toString() : '',
                options: groupe.personnesExterieures ? PersonnesExterieuresHelper.formatPresidentListForOptions(groupe.personnesExterieures) : [],
                condition: [
                    {
                        fieldName: "originePlaignant",
                        value: "externe",
                    },
                ]
            }
        ];

        const formElementsColumn2: FormElement[] = [
            {
                type: 'radio',
                fieldName: "origineMisEnCause",
                label: "Origine du notaire mis en cause",
                required: true,
                oldValue: differend ? differend.origineMisEnCause : "",
                modificators: props.isOnCreationStep ? "-on-white -skip-line-1" : " -skip-line-1",
                options: [
                    {value: "externe", label: "Externe"},
                    {value: "interne", label: "Interne"},
                ],
                condition: {
                    fieldName: "originePlaignant",
                    value: "interne",
                }
            },
            {
                type: "select",
                fieldName: "notaireMisEnCauseId",
                label: "Notaire mis en cause",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                oldValue: differend && differend.notaireMisEnCause ? differend.notaireMisEnCause.id.toString() : '',
                options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                condition: [
                    {
                        fieldName: "origineMisEnCause",
                        value: "interne",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "interne",
                    }
                ]
            },
            {
                type: "select",
                fieldName: "notaireMisEnCauseId",
                label: "Notaire mis en cause",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white  -skip-line-1" : " -skip-line-1",
                oldValue: differend && differend.notaireMisEnCause ? differend.notaireMisEnCause.id.toString() : '',
                options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                condition: {
                    fieldName: "originePlaignant",
                    value: "externe",
                }
            },
            {
                type: 'text',
                label: "Nom du mis en cause",
                required: true,
                fieldName: "notaireMisEnCauseNom",
                oldValue: differend && differend.notaireMisEnCauseNom || "",
                modificators: props.isOnCreationStep ? "-on-white" : "",
                condition: [
                    {
                        fieldName: "origineMisEnCause",
                        value: "externe",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "interne",
                    }
                ]
            },
            {
                type: 'radio',
                fieldName: "notaireMisEnCauseRechercheCpVille",

                label: "Rechercher la ville par code postal ?",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: "",

                condition: [
                    {
                        fieldName: "origineMisEnCause",
                        value: "externe",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "interne",
                    }
                ]
            },
            {
                type: 'codePostalVille',
                fieldName: "notaireMisEnCauseCpVille",
                label: "Code Postal / Ville",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: "",
                maxLength: 5,
                condition: [
                    {
                        fieldName: "notaireMisEnCauseRechercheCpVille",
                        value: "oui",
                    },
                    {
                        fieldName: "origineMisEnCause",
                        value: "externe",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "interne",
                    }
                ]
            },
            {
                type: 'text',
                label: "Ville du mis en cause",
                fieldName: "notaireMisEnCauseVille",
                required: false,
                oldValue: differend && differend.notaireMisEnCauseVille ? differend.notaireMisEnCauseVille : '',
                modificators: props.isOnCreationStep ? "-on-white" : "",
                condition: [
                    {
                        fieldName: "notaireMisEnCauseRechercheCpVille",
                        value: "non",
                    },
                    {
                        fieldName: "origineMisEnCause",
                        value: "externe",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "interne",
                    }
                ]
            },
            {
                type: "select",
                fieldName: "notaireMisEnCausePresidentId",
                label: "Président",
                modificators: props.isOnCreationStep ? "-on-white" : "",
                oldValue: differend && differend.notaireMisEnCausePresident ? differend.notaireMisEnCausePresident.id.toString() : "",
                options: groupe.personnesExterieures ? PersonnesExterieuresHelper.formatPresidentListForOptions(groupe.personnesExterieures) : [],
                required: false,
                condition: [
                    {
                        fieldName: "origineMisEnCause",
                        value: "externe",
                    },
                    {
                        fieldName: "originePlaignant",
                        value: "interne",
                    },
                ]
            }
        ];







        const currentFormColumns: FormColumn[] = [
            {
                modificators: "",
                elements: formElementsColumn1
            },
            {
                modificators: "",
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true);
        setFormActions(currentFormActions);
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IDifferendNotairesFormData).then((response: IDifferendGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le différend a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création du differend.",
                    type: "danger"
                });
            }
        });
    };

    if (!formColumns.length) return null;

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )
};

export default DifferendCreationForm;
