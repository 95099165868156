import {useEffect, useState} from "react";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IReclamation} from "src/Models/Reclamation.model";
import {IReclamationGetApiResponse, IReclamationInformationsFormData} from "src/Services/Reclamations.service";
import {ReclamationsConstants} from "src/Constants/ReclamationsConstants";
import {AppConstants} from "../../../Constants/AppConstants";


type IReclamationInformationsFormComponentProps = {
    reclamation?: IReclamation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IReclamationInformationsFormData) => Promise<IReclamationGetApiResponse>,
    onSuccessSubmit: (response: IReclamationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IReclamationGetApiResponse) => void,
    onCancel: () => void,
}

export default function ReclamationConciliationFormComponent(props: IReclamationInformationsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    useEffect(() => {
        const reclamation: IReclamation = props.reclamation;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'datetime-local',
                withTime: true,
                fieldName: "conciliationDate",
                label: "Date conciliation",
                placeholder: "Date conciliation",
                required: false,
                oldValue: reclamation.conciliationDate && reclamation.conciliationDate ? reclamation.conciliationDate : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            }
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'radio',
                fieldName: "conciliation",

                label: "Conciliation",
                placeholder: "Conciliation",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: ReclamationsConstants.conciliationOptions,
                hideSearchOnMultiple: true,

                oldValue: reclamation && reclamation.conciliation ? reclamation.conciliation : "",
            },

        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: "select",
                fieldName: "conciliationIssue",

                label: "Issue de la conciliation",
                hideSearchOnMultiple: true,
                required: true,

                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.conciliationIssue ? reclamation.conciliationIssue : "",
                options: ReclamationsConstants.conciliationIssueOptions,

                condition: {
                    fieldName: "conciliation",
                    value: "oui",
                }
            },
            {
                type: "select",
                fieldName: "conciliationMotif",

                label: "Motif",
                hideSearchOnMultiple: true,
                required: true,

                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: reclamation && reclamation.conciliationMotif ? reclamation.conciliationMotif : "",
                options: ReclamationsConstants.conciliationMotifOptions,

                condition: {
                    fieldName: "conciliation",
                    value: "non",
                }
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit);
        setFormActions(currentFormActions);


    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IReclamationInformationsFormData).then((response: IReclamationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La modification a bien été prise en compte.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive de la réclamation.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )

}
