import {SinistresConstants} from "../Constants/SinistresConstants";
import CommonHelper from "./Common.helper";
import {INotaire} from "../Models/Notaire.model";
import {ICtrc} from "../Models/Ctrc.model";


export default class SinistresHelper {

    /**
     * Retourne la source formatée
     *
     * @param {string} source
     * @returns {string}
     */
    static getFormatedSource(source: string): string {
        return CommonHelper.getFormatedElementFromOptions(source, SinistresConstants.sourceOptions);
    }

    /**
     * Retourne la civilité formatée
     *
     * @param {string} civilite
     * @returns {string}
     */
    static getFormatedCivilite(civilite: string): string {
        return CommonHelper.getFormatedElementFromOptions(civilite, SinistresConstants.civiliteOptions);
    }


    /**
     * Retourne le type de document formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedDocumentTypes(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, SinistresConstants.documentsTypesOptions);
    }


    /**
     * Récupère une liste de notaire formatée pour le sinistre
     *
     * @param {INotaire[]} notaires
     * @returns {string}
     */
    static getFormatedNotaires(notaires: INotaire[]) : string{
        const notairesFormated = [];

        for(let notaire of notaires){
            notairesFormated.push(`${notaire.nom} ${notaire.prenom}`);
        }

        return notairesFormated.join(', ');
    }


    /**
     * Récupère une liste de ctrcs formatée pour le sinistre
     *
     * @param {ICtrc[]} ctrcs
     * @returns {string}
     */
    static getFormatedCtrcs(ctrcs: ICtrc[]) : string{
        if(!ctrcs || ctrcs.length === 0) return '-';

        const ctrcsFormated = [];

        for(let ctrc of ctrcs){
            ctrcsFormated.push(`${ctrc.nomComplet}`);
        }

        return ctrcsFormated.join(', ');
    }



}

