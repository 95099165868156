import {ICertification} from "src/Models/Certification.model";
import FileHelper from "../../../Helpers/File.helper";

type ICertificationInformationsComponentProps = {
    certification: ICertification,
}

export default function CertificationInformationsComponent(props: ICertificationInformationsComponentProps) {
    const certification: ICertification = props.certification;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column -auto">
                    <figure className="informations-image -resume -border">
                        {
                            certification.fichier &&
                            <img src={FileHelper.getImageUrlFromSize(certification.fichier,"thumbnail")} alt="" className="image" />
                        }
                        {
                            !certification.fichier &&
                            <img src={FileHelper.getPlaceholderUrl()} alt="" className="image" />
                        }
                    </figure>
                </div>

                <div className="column ">
                    <div className="informations-block">
                        <p className="label">Nom</p>
                        <p className="value">{certification.nom || "-"}</p>
                    </div>
                </div>

                <div className="column">
                </div>
            </div>
        </div>
    );
}
