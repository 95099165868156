import {AppConstants} from "src/Constants/AppConstants";

export default class  FileHelper{

    /**
     * Retourne l'url d'une image à partir de son chemin en BDD et de la taille voulue
     * @param {string} initialImagePath
     * @param {string} size
     * @returns {string}
     */
    public static getImageUrlFromSize(initialImagePath: string, size: string): string{

        if(!initialImagePath || !size) return "";

        let pathParts = initialImagePath.split("/");
        const fileName = pathParts.pop();
        pathParts.unshift( "images" );
        pathParts.push(size);
        pathParts.push(fileName);

        return `${AppConstants.serverUrl}${pathParts.join("/")}`;
    }

    /**
     * Retourne l'url d'un fichier
     * @param {string} initialFilePath
     * @returns {string}
     */
    public static getFileUrl(initialFilePath: string,path:string="documents"): string{

        if(!initialFilePath) return "";

        return `${AppConstants.serverUrl}${path}/${initialFilePath}`;
    }

    /**
     * Retourne le nom d'un fichier à partir de son chemin
     * @param {string} path
     * @returns {string}
     */
    public static basename = (path: string): string => {
        if(!path || !path.length) return "";

        return path.split('/').reverse()[0];
    }

    public static getPlaceholderUrl = (slug: string = "placeholder"): string => {
        return `${AppConstants.serverUrl}assets/images/placeholders/${slug}.png` ;
    }

    /**
     * Téléchargement du fichier
     *
     * @param {string} url
     * @param {boolean} blank
     */
    public static handleFileDownload = (url:string,blank: boolean = false) => {
        const link = document.createElement('a');
        link.href = url;
        if(blank)
            link.target = "_blank";
        link.setAttribute('download', ``);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

}

