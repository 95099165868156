import {ICreationSteps} from "./AppConstants";
import {IOption} from "../Components/Fields/Select.field.component";

export type IFilInformationsConstants = {
    creationSteps: ICreationSteps[],
    abonnesStates: {[key:string]: string},
    abonnesStatesOptions: IOption[]
    formationsPictosOptions: IOption[]
}

export const FilInformationsConstants: IFilInformationsConstants = {
    abonnesStates: {
        abonne: "Abonné.e",
        error: "Invalide",
        desabonne: "Désabonné.e",
        blackliste: "Blacklisté.e",
    },
    abonnesStatesOptions: [
        {value: "abonne", label: "Abonné.e" },
        {value: "error", label: "Invalide" },
        {value: "desabonne", label: "Désabonné.e" },
        {value: "blackliste", label: "Blacklisté.e" },
    ],
    formationsPictosOptions: [
        {value: "nouveau", label: "Nouveau" },
        {value: "important", label: "Important" },
    ],
    creationSteps: [
        {
            url: "/une",
            title: "A la Une",
            icon: "icon-megaphone",
        },
        {
            url: "/actualites",
            title: "Actualités",
            icon: "icon-fil-infos",
        },
        {
            url: "/cvtheque",
            title: "CVthèque",
            icon: "icon-fiche",
        },
        {
            url: "/cles-real",
            title: "Clés REAL",
            icon: "icon-usb",
        },
        {
            url: "/images",
            title: "Images",
            icon: "icon-import",
        },
        {
            url: "/presse",
            title: "Vu dans la presse",
            icon: "icon-fil-infos",
        },
        {
            url: "/formations",
            title: "Formations",
            icon: "icon-formations",
        },
        {
            url: "/retraits-nominations",
            title: "Retraits et nominations",
            icon: "icon-megaphone",
        },
        {
            url: "/agenda",
            title: "Agenda",
            icon: "icon-agenda",
        },
    ],
};
