import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import {INotaire} from "src/Models/Notaire.model";
import {
    INotaireCommentairesFormData, INotaireFormData,
    INotaireHistoriquesFormData,
    INotaireIdentiteFormData, INotaireUpdateTypeNotaireFormData,
    NotairesService
} from "src/Services/Notaires.service";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import NotaireIdentiteComponent from "src/Components/Annuaire/Notaires/NotaireIdentite.component";
import NotaireIdentiteFormComponent from "src/Components/Forms/Annuaire/Notaires/NotaireIdentiteForm.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import NotaireResumeComponent from "src/Components/Annuaire/Notaires/NotaireResume.component";
import CommentairesComponent from "src/Components/Annuaire/Commons/Commentaires/Commentaires.component";
import NotaireCommentairesFormComponent from "src/Components/Forms/Annuaire/Notaires/NotaireCommentairesForm.component";
import EvenementsInfosComponent from "src/Components/Annuaire/Commons/Evenements/EvenementsInfos.component";
import {
    CommonsService,
    IAdressesFormData,
    ICoordonneesFormData,
    IEvenementsFormData
} from "src/Services/Commons.service";
import EvenementsFormComponent from "src/Components/Forms/Commons/EvenementsForm.component";
import useNotaire from "src/Hooks/UseNotaire";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import AsideNotaireComponent from "src/Components/Aside/AsideNotaire.component";
import AdressesFormComponent from "src/Components/Forms/Commons/AdressesForm.component";
import AdressesInfosComponent from "src/Components/Annuaire/Commons/Adresses/AdressesInfos.component";
import CoordonneesFormComponent from "src/Components/Forms/Commons/CoordonneesForm.component";
import CoordonneesInfosComponent from "src/Components/Annuaire/Commons/Coordonnees/CoordonneesInfos.component";
import NotaireHistoriqueComponent from "src/Components/Annuaire/Notaires/NotaireHistorique.component";
import NotaireOfficeComponent from "src/Components/Annuaire/Notaires/NotaireOffice.component";
import NotaireHistoriquesFormComponent from "src/Components/Forms/Annuaire/Notaires/NotaireHistoriquesForm.component";
import NotaireFormationsListComponent from "src/Components/Annuaire/Notaires/NotaireFormationsList.component";
import NotaireDocumentsComponent from "src/Components/Annuaire/Notaires/NotaireDocuments.component";
import NotaireDocumentsFormComponent from "src/Components/Forms/Annuaire/Notaires/NotaireDocumentsForm.component";
import ModalWithFormCompontent from "../../../Components/Modal/ModalWithForm.compontent";
import NotaireUpdateTypeNotaireFormComponent
    from "../../../Components/Forms/Annuaire/Notaires/NotaireUpdateTypeNotaireForm.component";
import NotaireDecedeResumeComponent from "../../../Components/Annuaire/Notaires/NotaireDecedeResume.component";


type NotaireFicheScreenProps = {
    nid: string
}
export default function NotaireFicheScreen(props: NotaireFicheScreenProps) {
    const identiteAccordionRef = useRef(null);
    const commentaireAccordionRef = useRef(null);
    const historiqueFonctionsAccordionRef = useRef(null);
    const adressesAccordionRef = useRef(null);
    const coordonneesAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);

    const [notaire, setNotaire] = useState<INotaire>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const notairesService: NotairesService = new NotairesService();
    const commonsService: CommonsService = new CommonsService();
    const history = useHistory();
    const [getNotaireById] = useNotaire();
    const [isNotaireAncien, setIsNotaireAncien] = useState<boolean>(false);
    const [isNotaireUpdateTypeModalVisible, setIsNotaireUpdateTypeModalVisible] = useState<boolean>(false);
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if (!notaire) return;

        const anciensNotairesTypesList = NotairesConstants.typesAnciens;


        let breadcrumbLinks;
        if (anciensNotairesTypesList.includes(notaire.type) || isDecede()) {
            setIsNotaireAncien(true);
            breadcrumbLinks = [
                {text: "Annuaire", link: AppConstants.pathAnnuaire},
                {text: "Anciens notaires", link: AppConstants.pathNotairesAnciens},
                {text: notaire.prenom + ' ' + notaire.nom},
            ];
        }
        else {
            setIsNotaireAncien(false);
            breadcrumbLinks = [
                {text: "Annuaire", link: AppConstants.pathAnnuaire},
                {text: "Notaires", link: AppConstants.pathNotaires},
                {text: notaire.prenom + ' ' + notaire.nom},
            ];
        }


        setBreadcrumbLinks(breadcrumbLinks);
    }, [notaire, loaded])


    useEffect(() => {
        if (!props.nid) {
            history.push(AppConstants.pathNotaires);
        }

        getNotaire();

    }, [props.nid])


    /**
     * Récupération du notaire selon le nid
     */
    const getNotaire = (): void => {
        getNotaireById(props.nid, (notaire: INotaire) => {
            setNotaire(notaire);

            if (!loaded) {
                setLoaded(true);
            }
        })
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getNotaire();

        setIsNotaireUpdateTypeModalVisible(false);

        closeForms();
    };
    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Callback lors de la fermeture de la modale
     */
    const onCloseModal = (): void => {
        setIsNotaireUpdateTypeModalVisible(false);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if ((identiteAccordionRef && identiteAccordionRef.current)) {
            identiteAccordionRef.current.hideForm(isCancel);
        }
        if ((commentaireAccordionRef && commentaireAccordionRef.current)) {
            commentaireAccordionRef.current.hideForm(isCancel);
        }
        if ((historiqueFonctionsAccordionRef && historiqueFonctionsAccordionRef.current)) {
            historiqueFonctionsAccordionRef.current.hideForm(isCancel);
        }
        if ((adressesAccordionRef && adressesAccordionRef.current)) {
            adressesAccordionRef.current.hideForm(isCancel);
        }
        if ((coordonneesAccordionRef && coordonneesAccordionRef.current)) {
            coordonneesAccordionRef.current.hideForm(isCancel);
        }
        if ((documentsAccordionRef && documentsAccordionRef.current)) {
            documentsAccordionRef.current.hideForm(isCancel);
        }
    }

    /**
     * Callback du bouton de modification d'un champ
     * => Ouverture de la modale
     */
    const onUpdateFieldButtonClickForm = (): void => {
        setIsNotaireUpdateTypeModalVisible(true);
    }

    /**
     * Récupérer le fait que le notaire est décédé ou non
     */
    const isDecede = (): boolean => {
        if (notaire !== null) {
            if (notaire.decede === "oui" || notaire.type === 'deces') {
                return true;
            }
        }
        return false;
    }
    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={notaire.prenom + ' ' + notaire.nom}
                                         icon="icon-notaires" onSearch={null}/>

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionSimpleComponent title="informations" icon="icon-informations"
                                                          contentShowedOnInit={true}>
                                    <>
                                        {!isDecede() ?
                                            <NotaireResumeComponent notaire={notaire}/> :
                                            <NotaireDecedeResumeComponent notaire={notaire}/>
                                        }
                                    </>
                                </AccordionSimpleComponent>

                                {!isDecede() &&
                                    <AccordionWithFormSwitchComponent
                                        ref={identiteAccordionRef}
                                        title="Identité"
                                        icon="icon-notaires"
                                        contentShowedOnInit={true}
                                        contentType="notaire"
                                        contentId={notaire.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<NotaireIdentiteFormComponent notaire={notaire}
                                                                            isAncienNotaire={isNotaireAncien}
                                                                            submitMethod={(formData: INotaireIdentiteFormData) => notairesService.updateIdentiteNotaire(notaire.id, formData)}
                                                                            onSuccessSubmit={onSuccessSubmitForm}
                                                                            onCancel={onCancelForm}
                                                                            onUpdateFieldButtonClick={onUpdateFieldButtonClickForm}/>}
                                        infos={<NotaireIdentiteComponent notaire={notaire}
                                                                         isAncienNotaire={isNotaireAncien}/>}
                                    />
                                }

                                {/* Modal pour changer le type de notaire */}
                                {isNotaireUpdateTypeModalVisible && !isDecede() &&
                                    <ModalWithFormCompontent
                                        form={<NotaireUpdateTypeNotaireFormComponent notaire={notaire}
                                                                                     submitMethod={(formData: INotaireUpdateTypeNotaireFormData) => notairesService.updateTypeNotaire(notaire.id, formData)}
                                                                                     onSuccessSubmit={onSuccessSubmitForm}
                                                                                     onCloseModal={onCloseModal}/>}
                                        onCloseModal={onCloseModal}
                                    />
                                }

                                {!isDecede() &&
                                    <AccordionWithFormSwitchComponent
                                        ref={adressesAccordionRef}
                                        title="Adresses"
                                        icon="icon-coordonnees"
                                        contentShowedOnInit={true}
                                        contentType="notaire"
                                        contentId={notaire.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<AdressesFormComponent adresses={notaire.adresses}
                                                                     relationId={notaire.id} relationName="notaire"
                                                                     submitMethod={(formData: IAdressesFormData) => commonsService.syncAdresses(formData)}
                                                                     onSuccessSubmit={onSuccessSubmitForm}
                                                                     onCancel={onCancelForm}/>}
                                        infos={<AdressesInfosComponent adresses={notaire.adresses || null}/>}
                                    />
                                }

                                {!isDecede() &&
                                    <AccordionWithFormSwitchComponent
                                        ref={coordonneesAccordionRef}
                                        title="Coordonnées"
                                        icon="icon-coordonnees-adresses"
                                        contentShowedOnInit={true}
                                        contentType="notaire"
                                        contentId={notaire.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<CoordonneesFormComponent coordonnees={notaire.coordonnees}
                                                                        relationId={notaire.id} relationName="notaire"
                                                                        submitMethod={(formData: ICoordonneesFormData) => commonsService.syncCoordonnees(formData)}
                                                                        onSuccessSubmit={onSuccessSubmitForm}
                                                                        onCancel={onCancelForm}/>}
                                        infos={<CoordonneesInfosComponent coordonnees={notaire.coordonnees || null}
                                                                          relationName="notaire"/>}
                                    />
                                }

                                {!isDecede() &&
                                    <AccordionSimpleComponent
                                        title={isNotaireAncien ? "Dernier office" : "Office"}
                                        icon="icon-office"
                                        contentShowedOnInit={true}
                                        children={<NotaireOfficeComponent
                                            notaire={notaire}
                                            isAncienNotaire={isNotaireAncien}
                                        />}
                                    />
                                }

                                {!isDecede() &&
                                    <AccordionWithFormSwitchComponent
                                        ref={commentaireAccordionRef}
                                        title="Commentaires"
                                        icon="icon-commentaires"
                                        contentShowedOnInit={true}
                                        contentType="notaire"
                                        contentId={notaire.id}
                                        onFormOpen={() => {setFormOpened(true);}}
                                        onFormClose={() => {setFormOpened(false);}}
                                        hideEditButton={formOpened}
                                        form={<NotaireCommentairesFormComponent notaire={notaire}
                                                                                submitMethod={(formData: INotaireCommentairesFormData) => notairesService.updateCommentaireNotaire(notaire.id, formData)}
                                                                                onSuccessSubmit={onSuccessSubmitForm}
                                                                                onCancel={onCancelForm}/>}
                                        infos={<CommentairesComponent commentaires={notaire.commentaires}/>}
                                    />
                                }


                                <AsideNotaireComponent type={isNotaireAncien ? 'ficheAnciens' : 'fiche'}
                                                       notaire={notaire}/>
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Historique" icon="icon-historique">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={commentaireAccordionRef}
                                    title="Historique"
                                    icon="icon-historique"
                                    contentShowedOnInit={true}
                                    contentType="notaire"
                                    contentId={notaire.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    hideEditButtonIfInfosEmpty={!notaire.historiques || notaire.historiques.length === 0}
                                    form={<NotaireHistoriquesFormComponent
                                        notaire={notaire}
                                        submitMethod={(formData: INotaireHistoriquesFormData) => notairesService.updateHistoriques(notaire.id, formData)}
                                        onSuccessSubmit={onSuccessSubmitForm}
                                        onCancel={onCancelForm}
                                    />}
                                    infos={<NotaireHistoriqueComponent notaire={notaire} historiques={notaire.historiques}/>}
                                />
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Historique des fonctions" icon="icon-historique">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={historiqueFonctionsAccordionRef}
                                    title="Historique des fonctions"
                                    icon="icon-historique"
                                    contentShowedOnInit={true}
                                    contentType="notaire"
                                    contentId={notaire.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<EvenementsFormComponent evenements={notaire.evenements}
                                                                   relationId={notaire.id} relationName="notaire"
                                                                   submitMethod={(formData: IEvenementsFormData) => commonsService.syncEvenements(formData)}
                                                                   onSuccessSubmit={onSuccessSubmitForm}
                                                                   onCancel={onCancelForm}/>}
                                    infos={<EvenementsInfosComponent evenements={notaire.evenements || null}
                                                                     relationName="notaire"/>}
                                />

                                <AsideNotaireComponent type={isNotaireAncien ? 'ficheAnciens' : 'fiche'}
                                                       notaire={notaire}/>
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Carrière Professionnelle" icon="icon-documents">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={commentaireAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="notaire"
                                    contentId={notaire.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<NotaireDocumentsFormComponent notaire={notaire}
                                                                         submitMethod={(formData: INotaireFormData) => notairesService.uploadDocument(notaire.id, formData)}
                                                                         onSuccessSubmit={onSuccessSubmitForm}
                                                                         onCancel={onCancelForm}/>}
                                    infos={<NotaireDocumentsComponent notaire={notaire}/>}
                                />

                                <AsideNotaireComponent type={isNotaireAncien ? 'ficheAnciens' : 'fiche'}
                                                       notaire={notaire}/>
                            </div>
                        </TabPanelComponent>

                        {!isDecede() && !isNotaireAncien &&
                            <TabPanelComponent label="Formations" icon="icon-formations">
                                <div className="fiche-content -right-gutter border-footer">
                                    <NotaireFormationsListComponent formations={notaire.formations}/>

                                    <AsideNotaireComponent type={isNotaireAncien ? 'ficheAnciens' : 'fiche'} notaire={notaire}/>
                                </div>
                            </TabPanelComponent>
                        }

                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
