import {NegociateursConstants} from "../Constants/NegociateursConstants";
import {IOption} from "../Components/Fields/Select.field.component";
import CommonHelper from "./Common.helper";

export default class NegociateursHelper{

    /**
     * Retourne le type d'office formatté
     * @param {string} type
     * @returns {string}
     */
    static getFormatedType(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type,NegociateursConstants.typesOptions);
    }

    /**
     * Retour les labels formatés
     *
     * @param {string[]} labels
     * @returns {string}
     */
    static getFormatedLabels(labels: string[]): string {
        return CommonHelper.getFormatedElementsFromOptions(labels, NegociateursConstants.labelsOptions);
    }

}
