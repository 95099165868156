import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, { useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {ICotisationsOffice} from "src/Models/CotisationsOffice.model";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import {AppConstants} from "../../Constants/AppConstants";

type IAsideCotisationsOfficesComponentProps = {
    modificators?: string,
    type: "list" | "fiche",
    sousType?: "saisieOffice" | "saisieNotaire",
    cotisationOffice?: ICotisationsOffice,

    exportCallback?: () => void,
    filters?: any,
}

export default function AsideCotisationsOfficesComponent(props: IAsideCotisationsOfficesComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [actions, setActions] = useState<ListAsideAction[]>([]);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF()})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
        }
        else{
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        setActions(actions);
    }, [props.type, props.sousType, props.filters]);


    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (): void => {
        setIsLoading(true);

        let url:string = `api${AppConstants.pathCotisationsListeOffices}/export-pdf`;
        if(props.sousType && props.sousType == "saisieOffice"){
            url = `api${AppConstants.pathCotisationsSaisieOffices}/export-pdf`;
        }
        else if(props.sousType && props.sousType == "saisieNotaire"){
            url = `api${AppConstants.pathCotisationsSaisieNotaires}/export-pdf`;
        }


        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }


    return (
        <>
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
