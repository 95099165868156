import React, {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse} from "src/Services/Api.service";
import {ICtrc, ICtrcNotaireMembrePresence} from "../../Models/Ctrc.model";
import {INotaire} from "../../Models/Notaire.model";
import NotairesHelper from "../../Helpers/Notaires.helper";
import RadioColorButtonsFieldComponent from "../Fields/RadioColorButtons.field.component";
import {
    CtrcsService,
    ICtrcNotaireMembrePresenceFormData
} from "../../Services/Ctrcs.service";

//Liste des notaires membres (avec les présences)
const CtrcNotairesMembresList = (props: {
    ctrc: ICtrc,
    onCtrcChange: (ctrc: ICtrc) => void,
}) => {
    const [notaireMembrePresences, setNotaireMembrePresences] = useState<ICtrcNotaireMembrePresence[]>([]);


    useEffect(() => {
        setNotaireMembrePresences(props.ctrc.notairesMembresPresences);
    }, [props.ctrc]);

    /**
     * Change la présence d'un notaire membre
     *
     * @param {INotaire} notaireMembre
     * @param {string} present
     */
    const onChangePresence = (notaireMembre: INotaire, present: string) => {
        const ctrcsService = new CtrcsService();

        const formDatas: ICtrcNotaireMembrePresenceFormData = {
            notaireId: notaireMembre.id.toString(),
            presence: present
        };

        //Déclencher l'appel à l'API pour mettre à jour la présence du notaire membre
        ctrcsService.updateNotaireMembrePresence(props.ctrc.id.toString(), formDatas).then((result) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La présence du notaire membre a bien été modifiée",
                type: "success"
            });

            if(result.datas && result.datas.ctrc) {
                //Déclencher la mise à jour de l'objet (CTRC) sur le parent
                if (props.onCtrcChange) {
                    props.onCtrcChange(result.datas.ctrc);
                }
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la mise à jour de la présence du notaire membre.",
                type: "danger"
            });
        });
    };


    const headers: { label: string, states: string[] }[] = [
        {label: "Identité", states: []},
        //{label: "Date d'ajout", states: []},
        //{label: "Date de mise à jour", states: []},
        {label: "Présence", states: []}
    ];
    return <>
        {/*
        <p className="list-table">
            <em>
                L'ajout de notaire(s) membre(s) est effectué automatiquement à la création du CTRC.<br />
                Le système va récupérer les notaires qui ont comme fonction "CTRC - Membre",<br />
                Dans la partie "Informations", il est tout de même possible de modifier cette liste de notaires membres.
            </em>
        </p>
        */}
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                {headers.map((item, iIndex) => <React.Fragment key={iIndex}>
                    {
                        <th className="th">
                            <span className="link">
                                <span className="text">{item.label}</span>
                            </span>
                        </th>
                    }
                </React.Fragment>)}
            </tr>
            </thead>
            <tbody>
            {
                notaireMembrePresences && notaireMembrePresences.map((notaireMembrePresence: ICtrcNotaireMembrePresence, index: number) =>
                    <tr key={index} className="tr">
                        <td className="td -w350">
                            {NotairesHelper.getFormattedIdentite(notaireMembrePresence.notaire, false)}
                            {/*Parmis les notaires membres, il y a le président (pour gérer sa présence) */}
                            {notaireMembrePresence.notaire.id === props.ctrc.president?.id && ' (Président)'}
                        </td>

                        {/*
                        <td className="td">
                            {notaireMembrePresence.created_at !== null && DateHelper.getFormatedDate(notaireMembrePresence.created_at, true)}
                        </td>

                        <td className="td">
                            {notaireMembrePresence.updated_at !== null && DateHelper.getFormatedDate(notaireMembrePresence.updated_at, true)}
                        </td>
                        */}

                        <td className="td -presence">
                            <RadioColorButtonsFieldComponent
                                options={
                                    [
                                        {
                                            value: 'oui',
                                            label: '',
                                            circleColorModificators: '-color-actif',
                                            modificators: '-with-circle-icon'
                                        },
                                        {
                                            value: 'non',
                                            label: '',
                                            circleColorModificators: '-color-annule',
                                            modificators: '-with-circle-icon'
                                        },
                                        {
                                            value: '',
                                            label: '',
                                            circleColorModificators: '-color-default',
                                            modificators: '-with-circle-icon'
                                        },
                                    ]
                                }
                                fieldName='reponsePresence'
                                modificators="-presence-radios"
                                label=''
                                oldValue={notaireMembrePresence.presence ?? null}
                                onChange={(present) => onChangePresence(notaireMembrePresence.notaire, present)}
                            />
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </>
};

export default CtrcNotairesMembresList;
