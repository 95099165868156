import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import SearchFieldComponent from "src/Components/Fields/Search.field.component";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useHistory} from "react-router";
import {debounce} from "lodash";

type SearchBlockComponentProps = {
    keyword?: string,
    placeholder?: string,
    modificators?: string,
    searchAuto?: boolean,
    onSubmit: (formDatas: IFormSearchData) => void
}

export type IFormSearchData = {
    [key:string] : string | number | string[]
}

export default function SearchBlockComponent(props: SearchBlockComponentProps){
    const {isAuthenticated} = useContext(UserContext) as IUserContext;
    const [keyword, setKeyword] = useState<string>(null);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const history = useHistory();



    //On vérifie si on est connecté ou non
    useEffect(()=>{
        if(!isAuthenticated){
            // On redirige vers la home
            history.push(AppConstants.pathLogin);
        }

        setKeyword(props.keyword ? props.keyword : '');
    },[isAuthenticated,props.keyword]);


    /**
     * Ecoute du changement du field keyword
     *
     * @param {string} value
     */
    const onKeywordChange = (value: string): void => {
        setKeyword(value);

        if(props.searchAuto){
            const formDatas: IFormSearchData = {
                keyword: value,
            };

            if(props.onSubmit){
                debouncedSave(formDatas)
            }
        }
    };

    const debouncedSave = useRef(debounce(formDatas => props.onSubmit(formDatas), 200)).current;


    /**
     * Ecoute du submit du formulaire
     *
     * @param {React.FormEvent} e
     * @private
     */
    const onFormSubmit = (e: React.FormEvent): void => {
        e.preventDefault();

        if(!keyword || !keyword.length){
            return;
        }

        const formDatas: IFormSearchData = {
            keyword: keyword,
        };

        if(props.onSubmit){
            props.onSubmit(formDatas);
        }
    };


    /**
     * Permet de récupérer les erreurs pour un champ
     *
     * @param {string} fieldName
     * @returns {string[]}
     */
    const getFieldsErrors = (fieldName: string): IApiErrorMessage  => {
        return errorFieldsMessages && errorFieldsMessages[fieldName] ? errorFieldsMessages[fieldName] : null;
    };



    return (
        <div className={`search-block`}>
            <form onSubmit={(e) => onFormSubmit(e)} autoComplete="off">
                <SearchFieldComponent fieldName="keyword" label="Recherche" onChange={onKeywordChange} errors={getFieldsErrors('keyword')} labelModificators="-white" modificators={props.modificators || ''} oldValue={props.keyword} placeholder={props.placeholder || "Office, Notaires, CRPCEN …"} />
            </form>
        </div>
    )
}
