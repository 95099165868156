import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {AutresContactsService, IAutresContactsGetFormData, IPersonnesExterieuresGetApiResponse} from "src/Services/AutresContacts.service";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import MapHelper from "src/Helpers/Map.helper";
import AsidePersonneExterieureComponent from "src/Components/Aside/AsidePersonneExterieure.component";
import PersonnesExterieuresAdvancedSearchComponent from "src/Components/Annuaire/Autres-contacts/Personnes-exterieures/PersonnesExterieuresAdvancedSearch.component";
import PersonnesExterieuresExportComponent from "src/Components/Annuaire/Autres-contacts/Personnes-exterieures/PersonnesExterieuresExport.component";
import {Link} from "react-router-dom";
import LoadingComponent from "../../../../Components/Loading/Loading.component";



type PersonneExterieureListScreenProps = {}

export default function PersonneExterieureListScreen(props: PersonneExterieureListScreenProps) {
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathPersonnesExterieures);
    const [personnesExterieures, setPersonnesExterieures] = useState<IPersonneExterieure[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const location = useLocation();
    const history = useHistory();
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const initialFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(initialFilters, false, true);
    }, []);


    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters,...formData},true);
        setShowAdvancedSearch(false);
    };


    /**
     * Récupération des offices
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        //Gestion de la tab
        filters.tab = activeTab;

        const autresContactsService: AutresContactsService = new AutresContactsService();
        autresContactsService.getPersonnesExterieures(filters as IAutresContactsGetFormData).then((response: IPersonnesExterieuresGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newPersonnesExterieuresList = response.datas.pagination.data;
                setPersonnesExterieures(newPersonnesExterieuresList);

                setPaginationLinks(response.datas.pagination.links);

                // Markers
                const markers: MapMarker[] = [];
                newPersonnesExterieuresList.forEach((p) => {
                    if (p.adressePrincipale && p.adressePrincipale.latitude) {
                        const adresse = p.adressePrincipale;

                        const markerText: string = MapHelper.createMapMarkerTextPersonneExterieure(`${AppConstants.pathPersonnesExterieures}/${p.id}`,`${p.nom} ${p.prenom}`, adresse, p.organisme);
                        const marker: MapMarker = MapHelper.createMapMarker(markerText, adresse);
                        markers.push(marker);
                    }
                });
                setMarkers(markers);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    };

    return <>
        <LayoutComponent showMenu={true} contentModificators="" wrapperModificators="">
            <div className={`list ${loaded ? 'border-footer' : ''}`}>

                <PageHeaderComponent breadcrumbLinks={[{text: "Annuaire", link: AppConstants.pathAnnuaire}, {text: "Personnes Extérieures"}]}
                     text="Personnes Extérieures"
                     icon="icon-personne-exterieure"
                     onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                     onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
					<>
						<TabsContainerComponent  defaultTab={TabsSlugs.LIST} onClickTabCallback={(tab)=>{updateFilters({},false); setActiveTab(tab)}} >
							<TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.LIST}>
								<div className="list-content -right-gutter">

                                    <PersonnesExterieuresAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={ onAdvancedSearch}
                                    />

									<table className={`list-table`}>
										<thead className={"headers"}>
										<tr>
                                            {[
                                                {slug: "nom", label: "Nom"},
                                                {slug: "nomOrganisme", label: "Organisme"},
                                                {slug: "ville", label: "Ville"},
                                                {slug: "fonction", label: "Fonction"},
                                            ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    label={item.label} click={(value: string) => setOrder(value)}/>
                                            </th>)}
										</tr>
										</thead>

										<tbody>
                                        {
                                            personnesExterieures && personnesExterieures.map((personne, index: number) =>
                                                <tr key={index}
                                                    onClick={() => history.push(`${AppConstants.pathPersonnesExterieures}/${personne.id}`)}
                                                    className="tr">
                                                    <td className="td -bold">
                                                        {personne.nom} {personne.prenom}
                                                    </td>
                                                    <td className="td">
                                                        {
                                                            personne.organisme &&
                                                            <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOrganismes}/${personne.organisme.id}`} onClick={(e:any) => e.stopPropagation()}>{personne.organisme.nom}</Link>
                                                        }
                                                    </td>
                                                    <td className="td -uppercase">{personne?.adressePrincipale?.ville}</td>
                                                    <td className="td">
                                                        {personne.fonction}
                                                    </td>
                                                </tr>
                                            )
                                        }
										</tbody>
									</table>

									<PaginationComponent links={paginationLinks}
									                     onPageClick={page => updateFilters({page})}/>

                                    <AsidePersonneExterieureComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
								</div>
							</TabPanelComponent>


							<TabPanelComponent label="Carte" icon="icon-map" slug={TabsSlugs.MAP}>
								<div className={`list-content -no-gutter`}>
                                    <PersonnesExterieuresAdvancedSearchComponent
                                        showSummary={false}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={ onAdvancedSearch}
                                    />

									<div className="map-wrapper">
										<MapComponent markers={markers} key={Math.random()}/>
									</div>

                                    <AsidePersonneExterieureComponent type="list" modificators="-on-map" exportCallback={() => setShowExport(true)}  filters={filters} />
								</div>
							</TabPanelComponent>
						</TabsContainerComponent>
					</>
                }
            </div>
        </LayoutComponent>

        <PersonnesExterieuresExportComponent filters={filters} setShowExport={() => setShowExport(false)} showExport={showExport} />
    </>
}
