import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import useProtection from "src/Hooks/UseProtection";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {ITableauBord} from "src/Models/TableauBord.model";
import usePrint from "../../Hooks/UsePrint";

type IAsideTableauxBordComponentProps = {
    modificators?: string,
    type: "list" | "fiche",
    tableauBord?: ITableauBord

    exportCallback?: () => void
}

export default function AsideTableauxBordComponent(props: IAsideTableauxBordComponentProps ) {
    const {checkLockProtection, unlockProtection, initProtection} = useProtection();
    const {isAdmin} = useContext(UserContext) as IUserContext;
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [printScreen] = usePrint();


    useEffect(()=> {
        let actions: ListAsideAction[] = [];
        actions.push({icon: "icon-print", click: () => printScreen()})

        // if( props.type === "fiche" && props.tableauBord){
        //     const tableauBord: ITableauBord = props.tableauBord;
        //     if(isAdmin && tableauBord){
        //         initProtection('tableauBord', tableauBord.id);
        //         checkLockProtection().then((isLocked: boolean) => {
        //             const oldActions = actions;
        //             const newActions = [...oldActions];
        //             if(isLocked){
        //                 newActions.push({icon: "icon-unlock", click: () => unlockProtection().then(()=> {
        //                         notificationSystem.addNotification({
        //                             ...defaultNotificationConfig,
        //                             message: "Le tableau de bord a bien été débloquée.",
        //                             type: "success"
        //                         });
        //
        //                         setActions(oldActions);
        //                     })
        //                 });
        //             }
        //
        //             setActions(newActions);
        //         });
        //     }
        // }

        setActions(actions);
    }, []);

    return (
        <>
            {
                actions && actions.length > 0 &&
                <ListAsideComponent modificators={props.modificators} actions={actions}/>
            }
        </>
    )
}
