import ExportComponent from "src/Components/Exports/Export.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {IOption} from "../Fields/Select.field.component";
import {FormElement} from "../Forms/FormCreator.component";
import moment, {Moment} from "moment";

type ICotisationsNotairesExportProps = {
    setShowExport: () => void,
    cotisationsYears?: number[],
    showExport: boolean,
}
export default function CotisationsNotairesExportComponent(props: ICotisationsNotairesExportProps){
    const [yearsOptions, setYearsOptions] = useState<IOption[]>(null);
    const [formElements, setFormElements] = useState<FormElement[]>([]);


    useEffect(() => {
        const newYearsOptions: IOption[] = [];

        const currentDate:Moment = moment();
        const currentYear: number = parseInt(currentDate.format("YYYY"));

        for(let i = 0, j = 5; i < j; i++) {
            const newYear: number = currentYear - i;
            newYearsOptions.push({value: newYear.toString(), label: newYear.toString()});
        }


        setYearsOptions(newYearsOptions);

        if(newYearsOptions && newYearsOptions.length) {
            setFormElements([
                {
                    type: 'select',
                    fieldName: "annee",
                    label: "Année",
                    modificators: "-on-white",
                    hideSearchOnMultiple: true,
                    options: yearsOptions
                }
            ]);
        }

    },[props.cotisationsYears])


    if( !props.showExport ) return null;

    return (
        <>
            {
                yearsOptions &&
                <ExportComponent title={"Cotisations"}
                                 icon="icon-cotisation-liste-notaire"
                                 subTitle={"Export des notaires"}
                                 onClose={() => props.setShowExport()}
                                 modificators={`${!props.showExport ? '-hide' : ''}`}
                                 exportUrl={`api${AppConstants.pathCotisationsSaisieNotaires}/export`}
                                 columns={[
                                     {
                                         elements: formElements
                                     }
                                 ]}
                />
            }
        </>
    )

}
