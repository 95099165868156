import React, {useEffect, useState} from "react";
import {IOrganisme} from "../../../../Models/Organisme.model";
import TableHeaderButtonComponent from "../../../Lists/TableHeaderButton.component";
import {AppConstants} from "../../../../Constants/AppConstants";
import {useHistory} from "react-router";
import ConfirmationComponent from "../../../Confirmation/Confirmation.component";
import {AutresContactsService} from "../../../../Services/AutresContacts.service";
import {IPersonneExterieure} from "../../../../Models/PersonneExterieure.model";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../../Shared/config";
import {IApiCustomResponse} from "../../../../Services/Api.service";

type IOrganismePersonneListProps = {
    organisme: IOrganisme,
};

const OrganismePersonneListComponent = (props: IOrganismePersonneListProps ) => {
    const history = useHistory();
    const [showConfirmationDelete, setShowConfirmationDelete] = useState<boolean>(false);
    const [organisme, setOrganisme] = useState<IOrganisme>(null);
    const [personneToDelete, setPersonneToDelete] = useState<IPersonneExterieure>(null);


    useEffect(() => {
        setOrganisme(props.organisme);
    }, [props.organisme]);



    /**
     * Annule la demande de confirmation
     */
    const onCancelDelete = () => {
        setShowConfirmationDelete(false);
    };

    const onConfirmationDeletePersonne = () => {
        const autresContactsService: AutresContactsService = new AutresContactsService();

        autresContactsService.deletePersonneFromOrganisme(organisme.id.toString(), personneToDelete.id.toString()).then((result) => {
            if (result.datas && result.datas.organisme) {
                setOrganisme(result.datas.organisme);
                setPersonneToDelete(null);
                setShowConfirmationDelete(false);

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "La personne a bien été supprimée de l'organisme",
                    type: "success"
                });
            }
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression de la personne de l'organisme.",
                type: "danger"
            });
        });
    }



    return <>
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                {[
                    {slug: "nom", label: "Nom"},
                    {slug: "prenom", label: "Prénom"},
                    {slug: "fonction", label: "Fonction"},
                    {slug: "Action", label: ""}
                ].map((item, iIndex) => <th className="th" key={iIndex}>
                    <TableHeaderButtonComponent direction={AppConstants.orderType.asc} value={item.slug} selectedValue={""} label={item.label} click={null}/>
                </th>)}
            </tr>
            </thead>
            <tbody>
                {
                    organisme && organisme.personnesExterieures && organisme.personnesExterieures.map((personne, index: number) =>
                        <tr key={index} className="tr">
                            <td className="td -bold" onClick={() => history.push(`${AppConstants.pathPersonnesExterieures}/${personne.id}`)}>
                                {personne.nom}
                            </td>
                            <td className="td -bold" onClick={() => history.push(`${AppConstants.pathPersonnesExterieures}/${personne.id}`)}>
                                {personne.prenom}
                            </td>
                            <td className="td" onClick={() => history.push(`${AppConstants.pathPersonnesExterieures}/${personne.id}`)}>
                                {personne.fonction}
                            </td>
                            <td className="td -w85px">
                                <button className="g-button -delete-button icon-remove" onClick={() => { setPersonneToDelete(personne); setShowConfirmationDelete(true)} } />
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>

        {showConfirmationDelete && <ConfirmationComponent onConfirm={()=>onConfirmationDeletePersonne()} onCancel={onCancelDelete} text="Souhaitez-vous vraiment supprimer cette personne de cet Organisme ?"/>}
    </>
};

export default OrganismePersonneListComponent;
