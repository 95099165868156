import {
    IFormationsGetApiResponse,
    FormationsService, IFormationGetApiResponse, IFormationFormData
} from "src/Services/Formations.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import React, {useEffect, useState} from "react";
import {IFormation} from "src/Models/Formation.model";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {
    CommonsService,
} from "../../../Services/Commons.service";
import useFormation from "../../../Hooks/UseFormation";
import InformationHeaderComponent from "../../Informations/InformationHeader.component";
import FormationInformationsForm from "../../Forms/Formations/Formations/FormationInformationsForm.component";
import FormationFormationForm from "../../Forms/Formations/Formations/FormationFormationForm.component";
import FormationDocumentsForm from "../../Forms/Formations/Formations/FormationDocumentsForm.component";
import FormationPrecisionsForm from "../../Forms/Formations/Formations/FormationPrecisionsForm.component";
import FormationComplementsForm from "../../Forms/Formations/Formations/FormationComplementsForm.component";
import FormationParticipantMailForm from "../../Forms/Formations/Formations/FormationParticipantMailForm.component";

type IFormationCreationStepComponentProps = {
    fid?: number,
    pid?: number,
    type: string;
}
export default function FormationCreationStepComponent(props: IFormationCreationStepComponentProps){
    const formationsService: FormationsService = new FormationsService();
    const commonsService: CommonsService = new CommonsService();
    const [getFormationById] = useFormation();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [formation, setFormation] = useState<IFormation>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.fid){
            getFormationById(props.fid, (formation: IFormation) => {
                setFormation(formation);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, []);


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IFormationGetApiResponse): void => {
        if(response.datas && response.datas.formation){
            const formation: IFormation = response.datas.formation;
            history.push(AppConstants.pathFormations + '/' + formation.id);
        }
        else{
            history.push(AppConstants.pathFormations + '/' + formation.id);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitNext = (response: IFormationGetApiResponse): void => {
        if(response.datas && response.datas.formation){
            const formation: IFormation = response.datas.formation;
            redirectToNextCreationStep(AppConstants.pathFormations, formation.id, formation.creationSteps);
        }
        else{
            history.push(AppConstants.pathFormations + '/' + formation.id);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        if(formation) {
            history.push(AppConstants.pathFormations + '/' + formation.id);
        }
        else{
            history.push(AppConstants.pathFormations);
        }
    };



    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter une formation" icon="icon-formation-add" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
                    {
                        props.type === "informations" &&
                        <>
	                        <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />
	                        <FormationInformationsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationInformations(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                    {
                        props.type === "formation" &&
                        <>
	                        <InformationHeaderComponent icon={"icon-formations"} title={"Formation"} modificators="-margin-bottom" />
	                        <FormationFormationForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationFormation(props.fid,formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                    {
                        props.type === "documents" &&
                        <>
	                        <InformationHeaderComponent icon={"icon-documents"} title={"Documents"} modificators="-margin-bottom" />
	                        <FormationDocumentsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationDocuments(props.fid,formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                    {
                        props.type === "precisions" &&
                        <>
	                        <InformationHeaderComponent icon={"icon-precisions"} title={"Précisions"} modificators="-margin-bottom" />
	                        <FormationPrecisionsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationPrecisions(props.fid,formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                    {
                        props.type === "complements" &&
                        <>
	                        <InformationHeaderComponent icon={"icon-complements"} title={"Compléments"} modificators="-margin-bottom" />
	                        <FormationComplementsForm formation={formation} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationComplements(props.fid,formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                    {
                        props.type === "editParticipantMail" &&
                        <>
	                        <InformationHeaderComponent icon={"icon-email-document"} title={"Modification de l'email"} modificators="-margin-bottom" />
	                        <FormationParticipantMailForm formation={formation} participantId={props.pid} submitMethod={(formData: IFormationFormData)=>formationsService.updateFormationParticipantEmailId(props.fid, props.pid, formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                </>
            }
        </LayoutComponent>
    )
}
