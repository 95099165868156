import React from "react";
import {IReclamation} from "src/Models/Reclamation.model";
import {INotaire} from "../../Models/Notaire.model";
import NotairesHelper from "../../Helpers/Notaires.helper";

type IReclamationNotairesComponentProps = {
    reclamation: IReclamation,
}

export default function ReclamationNotairesComponent(props: IReclamationNotairesComponentProps) {
    const reclamation: IReclamation = props.reclamation;

    return (
        <div className="informations-wrapper">
            {
                reclamation.notaires && reclamation.notaires.map( (notaire:INotaire, index) =>
                    <div className="columns" key={index}>
                        <p className="title -text-transform-none">Notaire - {notaire.prenom} {notaire.nom}</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">CRPCEN</p>
                                <p className="value">{notaire.office ? notaire.office.CRPCEN : '-'}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Prénom</p>
                                <p className="value">{notaire.prenom || '-'}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Nom</p>
                                <p className="value">{notaire.nom || '-'}</p>
                            </div>
                        </div>

                        <div className="column -auto">
                            <div className="informations-block">
                                <p className="label">Type de notaire </p>
                                <p className="value">{notaire.type ? NotairesHelper.getFormattedType(notaire.type) : '-'}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
