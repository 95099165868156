import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {IOrganismeGetApiResponse, IOrganismeInformationsFormData} from "src/Services/AutresContacts.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {IOrganisme} from "src/Models/Organisme.model";
import GroupesHelper from "src/Helpers/Groupes.helper";
import {IGroupe} from "src/Models/Groupe.model";
import {GroupesService, IGroupesGetApiResponse} from "src/Services/Groupes.service";
import useCreationSteps from "../../../../../Hooks/useCreationSteps";


type IOrganismeInformationsFormProps = {
    organisme?: IOrganisme,
    isOnCreationStep?: boolean

    submitMethod: (formData: IOrganismeInformationsFormData) => Promise<IOrganismeGetApiResponse>,
    onSuccessSubmit: (response: IOrganismeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOrganismeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OrganismeInformationsFormComponent(props: IOrganismeInformationsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [groupes, setGroupes] = useState<IGroupe[]>([]);
    const [groupesLoaded, setGroupesLoaded] = useState<boolean>(false);
    const {getFormActions} = useCreationSteps();


    useEffect(() => {
        const groupesServices: GroupesService = new GroupesService();

        groupesServices.getAllNonAutomatique().then((response:IGroupesGetApiResponse) => {
            if(response && response.datas && response.datas.groupes){
                setGroupes(response.datas.groupes);
                setGroupesLoaded(true);
            }
        });
    }, [])


    /**
     * Initialisation du formulaire à partir des infos de l'organisme
     */
    useEffect(()=>{
        const organisme: IOrganisme = props.organisme;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                showFieldErrorDetail: true,
                oldValue: organisme && organisme.nom ? organisme.nom : "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'selectMultiple',
                fieldName: "groupes",

                label: "Groupes",
                placeholder: "Groupes",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                //Les groupes automatique ne doivent pas être présent dans la liste déroulante
                //Leur gestion est automatique à l'enregistrement d'un organisme
                oldValues: organisme && organisme.groupes ? GroupesHelper.getIdsFromListWithoutAutomatique(organisme.groupes) : [],
                oldValuesReadOnly: organisme && organisme.groupes ? GroupesHelper.getNamesFromListAutomatique(organisme.groupes) : [],

                options: groupes ? GroupesHelper.formatListForOptions(groupes): []
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    },[props.organisme, groupes]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IOrganismeInformationsFormData).then((response: IOrganismeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'organisme a bien été créée.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création de l'organisme.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                groupesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }

        </div>
    )
}
